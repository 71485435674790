import React, { useContext, useEffect } from 'react';
import SearchBasedDropdown from '../../components/SearchBasedDropdown/SearchBasedDropdown';
import {
    Button,
    Drawer,
    Paper,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import { GlobalContext } from '../../context';
import { findIndex } from 'lodash';
import { useObjectState } from '../../utilities/customHooks';

const TABWIDTH = 420;

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',

        '& > div:not(:last-of-type)': {
            marginBottom: theme.spacing(4)
        }
    },
    apply: {
        marginBottom: theme.spacing(2)
    },
    listingTitle: {
        width: '100%'
    }
});

const contentTypes = [
    {
        id: 1,
        name: 'Article'
    },
    {
        id: 2,
        name: 'Interview'
    },
    {
        id: 3,
        name: 'Newsletter'
    }
];

const FiltersDrawer = ({
    classes,
    open,
    filters: filtersProp,
    onClose = () => { },
    onApplyFilters = () => { }
}) => {
    const context = useContext(GlobalContext);
    const [filters, setFilters] = useObjectState({
        client: null,
        directory: null,
        location: null,
        contentType: null,
        adType: null,
        listing: ''
    });
    const [prevFilters, setPrevFilters] = useObjectState({
        client: null,
        directory: null,
        location: null,
        contentType: null,
        adType: null,
        listing: ''
    });
    const {
        providers,
        directories,
        countries,
        productItems,
        fetchingProviders,
        fetchingDirectories,
        fetchingProductItems,
        fetchingCountries
    } = context.state;

    const handleFilterChange = (field) => (filter) =>
        setFilters({ ...filters, [field]: filter });

    const handleListingFilterChange = (e) =>
        setFilters({ ...filters, listing: e.target.value });

    const handleClearFilters = () =>
        setFilters({
            client: null,
            directory: null,
            location: null,
            contentType: null,
            adType: null,
            listing: ''
        });

    const handleApplyFilters = () => {
        setPrevFilters(filters);
        onApplyFilters(filters);
        onClose();
    };

    useEffect(() => {
        if (
            providers.length > 0 &&
            !!(filters.client || {}).value &&
            !(filters.client || {}).label
        ) {
            const provider = { value: filters.client.value };
            const index = findIndex(providers, ['id', provider.value]);

            if (index > -1) {
                provider.label = providers[index].name;
                handleFilterChange('client')(provider);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providers]);

    useEffect(() => {
        setFilters({
            client: filtersProp.client,
            directory: filtersProp.directory,
            location: filtersProp.location,
            contentType: filtersProp.contentType,
            adType: filtersProp.adType,
            listing: filtersProp.listing
        });
        setPrevFilters({
            client: filtersProp.client,
            directory: filtersProp.directory,
            location: filtersProp.location,
            contentType: filtersProp.contentType,
            adType: filtersProp.adType,
            listing: filtersProp.listing
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersProp]);

    const adTypes = productItems
        .filter(({ ad_type_id }) => {
            return ad_type_id > 0 && ad_type_id <= 13;
        })
        .map(({ id, item_name }) => {
            return { value: id, label: item_name };
        });
    const hasChanges = JSON.stringify(filters) !== JSON.stringify(prevFilters);

    return (
        <Drawer open={open} anchor="right" onClose={onClose}>
            <div className={classes.root}>
                <Typography className={classes.drawerTitle}>
                    Data Filters
                </Typography>
                <Paper className={classes.paper}>
                    <SearchBasedDropdown
                        valueKey="id"
                        labelKey="name"
                        placeholder={
                            fetchingProviders
                                ? 'Loading Client Names...'
                                : 'Client Name'
                        }
                        arrayOptions={providers}
                        handleChange={handleFilterChange('client')}
                        value={filters.client || null}
                        isClearable={true}
                        isDisabled={fetchingProviders}
                    />
                    <SearchBasedDropdown
                        valueKey="id"
                        labelKey="name"
                        placeholder={
                            fetchingDirectories
                                ? 'Loading Directories...'
                                : 'Directory'
                        }
                        arrayOptions={directories}
                        handleChange={handleFilterChange('directory')}
                        value={filters.directory || null}
                        isClearable={true}
                        isDisabled={fetchingDirectories}
                    />
                    <SearchBasedDropdown
                        valueKey="id"
                        labelKey="name"
                        placeholder={
                            fetchingCountries
                                ? 'Loading Locations ...'
                                : 'Location'
                        }
                        handleChange={handleFilterChange('location')}
                        arrayOptions={countries}
                        value={filters.location || null}
                        isClearable={true}
                        isDisabled={fetchingCountries}
                    />
                    <SearchBasedDropdown
                        valueKey="value"
                        labelKey="label"
                        placeholder={
                            fetchingProductItems
                                ? 'Loading Ad Names ...'
                                : 'Ad Name'
                        }
                        arrayOptions={adTypes}
                        handleChange={handleFilterChange('adType')}
                        value={filters.adType || null}
                        isDisabled={fetchingProductItems}
                    />
                    <TextField
                        className={classes.listingTitle}
                        variant="outlined"
                        size="small"
                        value={filters.listing}
                        onChange={handleListingFilterChange}
                        placeholder="Listing ID"
                    />
                    <SearchBasedDropdown
                        valueKey="id"
                        labelKey="name"
                        placeholder="Content Type"
                        arrayOptions={contentTypes}
                        handleChange={handleFilterChange('contentType')}
                        value={filters.contentType || null}
                    />
                </Paper>
                <Button
                    className={classes.apply}
                    variant="contained"
                    color="primary"
                    onClick={handleApplyFilters}
                    disabled={
                        !Object.keys(filters).filter((key) => !!filters[key])
                            .length || !hasChanges
                    }
                >
                    Apply Filters
                </Button>
                <Button
                    className={classes.clear}
                    variant="outlined"
                    color="secondary"
                    onClick={handleClearFilters}
                >
                    CLEAR FILTERS
                </Button>
            </div>
        </Drawer>
    );
};

export default withStyles(styles)(FiltersDrawer);
