import { format, differenceInDays } from 'date-fns';
/**
 * Builds the date depending on type whether datetime or not
 *
 * @param {Date} date       Value of the date
 * @param {string} type     type of format
 *
 * @return {String} desired formate of the datestring
 */
export const dateFormatter = (date, type) => {
    const is_valid = (date) => {
        return (
            date !== 'NaN' &&
            date !== null &&
            date !== '' &&
            date !== '0000-00-00 00:00:00' &&
            date !== '0000-00-00'
        );
    };
    if (is_valid(date)) {
        date = new Date(date);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours() + '';
        var minutes = date.getMinutes() + '';
        var seconds = date.getSeconds() + '';
        if (type === 'datetime') {
            return (
                year +
                '-' +
                updateToAddZero(month) +
                '-' +
                updateToAddZero(day) +
                ' ' +
                updateToAddZero(hour) +
                ':' +
                updateToAddZero(minutes) +
                ':' +
                updateToAddZero(seconds)
            );
        } else {
            return (
                year + '-' + updateToAddZero(month) + '-' + updateToAddZero(day)
            );
        }
    } else {
        return '';
    }
};

/**
 * Compute the endDate of invoiceItem depending on Duration
 *
 * @param {Date} date       Value of the startDate
 * @param {Object} duration value to be added to startDate
 * Object of duration:
 * {
 *   type: {String},
 *   value: {Integer}
 * }
 *
 * @return {Date} value of the endDate
 */
export const computeEndDate = (date = new Date(), duration = {}) => {
    if (duration.type === 'month') {
        return new Date(date).setMonth(
            new Date(date).getMonth() + parseInt(duration.value)
        );
    } else if (duration.type === 'day') {
        return new Date(date).setDate(
            new Date(date).getDate() + parseInt(duration.value)
        );
    } else if (duration.type === 'year') {
        return new Date(date).setFullYear(
            new Date(date).getFullYear() + parseInt(duration.value)
        );
    } else {
        return new Date(date);
    }
};

/**
 * Validates if input is a valid date
 *
 * @param {Object} input       Value of the input]
 *
 * @return bool value of the endDate
 */
export const is_date = (input) => {
    return new Date(input) !== 'Invalid Date' && !isNaN(new Date(input));
};

/**
 * Append extra zero if number is less than 10
 *
 * @param {Object} input        Value of the input
 *
 * @return string
 */
export function updateToAddZero(input) {
    return input < 10 ? `0${input}` : `${input}`;
}

export function transformDateParam({ start, end }) {
    if (
        differenceInDays(new Date(start), new Date(end)) === 0 ||
        end === undefined
    )
        return format(new Date(start), 'YYYY-MM-DD');
    else
        return `${format(new Date(start), 'YYYY-MM-DD')},${format(
            new Date(end),
            'YYYY-MM-DD'
        )}`;
}

export const toTimezoneDate = (date = new Date()) => {
    const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    if (typeof date === 'string' && pattern.test(date))
        date = date.replace(/ /g, 'T');

    const formatted = format(new Date(date), 'YYYY-MM-DDTHH:mm:ss');
    let databaseDate = new Date(`${formatted}.000+08:00`);

    return databaseDate.toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
};

export const toUTCDate = (date) => {
    return new Date(`${date}T00:00:00.000`);
};
