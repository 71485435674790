import InfoIcon from '@material-ui/icons/InfoRounded';
import React from 'react';
import { Tooltip, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import { withSearchResultsTableContext } from './SearchResultsTableProvider';

const styles = theme => ({
    label: {
        fontWeight: 'bold',
        minWidth: 100,
        padding: '8px 0',
    },
    subLabel: {
        fontSize: 11,
        minWidth: 100,
        padding: '8px 0',
    },
    tooltip: {
        fontSize: 16,
        marginBottom: -3,
        marginLeft: theme.spacing(1),
    },
    arrowButton: {
        padding: 0
    },
});

const SearchResultsTableHead = props => {
    const { context, classes } = props;
    const { columns } = context;
    const hasSubColumns = columns.filter(column => { return !!column.subcolumns }).length !== 0;

    const tempColumns = columns.map(column => {
        column.rowSpan = column.subcolumns ? 1 : 2;
        return column;
    });

    return (
        <TableHead>
            <TableRow>
                {
                    tempColumns.map((column) => {
                        return (
                            <TableCell
                                colSpan={!!column.subcolumns ? column.subcolumns.length : 1}
                                align='center'
                                padding="none"
                                rowSpan={column.rowSpan}
                                key={`row-header-${column.key}`}
                                className={classes.label}
                            >
                                { column.label}
                                {
                                    column.description &&
                                    <Tooltip
                                        className={classes.tooltip}
                                        title={column.description}
                                        placement="top"
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                }
                            </TableCell>
                        )
                    })
                }
                <TableCell rowSpan={2}></TableCell>
            </TableRow>
            {
                hasSubColumns &&
                <TableRow>
                    {
                        columns.map((column) => {
                            if (!column.subcolumns)
                                return null;
                            return column.subcolumns.map(subcolumn => {
                                return (
                                    <TableCell
                                        padding="none"
                                        align="center"
                                        key={`row-subheader-${column.key}-${subcolumn.key}`}
                                        className={classes.subLabel}
                                    >
                                        { subcolumn.label}
                                    </TableCell>
                                )
                            })
                        })
                    }

                </TableRow>
            }
        </TableHead>
    );
}

export default withSearchResultsTableContext(withStyles(styles)(SearchResultsTableHead));