import React, { memo } from 'react';
import { TableFooter, TableRow, TablePagination } from '@material-ui/core';
import { withState, withProps } from './StatsTableProvider';

const usedStates = ['page', 'rowsPerPage'];
const usedProps = [
    'isLoading',
    'data',
    'onPageChange',
    'onRowsPerPageChange'
];

const StatsTableFooter = ({
    data,
    page,
    isLoading,
    rowsPerPage,
    handleSetState,
    onPageChange = () => {},
    onRowsPerPageChange = () => {}
}) => {
    const handlePageChange = (e, page) =>
        handleSetState({ page }, () => onPageChange(page));

    const handleRowsPerPageChange = (e) =>
        handleSetState({ rowsPerPage: e.target.value }, () =>
            !!onRowsPerPageChange && onRowsPerPageChange(e.target.value)
        );

    return (
        <TableFooter>
            <TableRow padding="none">
                <TablePagination
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 12, 25, 50, 100]}
                    page={page}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    SelectProps={{ disabled: isLoading }}
                />
            </TableRow>
        </TableFooter>
    );
};

export default withState(usedStates)(
    withProps(usedProps)(memo(StatsTableFooter))
);
