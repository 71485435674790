import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import {
    useProgramRankingState,
    useProgramRankingMethods
} from './ProgramRankingProvider';
import { TableLoader } from '../../../components/Loader/Loader';
import DialogBox from '../../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2)
    }
}));

const ProgramRankingTable = () => {
    const classes = useStyles();
    const { fetching, rankLoading, dialog } = useProgramRankingState();
    const { handleCloseDialog } = useProgramRankingMethods();

    if (fetching) return <TableLoader />;

    return (
        <Paper>
            <div className={classes.root}>
                <TableHeader />
                <TableBody />
                <DialogBox
                    actions={dialog.actions}
                    title={dialog.title}
                    contentText={dialog.content}
                    open={dialog.showDialog}
                    onOk={dialog.onOk}
                    onCancel={dialog.onCancel}
                    onClose={handleCloseDialog}
                    stringOverride={dialog.stringOverride}
                    disableBackdropClick={true}
                />
                <DialogBox
                    open={rankLoading}
                    title={`Ranking provider's programs...`}
                    actions=""
                >
                    <LinearProgress />
                </DialogBox>
            </div>
        </Paper>
    );
};

export default ProgramRankingTable;
