import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    Drawer,
    Checkbox,
    Switch,
    FormGroup,
    FormControlLabel,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Button,
} from '@material-ui/core';
import {
    TEAM_ASSIGNMENT,
    OAI_AUTOMATED_STATUS,
    PROVIDER_LINK_STATUS,
} from '../../config/constants.js';

const styles = (theme) => ({
    root: {
        display: 'flex',
        '& > div:not(#closeButton)': {
            borderLeft: '1px solid #ddd',
            display: 'flex',
            flexGrow: 1,
            padding: `24px 16px`,
        },
        '& > #closeButton': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `16px 24px`,
        },
    },
    teamDropdown: {
        maxWidth: 200,
    },
    listingTypeDropdown: {
        width: 100,
    },
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    globalFilterCTA: {
        display: 'grid !important',
        '& > button': {
            margin: '4px 0px',
        },
    },
});

class GlobalFilterDrawer extends Component {
    state = {
        team: 32,
        listing: 'free',
        inquirySource: false,
        inquiry_source: -1,
        teamAssignment: false,
        source: 'goabroad',
        hasSource: false,
        paid: false,
        accountType: false,
        status: false,
        accountStatus: false,
        online: false,
        onlineStatus: false,
        activeReview: false,
        reviewStatus: false,
        published: false,
        listingType: false,
        publicationStatus: false,
        checkedContent: false,
        contentStatus: false,
        commentedReview: false,
        reviewComment: false,
        selectedStatus: 'inactive',
        selectedAccountType: 'unpaid',
        selectedReviewStatus: 'inactive',
        advisor_id: '',
        oaiTeamMember: false,
        oaiTeam: false,
        advisorTeam: '13',
        responseStatus: true,
        directoryStatus: false,
        program: '',
        programType: false,
        paidProgram: false,
        oaiMatches: false,
        showOAIMatches: false,
        program_directory_id: '',
        articleStatus: false,
        articlePublished: false,
        providerLink: false,
        providerLinkStatus: '',
        programLink: false,
        programLinkStatus: '',
        adLinkStatus: '',
        adLink: false,
        top_pick: false,
        topPickStatus: false,
        preferred_oai: false,
        preferredOAIStatus: false,
        is_generic: false,
        genericListingStatus: false,
    };

    getSnapshotBeforeUpdate(prevProps) {
        const { filters: prevFilters } = prevProps;
        const { filters } = this.props;
        return { filtersChanged: prevFilters !== filters };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot && snapshot.filtersChanged) {
            let newState = { ...prevState };
            const { filters } = this.props;
            Object.keys(filters).forEach(function (key, i) {
                if (
                    newState.hasOwnProperty(key) &&
                    filters[key] !== newState[key]
                ) {
                    newState[key] = filters[key];
                }
            });
            this.setState(newState);
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () =>
            this.handleTriggerChanges()
        );
    };

    handleOAIStatusChange = (event) => {
        const { handleStateChanged } = this.props;
        handleStateChanged({ [event.target.name]: event.target.value }, () =>
            this.handleTriggerChanges()
        );
    };

    handleCheckboxChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked }, () =>
            this.handleTriggerChanges()
        );
    };

    handleClearFilter = () => {
        this.setState(
            {
                team: 32,
                inquirySource: false,
                inquiry_source: -1,
                teamAssignment: false,
                listing: 'free',
                listingType: false,
                source: 'goabroad',
                hasSource: false,
                paid: false,
                accountType: false,
                status: false,
                accountStatus: false,
                online: false,
                onlineStatus: false,
                activeReview: false,
                reviewStatus: false,
                published: false,
                publicationStatus: false,
                checkedContent: false,
                contentStatus: false,
                commentedReview: false,
                reviewComment: false,
                selectedStatus: 'inactive',
                oaiTeamMember: false,
                oaiTeam: false,
                advisorTeam: '',
                responseStatus: false,
                directoryStatus: false,
                response: '0',
                program: '',
                programType: false,
                paidProgram: false,
                oaiMatches: false,
                showOAIMatches: false,
                articleStatus: false,
                articlePublished: false,
                adLink: false,
                programLink: false,
                top_pick: false,
                topPickStatus: false,
                preferred_oai: false,
                preferredOAIStatus: false,
                is_generic: false,
                genericListingStatus: false,
            },
            () =>
                this.props.onClearFilters &&
                this.props.onClearFilters({ filters: this.mapFilters() })
        );
    };

    handleClose = (e) => {
        const { onClose } = this.props;
        onClose && onClose();
    };

    handleTriggerChanges = () => {
        const { onFiltersChange } = this.props;

        onFiltersChange && onFiltersChange({ filters: this.mapFilters() });
    };

    mapFilters = () => {
        const filters = {
            is_active: this.state.accountStatus
                ? this.state.status
                    ? 1
                    : 0
                : null,
            is_paying: this.state.accountType
                ? this.state.paid
                    ? 1
                    : 0
                : null,
            is_review_active: this.state.reviewStatus
                ? this.state.activeReview
                    ? 1
                    : 0
                : null,
            is_review_published: this.state.publicationStatus
                ? this.state.published
                    ? 1
                    : 0
                : null,
            is_checked_content: this.state.contentStatus
                ? this.state.checkedContent
                    ? 1
                    : 0
                : null,
            is_commented_review: this.state.reviewComment
                ? this.state.commentedReview
                    ? 1
                    : 0
                : null,
            source: this.state.hasSource ? this.state.source : null,
            team: this.state.teamAssignment ? this.state.team : null,
            listing: this.state.listingType ? this.state.listing : null,
            online: this.state.onlineStatus
                ? this.state.online
                    ? 1
                    : 0
                : null,
            inquiry_source: this.state.inquirySource
                ? this.state.inquiry_source
                : null,
            advisor_id: this.state.oaiTeamMember ? this.state.advisor_id : null,
            advisorTeam: this.state.oaiTeam ? this.state.advisorTeam : null,
            listing_type: this.state.listingType
                ? this.state.listing_type
                : null,
            response: this.state.responseStatus ? this.props.response : null,
            program: this.state.directoryStatus ? this.state.program : null,
            paidProgram: this.state.programType ? this.state.paidProgram : null,
            showOAIMatches: this.state.oaiMatches
                ? this.state.showOAIMatches
                : null,
            status: this.state.responseStatus ? this.props.response : null,
            program_directory_id: this.state.directoryStatus
                ? this.state.program_directory_id
                : null,
            is_article_published: this.state.articleStatus
                ? this.state.articlePublished
                    ? 1
                    : 0
                : null,
            providerLinkStatus: this.state.providerLink
                ? this.state.providerLinkStatus
                : null,
            programLinkStatus: this.state.programLink
                ? this.state.programLinkStatus
                : null,
            adLinkStatus: this.state.adLink ? this.state.adLinkStatus : null,
            top_pick: this.state.topPickStatus
                ? this.state.top_pick
                    ? 1
                    : 0
                : null,
            preferred_oai: this.state.preferredOAIStatus
                ? this.state.preferred_oai
                    ? 1
                    : 0
                : null,
            is_generic: this.state.genericListingStatus
                ? this.state.is_generic
                    ? 1
                    : 0
                : null,
        };

        return filters;
    };

    getTeamAssignment = () => {
        let teams = [];
        Object.keys(TEAM_ASSIGNMENT).forEach((id) => {
            teams.push(
                <MenuItem data-cy={`team-name-${id}`} value={id}>
                    {TEAM_ASSIGNMENT[id]}
                </MenuItem>
            );
        });
        return teams;
    };

    getProviderLinkStatus = () => {
        let link_status = [];
        Object.keys(PROVIDER_LINK_STATUS).forEach((id) => {
            link_status.push(
                <MenuItem value={id}>{PROVIDER_LINK_STATUS[id]}</MenuItem>
            );
        });
        return link_status;
    };

    getAdvisors = () => {
        let { advisors = [] } = this.props;
        let updatedAdvisors = [];
        if (advisors) {
            advisors.map((member) =>
                updatedAdvisors.push(
                    <MenuItem
                        key={member.employee_id}
                        value={member.employee_id}
                    >
                        {member.employee.firstname}
                    </MenuItem>
                )
            );
        }
        return updatedAdvisors;
    };

    getOAIStatus = () => {
        let status = [];
        Object.keys(OAI_AUTOMATED_STATUS).forEach((id) => {
            status.push(<MenuItem value={id}>{OAI_AUTOMATED_STATUS[id]}</MenuItem>);
        });
        return status;
    };

    getDirectories = () => {
        let directoryList = [];
        let { directories = [] } = this.props;

        if (directories) {
            directories.map((program) =>
                directoryList.push(
                    <MenuItem key={program.id} value={program.id}>
                        {program.name}
                    </MenuItem>
                )
            );
        }

        return directoryList;
    };

    render() {
        const {
            classes,
            loadingData,
            onApplyFilter = () => {},
            onLoadAdvisors,
            response,
        } = this.props;

        const {
            team,
            listing,
            source,
            paid,
            status,
            activeReview,
            published,
            checkedContent,
            commentedReview,
            accountType,
            accountStatus,
            onlineStatus,
            online,
            reviewStatus,
            publicationStatus,
            contentStatus,
            reviewComment,
            hasSource,
            teamAssignment,
            listingType,
            inquirySource,
            inquiry_source,
            oaiTeamMember,
            advisor_id,
            oaiTeam,
            advisorTeam,
            responseStatus,
            directoryStatus,
            programType,
            paidProgram,
            showOAIMatches,
            program_directory_id,
            articlePublished,
            articleStatus,
            providerLink,
            programLink,
            providerLinkStatus,
            programLinkStatus,
            adLink,
            adLinkStatus,
            top_pick,
            topPickStatus,
            preferred_oai,
            preferredOAIStatus,
            is_generic,
            genericListingStatus,
        } = this.state;

        return (
            <Drawer
                id="global-drawer"
                anchor="bottom"
                open={this.props.open}
                onClose={this.handleClose}
            >
                <div className={classes.root}>
                    {this.props.accountType && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="account-type"
                                    control={
                                        <Checkbox
                                            id="account-type"
                                            color="primary"
                                            checked={accountType}
                                            value="accountType"
                                            onChange={this.handleCheckboxChange(
                                                'accountType'
                                            )}
                                        />
                                    }
                                    label="Account Type"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    id="paid-free-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!accountType}
                                            checked={paid}
                                            onChange={this.handleCheckboxChange(
                                                'paid'
                                            )}
                                        />
                                    }
                                    label={paid ? 'Paid' : 'Free'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.oaiMatches && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="program-matches"
                                    control={
                                        <Checkbox
                                            id="program-matches"
                                            color="primary"
                                            checked={showOAIMatches}
                                            value="showOAIMatches"
                                            onChange={this.handleCheckboxChange(
                                                'showOAIMatches'
                                            )}
                                        />
                                    }
                                    label="Show Programs with OAI Matches"
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.programType && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="program-type"
                                    control={
                                        <Checkbox
                                            id="program-type"
                                            color="primary"
                                            checked={programType}
                                            value="programType"
                                            onChange={this.handleCheckboxChange(
                                                'programType'
                                            )}
                                        />
                                    }
                                    label="Paid Program"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    id="paid-free-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!programType}
                                            checked={paidProgram}
                                            onChange={this.handleCheckboxChange(
                                                'paidProgram'
                                            )}
                                        />
                                    }
                                    label={paidProgram ? 'Paid' : 'Free'}
                                />
                            </FormGroup>
                        </div>
                    )}

                    {this.props.accountStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="account-status"
                                    control={
                                        <Checkbox
                                            data-cy="account-status"
                                            color="primary"
                                            checked={accountStatus}
                                            onChange={this.handleCheckboxChange(
                                                'accountStatus'
                                            )}
                                        />
                                    }
                                    label="Account Status"
                                />

                                <FormControlLabel
                                    id="active-inactive-option"
                                    control={
                                        <Switch
                                            data-cy="update-value-account-status"
                                            color="primary"
                                            disabled={!accountStatus}
                                            checked={status}
                                            onChange={this.handleCheckboxChange(
                                                'status'
                                            )}
                                        />
                                    }
                                    label={status ? 'Active' : 'Inactive'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.reviewStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="review-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={reviewStatus}
                                            onChange={this.handleCheckboxChange(
                                                'reviewStatus'
                                            )}
                                        />
                                    }
                                    label="Review Status"
                                />

                                <FormControlLabel
                                    id="active-inactive-review-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!reviewStatus}
                                            checked={activeReview}
                                            onChange={this.handleCheckboxChange(
                                                'activeReview'
                                            )}
                                        />
                                    }
                                    label={activeReview ? 'Active' : 'Inactive'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.source && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="source-checkbox"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={hasSource}
                                            onChange={this.handleCheckboxChange(
                                                'hasSource'
                                            )}
                                        />
                                    }
                                    label="Source"
                                />
                            </FormGroup>
                            <FormControl disabled={!hasSource}>
                                <Select
                                    id="source-select"
                                    value={source}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'source',
                                        id: 'source',
                                    }}
                                >
                                    <MenuItem value="goabroad">
                                        GoAbroad
                                    </MenuItem>
                                    <MenuItem value="goevaluator">
                                        GoEvaluator
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {this.props.reviewComment && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="review-comment"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={reviewComment}
                                            onChange={this.handleCheckboxChange(
                                                'reviewComment'
                                            )}
                                        />
                                    }
                                    label="Review Comment"
                                />

                                <FormControlLabel
                                    id="yes-no-option-review"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!reviewComment}
                                            checked={commentedReview}
                                            onChange={this.handleCheckboxChange(
                                                'commentedReview'
                                            )}
                                        />
                                    }
                                    label={commentedReview ? 'Yes' : 'No'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.publicationStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="publication-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={publicationStatus}
                                            onChange={this.handleCheckboxChange(
                                                'publicationStatus'
                                            )}
                                        />
                                    }
                                    label="Publication Status"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    id="published-unpublished-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!publicationStatus}
                                            checked={published}
                                            onChange={this.handleCheckboxChange(
                                                'published'
                                            )}
                                        />
                                    }
                                    label={
                                        published ? 'Published' : 'Unpublished'
                                    }
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.contentStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="content-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={contentStatus}
                                            onChange={this.handleCheckboxChange(
                                                'contentStatus'
                                            )}
                                        />
                                    }
                                    label="Content Status"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    id="checked-notChecked-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!contentStatus}
                                            checked={checkedContent}
                                            onChange={this.handleCheckboxChange(
                                                'checkedContent'
                                            )}
                                        />
                                    }
                                    label={
                                        checkedContent
                                            ? 'Checked'
                                            : 'Not Checked'
                                    }
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.teamAssignment && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="team-assignment"
                                    control={
                                        <Checkbox
                                            data-cy="team-assignment-checkbox"
                                            color="primary"
                                            checked={teamAssignment}
                                            onChange={this.handleCheckboxChange(
                                                'teamAssignment'
                                            )}
                                        />
                                    }
                                    label="Team Assignment"
                                />
                            </FormGroup>
                            <FormControl disabled={!teamAssignment}>
                                <InputLabel>Team Name</InputLabel>
                                <Select
                                    data-cy="team-name"
                                    className={classes.teamDropdown}
                                    value={team}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'team',
                                        id: 'team',
                                    }}
                                >
                                    {this.getTeamAssignment()}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {this.props.preferredOAIStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="preferred-oai-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={preferredOAIStatus}
                                            onChange={this.handleCheckboxChange(
                                                'preferredOAIStatus'
                                            )}
                                        />
                                    }
                                    label="Preferred OAI Match"
                                />
                                <FormControlLabel
                                    id="preferredoai-non-preferredoai-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!preferredOAIStatus}
                                            checked={preferred_oai}
                                            onChange={this.handleCheckboxChange(
                                                'preferred_oai'
                                            )}
                                        />
                                    }
                                    label={preferred_oai ? 'Yes' : 'No'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.providerLink && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="provider-link-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={providerLink}
                                            onChange={this.handleCheckboxChange(
                                                'providerLink'
                                            )}
                                        />
                                    }
                                    label="Provider Link Status"
                                />
                            </FormGroup>
                            <FormControl disabled={!providerLink}>
                                <InputLabel>Provider Link Status</InputLabel>
                                <Select
                                    className={classes.teamDropdown}
                                    value={providerLinkStatus}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'providerLinkStatus',
                                        id: 'provider-link',
                                    }}
                                >
                                    {this.getProviderLinkStatus()}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {this.props.programLink && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="provider-link-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={programLink}
                                            onChange={this.handleCheckboxChange(
                                                'programLink'
                                            )}
                                        />
                                    }
                                    label="Program Link Status"
                                />
                            </FormGroup>
                            <FormControl disabled={!programLink}>
                                <InputLabel>Program Link Status</InputLabel>
                                <Select
                                    className={classes.teamDropdown}
                                    value={programLinkStatus}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'programLinkStatus',
                                        id: 'provider-link',
                                    }}
                                >
                                    {this.getProviderLinkStatus()}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {this.props.adLink && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="provider-link-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={adLink}
                                            onChange={this.handleCheckboxChange(
                                                'adLink'
                                            )}
                                        />
                                    }
                                    label="Ad Link Status"
                                />
                            </FormGroup>
                            <FormControl disabled={!adLink}>
                                <InputLabel>Ad Link Status</InputLabel>
                                <Select
                                    className={classes.teamDropdown}
                                    value={adLinkStatus}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'adLinkStatus',
                                        id: 'ad-link',
                                    }}
                                >
                                    {this.getProviderLinkStatus()}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {this.props.listingType && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="listing-type"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={listingType}
                                            onChange={this.handleCheckboxChange(
                                                'listingType'
                                            )}
                                        />
                                    }
                                    label="Listing Type"
                                />
                            </FormGroup>
                            <FormControl disabled={!listingType}>
                                <InputLabel>Listing Type</InputLabel>
                                <Select
                                    className={classes.listingTypeDropdown}
                                    value={listing}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'listing',
                                        id: 'listing',
                                    }}
                                >
                                    <MenuItem value="paid">Linked</MenuItem>
                                    <MenuItem value="free">Free</MenuItem>
                                    <MenuItem value="hot_jobs">
                                        Hot Jobs
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {this.props.onlineStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="online-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={onlineStatus}
                                            onChange={this.handleCheckboxChange(
                                                'onlineStatus'
                                            )}
                                        />
                                    }
                                    label="Online Status"
                                />

                                <FormControlLabel
                                    id="online-non-online-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!onlineStatus}
                                            checked={online}
                                            onChange={this.handleCheckboxChange(
                                                'online'
                                            )}
                                        />
                                    }
                                    label={online ? 'Online' : 'Not Online'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.inquirySource && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="inquiry-source"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={inquirySource}
                                            onChange={this.handleCheckboxChange(
                                                'inquirySource'
                                            )}
                                        />
                                    }
                                    label="Inquiry Source"
                                />
                            </FormGroup>
                            <FormControl disabled={!inquirySource}>
                                <InputLabel>Inquiry Source</InputLabel>
                                <Select
                                    className={classes.teamDropdown}
                                    value={inquiry_source}
                                    autoWidth={true}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'inquiry_source',
                                        id: 'inquiry_source',
                                    }}
                                >
                                    <MenuItem value="0">Provider</MenuItem>
                                    <MenuItem value="1">Program</MenuItem>
                                    <MenuItem value="2">
                                        Participant Matches
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}

                    {this.props.oaiTeam && (
                        <div className={classes.wrapper}>
                            <FormGroup column>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={oaiTeam}
                                            onChange={this.handleCheckboxChange(
                                                'oaiTeam'
                                            )}
                                        />
                                    }
                                    label="OAI Team"
                                />
                                <FormControl disabled={!oaiTeam}>
                                    {/* <InputLabel>OAI Team</InputLabel> */}
                                    <Select
                                        className={classes.teamDropdown}
                                        value={advisorTeam}
                                        autoWidth={true}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'advisorTeam',
                                            id: 'advisorTeam',
                                        }}
                                    >
                                        <MenuItem value="9">Team 1</MenuItem>
                                        <MenuItem value="10">Team 2</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    )}
                    {this.props.oaiTeamMember && (
                        <div className={classes.wrapper}>
                            <FormGroup column>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={oaiTeamMember}
                                            onChange={this.handleCheckboxChange(
                                                'oaiTeamMember'
                                            )}
                                        />
                                    }
                                    label="OAI Team Member"
                                />
                                <FormControl disabled={!oaiTeamMember}>
                                    <Select
                                        className={classes.teamDropdown}
                                        value={advisor_id}
                                        autoWidth={true}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'advisor_id',
                                            id: 'advisor_id',
                                        }}
                                    >
                                        {!onLoadAdvisors && this.getAdvisors()}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    )}
                    {this.props.responseStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup column>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={responseStatus}
                                            onChange={this.handleCheckboxChange(
                                                'responseStatus'
                                            )}
                                        />
                                    }
                                    label="Response Status"
                                />
                                <FormControl disabled={!responseStatus}>
                                    <Select
                                        className={classes.teamDropdown}
                                        value={response}
                                        autoWidth={true}
                                        onChange={this.handleOAIStatusChange}
                                        inputProps={{
                                            name: 'response',
                                            id: 'response',
                                        }}
                                    >
                                        {this.getOAIStatus()}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    )}
                    {this.props.hasDirectories && (
                        <div className={classes.wrapper}>
                            <FormGroup column>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={directoryStatus}
                                            onChange={this.handleCheckboxChange(
                                                'directoryStatus'
                                            )}
                                        />
                                    }
                                    label="Directory"
                                />
                                <FormControl disabled={!directoryStatus}>
                                    <Select
                                        className={classes.teamDropdown}
                                        value={program_directory_id}
                                        autoWidth={true}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'program_directory_id',
                                            id: 'program_directory_id',
                                        }}
                                    >
                                        {this.getDirectories()}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    )}
                    {this.props.articleStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    data-cy="publication-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={articleStatus}
                                            onChange={this.handleCheckboxChange(
                                                'articleStatus'
                                            )}
                                        />
                                    }
                                    label="Publication Status"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!articleStatus}
                                            checked={articlePublished}
                                            onChange={this.handleCheckboxChange(
                                                'articlePublished'
                                            )}
                                        />
                                    }
                                    label={
                                        articlePublished
                                            ? 'Published'
                                            : 'Unpublished'
                                    }
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.topPickStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="top-pick-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={topPickStatus}
                                            onChange={this.handleCheckboxChange(
                                                'topPickStatus'
                                            )}
                                        />
                                    }
                                    label="GoAbroad Pick"
                                />

                                <FormControlLabel
                                    id="toppick-non-toppick-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!topPickStatus}
                                            checked={top_pick}
                                            onChange={this.handleCheckboxChange(
                                                'top_pick'
                                            )}
                                        />
                                    }
                                    label={top_pick ? 'Yes' : 'No'}
                                />
                            </FormGroup>
                        </div>
                    )}
                    {this.props.genericListingStatus && (
                        <div className={classes.wrapper}>
                            <FormGroup row>
                                <FormControlLabel
                                    id="generic-listing-status"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={genericListingStatus}
                                            onChange={this.handleCheckboxChange(
                                                'genericListingStatus'
                                            )}
                                        />
                                    }
                                    label="Generic Listings"
                                />

                                <FormControlLabel
                                    id="generic-non-generic-option"
                                    control={
                                        <Switch
                                            color="primary"
                                            disabled={!genericListingStatus}
                                            checked={is_generic}
                                            onChange={this.handleCheckboxChange(
                                                'is_generic'
                                            )}
                                        />
                                    }
                                    label={is_generic ? 'Yes' : 'No'}
                                />
                            </FormGroup>
                        </div>
                    )}

                    <div id="closeButton" className={classes.globalFilterCTA}>
                        <Button
                            data-cy="apply-global-filter"
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => onApplyFilter(this.mapFilters())}
                            disabled={loadingData}
                        >
                            Apply
                        </Button>
                        <Button
                            data-cy="clear-global-filter"
                            id="clear-button"
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={this.handleClearFilter}
                            disabled={loadingData}
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    }
}

GlobalFilterDrawer.defaultProps = {
    accountStatus: true,
    teamAssignment: true,
    open: false,
    loadingData: false,
};

GlobalFilterDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    accountType: PropTypes.bool,
    accountStatus: PropTypes.bool,
    reviewStatus: PropTypes.bool,
    source: PropTypes.bool,
    publicationStatus: PropTypes.bool,
    contentStatus: PropTypes.bool,
    reviewComment: PropTypes.bool,
    teamAssignment: PropTypes.bool,
    inquirySource: PropTypes.bool,
    loadingData: PropTypes.bool,
    onClose: PropTypes.func,
    onFiltersChange: PropTypes.func,
    onClearFilters: PropTypes.func,
    responseStatus: PropTypes.bool,
    oaiTeamMember: PropTypes.bool,
    oaiTeam: PropTypes.bool,
    response: PropTypes.string,
    providerLink: PropTypes.bool,
    providerLinkStatus: PropTypes.string,
    programLink: PropTypes.bool,
    programLinkStatus: PropTypes.string,
    adLink: PropTypes.bool,
    adLinkStatus: PropTypes.string,
    topPickStatus: PropTypes.bool,
    preferredOAIStatus: PropTypes.bool,
    genericListingStatus: PropTypes.bool,
};

export default withStyles(styles)(GlobalFilterDrawer);
