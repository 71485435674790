import React, { Fragment, useEffect } from 'react';
import FiltersDrawer from './FiltersDrawer';
import DataFilter from './DataFilter';
import DialogBox from '../../../components/DialogBox/DialogBox';
import { useArticlesState, useArticlesMethods } from './ArticlesProvider';
import { useGlobalState } from '../../Client/GlobalProvider';
import ResultsMain from './ResultsMain';

const Articles = (props) => {
    const { active } = props;
    const { dialog } = useArticlesState();
    const { articleExportDialogOpen } = useGlobalState();
    const {
        showExportActiveArticlesDialog,
        showExportContent,
        handleExportActiveArticles,
    } = useArticlesMethods();

    useEffect(() => {
        if (articleExportDialogOpen) {
            showExportActiveArticlesDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articleExportDialogOpen]);

    if (!active) return null;

    return (
        <Fragment>
            <DataFilter />
            <ResultsMain {...props} />
            <FiltersDrawer />
            <DialogBox
                actions="OkCancel"
                title="Export Published Articles"
                open={articleExportDialogOpen}
                onOk={handleExportActiveArticles}
                onCancel={dialog.onCancel}
                onClose={dialog.onCancel}
                stringOverride={dialog.stringOverride}
            >
                {showExportContent()}
            </DialogBox>
            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.onOk}
                onCancel={dialog.onCancel}
                onClose={dialog.onCancel}
                stringOverride={dialog.stringOverride}
                setSelectedRow={() => {}}
            />
        </Fragment>
    );
};

export default Articles;
