import React, { useEffect } from 'react';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import Select from 'react-select';
import SaveIcon from '@material-ui/icons/Save';
import {
    Checkbox,
    FormControlLabel,
    Drawer,
    Paper,
    Button,
    Typography,
    TextField
} from '@material-ui/core';
import { useArticlesState, useArticlesMethods } from './ArticlesProvider';
import { makeStyles } from '@material-ui/styles';
import {
    useAuthenticatedRequest,
    useGlobalState
} from '../../Client/GlobalProvider';
import {
    getCriteriaByDirectory,
    CRITERIA_PER_DIRECTORY,
    SEARCH_CRITERIA
} from '../../../utilities/searchCriteria';
import { format } from 'date-fns';
import { DIRECTORIES } from '../../../utilities/directory';
import { buildFormData } from '../../../helpers/dataUtility';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'node-forge/lib/util';
import { ERROR_CODES } from '../../../config/constants';
import { useObjectState } from '../../../utilities/customHooks';

const INNERTABWIDTH = 420;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: `${INNERTABWIDTH}px`
    },
    drawerTitle: {
        color: '#555',
        textAlign: 'center',
        fontSize: '1.5em',
        padding: '12px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
    paper: {
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 2px 1px -1px rgba(0, 0, 0, 0)',
        padding: theme.spacing(2),
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    filter: {
        fontFamily: 'Roboto',
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    label: {
        color: '#9e9e9e',
        fontSize: 11,
        textTransform: 'uppercase',
        lineHeight: 2.66,
        marginBottom: theme.spacing.unit * 0.5
    },
    articleTitle: {
        width: '100%'
    },
    create: {
        width: '100%',
        marginTop: theme.spacing(2),
        '& svg': {
            fontSize: 18,
            marginRight: theme.spacing.unit
        },
        '& button': {
            width: '100%',
            padding: `${theme.spacing(1)}px 0`
        }
    }
}));

const ArticleDrawer = (props) => {
    const { locations, reassign, selectedArticle } = useArticlesState();

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { articleDrawerOpen, handleStateChange: setGlobalState } =
        useGlobalState();
    const { postRequest } = useAuthenticatedRequest();

    const { fetchOptions, setArticlesState, fetchArticles, fetchLocations } =
        useArticlesMethods();

    const [options, setOptions] = useObjectState({});
    const [values, setValues] = useObjectState({
        directory: null,
        title: ''
    });

    const {
        locationOptions = [],
        directories = [],
        types,
        timings,
        typeLabel,
        timingLabel,
        hasType,
        hasTiming
    } = options;

    const { title, directory, location, type, timing, online } = values;
    const hasOnline =
        directory &&
            directory.value &&
            DIRECTORIES.filter((i) => {
                return i.id === directory.value;
            })[0].has_online === 1
            ? true
            : false;

    const handleFieldChange = (field) => (value) =>
        setValues({ ...values, [field]: value });

    const handleTitleChange = (e) => handleFieldChange('title')(e.target.value);

    const handleOnlineChange = (e) =>
        handleFieldChange('online')(e.target.checked ? 1 : 0);

    const handleCreateArticle = () => {
        const url = '/articles';
        const data = new FormData();

        data.append('title', title);
        data.append('date_created', format(new Date(), 'YYYY-MM-DD HH:mm:ss'));
        data.append('date_modified', format(new Date(), 'YYYY-MM-DD HH:mm:ss'));
        data.append('content', '');

        if (directory) {
            data.append('directory_id', directory.value);
        }

        if (!isEmpty(location)) {
            [
                'world_region_id',
                'country_id',
                'main_region_id',
                'city_id'
            ].forEach((i) => {
                let bin = SEARCH_CRITERIA[i].bin_value;

                if (location.value.startsWith(bin + '_')) {
                    let value = location.value.replace(bin + '_', '');
                    data.append(i, value);
                    if (i === 'city_id' || i === 'main_region_id') {
                        const obj = locations.data.filter((obj) => {
                            return obj.id === location.value;
                        })[0];

                        const country_id =
                            obj.search_criteria[0].criteria_value;
                        data.append('country_id', country_id);
                    }
                }
            });
        }

        if (!isEmpty(type)) {
            const typeCriteria = CRITERIA_PER_DIRECTORY[directory.value].type;
            data.append(typeCriteria, type.value);
        }

        if (!isEmpty(timing)) {
            const timingCriteria =
                CRITERIA_PER_DIRECTORY[directory.value].timing;
            data.append(timingCriteria, timing.value);
        }

        if (online) {
            data.append('online', 1);
        }

        postRequest(
            url,
            data,
            (json) => {
                setGlobalState({ articleDrawerOpen: false });
                props.history.push(`/articles/edit/${json.id}`);
            },
            ({ code }) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to create a new article.', {
                        variant: 'error'
                    });
            },
            handleCreateArticle
        );
    };

    const fetchSearchCriteria = async ({ value: directory_id }) => {
        const { [directory_id]: available } = getCriteriaByDirectory({
            key: 'id',
            val: [directory_id]
        });
        const criterias = Object.keys(available);
        const newState = {};

        if (criterias.includes('timing') && !!available.timing) {
            const { new_criteria, name } = available.timing;
            newState.timingCriteria = new_criteria;
            newState.hasTiming = true;
            newState.timingLabel = name;
            newState.timings = await fetchOptions(new_criteria);
        } else {
            newState.timingCriteria = null;
            newState.hasTiming = false;
            newState.timingLabel = '';
            newState.timings = [];
        }

        if (criterias.includes('type') && !!available.type) {
            const { new_criteria, name } = available.type;
            newState.typeCriteria = new_criteria;
            newState.hasType = true;
            newState.typeLabel = name;
            newState.types = await fetchOptions(new_criteria);
        } else {
            newState.typeCriteria = null;
            newState.hasType = false;
            newState.typeLabel = '';
            newState.types = [];
        }

        setOptions({ ...newState });

        return newState;
    };

    const handleDirectoryChange = async (directory = null) => {
        setValues({
            hasType: false,
            hasTiming: false,
            directory,
            timing: null,
            type: null,
            location,
            title,
            online: 0
        });
        await fetchSearchCriteria(directory);
    };

    const getReassignParams = () => {
        const { location, type, timing, directory, online } = values;
        let params = {};

        if (!isEmpty(directory)) {
            params = { directory_id: directory.value };
            if (!isEmpty(type)) {
                const typeCriteria =
                    CRITERIA_PER_DIRECTORY[directory.value].type;
                params = { ...params, [typeCriteria]: type.value };
            }
            if (!isEmpty(timing)) {
                const timingCriteria =
                    CRITERIA_PER_DIRECTORY[directory.value].timing;
                params = {
                    ...params,
                    [timingCriteria]: timing.value
                };
            }
        }

        // location params
        if (!isEmpty(location)) {
            [
                'world_region_id',
                'country_id',
                'main_region_id',
                'city_id'
            ].forEach((i) => {
                let bin = SEARCH_CRITERIA[i].bin_value;
                if (location.value.startsWith(bin + '_')) {
                    let value = location.value.replace(bin + '_', '');
                    params = { ...params, [i]: value };

                    // add country_id to city or main_region
                    if (i === 'city_id' || i === 'main_region_id') {
                        const obj = locations.data.filter((obj) => {
                            return obj.id === location.value;
                        })[0];
                        const country_id =
                            obj.search_criteria[0].criteria_value;
                        params = { ...params, country_id };
                    }
                }
            });
        }

        if (online) {
            params = { ...params, online: 1 };
        }

        return params;
    };

    const handleReassignArticle = () => {
        const { id } = selectedArticle;
        const params = getReassignParams();

        const form = new FormData();
        buildFormData(form, params);

        postRequest(
            `/articles/${id}/reassign`,
            form,
            (success) => {
                setGlobalState({ articleDrawerOpen: false });
                setArticlesState(
                    {
                        selectedArticle: null,
                        reassign: false,
                        fetchingArticles: true
                    },
                    fetchArticles
                );
                enqueueSnackbar('Article have been reassigned!', {
                    variant: 'success'
                });
            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to reassign article.', {
                        variant: 'error'
                    });
            },
            handleReassignArticle
        );
    };

    useEffect(() => {
        setValues({
            hasType: false,
            hasTiming: false,
            directory: { label: 'GoAbroad', value: 10 },
            timing: null,
            type: null,
            location: null,
            title: '',
            online: 0
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articleDrawerOpen]);

    useEffect(() => {
        if (!selectedArticle) {
            return;
        }

        const prepopulateDrawer = async () => {
            const { search_criteria } = selectedArticle;
            const directoryObj =
                !!search_criteria &&
                search_criteria.filter((item) => {
                    return item.criteria === 'directory_id';
                })[0];

            if (!isEmpty(directoryObj)) {
                const { value: directory_id } = directoryObj;
                const directory = directories.filter((item) => {
                    return directory_id === item.value;
                })[0];
                const newOptions = await fetchSearchCriteria({
                    value: directory_id
                });
                const criteria = CRITERIA_PER_DIRECTORY[directory_id];
                const typeObj =
                    !!criteria.type &&
                    search_criteria.filter((item) => {
                        return criteria.type === item.criteria;
                    })[0];
                const timingObj =
                    !!criteria.timing &&
                    search_criteria.filter((item) => {
                        return criteria.timing === item.criteria;
                    })[0];
                const hasType = !!typeObj;
                const hasTiming = !!timingObj;
                const type =
                    hasType &&
                    newOptions.types.filter((item) => {
                        return typeObj.value === item.value;
                    })[0];
                const timing =
                    hasTiming &&
                    newOptions.timings.filter((item) => {
                        return timingObj.value === item.value;
                    })[0];

                const locationObj = search_criteria.filter((item) => {
                    return [
                        'country_id',
                        'city_id',
                        'main_region_id',
                        'world_region_id'
                    ].includes(item.criteria);
                });
                let location = null;
                locationObj.forEach((item) => {
                    const id =
                        SEARCH_CRITERIA[item.criteria].bin_value +
                        '_' +
                        item.value;
                    location = locations.data
                        .filter((item) => {
                            return item.id === id;
                        })
                        .map((item) => {
                            return { value: item.id, label: item.name };
                        })[0];
                    if (
                        item.criteria === 'city_id' ||
                        item.criteria === 'main_region_id'
                    ) {
                        return;
                    }
                });

                const onlineObj = search_criteria.filter((item) => {
                    return item.criteria === 'online';
                });

                const online = onlineObj[0] && onlineObj[0].value;

                setValues({
                    hasType,
                    hasTiming,
                    directory,
                    timing,
                    type,
                    location,
                    online
                });
            }
        };

        prepopulateDrawer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedArticle]);

    useEffect(() => {
        const fetching = async () => {
            fetchLocations();

            const directories = DIRECTORIES.filter((o) => {
                return [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 19].includes(o.id);
            })
                .sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                })
                .map((directory) => {
                    return { value: directory.id, label: directory.name };
                });
            setOptions({
                directories
            });
        };
        fetching();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOptions({
            locationOptions: [...(locations.data || [])].sort((a, b) => {
                const [criteriaA] = a.id.split('_');
                const [criteriaB] = b.id.split('_');
                if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
                if (parseInt(criteriaA) < parseInt(criteriaB)) return -1;
                if (a.name > b.name) return 1;
                if (b.name < a.name) return -1;
                return 0;
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    return (
        <Drawer
            anchor="right"
            open={articleDrawerOpen}
            onClose={() => {
                setGlobalState({ articleDrawerOpen: false });
                setArticlesState({ reassign: false });
            }}
            BackdropProps={{
                invisible: false
            }}
        >
            <div className={classes.root}>
                <Paper className={classes.paper} data-cy="article-drawer">
                    <Typography className={classes.drawerTitle}>
                        {reassign ? 'Reassign' : 'Add'} Article
                    </Typography>
                    <form>
                        <div className={classes.filter}>
                            <Typography className={classes.label}>
                                Title
                            </Typography>
                            {reassign ? (
                                <Typography>
                                    {!!selectedArticle && selectedArticle.title}
                                </Typography>
                            ) : (
                                <TextField
                                    data-cy="text-title"
                                    className={classes.articleTitle}
                                    id="article-title"
                                    variant="outlined"
                                    size="small"
                                    value={title}
                                    onChange={handleTitleChange}
                                    autoFocus
                                    multiline
                                    required
                                />
                            )}
                        </div>

                        <div className={classes.filter}>
                            <Typography className={classes.label}>
                                Directory
                            </Typography>
                            <Select
                                id="directory-div"
                                options={directories}
                                placeholder="Directory"
                                value={directory}
                                onChange={handleDirectoryChange}
                                isSearchable
                            />
                        </div>

                        <div className={classes.filter}>
                            <Typography className={classes.label}>
                                Location
                            </Typography>
                            <SearchBasedDropdown
                                id="location-div"
                                placeholder="Location"
                                arrayOptions={locationOptions}
                                valueKey="id"
                                labelKey="name"
                                value={location}
                                handleChange={handleFieldChange('location')}
                                isClearable
                                isDisabled={!!online}
                            />
                        </div>

                        <div className={classes.filter}>
                            <Typography className={classes.label}>
                                {typeLabel || 'Type'}
                            </Typography>
                            <Select
                                options={types}
                                placeholder={typeLabel ? typeLabel : 'Type'}
                                value={type}
                                onChange={handleFieldChange('type')}
                                isDisabled={!hasType}
                                isSearchable
                                isClearable
                                id={!hasType ? "type-dropdown" : `${typeLabel?.toLowerCase().replaceAll(' ', '-')}-dropdown`}
                            />
                        </div>

                        <div className={classes.filter}>
                            <Typography className={classes.label}>
                                {timingLabel || 'Timing'}
                            </Typography>
                            <Select
                                options={timings}
                                placeholder={
                                    timingLabel ? timingLabel : 'Timing'
                                }
                                value={timing}
                                onChange={handleFieldChange('timing')}
                                isDisabled={!hasTiming}
                                isSearchable
                                isClearable
                                id={!hasTiming ? "timing-dropdown" : `${timingLabel?.toLowerCase().replaceAll(' ', '-')}-dropdown`}
                            />
                        </div>

                        {hasOnline && (
                            <div className={classes.filter}>
                                <FormControlLabel
                                    data-cy={`checkbox-disabled-is-${!!location}`}
                                    control={
                                        <Checkbox
                                            checked={online}
                                            onChange={handleOnlineChange}
                                            disabled={!!location}
                                        />
                                    }
                                    label="Online"
                                />
                            </div>
                        )}
                    </form>
                </Paper>
                <div className={classes.create}>
                    <Button
                        data-cy="btn-create-article-2"
                        variant="contained"
                        color="primary"
                        disabled={!reassign && title === ''}
                        onClick={
                            reassign
                                ? handleReassignArticle
                                : handleCreateArticle
                        }
                    >
                        <SaveIcon
                            color="white"
                            className={classes.extendedIcon}
                        />
                        {reassign ? 'Save' : 'Create Article'}
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default ArticleDrawer;
