import React, { Component } from 'react';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import {
    Button,
    Checkbox,
    Drawer,
    FormControlLabel,
    Paper,
    Typography,
    withStyles
} from '@material-ui/core';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import getCriteriaByDirectory from '../../../utilities/searchCriteria';
import { search_criteria_fields } from '../../../config/constants';

const TABWIDTH = 420;

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',

        '& > div:not(:last-of-type)': {
            marginBottom: theme.spacing(4)
        }
    }
});

class FiltersDrawer extends Component {
    state = {
        filterBuffer: {
            directory_filter: 'Directory',
            location_filter: 'Location',
            timing_filter: 'Timing',
            type_filter: 'Type'
        },
        loading: true,
        directories: [],
        regions: [],
        countries: [],
        types: [],
        timing: [],
        hasType: false,
        hasTiming: false,
        online: [],
        isOnline: false
    };

    componentDidMount() {
        //fetch directories, country, regions and online directories
        Promise.all([
            new Promise((resolve, reject) => {
                sendRequest(
                    '/directories?fields=id,name,alias,has_online',
                    resolve,
                    reject
                );
            }),
            new Promise((resolve, reject) => {
                sendRequest(
                    '/searchable-options?criteria=world_region',
                    resolve,
                    reject
                );
            }),
            new Promise((resolve, reject) => {
                sendRequest(
                    '/searchable-options?criteria=country&sort=+name',
                    resolve,
                    reject
                );
            }),
            new Promise((resolve, reject) => {
                sendRequest(
                    '/searchable-options?criteria=main_region',
                    resolve,
                    reject
                );
            }),
            new Promise((resolve, reject) => {
                sendRequest(
                    '/searchable-options?criteria=city',
                    resolve,
                    reject
                );
            })
        ]).then((response) => {
            this.setState({
                directories: response[0].directories,
                regions: response[1].searchable_options,
                countries: response[2].searchable_options,
                main_regions: response[3].searchable_options,
                cities: response[4].searchable_options,
                online: response[0].directories
                    .filter(({ has_online }) => has_online === 1)
                    .map(({ id }) => id),
                loading: false
            });
        });
    }

    buildLocations = () => {
        const { regions, countries, main_regions, cities } = this.state;
        let locations = [];

        !!regions &&
            regions.forEach((item) => {
                locations.push({ id: '16384_' + item.id, name: item.name });
            });
        !!countries &&
            countries.forEach((item) => {
                locations.push({ id: '2_' + item.id, name: item.name });
            });
        !!cities &&
            cities.forEach((item) => {
                const country =
                    countries.filter((x) => x.id === item.country_id)[0] || {};
                locations.push({
                    id: '16_' + item.id + '_2_' + country.id,
                    name: `${item.name}, ${country.name}`
                });
            });
        if (!!main_regions && !!cities) {
            const filtered_main_regions = main_regions.filter((main_region) => {
                return !cities.find((city) => {
                    return main_region.name === city.name;
                });
            });
            filtered_main_regions.forEach((item) => {
                const country = countries.filter(
                    (x) => x.id === item.country_id
                )[0];
                locations.push({
                    id: '8_' + item.id + '_2_' + country.id,
                    name: `${item.name}, ${country.name}`
                });
            });
        }
        
        return locations.sort((a, b) => {
            const [criteriaA] = a.id.split('_');
            const [criteriaB] = b.id.split('_');

            if (parseInt(criteriaA) === 16384 || (parseInt(criteriaB) === 16384)) {
                if (parseInt(criteriaA) === 16384 && (parseInt(criteriaB) === 16384)) {
                    if (b.name > a.name) return -1;
                    if (a.name < b.name) return 1;
                    return 0;
                }
                return 1;
            };
            
            if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
            if (parseInt(criteriaA) < parseInt(criteriaB)) return -1;
            if (b.name > a.name) return -1;
            if (a.name < b.name) return 1;
            
            return 0;
        })


    };

    fetchTypes = async ({ value: directoryId }) => {
        const { [directoryId]: { timing, type } = {} } = getCriteriaByDirectory(
            {
                key: 'id',
                val: [directoryId]
            },
            ['new_criteria', 'bin_value']
        );

        const { new_criteria: type_criteria } = !!type ? type : {};
        const { new_criteria: timing_criteria } = !!timing ? timing : {};

        this.setState({ loading: true });
        let type_options = !!type
            ? await sendRequest(
                  `/searchable-options?criteria=${type_criteria}`,
                  ({ searchable_options } = {}) => searchable_options || {}
              )
            : [];

        type_options = type_options.map((option) => {
            option['search_criteria'] = {
                criteria_id: type.bin_value,
                criteria_value: option.id
            };
            return option;
        });

        let timing_options = !!timing
            ? await sendRequest(
                  `/searchable-options?criteria=${timing_criteria}`,
                  ({ searchable_options } = {}) => searchable_options || {}
              )
            : [];

        timing_options = timing_options.map((option) => {
            option['search_criteria'] = {
                criteria_id: timing.bin_value,
                criteria_value: option.id
            };
            return option;
        });

        this.setState({
            loading: false,
            types: type_options,
            timing: timing_options,
            hasType: !!type_criteria,
            hasTiming: !!timing_criteria,
            isOnline: null,
            type_filter: null,
            timing_filter: null
        });
    };

    handleAddFilter = (filter) => (value) => {
        const { filterBuffer } = this.state;
        const { type_filter, timing_filter } = filterBuffer || {};

        if (filter === 'directory_filter') {
            this.fetchTypes(value || {});
        }

        this.setState({
            filterBuffer: {
                ...filterBuffer,
                type_filter: filter === 'directory_filter' ? null : type_filter,
                timing_filter:
                    filter === 'directory_filter' ? null : timing_filter,
                [filter]: value
            }
        });
    };

    handleAddLocationFilter = () => (value) => {
        const { filterBuffer } = this.state;
        this.setState({
            filterBuffer: {
                ...filterBuffer,
                location_filter: value
            }
        });
    };

    normalizeFilters = () => {
        const {
            directories,
            types,
            timing,
            filterBuffer: {
                directory_filter,
                location_filter,
                timing_filter,
                type_filter
            }
        } = this.state;
        const { filterBuffer, isOnline } = this.state;

        let filters = {};

        if (!!directory_filter && typeof directory_filter === 'object') {
            const [directory] = directories.filter(
                ({ id }) => id === directory_filter.value
            );
            filters['directory_filter'] = {
                label: directory.name,
                value: directory.id
            };
        }
        if (!!location_filter && typeof location_filter === 'object') {
            const location = filterBuffer.location_filter;
            const criteria = location.value.split('_')[0];
            const id = location.value.split('_')[1];
            const country_id = location.value.split('_')[3];

            filters['location_filter'] = {
                key: search_criteria_fields[criteria],
                label: location.label,
                value: id,
                country: !!country_id ? country_id : null
            };
        }
        if (!!type_filter && typeof type_filter === 'object') {
            const selectedType = filterBuffer.type_filter;

            const filteredType = types.filter((type) => {
                return type.id === selectedType.value;
            })[0];
            const { search_criteria } = filteredType;

            filters['type_filter'] = {
                label: selectedType.label,
                key: search_criteria_fields[search_criteria.criteria_id],
                value: search_criteria.criteria_value
            };
        }
        if (!!timing_filter && typeof timing_filter === 'object') {
            const selectedTiming = filterBuffer.timing_filter;

            const filteredTiming = timing.filter((timing) => {
                return timing.id === selectedTiming.value;
            })[0];
            const { search_criteria } = filteredTiming;

            filters['timing_filter'] = {
                label: selectedTiming.label,
                key: search_criteria_fields[search_criteria.criteria_id],
                value: search_criteria.criteria_value
            };
        }

        this.props.handleApplyFilters({
            ...filters,
            online: isOnline ? 1 : null
        });
    };

    render() {
        const { classes, filtersDrawerOpen, handleToggleFiltersDrawer } =
            this.props;
        const {
            filterBuffer,
            loading,
            directories,
            types,
            timing,
            hasType,
            hasTiming,
            online,
            isOnline
        } = this.state;
        const {
            directory_filter,
            location_filter,
            type_filter,
            timing_filter
        } = filterBuffer || {};
        const locations = this.buildLocations();
        return (
            <Drawer
                open={filtersDrawerOpen}
                anchor="right"
                onClose={() => handleToggleFiltersDrawer(false)}
            >
                <div className={classes.root}>
                    <Typography className={classes.drawerTitle}>
                        Data Filters
                    </Typography>
                    <Paper className={classes.paper}>
                        <SearchBasedDropdown
                            valueKey="id"
                            labelKey="name"
                            placeholder="Directory"
                            arrayOptions={directories}
                            handleChange={this.handleAddFilter(
                                'directory_filter'
                            )}
                            value={directory_filter}
                            isDisabled={loading}
                            isClearable={true}
                        />
                        <SearchBasedDropdown
                            valueKey="id"
                            labelKey="name"
                            placeholder="Locations"
                            handleChange={this.handleAddLocationFilter()}
                            arrayOptions={locations}
                            value={location_filter}
                            isDisabled={loading}
                            isClearable={true}
                        />
                        {hasType && (
                            <SearchBasedDropdown
                                valueKey="id"
                                labelKey="name"
                                placeholder="Type"
                                handleChange={this.handleAddFilter(
                                    'type_filter'
                                )}
                                arrayOptions={types}
                                value={type_filter || undefined}
                                isDisabled={loading}
                                isClearable={true}
                            />
                        )}
                        {hasTiming && (
                            <SearchBasedDropdown
                                valueKey="id"
                                labelKey="name"
                                placeholder="Timing"
                                handleChange={this.handleAddFilter(
                                    'timing_filter'
                                )}
                                arrayOptions={timing}
                                value={timing_filter || undefined}
                                isDisabled={loading}
                                isClearable={true}
                            />
                        )}

                        {online.includes((directory_filter || {}).value) && (
                            <FormControlLabel
                                label="Online"
                                control={<Checkbox />}
                                onChange={({ target: { checked } }) =>
                                    this.setState({ isOnline: checked })
                                }
                                checked={isOnline}
                            />
                        )}
                    </Paper>
                    <Button
                        className={classes.clear}
                        color="primary"
                        disabled={
                            !(
                                directory_filter &&
                                Object.values(filterBuffer).length > 1
                            )
                        }
                        onClick={this.normalizeFilters}
                        variant="contained"
                    >
                        SEARCH
                    </Button>
                </div>
            </Drawer>
        );
    }
}
export default withStyles(styles)(FiltersDrawer);
