import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import PlacementForm from './PlacementForm';
import MetaForm from './MetaForm';
import { useSEOToolState, useSEOToolMethods } from './SEOToolProvider';
import CustomPlacementForm from './CustomPlacementForm';
import { metaPageTypes, TYPE_CRITERIA, TIMING_CRITERIA, LOCATION_CRITERIA } from '../../../../config/constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { sendRequest } from '../../../../helpers/apiRequestUtility';
import { DIRECTORIES } from '../../../../utilities/directory';
import { SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';
const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: theme.breakpoints.width('lg'),
        margin: 'auto',
        marginBottom: theme.spacing(3),
        padding: `00 ${theme.spacing(4)}px`
    },
    editorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    label: {
        color: '#333',
        fontWeight: 500,
        fontSize: '1.5rem'
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        '& > div:first-child': {
            width: '70%'
        },
        '& > div:last-child': {
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            alignItems: 'flex-end'
        }
    },
    loader: {
        minHeight: '80vh',
        padding: theme.spacing(4)
    },
    circles: {
        display: 'flex',
        '& span': {
            marginRight: theme.spacing(2)
        }
    },
    header: {
        marginBottom: theme.spacing(2)
    }
}));

export default function SEOMetaEditor() {
    const classes = useStyles();
    const params = useParams();
    const { directory, online, isEdit, customPlacements, pageTypes, setSEOToolState } =
        useSEOToolState();
    const { getValueLabel } = useSEOToolMethods();
    const hasCustomPlacements = Object.keys(customPlacements).some(
        (key) => !!customPlacements[key]
    );
    const hasNonLocationTypes = metaPageTypes
        .filter(({ value }) => pageTypes.includes(value))
        .some(({ isLocation }) => !isLocation);
    const customizable = (!online || hasNonLocationTypes) && pageTypes.length > 0;
    const showCustomForm = (isEdit && hasCustomPlacements) || !isEdit;

    const fetchMeta = async () => {
        setSEOToolState({ fetching: true });
        await sendRequest(`/seo-meta/info?id=${params.id}`, (json) => {

            json['seo_meta'].forEach((json) => {
                const {
                    h1,
                    h2,
                    title,
                    description,
                    directory_id,
                    program_results_meta_criteria,
                } = json;

                const pageTypes = [];
                const programCriteria = json["program_results_meta_criteria"]["criteria_value"];

                const onlineData = programCriteria.find((x) => x.criteria === "online" && x.value === 1);

                const dir = DIRECTORIES.find((x) => {
                    return x.id === directory_id
                });
                const value = {
                    label: dir.name,
                    value: dir.id
                }
                const customPlacements = {};
                const criteria = program_results_meta_criteria.criteria_value;
                criteria.forEach((data) => {
                    let searchCrit = SEARCH_CRITERIA[data.criteria].new_criteria;

                    if (LOCATION_CRITERIA.includes(data.criteria)) {
                        pageTypes.push(searchCrit);
                        if (data.value === 0) return;
                        getValueLabel(searchCrit, data.value);

                    }
                    if (TYPE_CRITERIA.includes(data.criteria)) {
                        pageTypes.push('type');
                        if (data.value === 0) return;
                        getValueLabel(searchCrit, data.value);
                    }
                    if (TIMING_CRITERIA.includes(data.criteria)) {
                        pageTypes.push('timing');
                        if (data.value === 0) return;
                        getValueLabel(searchCrit, data.value);
                    }
                });

                setSEOToolState({ directory: value, h1, h2, title, description, pageTypes, customPlacements, online: onlineData ? true : false, fetching: false });
            });
        });
    };

    useEffect(() => {
        params.id && fetchMeta();
        setSEOToolState({ isEdit: !!params.id });
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.paper}>
            <Typography
                variant="h5"
                className={classes.header}
                display="block"
                align="left"
            >
                {directory ? directory.label : 'Directory'}{' '}
                {hasCustomPlacements ? 'Custom' : 'Default'} Meta
            </Typography>
            <Grid container spacing={4}>
                <Grid item md={customizable ? 3 : 4}>
                    <PlacementForm />
                </Grid>
                {customizable && showCustomForm && (
                    <Grid item md={customizable ? 3 : 0}>
                        <CustomPlacementForm />
                    </Grid>
                )}
                <Grid
                    item
                    md={customizable ? 6 : 8}
                    style={{ transition: 'width .3s ease' }}
                >
                    <MetaForm />
                </Grid>
            </Grid>
        </div>
    );
}
