import { FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';

export default function DefaultExtendedFilter({
    value,
    label,
    currentValue,
    onChange = () => {}
}) {
    const handleChange = (e) => {
        onChange(e.target.checked ? value : '');
    };

    return (
        <FormControlLabel
            control={<Radio checked={currentValue === value} onChange={handleChange} />}
            label={label}
            style={{ marginRight: 0 }}
        />
    );
}
