import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import Articles from './Articles/Articles';
import { makeStyles } from '@material-ui/styles';
import ArticlesProvider from './Articles/ArticlesProvider';
import ArticleDrawer from './Articles/ArticleDrawer';
import { SnackbarProvider } from 'notistack';
import Authors from './Authors/Authors';
import Topics from './Topics/Topics';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(2)
    }
}));

const articleTabs = [
    {
        path: '/articles',
        name: 'Articles'
    },
    {
        path: '/authors',
        name: 'Authors'
    },
    {
        path: '/topics',
        name: 'Topics'
    }
];

const ArticleTabs = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [active, setActive] = useState(0);
    const isArticles = useRouteMatch('/articles');
    const isAuthors = useRouteMatch('/authors');
    const isTopics = useRouteMatch('/topics');

    const handleTabChange = (e, tab) => {
        const [{ path }] = articleTabs.filter((i, index) => tab === index);
        history.push(path);
    };

    useEffect(() => {
        const activeTab = articleTabs.findIndex(
            ({ path }) => path === location.pathname
        );
        setActive(activeTab);
    }, [location.pathname]);

    return (
        <SnackbarProvider>
            <ArticlesProvider>
                <Tabs
                    className={classes.root}
                    value={active}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    {articleTabs.map((tab, index) => (
                        <Tab
                            key={`article-tab-${index}`}
                            label={tab.name}
                            disableRipple
                        />
                    ))}
                </Tabs>
                <Articles active={isArticles} {...props} />
                <Authors active={isAuthors} />
                <Topics active={isTopics} />
                <ArticleDrawer {...props} />
            </ArticlesProvider>
        </SnackbarProvider>
    );
};

export default ArticleTabs;
