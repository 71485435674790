import React, { useState } from 'react';
import ResultsTable from '../../components/ResultsTable/ResultsTable';
import { useGlobalState } from '../Client/GlobalProvider';
import { useInterviewState } from './InterviewsProvider';
import { Typography, Tooltip, makeStyles, lighten } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import CustomGlobalFilterDrawer from '../../components/CustomGlobalFilter/CustomGlobalFilterDrawer';
import { useObjectState } from '../../utilities/customHooks';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PeopleIcon from '@material-ui/icons/People';
import DialogBox from '../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { trimSlash } from '../../helpers/dataUtility';
import { GA_URL } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
    unpublished: {
        color: '#fff',
        fontSize: 9,
        width: 80,
        height: 18,
        verticalAlign: 'middle',
        display: 'inline-flex',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: lighten(red[500], 0.3),
        marginRight: theme.spacing(1)
    },
    '.Mui-disabled svg': {
        fill: 'red'
    }
}));

const GLOBAL_FILTERS = ['type', 'status', 'listing_status'];

const ResultsMain = (props) => {
    const classes = useStyles();
    const { providers, fetchingProviders } = useGlobalState();
    const {
        data = [],
        count,
        rowsPerPage,
        page,
        fetching,
        sortKey,
        sortDirection,
        selected,
        dialog,
        isSaving,
        setInterviewState,
        handleUpdateInterviews,
        handleShowActionConfirmation,
        handleCloseDialog,
        handleSelectMultiple,
        handleDeleteInterviews,
        filters
    } = useInterviewState();
    const [globalFiltersOpen, setGlobalFiltersOpen] = useState(false);
    const [columnFilters, setColumnFilters] = useObjectState({});

    const provider = columnFilters.provider_id
        ? providers.filter((client) => {
              return client.id === columnFilters.provider_id;
          })[0]
        : null;

    const provider_value = provider
        ? { value: provider.id, label: provider.name }
        : null;

    const columns = [
        {
            name: 'Interviewee Name',
            type: 'text',
            key: 'interviewee_name',
            filter: {
                onChange: (e) =>
                    setColumnFilters({ interviewee_name: e.target.value })
            }
        },
        {
            name: 'Client Name',
            format: 'bold',
            key: 'provider_name',
            options: [],
            filter: {
                type: 'select',
                options: providers || {},
                isLoading: fetchingProviders,
                optionValue: 'id',
                optionKey: 'name',
                value: provider_value
                    ? {
                          value: provider_value.value,
                          label: provider_value.label
                      }
                    : null,
                onChange: (client) =>
                    setColumnFilters({ provider_id: client.value })
            }
        },
        {
            name: 'Date Added',
            type: 'date',
            format: 'normal',
            key: 'date_added',
            filter: {
                onChange: (date_added) => setColumnFilters({ date_added })
            },
            sort: sortKey === 'date_added' ? sortDirection : undefined
        },
        {
            name: 'Date Published',
            type: 'date',
            format: 'normal',
            key: 'interview_date',
            filter: {
                onChange: (interview_date) =>
                    setColumnFilters({ interview_date })
            },
            sort: sortKey === 'interview_date' ? sortDirection : undefined
        }
    ];

    const batchActions = [
        {
            icon: 'publish',
            handler: () =>
                handleShowActionConfirmation('publish', () =>
                    handleUpdateInterviews('publish', true)
                )
        },
        {
            icon: 'unpublish',
            handler: () =>
                handleShowActionConfirmation('unpublish', () =>
                    handleUpdateInterviews('unpublish', true)
                )
        },
        {
            icon: 'delete',
            handler: () =>
                handleShowActionConfirmation('delete', () =>
                    handleDeleteInterviews(true)
                )
        }
    ];

    const rowMenus = [
        {
            name: 'view',
            text: 'View',
            target: '_blank'
        },
        {
            name: 'edit',
            text: 'Edit'
        },
        {
            name: 'publish',
            text: 'Publish',
            handler: (row) => {
                handleShowActionConfirmation('publish', () =>
                    handleUpdateInterviews('publish', false, row)
                );
            }
        },
        {
            name: 'unpublish',
            text: 'Unpublish',
            handler: (row) => {
                handleShowActionConfirmation('unpublish', () =>
                    handleUpdateInterviews('unpublish', false, row)
                );
            }
        },
        {
            name: 'delete',
            text: 'Delete',
            handler: (row) => {
                handleShowActionConfirmation('delete', () =>
                    handleDeleteInterviews(false, row)
                );
            }
        }
    ];

    const globalFilters = [
        {
            key: 'type',
            filterLabel: 'Interview Type',
            type: 'select',
            optionsLabel: 'Interview Type',
            options: [
                {
                    value: 1,
                    label: 'Alumni'
                },
                {
                    value: 2,
                    label: 'Staff'
                }
            ]
        },
        {
            key: 'status',
            filterLabel: 'Publication Status',
            type: 'switch',
            options: [
                {
                    value: 0,
                    label: 'Unpublished'
                },
                {
                    value: 1,
                    label: 'Published'
                }
            ]
        },
        {
            key: 'listing_status',
            filterLabel: 'Listing Status',
            type: 'select',
            optionsLabel: 'Listing Status',
            options: [
                {
                    value: 1,
                    label: 'Active'
                },
                {
                    value: 0,
                    label: 'Inactive'
                },
                {
                    value: 'none',
                    label: 'None'
                }
            ]
        }
    ];

    const rowMenusChecker = (row, menus) =>
        menus
            .filter((menu) => {
                if (menu.name === 'publish')
                    return ['0', 0].includes(row.status);
                if (menu.name === 'edit')
                    menu.url = `/interviews/edit/${row.id}`;
                if (['view', 'unpublish'].includes(menu.name))
                    return ['1', 1].includes(row.status);
                return true;
            })
            .map((menu) => {
                if (menu.name === 'view')
                    menu.url =
                        `${trimSlash(GA_URL)}/interviews/` +
                        (row.alias !== null ? row.alias : '');
                return menu;
            });

    const icons = [
        {
            name: 'unpublished',
            icon: (
                <Tooltip title="Unpublished" placement="top">
                    <Typography className={classes.unpublished}>
                        UNPUBLISHED
                    </Typography>
                </Tooltip>
            ),
            placement: 'right',
            column: 'interviewee_name'
        },
        {
            name: 'alumni',
            icon: (
                <Tooltip title="Alumni" placement="top">
                    <PersonPinIcon color="primary" />
                </Tooltip>
            ),
            placement: 'right',
            column: 'interviewee_name'
        },
        {
            name: 'staff',
            icon: (
                <Tooltip title="Staff" placement="top">
                    <PeopleIcon color="primary" />
                </Tooltip>
            ),
            placement: 'right',
            column: 'interviewee_name'
        }
    ];

    const iconsChecker = (row, icons) => {
        return icons.filter((icon) => {
            if (icon.name === 'unpublished')
                return ['0', 0].includes(row.status);
            if (icon.name === 'alumni') return ['1', 1].includes(row.type);
            if (icon.name === 'staff') return ['2', 2].includes(row.type);
            return true;
        });
    };

    const handleApplyColumnFilters = () => {
        setInterviewState({ filters: { ...filters, ...columnFilters } });
    };

    const handleHeadeClick = ({ key, sort }) => {
        if (['interview_date'].includes(key)) {
            if (sort === 'desc') {
                setInterviewState({
                    sortKey: key,
                    sortDirection: 'asc',
                    data: [...data].sort(
                        (a, b) =>
                            new Date(a[key]).getTime() -
                            new Date(b[key]).getTime()
                    )
                });
            } else {
                setInterviewState({
                    sortKey: key,
                    sortDirection: 'desc',
                    data: [...data].sort(
                        (a, b) =>
                            new Date(b[key]).getTime() -
                            new Date(a[key]).getTime()
                    )
                });
            }
        }
    };

    const handlePageChange = (page) => setInterviewState({ page });

    const handleRowsPerPageChange = (rpp) =>
        setInterviewState({ rowsPerPage: rpp });

    const handleApplyGlobalFilter = (filters) => {
        setInterviewState({ filters: { ...filters, ...columnFilters } });
    };

    const handleCloseGlobalFilter = () => {
        setGlobalFiltersOpen(false);
    };

    const handleClearGlobalFilter = () => {
        const newFilters = { ...filters };
        //eslint-disable-next-line array-callback-return
        GLOBAL_FILTERS.map((key) => {
            delete newFilters[key];
        });
        setInterviewState({ filters: newFilters });
    };

    const handleClearColumnFilters = () => {
        const newFilters = { ...filters };
        //eslint-disable-next-line array-callback-return
        Object.keys(columnFilters).map((key) => {
            delete newFilters[key];
            delete columnFilters[key];
        });
        setInterviewState({ filters: newFilters });
    };

    const globalChecker =
        //eslint-disable-next-line array-callback-return
        Object.keys(filters).map((key) => {
            if (GLOBAL_FILTERS.includes(key)) return true;
        })[0] || false;

    return (
        <>
            <ResultsTable
                label="interviews"
                data={data}
                count={count || data.length}
                columns={columns}
                icons={icons}
                iconsChecker={iconsChecker}
                hasSearch={false}
                batchActions={batchActions}
                rowMenus={rowMenus}
                onGlobalFiltersClick={() => setGlobalFiltersOpen(true)}
                rowsPerPage={rowsPerPage}
                page={page}
                onColumnFiltersApply={handleApplyColumnFilters}
                isLoadingData={fetching}
                onColumnHeaderClick={handleHeadeClick}
                rowMenusChecker={rowMenusChecker}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                selected={selected}
                onSelectedChange={handleSelectMultiple}
                onColumnFilterClose={handleClearColumnFilters}
                activeGlobalFilters={!!globalChecker}
            />
            <CustomGlobalFilterDrawer
                open={globalFiltersOpen}
                onClose={handleCloseGlobalFilter}
                closeOnApply
                closeOnClear
                onApply={handleApplyGlobalFilter}
                onClear={handleClearGlobalFilter}
                filters={globalFilters}
            />
            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.onOk}
                onCancel={handleCloseDialog}
                onClose={handleCloseDialog}
                stringOverride={dialog.stringOverride}
                classes={{
                    dialog: classes.dialog,
                    content: classes.content
                }}
            />
            <DialogBox open={isSaving} title={'Saving Changes...'} actions="">
                <LinearProgress />
            </DialogBox>
        </>
    );
};

export default ResultsMain;
