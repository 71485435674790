import React, { Component, useContext } from 'react';
import { ArticlesContext, GlobalContext } from '../../../context.js';
import { sendRequest, exportCsv } from '../../../helpers/apiRequestUtility';
import { transformDateParam } from '../../../utilities/dateFormatter.js';
import { isEmpty } from 'node-forge/lib/util';
import {
    CRITERIA_PER_DIRECTORY,
    SEARCH_CRITERIA,
} from '../../../utilities/searchCriteria';
import { withRouter } from 'react-router-dom';
import { isBookmarkable } from '../../../utilities/boomarkability.js';
import {
    constructHttpParams,
    filterObject,
} from '../../../helpers/dataUtility.js';
import queryString from 'query-string';
import { withSnackbar } from 'notistack';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
} from '@material-ui/core';

const { Provider, Consumer } = ArticlesContext;

class ArticlesProvider extends Component {
    static contextType = GlobalContext;
    state = {
        data: [],
        authors: [],
        programContent: [],
        count: 0,
        sort: 'date_published',
        sortOrder: 'desc',
        page: 0,
        rowsPerPage: 10,
        selected: [],
        unselected: [],
        filters: {},
        filterDrawerOpen: false,
        drawerOpen: false,
        fetchingArticles: false,
        selectedArticle: null,
        reassign: false,
        disablePublish: false,
        disableUnpublish: false,
        articleTitles: [],
        articleAuthors: [],
        openNotif: false,
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => {},
            onCancel: () => {},
        },
        isSaving: false,
        locations: {
            data: [],
            fetching: false,
        },
        topics: {
            data: [],
            fetching: false,
        },
        types: {
            data: [],
            fetching: false,
        },
        timings: {
            data: [],
            fetching: false,
        },
        exportCriteria: ['directory'],
    };

    componentDidMount = () => {
        const {
            location: { search = '' },
        } = this.props;
        const { article_id: id, ...filters } = filterObject(
            queryString.parse(search),
            ({ key }) => isBookmarkable('articles_view', key)
        );
        this.setState(
            {
                fetchingArticles: true,
                filters: { ...filters, id },
            },
            this.fetchArticles
        );
        this.fetchAuthors();
        this.fetchProgramContent();
        this.browserRouterListen();
    };

    browserRouterListen = () => {
        const { history } = this.props;
        history.listen((location, action) => {
            if (action === 'POP') {
                let { article_id: id, ...filters } = filterObject(
                    queryString.parse(location.search),
                    ({ key }) => isBookmarkable('articles_view', key)
                );

                this.setState(
                    {
                        fetchingArticles: true,
                        filters: { ...filters, id },
                    },
                    this.fetchArticles
                );
            }
        });
    };

    setArticlesState = (newState, callback) =>
        this.setState(newState, callback);

    transformLocationParams = () => {
        const { filters, locations } = this.state;
        const { location, isExactLocation } = filters;
        let filterParams = {};

        if (!isEmpty(location)) {
            const types = [
                'world_region_id',
                'country_id',
                'main_region_id',
                'city_id',
            ];
            types.forEach((i) => {
                let bin = SEARCH_CRITERIA[i].bin_value;
                if (location.value.startsWith(bin + '_')) {
                    let value = location.value.replace(bin + '_', '');
                    let bin_value = bin;
                    filterParams = { ...filterParams, [i]: value };

                    // add country_id to city or main_region
                    if (i === 'city_id' || i === 'main_region_id') {
                        const obj = locations.data.filter((obj) => {
                            return obj.id === location.value;
                        })[0];
                        const country_id =
                            obj.search_criteria[0].criteria_value;
                        bin_value += obj.search_criteria[0].criteria_id;
                        filterParams = { ...filterParams, country_id };
                    }

                    if (isExactLocation) {
                        // article always has directory so we need to add directory to bin_value
                        bin_value += SEARCH_CRITERIA['directory_id'].bin_value;
                        filterParams = { ...filterParams, bin_value };
                    }
                }
            });
        }

        return filterParams;
    };

    transformDirectoryParams = () => {
        const { filters } = this.state;
        const { directory, type, timing, isDirectoryOnly, online } = filters;
        let filterParams = {};

        if (!isEmpty(directory)) {
            filterParams = { directory_id: directory.value };
            if (isDirectoryOnly) {
                filterParams = { ...filterParams, bin_value: 1 };
            }
            if (!isEmpty(type)) {
                const typeCriteria =
                    CRITERIA_PER_DIRECTORY[directory.value].type;
                filterParams = { ...filterParams, [typeCriteria]: type.value };
            }
            if (!isEmpty(timing)) {
                const timingCriteria =
                    CRITERIA_PER_DIRECTORY[directory.value].timing;
                filterParams = {
                    ...filterParams,
                    [timingCriteria]: timing.value,
                };
            }
        }

        if (online && online.value) {
            filterParams = { ...filterParams, online: online.value };
        }

        return filterParams;
    };

    fetchArticles = async (callback) => {
        const { filters, page, rowsPerPage } = this.state;

        const {
            id,
            title,
            author_id,
            date_published,
            date_modified,
            is_article_published,
            topic,
            alias,
        } = filters;

        let filterParams = {
            id: id !== null ? id : null,
            title: title !== null ? title : null,
            author_id: author_id !== null ? author_id : null,
            date_published: date_published
                ? transformDateParam(date_published)
                : null,
            date_modified: date_modified
                ? transformDateParam(date_modified)
                : null,
            status: is_article_published !== null ? is_article_published : null,
            topic_id: !isEmpty(topic) ? topic.value : null,
            alias: !isEmpty(alias) ? alias : null,
        };

        if (!!title) {
            filterParams.wildcards = 'title';
        }

        const locationParams = this.transformLocationParams();
        const directoryParams = this.transformDirectoryParams();

        filterParams = {
            ...filterParams,
            ...locationParams,
            ...directoryParams,
        };

        const params = this.buildParams({
            fields: 'id,title,alias,author_id,photo,provider_id,alt_text,status,show_date_modified,topics,seo_meta,content,author_name,date_modified,date_published,status,date_created,is_featured,search_criteria',
            sort: `-status,-date_created`,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            ...filterParams,
        });
        await sendRequest(
            `/articles?${params}`,
            ({ articles = [], count = 0 } = {}) =>
                this.setState(
                    {
                        data: articles || [],
                        count,
                        fetchingArticles: false,
                    },
                    callback
                )
        );
    };

    fetchOptions = async (criteria) =>
        sendRequest(
            `/search-criteria-options?criteria=${criteria}`,
            ({ search_criteria_options = [] }) =>
                search_criteria_options.map(({ id: value, name: label }) => {
                    return { value, label };
                })
        );

    fetchAuthors = async () => {
        const params = this.buildParams({
            fields: 'id,name',
            limit: -1,
        });

        await sendRequest(`/authors?${params}`, ({ authors }) =>
            this.setState({
                authors,
            })
        );
    };

    fetchProgramContent = async () => {
        const params = this.buildParams({
            fields: 'id,title,search_criteria',
            limit: -1,
        });

        await sendRequest(`/articles?${params}`, ({ articles }) =>
            this.setState({
                programContent: articles,
            })
        );
    };

    fetchLocations = async () => {
        this.setState({ locations: { data: [], fetching: true } });
        await sendRequest(
            '/locations?location_type=world_region,country,main_region,city',
            ({ locations }) =>
                this.setState({
                    locations: { data: locations, fetching: false },
                })
        );
    };

    fetchTopics = async () => {
        this.setState({ topics: { data: [], fetching: true } });
        await sendRequest(
            '/articles/topics?fields=name,id&sort=+name',
            ({ topics }) =>
                this.setState({
                    topics: { data: topics, fetching: false },
                })
        );
    };

    fetchTypes = async (criteria) => {
        this.setState({ types: { data: [], fetching: true } });
        await sendRequest(
            `/search-criteria-options?criteria=${criteria}`,
            ({ search_criteria_options }) =>
                this.setState({
                    types: { data: search_criteria_options, fetching: false },
                })
        );
    };

    fetchTimings = async (criteria) => {
        this.setState({ timings: { data: [], fetching: true } });
        await sendRequest(
            `/search-criteria-options?criteria=${criteria}`,
            ({ search_criteria_options }) =>
                this.setState({
                    timings: { data: search_criteria_options, fetching: false },
                })
        );
    };

    handleClearCriteria = () => {
        this.setState({
            types: { data: [], fetching: false },
            timings: { data: [], fetching: false },
        });
    };

    handleExportActiveArticles = () => {
        const { enqueueSnackbar } = this.props;
        const { handleStateChange } = this.context;

        let url =
            '/articles/export-active?' +
            constructHttpParams({
                criteria: this.state.exportCriteria,
            });

        this.setState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: () => {},
                onCancel: () => {},
            },
            isSaving: true,
        });

        handleStateChange({ articleExportDialogOpen: false });

        exportCsv(
            url,
            'Articles.csv',
            () => {
                enqueueSnackbar('Articles successfully exported!', {
                    variant: 'success',
                });
                this.setState({ isSaving: false });
            },
            () => {
                handleStateChange({
                    showLoginDialog: true,
                    loginDialogCallback: this.handleExportActiveArticles,
                });
                this.setState({ isSaving: false });
            },
            () => {
                enqueueSnackbar('Error encountered', {
                    variant: 'error',
                });
                this.setState({ isSaving: false });
            },
            () => {
                enqueueSnackbar('No published articles.', {
                    variant: 'error',
                });
                this.setState({ isSaving: false });
            }
        );
    };

    handleExportCriteria = (e) => {
        let value = e.target.value;
        let { exportCriteria } = this.state;

        if (e.target.checked) {
            exportCriteria.push(value);
        } else {
            exportCriteria = exportCriteria.filter((id) => {
                return id !== value;
            });
        }
        this.setState({ exportCriteria });
    };

    showExportActiveArticlesDialog = () => {
        const { handleStateChange } = this.context;
        const dialog = {
            title: 'Export Published Articles',
            onCancel: () => {
                const dialog = {
                    show: false,
                    title: '',
                    content: '',
                    stringOverride: {},
                    onOk: () => {},
                    onClose: () => {},
                };

                this.setState({ dialog: dialog });
                handleStateChange({ articleExportDialogOpen: false });
            },
            stringOverride: {
                primaryAction: 'Export',
                secondaryAction: 'Cancel',
            },
        };

        this.setState({ dialog: dialog });
    };

    showExportContent = () => {
        const { exportCriteria } = this.state;
        return (
            <FormControl fullWidth>
                <FormHelperText id="export-article-criteria-helper-text">
                    This will export all published articles with its
                    corresponding directory and criteria assignments.
                </FormHelperText>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={exportCriteria.includes('country')}
                                onChange={this.handleExportCriteria}
                                value="country"
                            />
                        }
                        label="Country"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={exportCriteria.includes('type')}
                                onChange={this.handleExportCriteria}
                                value="type"
                            />
                        }
                        label="Type"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={exportCriteria.includes('timing')}
                                onChange={this.handleExportCriteria}
                                value="timing"
                            />
                        }
                        label="Timing"
                    />
                </FormGroup>
            </FormControl>
        );
    };

    buildParams = (params = {}) =>
        Object.keys(params)
            .filter((key) => ![undefined, null, NaN, ''].includes(params[key]))
            .map((key) => `${key}=${params[key]}`)
            .join('&');

    updateBookmarkParams = () => {
        const { history } = this.props;
        const { filters: { id: article_id, ...filters } = {} } = this.state;
        const params = { article_id, ...filters };
        const newParams = {};

        Object.keys(params)
            .filter(
                (key) => !!params[key] && isBookmarkable('articles_view', key)
            )
            .map((key) => (newParams[key] = params[key]));

        history.push({
            pathname: '/articles',
            search: `?${constructHttpParams(newParams)}`,
        });
    };

    render = () => (
        <Provider
            value={{
                state: this.state,
                setArticlesState: this.setArticlesState,
                fetchArticles: this.fetchArticles,
                fetchOptions: this.fetchOptions,
                fetchAuthors: this.fetchAuthors,
                fetchProgramContent: this.fetchProgramContent,
                fetchLocations: this.fetchLocations,
                fetchTopics: this.fetchTopics,
                fetchTypes: this.fetchTypes,
                fetchTimings: this.fetchTimings,
                handleClearCriteria: this.handleClearCriteria,
                handleExportActiveArticles: this.handleExportActiveArticles,
                showExportActiveArticlesDialog:
                    this.showExportActiveArticlesDialog,
                showExportContent: this.showExportContent,
                updateBookmarkParams: this.updateBookmarkParams,
                transformLocationParams: this.transformLocationParams,
                transformDirectoryParams: this.transformDirectoryParams,
            }}
            children={this.props.children}
        />
    );
}

export const withArticlesContext = (Component) => (props) =>
    (
        <Consumer>
            {(context) => <Component {...props} context={context} />}
        </Consumer>
    );

export const withArticlesContextState = (fields) => (Component) => (props) =>
    (
        <Consumer>
            {({ state, ...rest }) => {
                let extracted = {};
                //eslint-disable-next-line array-callback-return
                Object.keys(state).map((key) => {
                    if ((fields || []).includes(key))
                        extracted[key] = state[key];
                });
                return <Component {...{ ...props, ...extracted, ...rest }} />;
            }}
        </Consumer>
    );

export const useArticlesState = () => {
    const { state, setArticlesState } = useContext(ArticlesContext);
    return { ...state, setArticlesState };
};

export const useArticlesMethods = () => {
    const { state, ...methods } = useContext(ArticlesContext);
    return methods;
};

export default withRouter(withSnackbar(ArticlesProvider));
