import React, { Component } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { postRequest } from '../../../../helpers/apiRequestUtility';
import Loader from '../../../../components/Loader/Loader';
import { delete_cookie } from '../../../../helpers/cookieUtility';

class ReassignContactPortal extends Component {
    state = {
        isLoading: true,
        error: null,
        isValid: false,
        contact: {},
        provider: '',
        type: ''
    };

    componentDidMount(props) {
        const params = queryString.parse(this.props.location.search);
        const form_data = new FormData();
        form_data.append('accept_token', params.token);
        form_data.append('id', params.id);
        const path = this.props.location.pathname;
        postRequest(
            `/contacts${path}`,
            form_data,
            ({ contact, provider, type }) => {
                this.setState({
                    isLoading: false,
                    isValid: true,
                    type,
                    provider
                });
            },
            (error) => {
                this.setState({ isLoading: false, error: error });
            }
        );
    }

    handleThankYou() {
        let message = `Thank you for confirming your reassignment as the ${this.state.type} Contact for ${this.state.provider}!`;
        this.props.history.push('/admin-login/thank-you', message);
        delete_cookie('user_info');
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? (
                    <Loader />
                ) : this.state.isValid ? (
                    this.handleThankYou()
                ) : (
                    this.props.history.push('/page-not-found')
                )}
            </div>
        );
    }
}

export default withRouter(ReassignContactPortal);
