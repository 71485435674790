import FeaturesTableHeader from './FeaturesTableHeader';
import FeaturesTableBody from './FeaturesTableBody';
import React, { Fragment } from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
    paper: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        margin: `${theme.spacing(2)}px 0`,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    noResults: {
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.54)'
    }
});

const FeaturesTable = (props) => {
    const {
        classes,
        filters,
        reorder,
        disableRank,
        webFeatures,
        handleAddColumnFilter,
        handleClearFilter,
        handleClick,
        handleFeatureDragOver,
        handleFeatureDragStart,
        handleFeatureDrop,
        handleReorder,
        saveNewOrder,
        disableSearchFilter
    } = props;

    return (
        <Paper className={classes.paper}>
            {webFeatures.length > 0 ? (
                <Fragment>
                    <FeaturesTableHeader
                        reorder={reorder}
                        disableRank={disableRank}
                        handleReorder={handleReorder}
                        saveNewOrder={saveNewOrder}
                        handleAddColumnFilter={handleAddColumnFilter}
                        handleClearFilter={handleClearFilter}
                        filters={filters}
                        webFeatures={webFeatures}
                        disableSearchFilter={disableSearchFilter}
                    />
                    <FeaturesTableBody
                        filters={filters}
                        reorder={reorder}
                        webFeatures={webFeatures}
                        handleClick={handleClick}
                        handleFeatureDragOver={handleFeatureDragOver}
                        handleFeatureDragStart={handleFeatureDragStart}
                        handleFeatureDrop={handleFeatureDrop}
                    />
                </Fragment>
            ) : (
                <Typography className={classes.noResults} variant="h5">
                    {' '}
                    NO RESULTS{' '}
                </Typography>
            )}
        </Paper>
    );
};

export default withStyles(styles)(FeaturesTable);
