import { withSnackbar } from 'notistack';
import React, { Component, useContext } from 'react';
import { TopicContext, GlobalContext } from '../../../context';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import {
    buildFormData,
    constructHttpParams
} from '../../../helpers/dataUtility';

const { Provider, Consumer } = TopicContext;

class TopicProvider extends Component {
    static contextType = GlobalContext;
    sortable = ['articles_count'];
    state = {
        data: [],
        topics: [],
        count: 0,
        page: 0,
        rowsPerPage: 10,
        filters: {},
        edit: false,
        selectedTopic: null,
        selected: [],
        sortBy: 'articles_count',
        sortingOrder: 'desc',
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {}
        },
        fetching: false
    };

    componentDidMount() {
        this.fetchTopics();
    }

    setTopicsState = (newState, callback) => this.setState(newState, callback);

    fetchTopics = () => {
        const params = constructHttpParams({
            limit: -1,
            fields: 'id,name,alias,meta_title,meta_description,articles_count'
        });

        this.setState({ fetching: true });

        sendRequest(
            `/articles/topics?${params}`,
            ({ topics = [], count = 0 }) => {
                this.setState(
                    { topics, count: count || topics.length },
                    this.applyFilters
                );
            }
        ).finally(() => this.setState({ fetching: false }));
    };

    handleColumnFiltersChange = (filter, value) => {
        let { filters } = this.state;
        filters[filter] = value;
        this.setState({ filters });
    };

    applyFilters = () => {
        const { sortBy, sortingOrder, filters, rowsPerPage, page, topics } =
            this.state;
        const data = [...topics]
            .filter((topic) => {
                if (filters.name) return topic.id === filters.name.value;
                return true;
            })
            .filter((topic) => {
                if (filters.articles_count)
                    return (
                        parseInt(topic.articles_count) ===
                        parseInt(filters.articles_count)
                    );
                return true;
            })
            .sort((a, b) => {
                if (!sortBy) return 0;

                if (sortingOrder === 'asc') return a[sortBy] - b[sortBy];
                else return b[sortBy] - a[sortBy];
            });

        this.setState({
            count: data.length,
            data: [...data].splice(page * rowsPerPage, rowsPerPage)
        });
    };

    handleSorting = ({ key }) => {
        if (!this.sortable.includes(key)) return;

        const { sortingOrder, sortBy } = this.state;
        const newSortingOrder =
            key === sortBy ? (sortingOrder === 'asc' ? 'desc' : 'asc') : 'asc';
        this.setState(
            { sortingOrder: newSortingOrder, sortBy: key },
            this.applyFilters
        );
    };

    handleEditTopic = (selectedTopic) => {
        this.setState({ selectedTopic, edit: true });
    };

    handleConfirmDelete = (selectedTopic) => {
        this.setState({
            selectedTopic,
            dialog: {
                show: true,
                title: 'Delete Topic',
                content: `Are you sure you want to delete ${
                    selectedTopic.name || 'this topic'
                }?`,
                stringOverride: {
                    primaryAction: 'Delete',
                    secondaryAction: 'Cancel'
                },
                action: () => this.handleDeleteTopic()
            }
        });
    };

    handleDeleteTopic = (batch = false) => {
        const { authenticatedPostRequest: postRequest } = this.context;
        const { enqueueSnackbar } = this.props;
        const { selected, selectedTopic } = this.state;
        const formData = new FormData();
        const ids = batch
            ? (selected || [])
                  .map((t) => (t || {}).id)
                  .filter((id) => !!id)
                  .join(',')
            : (selectedTopic || {}).id;

        buildFormData(formData, { id: ids });

        this.setState({ deleting: true });

        postRequest(
            '/articles/topics/delete',
            formData,
            (response) => {
                const { failedIds = [] } = response || {};

                this.setState({ selected: [], selectedTopic: null, page: 0 });

                this.fetchTopics();

                enqueueSnackbar(`Successfully deleted selected topic(s)`, {
                    variant: 'success'
                });

                if (failedIds.length)
                    enqueueSnackbar(
                        `Failed to delete ${failedIds.length} topic(s)`,
                        {
                            variant: 'warning'
                        }
                    );
            },
            (e) => {
                enqueueSnackbar('Failed to delete selected topic(s)', {
                    variant: 'error'
                });
            },
            () => this.handleDeleteTopic(batch)
        ).finally(() => this.setState({ deleting: false }));

        this.handleCloseAction();
    };

    handleCancelAction = () => {
        this.handleHideBatchActionConfirmation();
    };

    handleCloseAction = () => {
        this.handleHideBatchActionConfirmation();
    };

    handleHideBatchActionConfirmation = () => {
        this.setState({
            selectedTopic: null,
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                action: null
            }
        });
    };

    onSelectedChange = (selected) => {
        this.setState({ selected });
    };

    handleConfirmBatchDelete = () => {
        const { selected = [] } = this.state;
        this.setState({
            dialog: {
                show: true,
                title: 'Delete',
                content: `Are you sure you want to permanently delete ${
                    selected.length
                } ${selected.length > 1 ? 'topics' : 'topic'}?`,
                stringOverride: {
                    primaryAction: 'Delete',
                    secondaryAction: 'Cancel'
                },
                action: () => this.handleDeleteTopic(true)
            }
        });
    };

    render() {
        return (
            <Provider
                value={{
                    state: this.state,
                    setTopicsState: this.setTopicsState,
                    fetchTopics: this.fetchTopics,
                    applyFilters: this.applyFilters,
                    handleColumnFiltersChange: this.handleColumnFiltersChange,
                    handleSorting: this.handleSorting,
                    handleEditTopic: this.handleEditTopic,
                    handleConfirmDelete: this.handleConfirmDelete,
                    handleCancelAction: this.handleCancelAction,
                    handleCloseAction: this.handleCloseAction,
                    onSelectedChange: this.onSelectedChange,
                    handleConfirmBatchDelete: this.handleConfirmBatchDelete
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export const withTopicsContext = (Component) => (props) => {
    return <Consumer>{(c) => <Component {...props} context={c} />}</Consumer>;
};

export const useTopicsState = () => {
    const { state, setTopicsState } = useContext(TopicContext);
    return { ...state, setTopicsState };
};

export const useTopicsMethods = () => {
    const { state, ...methods } = useContext(TopicContext);
    return methods;
};

export default withSnackbar(TopicProvider);
