import { Chip, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import AddBoxIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useCustomMetaState } from './CustomMetaProvider';
import { META_PAGE_MAPPER } from '../../../../config/constants';
import { SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2)
    },
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    filterChip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

export default function DataFilter() {
    const classes = useStyles();
    const { filters, setCustomMetaState, } = useCustomMetaState();
    const { customPlacements } = filters;
    const handleAddFilter = () => setCustomMetaState({ dataFilterOpen: true });

    const mapper = {
        type: [
            'volunteer_type',
            'intern_type',
            'language',
            'adventure_travel_type',
            'highschool_type',
            'degree_program',
            'gap_year_type'
        ],
        timing: [
            'duration',
            'teach_duration',
            'intern_duration',
            'term',
            'study_type',
            'gap_year_timing'
        ]
    }
    const location = ["world_region", "country", "main_region", "city"];
    const handleDeleteFilter = (key) => () => {
        let y = ["type", "timing"];
        setCustomMetaState((prev) => ({
            ...prev,
            metaTypes: key === "directory"
                ? [...prev.metaTypes].filter((x) => !y.includes(x.value))
                : [...prev.metaTypes],
            filters: {
                ...prev.filters,
                [key]: null,
                pageTypes: key === "directory"
                    ? [...prev.filters.pageTypes].filter((x) => !y.includes(x))
                    : [...prev.filters.pageTypes],
                customPlacements: key === "directory" ? Object.fromEntries(
                    Object.entries(prev.filters.customPlacements).filter(([keyData, value]) =>
                        location.includes(keyData)
                    )) : prev.filters.customPlacements
            }
        }))


    };

    const handleDeletePageType = (key) => () => {
        let y = ["type", "timing"];


        setCustomMetaState((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                pageTypes: key === "country"
                    ? [...prev.filters.pageTypes].filter(
                        (type) => y.includes(type))
                    : [...prev.filters.pageTypes].filter(
                        (type) => type !== key
                    ),
                customPlacements: Object.fromEntries(
                    Object.entries(prev.filters.customPlacements).filter(([keyData, value]) => {
                        if (key === "type" || key === "timing") {
                            return !mapper[key].includes(keyData)
                        }
                        if (key === "country") {
                            return !location.includes(keyData);
                        }
                        return keyData !== key
                    }
                    ))
            }
        }));
    };

    const hasFilters = Object.keys(filters || {}).some((key) => {
        if (key === 'online') return filters[key];
        if (key === 'pageTypes') return filters[key].length;
        return !!filters[key];
    });

    return (
        <Paper className={classes.paper}>
            <div className={classes.root}>
                <Typography variant="overline">Data Filters</Typography>
                <br />
                {Object.keys(filters)
                    .filter((key) =>
                        ['directory', 'pageTypes', 'online', 'customPlacements'].includes(key)
                    )
                    .map((key) => {
                        if (!filters[key]) return null;

                        if (key === 'directory') {
                            const { label } = filters[key] || {};
                            return (
                                <Chip
                                    data-cy="default-meta-directory-filter-chip"
                                    label={label}
                                    color="primary"
                                    onDelete={handleDeleteFilter(key)}
                                    onClick={handleAddFilter}
                                    className={classes.filterChip}
                                />
                            );
                        }

                        if (key === 'online' && filters[key]) {
                            return (
                                <Chip
                                    data-cy="default-meta-online-filter-chip"
                                    label={'Include Online'}
                                    color="primary"
                                    onDelete={handleDeleteFilter(key)}
                                    onClick={handleAddFilter}
                                    className={classes.filterChip}
                                />
                            );
                        }
                        if (key === 'customPlacements') {
                            let label = "";
                            return Object.entries(customPlacements).map(([keyData, value]) => {
                                let searchCriteria = Object.entries(SEARCH_CRITERIA).find(([x, y]) => y.new_criteria === keyData);
                                let keyMap = Object.entries(mapper).find(([x, y]) => y.includes(keyData));

                                label = META_PAGE_MAPPER[searchCriteria[0]];
                                if (!!value) {
                                    label = label.concat(": ", value.label);
                                }
                                if (!!keyMap) {
                                    keyData = keyMap[0];
                                }
                                return (
                                    <Chip
                                        data-cy="default-meta-page-type-filter-chip"
                                        label={label}
                                        color="primary"
                                        onDelete={handleDeletePageType(
                                            keyData
                                        )}
                                        onClick={handleAddFilter}
                                        className={classes.filterChip}
                                    />
                                );
                            });
                        }
                        return null;
                    })}
                <Chip
                    icon={
                        hasFilters ? (
                            <EditIcon fontSize="small" />
                        ) : (
                            <AddBoxIcon fontSize="small" />
                        )
                    }
                    data-cy="default-meta-add-data-filter"
                    label={hasFilters ? 'Edit Filters' : 'Add Filters'}
                    onClick={handleAddFilter}
                    color="primary"
                    variant="outlined"
                    className={classes.filterChip}
                />
            </div>
        </Paper>
    );
}
