import React from 'react';

import SearchResultsTableProvider from './SearchResultsTable/SearchResultsTableProvider';
import SearchResultsRankingContainer from './SearchResultsRankingContainer';

const SearchResultsRanking = () => {
    return (
        <SearchResultsTableProvider>
            <SearchResultsRankingContainer />
        </SearchResultsTableProvider>
    );
};

export default SearchResultsRanking;
