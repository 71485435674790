import Loader from './components/Loader/Loader';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import ForgotPassword from './views/Authentication/ForgotPassword';
import ResetPassword from './views/Authentication/ResetPassword';
import SetPassword from './views/Authentication/SetPassword';
import Login from './views/Authentication/Login';
import Dashboard from './layouts/Dashboard';
import GlobalProvider from './views/Client/GlobalProvider';
import { AbilityContext } from './components/AccessLevel/abilityContext';
import { getAbility } from './components/AccessLevel/ability';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import ErrorBoundary from './components/Error/ErrorBoundary';
import ReassignContactPortal from './views/Client/Profile/UserManagement/ReassignContactPortal';
import PaymentSuccess from './views/Client/Invoices/ViewInvoice/PaymentSuccess';
import NotFound from './views/Error/NotFound';
import Forbidden from './views/Error/Forbidden';
import SEOTool from './views/SiteTools/SEOMeta/SEOTool/SEOTool';
import './index.css';

const AddEditInvoiceContainer = lazy(() =>
    import('./views/Client/Invoices/AddEditInvoice/AddEditInvoiceContainer')
);
const CreateEditAd = lazy(() =>
    import('./views/Client/Ads/CreateEditAd/CreateEditAd')
);
const AddEditProgram = lazy(() =>
    import('./views/Program/AddEditProgram/AddEditProgramContainer')
);
const ClientIndividualAccount = lazy(() =>
    import('./views/Client/Profile/Main')
);
const ClientAdmin = lazy(() => import('./views/ClientAdmin/ClientAdmin'));
const ViewInvoiceContainer = lazy(() =>
    import('./views/Client/Invoices/ViewInvoice/ViewInvoiceContainer')
);
const InvoiceReporting = lazy(() => import('./views/Reports/Invoice/Main'));
const Collections = lazy(() => import('./views/Collections/Collections'));
const CMS = lazy(() => import('./views/Articles/Articles/ArticleCMS/CMS'));
const InterviewCMS = lazy(() =>
    import('./views/Interviews/InterviewCMS/InterviewCMS')
);
const SalesAndCollectionsSummary = lazy(() =>
    import('./views/SalesAndCollectionsSummary/Main')
);

const EditGuideTool = lazy(() => import('./views/Guides/EditGuide/Main'));
const EditFAQTool = lazy(() => import('./views/Guides/EditFAQ/Main'));

const ReviewWidgetGenerator = lazy(() => import('./views/ReviewWidgetGenerator/Main'));

const AddEditDealsContainer = lazy(() => import('./views/Deals/AddEditDealsContainer'));

const hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <AbilityContext.Provider value={getAbility()}>
            <GlobalProvider>
                <ErrorBoundary>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            <Route
                                path="/forgot-password"
                                component={ForgotPassword}
                            />
                            <Route
                                path="/reset-password"
                                component={ResetPassword}
                            />
                            <ProtectedRoute
                                mode="read"
                                subject="ClientDashboard"
                                path="/client-admin"
                                component={(props) => (
                                    <ClientAdmin {...props} />
                                )}
                            />
                            <Route
                                path="/confirm-invite/"
                                component={SetPassword}
                            />
                            <ProtectedRoute
                                mode="read"
                                subject="ClientProfile"
                                path="/client/profile/:id/"
                                component={(props) => (
                                    <ClientIndividualAccount {...props} />
                                )}
                                redirectUrl="/client-admin"
                            />
                            <Route
                                exact
                                mode="read"
                                subject="Collections"
                                path="/collections/:id?"
                                component={(props) => (
                                    <Collections {...props} />
                                )}
                            />
                            <ProtectedRoute
                                exact
                                mode="read"
                                subject="Sales and Collections Summary"
                                path="/sales-and-collections-summary"
                                component={SalesAndCollectionsSummary}
                            />
                            <Route
                                exact
                                path="/articles/create"
                                component={(props) => <CMS {...props} />}
                            />
                            <ProtectedRoute
                                mode="update"
                                subject="Article"
                                path="/articles/edit/:id?"
                                component={(props) => <CMS {...props} />}
                            />
                            <ProtectedRoute
                                mode="update"
                                subject="Guide"
                                path="/guides/edit/:id?"
                                component={EditGuideTool}
                            />
                            <ProtectedRoute
                                mode="update"
                                subject="Guide"
                                path="/faq/edit/:id?"
                                component={EditFAQTool}
                            />
                            <ProtectedRoute
                                mode="update"
                                subject="Interview"
                                path="/interviews/edit/:id?"
                                component={(props) => (
                                    <InterviewCMS {...props} />
                                )}
                            />
                            <ProtectedRoute
                                exact
                                mode="create"
                                subject="Invoice"
                                path="/invoice/create"
                                component={(props) => (
                                    <AddEditInvoiceContainer {...props} />
                                )}
                            />
                            <ProtectedRoute
                                mode="update"
                                subject="Invoice"
                                path="/invoice/edit/:id?"
                                component={(props) => (
                                    <AddEditInvoiceContainer {...props} />
                                )}
                            />
                            <ProtectedRoute
                                exact
                                mode="read"
                                subject="Invoice"
                                path="/invoice/view/:id?"
                                component={(props) => (
                                    <ViewInvoiceContainer {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/invoice/success/:checkout_id"
                                component={(props) => (
                                    <PaymentSuccess {...props} />
                                )}
                            />
                            <ProtectedRoute
                                path="/ad/create"
                                mode="create"
                                subject="Advertisement"
                                component={(props) => (
                                    <CreateEditAd {...props} mode="create" />
                                )}
                            />
                            <ProtectedRoute
                                path="/ad/:id/edit"
                                mode="update"
                                subject="Advertisement"
                                component={(props) => (
                                    <CreateEditAd {...props} />
                                )}
                            />
                            <ProtectedRoute
                                path="/listing/create"
                                mode="create"
                                subject="Program"
                                component={(props) => (
                                    <AddEditProgram {...props} />
                                )}
                            />
                            <ProtectedRoute
                                exact
                                mode="create"
                                subject="AdminAddDeals"
                                path="/mygoabroad/deals/add"
                                component={AddEditDealsContainer}
                            /> 
                            <ProtectedRoute
                                exact
                                mode="update"
                                subject="AdminAddDeals"
                                path="/mygoabroad/deals/edit/:id?"
                                component={AddEditDealsContainer}
                            />
                            <ProtectedRoute
                                path={["/site-tools/seo-tool/:id", "/site-tools/seo-tool/"]}
                                mode="create"
                                subject="SEO Meta"
                                component={(props) => <SEOTool {...props} />}
                            />
                            <ProtectedRoute    
                                path="/review-widget-generator"
                                mode="read"
                                subject="Review Widget Generator"
                                component={ReviewWidgetGenerator}
                            />
                            <Route exact path="/login" component={Login} />
                            <Route
                                exact
                                path="/admin-login/thank-you"
                                component={Login}
                            />
                            <ProtectedRoute
                                path="/listing/:programId/edit"
                                mode="update"
                                subject="Program"
                                component={(props) => (
                                    <AddEditProgram {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/accept/primary-contact"
                                component={(props) => (
                                    <ReassignContactPortal {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/accept/billing-contact"
                                component={(props) => (
                                    <ReassignContactPortal {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/invoice/reports"
                                component={(props) => <InvoiceReporting />}
                            />
                            <Route
                                exact
                                path="/page-not-found"
                                component={(props) => (
                                    <NotFound />
                                )}
                            />
                            <Route
                                exact
                                path="/unauthorized"
                                component={(props) => (
                                    <Forbidden />
                                )}
                            />
                            <ProtectedRoute
                                path="/"
                                mode="read"
                                subject="Dashboard"
                                redirectUrl="/client-admin/dashboard"
                                component={(props) => <Dashboard {...props} />}
                            />
                            <Route
                                component={(props) => (
                                    <Redirect
                                        push
                                        to={{
                                            pathname: '/page-not-found'
                                        }}
                                    />
                                )}
                            />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </GlobalProvider>
        </AbilityContext.Provider>
    </Router>,
    document.getElementById('root')
);
