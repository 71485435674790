import React, { useEffect, useState } from 'react';
import ResultsTable from '../../components/ResultsTable/ResultsTable';
import { useDealState, useDealMethods } from './DealProvider';
import { useGlobalState, useGlobalMethods } from '../Client/GlobalProvider';
import { useObjectState } from '../../utilities/customHooks';
import DialogBox from '../../components/DialogBox/DialogBox';
import { makeStyles } from '@material-ui/core';
import { blue, yellow } from '@material-ui/core/colors';
import LoadingDialog from '../../components/Loader/LoadingDialog';
import {
    DEAL_TYPE,
    TEAM_ASSIGNMENT,
    DEAL_STATUS,
} from '../../config/constants';
import GlobalFilterDrawer from '../../components/Drawer/GlobalFilterDrawer';
import { removeNull } from '../../helpers/dataUtility';
import { useSnackbar } from 'notistack';
import { postRequest } from '../../helpers/apiRequestUtility';
import TeamIcon from '../../components/Icons/TeamIcon';
import { Typography, TextField } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    homepage: {
        color: blue[500],
    },
    directory: {
        color: yellow[700],
    },
    red: {
        color: '#ff6969',
    },
    row: {
        '& td': {
            width: 150,
            minWidth: 120,
        },
        '& td:nth-child(1)': {
            width: 50,
            minWidth: 50,
        },
        '& td:nth-child(2)': {
            width: 90,
            minWidth: 80,
        },

        '& td:nth-child(3)': {
            width: 90,
            minWidth: 80,
        },
        '& td:nth-child(4)': {
            width: 170,
            minWidth: 120,
        },
        '& td:nth-child(6)': {
            width: 100,
            minWidth: 100,
        },
        '& td:nth-child(7)': {
            width: 100,
            minWidth: 100,
        },
        '& td:last-child': {
            width: 60,
            minWidth: 60,
        },
    },
    dialogContent: {
        '& .MuiDialog-paper': {
            overflowY: 'visible',
        },
        '& .MuiDialogContent-root': {
            overflowY: 'visible',
        },
    },
    expirationTitle: {
        marginBottom: theme.spacing.unit * 1,
    },
    expirationDatepicker: {
        display: 'flex',
    },
}));

const SORT_COLUMNS = ['date_created', 'expiration'];

const GLOBAL_FILTERS = ['team'];

const ResultsMain = ({ active }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { fetchDeals } = useDealMethods();
    const {
        providers,
        countries,
        ageRanges,
        educationStatus,
        fetchingProviders,
        fetchingCountries,
        fetchingAgeRanges,
        fetchingEducationStatus,
    } = useGlobalState();
    const {
        fetchProviders,
        fetchCountries,
        fetchAgeRanges,
        fetchEducationStatus,
    } = useGlobalMethods();
    const {
        data,
        count,
        page,
        rowsPerPage,
        fetching,
        filters,
        sort,
        sortOrder,
        setDealState,
        showGlobalFilters,
        showColumnFilters,
        buffer_status,
    } = useDealState();
    const [state, setState] = useObjectState({
        award_name: '',
        directory_id: [],
        location: [],
        types: [],
        type: null,
        date_created: '',
        deadline_date: '',
        fetchingTypes: false,
        is_featured_in_directory: null,
        is_featured_in_homepage: null,
        is_active: null,
        isSaving: false,
        selected: [],
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: '',
        },
        selectedRow: {},
        renewTitle: '',
        showRenewDialog: false,
        isRenew: false,
        renewExpiration: null,
        isBatch: false,
        disableRenew: true,
        disableArchive: true,
        message: '',
    });
    const [newExpiration, setNewExpiration] = useState([]);

    const columns = [
        {
            name: 'Type',
            key: 'type',
            type: 'status_chip:deal_type',
            filter: {
                type: 'select',
                options: Object.keys(DEAL_TYPE).map((key) => ({
                    value: DEAL_TYPE[key],
                    label: key.toUpperCase(),
                })),
            },
        },
        {
            name: 'Status',
            key: 'deal_status',
            type: 'status_chip:deal_status',
            format: 'bold',
            filter: {
                type: 'select',
                options: Object.keys(DEAL_STATUS).map((key) => ({
                    value: DEAL_STATUS[key],
                    label: key.toUpperCase(),
                })),
                value: buffer_status.deal_status,
                onChange: (status) => {
                    handleDealStatus(status.value);
                },
            },
        },
        {
            name: 'Title',
            key: 'title',
            type: 'text',
            format: 'bold',
            filter: {
                type: 'text',
                value: state.award_name,
            },
        },
        {
            name: 'Client Name',
            key: 'provider_name',
            filter: {
                type: 'select',
                options: providers || [],
                isLoading: fetchingProviders,
                optionKey: 'name',
                optionValue: 'id',
                isMultiSelect: true,
            },
        },
        {
            name: 'Program ID',
            key: 'program_id',
            type: 'text',
            filter: {
                type: 'text',
                alterValue: {
                    type: 'radio',
                    options: [
                        { value: 'is_all_programs', label: 'All Programs' },
                    ],
                },
            },
        },
        {
            name: 'Create Date',
            key: 'date_created',
            type: 'date',
            sort: sort === 'date_created' ? sortOrder : undefined,
            filter: {
                type: 'date',
                value: state.date_created,
            },
        },
        {
            name: 'Expiration Date',
            key: 'expiration',
            type: 'date',
            sort: sort === 'expiration' ? sortOrder : undefined,
            filter: {
                type: 'date',
                value: state.expiration_date,
            },
        },
        {
            name: 'Nationalities',
            key: 'nationalities',
            type: 'text',
            filter: {
                type: 'multiselect',
                options: countries || [],
                isLoading: fetchingCountries,
            },
        },
        {
            name: 'Ages',
            key: 'age_range_names',
            type: 'text',
            filter: {
                type: 'multiselect',
                options: ageRanges || [],
                isLoading: fetchingAgeRanges,
            },
        },
        {
            name: 'Education Level',
            key: 'education_status_names',
            type: 'text',
            filter: {
                type: 'multiselect',
                options: educationStatus || [],
                isLoading: fetchingEducationStatus,
            },
        },
    ];

    const rowMenus = [
        {
            name: 'edit',
            text: 'Edit/Review',
        },
        {
            name: 'renew',
            text: 'Renew',
            handler: (row) => {
                getNewExpiration();
                setState({
                    isRenew: true,
                    isBatch: false,
                    selectedRow: row,
                    dialog: {
                        title: `Renew Deal`,
                        content: `Are you sure you want to renew the selected deal(s)? This may affect the leads details.`,
                        stringOverride: {
                            primaryAction: "Yes I'm sure",
                            secondaryAction: 'Cancel',
                        },
                    },
                });
            },
        },
        {
            name: 'archive',
            text: 'Archive',
            handler: (row) => {
                handleShowActionConfirmation('archive', () =>
                    handleArchiveDeals(false, row)
                );
            },
        },
        {
            name: 'delete',
            text: 'Delete',
            handler: (row) => {
                handleShowActionConfirmation('delete', () =>
                    handleDeleteDeals(false, row)
                );
            },
        },
    ];

    const rowMenusChecker = (row, rowMenus) =>
        rowMenus
            .filter((menu) => {
                if (menu.name === 'archive')
                    return (
                        row.status === 1 &&
                        row.is_edited === 0 &&
                        row.deal_status !== 4
                    );
                else if (menu.name === 'renew')
                    return (
                        (row.status === 1 || row.status === 2) &&
                        row.is_edited === 0
                    );
                return true;
            })
            .map((menu) => {
                if (menu.name === 'edit') {
                    menu.url = `/mygoabroad/deals/edit/${row.id}`;
                }
                return menu;
            });

    const rowClasses = [{ className: classes.row }];

    const searchOptions = [];

    const getBatchActions = () => {
        const { disableArchive, disableRenew } = state;

        const batchActions = [
            {
                icon: 'renew',
                handler: () => {
                    getNewExpiration();
                    setState({
                        isRenew: true,
                        isBatch: true,
                        selectedRow: state.selected,
                        dialog: {
                            title: `Renew Deal`,
                            content: `Are you sure you want to renew the selected deal(s)? This may affect the leads details.`,
                            stringOverride: {
                                primaryAction: "Yes I'm sure",
                                secondaryAction: 'Cancel',
                            },
                        },
                    });
                },
                disabled: disableRenew,
            },
            {
                icon: 'archive',
                handler: () =>
                    handleShowActionConfirmation('archive', () =>
                        handleArchiveDeals(true)
                    ),
                disabled: disableArchive,
            },
            {
                icon: 'delete',
                handler: () =>
                    handleShowActionConfirmation('delete', () =>
                        handleDeleteDeals(true)
                    ),
            },
        ];

        return batchActions;
    };

    const icons = [
        {
            name: 'teams_icon',
            icon: <TeamIcon variant="unassigned" />,
            placement: 'right',
            column: 'title',
        },
        {
            name: 'inactive_icon',
            icon: null,
            placement: 'right',
            column: 'title',
        },
    ];

    const iconsChecker = (row, icons) => {
        return icons.map((icon) => {
            if (icon.name === 'teams_icon') {
                const icon_alias = (TEAM_ASSIGNMENT[row.team] || 'Unassigned')
                    .toLowerCase()
                    .replace(' ', '-');
                icon.icon = <TeamIcon variant={icon_alias} />;
            }
            return icon;
        });
    };

    const getNewExpiration = () => {
        const renewExpiration = moment(new Date())
            .add(1, 'years')
            .format('YYYY-MM-DD');

        setNewExpiration(renewExpiration);
    };

    const handleDealStatus = (dealStatus) => {
        let filter_params = {};
        let status = '';

        if (dealStatus === 4) {
            //pending
            setDealState({ deal_status: 4, dstatus: {}, is_edited: 1 });
            status = 'pending';
        } else if (dealStatus === 0) {
            //approved
            setDealState({ deal_status: 0, dstatus: 1, is_edited: 0 });
            status = 'approved';
        } else if (dealStatus === 1) {
            //archived
            setDealState({ deal_status: 1, dstatus: 2, is_edited: {} });
            status = 'archived';
        } else if (dealStatus === 2) {
            //expired
            setDealState({ deal_status: 2, is_edited: 0, dstatus: 1 });
            status = 'expired';
        } else if (dealStatus === 3) {
            //expiring
            setDealState({ deal_status: 3, is_edited: {}, dstatus: {} });
            status = 'expiring';
        }

        filter_params['deal_status'] = {
            value: DEAL_STATUS[status],
            label: status.toUpperCase(),
        };

        setDealState({
            buffer_status: { ...filter_params },
        });
    };

    const handleRenewDeals = async () => {
        const data = new FormData();
        const { selectedRow } = state;

        const selectedIds = state.isBatch
            ? selectedRow.map((row) => row.id)
            : [selectedRow.id];

        data.append('id', selectedIds.join());
        data.append('expiration', newExpiration);

        setState({
            isRenew: false,
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {},
            },
        });

        if (selectedIds.length) {
            setState({
                isSaving: true,
                message: 'Renewing Deal(s)',
                fetching: true,
            });

            await postRequest(
                `/deals/renew`,
                data,
                (success) => {
                    setState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Deal(s) have been renewed!`,
                        {
                            variant: 'success',
                        }
                    );
                    fetchDeals();
                },
                (error) => {
                    setState({ isSaving: false, fetching: false });
                    enqueueSnackbar('Failed to renew Deal(s).', {
                        variant: 'error',
                    });
                }
            );
        } else {
            enqueueSnackbar('No Deal(s) have been renewed.', {
                variant: 'error',
            });
        }
    };

    const handleArchiveDeals = async (isBatch, scholarship) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch
            ? selected.map((row) => row.id)
            : [scholarship.id];

        data.append('id', selectedIds.join());

        setState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {},
            },
        });

        if (selectedIds.length) {
            setState({
                isSaving: true,
                message: 'Archiving Deal(s)',
                fetching: true,
            });

            await postRequest(
                `/deals/archive`,
                data,
                (success) => {
                    setState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Deal(s) have been archived!`,
                        {
                            variant: 'success',
                        }
                    );
                    fetchDeals();
                },
                (error) => {
                    setState({ isSaving: false, fetching: false });
                    enqueueSnackbar('Failed to archive Deal(s).', {
                        variant: 'error',
                    });
                }
            );
        } else {
            enqueueSnackbar('No Deal(s) have been archived.', {
                variant: 'error',
            });
        }
    };

    const handleShowActionConfirmation = (batchAction, func) => {
        setState({
            dialog: {
                show: true,
                title: `${batchAction} Deal`,
                content: `Are you sure you want to ${batchAction} the selected deal(s)? This may affect the leads details.`,
                stringOverride: {
                    primaryAction: "Yes I'm sure",
                    secondaryAction: 'Cancel',
                },
                onOk: func,
            },
        });
    };

    const handleDeleteDeals = async (isBatch, scholarship) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch
            ? selected.map((row) => row.id)
            : [scholarship.id];

        data.append('id', selectedIds.join());

        setState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {},
            },
        });

        if (selectedIds.length) {
            setState({
                isSaving: true,
                message: 'Deleting Deal(s)',
                fetching: true,
            });

            await postRequest(
                `/deals/delete`,
                data,
                (success) => {
                    setState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Deal(s) have been deleted!`,
                        {
                            variant: 'success',
                        }
                    );
                    fetchDeals();
                },
                (error) => {
                    setState({ isSaving: false, fetching: false });
                    enqueueSnackbar('Failed to delete Deal(s).', {
                        variant: 'error',
                    });
                }
            );
        } else {
            enqueueSnackbar('No Deal(s) have been deleted.', {
                variant: 'error',
            });
        }
    };

    const handleCloseDialog = () => {
        setState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
            },
            isRenew: false,
            isBatch: false,
            selectedRow: {},
        });
    };

    const handleSelectMultiple = (selected) => {
        let disableRenew = !selected.every(
            (row) =>
                (row.status === 1 || row.status === 2) && row.is_edited === 0
        );

        let disableArchive = !selected.every((row) => {
            return (
                row.status === 1 && row.is_edited === 0 && row.deal_status !== 4
            );
        });

        setState({
            selected,
            disableRenew: disableRenew,
            disableArchive: disableArchive,
        });
    };

    const handlePageChange = (page) => setDealState({ page });

    const handleRowsPerPageChange = (rpp) =>
        setDealState({ rowsPerPage: rpp, page: 0 });

    const handleColumnFiltersOpen = () =>
        setDealState({ showColumnFilters: true });

    const handleColumnFilterClose = () => {
        const gFilters = {};
        //eslint-disable-next-line array-callback-return
        Object.keys(filters).map((key) => {
            if (GLOBAL_FILTERS.includes(key)) {
                gFilters[key] = filters[key];
            }
        });
        setDealState({
            filters: removeNull(gFilters),
            deal_status: {},
            dstatus: {},
            is_edited: {},
            buffer_status: {},
        });
    };

    const handleColumnFiltersApply = (cFilters) => {
        const gFilters = {};
        //eslint-disable-next-line array-callback-return
        Object.keys(filters).map((key) => {
            if (GLOBAL_FILTERS.includes(key)) {
                gFilters[key] = filters[key];
            }
        });

        setDealState({
            page: 0,
            filters: { ...removeNull({ ...(cFilters || {}) }), ...gFilters },
        });
    };

    const handleApplyFilter = (gFilters) => {
        setDealState({
            showGlobalFilters: false,
            filters: { ...removeNull({ ...filters, ...gFilters }) },
            page: 0,
        });
    };

    const handleToggleGlobalFilters = (open) =>
        setDealState({ showGlobalFilters: open });

    const handleSort = ({ key }) => {
        if (SORT_COLUMNS.includes(key))
            setDealState({
                sort: key,
                sortOrder:
                    key === sort
                        ? sortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                        : 'desc',
            });
    };

    useEffect(() => {
        if (!fetchingProviders && providers.length < 1) {
            fetchProviders();
        }
    }, [providers, fetchingProviders, fetchProviders]);

    useEffect(() => {
        if (!fetchingCountries && countries.length < 1) {
            fetchCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, fetchingCountries]);

    useEffect(() => {
        if (!fetchingAgeRanges && ageRanges.length < 1) {
            fetchAgeRanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ageRanges, fetchingAgeRanges]);

    useEffect(() => {
        if (!fetchingEducationStatus && educationStatus.length < 1) {
            fetchEducationStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [educationStatus, fetchingEducationStatus]);

    if (!active) return null;

    const hasGlobalFilters =
        Object.keys(filters).includes('paidProgram') ||
        Object.keys(filters).includes('team');

    return (
        <>
            <ResultsTable
                label={data.length > 1 ? 'deals' : 'deal'}
                isLoadingData={fetching}
                data={data}
                columns={columns}
                count={count || data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenusChecker}
                batchActions={getBatchActions()}
                rowClasses={rowClasses}
                icons={icons}
                iconsChecker={iconsChecker}
                showColumnFilters={showColumnFilters}
                searchFilterOptions={searchOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                selected={state.selected}
                onSelectedChange={handleSelectMultiple}
                onColumnFilterOpen={handleColumnFiltersOpen}
                onColumnFilterClose={handleColumnFilterClose}
                onColumnFiltersApply={handleColumnFiltersApply}
                onGlobalFiltersClick={() => handleToggleGlobalFilters(true)}
                onColumnHeaderClick={handleSort}
                hasSearch={false}
                activeGlobalFilters={hasGlobalFilters}
            />
            <GlobalFilterDrawer
                open={showGlobalFilters}
                accountStatus={false}
                onApplyFilter={handleApplyFilter}
                onClose={() => handleToggleGlobalFilters(false)}
            />
            <DialogBox
                actions="OkCancel"
                title={state.dialog.title.toUpperCase()}
                contentText={state.dialog.content}
                open={state.dialog.show}
                onOk={state.dialog.onOk}
                onCancel={handleCloseDialog}
                onClose={handleCloseDialog}
                stringOverride={state.dialog.stringOverride}
            />

            <DialogBox
                actions="OkCancel"
                title={state.dialog.title.toUpperCase()}
                contentText={state.dialog.content}
                open={state.isRenew}
                onOk={() => {
                    handleRenewDeals();
                }}
                onCancel={handleCloseDialog}
                onClose={handleCloseDialog}
                stringOverride={state.dialog.stringOverride}
            >
                {state.isRenew && (
                    <div>
                        <div>
                            <Typography className={classes.expirationTitle}>
                                Are you sure you want to renew the selected
                                deal(s)? This may affect the leads details.
                            </Typography>
                        </div>
                        <TextField
                            label={'Select Expiration Date'}
                            type="date"
                            value={newExpiration}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                setNewExpiration(event.target.value);
                            }}
                            className={classes.expirationDatepicker}
                        />
                    </div>
                )}
            </DialogBox>
            <LoadingDialog open={state.isSaving} message={state.message} />
        </>
    );
};

export default ResultsMain;
