import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Grow from '@material-ui/core/Grow';

const styles = theme => ({
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    paper: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 4,
        paddingTop: theme.spacing.unit * 2
    },
    toolbar: {
        padding: 0,
        justifyContent: 'space-between'
    },
    circle: {
        height: 20,
        width: 20,
        backgroundColor: grey[100],
        borderRadius: 10,
        display: 'inline-block',
        margin: `0 ${theme.spacing.unit * 2}px`,
        '&:first-of-type, &:last-of-type': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    iconButtonCircle: {
        height: 30,
        width: 30,
        backgroundColor: grey[100],
        borderRadius: 15,
        display: 'inline-block',
        margin: `0 ${theme.spacing.unit}px`,
        '&:last-of-type': {
            marginRight: 0
        },
        '&:first-of-type': {
            marginLeft: 0
        }
    },
    box: {
        height: 20,
        width: 20,
        backgroundColor: grey[100],
        borderRadius: 5,
        display: 'inline-block',
        margin: `0 ${theme.spacing.unit * 2}px`,
        '&:first-of-type, &:last-of-type': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    shortLine: {
        height: 20,
        width: 100,
        backgroundColor: grey[100],
        borderRadius: 10,
        display: 'inline-block',
        margin: `0 ${theme.spacing.unit * 2}px`,
        '&:first-of-type, &:last-of-type': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    mediumLine: {
        height: 20,
        width: 150,
        backgroundColor: grey[100],
        borderRadius: 10,
        display: 'inline-block',
        margin: `0 ${theme.spacing.unit * 2}px`,
        '&:first-of-type, &:last-of-type': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    longLine: {
        height: 20,
        width: 300,
        backgroundColor: grey[100],
        borderRadius: 10,
        display: 'inline-block',
        margin: `0 ${theme.spacing.unit * 2}px`,
        '&:first-of-type, &:last-of-type': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    footer: {
        '& td': {
            textAlign: '-webkit-right'
        }
    },
    shimmers: {
        animationDuration: '1.5s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        background:
            'linear-gradient(to right, #f5f5f5 8%, #fafafa 18%, #f5f5f5 33%)',
        backgroundSize: '800px 104px',
        animationName: 'shimmers'
    },

    '@keyframes shimmers': {
        '0%': {
            backgroundPosition: '-468px 0'
        },
        '100%': {
            backgroundPosition: '468px 0'
        }
    }
});

const Loader = props => {
    const { classes } = props;

    return (
        <div className={classes.loader}>
            <CircularProgress color="primary" disableShrink />
        </div>
    );
};

const TablePlaceholder = props => {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <Toolbar className={classes.toolbar}>
                <Grow in={true} timeout={300}>
                    <div
                        className={`${classes.mediumLine} ${classes.shimmers}`}
                    ></div>
                </Grow>
                <div>
                    <Grow in={true} timeout={400}>
                        <div
                            className={`${classes.iconButtonCircle} ${classes.shimmers}`}
                        ></div>
                    </Grow>
                    <Grow in={true} timeout={400}>
                        <div
                            className={`${classes.iconButtonCircle} ${classes.shimmers}`}
                        ></div>
                    </Grow>
                </div>
            </Toolbar>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Grow in={true} timeout={500}>
                                <div
                                    className={`${classes.iconButtonCircle} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                        <TableCell>
                            <Grow in={true} timeout={500}>
                                <div
                                    className={`${classes.shortLine} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                        <TableCell>
                            <Grow in={true} timeout={500}>
                                <div
                                    className={`${classes.shortLine} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Grow in={true} timeout={500}>
                                <div
                                    className={`${classes.iconButtonCircle} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                        <TableCell>
                            <Grow in={true} timeout={500}>
                                <div
                                    className={`${classes.longLine} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                        <TableCell>
                            <Grow in={true} timeout={500}>
                                <div
                                    className={`${classes.longLine} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter className={classes.footer}>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <Grow in={true} timeout={600}>
                                <div
                                    className={`${classes.shortLine} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                            <Grow in={true} timeout={700}>
                                <div
                                    className={`${classes.circle} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                            <Grow in={true} timeout={700}>
                                <div
                                    className={`${classes.circle} ${classes.shimmers}`}
                                ></div>
                            </Grow>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
};

const TableRowPlaceholder = props => {
    const { classes, colSpan = 2, withCheckbox = true } = props;
    let lines = [];

    for (let index = 0; index <= colSpan; index++) {
        lines.push(
            <TableCell key={`loader-column-${index}`}>
                {index < colSpan ? (
                    <Grow in={true} timeout={500}>
                        <div
                            className={`${classes.shortLine} ${classes.shimmers}`}
                        ></div>
                    </Grow>
                ) : null}
            </TableCell>
        );
    }

    return (
        <Fragment>
            <TableRow colsspan={colSpan}>
                {withCheckbox && (
                    <TableCell>
                        <Grow in={true} timeout={500}>
                            <div
                                className={`${classes.box} ${classes.shimmers}`}
                            ></div>
                        </Grow>
                    </TableCell>
                )}
                {lines}
            </TableRow>
            <TableRow colsspan={colSpan}>
                {withCheckbox && (
                    <TableCell>
                        <Grow in={true} timeout={500}>
                            <div
                                className={`${classes.box} ${classes.shimmers}`}
                            ></div>
                        </Grow>
                    </TableCell>
                )}
                {lines}
            </TableRow>
        </Fragment>
    );
};

export const TableLoader = withStyles(styles)(TablePlaceholder);

export const TableRowLoader = withStyles(styles)(TableRowPlaceholder);

export default withStyles(styles)(Loader);
