import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Paper, Typography, withStyles } from '@material-ui/core';
import { withState, withProps } from './StatsTableProvider';

const styles = (theme) => ({
    paper: {
        boxShadow: 'none',
        fontFamily: 'Roboto'
    },
    displayFilter: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    label: {
        padding: '0',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555'
    },
    displayFilterSelect: {
        minWidth: '200px',
        marginLeft: '1em'
    }
});

const usedStates = [];
const usedProps = [
    'isLoading',
    'onDisplayFilterChange',
    'displayBy',
    'compareID',
    'compare'
];

const DisplayFilter = ({
    classes,
    isLoading,
    displayBy = 'month',
    compareID,
    compare,
    onDisplayFilterChange = () => {}
}) => {
    const [selected, setSelected] = useState({
        label: 'Month',
        value: 'month'
    });

    const displayFilterOptions = [
        { label: 'Month', value: 'month', isDisabled: compareID === 3 },
        { label: 'Day', value: 'day', isDisabled: compareID === 3 },
        { label: 'Week', value: 'week', isDisabled: compareID === 3 },
        { label: 'Range', value: 'range', isDisabled: !compare },
        { label: 'All Locations', value: 'location', isDisabled: false },
        { label: 'All Directories', value: 'directory', isDisabled: false },
        { label: 'Ad Type', value: 'ad_type', isDisabled: false },
        { label: 'All Listings', value: 'listing', isDisabled: false },
        { label: 'All Articles', value: 'article', isDisabled: false },
        { label: 'All Interviews', value: 'interview', isDisabled: false }
    ];

    const handleChange = (value) => {
        setSelected(value);
        onDisplayFilterChange(value);
    };

    useEffect(() => {
        const [value] = displayFilterOptions.filter((option) => {
            return option.value === displayBy;
        });
        setSelected(value || selected);
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [displayBy]);

    return (
        <Paper elevation={1} className={classes.paper}>
            <div className={classes.displayFilter}>
                <Typography className={classes.label}>Display By: </Typography>
                <Select
                    isDisabled={isLoading}
                    value={selected}
                    onChange={handleChange}
                    className={classes.displayFilterSelect}
                    options={displayFilterOptions}
                />
            </div>
        </Paper>
    );
};

export default withState(usedStates)(
    withProps(usedProps)(withStyles(styles)(DisplayFilter))
);
