import React from 'react';
import ResultsMain from './ResultsMain';
import AdUrlVerifierProvider from './AdUrlVerifierProvider';

const AdUrlVerifier = ({ active }) => {
    if (!active) return null;
    return (
        <AdUrlVerifierProvider>
            <ResultsMain />
        </AdUrlVerifierProvider>
    );
};

export default AdUrlVerifier;
