import {
    Grid,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        position: 'absolute',
        marginTop: 10,
        zIndex: 3
    },
    input: {
        width: 60
    },
    button: { marginTop: theme.spacing(1) }
}));


export default function RangeFilter({
    currentValue,
    onChange = () => {}
}) {
    const classes = useStyles();
    const startRef = useRef();
    const endRef = useRef();

    const handleChange = (e) => {
        const start = startRef.current.value;
        const end = endRef.current.value;
        onChange({ start, end });
    };
    

    useEffect(() => {
        if (currentValue) {
            startRef.current.value = currentValue.start
            endRef.current.value = currentValue.end
        } else {
            startRef.current.value = null
            endRef.current.value = null
        }
    }, [currentValue])

    return (
        <>
            <Typography>Select Range</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography variant="overline" display="block">
                        Start
                    </Typography>
                    <TextField
                        inputRef={startRef}
                        className={classes.input}
                        type="number"
                        autoFocus
                        inputProps={{
                            max: endRef.current && endRef.current.value ? endRef.current.value : undefined
                        }}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    alignItems="baseline"
                    justify="center"
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography display="block"> - </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="overline" display="block">
                        End
                    </Typography>
                    <TextField
                        inputRef={endRef}
                        className={classes.input}
                        type="number"
                        inputProps={{ min: startRef.current ? startRef.current.value : 0 }}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </>
    );
}
