import React, { useEffect } from 'react';
import {
    Drawer,
    makeStyles,
    Paper,
    Typography,
    Button,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Check';
import { useGlobalState, useGlobalMethods } from '../Client/GlobalProvider';
import SearchBasedDropdown from '../../components/SearchBasedDropdown/SearchBasedDropdown';
import { DIRECTORIES } from '../../utilities/directory';
import { useObjectState } from '../../utilities/customHooks';
import {
    getCriteriaByDirectory,
    fetchCriteriaOptions
} from '../../utilities/searchCriteria';
import { useGuidesState, useGuidesMethods } from './GuidesProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36
            }
        }
    },
    paper: {
        padding: theme.spacing(2)
    },
    counter: {
        alignSelf: 'end'
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',
        '& button:first-child': {
            marginRight: theme.spacing(2)
        }
    },
    datePicker: {
        display: 'flex'
    }
}));

const DataFilterDrawer = (props) => {
    const classes = useStyles();
    const { providers, fetchingProviders } = useGlobalState();
    const { fetchProviders } = useGlobalMethods();
    const {
        setGuidesState,
        dataFilterOpen,
        filters,
        locations,
        fetchingLocations
    } = useGuidesState();
    const { fetchLocations } = useGuidesMethods();
    const [state, setState] = useObjectState({
        directory: null,
        location: null,
        type: null,
        timing: null,
        provider: null,
        online: null,
        isDirectoryOnly: false,
        exactLocation: false,
        isCriteriaDisabled: false,
        locationOptions: []
    });

    const handleFieldChange = (field) => (value) =>
        setState({ [field]: value });

    const handleOnlineChange = (e) => {
        const online = e.target.checked ? { label: 'Online', value: 1 } : null;
        const isLocationDisabled = e.target.checked;
        setState({ online, isLocationDisabled, location: null });
    };

    const handleApplyFilters = () => {
        setGuidesState({
            filters: {
                ...filters,
                directory: state.directory,
                location: state.location,
                type: state.type,
                timing: state.timing,
                provider: state.provider,
                online: state.online,
                isDirectoryOnly: state.isDirectoryOnly,
                exactLocation: state.exactLocation,
                urlParams: {}
            },
            dataFilterOpen: false,
            page_url: '',
            data: [],
            page: 0
        });
    };

    const handleClearFilters = () => {
        setState({
            directory: null,
            location: null,
            type: null,
            timing: null,
            provider: null,
            online: null,
            isDirectoryOnly: false,
            exactLocation: false,
            isCriteriaDisabled: false
        });
        setGuidesState({
            filters: {
                ...filters,
                directory: null,
                location: null,
                type: null,
                timing: null,
                provider: null,
                online: null,
                isDirectoryOnly: false,
                exactLocation: false
            },
            dataFilterOpen: false,
            data: [],
            page: 0
        });
    };

    const handleIsDirectoryOnlyChange = (e) => {
        const isDirectoryOnly = e.target.checked;
        if (isDirectoryOnly) {
            setState({
                ...state,
                isDirectoryOnly,
                location: null,
                type: null,
                timing: null,
                online: null,
                exactLocation: false,
                isCriteriaDisabled: true,
                isLocationDisabled: true
            });
        } else {
            const { exactLocation } = state;
            const isCriteriaDisabled = exactLocation;
            setState({
                ...state,
                isDirectoryOnly,
                isCriteriaDisabled,
                isLocationDisabled: false
            });
        }
    };

    const handleExactLocationChange = (e) => {
        const exactLocation = e.target.checked;
        if (exactLocation) {
            setState({
                ...state,
                exactLocation,
                type: null,
                timing: null,
                online: null,
                isCriteriaDisabled: true
            });
        } else {
            const { isDirectoryOnly } = state;
            const isCriteriaDisabled = isDirectoryOnly;
            setState({ ...state, exactLocation, isCriteriaDisabled });
        }
    };

    useEffect(() => {
        const fetchTypes = (directory_id) =>
            new Promise(async (res, rej) => {
                const { [directory_id]: available } = getCriteriaByDirectory({
                    key: 'id',
                    val: [directory_id]
                });
                const criterias = Object.keys(available);

                if (criterias.includes('type') && !!available.type) {
                    const { new_criteria } = available.type;
                    res(await fetchCriteriaOptions(new_criteria));
                }

                res([]);
            });

        const fetchTimings = (directory_id) =>
            new Promise(async (res, rej) => {
                const { [directory_id]: available } = getCriteriaByDirectory({
                    key: 'id',
                    val: [directory_id]
                });
                const criterias = Object.keys(available);

                if (criterias.includes('timing') && !!available.timing) {
                    const { new_criteria } = available.timing;
                    res(await fetchCriteriaOptions(new_criteria));
                }

                res([]);
            });

        if (!!state.directory) {
            setState({
                type: null,
                fetchingTypes: true,
                fetchingTimings: true
            });
            fetchTypes(state.directory.value).then((data) => {
                const existingType = [...data]
                    .map(({ value }) => value)
                    .includes((state.type || {}).value);
                setState({
                    type: existingType ? state.type : null,
                    types: data || [],
                    fetchingTypes: false
                });
            });
            fetchTimings(state.directory.value).then((data) => {
                const existingTiming = [...data]
                    .map(({ value }) => value)
                    .includes((state.timing || {}).value);
                setState({
                    timing: existingTiming ? state.timing : null,
                    timings: data || [],
                    fetchingTimings: false
                });
            });
        } else {
            setState({
                type: null,
                types: [],
                timing: null,
                timings: [],
                fetchingTypes: false,
                fetchingTimings: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.directory]);

    useEffect(() => {
        if (!locations.length) fetchLocations();
        if (!providers.length) fetchProviders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setState({
            directory: filters.directory,
            location: filters.location,
            type: filters.type,
            timing: filters.timing,
            provider: filters.provider
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        if (state.online && state.directory) {
            const [{ has_online } = {}] = [...DIRECTORIES].filter(
                ({ id }) => state.directory.value === id
            );
            if (!has_online) setState({ directory: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.online]);

    useEffect(() => {
        setState({
            locationOptions: [...(locations || [])].sort((a, b) => {
                const [criteriaA] = a.id.split('_');
                const [criteriaB] = b.id.split('_');
                if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
                if (parseInt(criteriaA) < parseInt(criteriaB)) return -1;
                if (a.name > b.name) return 1;
                if (b.name < a.name) return -1;
                return 0;
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    const {
        location,
        type,
        types,
        directory,
        fetchingTypes,
        fetchingTimings,
        timing,
        timings,
        provider,
        online,
        isDirectoryOnly,
        exactLocation,
        isCriteriaDisabled,
        isLocationDisabled,
        locationOptions
    } = state;

    const hasOnline =
        directory &&
        directory.value &&
        DIRECTORIES.filter((i) => {
            return i.id === directory.value;
        })[0].has_online === 1
            ? true
            : false;

    const directories = [...DIRECTORIES]
        .filter(
            (directory) =>
                !!directory.page_number_tag || directory.abbrv === 'GA'
        )
        .filter(({ has_online }) => (online ? has_online : true));

    return (
        <Drawer
            open={dataFilterOpen}
            onClose={() => setGuidesState({ dataFilterOpen: false })}
            anchor="right"
        >
            <div className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                    <Typography variant="h6">Add Data Filters</Typography>
                    <br />
                    <div>
                        <Typography variant="overline">Directory</Typography>
                        <SearchBasedDropdown
                            arrayOptions={directories}
                            labelKey="name"
                            valueKey="id"
                            value={directory}
                            placeholder="Select Directory"
                            onChange={handleFieldChange('directory')}
                            isClearable
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!isDirectoryOnly}
                                    onChange={handleIsDirectoryOnlyChange}
                                />
                            }
                            label="Include directory only"
                        />
                    </div>
                    <Typography variant="overline">Location</Typography>
                    <SearchBasedDropdown
                        arrayOptions={locationOptions || []}
                        labelKey="name"
                        valueKey="id"
                        value={location}
                        isDisabled={
                            isLocationDisabled ||
                            fetchingLocations ||
                            (locations || []).length === 0
                        }
                        placeholder={
                            fetchingLocations
                                ? 'Loading ...'
                                : 'Select Location'
                        }
                        onChange={handleFieldChange('location')}
                        isClearable
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!exactLocation}
                                onChange={handleExactLocationChange}
                                disabled={
                                    isLocationDisabled ||
                                    fetchingLocations ||
                                    (locations || []).length === 0
                                }
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Exact Location"
                    />
                    <br />
                    <Typography variant="overline">Timing</Typography>
                    <SearchBasedDropdown
                        arrayOptions={timings}
                        value={timing}
                        isDisabled={
                            isCriteriaDisabled ||
                            fetchingTimings ||
                            (timings || []).length === 0
                        }
                        placeholder={
                            fetchingTimings ? 'Loading ...' : 'Select Timing'
                        }
                        onChange={handleFieldChange('timing')}
                        isClearable
                    />
                    <br />
                    <Typography variant="overline">Type</Typography>
                    <SearchBasedDropdown
                        arrayOptions={types}
                        value={type}
                        isDisabled={
                            isCriteriaDisabled ||
                            fetchingTypes ||
                            (types || []).length === 0
                        }
                        placeholder={
                            fetchingTypes ? 'Loading ...' : 'Select Type'
                        }
                        onChange={handleFieldChange('type')}
                        isClearable
                    />
                    <br />

                    <Typography variant="overline">Client Name</Typography>
                    <SearchBasedDropdown
                        arrayOptions={providers}
                        labelKey="name"
                        valueKey="id"
                        value={provider}
                        isDisabled={fetchingProviders}
                        placeholder={
                            fetchingProviders ? 'Loading ...' : 'Select Client'
                        }
                        onChange={handleFieldChange('provider')}
                        isClearable
                    />

                    {hasOnline && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        online && online.value ? true : false
                                    }
                                    onChange={handleOnlineChange}
                                    disabled={!!location || isDirectoryOnly}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Online"
                        />
                    )}
                </Paper>
                <br />
                <div className={classes.cta}>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disableElevation
                        disableFocusRipple
                        startIcon={<CloseIcon />}
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        data-cy="apply-data-filters"
                        variant="contained"
                        fullWidth
                        color="primary"
                        disableFocusRipple
                        disableElevation
                        startIcon={<AddIcon />}
                        onClick={handleApplyFilters}
                    >
                        Apply Filters
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default DataFilterDrawer;
