import {
    CLOUDINARY_FOLDER,
    CLOUDINARY_PATHS,
    CLOUDINARY_URL
} from '../config/constants';

export const cloudinaryImage = ({
    filename,
    path,
    params = {},
    version = '1'
}) => {
    const transform = Object.keys(params)
        .map((key) => `${key}_${params[key]}`)
        .join(',');
    return [
        `${CLOUDINARY_URL}`,
        transform,
        version ? `v${version}` : null,
        CLOUDINARY_FOLDER,
        path,
        filename
    ]
        .filter((value) => !['', null, undefined].includes(value))
        .join('/');
};

export const clientLogo = (filename, override = {}) =>
    cloudinaryImage({
        filename,
        params: { c: 'pad', h: 100, w: 100 },
        path: CLOUDINARY_PATHS.CLIENT_LOGO,
        ...override
    });

export default cloudinaryImage;
