import {
    Button,
    Grow,
    IconButton,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSEOToolMethods, useSEOToolState } from './SEOToolProvider';
import DialogBox from '../../../../components/DialogBox/DialogBox';
import LoadingDialog from '../../../../components/Loader/LoadingDialog';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: theme.breakpoints.width('lg'),
        margin: 'auto',
        display: 'flex',
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    back: {
        display: 'flex',
        marginRight: 'auto'
    },
    button: {
        marginLeft: theme.spacing(1)
    }
}));

const Header = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const { saving, fetching, available, isEdit } = useSEOToolState();
    const { handleSave } = useSEOToolMethods();
    const params = useParams();
    const handleExit = () => {
        history.push('/site-tools');
    };

    const handleUpdate = () => {
        handleSave(params);
    };


    if (fetching)
        return (
            <div className={classes.root}>
                <Grow in>
                    <Tooltip title="Exit" placement="bottom">
                        <IconButton
                            className={classes.back}
                            onClick={handleExit}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                </Grow>
            </div>
        );

    return (
        <div className={classes.root}>
            <Grow in>
                <Tooltip title="Exit" placement="bottom">
                    <IconButton className={classes.back} onClick={handleExit}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>
            </Grow>
            <Grow in>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isEdit ? false : !available}
                    disableElevation
                    disableFocusRipple
                    startIcon={<Save fontSize="small" />}
                    className={classes.button}
                    onClick={handleUpdate}
                >
                    Save Changes
                </Button>
            </Grow>
            <DialogBox
                actions="Ok"
                title={'Error!'}
                contentText={`Opps! We can't publish your FAQ until you complete each section title and fill in the content.`}
                open={showError}
                stringOverride={{
                    primaryAction: 'Okay'
                }}
                onOk={() => setShowError(false)}
            />
            <LoadingDialog open={saving} message="Saving changes..." />
        </div>
    );
};

export default Header;
