import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    IconButton,
    makeStyles,
    Menu,
    MenuItem
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DragHandle from '@material-ui/icons/DragHandle';
import TeamIcon from '../../../components/Icons/TeamIcon';
import {
    useTravelResourceRankingMethods,
    useTravelResourceRankingState
} from './TravelResourceRankingProvider';
import {
    GA_INSURANCE_URL,
    GA_URL,
    PARTNER_NAMES,
    TEAM_ASSIGNMENT
} from '../../../config/constants';
import { NavLink } from 'react-router-dom';
import { trimSlash } from '../../../helpers/dataUtility';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1)}px 0`,
        marginBottom: theme.spacing(1),

        '& div:first-child > p': {
            paddingLeft: theme.spacing(2)
        },
        '&:last-child': {
            marginBottom: theme.spacing(2)
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    },
    wrapper: {
        alignItems: 'center',
        justifyContent: 'flex-start',

        '& div': {
            display: 'flex',
            alignItems: 'center',

            '&:nth-of-type(2) p:first-child': {
                marginRight: theme.spacing(1)
            },
            '&:nth-of-type(2) p:not(:first-child)': {
                fontWeight: 800
            }
        }
    },
    menu: {
        textDecoration: 'none',
        color: '#424242'
    },
    dragIcon: {
        cursor: 'grab'
    },
    flat: {
        boxShadow: 'none',
        marginBottom: 0
    }
}));

const teamIcons = {
    Unassigned: 'unassigned',
    'Corporate Pirates': 'pirates',
    'Sales Duo': 'duo',
    'Sales Express': 'express',
    'The Tycoons': 'tycoons',
    Content: 'content'
};

const getPartnerUrl = (partner_id) => {
    let url = trimSlash(GA_URL);

    switch (partner_id) {
        case 1:
            url = trimSlash(GA_INSURANCE_URL);
            break;
        case 5:
            url = `${url}/travel-resources/accommodations-abroad`;
            break;
        case 9:
            url = `${url}/travel-resources`;
            break;
        default:
            break;
    }
    return url;
};

const TableRow = ({ ad = {}, index }) => {
    const classes = useStyles();
    const [anchor, setAnchor] = useState(null);
    const { reorder, setTravelResourceRankingState } =
        useTravelResourceRankingState();
    const { onDrop } = useTravelResourceRankingMethods();

    const handleOpenMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.currentTarget);
    };

    const handleDragStart = () =>
        setTravelResourceRankingState({ dragged_index: index });

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        onDrop(index);
    };

    const rowMenu = [
        {
            name: 'View Ad',
            target: 'blank'
        },
        { name: 'Edit Ad', target: 'blank' }
    ].map((menu) => {
        if (menu.name === 'View Ad') menu.url = getPartnerUrl(ad.partner_id);
        if (menu.name === 'Edit Ad') menu.url = `/ad/${ad.id}/edit`;
        return menu;
    });

    const { id: team_id = 0 } = ad.team || {};

    return (
        <Card
            className={classNames({
                [classes.root]: true,
                [classes.flat]: !reorder
            })}
            draggable={reorder}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <Grid container className={classes.wrapper} spacing={1}>
                <Grid item xs={2}>
                    <Typography>{ad.position}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <TeamIcon
                        variant={teamIcons[TEAM_ASSIGNMENT[team_id || 0]]}
                        size={'small'}
                    />
                    <Typography>
                        <NavLink
                            to={{
                                pathname: `/client/profile/${ad.client_id}`
                            }}
                            className={classes.menu}
                        >
                            {ad.client_name}
                        </NavLink>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{PARTNER_NAMES[ad.partner_id]}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={handleOpenMenu}>
                        <MoreHoriz />
                    </IconButton>
                    <Menu
                        key={`menu-wrapper-${ad.id}`}
                        anchorEl={anchor}
                        open={!!anchor}
                        onClose={() => setAnchor(null)}
                        elevation={2}
                        transitionDuration={{ enter: 100, exit: 100 }}
                    >
                        {rowMenu.map((menu, index) => {
                            return (
                                <MenuItem
                                    key={`menu-item-${ad.id}-${index}`}
                                    dense
                                    disableGutters
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <NavLink
                                        to={{
                                            pathname: menu.url
                                        }}
                                        target={menu.target}
                                        className={classes.menu}
                                    >
                                        {menu.name}
                                    </NavLink>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                    {reorder && <DragHandle className={classes.dragIcon} />}
                </Grid>
            </Grid>
        </Card>
    );
};

export default TableRow;
