import { sendRequest } from '../helpers/apiRequestUtility.js';
import getDirectoryBy, {
    checkFilterBy,
    checkReturnData,
    filterReturnedData
} from './directory.js';
import { isEmpty } from 'lodash';

export const SEARCH_CRITERIA = {
    //KEY IS SEARCH CRITERIA ID
    directory_id: {
        name: 'Directory',
        id: 'directory_id',
        new_criteria: 'directory',
        old_criteria: 'programID',
        bin_value: 1
    },
    country_id: {
        name: 'Country',
        id: 'country_id',
        new_criteria: 'country',
        old_criteria: 'countryID',
        bin_value: 2
    },
    main_region_id: {
        name: '',
        id: 'main_region_id',
        new_criteria: 'main_region',
        old_criteria: 'mainregionID',
        bin_value: 8
    },
    city_id: {
        name: 'City',
        id: 'city_id',
        new_criteria: 'city',
        old_criteria: 'cityID',
        bin_value: 16
    },
    adventure_type_id: {
        name: 'Adventure Type',
        id: 'adventure_type_id',
        new_criteria: 'adventure_travel_type',
        old_criteria: 'advtravtypeID',
        bin_value: 32
    },
    degree_id: {
        name: 'Subject',
        id: 'degree_id',
        new_criteria: 'degree_program',
        old_criteria: 'degprogID',
        bin_value: 64
    },
    study_type_id: {
        name: 'Degree',
        id: 'study_type_id',
        new_criteria: 'study_type',
        old_criteria: 'studytypeID',
        bin_value: 128
    },
    highschool_type_id: {
        name: 'Focus Area',
        id: 'highschool_type_id',
        new_criteria: 'highschool_type',
        old_criteria: 'highschooltypeID',
        bin_value: 256
    },
    duration_id: {
        name: 'Duration',
        id: 'duration_id',
        new_criteria: 'duration',
        old_criteria: 'durationID',
        bin_value: 512
    },
    language_id: {
        name: 'Foreign Language',
        id: 'language_id',
        new_criteria: 'language',
        old_criteria: 'foreignlangID',
        bin_value: 1024
    },
    intern_type_id: {
        name: 'Field',
        id: 'intern_type_id',
        new_criteria: 'intern_type',
        old_criteria: 'interntypeID',
        bin_value: 2048
    },
    intern_duration_id: {
        name: 'Duration',
        id: 'intern_duration_id',
        new_criteria: 'intern_duration',
        old_criteria: 'internDurationID',
        bin_value: 2097152
    },
    teach_duration_id: {
        name: 'Duration',
        id: 'teach_duration_id',
        new_criteria: 'teach_duration',
        old_criteria: 'teachDurationID',
        bin_value: 8388608
    },
    term_id: {
        name: 'Term',
        id: 'term_id',
        new_criteria: 'term',
        old_criteria: 'termID',
        bin_value: 4096
    },
    volunteer_type_id: {
        name: 'Cause',
        id: 'volunteer_type_id',
        new_criteria: 'volunteer_type',
        old_criteria: 'volunteertypeID',
        bin_value: 8192
    },
    world_region_id: {
        name: 'Region',
        id: 'world_region_id',
        new_criteria: 'world_region',
        old_criteria: 'regionID',
        bin_value: 16384
    },
    online: {
        name: 'Online',
        id: 'online',
        new_criteria: 'online',
        old_criteria: 'onlineTEFL',
        bin_value: 32768
    },
    gap_year_type_id: {
        name: 'Focus Area',
        id: 'gap_year_type_id',
        new_criteria: 'gap_year_type',
        old_criteria: 'gapyeartypeID',
        bin_value: 524288
    },
    gap_year_timing_id: {
        name: 'Timing',
        id: 'gap_year_timing_id',
        new_criteria: 'gap_year_timing',
        old_criteria: 'gapYearTimingID',
        bin_value: 4194304
    },
    special_page_ad: {
        name: '',
        id: 'special_page_ad',
        new_criteria: '',
        old_criteria: '',
        bin_value: 131072
    },
    sponsorship_type_id: {
        name: '',
        id: 'sponsorship_type_id',
        new_criteria: '',
        old_criteria: '',
        bin_value: 16384
    },
    partner_id: {
        name: '',
        id: 'partner_id',
        new_criteria: '',
        old_criteria: '',
        bin_value: 16
    }
};

export const CRITERIA_PER_DIRECTORY = {
    //KEY IS DIRECTORY ID
    1: {
        //US University
        type: '',
        timing: ''
    },
    2: {
        //Volunteer Abroad
        type: 'volunteer_type_id',
        timing: 'duration_id'
    },
    3: {
        //Teach Abroad
        type: '',
        timing: 'teach_duration_id'
    },
    4: {
        //Intern Abroad
        type: 'intern_type_id',
        timing: 'intern_duration_id'
    },
    5: {
        //Jobs Abroad
        type: 'intern_type_id',
        timing: ''
    },
    6: {
        //Study Abroad
        type: 'degree_id',
        timing: 'term_id'
    },
    7: {
        //Language School
        type: 'language_id',
        timing: ''
    },
    8: {
        //Adventure Travel
        type: 'adventure_type_id',
        timing: ''
    },
    9: {
        //High School
        type: 'highschool_type_id',
        timing: 'duration_id'
    },
    10: {
        //GoAbroad
        type: '',
        timing: ''
    },
    11: {
        //TEFL Courses
        type: '',
        timing: ''
    },
    12: {
        //Degree Abroad
        type: 'degree_id',
        timing: 'study_type_id'
    },
    15: {
        //Scholarships
        type: '',
        timing: ''
    },
    17: {
        //Accommodations
        type: '',
        timing: ''
    },
    18: {
        //Embassies
        type: '',
        timing: ''
    },
    19: {
        //Gap Year
        type: 'gap_year_type_id',
        timing: 'gap_year_timing_id'
    }
};

/**
 * Get search criteria filtered by other criteria value/s.
 *
 * @params {Object} filterBy      Filter search criteria by this object. Ex. {key:'id', val:["city_id", "gap_year_type_id"]}
 *                                Invalid object, key or val will return all search criteria.
 *         {String} filterBy.key  This should be a valid key of search criteria.
 *         {Array}  filterBy.val  Array of values of the search criteria to be filtered.
 * @params {Array}  returnData    Array of properties to be returned. Ex: ['bin_value','name']
 *                                Empty array will return all properties of search criteria.
 *
 * @return {Object} Object of search criteria
 */
export function getCriteriaByCriteria(filterBy = {}, returnData = []) {
    let basedOn = SEARCH_CRITERIA.directory_id;
    let checkedFilterBy = checkFilterBy(filterBy, basedOn);
    let checkedReturnData = checkReturnData(returnData, basedOn);

    return Object.keys(SEARCH_CRITERIA)
        .filter((sc_key) =>
            checkedFilterBy
                ? filterBy.val.includes(SEARCH_CRITERIA[sc_key][filterBy.key])
                : true
        )
        .reduce((obj, sc_key) => {
            obj[sc_key] = checkedReturnData
                ? filterReturnedData(SEARCH_CRITERIA[sc_key], returnData)
                : SEARCH_CRITERIA[sc_key];

            return obj;
        }, {});
}

/**
 * Get search criteria grouped by directories, filtered by directories.
 *
 * @params {Object} filterBy      Filter search criteria by directory. Ex. {key:'id', val:[6, 12]}
 *                                Invalid object, key or val will return all search criteria of each directories.
 *         {String} filterBy.key  This should be a valid key of directory.
 *         {Array}  filterBy.val  Array of values of the directories to be filtered.
 * @params {Array}  returnData    Array of properties of search criteria to be returned. Ex: ['bin_value','name']
 *                                Empty array will return all properties of search criteria.
 *
 * @return {Object} Object of search criteria grouped by directory id.
 */
export function getCriteriaByDirectory(filterBy = {}, returnData = []) {
    let directories = getDirectoryBy(filterBy, ['id']);
    let searchCriteriaByDir = {};

    let intersect = returnData.filter((x) =>
        ['name', 'id', 'new_criteria', 'old_criteria', 'bin_value'].includes(x)
    );

    if (
        returnData.length === 0 ||
        !(
            returnData.length === intersect.length &&
            returnData.sort().every(function (value, index) {
                return value === intersect.sort()[index];
            })
        )
    ) {
        returnData = [
            'name',
            'id',
            'new_criteria',
            'old_criteria',
            'bin_value'
        ];
    }

    directories.forEach(function (element) {
        let directoryCriteria = CRITERIA_PER_DIRECTORY[element.id];

        searchCriteriaByDir[element.id] = {
            timing: directoryCriteria.timing
                ? filterReturnedData(
                    SEARCH_CRITERIA[directoryCriteria.timing],
                    returnData
                )
                : '',
            type: directoryCriteria.type
                ? filterReturnedData(
                    SEARCH_CRITERIA[directoryCriteria.type],
                    returnData
                )
                : ''
        };
    });

    return searchCriteriaByDir;
}

export const fetchCriteriaOptions = async (criteria) =>
    sendRequest(
        `/search-criteria-options?criteria=${criteria}`,
        ({ search_criteria_options = [] }) =>
            search_criteria_options.map(
                ({ id: value, name: label, ...rest }) => {
                    return { value, label, ...rest };
                }
            ),
        () => []
    );

export const transformLocationParams = ({ filters, locations }) => {
    const { location, exactLocation } = filters;
    let filterParams = {};

    if (!isEmpty(location)) {
        const types = [
            'world_region_id',
            'country_id',
            'main_region_id',
            'city_id'
        ];
        types.forEach((i) => {
            let bin = SEARCH_CRITERIA[i].bin_value;
            if (location.value.startsWith(bin + '_')) {
                let value = location.value.replace(bin + '_', '');
                let bin_value = bin;
                filterParams = { ...filterParams, [i]: value };

                // add country_id to city or main_region
                if (i === 'city_id' || i === 'main_region_id') {
                    const obj = locations.filter((obj) => {
                        return obj.id === location.value;
                    })[0];
                    const country_id = obj.search_criteria[0].criteria_value;
                    bin_value += obj.search_criteria[0].criteria_id;
                    filterParams = { ...filterParams, country_id };
                }

                if (exactLocation) {
                    // we need to add directory to bin_value
                    bin_value += SEARCH_CRITERIA['directory_id'].bin_value;
                    filterParams = { ...filterParams, bin_value };
                }
            }
        });
    }

    return filterParams;
};

export const transformDirectoryParams = ({
    directory,
    type,
    timing,
    isDirectoryOnly,
    online
}) => {
    let filterParams = {};

    if (!isEmpty(directory)) {
        filterParams = { directory_id: directory.value };
        if (isDirectoryOnly) {
            filterParams = { ...filterParams, bin_value: 1 };
        }
        if (!isEmpty(type)) {
            const typeCriteria = CRITERIA_PER_DIRECTORY[directory.value].type;
            filterParams = { ...filterParams, [typeCriteria]: type.value };
        }
        if (!isEmpty(timing)) {
            const timingCriteria =
                CRITERIA_PER_DIRECTORY[directory.value].timing;
            filterParams = {
                ...filterParams,
                [timingCriteria]: timing.value
            };
        }
    }

    if (online && online.value) {
        filterParams = { ...filterParams, online: online.value };
    }

    return filterParams;
};

export default getCriteriaByDirectory;
