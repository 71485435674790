import React, { useEffect } from 'react';
import {
    Drawer,
    Typography,
    makeStyles,
    Paper,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { useObjectState } from '../../../utilities/customHooks';
import Close from '@material-ui/icons/Close';
import Save from '@material-ui/icons/Check';
import { useProgramRankingState } from './ProgramRankingProvider';
import { useGlobalMethods, useGlobalState } from '../../Client/GlobalProvider';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import { DIRECTORIES } from '../../../utilities/directory';
import getCriteriaByDirectory, {
    fetchCriteriaOptions,
} from '../../../utilities/searchCriteria';
import { BIN_VALUE, search_criteria_fields } from '../../../config/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: 420,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px',
    },
    paper: {
        padding: theme.spacing(3),
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1),
        width: 324,
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',

        '& button:first-child': {
            marginRight: theme.spacing(1),
        },
    },
    required: {
        marginLeft: 'auto',
    },
}));

const directories = [...DIRECTORIES]
    .filter(
        (directory) =>
            (!!directory.page_number_tag || directory.abbrv === 'GA') &&
            directory.id !== 10
    )
    .map((l) => {
        return {
            value: l.id,
            label: l.name,
        };
    });

const FilterDrawer = (props) => {
    const classes = useStyles();
    const { filterDrawerOpen, setProgramRankingState } =
        useProgramRankingState();
    const [state, setState] = useObjectState({
        locations: [],
        fetchingLocations: false,
        provider: null,
        resource: null,
        directory: null,
        location: null,
        type: null,
        timing: null,
        online: false,
        timings: [],
        types: [],
        fetchingTimings: false,
        fetchingTypes: false,
        hasOnline: false,
    });
    const { providers, fetchingProviders } = useGlobalState();
    const { fetchProviders } = useGlobalMethods();

    const handleFieldChange = (field) => (value) => setState({ [field]: value });

    const handleClear = () => {
        setState({
            provider: null,
            directory: null,
            location: null,
            timing: null,
            type: null,
            online: false,
            hasOnline: false,
        });
    };

    const handleSave = () => {
        const newFilters = {
            provider_id: state.provider.value,
            directory_id: state.directory.value,
            location: (state.location || {}).value,
            timing: (state.timing || {}).value,
            type: (state.type || {}).value,
            online: state.online,
        };
        setProgramRankingState({
            filterDrawerOpen: false,
            filters: newFilters,
            filterValues: {
                provider_id: state.provider,
                directory_id: state.directory,
                location: state.location,
                type: state.type,
                timing: state.timing,
            },
            hasOnline: state.hasOnline,
            types: state.types,
            timings: state.timings,
        });
    };

    const handleClose = () => {
        setProgramRankingState({ filterDrawerOpen: false });
    };

    useEffect(() => {
        if (!providers.lenght) {
            fetchProviders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providers]);

    useEffect(() => {
        const fetchLocations = async () =>
            await sendRequest('/locations', ({ locations = [] }) =>
                [...locations]
                    .sort((a, b) => {
                        const [criteriaA] = a.id.split('_');
                        const [criteriaB] = b.id.split('_');
                        if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
                        if (parseInt(criteriaA) < parseInt(criteriaB))
                            return -1;
                        if (a.name > b.name) return 1;
                        if (b.name < a.name) return -1;
                        return 0;
                    })
                    //eslint-disable-next-line array-callback-return
                    .map(({ id, name: label, search_criteria, ...rest }) => {
                        const [criterion_id, value] = `${id}`.split('_');
                        const citerionId = parseInt(criterion_id);

                        if (citerionId === BIN_VALUE.country_id)
                            return {
                                value: `country_id-${value}`,
                                label,
                                ...rest,
                            };

                        if (
                            [
                                BIN_VALUE.main_region_id,
                                BIN_VALUE.city_id,
                            ].includes(citerionId)
                        ) {
                            const locationValue = search_criteria
                                .map(
                                    ({ criteria_id, criteria_value }) => `${search_criteria_fields[criteria_id]}-${criteria_value}`
                                )
                                .join(',');
                            return {
                                value: `${locationValue}`,
                                label,
                                ...rest,
                            };
                        }

                        if (citerionId === BIN_VALUE.world_region_id)
                            return {
                                value: `world_region_id-${value}`,
                                label,
                                ...rest,
                            };
                    })
            );
        if (!state.locations.length) {
            setState({ fetchingLocations: true });
            fetchLocations().then((data) => {
                setState({ locations: data, fetchingLocations: false });
                setProgramRankingState({ locations: data });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.locations]);

    useEffect(() => {
        const fetchTypes = (directory_id) =>
            new Promise(async (res, rej) => {
                const { [directory_id]: available } = getCriteriaByDirectory({
                    key: 'id',
                    val: [directory_id],
                });
                const criterias = Object.keys(available);

                if (criterias.includes('type') && !!available.type) {
                    const { new_criteria, id } = available.type;
                    setState({ typeCriteria: id });
                    res(await fetchCriteriaOptions(new_criteria));
                }

                res([]);
            });

        const fetchTimings = (directory_id) =>
            new Promise(async (res, rej) => {
                const { [directory_id]: available } = getCriteriaByDirectory({
                    key: 'id',
                    val: [directory_id],
                });
                const criterias = Object.keys(available);

                if (criterias.includes('timing') && !!available.timing) {
                    const { new_criteria, id } = available.timing;
                    setState({ timingCriteria: id });
                    res(await fetchCriteriaOptions(new_criteria));
                }

                res([]);
            });

        if (!!state.directory) {
            const [{ has_online }] = DIRECTORIES.filter(
                (dir) => dir.id === state.directory.value
            );
            setState({
                type: null,
                fetchingTypes: true,
                fetchingTimings: true,
                timingCriteria: null,
                typeCriteria: null,
                hasOnline: has_online === 1,
            });
            fetchTypes(state.directory.value).then((data) => {
                const existingType = [...data]
                    .map(({ value }) => value)
                    .includes((state.type || {}).value);
                setState({
                    type: existingType ? state.type : null,
                    types: data || [],
                    fetchingTypes: false,
                });
            });
            fetchTimings(state.directory.value).then((data) => {
                const existingTiming = [...data]
                    .map(({ value }) => value)
                    .includes((state.timing || {}).value);
                setState({
                    timing: existingTiming ? state.timing : null,
                    timings: data || [],
                    fetchingTimings: false,
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.directory]);

    const isValid =
        !!state.provider &&
        !!state.directory &&
        (state.location || state.type || state.timing || state.online);
    const clientNames = [...(providers || [])].map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    return (
        <Drawer open={filterDrawerOpen} anchor="right" onClose={handleClose}>
            <div className={classes.root}>
                <Typography className={classes.drawerTitle}>
                    Data Filters
                </Typography>
                <Paper className={classes.paper}>
                    <Typography variant="overline" style={{ display: 'flex' }}>
                        Providers
                        <Typography
                            variant="overline"
                            color="secondary"
                            align="right"
                            className={classes.required}
                            style={{
                                color: state.provider ? 'green' : undefined,
                            }}
                        >
                            <small>Required</small>
                        </Typography>
                    </Typography>
                    <SearchBasedDropdown
                        value={state.provider}
                        arrayOptions={clientNames}
                        handleChange={handleFieldChange('provider')}
                        isDisabled={fetchingProviders}
                        placeholder={
                            fetchingProviders
                                ? 'Loading ...'
                                : 'Select Provider'
                        }
                    />
                    <br />
                    <Typography variant="overline" style={{ display: 'flex' }}>
                        Directory
                        <Typography
                            variant="overline"
                            color="secondary"
                            align="right"
                            className={classes.required}
                            style={{
                                color: state.directory ? 'green' : undefined,
                            }}
                        >
                            <small>Required</small>
                        </Typography>
                    </Typography>
                    <SearchBasedDropdown
                        value={state.directory}
                        arrayOptions={directories}
                        handleChange={handleFieldChange('directory')}
                        placeholder="Select Directory"
                    />
                    <br />
                    <Typography variant="overline">Location</Typography>
                    <SearchBasedDropdown
                        value={state.location}
                        arrayOptions={state.locations}
                        handleChange={handleFieldChange('location')}
                        isLoading={state.fetchingLocations}
                        isDisabled={state.fetchingLocations}
                        placeholder={
                            state.fetchingLocations
                                ? 'Loading...'
                                : 'Select Location'
                        }
                        isClearable
                    />
                    <br />
                    <Typography variant="overline">Type</Typography>
                    <SearchBasedDropdown
                        value={state.type}
                        arrayOptions={state.types}
                        handleChange={handleFieldChange('type')}
                        isDisabled={
                            state.fetchingTypes ||
                            (!state.types.length && !state.fetchingTypes)
                        }
                        placeholder={
                            state.fetchingTypes ? 'Loading...' : 'Select Type'
                        }
                        isClearable
                    />
                    <br />
                    <Typography variant="overline">Timing</Typography>
                    <SearchBasedDropdown
                        value={state.timing}
                        arrayOptions={state.timings}
                        handleChange={handleFieldChange('timing')}
                        isDisabled={
                            state.fetchingTypes ||
                            (!state.timings.length && !state.fetchingTimings)
                        }
                        placeholder={
                            state.fetchingTimings
                                ? 'Loading...'
                                : 'Select Timing'
                        }
                        isClearable
                    />
                    {state.directory && state.hasOnline && (
                        <>
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={({ target: { checked } }) =>
                                            handleFieldChange('online')(checked)
                                        }
                                        checked={state.online}
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<CheckCircleIcon />}
                                        color="primary"
                                    />
                                }
                                label="Online"
                            />
                        </>
                    )}
                </Paper>
                <div className={classes.cta}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disableElevation
                        disabled={
                            !state.provider &&
                            !state.directory &&
                            !state.location &&
                            !state.timing &&
                            !state.type &&
                            !state.online
                        }
                        onClick={handleClear}
                        startIcon={<Close />}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disableElevation
                        onClick={handleSave}
                        startIcon={<Save />}
                        disabled={!isValid}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default FilterDrawer;
