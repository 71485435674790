import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import SearchBasedDropdown from '../SearchBasedDropdown/SearchBasedDropdown';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { isEmpty } from 'lodash';

const styles = (theme) => ({
    formControlSelect: {
        border: 'solid 1px #ddd',
        borderRight: 'none',
        borderRadius: '4px 0 0 4px'
    },
    formControlInput: {
        border: 'solid 1px #ddd',
        borderRadius: '0 4px 4px 0',
        minWidth: 200,
        maxWidth: 530
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 200
    },
    label: {
        transform: 'translate(15px, 13px)'
    },
    input: {
        paddingRight: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: 100,
            '&:focus': {
                width: 250
            }
        }
    },
    dropdownSearch: {
        display: 'inline-flex'
    },
    searchBased: {
        minWidth: 200,
        height: 32,
        border: 'none',
        '& .search-based__control': {
            minHeight: 32,
            borderStyle: 'none',
            padding: '0 8px',
            backgroundColor: '#00000000'
        },
        '& .search-based__control:hover, & .search-based__control:focus, & .search-based__control--menu-is-open':
        {
            borderColor: '#00000000',
            boxShadow: 'none'
        },
        '& .search-based__control--is-focused': {
            width: 300
        },
        '& .search-based__indicators': {
            height: 32
        }
    }
});

class ResultsTableSearchFilter extends PureComponent {
    state = {
        value: '',
        filter: '',
        selected: null
    };

    handleFilterOptionChange = (e) => {
        const filter = e.target.value;
        this.setState({ filter, value: '', selected: null });
    };

    handleSearchChange = (e) => {
        const value = e.target.value;
        this.setState({ value });
    };

    handleFilterChange = () => {
        const { onSearchFilterChange: callback } = this.props;
        const { filter, value } = this.state;
        if (filter !== '' && !!callback) callback({ filter, value });
    };

    handleSetSelected = ({ label, value }) => {
        this.setState(
            { selected: { label, value }, value },
            this.handleFilterChange
        );
    };

    handleKeyDown = (e) => {
        if (e.keyCode === 13) this.handleFilterChange();
        return e;
    };

    renderFilterOptions = () => {
        const { searchFilterOptions } = this.props;

        if (!searchFilterOptions || !Array.isArray(searchFilterOptions))
            return null;
        return searchFilterOptions.map(this.renderOption);
    };

    renderOption = (menu, key) => {
        if (typeof menu !== 'object') return null;
        return (
            <MenuItem
                data-cy={`menu-${menu.key}`}
                value={menu.key ? menu.key : key}
                key={`search-filter-menu-item-${key}`}
                disabled={menu.disabled}
            >
                {menu.name ? menu.name : `Option ${key + 1}`}
            </MenuItem>
        );
    };

    renderFilterValue = () => {
        const {
            classes,
            searchFilterOptions: options = [],
            isLoadingData
        } = this.props;
        const { value, filter, selected } = this.state;
        const selectedFilter = (Array.isArray(options) ? options : []).filter(
            (item, key) => {
                return item.key ? item.key === filter : key === filter;
            }
        );
        const { hasAutoSuggest, dropdownOptions, onChange, selectedValue } =
            selectedFilter[0] || {};
        const {
            arrayOptions,
            labelKey,
            valueKey,
            isLoading,
            isMultiSelect,
            isSearchable
        } = dropdownOptions || {};

        if (hasAutoSuggest)
            return (
                <FormControl className={classes.formControlInput + " data-cy-menu"}>
                    <SearchBasedDropdown
                        isDisabled={
                            isLoadingData || isLoading || isEmpty(filter)
                        }
                        arrayOptions={arrayOptions}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        handleChange={
                            !isMultiSelect ? this.handleSetSelected : onChange
                        }
                        value={!isMultiSelect ? selected : selectedValue}
                        className={classes.searchBased}
                        placeholder={isLoading ? 'Loading ...' : 'Search...'}
                        classNamePrefix={
                            isMultiSelect
                                ? 'multi-search-based'
                                : 'search-based'
                        }
                        isSearchable={isSearchable}
                        isMultiSelect={isMultiSelect}
                    />
                </FormControl>
            );

        return (
            <FormControl className={classes.formControlInput}>
                <Input
                    disabled={isLoadingData || isEmpty(filter)}
                    disableUnderline
                    placeholder={isLoadingData ? 'Loading ...' : 'Search...'}
                    value={value}
                    onChange={this.handleSearchChange}
                    onKeyDown={this.handleKeyDown}
                    classes={{ input: classes.input }}
                />
            </FormControl>
        );
    };

    render() {
        const { classes, isLoadingData } = this.props;
        const { filter } = this.state;

        return (
            <div className={classes.search}>
                <FormControl className={classes.formControlSelect + " data-cy-select-filter"}>
                    <Select
                        disableUnderline
                        displayEmpty
                        className={classes.select}
                        value={filter}
                        onChange={this.handleFilterOptionChange}
                        style={{ paddingLeft: '15px' }}
                        disabled={isLoadingData}
                    >
                        <MenuItem value="">
                            <em>Select Filter</em>
                        </MenuItem>
                        {this.renderFilterOptions()}
                    </Select>
                </FormControl>
                {this.renderFilterValue()}
            </div>
        );
    }
}

ResultsTableSearchFilter.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ResultsTableSearchFilter);
