import FeaturesRanking from './FeaturesRanking/FeaturesRanking';
import React, { Component, Fragment } from 'react';
import SearchResultsRanking from './SearchResultsRanking/SearchResultsRanking';
import { Tabs, withStyles } from '@material-ui/core';
import LinkTab from '../../components/Tabs/LinkTab';
import { findIndex } from 'lodash';
import TravelResourceRanking from './TravelResourcesRanking/TravelResourceRanking';
import ProgramRanking from './ProgramRanking/ProgramRanking';

const styles = (theme) => ({
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(4)
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontSize: '0.9rem'
    },
    accountSummary: {
        padding: theme.spacing(3),
        width: 500
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const rankingTabs = [
    {
        path: 'ranking-system',
        name: 'Search Results Ranking'
    },
    {
        path: 'ranking-system/features',
        name: 'Features Ranking'
    },
    {
        path: 'ranking-system/program-ranking',
        name: 'Program Ranking in SR'
    },
    {
        path: 'ranking-system/travel-resource',
        name: 'Travel Resource Ranking'
    }
];

class RankingSystem extends Component {
    render() {
        const { classes, location } = this.props;
        const tabSelected = findIndex(rankingTabs, [
            'path',
            location.pathname.replace(/^\/+|\/+$/g, '')
        ]);

        return (
            <Fragment>
                <Tabs
                    value={tabSelected}
                    className={classes.tabsRoot}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    {rankingTabs.map((view) => {
                        return (
                            <LinkTab
                                key={view.path}
                                disableRipple
                                classes={{
                                    root: classes.tabRoot,
                                    selected: classes.tabSelected
                                }}
                                label={view.name}
                                to={`/${view.path}`}
                            />
                        );
                    })}
                </Tabs>
                {tabSelected === 0 && <SearchResultsRanking {...this.props} />}
                {tabSelected === 1 && <FeaturesRanking />}
                {tabSelected === 2 && <ProgramRanking />}
                {tabSelected === 3 && <TravelResourceRanking />}
            </Fragment>
        );
    }
}

export default withStyles(styles)(RankingSystem);
