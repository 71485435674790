import React from 'react';
import ResultsTable from '../../components/ResultsTable/ResultsTable';
import { GA_URL, GA_JOBS_URL } from '../../config/constants';
import { isEmptyDate, trimSlash } from '../../helpers/dataUtility';
import { useGuidesMethods, useGuidesState } from './GuidesProvider';
import { Typography, Tooltip, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DataFilters from './DataFilters';
import { useObjectState } from '../../utilities/customHooks';
import CustomGlobalFilterDrawer from '../../components/CustomGlobalFilter/CustomGlobalFilterDrawer';
import { useSnackbar } from 'notistack';
import DialogBox from '../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { intersection, keys, sumBy } from 'lodash';
import urlParser from '../../utilities/urlParser';
import { DIRECTORIES } from '../../utilities/directory';
import {
    getCriteriaByDirectory,
    SEARCH_CRITERIA
} from '../../utilities/searchCriteria';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
    unpublished: {
        color: '#fff',
        fontSize: 10,
        width: 90,
        height: 20,
        verticalAlign: 'middle',
        display: 'inline-flex',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: red[500]
    },
    '.Mui-disabled svg': {
        fill: 'red'
    }
}));

const GLOBAL_FILTERS_NAMES = ['status'];

const ResultsMain = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const {
        data,
        authors,
        count,
        page,
        rowsPerPage,
        fetching,
        isSaving,
        globalFiltersOpen,
        setGuidesState,
        dialog,
        sortBy,
        sortOrder,
        filters,
        locations,
        page_url,
        selected
    } = useGuidesState();
    const {
        handleSort,
        handleUpdateGuides,
        handleDeleteGuides,
        handleExportGuides,
        handleShowActionConfirmation,
        handleCloseDialog,
        handleSelectMultiple,
        fetchLocations,
        fetchCriteriaOptions,
        fetchGuides
    } = useGuidesMethods();

    const [columnFilters, setColumnFilters] = useObjectState({});

    const handleApplyGlobalFilters = (status) => {
        setGuidesState({
            filters: { ...filters, ...status },
            data: [],
            page: 0
        });
    };

    const handleClearGlobalFilters = ({ filters: globalFilters }) => {
        const newFilters = { ...globalFilters };
        newFilters['status'] = null;

        setGuidesState({
            filters: { ...filters, ...newFilters },
            data: [],
            page: 0
        });
    };

    const handleApplyColumnFilters = async () => {
        const urlParams = page_url ? await generateFiltersByUrl(page_url) : {};
        const newFilters =
            page_url && !isEmpty(urlParams)
                ? {
                      ...filters,
                      directory: null,
                      location: null,
                      type: null,
                      timing: null,
                      provider: null,
                      online: null,
                      isDirectoryOnly: false,
                      exactLocation: false,
                      isCriteriaDisabled: false
                  }
                : filters;
        if (!page_url || !isEmpty(urlParams)) {
            setGuidesState({
                filters: {
                    ...newFilters,
                    ...columnFilters,
                    urlParams
                },
                data: [],
                page: 0
            });
        }
    };

    const handleClearColumnFilters = () => {
        const newFilters = { ...filters };
        //eslint-disable-next-line array-callback-return
        Object.keys(columnFilters).map((key) => {
            delete newFilters[key];
            delete columnFilters[key];
        });
        delete newFilters['urlParams'];
        delete columnFilters['urlParams'];
        setGuidesState({ filters: newFilters, data: [], page: 0 });
    };

    const handlePageUrlFilter = ({ target: { value } }) => {
        setGuidesState({ page_url: value });
    };

    const generateFiltersByUrl = async (url) => {
        const { hostname: domain, pathname } = urlParser(url);
        const { hostname: GA_DOMAIN } = urlParser(GA_URL);
        const { hostname: JOBS_DOMAIN } = urlParser(GA_JOBS_URL);
        const parts = pathname
            .split('/')
            .filter((part) => !!part && !['https:', 'http:'].includes(part));

        if (![GA_DOMAIN, JOBS_DOMAIN].includes(domain)) {
            enqueueSnackbar('Invalid URL', {
                variant: 'error',
                autoHideDuration: 2000
            });
            return;
        }

        const isJobs = domain === JOBS_DOMAIN;
        const directory = isJobs ? 'jobs-abroad' : parts[0];
        const segments = parts.slice(isJobs ? 1 : 2, parts.length);
        const isOnline = segments.includes('online');
        let locationOptions = locations;

        const [{ id: directory_id } = {}] = DIRECTORIES.filter(
            ({ alias }) => directory === alias
        );
        const {
            [directory_id]: {
                timing: { id: timing_id, new_criteria: timing_criteria } = {},
                type: { id: type_id, new_criteria: type_criteria } = {}
            } = {}
        } = getCriteriaByDirectory({
            key: 'id',
            val: [directory_id]
        });

        setGuidesState({ fetching: true });

        if (locationOptions.length === 0) {
            locationOptions = await fetchLocations();
            setGuidesState({ locations: locationOptions });
        }

        const criteria = { directory_id };
        const types = type_criteria
            ? await fetchCriteriaOptions(type_criteria)
            : [];
        const timings = timing_criteria
            ? await fetchCriteriaOptions(timing_criteria)
            : [];
        const params = {
            location: locationOptions.filter((data) => {
                const { alias, url_alias, search_criteria } = data;
                const bin_value = sumBy(search_criteria, (criteria) => {
                    return criteria.criteria_id;
                });
                if (
                    [
                        SEARCH_CRITERIA.country_id.bin_value,
                        SEARCH_CRITERIA.world_region_id.bin_value
                    ].includes(bin_value)
                ) {
                    return segments.includes(alias);
                }
                if (segments.length > 1) {
                    return `${segments[0]}/${segments[1]}` === url_alias;
                }
                return false;
            }),
            timing: timings.filter(({ alias }) => segments.includes(alias)),
            type: types.filter(({ alias }) => segments.includes(alias))
        };
        if (isOnline) params['online'] = [1];
        Object.keys(params)
            .filter((key) => !!params[key] && params[key].length)
            //eslint-disable-next-line array-callback-return
            .map((key) => {
                const [{ value: id }] = params[key] || [{}];
                if (key === 'online') criteria['online'] = 1;
                else if (key === 'type') criteria[type_id] = id;
                else if (key === 'timing') criteria[timing_id] = id;
                else
                    params[key].map(({ search_criteria }) =>
                        search_criteria.map(
                            //eslint-disable-next-line array-callback-return
                            ({ criteria_id, criteria_value }) => {
                                const [criteria_key] = Object.keys(
                                    SEARCH_CRITERIA
                                ).filter(
                                    (key) =>
                                        SEARCH_CRITERIA[key].bin_value ===
                                        criteria_id
                                );
                                criteria[SEARCH_CRITERIA[criteria_key].id] =
                                    criteria_value;
                            }
                        )
                    );
            });

        const bin_value = Object.keys(criteria)
            .map((key) => SEARCH_CRITERIA[key].bin_value)
            .reduce((total, current) => total + current, 0);

        return {
            ...criteria,
            bin_value
        };
    };

    const handlePageChange = (page) => {
        const requiredNumberOfRows = (page + 1) * rowsPerPage;

        setGuidesState({ page });

        if (requiredNumberOfRows > data.length) {
            fetchGuides(data.length, requiredNumberOfRows - data.length);
        }
    };

    const handleRowsPerPageChange = (rowsPerPage) => {
        const requiredNumberOfRows = (page + 1) * rowsPerPage;

        setGuidesState({ rowsPerPage });

        if (requiredNumberOfRows > data.length) {
            fetchGuides(data.length, requiredNumberOfRows - data.length);
        }
    };

    const author = columnFilters.author_id
        ? authors.filter((auth) => {
              return auth.id === columnFilters.author_id;
          })[0]
        : null;

    const author_value = author
        ? { value: author.id, label: author.name }
        : null;

    const columns = [
        {
            name: 'Page URL',
            id: 'page-url',
            type: 'text',
            format: 'bold',
            key: 'page_url',
            urlKey: 'target_url',
            urlTarget: '_blank',
            filter: {
                value: page_url,
                onChange: handlePageUrlFilter
            }
        },
        {
            name: 'Author Name',
            id: 'author-name',
            format: 'bold',
            key: 'author_name',
            options: [],
            filter: {
                type: 'select',
                options: authors || {},
                optionValue: 'id',
                optionKey: 'name',
                value: author_value,
                onChange: (author) => {
                    setColumnFilters({ author_id: author.value });
                }
            }
        },
        {
            name: 'Date Published',
            id: 'date-published',
            type: 'date',
            format: 'normal',
            key: 'date_published',
            sort: sortBy === 'date_published' ? sortOrder : null,
            filter: {
                onChange: (date_published) =>
                    setColumnFilters({ date_published })
            }
        },
        {
            name: 'Date Modified',
            type: 'date',
            id: 'date-modified',
            format: 'normal',
            key: 'date_modified',
            sort: sortBy === 'date_modified' ? sortOrder : null,
            filter: {
                onChange: (date_modified) => setColumnFilters({ date_modified })
            }
        }
    ];

    const rowMenus = [
        {
            name: 'edit',
            text: 'Edit'
        },
        {
            name: 'editFAQ',
            text: 'Edit FAQ'
        },
        {
            name: 'publish',
            text: 'Publish',
            handler: (row) => {
                handleShowActionConfirmation('publish', () =>
                    handleUpdateGuides('publish', false, row)
                );
            }
        },
        {
            name: 'unpublish',
            text: 'Unpublish',
            handler: (row) => {
                handleShowActionConfirmation('unpublish', () =>
                    handleUpdateGuides('unpublish', false, row)
                );
            }
        },
        {
            name: 'delete',
            text: 'Delete',
            handler: (row) => {
                handleShowActionConfirmation('delete', () =>
                    handleDeleteGuides(false, row)
                );
            }
        }
    ];

    const rowMenusChecker = (row, rowMenus) =>
        rowMenus
            .filter((menu) => {
                if (menu.name === 'publish') return row.status === 0;
                if (menu.name === 'unpublish') return row.status === 1;
                return true;
            })
            .map((menu) => {
                if (menu.name === 'edit') menu.url = `/guides/edit/${row.id}`;
                if (menu.name === 'editFAQ') menu.url = `/faq/edit/${row.id}`;
                return menu;
            });

    const icons = [
        {
            name: 'unpublished',
            icon: (
                <Tooltip title="Unpublished" placement="top">
                    <Typography className={classes.unpublished}>
                        UNPUBLISHED
                    </Typography>
                </Tooltip>
            ),
            placement: 'right',
            column: 'page_url'
        }
    ];

    const iconsChecker = (row, icons) =>
        icons.filter((icon) => {
            if (icon.name === 'unpublished') return row.status === 0;
            return true;
        });

    const batchActions = [
        {
            icon: 'publish',
            handler: () =>
                handleShowActionConfirmation('publish', () =>
                    handleUpdateGuides('publish', true)
                )
        },
        {
            icon: 'unpublish',
            handler: () =>
                handleShowActionConfirmation('unpublish', () =>
                    handleUpdateGuides('unpublish', true)
                )
        },
        {
            icon: 'delete',
            handler: () =>
                handleShowActionConfirmation('delete', () =>
                    handleDeleteGuides(true)
                )
        },
        {
            icon: 'export',
            handler: () =>
                handleShowActionConfirmation(
                    'Export Guides',
                    handleExportGuides,
                    'Download All Columns & Program Fields as CSV'
                ),
            enableInSelectAll: true
        }
    ];

    const mappedData = [...data].map((row) => {
        row.author_name = row.author ? row.author.name : '';
        row.page_url = trimSlash(row.url)
            .replace(trimSlash(GA_URL), '')
            .replace('https://', '');

        row.target_url = row.status === 1 ? row.url : '';

        if (isEmptyDate(row.date_modified))
            row.date_modified = row.date_created;

        if (isEmptyDate(row.date_published)) row.date_published = '';

        return row;
    });

    const activeGlobalFilters = intersection(
        keys(filters),
        GLOBAL_FILTERS_NAMES
    ).some((key) => ![null, undefined, ''].includes(filters[key]));

    return (
        <>
            <DataFilters />
            <ResultsTable
                label={mappedData.length > 1 ? 'guides' : 'guide'}
                data={mappedData.slice(
                    rowsPerPage * page,
                    rowsPerPage * page + rowsPerPage
                )}
                count={count || mappedData.length}
                page={page}
                rowsPerPage={rowsPerPage}
                columns={columns}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenusChecker}
                icons={icons}
                iconsChecker={iconsChecker}
                batchActions={batchActions}
                isLoadingData={fetching}
                onColumnFiltersApply={handleApplyColumnFilters}
                onColumnFilterClose={handleClearColumnFilters}
                onGlobalFiltersClick={() =>
                    setGuidesState({ globalFiltersOpen: true })
                }
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                hasSearch={false}
                onColumnHeaderClick={handleSort}
                selected={selected}
                onSelectedChange={handleSelectMultiple}
                activeGlobalFilters={activeGlobalFilters}
            />
            <CustomGlobalFilterDrawer
                open={globalFiltersOpen}
                onClose={() => setGuidesState({ globalFiltersOpen: false })}
                onApply={handleApplyGlobalFilters}
                onClear={handleClearGlobalFilters}
                closeOnApply
                closeOnClear
                filters={[
                    {
                        key: 'status',
                        filterLabel: 'Publication Status',
                        type: 'switch',
                        options: [
                            {
                                value: 0,
                                label: 'Unpublished'
                            },
                            {
                                value: 1,
                                label: 'Published'
                            }
                        ]
                    }
                ]}
            />
            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                stringOverride={dialog.stringOverride}
                onOk={dialog.onOk}
                onCancel={handleCloseDialog}
                onClose={handleCloseDialog}
            />
            <DialogBox open={isSaving} title={'Processing...'} actions="">
                <LinearProgress />
            </DialogBox>
        </>
    );
};

export default ResultsMain;
