import React, { PureComponent } from 'react';

import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ResultsTableEnhancedToolbar from './ResultsTableEnhancedToolbar';
import ResultsTableFooter from './ResultsTableFooter';
import ResultsTableHead from './ResultsTableHead';
import ResultsTableColumnFilters from './ResultsTableColumnFilters';
import ResultsTableProvider from './ResultsTableProvider';
import ResultsTableBody from './ResultsTableBody';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing.unit * 0,
    },
    tableWrapper: {
        padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
        overflowX: 'auto',
        minHeight: 480,
        height: '100%',

        '& th': {
            padding: 0,
        },
        '& td': {
            minWidth: 175,
        },
        '& td:first-child': {
            minWidth: 60,
        },
        '& td:last-child': {
            minWidth: 60,
        },
    },
});

class ResultsTable extends PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <ResultsTableProvider {...this.props}>
                <Paper
                    elevation={1}
                    className={classes.root}
                    data-cy="table-client-deal"
                >
                    <ResultsTableEnhancedToolbar />
                    <div className={classes.tableWrapper}>
                        <Table padding="dense" className={classes.table}>
                            <ResultsTableHead />
                            <ResultsTableColumnFilters />
                            <ResultsTableBody />
                            <ResultsTableFooter />
                        </Table>
                    </div>
                </Paper>
            </ResultsTableProvider>
        );
    }
}

ResultsTable.propTypes = {
    batchActions: PropTypes.array,
    selected: PropTypes.array,
    unselected: PropTypes.array,
    classes: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    globalFilters: PropTypes.object,
    hasColumnFilters: PropTypes.bool,
    hasGlobalFilters: PropTypes.bool,
    hasSearch: PropTypes.bool,
    activeGlobalFilters: PropTypes.bool,
    label: PropTypes.string,
    customLabel: PropTypes.string,
    onColumnFiltersApply: PropTypes.func,
    onColumnFilterClose: PropTypes.func,
    onColumnFilterOpen: PropTypes.func,
    onMenuClick: PropTypes.func,
    onMenuClose: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowClick: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onRowSelectChange: PropTypes.func,
    handlePageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectedChange: PropTypes.func,
    onSearchFilterChange: PropTypes.func,
    onSearchFilterClick: PropTypes.func,
    onSearchFilterClose: PropTypes.func,
    onSelectNone: PropTypes.func,
    onUnselectedChange: PropTypes.func,
    onGlobalFiltersClick: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    searchFilterOptions: PropTypes.array,
    selectable: PropTypes.bool,
    topCTA: PropTypes.array,
    isLoadingData: PropTypes.bool,
    onColumnHeaderClick: PropTypes.func,
    isSelectedAll: PropTypes.bool,
    noDataMessage: PropTypes.string,
    onAddRowClick: PropTypes.func,
    disabledColumnFilter: PropTypes.bool,
    disabledSearchFilter: PropTypes.bool,
    disabledGlobalFilter: PropTypes.bool,
    disabledSelectAllResults: PropTypes.bool,
};

export default withStyles(styles)(ResultsTable);
