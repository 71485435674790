import React, { useEffect } from 'react';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import {
    Button,
    Drawer,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import { useArticlesState, useArticlesMethods } from './ArticlesProvider';
import Select from 'react-select';
import { makeStyles } from '@material-ui/styles';
import {
    CRITERIA_PER_DIRECTORY,
    SEARCH_CRITERIA
} from '../../../utilities/searchCriteria';
import { DIRECTORIES } from '../../../utilities/directory';
import { useObjectState } from '../../../utilities/customHooks';

const TABWIDTH = 420;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',

        '& > div:not(:last-of-type)': {
            marginBottom: theme.spacing(4)
        }
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(1)}px 0`
    }
}));

const FilterDrawer = () => {
    const classes = useStyles();
    const {
        filters,
        filterDrawerOpen,
        setArticlesState,
        locations,
        topics,
        types,
        timings
    } = useArticlesState();
    const {
        fetchArticles,
        fetchLocations,
        fetchTopics,
        fetchTypes,
        fetchTimings,
        handleClearCriteria
    } = useArticlesMethods();
    const [state, setState] = useObjectState({
        locationOptions: []
    });

    const handleApplyFilters = () => {
        setArticlesState(
            {
                fetchingArticles: true,
                filters: { ...filters, ...state },
                filterDrawerOpen: false,
                selected: [],
                page: 0
            },
            fetchArticles
        );
    };

    const handleFilterChange = (field) => (value) => {
        const newState = { [field]: value };
        if (field === 'location' && !value) {
            newState.isExactLocation = false;
        }
        setState({ ...state, ...newState });
    };

    const handleOnlineChange = (e) => {
        setState({
            isDirectoryOnly: false,
            online: e.target.checked
                ? {
                    value: 1,
                    label: 'Online'
                }
                : null
        });
    };

    const handleClearFilter = () => {
        setState({
            directory: null,
            location: null,
            type: null,
            timing: null,
            online: null,
            topic: null,
            isDirectoryOnly: false,
            isExactLocation: false,
            isCriteriaDisabled: false
        });
        handleClearCriteria();
    };

    const handleFilterCriteria = (item) => {
        const newState = {
            directory: item,
            type: null,
            timing: null,
            online: null
        };

        if (!item) {
            newState.isDirectoryOnly = false;
        }

        setState({ ...newState });
        handleClearCriteria();

        if (!item) {
            return;
        }

        const obj = CRITERIA_PER_DIRECTORY[item.value];

        if (obj.type !== '') {
            fetchTypes(SEARCH_CRITERIA[obj.type].new_criteria);
        }

        if (obj.timing !== '') {
            fetchTimings(SEARCH_CRITERIA[obj.timing].new_criteria);
        }
    };

    const handleIsDirectoryOnlyChange = (e) => {
        const isDirectoryOnly = e.target.checked;
        if (isDirectoryOnly) {
            setState({
                isDirectoryOnly,
                location: null,
                type: null,
                timing: null,
                online: null,
                topic: null,
                isExactLocation: false,
                isCriteriaDisabled: true,
                isLocationDisabled: true
            });
        } else {
            const { isExactLocation } = state;
            const isCriteriaDisabled = isExactLocation;
            setState({
                isDirectoryOnly,
                isCriteriaDisabled,
                isLocationDisabled: false
            });
        }
    };

    const handleIsExactLocationChange = (e) => {
        const isExactLocation = e.target.checked;
        if (isExactLocation) {
            setState({
                isExactLocation,
                type: null,
                timing: null,
                online: null,
                topic: null,
                isCriteriaDisabled: true
            });
        } else {
            const { isDirectoryOnly } = state;
            const isCriteriaDisabled = isDirectoryOnly;
            setState({ isExactLocation, isCriteriaDisabled });
        }
    };

    useEffect(() => {
        fetchLocations();
        fetchTopics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const {
            directory,
            location,
            type,
            timing,
            online,
            topic,
            isDirectoryOnly,
            isExactLocation
        } = filters;
        setState({
            directory,
            location,
            type,
            timing,
            online,
            topic,
            isDirectoryOnly,
            isExactLocation
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        setState({
            locationOptions: [...(locations.data || [])].sort((a, b) => {
                const [criteriaA] = a.id.split('_');
                const [criteriaB] = b.id.split('_');
                if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
                if (parseInt(criteriaA) < parseInt(criteriaB)) return -1;
                if (a.name > b.name) return 1;
                if (b.name < a.name) return -1;
                return 0;
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    const {
        directory,
        location,
        type,
        timing,
        online,
        topic,
        isDirectoryOnly,
        isExactLocation,
        isCriteriaDisabled,
        isLocationDisabled
    } = state;

    const directories = DIRECTORIES.filter((obj) => {
        return [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 19].includes(obj.id);
    }).sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const hasOnline =
        online ||
        (directory &&
            directory.value &&
            directories.filter((i) => {
                return i.id === directory.value;
            })[0].has_online === 1
            ? true
            : false);

    return (
        <Drawer
            open={filterDrawerOpen}
            anchor="right"
            onClose={() => setArticlesState({ filterDrawerOpen: false })}
            BackdropProps={{
                invisible: false
            }}
        >
            <div className={classes.root}>
                <Typography className={classes.drawerTitle}>
                    Data Filters
                </Typography>
                <Paper className={classes.paper}>
                    <div>
                        <Select
                            options={directories.map((directory) => {
                                return {
                                    value: directory.id,
                                    label: directory.name
                                };
                            })}
                            placeholder="Directories"
                            value={directory}
                            onChange={handleFilterCriteria}
                            isSearchable
                            isClearable
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!isDirectoryOnly}
                                    onChange={handleIsDirectoryOnlyChange}
                                />
                            }
                            label="Include directory only"
                        />
                    </div>
                    <div>
                        <SearchBasedDropdown
                            key="locations"
                            valueKey="id"
                            labelKey="name"
                            placeholder={
                                locations.fetching
                                    ? 'Loading Locations...'
                                    : 'Locations'
                            }
                            arrayOptions={state.locationOptions || []}
                            handleChange={handleFilterChange('location')}
                            value={location}
                            isClearable={true}
                            isDisabled={
                                isLocationDisabled ||
                                locations.fetching ||
                                locations.data.length === 0
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!isExactLocation}
                                    onChange={handleIsExactLocationChange}
                                    disabled={
                                        isLocationDisabled ||
                                        locations.fetching ||
                                        locations.data.length === 0
                                    }
                                />
                            }
                            label="Include location only"
                        />
                    </div>
                    <SearchBasedDropdown
                        key="types"
                        valueKey="id"
                        labelKey="name"
                        placeholder={
                            types.fetching ? 'Loading Types...' : 'Types'
                        }
                        arrayOptions={types.data || []}
                        handleChange={handleFilterChange('type')}
                        value={type}
                        isClearable={true}
                        isDisabled={
                            isCriteriaDisabled ||
                            types.fetching ||
                            types.data.length === 0
                        }
                    />
                    <SearchBasedDropdown
                        key="timings"
                        valueKey="id"
                        labelKey="name"
                        placeholder={
                            timings.fetching ? 'Loading Timings...' : 'Timings'
                        }
                        arrayOptions={timings.data || []}
                        handleChange={handleFilterChange('timing')}
                        value={timing}
                        isClearable={true}
                        isDisabled={
                            isCriteriaDisabled ||
                            timings.fetching ||
                            timings.data.length === 0
                        }
                    />
                    <SearchBasedDropdown
                        key="topics"
                        valueKey="id"
                        labelKey="name"
                        placeholder={
                            topics.fetching ? 'Loading Topics...' : 'Topics'
                        }
                        arrayOptions={topics.data || []}
                        handleChange={handleFilterChange('topic')}
                        value={topic}
                        isClearable
                        isDisabled={topics.fetching}
                    />

                    {hasOnline && (
                        <div className={classes.filter}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={online && online.value}
                                        onChange={handleOnlineChange}
                                    />
                                }
                                label="Online"
                            />
                        </div>
                    )}
                </Paper>
                <Button
                    data-cy="btn-apply-filters"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleApplyFilters}
                >
                    APPLY FILTERS
                </Button>
                <Button
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    onClick={handleClearFilter}
                >
                    CLEAR FILTERS
                </Button>
            </div>
        </Drawer>
    );
};

export default FilterDrawer;
