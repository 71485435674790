import React from 'react';
import { Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { useLocation } from 'react-router-dom';
import { findIndex } from 'lodash';
import LinkTab from '../../components/Tabs/LinkTab';
import DirectorySRDefaultMeta from './SEOMeta/DefaultMeta/DirectorySRDefaultMeta';
import DefaultMetaProvider from './SEOMeta/DefaultMeta/DefaultMetaProvider';
import CustomMetaProvider from './SEOMeta/CustomMeta/CustomMetaProvider';
import SearchResultCustomMeta from './SEOMeta/CustomMeta/SearchResultCustomMeta';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(2)
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontSize: '0.9rem'
    }
}));

const verifierTabs = [
    {
        name: 'Directory & SR Default Metas',
        url: 'site-tools'
    },
    {
        name: 'Search Results Custom Metas',
        url: 'site-tools/custom-meta'
    }
];

const VerifierTabs = (props) => {
    const classes = useStyles();
    const { pathname } = useLocation();

    const tabSelected = findIndex(verifierTabs, [
        'url',
        pathname.replace(/^\/+|\/+$/g, '')
    ]);

    return (
        <SnackbarProvider>
            <DefaultMetaProvider>
                <CustomMetaProvider>
                    <Tabs
                        className={classes.root}
                        value={tabSelected < 0 ? 0 : tabSelected}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        {verifierTabs.map(({ name, url }, index) => (
                            <LinkTab
                                key={`verifier-tab-${index}`}
                                label={name}
                                className={classes.tabRoot}
                                disableRipple
                                to={`/${url}`}
                            />
                        ))}
                    </Tabs>
                    <DirectorySRDefaultMeta active={tabSelected === 0} />
                    <SearchResultCustomMeta active={tabSelected === 1} />
                </CustomMetaProvider>
            </DefaultMetaProvider>
        </SnackbarProvider>
    );
};

export default VerifierTabs;
