import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    dialog: {
        minWidth: '400px',
        padding: theme.spacing.unit * 2
    },
    content: {
        padding: theme.spacing.unit * 2
    },
    actions: {},
    dialogInput: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const Transition = props => {
    return <Slide direction="up" {...props} />;
};

const DialogBox = props => {
    const {
        classes,
        open,
        title,
        disableBackdropClick = false,
        contentText,
        children,
        actions,
        stringOverride,
        onOk,
        ...unused
    } = props;
    return (
        <Dialog
            open={open}
            disableBackdropClick={disableBackdropClick}
            TransitionComponent={Transition}
            classes={{ paper: classes.dialog }}
            {...unused}
            data-cy='dialog-box'
        >
            {!!title && <DialogTitle align="center" data-cy="dialog-box-title">{title}</DialogTitle>}
            <div className={classes.dialogInput}></div>
            {children ? (
                <DialogContent className={classes.content}>
                    {children}
                </DialogContent>
            ) : contentText ? (
                <DialogContent className={classes.content}>
                    <DialogContentText>{contentText}</DialogContentText>
                </DialogContent>
            ) : null}
            {actions && (
                <DialogActions className={classes.actions}>
                    {['Ok', 'OkCancel'].includes(actions) && (
                        <Button
                            data-cy="dialog-box-primary-cta"
                            onClick={onOk || props.onClose}
                            color="primary"
                            variant="contained"
                        >
                            {(stringOverride && stringOverride.primaryAction) ||
                                'Ok'}
                        </Button>
                    )}
                    {['Cancel', 'OkCancel'].includes(actions) && (
                        <Button
                            data-cy="dialog-box-cancel"
                            onClick={props.onCancel || props.onClose}
                            color="secondary"
                            variant="contained"
                            autoFocus
                        >
                            {(stringOverride &&
                                stringOverride.secondaryAction) ||
                                'Cancel'}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

DialogBox.defaultProps = { actions: 'Ok' };

DialogBox.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    disableBackdropClick: PropTypes.bool,
    title: PropTypes.string.isRequired,
    contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    actions: PropTypes.string,
    stringOverride: PropTypes.object,
    children: PropTypes.element,
    onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onOk: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default withStyles(styles)(DialogBox);
