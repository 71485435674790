import React from 'react';
import { withProps } from './StatsTableProvider';
import { TableRow, TableCell, withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        borderBottom: 0,
    },
    totalLabel: {
        minWidth: '150px',
        backgroundColor: '#f9f9f9',
        fontSize: '18px',
        fontWeight: '600',
        color: '#555',
    },
    total: {
        backgroundColor: '#f9f9f9',
    },
    checkbox: {
        width: '70px',
        backgroundColor: '#f9f9f9',
        borderRadius: '0 0 0 5px',
    },
    rowValue: {
        fontWeight: 'bold',
        color: '#555',
        fontSize: '18px',
        margin: 0,
    },
});

const StatsTableTotals = ({ isLoading, data, columns, classes }) => {
    const totals = {};

    //eslint-disable-next-line array-callback-return
    data.map((row) => {
        columns
            .filter((column) => {
                return column.isShown;
            })
            //eslint-disable-next-line array-callback-return
            .map((column) => {
                if (!column.subcolumns) {
                    const rowTotal =
                        row.compare && row.compare[column.key]
                            ? row.compare[column.key] + row[column.key]
                            : row[column.key];
                    totals[column.key] = !totals[column.key]
                        ? rowTotal
                        : totals[column.key] + rowTotal;
                } else {
                    //eslint-disable-next-line array-callback-return
                    column.subcolumns.map((subcolumn) => {
                        const rowTotal =
                            row.compare && row.compare[subcolumn.key]
                                ? row.compare[subcolumn.key] +
                                  row[subcolumn.key]
                                : row[subcolumn.key];
                        totals[subcolumn.key] = !totals[subcolumn.key]
                            ? rowTotal
                            : totals[subcolumn.key] + rowTotal;
                    });
                }
            });
    });

    if (isLoading) return null;

    return (
        <TableRow className={classes.root}>
            <TableCell
                padding="checkbox"
                className={classes.checkbox}
            ></TableCell>
            <TableCell align="left" className={classes.totalLabel}>
                Total
            </TableCell>
            {columns
                .filter((column) => {
                    return column.isShown;
                })
                .map((column) => {
                    if (!column.subcolumns)
                        return (
                            <TableCell align="center" className={classes.total}>
                                <p className={classes.rowValue}>
                                    {!!totals[column.key]
                                        ? `${totals[column.key]
                                              .toFixed()
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                              )}`
                                        : '0'}
                                </p>
                            </TableCell>
                        );

                    return column.subcolumns.map((subcolumn) => {
                        return (
                            <TableCell align="center" className={classes.total}>
                                <p className={classes.rowValue}>
                                    {!!totals[subcolumn.key]
                                        ? `${totals[subcolumn.key]
                                              .toFixed()
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                              )}`
                                        : '0'}
                                </p>
                            </TableCell>
                        );
                    });
                })}
        </TableRow>
    );
};

export default withProps(['isLoading', 'data', 'columns'])(
    withStyles(styles)(StatsTableTotals)
);
