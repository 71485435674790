import React from 'react';
import SEOToolProvider from './SEOToolProvider';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { adminTheme } from '../../../../theme';
import SEOMetaEditor from './SEOMetaEditor';
import Header from './Header';

export default function SEOTool() {
    return (
        <MuiThemeProvider theme={adminTheme}>
            <CssBaseline />
            <SnackbarProvider autoHideDuration={5000}>
                <SEOToolProvider>
                    <Header />
                    <SEOMetaEditor />
                </SEOToolProvider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
}
