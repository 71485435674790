import { Paper, Typography, makeStyles, Button, Grow } from '@material-ui/core';
import React from 'react';
import { metaPageTypes } from '../../../../config/constants';
import { useSEOToolMethods, useSEOToolState } from './SEOToolProvider';
import CustomPlacement from './CustomPlacement';
import getCriteriaByDirectory from '../../../../utilities/searchCriteria';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    form: {},
    checkbox: {
        width: '100%'
    },
    addCustomBtn: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        borderStyle: 'dashed'
    },
    alert: {
        marginTop: theme.spacing(2),
        '& .MuiAlert-message': {
            width: '100%',
            textAlign: 'center'
        }
    }
}));

export default function CustomPlacementForm() {
    const classes = useStyles();
    const {
        directory,
        pageTypes,
        online,
        customPlacements,
        placementOptions,
        setSEOToolState,
        isEdit
    } = useSEOToolState();
    const { setCustomPlacement } = useSEOToolMethods();

    const handleCustomPlacementChange = (type) => (value) => {
        setCustomPlacement(type, value);
        setSEOToolState({ doneCheck: false, available: false });
    };

    const handleAddCustomPlacement = (type) => () => {
        setCustomPlacement(type, null);
        setSEOToolState({ doneCheck: false, available: false });
    };

    const handleRemoveCustomPlacement = (type) => () => {
        setSEOToolState((prev) => {
            const { [type]: removed, ...customPlacements } =
                prev.customPlacements;

            return {
                ...prev,
                customPlacements: customPlacements,
                doneCheck: false,
                available: false
            };
        });
    };

    const directoryCriteria = directory
        ? getCriteriaByDirectory({ key: 'id', val: [directory.value] }, [
            'id',
            'name',
            'new_criteria',
            'bin_value'
        ])[directory.value]
        : {};

    return (
        <Grow in={!!directory && pageTypes.length > 0}>
            <Paper className={classes.paper}>
                <div className={classes.form}>
                    <Typography variant="overline">Custom Fields</Typography>
                    {metaPageTypes
                        .filter(({ value }) => pageTypes.includes(value))
                        // eslint-disable-next-line
                        .map(({ value, label, isLocation }) => {
                            const isCriteria =
                                !isLocation && !!directoryCriteria[value];
                            const criteriaLabel = isCriteria
                                ? directoryCriteria[value].name
                                : '';
                            const criteriaOptionKey = isCriteria
                                ? directoryCriteria[value].new_criteria
                                : value;

                            if (!!directory && !isLocation && !isCriteria)
                                return null;

                            if (isLocation && online) return null;

                            if (
                                Object.keys(customPlacements).includes(
                                    criteriaOptionKey
                                )
                            ) {
                                const placementValue =
                                    placementOptions[criteriaOptionKey] ===
                                        'fetching'
                                        ? null
                                        : customPlacements[criteriaOptionKey];
                                const options =
                                    placementOptions[criteriaOptionKey] ===
                                        'fetching'
                                        ? []
                                        : placementOptions[criteriaOptionKey];
                                return (
                                    <CustomPlacement
                                        key={`custom-placement-${criteriaOptionKey}`}
                                        arrayOptions={options}
                                        labelKey="label"
                                        valueKey="value"
                                        value={placementValue}
                                        placeholder={
                                            placementOptions[
                                                criteriaOptionKey
                                            ] === 'fetching' ? 'Loading...' : `Select ${isLocation ? label : criteriaLabel
                                            }`}
                                        onChange={!isEdit && handleCustomPlacementChange(
                                            criteriaOptionKey
                                        )}
                                        isClearable
                                        onDelete={!isEdit && handleRemoveCustomPlacement(
                                            criteriaOptionKey
                                        )}
                                        label={criteriaLabel || label}
                                    />
                                );
                            }
                            if (!isEdit) {
                                return (
                                    <Button
                                        key={`add-custom-${criteriaOptionKey}`}
                                        data-cy={`add-custom-${criteriaOptionKey}`}
                                        fullWidth
                                        color="primary"
                                        variant="outlined"
                                        className={classes.addCustomBtn}
                                        onClick={handleAddCustomPlacement(
                                            criteriaOptionKey
                                        )}
                                    >
                                        Add {isLocation ? label : criteriaLabel}
                                    </Button>
                                )
                            }
                        })}
                    <br />
                </div>
            </Paper>
        </Grow>
    );
}
