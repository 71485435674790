import React from 'react';
import ResultsMain from './ResultsMain';
import AuthorProvider from './AuthorProvider';

const Authors = ({ active, ...props }) => {
    if (!active) return null;
    return (
        <AuthorProvider {...props}>
            <ResultsMain />
        </AuthorProvider>
    );
};

export default Authors;
