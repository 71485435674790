import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStylesProps } from '../../withstyleprops';
import { Chip } from '@material-ui/core';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

const styles = props => theme => ({
    root: {
        borderStyle: props.borderStyle
    },
    positive: {
        borderColor: props.variant === 'filled' ? green[300] : green[100],
        color: props.variant === 'filled' ? '#fff' : green[500],
        backgroundColor: props.variant === 'filled' ? green[300] : 'transparent'
    },
    negative: {
        borderColor: props.variant === 'filled' ? red[300] : red[100],
        color: props.variant === 'filled' ? '#fff' : red[500],
        backgroundColor: props.variant === 'filled' ? red[300] : 'transparent'
    },
    neutral: {
        borderColor: props.variant === 'filled' ? amber[500] : amber[100],
        color: props.variant === 'filled' ? '#fff' : amber[500],
        backgroundColor: props.variant === 'filled' ? amber[500] : 'transparent'
    },
    none: {
        borderColor: props.variant === 'filled' ? grey[300] : grey[100],
        color: props.variant === 'filled' ? '#fff' : grey[500],
        backgroundColor: props.variant === 'filled' ? grey[300] : 'transparent'
    }
});

let EnhancedChip = props => {
    const { label, classes, type, handleClick } = props;

    return (
        <Chip
            label={label}
            variant={props.variant === 'filled' ? 'default' : 'outlined'}
            className={cn(classes.root, classes[`${type}`])}
            color={props.color}
            onClick={handleClick}
        />
    );
};

EnhancedChip.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['positive', 'negative', 'neutral', 'none'])
        .isRequired,
    label: PropTypes.string.isRequired,
    borderStyle: PropTypes.string,
    variant: PropTypes.string
};

export default withStylesProps(styles)(EnhancedChip);
