import React, { Component, useContext } from 'react';
import { AuthorContext, GlobalContext } from '../../../context';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import { constructHttpParams } from '../../../helpers/dataUtility';

export const sortable = ['articles_count', 'guides_count'];

class AuthorProvider extends Component {
    static contextType = GlobalContext;
    state = {
        authors: [],
        data: [],
        count: 0,
        page: 0,
        rowsPerPage: 10,
        filters: {},
        fetching: false,
        edit: false,
        selectedAuthor: '',
        selected: [],
        sortingOrder: 'asc',
        sortBy: '',
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => {},
            onCancel: () => {}
        },
        openNotif: false,
        isSaving: false
    };

    componentDidMount() {
        this.fetchAuthors();
    }

    setAuthorsState = (newState, callback) => this.setState(newState, callback);

    fetchAuthors = () => {
        const { page, rowsPerPage } = this.state;
        const params = constructHttpParams({
            offset: page * rowsPerPage,
            limit: -1
        });

        this.setState({ fetching: true });

        sendRequest(`/authors?${params}`, ({ authors = [], count = 0 }) => {
            this.setState({ authors, count }, this.applyFilters);
        }).finally(() => this.setState({ fetching: false }));
    };

    applyFilters = () => {
        const { sortBy, sortingOrder, filters, rowsPerPage, page, authors } =
            this.state;
        const data = [...authors]
            .filter((author) => {
                if (filters.name) return author.id === filters.name.value;
                return true;
            })
            .filter((author) => {
                if (filters.articlesCount)
                    return (
                        parseInt(author.articles_count) ===
                        parseInt(filters.articlesCount)
                    );
                return true;
            })
            .filter((author) => {
                if (filters.guidesCount)
                    return (
                        parseInt(author.guides_count) ===
                        parseInt(filters.guidesCount)
                    );
                return true;
            })
            .sort((a, b) => {
                if (!sortBy) return 0;

                if (sortingOrder === 'asc') return a[sortBy] - b[sortBy];
                else return b[sortBy] - a[sortBy];
            });

        this.setState({
            count: data.length,
            data: [...data].splice(page * rowsPerPage, rowsPerPage)
        });
    };

    render() {
        return (
            <AuthorContext.Provider
                value={{
                    state: this.state,
                    setAuthorsState: this.setAuthorsState,
                    applyFilters: this.applyFilters,
                    fetchAuthors: this.fetchAuthors
                }}
            >
                {this.props.children}
            </AuthorContext.Provider>
        );
    }
}

export const withAuthorContext = (Component) => (props) => {
    return (
        <AuthorContext.Consumer>
            {(context) => <Component {...props} context={context} />}
        </AuthorContext.Consumer>
    );
};

export const useAuthorsState = () => {
    const { state, setAuthorsState } = useContext(AuthorContext);
    return { ...state, setAuthorsState };
};

export const useAuthorsMethods = () => {
    const { state, ...methods } = useContext(AuthorContext);
    return methods;
};

export default AuthorProvider;
