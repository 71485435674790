import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GA_LOGO } from '../../config/constants';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)',
        },
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    back: {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        top: theme.spacing.unit * 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none',
        },

        '& svg': {
            fill: 'white',
            fontSize: '1em',
        },
    },
    paper: {
        width: '100%',
        maxWidth: 430,
        minHeight: 600,
        padding: theme.spacing.unit * 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1',
        position: 'relative',
    },
    logo: {
        marginBottom: theme.spacing.unit * 4,
    },
    h1: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1.5em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333',
    },
    h2: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333',
    },
    submitButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& div[role=progressbar]': {
            position: 'absolute',
        },
    },
    button: {
        minWidth: 320,
        height: 50,
        background: '#03a9f4',
        color: 'white',

        '&:hover': {
            background: '#0092d3',
        },
    },
});

const Forbidden = ({
    classes,
    errorMessage = 'You are not authorized to view this page.',
}) => {
    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.back}>
                <ArrowBackIos />
                <a href="https://www.goabroad.com">Back to GoAbroad</a>
            </div>
            <img
                className={classes.background}
                src="https://images.unsplash.com/photo-1516738901171-8eb4fc13bd20?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
                alt=""
            />
            <Paper className={classes.paper}>
                <img className={classes.logo} src={GA_LOGO} alt="GoAbroad" />
                <h1 className={classes.h1}>Forbidden</h1>
                <h2 className={classes.h2}>{errorMessage}</h2>
                <div className={classes.submitButtonWrapper}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        href="https://www.goabroad.com/contact"
                    >
                        Contact Us
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export default withStyles(styles)(Forbidden);
