import React, { useState } from 'react';
import TransformControl from './TransformControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cropper from 'react-cropper';
import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import Check from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import 'cropperjs/dist/cropper.css';

const useStyles = makeStyles((theme) => ({
    cropperLoader: {
        color: '#6798e5',
        animationDuration: '550ms'
    },
    loaderContainer: {
        height: 'calc(100% - 52px)',
        width: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff'
    },
    cropperContainer: {
        height: '100%',
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        position: 'relative'
    },
    buttonContainer: {
        width: '100%',
        height: '4rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionButton: {
        margin: '5px',
        borderRadius: '50px'
    },
    zoomContainer: {
        width: '100%',
        height: '4rem',
        position: 'absolute',
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: '1rem'
    },
    cropperWrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        overflow: 'hidden'
    },
    cropper: {
        width: '100%',
        position: 'relative'
    },
    cancelButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: 82 + theme.spacing(2)
    },
    doneButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

const ImageCropper = ({
    image,
    width,
    height,
    minWidth,
    minHeight,
    rotate,
    cropperRef: cropper,
    show,
    onCancel = () => { },
    onDone = () => { }
}) => {
    const classes = useStyles();
    const [cropperReady, setImageCropperReady] = useState(false);

    const handleCropperReady = () => setImageCropperReady(true);

    const handleZoom = (value) => () => cropper.current.zoom(value);

    const handleRotate = (value) => () => cropper.current.rotate(value);

    if (!image || !show) return null;

    return (
        <div className={classes.cropperContainer} data-cy="image-cropper">
            {!cropperReady && (
                <div
                    className={classes.cropperContainer}
                    data-cy="image-cropper"
                >
                    <div className={classes.loaderContainer}>
                        <CircularProgress
                            className={classes.cropperLoader}
                            disableShrink
                            variant="indeterminate"
                            size={50}
                            thickness={3}
                        />
                    </div>
                </div>
            )}
            <div className={classes.cropperWrapper}>
                <div className={classes.cropper}>
                    <Cropper
                        data-cy='image-cropper'
                        src={image}
                        ref={cropper}
                        style={{ height: '100%', width: '100%' }}
                        aspectRatio={width / height}
                        minCropBoxWidth={minWidth}
                        minCropBoxHeight={minHeight}
                        data={{ rotate }}
                        guides={true}
                        dragMode="move"
                        modal={false}
                        viewMode={1}
                        background={true}
                        cropBoxResizable={true}
                        rotatable={true}
                        checkOrientation={true}
                        movable={true}
                        ready={handleCropperReady}
                    />
                    <section className={classes.zoomContainer}>
                        <TransformControl
                            onZoomIn={handleZoom(0.1)}
                            onZoomOut={handleZoom(-0.1)}
                            onRotateLeft={handleRotate(-90)}
                            onRotateRight={handleRotate(90)}
                            show={cropperReady}
                        />
                    </section>
                    <Tooltip title="Cancel Cropping" placement="bottom">
                        <Chip
                            className={classes.cancelButton}
                            label="Cancel"
                            onClick={onCancel}
                            data-cy="btn_clear_crop"
                            icon={<Cancel fontSize="small" />}
                            size="large"
                        />
                    </Tooltip>
                    <Tooltip title="Done Cropping" placement="bottom">
                        <Chip
                            className={classes.doneButton}
                            label="Done"
                            onClick={onDone}
                            color="primary"
                            data-cy="btn_done_crop"
                            icon={<Check fontSize="small" />}
                            size="large"
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
export default ImageCropper;
