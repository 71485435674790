import React, { Component, Fragment } from 'react';
import { Button, Chip, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
    paper: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(2),
        margin: `${theme.spacing(2)}px 0`,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        position: 'sticky',
        top: 40,
        zIndex: 1,
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& svg': {
            fontSize: 18,
        }
    },
    label: {
        padding: 0,
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        display: 'inline',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dataFilterWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
});

class DataFilters extends Component {

    renderFilterTags = (filterValues) => {
        const { classes, handleToggleFiltersDrawer } = this.props;
        
        return (
            <div>
                {
                    filterValues.map(filter => {
                        return(
                            <Tooltip
                                title={filter.filter.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                key={filter.filter}
                                placement="top"
                            >
                                <Chip
                                    className={classes.chip}
                                    variant="outlined"
                                    color="primary"
                                    label={filter.value}
                                    onClick={() => handleToggleFiltersDrawer(true)}
                                />
                            </Tooltip>
                        )
                    })
                }
                <Chip
                    key="edit_filters"
                    className={classes.chip}
                    color="primary"
                    label="Edit"
                    onClick={() => handleToggleFiltersDrawer(true)}
                />
            </div>
        )
    }

    render() {
        const { classes, filters, handleToggleFiltersDrawer } = this.props;
        let filterValues = [];
        !!filters && typeof filters === 'object' && Object.keys(filters).forEach(filter => {
            filters[filter] && filterValues.push({filter: filter, value: filters[filter].label})
        });
    
        return ( 
            <Fragment>
                <Paper
                    className={classes.paper}
                    elevation={1}
                >
                    <div className={classes.dataFilterWrapper}>
                        { filterValues.length ?
                            <Fragment>
                                <Typography className={classes.label} >Data Filters: </Typography>
                                { this.renderFilterTags(filterValues) }
                            </Fragment>
                            : <Button onClick={() => handleToggleFiltersDrawer(true)} color="primary" variant="contained">Add Filters</Button>
                        }
                    </div>               
                </Paper>
            </Fragment>
        );
    }
}

export default withStyles(styles)(DataFilters);
