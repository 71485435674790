import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    IconButton,
    makeStyles,
    Menu,
    MenuItem
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import DragHandle from '@material-ui/icons/DragHandle';
import {
    useProgramRankingMethods,
    useProgramRankingState
} from './ProgramRankingProvider';
import { BIN_VALUE, GA_URL } from '../../../config/constants';
import { NavLink } from 'react-router-dom';
import { constructHttpParams, trimSlash } from '../../../helpers/dataUtility';
import classNames from 'classnames';
import { DIRECTORIES } from '../../../utilities/directory';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1)}px 0`,
        marginBottom: theme.spacing(1),

        '& div:first-child > p': {
            paddingLeft: theme.spacing(2)
        },
        '&:last-child': {
            marginBottom: theme.spacing(2)
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    },
    wrapper: {
        alignItems: 'center',
        justifyContent: 'flex-start',

        '& div': {
            display: 'flex',
            alignItems: 'center',

            '&:nth-of-type(2) p:first-child': {
                marginRight: theme.spacing(1)
            },
            '&:nth-of-type(2) p:not(:first-child)': {
                fontWeight: 800
            }
        }
    },
    menu: {
        textDecoration: 'none',
        color: '#424242'
    },
    dragIcon: {
        cursor: 'grab'
    },
    flat: {
        boxShadow: 'none',
        marginBottom: 0
    }
}));

const TableRow = ({ program = {}, index }) => {
    const classes = useStyles();
    const [anchor, setAnchor] = useState(null);
    const {
        reorder,
        filters,
        locations,
        types,
        timings,
        setProgramRankingState
    } = useProgramRankingState();

    const getSRPage = () => {
        const [{ alias: directory_alias, page_number_tag }] =
            DIRECTORIES.filter(
                (directory) => directory.id === filters.directory_id
            );

        const sr_alias = [
            `${trimSlash(GA_URL)}`,
            `${directory_alias}/search`,
            filters.online ? 'online' : ''
        ];

        if (filters.location) {
            const [criteria_id, criteria_value] = filters.location.split('-');
            const [{ search_criteria = [] } = {}] = locations.filter(
                (loc) =>
                    loc.id === `${BIN_VALUE[criteria_id]}_${criteria_value}`
            );

            //eslint-disable-next-line array-callback-return
            search_criteria.map((criterion) => {
                const [{ alias }] = locations.filter(
                    ({ id }) =>
                        id ===
                        `${criterion.criteria_id}_${criterion.criteria_value}`
                );
                sr_alias.push(alias);
            });
        }

        if (filters.timing) {
            const [{ alias } = {}] = timings.filter(
                (timing) => timing.value === filters.timing
            );
            sr_alias.push(alias);
        }

        if (filters.type) {
            const [{ alias } = {}] = types.filter(
                (type) => type.value === filters.type
            );
            sr_alias.push(alias);
        }

        sr_alias.push(`${page_number_tag}-1`);

        return sr_alias.filter((a) => !!a).join('/');
    };

    const { onDrop, getSearchCriteria } = useProgramRankingMethods();

    const handleOpenMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.currentTarget);
    };

    const handleDragStart = () =>
        setProgramRankingState({ dragged_index: index });

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        onDrop(index);
    };

    const rowMenu = [
        {
            name: 'View SR Page',
            target: 'blank'
        },
        { name: `View Provider's Program Tab`, target: 'blank' },
        {
            name: 'Edit Program',
            url: `/listing/${program.id}/edit`
        }
    ].map((menu) => {
        const search_criteria = getSearchCriteria();
        const searchCriteriaParams = constructHttpParams(search_criteria);
        if (menu.name === 'View SR Page') {
            menu.url = getSRPage();
        }
        if (menu.name === `View Provider's Program Tab`)
            menu.url = `${trimSlash(GA_URL)}/providers/${
                program.client_alias
            }/programs?${searchCriteriaParams}`;
        return menu;
    });

    return (
        <Card
            className={classNames({
                [classes.root]: true,
                [classes.flat]: !reorder
            })}
            draggable={reorder}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <Grid container className={classes.wrapper} spacing={1}>
                <Grid item xs={2}>
                    <Typography>{program.reorder || 0}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography>{program.id}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <NavLink
                        to={{
                            pathname: `${trimSlash(GA_URL)}/providers/${
                                program.client_alias
                            }/programs/${program.url_alias}-${program.id}`
                        }}
                        className={classes.menu}
                    >
                        <Typography>{program.name}</Typography>
                    </NavLink>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={handleOpenMenu}>
                        <MoreHoriz />
                    </IconButton>
                    <Menu
                        key={`menu-wrapper-${program.id}`}
                        anchorEl={anchor}
                        open={!!anchor}
                        onClose={() => setAnchor(null)}
                        elevation={2}
                        transitionDuration={{ enter: 100, exit: 100 }}
                    >
                        {rowMenu.map((menu, index) => {
                            return (
                                <MenuItem
                                    key={`menu-item-${program.id}-${index}`}
                                    dense
                                    disableGutters
                                    // onClick={(e) => e.stopPropagation()}
                                >
                                    <NavLink
                                        // onClick={(e) => e.preventDefault()}
                                        to={{
                                            pathname: menu.url
                                        }}
                                        target={menu.target}
                                        className={classes.menu}
                                    >
                                        {menu.name}
                                    </NavLink>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                    {reorder && <DragHandle className={classes.dragIcon} />}
                </Grid>
            </Grid>
        </Card>
    );
};

export default TableRow;
