import Datepicker from 'react-datepicker';
import React, { Component } from 'react';
import {
    constructHttpParams,
    getYearMonthDayDateFormat,
} from '../../helpers/dataUtility';
import { lastDayOfMonth } from 'date-fns';
import { exportCsv } from '../../helpers/apiRequestUtility';
import {
    FormControl,
    FormControlLabel,
    Card,
    Checkbox,
    Grid,
    Divider,
    Button,
    Drawer,
    Typography,
    withStyles,
    LinearProgress,
} from '@material-ui/core';
import DialogBox from '../../components/DialogBox/DialogBox';
import SearchBasedDropdown from '../SearchBasedDropdown/SearchBasedDropdown';
import { withGlobalContext } from '../../views/Client/GlobalProvider';
import { isEmpty, sortBy } from 'lodash';
import { DIRECTORIES } from '../../utilities/directory';

const TABWIDTH = 420;

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    exportDrawer: {
        padding: `0px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`,
    },
    formControl: {
        width: '100%',

        '& > span': {
            margin: '0',
        },
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px',
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1),
        width: '310px',
    },
    export: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    drawerLabel: {
        marginBottom: theme.spacing.unit,
    },
    card: {
        overflow: 'visible',
    },
});

class ExportTopRatedProvidersDrawer extends Component {
    state = {
        start_date: null,
        end_date: null,
        openLoader: false,
        directories: [],
        selectedDirectory: null,
        reviews_online: null,
        onlineDisabled: false,
    };

    componentDidMount = () => {
        this.fetchDirectories();
    };

    fetchDirectories = () => {
        const { directories } = this.state;
        if (isEmpty(directories)) {
            this.setDirectories(
                sortBy(
                    DIRECTORIES.filter(({ id }) => {
                        return [2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 19].includes(
                            id
                        );
                    }),
                    ({ name }) => {
                        return name;
                    }
                )
            );
        }
    };

    handleClearAllFilters = () => {
        this.setState({
            start_date: null,
            end_date: null,
            selectedDirectory: null,
            reviews_online: null,
            onlineDisabled: false,
        });
    };

    handleOnlineCheckbox = (checked) => {
        this.setState({ reviews_online: checked ? 1 : 0 });
    };

    setSelectedDirectory = (selectedDirectory) => {
        const { value: directory_id = null } = selectedDirectory || {};
        const { directories } = this.state;
        let { reviews_online } = this.state;
        const onlineDisabled =
            !!selectedDirectory &&
            !directories.filter((item) => {
                return item.id === directory_id;
            })[0].has_online;
        reviews_online = onlineDisabled ? 0 : reviews_online;

        this.setState({ selectedDirectory, onlineDisabled, reviews_online });
    };

    setDirectories = (directories) => {
        this.setState({ directories });
    };

    exportTopRatedProviders = () => {
        let { start_date, end_date, selectedDirectory, reviews_online } =
            this.state;
        const { value: directory_id = null } = selectedDirectory || {};
        const { onClose, globalContext } = this.props;
        const { handleStateChange } = globalContext;

        this.setState({ openLoader: true });
        start_date = getYearMonthDayDateFormat(start_date);
        end_date = getYearMonthDayDateFormat(lastDayOfMonth(end_date));

        const url =
            '/providers/export-top-rated-providers?' +
            constructHttpParams({
                start_date,
                end_date,
                directory_id,
                reviews_online,
            });

        exportCsv(
            url,
            'Top-Rated-Providers.csv',
            () => {
                this.setState({
                    openLoader: false,
                });
                this.handleClearAllFilters();
                onClose();
            },
            () => {
                handleStateChange({
                    showLoginDialog: true,
                    loginDialogCallback: this.handleExportReviews,
                });
                this.setState({ isSaving: false });
            },
            () => {
                this.setState({
                    openLoader: false,
                });
            }
        );
    };

    render() {
        const { classes, open, onClose } = this.props;

        const {
            start_date,
            end_date,
            openLoader,
            directories,
            selectedDirectory,
            reviews_online,
            onlineDisabled,
        } = this.state;

        return (
            <Drawer
                open={open}
                anchor="right"
                onClose={onClose}
                BackdropProps={{
                    invisible: true,
                }}
            >
                <div className={classes.root}>
                    <Typography className={classes.drawerTitle}>
                        Data Filters
                    </Typography>
                    <Card className={classes.card}>
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        Directory
                                    </Typography>
                                    <SearchBasedDropdown
                                        arrayOptions={directories.map(
                                            ({ id, name }) => {
                                                return {
                                                    value: id,
                                                    label: name,
                                                };
                                            }
                                        )}
                                        onChange={this.setSelectedDirectory}
                                        value={selectedDirectory}
                                        placeholder="Select Directory"
                                        isClearable
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={reviews_online === 1}
                                                disabled={onlineDisabled}
                                                onChange={(e, checked) =>
                                                    this.handleOnlineCheckbox(
                                                        checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Online"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        Start Date
                                    </Typography>
                                    <Datepicker
                                        className={classes.datepicker}
                                        selected={start_date}
                                        onChange={(start_date) =>
                                            this.setState({ start_date })
                                        }
                                        dateFormat="MM/yyyy"
                                        placeholderText="Select Date"
                                        showMonthYearPicker
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        End Date
                                    </Typography>
                                    <Datepicker
                                        className={classes.datepicker}
                                        selected={end_date}
                                        onChange={(end_date) =>
                                            this.setState({ end_date })
                                        }
                                        dateFormat="MM/yyyy"
                                        placeholderText="Select Date"
                                        showMonthYearPicker
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Card>
                    <Button
                        className={classes.export}
                        color="primary"
                        onClick={this.exportTopRatedProviders}
                        variant="contained"
                        disabled={!(start_date && end_date)}
                    >
                        EXPORT
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleClearAllFilters}
                        variant="outlined"
                    >
                        CLEAR FILTERS
                    </Button>
                </div>
                <DialogBox
                    open={openLoader}
                    title="Export Processing"
                    actions=""
                >
                    <LinearProgress />
                </DialogBox>
            </Drawer>
        );
    }
}

export default withStyles(styles)(
    withGlobalContext(ExportTopRatedProvidersDrawer)
);
