import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import {
    INTERNAL_ADMIN,
    CLIENT_ADMIN,
    CLIENT_PROFILE
} from '../../config/constants';
import {
    isAuthenticated,
    isClientAdmin,
    getAbility
} from '../AccessLevel/ability';
import { isNumeric } from 'validator';

const isFunction = (functionToCheck) => {
    return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === '[object Function]'
    );
};

const ProtectedRoute = ({ component: Component, mode, subject, ...rest }) => {
    const ability = getAbility();
    const location = useLocation();
    const can = ability.can(mode, subject);
    const isLoggedin = isAuthenticated();

    if (!isLoggedin)
        return (
            <Redirect
                push
                to={{
                    pathname: '/login',
                    state: {
                        from: [
                            location.pathname,
                            location.search,
                            location.hash
                        ].join('')
                    }
                }}
            />
        );

    if (!can && isLoggedin) {
        let { redirectUrl: redirect = null } = rest;
        let redirectUrl = isFunction(redirect) ? redirect(location) : redirect;

        if (redirectUrl) {
            let { pathname } = location;
            let page = pathname.split('/').pop().toLowerCase();

            if (
                pathname.toLowerCase().includes(INTERNAL_ADMIN) &&
                isClientAdmin() &&
                page !== CLIENT_ADMIN
            ) {
                redirectUrl = pathname.replace(INTERNAL_ADMIN, CLIENT_ADMIN);
            }

            if (
                pathname.toLowerCase().includes(CLIENT_PROFILE) &&
                isClientAdmin()
            ) {
                if (!isNumeric(page)) redirectUrl = `/${CLIENT_ADMIN}/${page}`;
            }

            return <Redirect push to={redirectUrl} />;
        }

        // @todo redirect to 401 page
        return (
            <div>
                <h1>401</h1>
            </div>
        );
    }

    return <Route {...rest} component={Component} />;
};

export default ProtectedRoute;
