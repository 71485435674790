import React, { Component } from 'react';
import ResultsTableRow from './ResultsTableRow.js';
import TableBody from '@material-ui/core/TableBody';
import { withState, withProps } from './ResultsTableProvider';
import { TableRowLoader } from '../Loader/Loader';
import { TableCell, TableRow, Typography } from '@material-ui/core';

const usedStates = ['page', 'rowsPerPage'];
const usedProps = [
    'data',
    'isLoadingData',
    'columns',
    'noDataMessage',
    'selectable'
];

class ResultsTableBody extends Component {
    renderResultsTableRow = (row, key) => {
        return (
            <ResultsTableRow
                key={`resultstable-row-wrapper-${key}`}
                rowKey={`resultstable-row-${key}`}
                row={row}
            />
        );
    };

    render() {
        const {
            data = [],
            columns = [],
            isLoadingData,
            noDataMessage,
            selectable
        } = this.props;

        if (isLoadingData)
            return (
                <TableBody>
                    <TableRowLoader
                        colSpan={!!columns && columns.length}
                        withCheckbox={selectable}
                    />
                </TableBody>
            );

        if (!data || !Array.isArray(data) || data.length === 0)
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={columns.length + 2}>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textSecondary"
                            >
                                {noDataMessage || 'No data'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );

        if (!columns || !Array.isArray(columns) || columns.length === 0)
            return null;

        return <TableBody>{data.map(this.renderResultsTableRow)}</TableBody>;
    }
}

export default withState(usedStates)(withProps(usedProps)(ResultsTableBody));
