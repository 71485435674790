import { useEffect, useRef, useState } from 'react';
export function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
}

export const useObjectState = (value) => {
    const [state, setState] = useState(value);
    return [
        state,
        (obj) => {
            if (typeof obj === 'function') setState(obj);
            else setState((prev) => ({ ...prev, ...obj }));
        },
        setState
    ];
};

export const useSnackbarMessage = (enqueueSnackbar) => {
    return {
        showSuccess: (message = 'Success!', options = {}) =>
            enqueueSnackbar(message, { variant: 'success', ...options }),
        showError: (message = 'Error!', options = {}) =>
            enqueueSnackbar(message, { variant: 'error', ...options })
    };
};
