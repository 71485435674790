import { GA_API } from '../config/constants';
import { delete_cookie } from './cookieUtility';

const baseUrl = GA_API;

export const postRequest = (relative_url, params, callback, error) => {
    let url = baseUrl + relative_url;
    let status = 0;

    const response = fetch(url, {
        method: 'post',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
        body: params,
    })
        .then((response) => {
            status = response.status;
            return response.json();
        })
        .then((json) => {
            switch (status) {
                case 400:
                case 401:
                case 422:
                case 500:
                    return !!error && error(json);
                default:
                    return !!callback ? callback(json) : json;
            }
        })
        .catch(function (error_response) {
            return !!error ? error(error_response) : error_response;
        });

    return response;
};

export const sendRequest = (relative_url, callback, error) => {
    let url = baseUrl + relative_url;

    const response = fetch(url, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    })
        .then((response) => {
            if (response.status === 401) {
                delete_cookie('user_info', '/');
                window.location.href = '/login';
            }
            return response.json();
        })
        .then((json = {}) => {
            if (!!json.error) return error(json);
            else return callback(json || {});
        })
        .catch((error_response) => {
            !!error && error(error_response);
        });

    return response;
};

export const login = (email, password, success, error) => {
    let form = new FormData();

    form.append('email', email);
    form.append('password', password);

    postRequest(
        '/login',
        form,
        function (data) {
            if (!!success) success(data);
        },
        error
    );
};

export const exportCsv = (
    relative_url,
    filename,
    successCallback,
    authCallback,
    errorCallback,
    emptyCallback = () => {}
) => {
    let url = baseUrl + relative_url;

    fetch(url, {
        credentials: 'include',
        headers: {
            Accept: 'text/csv',
        },
    })
        .then((res) => {
            if (res.status === 200)
                res.blob().then((blob) => {
                    const objUrl = (
                        window.webkitURL || window.URL
                    ).createObjectURL(blob);

                    let tempAnchor = document.createElement('a');
                    tempAnchor.download = filename;
                    tempAnchor.href = objUrl;
                    tempAnchor.dataset.downloadurl = [
                        'text/csv',
                        tempAnchor.download,
                        tempAnchor.href,
                    ].join(':');

                    tempAnchor.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(objUrl);
                    }, 250);

                    successCallback();
                });
            else if (res.status === 204) emptyCallback();
            else if (res.status === 401) authCallback();
            else errorCallback && errorCallback();
        })
        .catch((e) => {
            !!errorCallback && errorCallback(e);
        });
};
