import React, { Component } from 'react';
import DetailsTab from './DetailsTab';
import { grey } from '@material-ui/core/colors';

import { Drawer, Tabs, Tab, withStyles } from '@material-ui/core';

const TABWIDTH = 420;

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    tabsRoot: {
        borderBottom: `1px solid ${grey[200]}`,
        marginBottom: theme.spacing(4)
    },
    tabRoot: {
        textTransform: 'initial',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '0.9rem'
    },
    badge: {
        width: 18,
        height: 18,
        right: -24,
        top: -1
    },
    labelContainer: {
        paddingLeft: 0
    }
});

const drawerTabs = [
    {
        id: 0,
        name: 'Ad Details'
    }
];

class FeaturesDrawer extends Component {
    state = {
        tabSelected: 0
    };

    handleChange = (event, tabSelected) => {
        this.setState({ tabSelected });
    };

    closeDrawer = () => {
        this.setState({ tabSelected: 0 }, () =>
            this.props.toggleFeaturesDrawer(false)
        );
    };

    render() {
        const {
            classes,
            featuresDrawerOpen,
            selectedFeature,
            filterData,
            filters
        } = this.props;
        const { tabSelected } = this.state;

        return (
            <Drawer
                open={featuresDrawerOpen}
                anchor="right"
                onClose={this.closeDrawer}
            >
                <div className={classes.root}>
                    <Tabs
                        value={tabSelected}
                        onChange={this.handleChange}
                        className={classes.tabsRoot}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                    >
                        {drawerTabs.map((tab) => {
                            return <Tab label={tab.name} />;
                        })}
                    </Tabs>
                    {tabSelected === 0 && (
                        <DetailsTab
                            {...selectedFeature}
                            filterData={filterData}
                            filters={filters}
                            closeDrawer={this.closeDrawer}
                        />
                    )}
                </div>
            </Drawer>
        );
    }
}

export default withStyles(styles)(FeaturesDrawer);
