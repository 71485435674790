import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { isEmail } from 'validator';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { postRequest } from '../../../helpers/apiRequestUtility';
import { CircularProgress } from '@material-ui/core';
import { CLIENT_ADMIN, GA_LOGO } from '../../../config/constants';
import IsLoggedIn from '../../../helpers/IsLoggedIn';
import { isClientAdmin } from '../../../components/AccessLevel/ability';

const styles = (theme) => ({
    paper: {
        width: '100%',
        maxWidth: 430,
        minHeight: 600,
        padding: theme.spacing.unit * 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1',
        position: 'relative'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        minWidth: 320,
        background: '#f8f8f8',
        marginBottom: theme.spacing.unit * 2,

        // Remove chrome autofill background
        '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active': {
            transition: 'background-color 5000s ease-in-out 0s'
        }
    },
    button: {
        minWidth: 320,
        height: 50,
        background: '#03a9f4',
        color: 'white',

        '&:hover': {
            background: '#0092d3'
        }
    },
    logo: {
        marginBottom: theme.spacing.unit * 4
    },
    h1: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1.5em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333'
    },
    h2: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333'
    },
    forgot: {
        fontSize: '0.75em',
        marginTop: theme.spacing.unit * 2,
        color: '#555',

        '& a': {
            color: '#03a9f4'
        }
    },
    email: {
        borderRight: 'none',
        borderRadius: '3px 0 0 3px'
    },
    error: {
        position: 'absolute',
        top: -50,
        background: 'rgba(255,42,42,0.60)',
        color: 'white',
        padding: '8px 16px',
        borderRadius: 3
    },
    submitButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& div[role=progressbar]': {
            position: 'absolute'
        }
    },
    progress: {
        color: '#03a9f4'
    }
});

const ForgotPasswordForm = ({ classes, googleReCaptchaProps }) => {
    const [state, setState] = useState({
        error: '',
        email: '',
        recaptchaToken: '',
        isSending: false,
        sent: false
    });

    const handleSetState = (newState, callback) => {
        setState({ ...state, ...newState }, !!callback && callback());
    };

    const handleEmailChange = (e) => {
        handleSetState({ email: e.target.value });
    };

    const getRecaptchaResponse = async () => {
        return await googleReCaptchaProps.executeRecaptcha('login');
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        const { email } = state;
        const formData = new FormData();
        formData.append('email', email);
        formData.append('g-recaptcha-response', await getRecaptchaResponse());
        handleSetState({ isSending: true });
        postRequest(
            '/send-reset-link',
            formData,
            async ({ sent, error }) => {
                handleSetState({ isSending: false });
                if (!!error)
                    handleSetState({
                        error: error.message || 'Error while sending email.'
                    });
                else if (sent)
                    handleSetState({
                        error: '',
                        recaptchaToken: '',
                        sent: true
                    });
                else if (!sent)
                    handleSetState({
                        error: 'Failed to send reset link. Please try again.',
                        recaptchaToken: await getRecaptchaResponse()
                    });
            },
            async (json) => {
                const { error: { code } = {} } = json;
                const message =
                    code === 400 &&
                    'Failed to send reset link. Email address not found.';
                handleSetState({
                    error:
                        message ||
                        'Failed to send reset link. Please try again.',
                    recaptchaToken: await getRecaptchaResponse(),
                    isSending: false
                });
            }
        );
    };

    useEffect(() => {
        if (IsLoggedIn()) {
            const redirect_url = isClientAdmin()
                ? `/${CLIENT_ADMIN}/dashboard`
                : '/dashboard';
            window.location = redirect_url;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { error, email, isSending, sent } = state;

    return (
        <Paper className={classes.paper}>
            {!!error && <span className={classes.error}>{error}</span>}
            <img className={classes.logo} src={GA_LOGO} alt="" />
            <h1 className={classes.h1}>
                Reset Your GoAbroad.com Admin Account Password
            </h1>
            <h2 className={classes.h2}>
                {sent
                    ? `Reset link has been sent to your email.`
                    : `Enter your email below to help us find your account and we’ll
                send you a link to reset your password.`}
            </h2>

            <form className={classes.form} onSubmit={sendEmail}>
                <FormControl className={classes.input} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-email">
                        Email Address
                    </InputLabel>
                    <OutlinedInput
                        data-cy="send-email"
                        id="outlined-adornment-email"
                        type="text"
                        labelWidth={105}
                        placeholder={'example@domain.com'}
                        value={email}
                        required
                        onChange={handleEmailChange}
                        error={!!error}
                        disabled={isSending || sent}
                    />
                </FormControl>
                <div className={classes.submitButtonWrapper}>
                    {isSending && (
                        <CircularProgress
                            className={classes.progress}
                            size={24}
                        />
                    )}
                    {!isSending && (
                        <Button
                            data-cy="submit-cta"
                            className={classes.button}
                            variant="contained"
                            disabled={!isEmail(email) || isSending || sent}
                            type="submit"
                        >
                            {sent ? 'Sent' : 'Submit'}
                        </Button>
                    )}
                </div>
            </form>
        </Paper>
    );
};

export default withStyles(styles)(withGoogleReCaptcha(ForgotPasswordForm));
