import React from 'react';
import DefaultExtendedFilter from './DefaultExtendedFilter';
import RadioFilter from './RadioFilter';
import RangeFilter from './RangeFilter';

export default function FilterInput({ type, ...props }) {
    if (type === 'radio') return <RadioFilter {...props} />;
    if (type === 'range') return <RangeFilter {...props} />
    return <DefaultExtendedFilter {...props} />
}
