import React, { Fragment } from 'react';
import SearchResultsTableBody from './SearchResultsTableBody';
import SearchResultsTableFooter from './SearchResultsTableFooter';
import SearchResultsTableHead from './SearchResultsTableHead';
import { Paper, Table, Typography, withStyles } from '@material-ui/core';
import Loader from '../../../../components/Loader/Loader.js';

const styles = theme => ({
    root: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(0),
        marginTop: theme.spacing(4)
    },
    tableWrapper: {
        padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px`,
        overflowX: 'scroll',

        '& td': {
            padding: '0 8px'
        }
    },
    noResults: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.54)'
    }
});

const SearchResultsTable = (props) => {
    const { classes, count, loading = false } = props;

    if (!!loading) {
        return <Loader />;
    }

    return (
        <Paper elevation={1} className={classes.root}>
            {count > 0 ? (
                <Fragment>
                    <div className={classes.tableWrapper}>
                        <Table size="small">
                            <SearchResultsTableHead />
                            <SearchResultsTableBody />
                        </Table>
                    </div>
                    <SearchResultsTableFooter />
                </Fragment>
            ) : (
                <Typography className={classes.noResults} variant="h5">
                    NO RESULTS
                </Typography>
            )}
        </Paper>
    );
};

export default withStyles(styles)(SearchResultsTable);
