import React, { PureComponent, Fragment } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { NavLink, withRouter } from 'react-router-dom';
import StatusChip from '../StatusChip/StatusChip';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import blue from '@material-ui/core/colors/blue';
import classNames from 'classnames';
import format from 'date-fns/format';
import grey from '@material-ui/core/colors/grey';
import { withStyles } from '@material-ui/core/styles';
import { withState, withProps } from './ResultsTableProvider';
import {
    stringifyObject,
    arrayJsonToString,
    arrayStringToJSON,
} from '../../helpers/dataUtility';
import { toCurrency } from '../../utilities/extract';

const styles = (theme) => ({
    bold: {
        color: grey[800],
        fontWeight: 'bold',
    },
    underline: {
        textDecoration: 'underline',
    },
    italic: {
        fontStyle: 'italic',
    },
    globalFilterIcon: {
        verticalAlign: 'middle',
        fontSize: '18px',
    },
    avatar: {
        marginRight: 4,
        color: '#fff',
        fontSize: 8,
        width: 16,
        height: 16,
        backgroundColor: blue[400],
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    menuColumn: {
        '& svg': {
            color: grey[400],
        },
    },
    link: {
        color: grey[800],
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    linkMenu: {
        color: grey[800],
        textDecoration: 'none',
    },
    iconWrapLeft: {
        display: 'flex',
        float: 'left',
        marginRight: theme.spacing(1),

        '& > svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5),
        },
    },
    iconWrapRight: {
        display: 'flex',
        float: 'right',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),

        '& > svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5),
        },
    },
    tableRow: {
        borderLeft: 'solid 5px #fff',
    },
    actionsColumn: {
        display: 'flex',
    },
});

const usedStates = ['isSelectedAll', 'selected', 'unselected'];
const usedProps = [
    'onRowClick',
    'columns',
    'icons',
    'iconsChecker',
    'rowMenus',
    'rowMenusChecker',
    'rowCTA',
    'rowCTAChecker',
    'rowClasses',
    'rowClassesChecker',
    'onSelectedChange',
    'onUnselectedChange',
    'selectable',
];

class ResultsTableRow extends PureComponent {
    state = {
        anchorEl: null,
        showDetails: true,
    };

    toggleDetails = () => {
        this.setState({ showDetails: !this.state.showDetails });
    };

    handleClick = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    handleMenuClick = (callback) => (event) => {
        const { row } = this.props;
        event.stopPropagation();
        !!callback && callback(row);
        this.handleCloseMenu(event);
    };

    handleRowSelectChange = (e) => {
        e.stopPropagation();
        let {
            row,
            selected,
            unselected,
            isSelectedAll,
            onSelectedChange,
            onUnselectedChange,
            handleStateChanged: setState,
        } = this.props;

        let selected_str = arrayJsonToString(selected);
        let unselected_str = arrayJsonToString(unselected);

        if (isSelectedAll) {
            let idx = unselected_str.indexOf(JSON.stringify(row));

            idx !== -1
                ? unselected_str.splice(idx, 1)
                : unselected_str.push(JSON.stringify(row));
            unselected = arrayStringToJSON(unselected_str);
            onUnselectedChange && onUnselectedChange(unselected);
        } else {
            if (selected_str.includes(JSON.stringify(row))) {
                selected = selected.filter(this.filterSelected);
            } else {
                selected = [...selected, row];
            }
            onSelectedChange && onSelectedChange(selected);
        }
        setState({
            selected,
            unselected,
        });
    };

    filterSelected = (item) => {
        const { row } = this.props;
        return JSON.stringify(item) !== JSON.stringify(row);
    };

    handleRowActionClick = (handler) => (event) => {
        const { row } = this.props;
        event.stopPropagation();
        !!handler && handler(row);
    };

    renderTextNumberValue = (column, row) => {
        const { classes } = this.props;
        const defaultValue = column.type === 'number' ? 0 : '';
        const column_value = column.isCurrency
            ? toCurrency(row[column.key] || 0, column.prefix || 'USD')
            : row[column.key] || defaultValue;

        if (column.listingname || column.message) {
            return null;
        }

        if (!!column.staticUrl || !!column.urlKey) {
            const [urlKey, searchParam] = (row[column.urlKey] || '').split('?');
            return (
                <NavLink
                    to={{
                        pathname: column.staticUrl || urlKey,
                        search: column.staticUrl ? '' : searchParam,
                        from: this.props.location.pathname,
                    }}
                    className={classes.link}
                    target={column.urlTarget}
                >
                    {column_value || defaultValue}
                </NavLink>
            );
        }

        return <span>{column_value || ''}</span>;
    };

    renderColumnIcons = (icons, placement) => {
        const { classes } = this.props;

        if (!icons || icons.length === 0) return null;

        return (
            <div
                className={
                    classes[
                        `iconWrap${placement === 'left' ? 'Left' : 'Right'}`
                    ]
                }
            >
                {icons
                    .filter((icon) => {
                        return icon.placement === placement;
                    })
                    .map(({ icon }, iconKey) => {
                        return (
                            <div key={`icon-${placement}-${iconKey}`}>
                                {icon}
                            </div>
                        );
                    })}
            </div>
        );
    };

    renderColumns = () => {
        const { columns = [] } = this.props;

        return columns.map(this.renderColumnByType);
    };

    renderColumnByType = (column, index) => {
        const {
            classes,
            row,
            rowKey: key,
            icons = [],
            iconsChecker,
        } = this.props;

        let { type = 'text' } = column;
        let columnIcons = icons.filter(this.filterColumnIcons(column, index));
        let validatedIcons = !!iconsChecker
            ? iconsChecker(row, columnIcons)
            : columnIcons;

        if (type === 'date')
            return (
                <TableCell key={`${key}-col-${index}`} size="small">
                    {!!row[column.key] && row[column.key] !== '0000-00-00'
                        ? format(
                              row[column.key],
                              column.isInquiry
                                  ? 'MM/DD/YYYY hh:mm A'
                                  : 'MM/DD/YYYY'
                          )
                        : ''}
                    <div className={classes.iconWrapRight}>
                        {' '}
                        {!!validatedIcons &&
                            validatedIcons.map(({ icon }, iconKey) => {
                                return (
                                    <div key={`icon-right-${iconKey}`}>
                                        {icon}
                                    </div>
                                );
                            })}{' '}
                    </div>
                </TableCell>
            );

        if (type.startsWith('status_chip:')) {
            let status_type = type.substring(type.indexOf(':') + 1).trim();
            return (
                <TableCell key={`${key}-col-${index}`} size="small">
                    <StatusChip type={status_type} status={row[column.key]} />
                    {this.renderColumnIcons(validatedIcons, 'left')}
                    {this.renderColumnIcons(validatedIcons, 'right')}
                </TableCell>
            );
        }

        if (type === 'text' || type === 'number') {
            let formatting = column.format ? column.format.split(',') : [];
            return (
                <TableCell
                    key={`${key}-col-${index}`}
                    className={classNames(
                        formatting.map((format) => classes[format])
                    )}
                    size="small"
                >
                    <a
                        style={{ color: 'black', textDecoration: 'none' }}
                        href={row[column.urlKey]}
                        target={row[column.urlKey]}
                    >
                        {row[column.listingname] ? (
                            <span>
                                <strong>Title: </strong>{' '}
                                <a
                                    className={classes.link}
                                    href={row[column.listingUrl]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {row[column.listingname]}
                                </a>
                                <br />
                            </span>
                        ) : null}
                        {row[column.clientname] ? (
                            <span>
                                <strong>Client:</strong>{' '}
                                <a
                                    className={classes.link}
                                    href={row[column.clientUrl]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {row[column.clientname]}
                                </a>
                                <br />
                            </span>
                        ) : null}
                    </a>
                    {row[column.message] ? (
                        row[column.message].length > 150 ? (
                            <div
                                style={{
                                    width: 400,
                                }}
                            >
                                {row[column.message].substring(0, 150) + '...'}
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: 400,
                                }}
                            >
                                {row[column.message]}
                            </div>
                        )
                    ) : (
                        this.renderTextNumberValue(column, row)
                    )}
                    {this.renderColumnIcons(validatedIcons, 'left')}
                    {this.renderColumnIcons(validatedIcons, 'right')}
                </TableCell>
            );
        }

        if (type === 'html') {
            let formatting = column.format ? column.format.split(',') : [];
            return (
                <TableCell
                    key={`${key}-col-${index}`}
                    className={classNames(
                        formatting.map((format) => classes[format])
                    )}
                    align={type === 'number' ? 'center' : 'left'}
                    width="300px"
                    size="small"
                >
                    <div className={classes.iconWrapLeft}>
                        {!!validatedIcons &&
                            validatedIcons
                                .filter((icon) => {
                                    return icon.placement === 'left';
                                })
                                .map(({ icon }, iconKey) => {
                                    return (
                                        <div key={`icon-left-${iconKey}`}>
                                            {icon}
                                        </div>
                                    );
                                })}
                    </div>
                    <div onClick={this.toggleDetails}>
                        <div
                            style={{
                                height: this.state.showDetails ? 50 : 'auto',
                                overflow: 'hidden',
                            }}
                        >
                            <p
                                style={{
                                    margin: 0,
                                    maxWidth: 400,
                                }}
                            >
                                {row[column.city] ? (
                                    <span
                                        style={{
                                            overflowWrap: 'break-word',
                                        }}
                                    >
                                        <strong>Locations: </strong>{' '}
                                        {row[column.city]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.availability] ? (
                                    <span>
                                        <strong>Duration:</strong>{' '}
                                        {row[column.availability]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.agerange] ? (
                                    <span>
                                        <strong>Age Requirement: </strong>{' '}
                                        {row[column.agerange]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.nationality] ? (
                                    <span>
                                        <strong>Nationalities: </strong>{' '}
                                        {row[column.nationality]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.participantType] ? (
                                    <span>
                                        <strong>Participant Type: </strong>{' '}
                                        {row[column.participantType]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.cost] ? (
                                    <span>
                                        <strong>Cost: </strong>{' '}
                                        {row[column.cost]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.hasSalary] ? (
                                    <span>
                                        <strong>Salary & Compensation: </strong>{' '}
                                        {row[column.hasSalary]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.languageSkills] ? (
                                    <span>
                                        <strong>
                                            Language Skills Required:{' '}
                                        </strong>{' '}
                                        {row[column.languageSkills]}
                                        <br />
                                    </span>
                                ) : null}
                                {row[column.experience] ? (
                                    <span>
                                        <strong>Experience Required: </strong>{' '}
                                        Yes <br />
                                    </span>
                                ) : (
                                    <span>
                                        <strong>Experience Required: </strong>{' '}
                                        No <br />
                                    </span>
                                )}
                                {row[column.requirements] ? (
                                    <span>
                                        <strong>
                                            Other Requirements of Participation:{' '}
                                        </strong>{' '}
                                        {row[column.requirements]}
                                        <br />
                                    </span>
                                ) : null}
                            </p>
                        </div>
                        {this.state.showDetails ? (
                            <u>Show More</u>
                        ) : (
                            <u>Show Less</u>
                        )}
                    </div>
                    <div className={classes.iconWrapRight}>
                        {!!validatedIcons &&
                            validatedIcons
                                .filter((icon) => {
                                    return icon.placement === 'right';
                                })
                                .map(({ icon }, iconKey) => {
                                    return (
                                        <div key={`icon-right-${iconKey}`}>
                                            {icon}
                                        </div>
                                    );
                                })}
                    </div>
                </TableCell>
            );
        }

        return (
            <TableCell key={`${key}-col-${index}`} size="small">
                <span>{row[column.key] || ''}</span>
            </TableCell>
        );
    };

    filterColumnIcons = (column, index) => (icon) => {
        return Array.isArray(icon.column)
            ? icon.column.includes[column.key] || icon.column.includes[index]
            : icon.column === column.key || icon.column === index;
    };

    renderRowActions = () => {
        const { classes, rowCTA = [], rowCTAChecker, row } = this.props;

        const validatedCTA = !!rowCTAChecker
            ? rowCTAChecker(row, rowCTA)
            : rowCTA;

        return (
            <div className={classes.actionsColumn}>
                {validatedCTA
                    .filter(this.filterValidatedCTA('before'))
                    .map(this.renderValidatedCTA)}
                {this.renderRowMenus()}
                {validatedCTA
                    .filter(this.filterValidatedCTA('after'))
                    .map(this.renderValidatedCTA)}
            </div>
        );
    };

    renderValidatedCTA = (cta, index) => {
        return (
            <div
                key={`row-cta-${index}`}
                onClick={this.handleRowActionClick(cta.handler)}
            >
                {cta.cta}
            </div>
        );
    };

    filterValidatedCTA = (placement) => (cta) => {
        if (!cta.placement && placement === 'before') return true;
        return !!cta.placement && cta.placement === placement;
    };

    renderRowMenus = () => {
        const {
            row,
            rowKey: key,
            rowMenus = [],
            rowMenusChecker,
            classes,
        } = this.props;
        const { anchorEl } = this.state;
        const validatedRowMenu = !!rowMenusChecker
            ? rowMenusChecker(row, rowMenus)
            : rowMenus;

        if (!validatedRowMenu.length) return null;

        return (
            <Fragment>
                <IconButton
                    data-id={row.id}
                    data-cy={`ellipsis-${key}`}
                    onClick={this.handleClick}
                    disableRipple
                >
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    data-cy="row-menu-list"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                    elevation={2}
                    transitionDuration={{ enter: 100, exit: 100 }}
                >
                    {validatedRowMenu.map(
                        ({ text, handler, url, target }, index) => {
                            if (!!url)
                                return (
                                    <NavLink
                                        to={{
                                            pathname: url,
                                            from: this.props.location.pathname,
                                        }}
                                        target={target}
                                        className={classes.linkMenu}
                                        key={index}
                                    >
                                        <MenuItem
                                            dense
                                            disableGutters
                                            onClick={this.handleMenuClick()}
                                            key={`${key}-menu-${index}`}
                                            data-cy={`${key}-menu-${index}`}
                                        >
                                            {text}
                                        </MenuItem>
                                    </NavLink>
                                );
                            return (
                                <MenuItem
                                    dense
                                    disableGutters
                                    onClick={this.handleMenuClick(handler)}
                                    key={`${key}-menu-${index}`}
                                    data-cy={`${key}-menu-${index}`} // added by nikka, still not sure if this is correct
                                >
                                    {text}
                                </MenuItem>
                            );
                        }
                    )}
                </Menu>
            </Fragment>
        );
    };

    renderRowClasses = () => {
        const { row, rowClasses = [], rowClassesChecker } = this.props;
        const validatedRowClasses = !!rowClassesChecker
            ? rowClassesChecker(row, rowClasses)
            : rowClasses;

        return validatedRowClasses.map(this.extractRowClassName);
    };

    extractRowClassName = (rowClass) => {
        return rowClass.className;
    };

    isRowSelected = () => {
        let { row, isSelectedAll, selected, unselected } = this.props;

        selected = !!selected && selected.map(stringifyObject);
        unselected = !!unselected && unselected.map(stringifyObject);

        if (isSelectedAll) return !unselected.includes(JSON.stringify(row));

        return selected.includes(JSON.stringify(row));
    };

    handleRowClick = () => {
        const { onRowClick, row } = this.props;
        !!onRowClick && onRowClick(row);
    };

    renderRowAdd = () => {
        return (
            <Fragment>
                <IconButton onClick={this.handleAddRowClick} disableRipple>
                    <AddBoxIcon />
                </IconButton>
            </Fragment>
        );
    };

    handleAddRowClick = () => {
        const { handleAddRowClick, row } = this.props;
        !!handleAddRowClick && handleAddRowClick(row);
    };

    render() {
        const { classes, selectable = true, rowAdd, rowKey } = this.props;
        return (
            <TableRow
                hover
                selected={this.isRowSelected()}
                onClick={this.handleRowClick}
                className={classNames(
                    classes.tableRow,
                    this.renderRowClasses()
                )}
                data-cy={`${rowKey}`}
            >
                {selectable && (
                    <TableCell padding="none" size="small">
                        <Checkbox
                            data-cy={`row-${rowKey}`}
                            checked={this.isRowSelected()}
                            onClick={this.handleRowSelectChange}
                        />
                    </TableCell>
                )}
                {this.renderColumns()}
                <TableCell size="small">
                    {this.renderRowActions()}
                    {rowAdd && this.renderRowAdd()}
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(
    withState(usedStates)(
        withProps(usedProps)(withStyles(styles)(ResultsTableRow))
    )
);
