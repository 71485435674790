import DataFilters from './DataFilters';
import FeaturesDrawer from './FeaturesDrawer/FeaturesDrawer';
import FeaturesTable from './FeaturesTable/FeaturesTable';
import FiltersDrawer from './FiltersDrawer';
import React, { Component } from 'react';
import {
    arrayDeepCopy,
    daysInMonth,
    getYearMonthDayDateFormat,
} from '../../../helpers/dataUtility';
import { withStyles } from '@material-ui/core';
import { sendRequest, postRequest } from '../../../helpers/apiRequestUtility';
import { TableLoader } from '../../../components/Loader/Loader';
import { isEmpty, map, findIndex, isEqual, isNil, has } from 'lodash';
import DialogBox from '../../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withGlobalContext } from '../../Client/GlobalProvider';

const styles = (theme) => ({
    root: {
        color: '#555',
    },
});

const today = new Date();

class FeaturesRanking extends Component {
    state = {
        reorder: false,
        webFeatures: [],
        filteredWebFeatures: [],
        showClientSearch: false,
        showProgramSearch: false,
        filters: {
            directory_filter: { label: 'GoAbroad Homepage', value: 10 },
            date_filter: new Date(today.getFullYear(), today.getMonth(), 1),
        },
        filtersDrawerOpen: false,
        featuresDrawerOpen: false,
        selectedFeature: {},
        prevFilters: {},
        loading: false,
        rankloading: false,
        dialog: {
            showDialog: false,
            title: '',
            content: '',
            onOk: () => {},
            onCancel: () => {},
            stringOverride: {},
        },
    };

    componentDidMount() {
        const { filters } = this.state;
        this.setState({ prevFilters: { ...filters } }, () => {
            this.filterData(filters, true);
        });
    }

    filterData = async (filters, refetch = false) => {
        let params = '';
        if (isEqual(filters, this.state.prevFilters) && !refetch) {
            return;
        }
        if (!isEmpty(filters.directory_filter)) {
            params = `&directory_id=${filters.directory_filter.value}`;
        }
        if (!!filters.date_filter) {
            const dateFilter = this.getFormattedDateFilter(filters.date_filter);
            params += `&${dateFilter}`;
        }
        this.setState({ prevFilters: { ...filters }, loading: true });

        let ads = await sendRequest(
            `/ads?type=12&status=1&sort=+position,+id&bin_value=1${params}`,
            ({ ads }) =>
                (ads || []).sort((a, b) =>
                    !a.position ? 1 : !b.position ? -1 : a.position - b.position
                ),
            () => []
        );
        const programIds = map(ads, 'link.program_id').join(',');

        if (!isEmpty(programIds)) {
            await sendRequest(
                `/programs?id=${programIds}&fields=id,name`,
                (res) => {
                    const { programs } = res;
                    ads = [...ads].map((ad) => {
                        const index = findIndex(programs, {
                            id: ad.link.program_id,
                        });
                        const { name = '' } = programs[index] || {};
                        ad.program_id = ad.link.program_id;
                        ad.program_name = name;
                        return ad;
                    });
                }
            );
        }

        this.setState({ webFeatures: ads, loading: false });
    };

    getFormattedDateFilter(dateFilter) {
        const month = dateFilter.getMonth();
        const year = dateFilter.getFullYear();
        const firstDay = new Date(
            dateFilter.getFullYear(),
            dateFilter.getMonth(),
            1
        );

        const lastDay = new Date(
            dateFilter.getFullYear(),
            dateFilter.getMonth(),
            daysInMonth(year, month)
        );
        return `start_date=-${getYearMonthDayDateFormat(
            lastDay
        )}&end_date=+${getYearMonthDayDateFormat(firstDay)}`;
    }

    handleApplyFilters = (filters, toClear = false) => {
        if (toClear) {
            this.setState({
                filters: {},
                prevFilters: {},
                filtersDrawerOpen: true,
                webFeatures: [],
                filteredWebFeatures: [],
            });
        } else {
            filters.program_filter = null;
            filters.client_filter = null;
            this.setState(
                {
                    filters: filters,
                    filteredWebFeatures: [],
                    filtersDrawerOpen: false,
                },
                () => {
                    this.filterData(filters);
                }
            );
        }
    };

    handleFeatureDragStart = (e, data) => {
        e.dataTransfer.setData('index', data);
    };

    handleFeatureDragOver = (e, data) => {
        e.preventDefault();

        return false;
    };

    handleAddColumnFilter = (filter) => (value) => {
        const { filters } = this.state;
        if (isNil(value) && !has(filters, filter)) {
            return;
        }
        filters[filter] = value;
        this.setState({ filters }, () => {
            this.filterByClientOrProgram(filters);
        });
    };

    filterByClientOrProgram(filters) {
        const { webFeatures } = this.state;
        if (!!filters.program_filter || !!filters.client_filter) {
            const filteredWebFeatures = webFeatures.filter((ad) => {
                if (!!filters.client_filter) {
                    return ad.client_id === filters.client_filter.value;
                }
                return ad.program_id === filters.program_filter.value;
            });
            return this.setState({ filteredWebFeatures });
        } else if (!isEmpty(this.state.filteredWebFeatures)) {
            this.setState({ filteredWebFeatures: [] });
        }
    }

    handleClearFilter = (filter) => {
        const { filters } = this.state;
        if (has(filters, filter)) {
            filters[filter] = null;
            this.setState({ filters }, () => {
                this.filterByClientOrProgram(filters);
            });
        }
    };

    handleFeatureDrop = (e, dropAreaIndex) => {
        e.preventDefault();
        let { webFeatures } = this.state;
        const oldFeatures = arrayDeepCopy(webFeatures);
        let draggedFeatureID = JSON.parse(e.dataTransfer.getData('index'));
        let draggedFeature = oldFeatures[draggedFeatureID];

        if (draggedFeatureID < dropAreaIndex) {
            for (let index = draggedFeatureID; index < dropAreaIndex; index++) {
                webFeatures[index] = oldFeatures[index + 1];
            }
            webFeatures[dropAreaIndex] = draggedFeature;
        } else if (draggedFeatureID > dropAreaIndex) {
            for (let index = draggedFeatureID; index > dropAreaIndex; index--) {
                webFeatures[index] = oldFeatures[index - 1];
            }
            webFeatures[dropAreaIndex] = draggedFeature;
        }
        this.setState({ webFeatures });
    };

    handleReorder = () => {
        this.setState({ reorder: true });
    };

    toggleSearch = (search, value) => {
        this.setState({ [search]: value });
    };

    saveNewOrder = () => {
        let { webFeatures } = this.state;
        webFeatures.forEach((feature, index) => {
            feature.position = index + 1;
        });

        this.setState({ webFeatures, reorder: false });
        const mappedAdIds = map(webFeatures, 'id');
        let rankIds = mappedAdIds.join();
        this.setState({ rankLoading: true });
        postRequest(
            `/ads/rank?id=${rankIds}`,
            [],
            (json) => {
                this.setState({ rankLoading: false });
                if (!!json.error) {
                    this.handleErrorDialog();
                } else {
                    const dialog = {
                        actions: 'Ok',
                        title: 'Successfully ranked features!',
                        showDialog: true,
                        onOk: this.handleCloseDialog,
                        stringOverride: {
                            primaryAction: 'OK',
                        },
                    };
                    this.setState({ dialog: dialog });
                }
            },
            (error) => {
                this.handleErrorDialog();
            }
        );
    };

    handleErrorDialog = () => {
        const dialog = {
            actions: 'Ok',
            title: 'Oh no! Ranking of features was unsuccessful, please try again.',
            showDialog: true,
            onOk: this.handleCloseDialog,
            stringOverride: { primaryAction: 'OK' },
        };
        this.setState({ dialog: dialog });
    };

    handleCloseDialog = () => {
        const dialog = {
            showDialog: false,
        };
        this.setState({ dialog: dialog });
    };

    toggleFiltersDrawer = (value) => {
        this.setState({ filtersDrawerOpen: value });
    };

    toggleFeaturesDrawer = (value) => {
        this.setState({ featuresDrawerOpen: value });
    };

    handleClick = (event, selected) => {
        this.setState({ selectedFeature: selected }, () => {
            this.toggleFeaturesDrawer(true);
        });
    };

    handleClearFilters = () => this.setState({ filters: {} });

    render() {
        const { classes, globalContext } = this.props;
        const { access_level: userLevel } = globalContext.state.user;
        const {
            reorder,
            webFeatures,
            filters,
            filtersDrawerOpen,
            featuresDrawerOpen,
            selectedFeature,
            loading,
            filteredWebFeatures,
        } = this.state;

        const disableRank =
            !!filters.client_filter ||
            !!filters.program_filter ||
            userLevel < 3;

        return (
            <div className={classes.root}>
                <DataFilters
                    filtersDrawerOpen={filtersDrawerOpen}
                    filters={filters}
                    isDisabled={loading}
                    handleToggleFiltersDrawer={this.toggleFiltersDrawer}
                />
                <FiltersDrawer
                    onClear={this.handleClearFilters}
                    handleApplyFilters={this.handleApplyFilters}
                    filtersDrawerOpen={filtersDrawerOpen}
                    handleToggleFiltersDrawer={this.toggleFiltersDrawer}
                />
                <FeaturesDrawer
                    featuresDrawerOpen={featuresDrawerOpen}
                    selectedFeature={selectedFeature}
                    toggleFeaturesDrawer={this.toggleFeaturesDrawer}
                    filterData={this.filterData}
                    filters={filters}
                />
                {loading ? (
                    <TableLoader />
                ) : (
                    <FeaturesTable
                        reorder={reorder}
                        disableRank={disableRank}
                        saveNewOrder={this.saveNewOrder}
                        handleReorder={this.handleReorder}
                        handleAddColumnFilter={this.handleAddColumnFilter}
                        handleClearFilter={this.handleClearFilter}
                        handleFeatureDragOver={this.handleFeatureDragOver}
                        handleFeatureDragStart={this.handleFeatureDragStart}
                        handleFeatureDrop={this.handleFeatureDrop}
                        webFeatures={
                            isEmpty(filteredWebFeatures)
                                ? webFeatures
                                : filteredWebFeatures
                        }
                        filters={filters}
                        handleClick={this.handleClick}
                        disableSearchFilter={reorder}
                    />
                )}

                <DialogBox
                    actions={this.state.dialog.actions}
                    title={this.state.dialog.title}
                    contentText={this.state.dialog.content}
                    open={this.state.dialog.showDialog}
                    onOk={this.state.dialog.onOk}
                    onCancel={this.state.dialog.onCancel}
                    onClose={this.handleCloseDialog}
                    stringOverride={this.state.dialog.stringOverride}
                    disableBackdropClick={true}
                />

                <DialogBox
                    open={this.state.rankLoading}
                    title={'Ranking program...'}
                    actions=""
                >
                    <LinearProgress />
                </DialogBox>
            </div>
        );
    }
}

export default withGlobalContext(withStyles(styles)(FeaturesRanking));
