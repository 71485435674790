import React from 'react';
import ResultsMain from './ResultsMain';
import TopicProvider from './TopicProvider';

const Topics = ({ active, ...props }) => {
    if (!active) return null;
    return (
        <TopicProvider {...props}>
            <ResultsMain />
        </TopicProvider>
    );
};

export default Topics;
