import React, { Fragment, useState } from 'react';
import DialogBox from '../../../components/DialogBox/DialogBox';
import ResultsTable from '../../../components/ResultsTable/ResultsTable';
import GlobalFilterDrawer from '../../../components/Drawer/GlobalFilterDrawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Tooltip } from '@material-ui/core';
import { useArticlesState, useArticlesMethods } from './ArticlesProvider';
import {
    useAuthenticatedRequest,
    useGlobalState
} from '../../Client/GlobalProvider';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import {
    arrayDeepCopy,
    constructHttpParams
} from '../../../helpers/dataUtility';
import { useSnackbar } from 'notistack';
import { intersection, isEmpty, keys } from 'lodash';
import { ERROR_CODES, GA_API, GA_URL } from '../../../config/constants';
import urlParser from '../../../utilities/urlParser';
import { trimSlash } from '../../../helpers/dataUtility';

const useStyles = makeStyles((theme) => ({
    addArticle: {
        marginLeft: 'auto',
        marginBottom: theme.spacing(2),
        display: 'block'
    },

    unpublished: {
        color: '#fff',
        fontSize: 10,
        width: 90,
        height: 20,
        verticalAlign: 'middle',
        display: 'inline-flex',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: red[500]
    },
    '.Mui-disabled svg': {
        fill: 'red'
    }
}));

const GLOBAL_FILTERS_NAMES = ['is_article_published'];
const COLUMN_FILTERS = ['id', 'author_id', 'date_published', 'date_modified'];

const ResultsMain = (props) => {
    const classes = useStyles();
    const { postRequest } = useAuthenticatedRequest();
    const { enqueueSnackbar } = useSnackbar();
    const {
        data,
        authors,
        programContent,
        count,
        fetchingArticles,
        selected,
        page,
        rowsPerPage,
        disablePublish,
        disableUnpublish,
        openNotif,
        filters,
        isSaving
    } = useArticlesState();

    // sort authors alphabetically
    authors.sort((a, b) => {
        let fa = a.name.toString().replace(/\s+/g, ''),
            fb = b.name.toString().replace(/\s+/g, '');
        if (fa > fb) return 1;
        if (fa < fb) return -1;
        return 0;
    })

    const {
        setArticlesState,
        fetchArticles,
        updateBookmarkParams,
        transformLocationParams,
        transformDirectoryParams
    } = useArticlesMethods();
    const { handleStateChange: setGlobalState } = useGlobalState();
    const [globalFilterOpen, setGlobalFilterOpen] = useState(false);
    const [bufferFilters, setBufferFilters] = useState({});

    const COLUMN_FILTER_NAMES = [
        'id',
        'title',
        'author_id',
        'date_published',
        'date_modified'
    ];

    const rowMenus = [
        {
            name: 'view',
            text: 'View',
            handler: (row) => {
                const url =
                    `${trimSlash(GA_URL)}/articles/${(row.alias !== null ? row.alias : '')}`;
                window.open(url, '_blank');
            }
        },
        {
            name: 'edit',
            text: 'Edit',
            handler: (row) => props.history.push(`/articles/edit/${row.id}`)
        },
        {
            name: 'publish',
            text: 'Publish',
            handler: (row) =>
                handleDialogConfirmation(
                    'Publish',
                    `publish "${row.title}"`,
                    () => handlePublished(false, row)
                )
        },
        {
            name: 'unpublish',
            text: 'Unpublish',
            handler: (row) =>
                handleDialogConfirmation(
                    'Unpublish',
                    `unpublish "${row.title}"`,
                    () => handleUnpublished(false, row)
                )
        },
        {
            name: 'reassign',
            text: 'Reassign',
            handler: (row) => {
                setGlobalState({ articleDrawerOpen: true });
                setArticlesState({ selectedArticle: row, reassign: true });
            }
        },
        {
            name: 'featHome',
            text: 'Feature on Articles Homepage',
            handler: (row) =>
                handleDialogConfirmation(
                    'Feature on Homepage',
                    `feature the "${row.title}" on Homepage`,
                    () => handleFeatureHomepageDirectory(row, 1)
                )
        },
        {
            name: 'featDir',
            text: 'Feature on Directory',
            handler: (row) =>
                handleDialogConfirmation(
                    'Feature on Directory',
                    `feature the "${row.title}" on Directory`,
                    () => handleFeatureHomepageDirectory(row, 0)
                )
        },
        {
            name: 'delete',
            text: 'Delete',
            handler: (row) => {
                handleOpenDialog(
                    'Delete Article',
                    'Are you sure you want to delete this article?',
                    () => {
                        handleDelete(false, row);
                    }
                );
            }
        }
    ];

    const handleDialogConfirmation = (batchAction, message, callback) => {
        let dialog = {
            show: true,
            title: batchAction,
            content: `Are you sure you want to ${message}?`,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            },
            stringOverride: {
                primaryAction: batchAction,
                secondaryAction: 'Cancel'
            }
        };

        setArticlesState({
            dialog: dialog
        });
    };

    const handleCloseDialog = () => {
        const dialog = {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => {},
            onClose: () => {}
        };

        setArticlesState({ dialog: dialog });
    };

    const handleUnpublished = (isBatch, article) => {
        const data = new FormData();

        let selectedIds = isBatch
            ? selected.map((article) => article.id)
            : [article.id];

        data.append('id', selectedIds.join());

        handleCloseDialog();
        setArticlesState({ isSaving: true });
        postRequest(
            '/articles/unpublish',
            data,
            (json) => {
                setArticlesState(
                    {
                        selected: [],
                        fetchingArticles: true,
                        page: 0
                    },
                    fetchArticles
                );
                enqueueSnackbar('Articles have been updated!', {
                    variant: 'success'
                });
            },
            ({ code }) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to update articles.', {
                        variant: 'error'
                    });
            },
            () => handleUnpublished(isBatch, article)
        ).finally(() => setArticlesState({ isSaving: false }));
    };

    const handlePublished = (isBatch, article) => {
        const data = new FormData();
        article.date_modified = article.date_published

        let selectedIds = isBatch
            ? selected.map((article) => article.id)
            : [article.id];

        data.append('id', selectedIds.join());

        handleCloseDialog();
        setArticlesState({ isSaving: true });
        postRequest(
            '/articles/publish',
            data,
            (json) => {
                setArticlesState(
                    {
                        selected: [],
                        page: 0,
                        fetchingArticles: true
                    },
                    fetchArticles
                );
                enqueueSnackbar('Articles have been updated!', {
                    variant: 'success'
                });
            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to update articles.', {
                        variant: 'error'
                    });
            },
            () => handlePublished(isBatch, article)
        ).finally(() => setArticlesState({ isSaving: false }));
    };

    const handleFeatureHomepageDirectory = (article, location) => {
        const data = new FormData();

        data.append('homepage', location);

        handleCloseDialog();
        setArticlesState({ isSaving: true });
        postRequest(
            `/articles/${article.id}/featured-articles`,
            data,
            (json) => {
                setArticlesState(
                    {
                        selected: [],
                        page: 0,
                        fetchingArticles: true
                    },
                    fetchArticles
                );
                enqueueSnackbar('Articles have been updated!', {
                    variant: 'success'
                });
            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to update articles.', {
                        variant: 'error'
                    });
            },
            () => handleFeatureHomepageDirectory(article, location)
        ).finally(() => setArticlesState({ isSaving: false }));
    };

    const rowMenusChecker = (row, rowMenus) => {
        return rowMenus.filter((menu) => {
            if (menu.name === 'publish') return row.status === 0;
            if (menu.name === 'unpublish' || menu.name === 'view')
                return row.status === 1;
            if (menu.name === 'featHome')
                return (
                    row.status === 1 &&
                    (row.is_featured === 0 || row.is_featured === null)
                );
            if (menu.name === 'featDir')
                return (
                    row.status === 1 &&
                    (row.is_featured === 1 || row.is_featured === null)
                );
            return true;
        });
    };

    const handleColumnFiltersChange = (filter, value) => {
        const newFilters = { ...filters };
        newFilters[filter] = value;
        setArticlesState({ filters: newFilters, selected: [] });
    };

    const programContentFiltered = programContent
        .filter((art) => {
            return art.title !== '';
        })
        .filter((article) => {
            if (!!filters.directory) {
                const { search_criteria } = article;
                return [...(search_criteria || [])].some(
                    ({ value, criteria }) =>
                        criteria === 'directory_id' &&
                        value === filters.directory.value
                );
            }
            return true;
        })
        .map(({ title }) => {
            return { title };
        });

    const author = filters.author_id
        ? authors.filter((auth) => {
              return auth.id === filters.author_id;
          })[0]
        : null;

    const author_value = author
        ? { value: author.id, label: author.name }
        : null;

    const columns = [
        {
            name: 'Title',
            type: 'text',
            format: 'bold',
            key: 'title',
            filter: {
                options: programContentFiltered,
                type: 'select',
                optionValue: 'title',
                optionKey: 'title',
                value: { label: filters.title },
                onChange: ({ value }) =>
                    handleColumnFiltersChange('title', value),
                onInputChange: (value, { action }) => {
                    if (action === 'input-change')
                        handleColumnFiltersChange('title', value);
                }
            }
        },
        {
            name: 'Author Name',
            format: 'bold',
            key: 'author_name',
            options: [],
            filter: {
                type: 'select',
                options: authors || {},
                optionValue: 'id',
                optionKey: 'name',
                value: author_value,
                onChange: (author) =>
                    handleColumnFiltersChange('author_id', author.value)
            }
        },
        {
            name: 'Date Published',
            type: 'date',
            format: 'normal',
            key: 'date_published',
            filter: {
                onChange: (date_published) =>
                    handleColumnFiltersChange('date_published', date_published)
            }
        },
        {
            name: 'Date Modified',
            type: 'date',
            format: 'normal',
            key: 'date_modified',
            filter: {
                onChange: (date_modified) =>
                    handleColumnFiltersChange('date_modified', date_modified)
            }
        }
    ];

    const batchActions = [
        {
            icon: 'publish',
            handler: () => {
                handleDialogConfirmation(
                    'Publish',
                    `publish ${selected.length} article${
                        selected.length === 1 ? '' : 's'
                    }`,
                    () => handlePublished(true)
                );
            },
            disabled: disablePublish
        },
        {
            icon: 'unpublish',
            handler: () => {
                handleDialogConfirmation(
                    'Unpublish',
                    `unpublish ${selected.length} article${
                        selected.length === 1 ? '' : 's'
                    }`,
                    () => handleUnpublished(true)
                );
            },
            disabled: disableUnpublish
        },
        {
            icon: 'delete',
            handler: () =>
                handleBatchDialogConfirmation('Delete', 'Deleted', () =>
                    handleDelete(true)
                )
        },
        {
            icon: 'export',
            handler: () =>
                handleBatchDialogConfirmation(
                    'Export Articles',
                    '',
                    handleExport,
                    'Download All Columns & Article Fields as CSV'
                ),
            enableInSelectAll: true
        }
    ];

    const icons = [
        {
            name: 'unpublished',
            icon: (
                <Tooltip title="Unpublished" placement="top">
                    <Typography className={classes.unpublished}>
                        UNPUBLISHED
                    </Typography>
                </Tooltip>
            ),
            placement: 'right',
            column: 'title'
        }
    ];

    const iconsChecker = (row, icons) => {
        return icons.filter((icon) => {
            if (icon.name === 'unpublished') return row.status === 0;
            return true;
        });
    };

    const tableData = arrayDeepCopy(data).map(
        ({ date_published, ...article }) => {
            if (
                [null, undefined, '0000-00-00 00:00:00'].includes(
                    date_published
                )
            ) {
                return { date_published: '', ...article };
            }

            return { date_published, ...article };
        }
    );

    const handleOpenDialog = (title, content, callback) => {
        const dialog = {
            actions: 'OkCancel',
            title,
            content,
            show: true,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        };
        setArticlesState({ dialog: dialog });
    };

    const handleBatchDialogConfirmation = (
        batchAction,
        message,
        callback,
        content = null
    ) => {
        let dialog = {
            show: true,
            title: batchAction,
            content:
                content ||
                `Are you sure you want to set selected article/s to ${message}?`,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            },
            stringOverride: {
                primaryAction: batchAction,
                secondaryAction: 'Cancel'
            }
        };

        setArticlesState({
            dialog: dialog
        });
    };

    const handleDelete = (isBatch, article) => {
        const data = new FormData();
        let selectedIds = isBatch
            ? selected.map((article) => article.id)
            : [article.id];

        data.append('id', selectedIds.join());

        handleCloseDialog();
        setArticlesState({ isSaving: true });

        postRequest(
            '/articles/delete',
            data,
            (json) => {
                setArticlesState(
                    {
                        selected: [],
                        page: 0,
                        fetchingArticles: true,
                        isSaving: false
                    },
                    () => fetchArticles()
                );
                enqueueSnackbar('Articles have been deleted!', {
                    variant: 'success'
                });
            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to delete articles.', {
                        variant: 'error'
                    });
            },
            () => handleDelete(isBatch, article)
        ).finally(() => setArticlesState({ isSaving: false }));
    };

    const handleExport = () => {
        const ids = selected.map((obj) => {
            return obj.id;
        });

        const filename = 'Articles.csv';

        let url = `${GA_API}/articles/export`;

        handleCloseDialog();
        setArticlesState({ isSaving: true });

        if (selected.length > 0) {
            url += '?id=' + ids.join(',');
        } else {
            const {
                id,
                title,
                author_id,
                date_published,
                date_modified,
                is_article_published,
                topic
            } = filters;
            let filterParams = {
                id: id !== null ? id : null,
                title: title !== null ? title : null,
                author_id: author_id !== null ? author_id : null,
                date_published: date_published,
                date_modified: date_modified,
                status:
                    is_article_published !== null ? is_article_published : null,
                topic_id: !isEmpty(topic) ? topic.value : null
            };

            if (!!title) {
                filterParams.wildcards = 'title';
            }

            const locationParams = transformLocationParams();
            const directoryParams = transformDirectoryParams();

            filterParams = {
                limit: count,
                offset: 0,
                ...filterParams,
                ...locationParams,
                ...directoryParams
            };

            url += '?' + constructHttpParams(filterParams);
        }

        fetch(url, {
            credentials: 'include',
            headers: {
                Accept: 'text/csv'
            }
        })
            .then((res) => {
                if (res.status === ERROR_CODES.UNAUTHORIZED)
                    setGlobalState({
                        showLoginDialog: true,
                        loginDialogCallback: this.exportInvoice
                    });
                else if (res.status === ERROR_CODES.SUCCESS)
                    res.blob().then((blob) => {
                        const objUrl = (
                            window.webkitURL || window.URL
                        ).createObjectURL(blob);

                        let tempAnchor = document.createElement('a');
                        tempAnchor.download = filename;
                        tempAnchor.href = objUrl;
                        tempAnchor.dataset.downloadurl = [
                            'text/csv',
                            tempAnchor.download,
                            tempAnchor.href
                        ].join(':');

                        tempAnchor.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(objUrl);
                        }, 250);

                        setArticlesState({ selected: [] });
                        enqueueSnackbar('Articles successfully exported!', {
                            variant: 'success'
                        });
                    });
                else {
                    enqueueSnackbar('Error encountered', { variant: 'error' });
                }
            })
            .finally(() => setArticlesState({ isSaving: false }));
    };

    const handleSelectMultipleArticles = (rows) => {
        let disablePublish = !rows.every((val) => val['status'] === 0);
        let disableUnpublish = !rows.every((val) => val['status'] === 1);

        setArticlesState({
            selected: rows,
            disablePublish: disablePublish,
            disableUnpublish: disableUnpublish
        });
    };

    const handleGlobalFiltersChange = ({ filters: globalFilters }) => {
        let { is_article_published, online, ...otherFilters } = globalFilters;
        otherFilters.is_article_published = is_article_published;
        setBufferFilters({ ...filters, ...otherFilters });
        setArticlesState({
            selected: []
        });
    };

    const handleClearGlobalFilters = ({ filters: globalFilters }) => {
        let { online, ...otherFilters } = globalFilters;
        const newFilters =  { otherFilters};
        newFilters['status'] = null;
        setArticlesState(
            {
                filters: { ...filters, ...newFilters },
                fetchingArticles: true,
                page: 0,
                selected: []
            },
            fetchArticles
        );
        setBufferFilters({});
    };

    const handleApplyGlobalFilters = () => {
        setArticlesState(
            {
                filters: bufferFilters,
                fetchingArticles: true,
                page: 0,
                selected: []
            },
            fetchArticles
        );
    };

    const handleCloseGlobalFilters = () => {
        setGlobalFilterOpen(false);
    };

    const handleApplyColumnFilters = () => {
        setArticlesState(
            {
                page: 0,
                fetchingArticles: true,
                selected: []
            },
            () => fetchArticles(updateBookmarkParams)
        );
    };

    const handleClearColumnFilters = () => {
        const newFilters = { ...filters };
        //eslint-disable-next-line array-callback-return
        COLUMN_FILTER_NAMES.map((key) => {
            newFilters[key] = null;
        });
        setArticlesState(
            {
                filters: newFilters,
                page: 0,
                fetchingArticles: true,
                selected: []
            },
            () => fetchArticles(updateBookmarkParams)
        );
    };

    const activeGlobalFilters = intersection(
        keys(filters),
        GLOBAL_FILTERS_NAMES
    ).some((key) => ![null, undefined, ''].includes(filters[key]));
    const showColumnFilters = intersection(keys(filters), COLUMN_FILTERS).some(
        (key) => ![null, undefined, ''].includes(filters[key])
    );

    const searchFilterOptions = [
        {
            key: 'page_url',
            name: 'Page URL',
        }
    ];

    const handleFilterByUrl = async(url) => {
        let slug = trimSlash(url).split(`/`).pop();
        const { hostname: domain } = urlParser(url);
        const { hostname: GA_DOMAIN } = urlParser(GA_URL);

        if (![GA_DOMAIN].includes(domain)) {
            enqueueSnackbar('Invalid URL', {
                variant: 'error',
                autoHideDuration: 2000
            });
            return;
        }
        
        setArticlesState({
            filters: {alias: slug},
            page: 0,
            fetchingArticles: true,
            selected: []
        }, 
            fetchArticles
        );
    }

    const handleSearchFilterChange = ({filter, value}) => {
        if(filter === 'page_url'){
            handleFilterByUrl(value);
        }
    }

    const handleSearchFilterClose = () => {
        setArticlesState(
            {
                filters: { ...filters, alias: null },
                fetchingArticles: true,
                page: 0,
                selected: []
            },
            fetchArticles
        );
    }

    return (
        <Fragment>
            <Snackbar
                className={classes.notification}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={openNotif}
                autoHideDuration={5000}
                onClose={() => {}}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => {}}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <GlobalFilterDrawer
                articleStatus
                teamAssignment={false}
                accountStatus={false}
                open={globalFilterOpen}
                onFiltersChange={handleGlobalFiltersChange}
                onClose={handleCloseGlobalFilters}
                onClearFilters={handleClearGlobalFilters}
                onApplyFilter={handleApplyGlobalFilters}
            />
            <ResultsTable
                columns={columns}
                label={`article${count === 1 ? '' : 's'}`}
                hasSearch={true}
                data={tableData}
                isLoadingData={fetchingArticles}
                count={count}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenusChecker}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                icons={icons}
                iconsChecker={iconsChecker}
                batchActions={batchActions}
                showColumnFilters={showColumnFilters}
                onGlobalFiltersClick={() => setGlobalFilterOpen(true)}
                onPageChange={(page) =>
                    setArticlesState(
                        { fetchingArticles: true, page },
                        fetchArticles
                    )
                }
                onRowsPerPageChange={(rows) =>
                    setArticlesState(
                        {
                            fetchingArticles: true,
                            page: 0,
                            rowsPerPage: rows
                        },
                        fetchArticles
                    )
                }
                onSelectedChange={(rows) => handleSelectMultipleArticles(rows)}
                onColumnFiltersApply={handleApplyColumnFilters}
                onColumnFilterClose={handleClearColumnFilters}
                activeGlobalFilters={activeGlobalFilters}
                searchFilterOptions={searchFilterOptions}
                onSearchFilterChange={handleSearchFilterChange}
                onSearchFilterClose={handleSearchFilterClose}
            />
            <DialogBox open={isSaving} title={'Processing...'} actions="">
                <LinearProgress />
            </DialogBox>
        </Fragment>
    );
};

export default ResultsMain;
