import React from 'react';
import ResultsTable from '../../../../components/ResultsTable/ResultsTable';
import { useDefaultMetaState } from './DefaultMetaProvider';
import MetaViewer from '../MetaViewer';
import { GA_URL } from '../../../../config/constants';
import urlParser from '../../../../utilities/urlParser';
import { trimSlash } from '../../../../helpers/dataUtility';
import { useSnackbar } from 'notistack';
import { DIRECTORIES } from '../../../../utilities/directory';
import { sendRequest } from '../../../../helpers/apiRequestUtility';
export default function ResultsMain() {
    const {
        data,
        count,
        page,
        rowsPerPage,
        fetching,
        selectedMeta,
        setDefaultMetaState
    } = useDefaultMetaState();

    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            id: 'directory',
            key: 'directory',
            name: 'Directory',
            type: 'text'
        },
        {
            id: 'page_type',
            key: 'pageTypeLabel',
            name: 'Page Type',
            type: 'text'
        },
        {
            id: 'title',
            key: 'title',
            name: 'Page Title',
            type: 'text'
        },
        {
            id: 'description_truncated',
            key: 'descriptionTruncated',
            name: 'Meta Description',
            type: 'text'
        },
        {
            id: 'h1',
            key: 'h1',
            name: 'H1',
            type: 'text'
        },
        {
            id: 'h2',
            key: 'h2',
            name: 'H2',
            type: 'text'
        }
    ];

    const rowMenus = [
        {
            name: 'edit',
            text: 'Edit',
            target: '/site-tools/seo-tool/',
            handler: ({ id }) => {
                window.location.href = `/site-tools/seo-tool/${id}`;
            }
        }
    ];
    const getData = () => {

        data.forEach((dataDetail) => {
            dataDetail.descriptionTruncated = dataDetail.description.substr(0, 160);
            if (dataDetail.description.length > 160) {
                dataDetail.descriptionTruncated += "..."
            }
        })
        return data;
    }
    const handleOpenDialog = (title, content, callback) => {
        const dialog = {
            actions: 'OkCancel',
            title,
            content,
            show: true,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        };
        setDefaultMetaState({ dialog: dialog });
    };

    const handleCloseDialog = () => {
        const dialog = {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => { },
            onClose: () => { }
        };

        setDefaultMetaState({ dialog: dialog });
    };
    const searchFilters = [
        {
            key: 'page_url',
            name: 'Page URL',
            hasAutoSuggest: false
        }
    ];

    const handleRowClick = (row) => {
        setDefaultMetaState({ selectedMeta: row })
    }
    const handleSearchFilterChange = ({ filter, value }) => {
        if (filter === 'page_url') {
            handleFilterByUrl(value);
        }
    }
    const handleCloseSearchFilter = () => {
        setDefaultMetaState({
            urlFilter: null,
            selected: []
        })
    }
    const getAliasCriteria = (alias = []) => {
        let slug = [];
        alias.pop();
        if (alias.length < 3) return []
        for (let index = 5; index < alias.length; index++) {
            slug.push(alias[index]);
        }

        return slug;
    }
    const handleFilterByUrl = async (url) => {
        let slug = trimSlash(url).split(`/`);
        let directory = slug[3];
        let criteria = getAliasCriteria(slug);
        let params = "";
        const { hostname: domain } = urlParser(url);
        const { hostname: GA_DOMAIN } = urlParser(GA_URL);

        if (GA_DOMAIN !== domain) {
            enqueueSnackbar('Invalid URL', {
                variant: 'error',
                autoHideDuration: 2000
            });
            return;
        }
        setDefaultMetaState({ fetching: true });
        const { id } = (DIRECTORIES.find((data) => data.alias === directory));
        if (criteria.length === 0) {
            params = "criteria[]";
        };
        criteria.forEach((crit, i) => {
            params += `criteria[]=${crit}${(i === criteria.length - 1) ? "" : "&"}`;
        });

        await sendRequest(
            `/directories/${id}/alias/search-options?${params}`,
            ({ search_criteria }) => {


                setDefaultMetaState({
                    urlFilter: criteria.includes("online")
                        ? [...search_criteria, { value: 1, criteria: "online" }] :
                        search_criteria,
                    page: 0,
                    selected: [],
                    filters: {}
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    return (
        <>
            <ResultsTable
                data={getData()}
                columns={columns}
                count={count}
                rowMenus={rowMenus}
                searchFilterOptions={searchFilters}
                isLoadingData={fetching}
                hasGlobalFilters={false}
                hasColumnFilters={false}
                label={count > 1 ? 'default metas' : 'default meta'}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={(rows) => {
                    setDefaultMetaState(
                        {
                            page: 0,
                            rowsPerPage: rows
                        }
                    )


                }
                }
                onPageChange={(page) =>

                    setDefaultMetaState(
                        {
                            page: page
                        },

                    )
                }
                onRowClick={handleRowClick}
                onSearchFilterChange={handleSearchFilterChange}
                onSearchFilterClose={handleCloseSearchFilter}
            />
            <MetaViewer
                open={!!selectedMeta}
                onClose={() => setDefaultMetaState({ selectedMeta: null })}
                meta={selectedMeta}
                openDialog={handleOpenDialog}
            />
        </>
    );
}
