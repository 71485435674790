import * as dataUtility from '../../../helpers/dataUtility';

import React, { PureComponent } from 'react';

const { Provider, Consumer } = React.createContext();

class StatsTableProvider extends PureComponent {
    state = {
        selected: [],
        unselected: [],
        page: this.props.page || 0,
        rowsPerPage: this.props.rowsPerPage || 10,
        isSelectedAll: false
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.page !== prevProps.page)
            this.setState({ page: this.props.page });

        if (this.props.rowsPerPage !== prevProps.rowsPerPage)
            this.setState({ rowsPerPage: this.props.rowsPerPage });

        if (
            JSON.stringify(this.props.selected) !==
            JSON.stringify(prevProps.selected)
        )
            this.setState({ selected: this.props.selected });
    };

    handleSetState = (state, callback) => this.setState(state, callback);

    getSelectedFromCurrentPage = () => {
        let { selected } = this.props;
        let dataOnPage = this.getDataFromCurrentPage();

        let selectedLabels = dataUtility.arrayJsonToString(selected);
        let dataOnPageLabels = dataUtility.arrayJsonToString(dataOnPage);

        return dataOnPageLabels.filter((item) => {
            return selectedLabels.includes(item);
        });
    };

    getDataFromCurrentPage = () => {
        let { data } = this.props;
        let { page, rowsPerPage } = this.state;
        if (!data || !Array.isArray(data)) return [];

        return [...data].slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    };

    render() {
        return (
            <Provider
                value={{
                    state: this.state,
                    props: this.props,
                    handleSetState: this.handleSetState,
                    getSelectedFromCurrentPage: this.getSelectedFromCurrentPage,
                    getDataFromCurrentPage: this.getDataFromCurrentPage
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export const withState =
    (fields = []) =>
    (Component) =>
    (props) => {
        return (
            <Consumer>
                {(context) => {
                    const { state, props: context_props, ...rest } = context;
                    let values = {};
                    //eslint-disable-next-line array-callback-return
                    fields.map((field) => {
                        values[field] = state[field];
                    });
                    return <Component {...values} {...props} {...rest} />;
                }}
            </Consumer>
        );
    };

export const withProps =
    (fields = []) =>
    (Component) =>
    (props) => {
        return (
            <Consumer>
                {(context) => {
                    const { props: context_props } = context;
                    let values = {};
                    //eslint-disable-next-line array-callback-return
                    fields.map((field) => {
                        values[field] = context_props[field];
                    });
                    return <Component {...values} {...props} />;
                }}
            </Consumer>
        );
    };

export default StatsTableProvider;
