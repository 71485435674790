import React from 'react';
import TableRow from './SearchResultsTableRow';
import { withSearchResultsTableContext } from './SearchResultsTableProvider';
import { TableBody } from '@material-ui/core';

const SearchResultsTableBody = props => {
    const { context } = props;
    const { page = 0, rowsPerPage = 10, data } = context.state;

    return (
        <TableBody>
            {data
                .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
                .map((row) => {
                    return (
                        <TableRow
                            row={row}
                            key={`search-results-table-row-${row.provider.id}`}
                        />
                    );
                })}
        </TableBody>
    );
};

export default withSearchResultsTableContext(SearchResultsTableBody);
