import { SnackbarProvider } from 'notistack';
import React from 'react';
import AddGuideDrawer from './AddGuideDrawer';
import DataFilterDrawer from './DataFilterDrawer';
import GuidesProvider from './GuidesProvider';
import ResultsMain from './ResultsMain';

const Main = (props) => {
    return (
        <SnackbarProvider>
            <GuidesProvider>
                <DataFilterDrawer />
                <AddGuideDrawer />
                <ResultsMain />
            </GuidesProvider>
        </SnackbarProvider>
    );
};

export default Main;
