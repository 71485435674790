import React from 'react';
import { Chip, makeStyles, Paper, Typography } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useGuidesState } from './GuidesProvider';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2)
    },
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    filterChip: {
        marginRight: theme.spacing(1)
    }
}));

const DataFilters = (props) => {
    const classes = useStyles();
    const { filters, setGuidesState } = useGuidesState();

    const handleAddFilter = () => setGuidesState({ dataFilterOpen: true });

    const handleDeleteFilter = (key) => () =>
        setGuidesState((prev) => ({
            ...prev,
            filters: { ...prev.filters, [key]: null }
        }));

    const hasFilters = Object.keys(filters)
        .filter((key) => !['online', 'exactLocation'].includes(key))
        .some((key) => !!filters[key]);

    return (
        <Paper elevation={1} className={classes.paper}>
            <div className={classes.root}>
                <Typography variant="overline">Data Filters</Typography>
                <br />
                {Object.keys(filters)
                    .filter((key) => [
                        'directory',
                        'location',
                        'type',
                        'timing',
                        'provider',
                        'online'
                    ].includes(key))
                    .map((key) => {
                        if (!filters[key]) return null;
                        const { label } = filters[key] || {};
                        return (
                            <Chip
                                label={label}
                                color="primary"
                                onDelete={handleDeleteFilter(key)}
                                onClick={handleAddFilter}
                                className={classes.filterChip}
                            />
                        );
                    })}
                <Chip
                    icon={
                        hasFilters ? (
                            <EditIcon fontSize="small" />
                        ) : (
                            <AddBoxIcon fontSize="small" />
                        )
                    }
                    data-cy="add-data-filter"
                    label={hasFilters ? 'Edit Filters' : 'Add Filters'}
                    onClick={handleAddFilter}
                    color="primary"
                    variant="outlined"
                />
            </div>
        </Paper>
    );
};

export default DataFilters;
