import React, { useState } from 'react';
import { CSVDownload } from "react-csv";
import DisplayFilter from './DisplayFilter';
import ExportIcon from '@material-ui/icons/CloudDownload';
import StatsTableBody from './StatsTableBody';
import StatsTableFooter from './StatsTableFooter';
import StatsTableHead from './StatsTableHead';
import StatsTableProvider from './StatsTableProvider';
import StatsTableTotals from './StatsTableTotals';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import StatsExportDialog from './StatsExportDialog';
import {
    IconButton,
    Paper,
    Table,
    Toolbar,
    Tooltip,
    withStyles
} from '@material-ui/core';
import ColumnManipulator from './ColumnManipulator';

const styles = (theme) => ({
    paper: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(2),
        marginTop: theme.spacing(4)
    },
    tableWrap: {
        overflowX: 'auto'
    },
    root: {
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
        borderRadius: 3
    },
    highlight: {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        display: 'flex',
        color: theme.palette.text.secondary
    },
    title: {
        flex: '0 0 auto'
    },
    tableTitle: {
        fontSize: '1.2rem',
        fontWeight: 'normal'
    },
    toolbarActions: {
        display: 'flex',
        alignItems: 'center'
    },
    batchActions: {
        display: 'flex'
    },
    unpublish: {
        color: 'blue'
    },
    iconButton: {
        width: 48,
        height: 48
    }
});


const StatsTable = ({
    classes,
    onColumnsChange,
    columns,
    onExport,
    ...otherProps
}) => {
    const [showExport, setShowExport] = useState(false);
    const [showColumnsDrawer, setShowColumnsDrawer] = useState(false);

    const handleShowColumnsDrawer = () => setShowColumnsDrawer(true);

    const handleCloseColumnsDrawer = () => setShowColumnsDrawer(false);

    const handleShowExportDialog = () => setShowExport(true);

    const handleCloseExportDialog = () => setShowExport(false);

    const handleExport = (exportColumns) => {
        setShowExport(false);
        onExport(exportColumns);
    };

    return (
        <Paper elevation={1} className={classes.paper}>
            <StatsTableProvider {...otherProps} columns={columns}>
                <Toolbar className={classes.root}>
                    <div className={classes.title}>
                        <DisplayFilter />
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                        <IconButton
                            aria-label="Filter"
                            onClick={handleShowExportDialog}
                            disabled={!otherProps.enableExport}
                        >
                            <Tooltip title="Export" placement="top">
                                <ExportIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            aria-label="Filter"
                            onClick={handleShowColumnsDrawer}
                            disabled={otherProps.isLoading}
                        >
                            <Tooltip title="Show/Hide Columns" placement="top">
                                <VisibilityIcon />
                            </Tooltip>
                        </IconButton>
                    </div>
                </Toolbar>
                <div className={classes.tableWrap}>
                    <Table size="small">
                        <StatsTableHead />
                        <StatsTableTotals />
                        <StatsTableBody />
                    </Table>
                </div>
                <StatsTableFooter />
            </StatsTableProvider>
            <StatsExportDialog
                open={showExport}
                onDownload={handleExport}
                onClose={handleCloseExportDialog}
                columns={columns}
            />
            <ColumnManipulator
                open={showColumnsDrawer}
                columns={columns}
                onClose={handleCloseColumnsDrawer}
                onChange={onColumnsChange}
            />
            {otherProps.isExporting && <CSVDownload data={otherProps.exportData} target="_blank" />}
        </Paper>
    );
};

export default withStyles(styles)(StatsTable);
