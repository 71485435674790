import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { adminTheme } from '../theme';
import { Route, Switch } from 'react-router-dom';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import dashboardRoutes from '../routes/dashboard';
import { SnackbarProvider } from 'notistack';

const styles = (theme) => ({
    root: {
        flexGrow: 1
    },
    appFrame: {
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: '40px 35px 60px',
        overflowY: 'auto'
    }
});

const switchRoutes = (
    <Fragment>
        {dashboardRoutes.map((prop, key) => {
            const { path, component, switchPath = null } = prop;
            if (switchPath) {
                return (
                    <Switch>
                        <Route
                            exact
                            path={path}
                            component={component}
                            key={key}
                        />
                        <Route
                            path={switchPath}
                            component={component}
                            key={key}
                        />
                    </Switch>
                );
            }
            return <Route exact path={path} component={component} key={key} />;
        })}
    </Fragment>
);

class Dashboard extends Component {
    state = {
        sidebarExpanded: false,
        view: 'Default'
    };

    handleSidebarExpand = () => {
        this.setState((prevState) => ({
            sidebarExpanded: !prevState.sidebarExpanded
        }));
    };

    handleChangeView = (event) => {
        this.setState({
            view: event.target.value
        });
    };

    render() {
        const { classes, ...rest } = this.props;
        const { sidebarExpanded, view } = this.state;
        return (
            <Fragment>
                <MuiThemeProvider theme={adminTheme}>
                    <CssBaseline />
                    <div className={classes.root}>
                        <div className={classes.appFrame}>
                            <SnackbarProvider>
                                <Header
                                    handleSidebarExpand={this.handleSidebarExpand}
                                    handleChangeView={this.handleChangeView}
                                    sidebarExpanded={sidebarExpanded}
                                    routes={dashboardRoutes}
                                    view={view}
                                    {...rest}
                                />
                            </SnackbarProvider>
                            <Sidebar
                                routes={dashboardRoutes}
                                sidebarExpanded={sidebarExpanded}
                                {...rest}
                            />
                            <main className={classes.content}>
                                <div className={classes.toolbar} />
                                {switchRoutes}
                            </main>
                        </div>
                    </div>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Dashboard);
