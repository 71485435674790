import React, { memo, useEffect, useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import DropZone from './DropZone';
import ImageData from './ImageData';
import Cropper from './Cropper';
import ImageViewer from './ImageViewer';
import { useObjectState } from '../../utilities/customHooks';

const MB = 1048576;

const useStyles = ({ withImageData }) =>
    makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overFlow: 'hidden',
        },
        paper: {
            padding: '2rem',
            height: '80vh',
            width: '80vw',
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
        },
        wrapper: {
            display: 'flex',
            height: !withImageData ? 'calc(100% - 52px)' : '100%',
            width: '100%',
            justifyContent: 'flex-end',
        },
        actions: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            padding: `${theme.spacing(2)}px 0 0`,

            '& button:last-child': {
                marginLeft: theme.spacing(2),
            },
        },
    }));

const getImageType = (file) => {
    if (file.startsWith('data:image/')) {
        let [mimeType] = file.split(';');
        let type = mimeType.split(':').pop().split('/').pop();
        return type;
    }
    return file.split('.').pop();
};

const ImageUploader = ({
    open,
    minWidth,
    minHeight,
    withAlt: includeAlt,
    withCaption: includeCaption,
    imageCaptionRequired: requireCaption,
    imageAltMin,
    imageAltMax,
    imageCaptionMin,
    imageCaptionMax,
    requireCrop = false,
    allowPng,
    options: {
        withAlt = includeAlt,
        withCaption = includeCaption,
        imageCaptionRequired = requireCaption,
        withOrientation,
        orientationOptions: { withAlignment, ...orientationOptions } = {},
        ...otherOptions
    } = {},
    maxSize = 25, //MB
    onClose = () => {},
    onSubmit = () => {},
    ...rest
}) => {
    const cropper = useRef();
    const withImageData =
        withAlt || withCaption || withOrientation || withAlignment;
    const classes = useStyles({ withImageData })();
    const [state, setState] = useObjectState({
        crop: false,
        image: null,
        cropped: null,
        type: '',
        alt: '',
        caption: '',
        orientation: 'landscape',
        alignment: 'left',
        mobileWrap: 'full',
        allowPng: false,
        exceed: false,
    });

    const handleImageChange = (image, { type, size }) => {
        if (size / MB < maxSize) {
            setState({ image, exceed: false, type: type.split('/').pop() });
        } else {
            setState({ exceed: true });
        }
    };

    const handleClearImage = () => {
        setState({
            orientation: 'landscape',
            alignment: 'left',
            mobileWrap: 'full',
            image: null,
            type: '',
            cropped: '',
        });
    };

    const handleCropImage = () => {
        // setCrop(true);
        setState({ crop: true });
    };

    const handleCancelCrop = () => {
        // setCrop(false);
        setState({ crop: false });
    };

    const handleDoneCropping = () => {
        const croppedImage = cropper.current
            .getCroppedCanvas()
            .toDataURL(
                state.allowPng && ['png', 'webp'].includes(state.type)
                    ? 'image/png'
                    : 'image/jpeg',
                0.9
            );
        setState({
            cropped: croppedImage,
            crop: false,
        });
    };

    const handleSubmit = () => {
        const { alt, caption, orientation, alignment, mobileWrap } = state;
        const orientationConfig = {
            orientation,
            alignment: withAlignment ? alignment : null,
            mobileWrap,
        };
        onSubmit(
            state.cropped || state.image,
            state.image,
            alt,
            caption,
            orientationConfig
        );
        onClose();
    };

    useEffect(() => {
        if (open) {
            const {
                image,
                cropped,
                imageAlt: alt,
                imageCaption: caption,
                orientation,
                alignment,
                mobileWrap,
            } = rest;
            setState({
                alt: alt || '',
                caption: caption || '',
                orientation: orientation || 'orientation',
                alignment: alignment || 'left',
                mobileWrap: mobileWrap,
                image: image,
                cropped: cropped,
            });
        } else {
            setState({
                alt: '',
                caption: '',
                orientation: 'landscape',
                alignment: 'left',
                image: null,
                type: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (state.image && requireCrop) {
            setState({ crop: !state.cropped });
        }
        if (!!state.image) {
            const loader = new Image();
            loader.src = state.image;
            loader.onerror = () => setState({ image: null });
            setState({ type: getImageType(state.image) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.image, requireCrop, state.cropped]);

    useEffect(() => {
        if (state.mobileWrap === 'wrap' && state.type !== 'gif') {
            setState({
                crop: true,
                allowPng: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.mobileWrap]);

    useEffect(() => {
        setState({
            allowPng: allowPng,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowPng]);

    const isNewImage =
        rest.image !== state.image ||
        (!!state.image && rest.cropped !== state.cropped);
    const isValid =
        !state.crop &&
        (isNewImage ||
            rest.imageAlt !== state.alt ||
            rest.imageCaption !== state.caption ||
            rest.orientation !== state.orientation ||
            rest.alignment !== state.alignment ||
            rest.mobileWrap !== state.mobileWrap) &&
        ((requireCrop && !!state.cropped) || (!requireCrop && !!state.image)) &&
        (withAlt ? !!state.alt : true) &&
        (imageCaptionRequired ? !!state.caption : true);

    const selectedDimension = withOrientation
        ? orientationOptions[`${state.orientation}Dimension`]
        : { height: minHeight, width: minWidth };
    const { height, width } =
        state.mobileWrap === 'wrap'
            ? { height: 150, width: 150 }
            : selectedDimension;

    return (
        <Modal open={open} onClose={onClose} className={classes.modal}>
            <Slide in={open} out={!open}>
                <Paper
                    elevation={0}
                    className={classes.paper}
                    data-cy="image-uploader-modal"
                >
                    <div className={classes.wrapper}>
                        <Cropper
                            show={state.crop && state.type !== 'gif'}
                            cropperRef={cropper}
                            image={state.image}
                            minWidth={minWidth}
                            minHeight={minHeight}
                            height={height}
                            width={width}
                            onCancel={handleCancelCrop}
                            onDone={handleDoneCropping}
                        />
                        <DropZone
                            onImageChange={handleImageChange}
                            hasImage={!!state.image}
                            maxSize={maxSize}
                            minWidth={minWidth}
                            minHeight={minHeight}
                            error={
                                state.exceed
                                    ? `Image size exceeded to max size ${maxSize}MB. Please select another image.`
                                    : ''
                            }
                        />
                        <ImageViewer
                            show={!!state.image && !state.crop}
                            type={state.type}
                            image={state.cropped || state.image}
                            onClear={handleClearImage}
                            onCrop={handleCropImage}
                        />
                        {!state.crop && (
                            <ImageData
                                onCancel={onClose}
                                onDone={handleSubmit}
                                onChange={(data) => setState(data)}
                                options={{
                                    withAlt,
                                    withCaption,
                                    withOrientation,
                                    withAlignment,
                                    imageCaptionRequired,
                                    imageAltMin,
                                    imageAltMax,
                                    imageCaptionMin,
                                    imageCaptionMax,
                                    ...otherOptions,
                                }}
                                isValid={isValid}
                                alt={state.alt}
                                caption={state.caption}
                                orientation={state.orientation}
                                alignment={state.alignment}
                                mobileWrap={state.mobileWrap}
                            />
                        )}
                    </div>
                    {!withImageData && (
                        <div className={classes.actions}>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                data-cy="btn_ok_crop"
                                disableRipple
                                disabled={!isValid}
                                disableElevation
                            >
                                Done
                            </Button>
                        </div>
                    )}
                </Paper>
            </Slide>
        </Modal>
    );
};

export default memo(ImageUploader);
