import {
    Paper,
    Drawer,
    makeStyles,
    Typography,
    Button,
    Link
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCustomMetaMethods, useCustomMetaState } from './CustomMeta/CustomMetaProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36
            }
        }
    },
    paper: {
        padding: theme.spacing(2)
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',
        '& a': {
            width: '100%'
        },
        '& button:first-child': {
            marginRight: theme.spacing(2)
        }
    }
}));

export default function MetaViewer({
    open,
    meta,
    onClose = () => { },
    onDelete = () => { },
    isCustom = false,
    openDialog
}) {
    const classes = useStyles();

    const { deleteMeta } = useCustomMetaMethods();
    const { setCustomMetaState } = useCustomMetaState();

    const {
        id,
        directory,
        pageTypeLabel,
        title,
        h1,
        h2,
        description,
        region,
        country,
        mainRegion,
        city,
        type,
        timing
    } = meta || {};
    const [customPage, setCustomPage] = useState();

    useEffect(() => {
        window.location.pathname === "/site-tools/custom-meta" ? setCustomPage(true) : setCustomPage(false);
    }, [])

    const handleOpenDialog = (title, content, callback) => {
        const dialog = {
            actions: 'OkCancel',
            title,
            content,
            show: true,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        };
        setCustomMetaState({ dialog: dialog });
    };
    const handleCloseDialog = () => {
        const dialog = {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => { },
            onClose: () => { }
        };
        setCustomMetaState({ dialog: dialog });
    };


    const handleDeleteMeta = (bol, meta) => {
        handleOpenDialog(
            'Delete SEO META',
            'Are you sure you want to delete this seo-meta?',
            () => {
                handleCloseDialog();
                deleteMeta(bol, meta);

            }
        );

        setCustomMetaState({ selectedMeta: null });
    }

    return (
        <Drawer open={open} onClose={onClose} anchor="right">
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h6">
                        {isCustom ? 'Custom' : 'Default'} Meta
                    </Typography>
                    <br />
                    <Typography variant="overline">Directory</Typography>
                    <Typography>{directory}</Typography>
                    <br />
                    {isCustom && (
                        <>
                            {region && (
                                <>
                                    <Typography variant="overline">
                                        Region
                                    </Typography>
                                    <Typography>{region}</Typography>
                                    <br />
                                </>
                            )}
                            {country && (
                                <>
                                    <Typography variant="overline">
                                        Country
                                    </Typography>
                                    <Typography>{country}</Typography>
                                    <br />
                                </>
                            )}
                            {mainRegion && (
                                <>
                                    <Typography variant="overline">
                                        Main Region
                                    </Typography>
                                    <Typography>{mainRegion}</Typography>
                                    <br />
                                </>
                            )}
                            {city && (
                                <>
                                    <Typography variant="overline">
                                        City
                                    </Typography>
                                    <Typography>{city}</Typography>
                                    <br />
                                </>
                            )}
                            {type && (
                                <>
                                    <Typography variant="overline">
                                        Type
                                    </Typography>
                                    <Typography>{type}</Typography>
                                    <br />
                                </>
                            )}
                            {timing && (
                                <>
                                    <Typography variant="overline">
                                        Timing
                                    </Typography>
                                    <Typography>{timing}</Typography>
                                    <br />
                                </>
                            )}
                        </>
                    )}
                    <Typography variant="overline">Page Type</Typography>
                    <Typography>{pageTypeLabel}</Typography>
                    <br />
                    <Typography variant="overline">Page Title</Typography>
                    <Typography>{title}</Typography>
                    <br />
                    <Typography variant="overline">Description</Typography>
                    <Typography>{description}</Typography>
                    <br />
                    <Typography variant="overline">H1</Typography>
                    <Typography>{h1}</Typography>
                    <br />
                    <Typography variant="overline">H2</Typography>
                    <Typography>{h2}</Typography>
                    <br />
                </Paper>
                <div className={classes.cta}>
                    {customPage && <Button
                        data-cy="default-meta-delete-meta"
                        // variant="contained"
                        fullWidth
                        color="secondary"
                        disableFocusRipple
                        disableElevation
                        startIcon={<DeleteIcon fontSize="small" />}
                        onClick={() => handleDeleteMeta(false, meta)}
                    >
                        Delete
                    </Button>}
                    <Link href={`/site-tools/seo-tool/${id}`}>
                        <Button
                            data-cy="default-meta-edit-meta"
                            variant="contained"
                            fullWidth
                            color="primary"
                            disableFocusRipple
                            disableElevation
                            startIcon={<EditIcon fontSize="small" />}
                        >
                            Edit
                        </Button>
                    </Link>
                </div>
            </div>
        </Drawer >
    );
}
