export const DIRECTORIES = [
    {
        id: 1,
        name: 'US University',
        alias: 'us-university',
        abbrv: 'USU',
        page_number_tag: '',
        has_online: 0
    },
    {
        id: 2,
        name: 'Volunteer Abroad',
        alias: 'volunteer-abroad',
        abbrv: 'VA',
        page_number_tag: 'volunteer-abroad',
        has_online: 1
    },
    {
        id: 3,
        name: 'Teach Abroad',
        alias: 'teach-abroad',
        abbrv: 'TA',
        page_number_tag: 'teach-abroad',
        has_online: 1
    },
    {
        id: 4,
        name: 'Intern Abroad',
        alias: 'intern-abroad',
        abbrv: 'IA',
        page_number_tag: 'internships-abroad',
        has_online: 1
    },
    {
        id: 5,
        name: 'Jobs Abroad',
        alias: 'jobs-abroad',
        abbrv: 'JA',
        page_number_tag: 'jobs-abroad',
        has_online: 1
    },
    {
        id: 6,
        name: 'Study Abroad',
        alias: 'study-abroad',
        abbrv: 'SA',
        page_number_tag: 'study-abroad',
        has_online: 1
    },
    {
        id: 7,
        name: 'Language School',
        alias: 'language-study-abroad',
        abbrv: 'LS',
        page_number_tag: 'language-programs-abroad',
        has_online: 1
    },
    {
        id: 8,
        name: 'Adventure Travel',
        alias: 'adventure-travel-abroad',
        abbrv: 'AT',
        page_number_tag: 'adventure-travel-abroad',
        has_online: 0
    },
    {
        id: 9,
        name: 'High School',
        alias: 'highschool-study-abroad',
        abbrv: 'HS',
        page_number_tag: 'highschool-programs-abroad',
        has_online: 0
    },
    {
        id: 10,
        name: 'GoAbroad',
        alias: 'goabroad',
        abbrv: 'GA',
        page_number_tag: '',
        has_online: 0
    },
    {
        id: 11,
        name: 'TEFL Courses',
        alias: 'tefl-courses',
        abbrv: 'TEFL',
        page_number_tag: 'tefl-abroad',
        has_online: 1
    },
    {
        id: 12,
        name: 'Degree Abroad',
        alias: 'degree-abroad',
        abbrv: 'DA',
        page_number_tag: 'degree-abroad',
        has_online: 1
    },
    {
        id: 15,
        name: 'Scholarships',
        alias: 'scholarships-abroad',
        abbrv: 'SCH',
        page_number_tag: '',
        has_online: 0
    },
    {
        id: 17,
        name: 'Accommodations',
        alias: 'accommodations',
        abbrv: 'AA',
        page_number_tag: '',
        has_online: 0
    },
    {
        id: 18,
        name: 'Embassies',
        alias: 'embassies',
        abbrv: 'EMB',
        page_number_tag: '',
        has_online: 0
    },
    {
        id: 19,
        name: 'Gap Year',
        alias: 'gap-year',
        abbrv: 'GY',
        page_number_tag: 'gap-year',
        has_online: 0
    }
];

/**
 * Check filterBy value if valid. Ex: {key:'id',val:[2,6]}
 * 'key' and 'val' should be present in filterBy.
 * 'key' should be a valid property of filterBasedOn.
 * 'val' should be an array.
 *
 * @params {Object} filterBy       Filter object.
 * @params {Object} filterBasedOn  Object whose keys will be validated with filterBy.key.
 *
 * @return {Boolean}
 */
export const checkFilterBy = (filterBy, filterBasedOn) => {
    return (filterBy &&
        filterBasedOn &&
        'key' in filterBy &&
        'val' in filterBy &&
        Object.keys(filterBasedOn).includes(filterBy.key) &&
        Array.isArray(filterBy.val)) ? true : false;
}

/**
 * Check returnData if contains valid keys from returnDataBasedOn.
 *
 * @params {Array} returnData          Array of properties to be returned.
 * @params {Object} returnDataBasedOn  returnData value should contain keys of returnDataBasedOn.
 *
 * @return {Boolean}
 */
export const checkReturnData = (returnData, returnDataBasedOn) => {
    return (returnData &&
        returnDataBasedOn &&
        Object.keys(returnDataBasedOn).some(key => returnData.includes(key))) ? true : false;
}

/**
 * Filter filterReturnedData object properties.
 *
 * @params {Object} filterBasedOn  Object whose keys will be filtered.
 * @params {Array} returnData      Array of properties filterBasedOn should be returned.
 *
 * @return {Object}
 */
export const filterReturnedData = (filterBasedOn, returnData) => {
    return !(filterBasedOn && returnData) ?
        {} :
        Object.keys(filterBasedOn)
            .filter(key => returnData.includes(key))
            .reduce((obj, key) => {
                obj[key] = filterBasedOn[key];

                return obj;
            }, {});
}

/**
 * Get Directories or a specific directory.
 *
 * @param {Object} filterBy      Filter directory by filterBy Object. Ex: {key:'id',val:[2,6]}
 *                               Invalid object, key or val will return ALL directories.
 *        {String} filterBy.key  Directory property to be filtered.
 *        {Array}  filterBy.val  Value of the directory property to be filtered.
 * @param {Array}  returnData    Array of directory properties to be returned. Ex: ['id','alias']
 *                               Empty array will return all properties of Directory.
 *
 * @return {Array} Array of directories
 */
export default function getDirectoryBy(
    filterBy = {},
    returnData = []
) {
    let directories = [];
    let checkedFilterBy = checkFilterBy(filterBy, DIRECTORIES[0]);
    let checkedReturnData = checkReturnData(returnData, DIRECTORIES[0]);

    DIRECTORIES.forEach(function (element) {
        var temp = (!checkedFilterBy || (checkedFilterBy && filterBy.val.includes(element[filterBy.key]))) ? element : null;

        if (checkedReturnData && temp != null) {
            temp = filterReturnedData(temp, returnData);
        }

        temp && directories.push(temp);
    });

    return directories;
}