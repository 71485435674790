import Client from '../views/Client/Client';
import ClientIcon from '@material-ui/icons/People';
import ClientStats from '../views/ClientStats/AccountStats/Main';
import ClientStatsIcon from '@material-ui/icons/TrendingUp';
import Dashboard from '../views/Dashboard/Dashboard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import OnlineAdvisor from '../views/OnlineAdvisor/OnlineAdvisor';
import OnlineAdvisorIcon from '@material-ui/icons/QuestionAnswer';
import RankingSystemIcon from '@material-ui/icons/Sort';
import ExploreIcon from '@material-ui/icons/Explore';
import RankingSystem from '../views/RankingSystem';
import ArticleTabs from '../views/Articles/ArticleTabs';
import Guides from '../views/Guides/Main';
import Interviews from '../views/Interviews/Main';
import ArticlesIcon from '@material-ui/icons/LibraryBooks';
import ScholarshipIcon from '@material-ui/icons/LocalLibrary';
import Scholarships from '../views/Scholarships/Main';
import Deals from '../views/Deals/Main';
import InterviewsIcon from '@material-ui/icons/SpeakerNotes';
import UrlVerifierTabs from '../views/UrlVerifiers/VerifierTabs';
import VerifierIcon from '@material-ui/icons/Link';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BuildIcon from '@material-ui/icons/Build';
import SiteToolsTabs from '../views/SiteTools/SiteToolsTabs'

var dashboardRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        shortName: 'Dashboard',
        icon: DashboardIcon,
        component: Dashboard
    },
    {
        path: '/client-accounts',
        switchPath: '/client-accounts/:page',
        name: 'Clients',
        shortName: 'Clients',
        icon: ClientIcon,
        component: Client
    },
    {
        path: '/stats',
        name: 'Client Stats',
        shortName: 'Stats',
        icon: ClientStatsIcon,
        component: ClientStats
    },
    {
        path: '/ranking-system',
        switchPath: '/ranking-system/:tab',
        name: 'Ranking System',
        shortName: 'Rank System',
        icon: RankingSystemIcon,
        component: RankingSystem
    },
    {
        path: '/onlineadvisor',
        name: 'Online Advisor',
        shortName: 'OAI',
        icon: OnlineAdvisorIcon,
        component: OnlineAdvisor
    },
    {
        path: '/articles',
        switchPath: ['/articles', '/authors', '/topics'],
        name: 'Articles',
        shortName: 'Articles',
        icon: ArticlesIcon,
        component: ArticleTabs
    },
    {
        path: '/guides',
        name: 'Guides',
        shortName: 'Guides',
        icon: ExploreIcon,
        component: Guides
    },
    {
        path: '/interviews',
        name: 'Interviews',
        shortName: 'Interviews',
        icon: InterviewsIcon,
        component: Interviews
    },
    {
        path: '/scholarships',
        switchPath: ['/scholarships', '/scholarships-ranking'],
        name: 'Scholarships',
        shortName: 'Scholarships',
        icon: ScholarshipIcon,
        component: Scholarships
    },
    {
        path: '/mygoabroad',
        switchPath: ['/mygoabroad', '/mygoabroad/applications', '/mygoabroad/users'],
        name: 'MyGoAbroad',
        shortName: 'MyGoAbroad',
        icon: CardGiftcardIcon,
        component: Deals
    },
    {
        path: '/url-verifiers',
        switchPath: '/url-verifiers/:page',
        name: 'URL Verifiers',
        shortName: 'URL Verifiers',
        icon: VerifierIcon,
        component: UrlVerifierTabs
    },
    {
        path: '/site-tools',
        switchPath: ['/site-tools', '/site-tools/default-meta', '/site-tools/custom-meta'],
        name: 'Site Tools',
        shortName: 'Site Tools',
        icon: BuildIcon,
        component: SiteToolsTabs
    },
    {
        redirect: true,
        path: '/',
        pathTo: '/dashboard',
        name: 'Dashboard'
    }
];

export default dashboardRoutes;
