import React from 'react';
import DataFilters from './DataFilters';
import FilterDrawer from './FilterDrawer';
import ReorderAction from './ReorderAction';
import ProgramRankingProvider from './ProgramRankingProvider';
import ProgramRankingTable from './ProgramRankingTable';

const ProgramRanking = (props) => {
    return (
        <ProgramRankingProvider>
            <DataFilters />
            <ReorderAction />
            <FilterDrawer />
            <ProgramRankingTable />
        </ProgramRankingProvider>
    );
};

export default ProgramRanking;
