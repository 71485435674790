import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { postRequest } from '../../../helpers/apiRequestUtility';
import { withGlobalContext } from '../../Client/GlobalProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { storeUserCookie } from '../../../helpers/cookieUtility';
import { GA_LOGO } from '../../../config/constants';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import passwordValidator from 'password-validator';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',
        overflow: 'hidden',
        zIndex: 1000,

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    paper: {
        width: '100%',
        maxWidth: 430,
        padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        position: 'relative'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        minWidth: 320,
        background: '#f8f8f8',
        '&:first-child': {
            marginBottom: theme.spacing.unit * 2
        },

        // Remove chrome autofill background
        '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
        {
            transition: 'background-color 5000s ease-in-out 0s'
        },
        '&.Mui-focused': {
            outlineColor: '#03a9f4'
        }
    },
    button: {
        minWidth: 320,
        height: 50,
        background: '#03a9f4',
        color: 'white',

        '&:hover': {
            background: '#0092d3'
        }
    },
    logo: {
        marginBottom: theme.spacing.unit * 4
    },
    h1: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333'
    },
    forgot: {
        fontSize: '0.75em',
        marginTop: theme.spacing.unit * 2,
        color: '#555',

        '& a': {
            color: '#03a9f4',
            fontWeight: 'bold',
            textDecoration: 'none'
        }
    },
    email: {
        borderRight: 'none',
        borderRadius: '3px 0 0 3px'
    },
    error: {
        background: '#ff5d5d',
        color: 'white',
        padding: '8px 16px',
        borderRadius: 3,
        width: '320px',
        textAlign: 'center',
        marginTop: '10px'
    },
    showPassword: {
        marginRight: '-7px'
    },
    loginButtonWrapper: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& div[role=progressbar]': {
            position: 'absolute'
        }
    },
    circularProgress: {
        color: '#03a9f4'
    },
    passwordStrength: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

        '& label': {
            color: '#555'
        }
    },
    progressWrap: {
        marginBottom: theme.spacing.unit,
        marginTop: theme.spacing.unit * 2,
        background: '#ddd',
        width: '100%',
        height: 5,
        borderRadius: 3
    },
    progress: {
        background: '#ff5d5d',
        height: 5,
        width: '10%',
        transition: 'width 0.5s ease-in-out',
        borderRadius: 3
    },
    fair: {
        width: '33%',
        background: '#ffa914'
    },
    good: {
        width: '66%',
        background: '#57b0e2'
    },
    strong: {
        width: '100%',
        background: '#32ad22'
    }
});

const ResetPasswordForm = ({
    classes,
    email,
    globalContext,
    googleReCaptchaProps,
    history,
    token
}) => {
    const [state, setState] = useState({
        error: '',
        password: '',
        showPassword: false,
        verifying: false,
        chooseClient: false,
        user: {},
        providers: [],
        recaptchaToken: '',
        failedRules: [],
        strength: 0,
        isValidPassword: true
    });

    const handleSetState = (newState, callback = () => { }) => {
        setState((prev) => {
            return { ...prev, ...newState };
        });
        callback();
    };

    const [schema, setSchema] = useState(new passwordValidator());

    useEffect(() => {
        schema
            .is()
            .min(8)
            .has()
            .uppercase()
            .has()
            .lowercase()
            .has()
            .symbols()
            .has()
            .digits()
            .has()
            .not()
            .spaces();
        setSchema(schema);
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    const handleError = async ({ error }) => {
        const { message } = error || {};
        handleSetState({
            verifying: false,
            password: '',
            error: message || 'Setting of password failed. Please try again.',
            recaptchaToken: await getRecaptchaResponse()
        });
    };

    const handleClickShowPassword = () => {
        handleSetState({ showPassword: !state.showPassword });
    };

    const storeAndRedirect = (user) => {
        const { access_level = 1 } = user || {};
        if (!!error) {
            handleError(error);
        } else {
            storeUserCookie(user);
            globalContext.handleStateChange({ user });
            if (access_level === 1) window.location = '/client-admin/dashboard';
            else window.location = '/dashboard';
        }
    };

    const handleSetPassword = (e) => {
        e.preventDefault();
        const { password } = state;
        handleSetState({ verifying: true, error: '' }, async () => {
            let form = new FormData();
            form.append('password', password);
            form.append('token', token);
            form.append('g-recaptcha-response', await getRecaptchaResponse());
            postRequest('/reset-password', form, storeAndRedirect, handleError);
        });
    };

    const getRecaptchaResponse = async () => {
        return await googleReCaptchaProps.executeRecaptcha('login');
    };

    const validatePassword = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let passwordFailedRules = schema.validate(value, { list: true });
        let isValidPassword = passwordFailedRules.length === 0;
        let strength = isValidPassword ? zxcvbn(value).score : 0;

        handleSetState({
            password: value,
            failedRules: passwordFailedRules,
            isValidPassword,
            strength
        });
    };

    const convertFailedRulesToString = (rules) => {
        let message = rules.map((rule, index) => {
            let converted = index > 0 ? ',' : '';

            if (index === rules.length - 1 && index !== 0) converted += ' and';

            switch (rule) {
                case 'min':
                    converted += ' at least 8 characters';
                    break;
                case 'uppercase':
                    converted += ' an uppercase';
                    break;
                case 'lowercase':
                    converted += ' a lowercase';
                    break;
                case 'symbols':
                    converted += ' a special character';
                    break;
                case 'digits':
                    converted += ' a digit';
                    break;
                case 'spaces':
                    converted += ' no spaces';
                    break;
                default:
            }

            return converted;
        });

        return <span>Password must contain {message}</span>;
    };

    const createPasswordStrengthLabel = (score) => {
        switch (score) {
            case 0:
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    };

    const {
        password,
        verifying,
        showPassword,
        error,
        strength,
        isValidPassword = true,
        failedRules = []
    } = state;

    return (
        <Paper className={classes.paper}>
            <img className={classes.logo} src={GA_LOGO} alt="" />
            <h1 className={classes.h1}>
                Enter a New Password for Your GoAbroad.com Admin Account
            </h1>

            <form className={classes.form} onSubmit={handleSetPassword}>
                <FormControl className={classes.input} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Email Address
                    </InputLabel>
                    <OutlinedInput
                        data-cy="email-field"
                        id="outlined-adornment-email"
                        type="text"
                        color="primary"
                        labelWidth={105}
                        value={email}
                        defaultValue={email}
                        disabled
                        notched
                    />
                </FormControl>

                <FormControl className={classes.input} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                        Password
                    </InputLabel>
                    <OutlinedInput
                        data-cy="password-field"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    className={classes.showPassword}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    disabled={verifying}
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                        value={password}
                        onChange={validatePassword}
                        required
                        disabled={verifying}
                        error={!!error}
                        autoFocus
                    />
                </FormControl>

                <div className={classes.passwordStrength}>
                    <div className={classes.progressWrap}>
                        <div
                            className={classNames(
                                `${classes.progress}`,
                                `${classes[
                                createPasswordStrengthLabel(
                                    strength
                                ).toLowerCase()
                                ]
                                }`
                            )}
                        />
                    </div>

                    <label data-cy="password-strength">
                        <strong>Password Strength: </strong>
                        {createPasswordStrengthLabel(strength)}
                    </label>

                    {!!password && !isValidPassword && (
                        <span className={classes.error} data-cy="error-message">
                            {convertFailedRulesToString(failedRules)}
                        </span>
                    )}
                </div>

                <div className={classes.loginButtonWrapper}>
                    {verifying && (
                        <CircularProgress
                            className={classes.circularProgress}
                            size={24}
                        />
                    )}
                    {!verifying && (
                        <Button
                            data-cy="submit-cta"
                            className={classes.button}
                            variant="contained"
                            disabled={
                                !password ||
                                !isValidPassword ||
                                [0, 1].includes(strength)
                            }
                            type="submit"
                        >
                            {!verifying && 'Submit'}
                        </Button>
                    )}
                </div>
            </form>
        </Paper>
    );
};

export default withGoogleReCaptcha(
    withGlobalContext(withStyles(styles)(withRouter(ResetPasswordForm)))
);
