import Datepicker from 'react-datepicker';
import React, { Component } from 'react';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import {
    Button,
    Drawer,
    Paper,
    Typography,
    withStyles
} from '@material-ui/core';

const TABWIDTH = 420;

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',

        '& > div:not(:last-of-type)': {
            marginBottom: theme.spacing(4)
        }
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1),
        width: '310px'
    },
    search: {
        marginBottom: theme.spacing(2)
    }
});

const today = new Date();

class FiltersDrawer extends Component {
    state = {
        filterBuffer: {
            directory_filter: { label: 'GoAbroad Homepage', value: 10 },
            date_filter: new Date(today.getFullYear(), today.getMonth(), 1)
        },
        directories: []
    };

    componentDidMount() {
        sendRequest('/directories', (res) => {
            const directories = [{ name: 'GoAbroad Homepage', value: 10 }];
            res.directories.forEach((directory) => {
                directories.push({ name: directory.name, value: directory.id });
            });
            this.setState({ directories });
        });
    }

    handleClearAllFilters = () => {
        this.setState({ filterBuffer: {} }, () => {
            const { handleApplyFilters } = this.props;
            handleApplyFilters &&
                handleApplyFilters(this.state.filterBuffer, true);
        });
    };

    handleAddFilter = (filter) => (value) => {
        const { filterBuffer } = this.state;
        filterBuffer[filter] = value;

        this.setState({ filterBuffer });
    };

    handleClear = () => this.setState({ filterBuffer: {} });

    render() {
        const {
            classes,
            filtersDrawerOpen,
            handleToggleFiltersDrawer,
            handleApplyFilters
        } = this.props;
        const { filterBuffer, directories } = this.state;

        return (
            <Drawer
                open={filtersDrawerOpen}
                anchor="right"
                onClose={() => handleToggleFiltersDrawer(false)}
            >
                <div className={classes.root}>
                    <Typography className={classes.drawerTitle}>
                        Data Filters
                    </Typography>
                    <Paper className={classes.paper}>
                        <SearchBasedDropdown
                            valueKey="value"
                            labelKey="name"
                            placeholder="Directory"
                            arrayOptions={directories}
                            handleChange={this.handleAddFilter(
                                'directory_filter'
                            )}
                            value={filterBuffer.directory_filter}
                            isClearable
                        />
                        <Datepicker
                            className={classes.datepicker}
                            selected={filterBuffer.date_filter}
                            onChange={this.handleAddFilter('date_filter')}
                            dateFormat="MM/yyyy"
                            placeholderText="Select date"
                            showMonthYearPicker
                        />
                    </Paper>
                    <Button
                        className={classes.search}
                        color="primary"
                        onClick={() => handleApplyFilters(filterBuffer)}
                        variant="contained"
                        disabled={
                            !(
                                filterBuffer.directory_filter &&
                                filterBuffer.date_filter
                            )
                        }
                    >
                        SEARCH
                    </Button>
                    <Button
                        className={classes.clear}
                        color="secondary"
                        onClick={this.handleClear}
                        variant="outlined"
                        disabled={
                            !(
                                filterBuffer.directory_filter ||
                                filterBuffer.date_filter
                            )
                        }
                    >
                        CLEAR FILTERS
                    </Button>
                </div>
            </Drawer>
        );
    }
}
export default withStyles(styles)(FiltersDrawer);
