import React, { useState, useEffect, memo } from 'react';
import Datepicker from 'react-datepicker';
import { differenceInCalendarDays, subDays } from 'date-fns';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Paper,
    Select,
    withStyles
} from '@material-ui/core';

const styles = (theme) => ({
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& svg': {
            fontSize: 18
        }
    },
    dateRange: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    date: {
        width: 230
    },
    formControl: {
        marginRight: '0px'
    },
    formControlLabel: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(2)
    },
    label: {
        fontWeight: 'bold',
        color: '#555',
        marginRight: theme.spacing(1),
        marginLeft: -theme.spacing(1) * 4
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1)
    },
    dates: {
        '& span > div': {
            margin: `0 ${theme.spacing(1)}px`
        },

        '& span > div:last-child': {
            marginRight: 0
        },

        '& input': {
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    dateWrap: {
        marginLeft: 'auto',
        padding: 0,
        marginBottom: theme.spacing(0.5)
    },
    paperDate: {
        position: 'absolute',
        top: 40,
        right: 0,
        zIndex: 3,
        width: 'auto',
        padding: theme.spacing(3)
    },
    custom: {
        marginTop: theme.spacing(2)
    },
    comparisonDateRange: {
        marginTop: '-16px',
        marginBottom: '-8px',
        marginLeft: '-32px'
    },
    compare: {
        marginLeft: '32px'
    },
    dataFilterWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    ctaWrapper: {
        padding: `${theme.spacing(2)}px 0 0`,
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

const getDateRange = (dateRange) => {
    const today = new Date();

    switch (dateRange) {
        case 1:
            return {
                start: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    0
                ),
                end: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    23,
                    59,
                    59
                )
            };
        case 2:
            return {
                start: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - 1,
                    0
                ),
                end: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - 1,
                    23,
                    59,
                    59
                )
            };

        case 3:
            return {
                start: new Date(today.getFullYear(), today.getMonth(), 1, 0),
                end: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    23,
                    59,
                    59
                )
            };

        case 4:
            return {
                start: new Date(
                    today.getFullYear(),
                    today.getMonth() - 1,
                    1,
                    0
                ),
                end: new Date(
                    today.getFullYear(),
                    today.getMonth() - 1,
                    31,
                    23,
                    59,
                    59
                )
            };

        case 5:
            return {
                start: new Date(today.setDate(today.getDate() - 7)),
                end: new Date()
            };

        case 6:
            return {
                start: new Date(today.setDate(today.getDate() - 30)),
                end: new Date()
            };

        case 7:
            return {
                start: new Date(today.getFullYear(), 0, 1, 0),
                end: new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    23,
                    59,
                    59
                )
            };

        default:
            return { start: today, end: today };
    }
};

const getComparisonDateRange = (dates, comparisonId) => {
    const { start = new Date(), end = new Date() } = dates;
    const newStart = new Date(start);
    const newEnd = new Date(end);

    switch (comparisonId) {
        case 1:
            return {
                start: new Date(
                    new Date(newStart).setFullYear(newStart.getFullYear() - 1)
                ),
                end: new Date(
                    new Date(newEnd).setFullYear(newEnd.getFullYear() - 1)
                )
            };

        case 2:
            const difference = differenceInCalendarDays(newEnd, newStart);
            return {
                start: subDays(subDays(newStart, 1), difference),
                end: subDays(newStart, 1)
            };

        default:
            return dates;
    }
};

const DateRangeFilter = ({
    classes,
    dates: datesProp,
    dateRangeID: dateRangeIDProp,
    compare: compareProp,
    comparisonRangeID: comparisonRangeIDProp,
    comparisonDates: comparisonDatesProp,
    onClose = () => {},
    onChange = () => {},
    open: openProp
}) => {
    const [dates, setDates] = useState({ start: new Date(), end: new Date() });
    const [dateRangeID, setDateRangeId] = useState(6);
    const [compare, setCompare] = useState(false);
    const [comparisonRangeID, setCompareRangeId] = useState(1);
    const [comparisonDates, setComparisonDates] = useState({
        start: new Date(),
        end: new Date()
    });

    const handleDateRangeChange = (e) => {
        let rangeID = parseInt(e.target.value) || 0;
        setDateRangeId(rangeID);
        setDates(getDateRange(rangeID));
    };

    const handleComparisonRangeChange = (e) =>
        setCompareRangeId(parseInt(e.target.value) || 0);

    const handleCompareDatesChange = (field) => (date) => {
        setComparisonDates({ ...comparisonDates, [field]: new Date(date) });
    };

    const handleDatesChange = (field) => (date) =>
        setDates({ ...dates, [field]: new Date(date) });

    const handleApply = () => {
        onChange({
            dates,
            dateRangeID,
            compare,
            comparisonRangeID,
            comparisonDates: compare ? comparisonDates : {}
        });
        onClose();
    };

    const handleClose = () => onClose();

    useEffect(() => {
        setCompare(compareProp);
        setDateRangeId(dateRangeIDProp);
        setDates(datesProp);
        setCompareRangeId(comparisonRangeIDProp);
    }, [datesProp, dateRangeIDProp, compareProp, comparisonRangeIDProp]);

    useEffect(() => {
        if (comparisonDatesProp.start && comparisonDatesProp.end)
            setComparisonDates(comparisonDatesProp);
    }, [comparisonDatesProp]);

    useEffect(() => {
        if (compare) {
            setComparisonDates(
                getComparisonDateRange(dates, comparisonRangeID)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparisonRangeID, compare]);

    if (!openProp) {
        return null;
    }

    return (
        <Paper className={classes.paperDate}>
            <div className={classes.dateRange}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Select
                                native
                                className={classes.date}
                                value={dateRangeID}
                                onChange={handleDateRangeChange}
                            >
                                <option value={0} />
                                <option value={1}>Today</option>
                                <option value={2}>Yesterday</option>
                                <option value={3}>This Month</option>
                                <option value={4}>Last Month</option>
                                <option value={5}>Last 7 Days</option>
                                <option value={6}>Last 30 Days</option>
                                <option value={7}>Year-to-Date</option>
                                <option value={8}>Custom</option>
                            </Select>
                        }
                        label="Date Range:"
                        labelPlacement="start"
                        classes={{
                            label: classes.label
                        }}
                        className={classes.formControl}
                    />

                    <span className={classes.custom}>
                        <Datepicker
                            className={classes.datepicker}
                            selected={dates.start}
                            disabled={dateRangeID !== 8}
                            onChange={handleDatesChange('start')}
                            maxDate={dates.end}
                            showMonthDropdown
                            showYearDropdown
                        />{' '}
                        -{' '}
                        <Datepicker
                            className={classes.datepicker}
                            selected={dates.end}
                            onChange={handleDatesChange('end')}
                            disabled={dateRangeID !== 8}
                            minDate={dates.start}
                            showMonthDropdown
                            showYearDropdown
                        />
                    </span>
                </FormControl>

                <FormControl className={classes.compare}>
                    <div className={classes.comparisonDateRange}>
                        <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                                <Checkbox
                                    value=""
                                    checked={compare}
                                    onChange={(e) =>
                                        setCompare(e.target.checked)
                                    }
                                />
                            }
                            label="Compare"
                        />
                        <Select
                            disabled={!compare}
                            native
                            className={classes.date}
                            value={comparisonRangeID}
                            onChange={handleComparisonRangeChange}
                        >
                            <option value={0} />
                            <option value={1}>Previous Year</option>
                            <option value={2}>Previous Period</option>
                            <option value={3}>Custom </option>
                        </Select>
                    </div>

                    <span className={classes.custom}>
                        <Datepicker
                            className={classes.datepicker}
                            selected={comparisonDates.start}
                            disabled={comparisonRangeID !== 3}
                            onChange={handleCompareDatesChange('start')}
                            maxDate={comparisonDates.end}
                            showMonthDropdown
                            showYearDropdown
                        />{' '}
                        -{' '}
                        <Datepicker
                            className={classes.datepicker}
                            selected={comparisonDates.end}
                            onChange={handleCompareDatesChange('end')}
                            disabled={comparisonRangeID !== 3}
                            minDate={comparisonDates.start}
                            showMonthDropdown
                            showYearDropdown
                        />
                    </span>
                </FormControl>
            </div>
            <div className={classes.ctaWrapper}>
                <Button color="default" onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleApply}>
                    Apply
                </Button>
            </div>
        </Paper>
    );
};

export default withStyles(styles)(memo(DateRangeFilter));
