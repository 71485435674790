import React from 'react';
import { withSearchResultsTableContext } from './SearchResultsTableProvider';

import { TableFooter, TablePagination, TableRow } from '@material-ui/core';

const SearchResultsTableFooter = props => {
    const { context } = props;
    const { handlePageChange } = context;
    const { page = 0, rowsPerPage = 10, data, count } = context.state;

    return (
        <TableFooter>
            <TableRow padding="none">
                <TablePagination
                    count={count || data.length}
                    page={page}
                    onChangePage={(e, page) => handlePageChange(page)}
                    rowsPerPage={rowsPerPage || 10}
                    backIconButtonProps={{'aria-label': 'Previous Page'}}
                    nextIconButtonProps={{'aria-label': 'Next Page'}}
                    onChangeRowsPerPage={context.handleRowsPerPageChange}
                />
            </TableRow>
        </TableFooter>
    )
}

export default withSearchResultsTableContext(SearchResultsTableFooter);