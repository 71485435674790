import React, { Fragment, useEffect, useState } from 'react';
import ResultsTable from '../../../components/ResultsTable/ResultsTable';
import DialogBox from '../../../components/DialogBox/DialogBox';
import AuthorDrawer from './AuthorDrawer';
import { useAuthorsMethods, useAuthorsState, sortable } from './AuthorProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useObjectState } from '../../../utilities/customHooks';
import { GA_URL } from '../../../config/constants';
import { trimSlash } from '../../../helpers/dataUtility';
import { blue } from '@material-ui/core/colors';
import { Tooltip, Typography } from '@material-ui/core';
import { useAuthenticatedRequest } from '../../Client/GlobalProvider';
import { useSnackbar } from 'notistack';
import { ERROR_CODES } from '../../../config/constants';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    mti: {
        color: '#fff',
        fontSize: 10,
        width: 30,
        height: 20,
        verticalAlign: 'middle',
        display: 'inline-flex',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        background: blue[300],
    },
    '.Mui-disabled svg': {
        fill: 'red',
    },
}));

const ResultsMain = () => {
    const classes = useStyles();
    const { postRequest } = useAuthenticatedRequest();
    const { enqueueSnackbar } = useSnackbar();
    const {
        sortBy,
        sortingOrder,
        count,
        authors,
        data,
        page,
        rowsPerPage,
        filters,
        selected,
        dialog,
        fetching,
        openNotif,
        isSaving,
    } = useAuthorsState();
    const { setSelectedAuthor, setAuthorsState, applyFilters, fetchAuthors } =
        useAuthorsMethods();
    const [authorNames, setAuthorNames] = useState([]);
    const [columnFilters, setColumnFilters] = useObjectState({});

    useEffect(() => {
        const names = [...authors].map(({ id, name }) => ({
            value: id,
            label: name,
        }));
        names.sort((a, b) => {
            let fa = a.label.toString().replace(/\s+/g, ''),
                fb = b.label.toString().replace(/\s+/g, '');
            if (fa > fb) return 1;
            if (fa < fb) return -1;
            return 0;
        })
        setAuthorNames(names);
    }, [authors]);

    const columns = [
        {
            name: 'Author Name',
            format: 'bold',
            key: 'name',
            filter: {
                type: 'select',
                options: authorNames,
                value: columnFilters.name,
                onChange: (name) => setColumnFilters({ name: name }),
            },
        },
        {
            name: 'Articles Published',
            format: 'number',
            key: 'articles_count',
            type: 'number',
            sort: sortBy === 'articles_count' ? sortingOrder : null,
        },
        {
            name: 'Guides Published',
            format: 'number',
            key: 'guides_count',
            type: 'number',
            sort: sortBy === 'guides_count' ? sortingOrder : null,
        },
    ];

    const handleEditAuthor = (selectedAuthor) => {
        setAuthorsState({ selectedAuthor, edit: true });
    };

    const handleDeleteAuthor = (selectedAuthor) => {
        setAuthorsState({
            selectedAuthor,
            dialog: {
                show: true,
                title: 'Delete',
                content: `Are you sure you want to delete this author?`,
                stringOverride: {
                    primaryAction: 'Delete',
                    secondaryAction: 'Cancel',
                },
                onOk: () => {
                    deleteAuthors(false, selectedAuthor);
                },
                onCancel: () => {
                    handleCloseDialog();
                },
            },
        });
    };

    const handleBatchDeleteAuthor = () => {
        setAuthorsState({
            dialog: {
                show: true,
                title: 'Delete',
                content: `Are you sure you want to permanently delete ${
                    selected.length
                } ${selected.length > 1 ? 'authors' : 'author'}?`,
                stringOverride: {
                    primaryAction: 'Delete',
                    secondaryAction: 'Cancel',
                },
                onOk: () => {
                    deleteAuthors(true);
                },
                onCancel: () => {
                    handleCloseDialog();
                },
            },
        });
    };

    const deleteAuthors = (isBatch, author) => {
        const data = new FormData();
        let selectedIds = isBatch
            ? selected.map((author) => author.id)
            : [author.id];

        data.append('id', selectedIds.join());

        handleCloseDialog();
        setAuthorsState({ isSaving: true });

        postRequest(
            '/authors/delete',
            data,
            (json) => {
                setAuthorsState(
                    {
                        selected: [],
                        page: 0,
                        fetching: true,
                        isSaving: false,
                    },
                    () => fetchAuthors()
                );
                enqueueSnackbar('Authors have been deleted!', {
                    variant: 'success',
                });
            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to delete authors.', {
                        variant: 'error',
                    });
            },
            () => deleteAuthors(isBatch, author)
        ).finally(() => setAuthorsState({ isSaving: false }));
    };

    const handleMarkAuthor = (selectedAuthor, action) => {
        setAuthorsState({
            selectedAuthor,
            dialog: {
                show: true,
                title: action ? 'Mark as MTI' : 'Remove as MTI',
                content: `Are you sure you want to update this author?`,
                stringOverride: {
                    primaryAction: action ? 'Mark' : 'Remove',
                    secondaryAction: 'Cancel',
                },
                onOk: () => {
                    markAuthors(selectedAuthor, action);
                },
                onCancel: () => {
                    handleCloseDialog();
                },
            },
        });
    };

    const markAuthors = (author, action) => {
        const data = new FormData();
        let authorId = author.id;

        data.append('mti', action);

        handleCloseDialog();
        setAuthorsState({ isSaving: true });

        postRequest(
            `/authors/${authorId}/update`,
            data,
            (json) => {
                setAuthorsState(
                    {
                        selected: [],
                        page: 0,
                        fetching: true,
                        isSaving: false,
                    },
                    () => fetchAuthors()
                );
                enqueueSnackbar('Author has been updated!', {
                    variant: 'success',
                });
            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to update author.', {
                        variant: 'error',
                    });
            },
            () => markAuthors(author, action)
        ).finally(() => setAuthorsState({ isSaving: false }));
    };

    const rowMenus = [
        { name: 'view', text: 'View', target: '_blank' },
        { name: 'edit', text: 'Edit', handler: handleEditAuthor },
        { name: 'delete', text: 'Delete', handler: handleDeleteAuthor },
        {
            name: 'mark_as_mti',
            text: 'Mark As MTI',
            handler: (row) => {
                handleMarkAuthor(row, 1);
            },
        },
        {
            name: 'remove_as_mti',
            text: 'Remove As MTI',
            handler: (row) => {
                handleMarkAuthor(row, 0);
            },
        },
    ];

    const rowMenusChecker = (row, menus) =>
        menus
            .filter((menu) => {
                if (menu.name === 'mark_as_mti')
                    return ['0', 0, null, undefined, 'false', false].includes(
                        row.mti
                    );
                if (menu.name === 'remove_as_mti')
                    return ['1', 1, true, 'true'].includes(row.mti);
                return true;
            })
            .map((menu) => {
                if (menu.name === 'view')
                    menu.url = `${trimSlash(GA_URL)}/author/${row.url_alias}`;
                return menu;
            });

    const batchActions = [
        {
            icon: 'delete',
            handler: handleBatchDeleteAuthor,
        },
    ];

    const icons = [
        {
            name: 'mti',
            icon: (
                <Tooltip title="Meaningful Travel Insider" placement="top">
                    <Typography className={classes.mti}>MTI</Typography>
                </Tooltip>
            ),
            placement: 'right',
            column: 'name',
        },
    ];

    const iconsChecker = (row, icons) => {
        return icons.filter((icon) => {
            if (icon.name === 'mti')
                return ['1', 1, true, 'true'].includes(row.mti);
            return true;
        });
    };

    const onCloseColumnFilters = () => {
        const otherKeys = {};
        const filterKeys = columns
            .filter(({ filter }) => !!filter)
            .map(({ key }) => key);
        Object.keys(filters || {})
            .filter((key) => !filterKeys.includes(key))
            //eslint-disable-next-line array-callback-return
            .map((key) => {
                otherKeys[key] = filters[key];
            });

        setColumnFilters({
            name: null,
            articlesCount: undefined,
            guidesCount: undefined,
        });
        setAuthorsState({ filters: { ...otherKeys }, page: 0 }, applyFilters);
    };

    const onApplyColumnFilters = () => {
        const otherKeys = {};
        const filterKeys = columns
            .filter(({ filter }) => !!filter)
            .map(({ key }) => key);
        Object.keys(filters || {})
            .filter((key) => !filterKeys.includes(key))
            //eslint-disable-next-line array-callback-return
            .map((key) => {
                otherKeys[key] = filters[key];
            });

        setAuthorsState(
            { filters: { ...columnFilters, ...otherKeys }, page: 0 },
            applyFilters
        );
    };

    const handlePageChange = (page) => {
        setAuthorsState({ page }, applyFilters);
    };

    const handleRowsPerPageChange = (rowsPerPage) => {
        setAuthorsState({ rowsPerPage }, applyFilters);
    };

    const handleSorting = ({ key }) => {
        if (!sortable.includes(key)) return;

        const newSortingOrder =
            key === sortBy ? (sortingOrder === 'asc' ? 'desc' : 'asc') : 'asc';
        setAuthorsState(
            { sortingOrder: newSortingOrder, sortBy: key },
            applyFilters
        );
    };

    const handleSelectedChange = (selected) => {
        setAuthorsState({ selected });
    };

    const handleCloseDialog = () => {
        setAuthorsState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
            },
        });
    };

    return (
        <Fragment>
            <Snackbar
                className={classes.notification}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openNotif}
                autoHideDuration={5000}
                onClose={() => {}}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => {}}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <AuthorDrawer />
            <ResultsTable
                data-cy="authors-table"
                isLoadingData={fetching}
                columns={columns}
                label={count > 1 ? 'authors' : 'author'}
                hasSearch={false}
                hasGlobalFilters={false}
                data={data}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowMenus={rowMenus}
                icons={icons}
                selected={selected}
                batchActions={batchActions}
                iconsChecker={iconsChecker}
                rowMenusChecker={rowMenusChecker}
                onColumnFilterClose={onCloseColumnFilters}
                onColumnFiltersApply={onApplyColumnFilters}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSelectedChange={handleSelectedChange}
                onColumnHeaderClick={handleSorting}
            />
            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.onOk}
                onCancel={dialog.onCancel}
                onClose={dialog.onCancel}
                stringOverride={dialog.stringOverride}
                setSelectedRow={setSelectedAuthor}
            />
            <DialogBox open={isSaving} title={'Saving Changes...'} actions="">
                <LinearProgress />
            </DialogBox>
        </Fragment>
    );
};

export default ResultsMain;
