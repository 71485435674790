import React, { useEffect } from 'react';
import {
    Drawer,
    Paper,
    Button,
    Typography,
    TextField,
    TextareaAutosize,
    makeStyles,
    Tooltip,
    InputAdornment,
    Avatar,
    Badge,
    Fab,
} from '@material-ui/core';
import { blue, red, lightBlue, grey, green } from '@material-ui/core/colors';
import Pinterest from '@material-ui/icons/Pinterest';
import YouTube from '@material-ui/icons/YouTube';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Instagram from '@material-ui/icons/Instagram';
import Web from '@material-ui/icons/Language';
import Twitter from '@material-ui/icons/Twitter';
import Facebook from '@material-ui/icons/Facebook';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Edit from '@material-ui/icons/Edit';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';

import SaveIcon from '@material-ui/icons/Save';
import ImageUploader from '../../../components/ImageUploader/ImageUploader';
import { useObjectState } from '../../../utilities/customHooks';
import { useGlobalMethods, useGlobalState } from '../../Client/GlobalProvider';
import { useAuthorsMethods, useAuthorsState } from './AuthorProvider';
import { useSnackbar } from 'notistack';
import { buildFormData, toValidUrl } from '../../../helpers/dataUtility';
import LoadingDialog from '../../../components/Loader/LoadingDialog';
import { CLOUDINARY_PATHS } from '../../../config/constants';
import { cloudinaryImage } from '../../../helpers/cloudinary';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: 420,
    },
    drawerTitle: {
        color: '#555',
        textAlign: 'center',
        fontSize: '1.5em',
        padding: '12px',
        fontWeight: 'bold',
    },
    paper: {
        padding: theme.spacing(3),
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
    },
    filter: {
        fontFamily: 'Roboto',
        width: '100%',

        '& > div': {
            marginBottom: theme.spacing(2),
        },

        '& button': {
            width: '100%',
            padding: '8px 0',
            background: 'rgb(31,179,212)',
            color: 'white',
            marginTop: theme.spacing(1),
            boxShadow: 'none',

            '&:hover': {
                background: 'rgba(31,179,212,0.9)',
            },
        },
    },
    label: {
        color: '#9e9e9e',
        fontSize: 11,
        textTransform: 'uppercase',
        lineHeight: 2.66,
        marginBottom: theme.spacing.unit * 0.5,
    },
    articleTitle: {
        width: '100%',
    },
    datepicker: {
        fontSize: 11,
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'left',
        padding: theme.spacing(1),
        height: 38,
        width: 360,
    },
    bio: {
        width: '100%',
        resize: 'none',
        fontSize: 16,
        borderRadius: 4,
        lineHeight: '1.5em',
        borderColor: '#c4c4c4',
        padding: '10px 14px',
    },
    socialMedia: {
        marginBottom: theme.spacing(2),
        '& svg': {
            fontSize: 18,
        },
    },
    headshot: {
        height: 150,
        width: 150,
        margin: 'auto',
    },
    avatar: {
        display: 'flex',
        margin: 'auto',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fab: {
        backgroundColor: '#FFF',
        color: grey[700],
    },
}));

const socialMediaRegex = {
    facebook: new RegExp(
        /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(\/*)$/ //eslint-disable-line  no-useless-escape
    ),
    twitter: new RegExp(
        /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:[\w\-]*\/)*([\w\-\.]+)(\/*)$/ //eslint-disable-line  no-useless-escape
    ),
    instagram: new RegExp(
        /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:[\w\-]*\/)*([\w\-\.]+)(\/*)$/ //eslint-disable-line  no-useless-escape
    ),
    pinterest: new RegExp(
        /^(?:https?:\/\/)?(?:www\.)?pinterest\.[a-zA-Z0-9()]{1,24}(.[a-zA-Z0-9()]{1,24})?\/(?:[\w\-]*\/)*([\w\-\.]+)(\/*)$/ //eslint-disable-line  no-useless-escape
    ),
    youtube: new RegExp(
        /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:[\w\-]*\/)*([\w\-\.]+)(\/*)$/ //eslint-disable-line  no-useless-escape
    ),
    linkedin: new RegExp(
        /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:[\w\-]*\/)*([\w\-\.]+)(\/*)$/ //eslint-disable-line  no-useless-escape
    ),
    blog: new RegExp(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,24}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ //eslint-disable-line  no-useless-escape
    ),
};

const socialMediaFields = [
    {
        name: 'Blog/Website',
        icon: <Web color="primary" />,
        domain: 'https://yourwebsite.com',
        key: 'blog',
    },
    {
        name: 'Facebook',
        icon: <Facebook style={{ color: blue[500] }} />,
        domain: 'https://facebook.com',
        key: 'facebook',
    },
    {
        name: 'Twitter',
        icon: <Twitter style={{ color: lightBlue[500] }} />,
        domain: 'https://twitter.com',
        key: 'twitter',
    },
    {
        name: 'Instagram',
        icon: <Instagram style={{ color: grey[700] }} />,
        domain: 'https://instagram.com',
        key: 'instagram',
    },
    {
        name: 'LinkedIn',
        icon: <LinkedIn style={{ color: blue[500] }} />,
        domain: 'https://linkedin.com',
        key: 'linkedin',
    },
    {
        name: 'YouTube',
        icon: <YouTube style={{ color: red[500] }} />,
        domain: 'https://youtube.com',
        key: 'youtube',
    },
    {
        name: 'Pinterest',
        icon: <Pinterest style={{ color: red[700] }} />,
        domain: 'https://pinterest.com',
        key: 'pinterest',
    },
];

const isValidLink = (key, value) => {
    if (!value || !socialMediaRegex[key]) return false;
    return socialMediaRegex[key] && socialMediaRegex[key].test(value);
};

const validationIcon = (key, value) => {
    if (!value) return null;
    if (isValidLink(key, value))
        return (
            <CheckCircle
                style={{
                    color: green[500],
                }}
                fontSize="small"
            />
        );
    else
        return (
            <Error
                data-cy={key}
                style={{
                    color: red[300],
                }}
                fontSize="small"
            />
        );
};

const AuthorDrawer = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { edit, selectedAuthor, setAuthorsState } = useAuthorsState();
    const { fetchAuthors } = useAuthorsMethods();
    const { authorDrawerOpen } = useGlobalState();
    const {
        authenticatedPostRequest: postRequest,
        handleStateChange: setGlobalState,
    } = useGlobalMethods();
    const [state, setState] = useObjectState({
        image: null,
        origImage: null,
        uploaderOpen: false,
        authorName: '',
        title: '',
        bio: '',
        saving: false,
    });

    useEffect(() => {
        if (edit) {
            const photoParams = {
                filename: selectedAuthor.photo,
                path: CLOUDINARY_PATHS.AUTHORS,
            };
            const editData = {
                authorName: selectedAuthor.name || '',
                title: selectedAuthor.title || '',
                bio: selectedAuthor.bio || selectedAuthor.description || '',
                image: cloudinaryImage({
                    ...photoParams,
                    params: { h: 150, w: 150 },
                }),
                origImage: cloudinaryImage(photoParams),
                blog: selectedAuthor.personal_url,
                facebook: selectedAuthor.facebook_url,
                youtube: selectedAuthor.youtube_url,
                twitter: selectedAuthor.twitter_url,
                instagram: selectedAuthor.instagram_url,
                pinterest: selectedAuthor.pinterest_url,
                linkedin: selectedAuthor.linkedin_url,
            };
            setState({ ...editData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit, selectedAuthor]);

    const handleToggleUploader = () =>
        setState({ uploaderOpen: !state.uploaderOpen });

    const handleSubmitImage = (cropped, orig) => {
        setState({
            image: cropped,
            origImage: orig,
        });
    };

    const handleSave = () => {
        const data = new FormData();
        const endpoint = edit
            ? `/authors/${selectedAuthor.id}/update`
            : '/authors';

        buildFormData(data, {
            name: state.authorName,
            title: state.title,
            description: state.bio,
            photo: state.image.startsWith('data:image/') ? state.image : null,
            facebook_url: toValidUrl(state.facebook) || null,
            twitter_url: toValidUrl(state.twitter) || null,
            instagram_url: toValidUrl(state.instagram) || null,
            pinterest_url: toValidUrl(state.pinterest) || null,
            linkedin_url: toValidUrl(state.linkedin) || null,
            youtube_url: toValidUrl(state.youtube) || null,
            personal_url: toValidUrl(state.blog) || null,
        });

        setState({ saving: true });

        postRequest(
            endpoint,
            data,
            () => {
                enqueueSnackbar(
                    edit
                        ? 'Author successfully updated!'
                        : 'New Author successfully added!',
                    {
                        variant: 'success',
                    }
                );
                setGlobalState({ authorDrawerOpen: false });
                setAuthorsState({ edit: false, selectedAuthor: null });
                setState({
                    image: null,
                    origImage: null,
                    uploaderOpen: false,
                    authorName: '',
                    title: '',
                    bio: '',
                    twitter: '',
                    facebook: '',
                    youtube: '',
                    instagram: '',
                    linkedin: '',
                    pinterest: '',
                    blog: '',
                    saving: false,
                });
                fetchAuthors();
            },
            () => {
                enqueueSnackbar(
                    edit
                        ? 'Failed to update author. Please try again.'
                        : 'Failed to save author. Please try agan.',
                    {
                        variant: 'error',
                    }
                );
            },
            handleSave
        ).finally(() => {
            setState({ saving: false });
        });
    };

    const hanldleClose = () => {
        setAuthorsState({ edit: false, selectedAuthor: null });
        setGlobalState({ authorDrawerOpen: false });
        setState({
            image: null,
            origImage: null,
            uploaderOpen: false,
            authorName: '',
            title: '',
            bio: '',
        });
    };

    const handleFieldChange =
        (field) =>
        ({ target: { value } }) =>
            setState({ [field]: value });

    const isValidFields =
        !!state.authorName &&
        !!state.image &&
        !!state.bio &&
        socialMediaFields.every(({ key }) => {
            if (!state[key]) return true;
            return isValidLink(key, state[key]);
        });

    return (
        <>
            <LoadingDialog message="Saving Author..." open={state.saving} />
            <ImageUploader
                open={state.uploaderOpen}
                minWidth={150}
                minHeight={150}
                cropped={state.image}
                image={state.origImage}
                requireCrop
                onSubmit={handleSubmitImage}
                onClose={handleToggleUploader}
            />
            <Drawer
                anchor="right"
                open={authorDrawerOpen || edit}
                onClose={hanldleClose}
            >
                <div className={classes.root}>
                    <form className={classes.form}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography className={classes.drawerTitle}>
                                {edit ? 'Edit' : 'Add'} Author
                            </Typography>
                            <div className={classes.avatar}>
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={
                                        <Tooltip
                                            data-cy="headshot-CTA"
                                            title={`${
                                                !!state.image ? 'Edit' : 'Add'
                                            } Headshot`}
                                        >
                                            <Fab
                                                size="small"
                                                className={classes.fab}
                                                onClick={handleToggleUploader}
                                                disableFocusRipple
                                            >
                                                {!!state.image ? (
                                                    <Edit fontSize="small" />
                                                ) : (
                                                    <AddPhotoAlternate fontSize="small" />
                                                )}
                                            </Fab>
                                        </Tooltip>
                                    }
                                >
                                    <Avatar
                                        data-cy="author-headshot-preview"
                                        src={state.image}
                                        className={classes.headshot}
                                        alt={`Headshot ${state.authorName}`}
                                    />
                                </Badge>
                            </div>
                            <Typography variant="overline">
                                Author name
                            </Typography>
                            <TextField
                                data-cy="author-name-input"
                                size="small"
                                autoFocus
                                onChange={handleFieldChange('authorName')}
                                value={state.authorName}
                                placeholder="Enter Author Name"
                            />
                            <br />
                            <Typography variant="overline">
                                Job Title
                            </Typography>
                            <TextField
                                data-cy="job-title-input"
                                size="small"
                                onChange={handleFieldChange('title')}
                                value={state.title}
                                placeholder="Enter Job Title"
                                inputProps={{ maxLength: 50 }}
                                //eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                    endAdornment: (
                                        <Typography variant="caption">
                                            {state.title.length}/50
                                        </Typography>
                                    ),
                                }}
                                error={state.title.length > 50}
                            />
                            <br />
                            <Typography variant="overline">Bio</Typography>
                            <TextareaAutosize
                                data-cy="author-bio-text"
                                className={classes.bio}
                                rowsMin={4}
                                rowsMax={8}
                                onChange={handleFieldChange('bio')}
                                value={state.bio}
                                maxLength={600}
                                placeholder="Enter bio here"
                            />
                            <Typography variant="caption" align="right">
                                {state.bio.length}/600
                            </Typography>
                        </Paper>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="overline">
                                Social Media Links
                            </Typography>
                            <br />
                            {socialMediaFields.map(
                                ({ name, icon, domain, key }) => (
                                    <Tooltip title={name} placement="left">
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        style={{
                                                            width: 30,
                                                        }}
                                                    >
                                                        {icon}
                                                    </InputAdornment>
                                                ),
                                                endAdornment: validationIcon(
                                                    key,
                                                    state[key]
                                                ),
                                            }}
                                            data-cy={`${name}-link`}
                                            size="small"
                                            className={classes.socialMedia}
                                            variant="outlined"
                                            placeholder={domain}
                                            value={state[key]}
                                            onChange={handleFieldChange(key)}
                                        />
                                    </Tooltip>
                                )
                            )}
                        </Paper>
                        <Button
                            data-cy="create-author-save"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSave}
                            startIcon={
                                <SaveIcon
                                    color="white"
                                    className={classes.extendedIcon}
                                />
                            }
                            disabled={!isValidFields}
                        >
                            {edit ? 'Save' : 'Add Author'}
                        </Button>
                    </form>
                </div>
            </Drawer>
        </>
    );
};

export default AuthorDrawer;
