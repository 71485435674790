import React, { Component } from 'react';
import { sendRequest } from '../../../../helpers/apiRequestUtility';
import { isEqual } from 'lodash';

const { Provider, Consumer } = React.createContext();

const columns = [
    {
        label: 'Current Rank',
        key: 'rank'
    },
    {
        label: 'Client Name',
        key: 'client_name'
    },
    {
        label: 'Listing Title',
        key: 'program_name'
    },
    {
        label: 'Tier 1',
        key: 'monetary_values',
        description: 'This is the tier 1',
        subcolumns: [
            {
                label: 'Total Linked Listings',
                key: 'listing_value',
                format: 'currency'
            },
            {
                label: 'Total Ads',
                key: 'page_ads_value',
                format: 'currency'
            },
            {
                label: 'Result Text Ads Value',
                key: 'result_text_ads_value',
                format: 'currency'
            }
        ]
    },
    {
        label: 'Tier 3',
        key: 'monetary_values',
        description: 'This is the tier 3',
        subcolumns: [
            {
                label: 'Total Linked Listings',
                key: 'domain_listing_value',
                format: 'currency'
            },
            {
                label: 'Total Ads',
                key: 'domain_ads_value',
                format: 'currency'
            }
        ]
    },
    {
        label: 'Tier 4',
        key: 'review_count',
        description:
            'Total Number of Reviews for Search (Review Count)',
        format: 'comma-format'
    },
    {
        label: 'Tier 5',
        key: 'last_edited_date',
        description:
            'Most recent date a listing was added or edited (Last edited date)'
    }
];

class SearchResultsTableProvider extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        searchCriteriaParams: '',
        data: [],
        count: 0,
        loading: false,
        filtersDrawerOpen: true,
        open: false,
        row: null,
        filters: {},
        online: []
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            !isEqual(prevState.filters, this.state.filters) ||
            !isEqual(prevState.online, this.state.online)
        ) {
            this.setState({ data: [] });
            this.fetchResults(0, this.state.rowsPerPage);
        }
    }   

    toggleAdsBreakdownModal = (open, row) => () => {
        this.setState({ open, row });
    };

    handleRowsPerPageChange = (event) => {
        const rowsPerPage = event.target.value;
        const { data, page, count } = this.state;
        let requiredNumberOfRows = (page + 1) * rowsPerPage;
        requiredNumberOfRows = requiredNumberOfRows > count
            ? count
            : requiredNumberOfRows;

        if (requiredNumberOfRows > data.length) {
            this.setState({ rowsPerPage: rowsPerPage }, () =>
                this.fetchResults(data.length, requiredNumberOfRows - data.length)
            );
        } else {
            this.setState({ rowsPerPage });
        }
    };

    handlePageChange = (page) => {
        const { rowsPerPage, data, count } = this.state;
        let requiredNumberOfRows = (page + 1) * rowsPerPage;
        requiredNumberOfRows = requiredNumberOfRows > count
            ? count
            : requiredNumberOfRows;

        if (requiredNumberOfRows > data.length) {
            this.setState({ page: page }, () =>
                this.fetchResults(data.length, requiredNumberOfRows - data.length)
            );
        } else {
            this.setState({ page });
        }
    };

    getDataOnCurrentPage = () => {
        let { rowsPerPage, page, data } = this.state;

        return (
            data &&
            data.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
        );
    };

    fetchResults = (offset, limit) => {
        const {
            filters,
            online
        } = this.state;

        if (!!filters && typeof filters === 'object' && Object.keys(filters)) {
            let query_params = '';

            query_params += filters.directory_filter
                ? `directory_id=${filters.directory_filter.value}`
                : '';
            query_params += filters.location_filter
                ? `&${filters.location_filter.key}=${
                      filters.location_filter.value
                  }${
                      filters.location_filter.country
                          ? `&country_id=${filters.location_filter.country}`
                          : ``
                  }`
                : '';
            query_params += filters.type_filter
                ? `&${filters.type_filter.key}=${filters.type_filter.value}`
                : '';
            query_params += filters.timing_filter
                ? `&${filters.timing_filter.key}=${filters.timing_filter.value}`
                : '';

            if ([1].includes(online)) {
                query_params += `&online=1,2`;
            } else {
                query_params += `&online=0,1`;
            }

            if (!!query_params) {
                query_params += `&offset=${offset}&limit=${limit}`;
            }

            this.setState({ loading: true });

            sendRequest(
                `/search-ranking?${query_params}`,
                response => {
                    const { data } = this.state;
                    let tempData = response.provider_program_results;
                    tempData =
                        !!tempData && !!tempData.length
                            ? tempData.map(d => {
                                  d.monetary_values =
                                      d.provider.monetary_values;
                                  d.last_edited_date =
                                      d.top_program.date_edited;
                                  d.client_name = d.provider.name;
                                  d.program_name = d.top_program.name;
                                  d.review_count = d.provider.reviews_count;

                                  return d;
                              })
                            : [];
                    tempData = data.concat(tempData);
                    this.setState({
                        ...this.state,
                        data: tempData,
                        count: response.meta.providers_count,
                        loading: false,
                        searchCriteriaParams: query_params
                    });
                },
                error => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            );
        }
    };

    handleStateChanged = (state, callback) => {
        this.setState(state, callback);
    };

    render() {
        return (
            <Provider
                value={{
                    state: this.state,
                    props: this.props,
                    handleRowsPerPageChange: this.handleRowsPerPageChange,
                    handlePageChange: this.handlePageChange,
                    getDataOnCurrentPage: this.getDataOnCurrentPage,
                    handleStateChanged: this.handleStateChanged,
                    toggleAdsBreakdownModal: this.toggleAdsBreakdownModal,
                    columns: columns
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export const withSearchResultsTableContext = (Component) => (props) => {
    return (
        <Consumer>
            {(context) => <Component context={context} {...props} />}
        </Consumer>
    );
};

export default SearchResultsTableProvider;
