import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CssBaseline from '@material-ui/core/CssBaseline';
import ResetPasswordForm from './ResetPassword/ResetPasswordForm';
import NotFound from '../Error/NotFound';
import Loader from '../../components/Loader/Loader';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../config/constants';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../../helpers/apiRequestUtility';
import IsLoggedIn from '../../helpers/IsLoggedIn';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    back: {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        top: theme.spacing.unit * 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none'
        },

        '& svg': {
            fill: 'white',
            fontSize: '1em'
        }
    }
});

const ResetPassword = ({ classes, location, history }) => {
    const [email, setEmail] = useState(null);
    const [token, setToken] = useState(null);
    const [isValid, setValid] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const validateToken = () => {
        const urlParams = new URLSearchParams(location.search);
        const resetToken = urlParams.get('token');
        if (!resetToken) setValid(false, setLoading(false));
        sendRequest(
            `/verify-reset-token?token=${resetToken}`,
            result => {
                const { email, error } = result;
                if (!!error) setValid(false, setLoading(false));
                else if (email) {
                    setToken(resetToken);
                    setEmail(email, setValid(true, setLoading(false)));
                }
            },
            error => {
                console.error(error);
                setValid(false, setLoading(false));
            }
        );
    };

    useEffect(() => {
        if (IsLoggedIn()) history.push('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    if (isLoading) return <Loader />;

    if (!isValid) return <NotFound />;

    return (
        <Fragment>
            <CssBaseline />
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                <div className={classes.root}>
                    <div className={classes.back}>
                        <ArrowBackIos />
                        <a href="https://www.goabroad.com">Back to GoAbroad</a>
                    </div>
                    <img
                        className={classes.background}
                        src="https://images.unsplash.com/photo-1504609773096-104ff2c73ba4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
                        alt=""
                    />
                    <ResetPasswordForm email={email} token={token} />
                </div>
            </GoogleReCaptchaProvider>
        </Fragment>
    );
};
export default withStyles(styles)(withRouter(ResetPassword));
