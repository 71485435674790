import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { ScholarshipContext } from '../../context';
import { sendRequest, postRequest } from '../../helpers/apiRequestUtility';
import {
    constructHttpParams,
    explodeUrlParams,
    isEmptyDate,
    toValidUrl
} from '../../helpers/dataUtility';
import {
    useDidUpdateEffect,
    useObjectState
} from '../../utilities/customHooks';
import { useHistory, useLocation } from 'react-router-dom';
import { isBookmarkable } from '../../utilities/boomarkability';
import moment from 'moment';
import { useGlobalState } from '../../views/Client/GlobalProvider';
import { truncate } from 'lodash';
import { buildFormData } from '../../helpers/dataUtility';

const { Provider } = ScholarshipContext;

const mapper = {
    volunteer_type: 'volunteer_type_id',
    intern_type: 'intern_type_id',
    degree_program: 'degree_id',
    language: 'language_id',
    adventure_travel_type: 'adventure_type_id',
    highschool_type: 'highschool_type_id',
    gap_year_type: 'gapyear_type_id'
};

const ScholarshipProvider = (props) => {
    const history = useHistory();
    const { search } = useLocation();
    const { handleStateChange } = useGlobalState();
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useObjectState({
        data: [],
        filters: {},
        sort: 'date_created',
        sortOrder: 'desc',
        rowsPerPage: 10,
        page: 0,
        count: 0,
        selectedScholarship: null,
        edit: false,
        fetching: false,
        showGlobalFilters: false,
        showColumnFilters: false,
        saving: false
    });

    const fetchScholars = () => {
        const {
            filters = {},
            sort,
            sortOrder,
            rowsPerPage = 10,
            page = 0
        } = state;
        const dealine_date = Object.keys(filters.deadline_date || {}).map(
            (key) => filters.deadline_date[key]
        );
        const params = constructHttpParams({
            award_name: filters.award_name,
            contact_email: filters.contact_email,
            directory_id: (filters.directory_id || []).join(','),
            country_id: (filters.country_id || []).join(','),
            city_id: (filters.city_id || []).join(','),
            date_created: Object.keys(filters.date_created || {})
                .map((key) => filters.date_created[key])
                .join(','),
            deadline_date: dealine_date.includes('offered_year_round')
                ? '0000-00-00'
                : dealine_date
                    .filter((k) => k !== 'offered_year_round')
                    .join(','),
            is_featured_in_directory: filters.is_featured_in_directory,
            is_featured_in_homepage: filters.is_featured_in_homepage,
            volunteer_type_id: filters.volunteer_type,
            intern_type_id: filters.intern_type,
            degree_id: filters.degree_program,
            language_id: filters.language,
            adventure_type_id: filters.adventure_travel_type,
            highschool_type_id: filters.highschool_type,
            gap_year_type: filters.gap_year_type,
            is_active: filters.is_active,
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            wildcards: [
                filters.award_name ? 'award_name' : '',
                filters.contact_email ? 'contact_email' : ''
            ]
                .filter((v) => !!v)
                .join(','),
            sort: `${sortOrder === 'asc' ? '+' : '-'}${sort}`
        });

        setState({ fetching: true });

        sendRequest(
            `/scholarships?${params}`,
            ({ scholarships, count }) => {
                const data = [...scholarships].map((scholarship) => {
                    const featuredDirectories = (
                        scholarship.directories || []
                    ).filter(
                        ({ id, is_featured_in_directory }) =>
                            id !== 10 && is_featured_in_directory === 1
                    );
                    scholarship.featuredDirectories = featuredDirectories
                        .map(({ name }) => name)
                        .join(',');
                    scholarship.is_featured_in_directory =
                        featuredDirectories.length > 0;
                    scholarship.directoryNames = (scholarship.directories || [])
                        .map(({ name }) => name)
                        .join(',');
                    scholarship.locations = truncate(
                        [
                            ...(scholarship.countries || []),
                            ...(scholarship.cities || []),
                            ...(scholarship.main_regions || [])
                        ]
                            .map(({ name }) => name)
                            .join(','),
                        30
                    );
                    scholarship.types = truncate(
                        [
                            ...new Set(
                                [
                                    ...(scholarship.adventure_types || []),
                                    ...(scholarship.degrees || []),
                                    ...(scholarship.intern_types || []),
                                    ...(scholarship.highschool_types || []),
                                    ...(scholarship.volunteer_types || []),
                                    ...(scholarship.gapyear_types || [])
                                ].map(({ name }) => name)
                            )
                        ].join(','),
                        30
                    );

                    if (isEmptyDate(scholarship.date_created))
                        scholarship.date_created = null;

                    if (isEmptyDate(scholarship.deadline_date))
                        scholarship.deadline_date = 'Offered Year Round';

                    return scholarship;
                });

                setState({ data: data, count });
            },
            () => {
                setState({ data: [], count: 0 });
                enqueueSnackbar(
                    'Failed to fetch scholarships. Please try again.',
                    { variant: 'error' }
                );
            }
        ).finally(() => setState({ fetching: false }));
    };

    const handleAddScholarship = async (scholarship, action) => {
        setState({ saving: true });
        const data = new FormData();

        data.append('award_name', scholarship.title);
        data.append('url', toValidUrl(scholarship.link));
        data.append('description', scholarship.description);
        data.append(
            'date_created',
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        );
        if (scholarship.deadline) {
            data.append(
                'deadline_date',
                moment(scholarship.deadline).format('YYYY-MM-DD')
            );
        }
        data.append('contact_email', scholarship.contactEmail);

        buildFormData(data, {
            directory_id: scholarship.selectedDirectories
        });

        //eslint-disable-next-line array-callback-return
        (scholarship.location || []).map((loc) => {
            if (loc.value.startsWith('2_')) {
                data.append('country_id[]', [loc.value.split('_')[1]]);
            }
            if (loc.value.startsWith('16_')) {
                data.append('city_id[]', [loc.value.split('_')[1]]);
            }
            if (loc.value.startsWith('8_')) {
                data.append('main_region_id[]', [loc.value.split('_')[1]]);
            }
        });

        const values = {};
        //eslint-disable-next-line array-callback-return
        (scholarship.type || []).map((type) => {
            const [criteria, value] = type.value.split('=');
            values[criteria] = [...(values[criteria] || []), value];
        });

        Object.keys(values).forEach(function (key) {
            buildFormData(data, {
                [mapper[key]]: values[key]
            });
        });

        if (
            scholarship.selectedDirectories.length === 1 &&
            scholarship.featuredOnDirectory
        ) {
            buildFormData(data, {
                feature_directory_id: scholarship.selectedDirectories
            });
        }

        if (
            scholarship.featuredOnHomepage !== null &&
            scholarship.selectedDirectories.includes(10)
        )
            data.append(
                'is_feature_homepage',
                scholarship.featuredOnHomepage ? 1 : 0
            );

        if (action === 'add') {
            await postRequest(
                `/scholarships`,
                data,
                (response) => {
                    setState({ saving: false });
                    handleStateChange({ scholarshipDrawerOpen: false });
                    enqueueSnackbar('Scholarship successfully added!', {
                        variant: 'success'
                    });
                    fetchScholars();
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Scholarship adding failed!', {
                        variant: 'error'
                    });
                }
            );
        } else if (action === 'edit') {
            await postRequest(
                `/scholarships/${state.selectedScholarship.id}/update`,
                data,
                (response) => {
                    setState({
                        saving: false,
                        edit: false
                    });
                    handleStateChange({
                        scholarshipDrawerOpen: false
                    });
                    enqueueSnackbar('Scholarship successfully saved!', {
                        variant: 'success'
                    });
                    fetchScholars();
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Scholarship saving failed!', {
                        variant: 'error'
                    });
                }
            );
        }
    };

    useDidUpdateEffect(() => {
        fetchScholars();
    }, [
        state.filters.award_name,
        state.filters.contact_email,
        state.filters.directory_id,
        state.filters.country_id,
        state.filters.city_id,
        state.filters.date_created,
        state.filters.deadline_date,
        state.filters.is_featured_in_homepage,
        state.filters.is_featured_in_directory,
        state.filters.volunteer_type,
        state.filters.intern_type,
        state.filters.degree_program,
        state.filters.language,
        state.filters.adventure_travel_type,
        state.filters.highschool_type,
        state.filters.gap_year_type,
        state.filters.is_active,
        state.sort,
        state.sortOrder,
        state.rowsPerPage,
        state.page
    ]);

    useDidUpdateEffect(() => {
        const { filters, sort, sortOrder } = state;
        const params = constructHttpParams({
            deadline_date: Object.keys(filters.deadline_date || {})
                .map((key) => filters.deadline_date[key])
                .join(','),
            sort_deadline:
                sort === 'deadline_date'
                    ? sortOrder === 'asc'
                        ? 'farthest'
                        : 'soonest'
                    : ''
        });
        history.push({ pathname: `/scholarships`, search: `?${params}` });
    }, [state.filters.deadline_date, state.sort, state.sortOrder]);

    useEffect(() => {
        const search_params = explodeUrlParams(search);
        const filter_params = {};
        const sortParams = {};

        //eslint-disable-next-line array-callback-return
        Object.keys(search_params).map((key) => {
            if (isBookmarkable('scholarship_view', key)) {
                if (['deadline_date'].includes(key)) {
                    const [start, end] = search_params[key].split(',');
                    if (start === 'offered_year_round')
                        filter_params['deadline_date'] = { alterValue: start };
                    else if (!!end) filter_params[key] = { start, end };
                    else filter_params[key] = { start };
                } else if (['sort_deadline'].includes(key)) {
                    if (['soonest', 'farthest'].includes(search_params[key])) {
                        sortParams['sort'] = 'deadline_date';
                        sortParams['sortOrder'] =
                            search_params[key] === 'soonest' ? 'desc' : 'asc';
                    }
                } else {
                    filter_params[key] = search_params[key];
                }
            }
        });

        if (Object.keys({ ...filter_params, ...sortParams }).length)
            setState((prev) => ({
                ...prev,
                ...sortParams,
                filters: { ...prev.filters, ...filter_params },
                showColumnFilters:
                    Object.keys(filter_params).includes('deadline_date')
            }));
        else fetchScholars();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Provider
            value={{
                state,
                setScholarshipState: setState,
                handleAddScholarship,
                fetchScholars
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useScholarshipState = () => {
    const { state, setScholarshipState } = useContext(ScholarshipContext);
    return { ...state, setScholarshipState };
};

export const useScholarshipMethods = () => {
    const { state, ...methods } = useContext(ScholarshipContext);
    return methods;
};

export default ScholarshipProvider;
