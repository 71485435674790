import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormlLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import DialogBox from '../../../components/DialogBox/DialogBox';
import { includes } from 'lodash';

const styles = theme => ({
    dialog: {
        minWidth: '500px',
        padding: theme.spacing.unit * 2
    },
    content: {
        padding: theme.spacing.unit * 2
    },
    actions: {},
    dialogInput: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const StatsExportDialog = ({ open, columns, onClose, onDownload }) => {
    const [selected, setSelected] = useState([]);

    const handleSelectionChange = key => e => {
        if (e.target.checked) {
            let tmp = selected;
            let keys = [];

            tmp.push(key);
            Object.keys(columns).forEach(k => {
                if (includes(tmp, columns[k].key)) {
                    keys.push(columns[k].key);
                }
            });
            setSelected(keys);
        } else {
            setSelected(selected.filter(k => k !== key));
        }
    };

    const handleDownload = () => onDownload(selected);

    useEffect(() => {
        const selectedCol = columns.filter((e) => !!e.isShown);
        let keys = Object.values(selectedCol).map(val => val.key);
        setSelected(keys);
    }, [columns]);

    return (
        <DialogBox
            open={open}
            title="DOWNLOAD"
            actions="OkCancel"
            onOk={handleDownload}
            onClose={onClose}
            onCancel={onClose}
            stringOverride={{ primaryAction: 'Download' }}
        >
            <FormControl>
                <FormlLabel>Select which columns to Export</FormlLabel>
                {(columns || []).map(column => {
                    return (
                        <FormControlLabel
                            key={column.label}
                            control={
                                <Checkbox
                                    checked={selected.includes(column.key)}
                                    onChange={handleSelectionChange(column.key)}
                                />
                            }
                            label={column.label}
                        />
                    );
                })}
            </FormControl>
        </DialogBox>
    );
};

export default withStyles(styles)(StatsExportDialog);
