import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Check from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Warning from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import DialogBox from '../DialogBox/DialogBox';
import SearchBasedDropdown from '../SearchBasedDropdown/SearchBasedDropdown';
import { DIRECTORIES } from '../../utilities/directory';
import { postRequest, sendRequest } from '../../helpers/apiRequestUtility';
import { isEmpty, sortBy } from 'lodash';

const TABWIDTH = 420;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `12px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& > span': {
            marginLeft: '0'
        }
    },
    card: {
        marginTop: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2,
        overflow: 'visible',

        '& > p': {
            display: 'flex',
            alignItems: 'center'
        },
        '& svg': {
            fontSize: 14,
            marginRight: theme.spacing.unit * 1
        }
    },
    label: {
        marginTop: theme.spacing.unit * 2
    },
    button: {
        width: '100%'
    },
    ctaButton: {
        marginTop: 'auto',
        marginBottom: theme.spacing.unit * 4
    },
    input: {
        display: 'none'
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    helptext: {
        marginBottom: theme.spacing.unit
    }
}));

const ImportReviewsDrawer = ({ open, onClose }) => {
    const classes = useStyles();

    const [directories, setDirectories] = useState([]);
    const [selectedDirectory, setSelectedDirectory] = useState(null);

    //eslint-disable-next-line no-unused-vars
    const [fetchingCategories, setFetchingCategories] = useState(false);
    const [categories, setCategories] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);

    const [errors, setErrors] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [saving, setSaving] = useState(false);

    const staticCategories = [
        'Name/Alias of Participant',
        'Program ID',
        'Review Date',
        'Title',
        'Review',
        'Overall Rating'
    ];

    const submit = () => {
        const form_data = new FormData();
        const { value } = selectedDirectory || {};

        form_data.append('directory_id', value);
        form_data.append('file', selectedFile);

        setSaving(true);
        setDisabled(true);
        setErrors([]);

        postRequest(
            `/reviews/import`,
            form_data,
            (json) => {
                window.location.reload();
            },
            (error) => {
                if (error.errors) setErrors(error.errors);
                else setErrors(['An error occurred. Please try again.']);
                setSaving(false);
                setDisabled(false);
            }
        );
    };

    const fetchDirectories = () => {
        if (isEmpty(directories)) {
            setDirectories(
                sortBy(
                    DIRECTORIES.filter((d) => {
                        return [2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 19].includes(
                            d.id
                        );
                    }),
                    (d) => {
                        return d.name;
                    }
                )
            );
            setSelectedDirectory(null);
        }
    };

    const fetchCategories = () => {
        if (!isEmpty(selectedDirectory)) {
            setFetchingCategories(true);
            sendRequest(
                `/reviews/categories?directory_id=${selectedDirectory.value}&fields=id,name&limit=-1&sort=+id`,
                (json) => {
                    setFetchingCategories(false);
                    if (json.categories) {
                        setCategories(
                            staticCategories.concat(
                                json.categories.map(({ name }) => name)
                            )
                        );
                    }
                }
            );
        } else {
            setCategories([]);
        }
    };

    useEffect(() => {
        if (directories.length === 0) fetchDirectories();
    });

    useEffect(() => {
        fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDirectory]);

    useEffect(() => {
        if (!isEmpty(selectedDirectory) && selectedFile) setDisabled(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDirectory, selectedFile]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            BackdropProps={{
                invisible: true
            }}
        >
            <DialogBox open={saving} title={'Importing Reviews...'} actions="">
                <LinearProgress />
            </DialogBox>
            <div className={classes.root} data-cy="import-review-drawer">
                <Typography className={classes.label} variant="label">
                    Directory
                </Typography>
                <SearchBasedDropdown
                    arrayOptions={directories.map(({ id, name }) => {
                        return { value: id, label: name };
                    })}
                    onChange={setSelectedDirectory}
                    value={selectedDirectory}
                    placeholder="Select Directory"
                />

                {errors.length > 0 ? (
                    <div>
                        <Card className={classes.card} data-cy="drawer-error-card">
                            <Typography
                                color="error"
                                className={classes.helptext}
                            >
                                Failed importing reviews. CSV file does not
                                contain the following required headers. Check
                                the CSV file and try the upload again.
                            </Typography>
                            {errors.map((e, key) => {
                                return (
                                    <Typography color="error" key={key}>
                                        <Warning color="error" /> {e}
                                    </Typography>
                                );
                            })}
                        </Card>

                        {categories.length > 0 && (
                            <Card className={classes.card}>
                                {categories.map((category, key) => {
                                    return (
                                        <Typography color="success" key={key}>
                                            <Check color="success" /> {category}
                                        </Typography>
                                    );
                                })}
                            </Card>
                        )}
                    </div>
                ) : (
                    <Card className={classes.card} data-cy='drawer-column-headings'>
                        <Typography className={classes.helptext}>
                            To import reviews successfully, the CSV file must
                            contain the following required column headings:
                        </Typography>
                        {categories.map((category, key) => {
                            return (
                                <Typography key={key}>
                                    {key + 1}. {category}
                                </Typography>
                            );
                        })}
                    </Card>
                )}

                <Card className={classes.card}>
                    <input
                        accept="text/csv"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <label htmlFor="contained-button-file">
                        <Button
                            data-cy="upload-csv-btn"
                            variant="contained"
                            component="span"
                            className={classes.button}
                        >
                            <CloudUploadIcon className={classes.leftIcon} />
                            Upload CSV File
                        </Button>
                    </label>
                </Card>
                <Button
                    onClick={submit}
                    disabled={disabled}
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.ctaButton}
                    data-cy="import-review-drawer-btn"
                >
                    Import Reviews
                </Button>
            </div>
        </Drawer>
    );
};

export default ImportReviewsDrawer;
