import {
    Dialog,
    DialogContent,
    Fade,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 360,
        padding: theme.spacing(4)
    },
    progress: {
        marginTop: theme.spacing(1)
    }
}));

const LoadingDialog = ({ message, open }) => {
    const classes = useStyles();
    return (
        <Dialog open={open} TransitionComponent={Fade}>
            <DialogContent className={classes.dialog}>
                <Typography variant="h6">{message || 'Loading ...'}</Typography>
                <LinearProgress className={classes.progress} />
            </DialogContent>
        </Dialog>
    );
};

export default LoadingDialog;
