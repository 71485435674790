import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { PureComponent } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { withState, withProps } from './ResultsTableProvider';
import {
    arrayJsonToString,
    arrayStringToJSON,
} from '../../helpers/dataUtility';
import { difference } from 'lodash';
import { TableSortLabel } from '@material-ui/core';

const styles = (theme) => ({
    selectAllCheckbox: {
        paddingRight: 0,
    },
    arrowButton: {
        padding: 0,
    },
    checkBoxCol: {
        width: '60px',
        minWidth: 60,
    },
    tablehead: {
        '& th': {
            padding: 0,
        },
    },
});

const usedStates = [
    'isSelectedAll',
    'selected',
    'unselected',
    'page',
    'rowsPerPage',
];

const usedProps = [
    'columns',
    'selectable',
    'isLoadingData',
    'count',
    'onSelectedChange',
    'onUnselectedChange',
    'onSelectAllClick',
    'onSelectNoneClick',
    'onColumnHeaderClick',
];

class ResultsTableHead extends PureComponent {
    state = {
        anchorEl: null,
    };

    handlePageSelectChange = (e) => {
        let {
            isSelectedAll,
            selected,
            unselected,
            count,
            getDataOnCurrentPage,
            handleStateChanged: setState,
            onUnselectedChange,
            onSelectedChange,
        } = this.props;
        let dataOnPage = arrayJsonToString(getDataOnCurrentPage());

        selected = arrayJsonToString(selected);
        unselected = arrayJsonToString(unselected);

        if (isSelectedAll) {
            if (e.target.checked)
                unselected = arrayStringToJSON(
                    difference(unselected, dataOnPage)
                );
            else unselected = arrayStringToJSON([...unselected, ...dataOnPage]);
            let notUnselectedAll = unselected.length < count;
            !!onUnselectedChange && onUnselectedChange(unselected);
            setState({
                unselected: notUnselectedAll ? unselected : [],
                isSelectedAll: notUnselectedAll,
            });
        } else {
            if (e.target.checked) {
                dataOnPage = dataOnPage.filter(this.filterDataOnPage(selected));
                selected = [...selected, ...dataOnPage];
            } else {
                selected = selected.filter(this.filterSelected(dataOnPage));
            }
            selected = arrayStringToJSON(selected);
            !!onSelectedChange && onSelectedChange(selected);
            setState({ selected });
        }
    };

    filterDataOnPage = (selected) => (item) => {
        return !selected.includes(item);
    };

    filterSelected = (dataOnPage) => (item) => {
        return !dataOnPage.includes(item);
    };

    handleSelectAllClick = () => {
        const {
            onSelectAllClick: callback,
            handleStateChanged: setState,
            onSelectedChange,
            onUnselectedChange,
        } = this.props;

        this.setState({ anchorEl: null }, () => {
            setState(
                {
                    anchorEl: null,
                    isSelectedAll: true,
                    selected: [],
                    unselected: [],
                },
                () => {
                    callback && callback();
                    onSelectedChange && onSelectedChange([]);
                    onUnselectedChange && onUnselectedChange([]);
                }
            );
        });
    };

    handleSelectNoneClick = () => {
        const {
            onSelectNoneClick: callback,
            handleStateChanged: setState,
            onSelectedChange,
            onUnselectedChange,
            selected,
            isSelectedAll,
        } = this.props;

        selected.length && !!onSelectedChange && onSelectedChange([]);
        isSelectedAll && !!onUnselectedChange && onUnselectedChange([]);

        this.setState({ anchorEl: null }, () => {
            setState(
                {
                    isSelectedAll: false,
                    selected: [],
                    unselected: [],
                },
                callback && callback()
            );
        });
    };

    handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    renderHeaderRow = () => {
        const {
            classes,
            columns = [],
            selectable = true,
            isLoadingData,
            getDataOnCurrentPage,
            getSelectedDataOnCurrentPage,
        } = this.props;
        const { anchorEl } = this.state;

        let dataOnPage = getDataOnCurrentPage();
        let selectedDataOnPage = getSelectedDataOnCurrentPage();

        if (!columns || !Array.isArray(columns) || columns.length === 0)
            return null;

        if (!selectable)
            return (
                <TableRow>
                    {this.renderColumnHeaders()}
                    <TableCell />
                </TableRow>
            );

        return (
            <TableRow>
                <TableCell padding="none" className={classes.checkBoxCol}>
                    <Checkbox
                        data-cy="row-resultstable-select-all"
                        className={classes.selectAllCheckbox}
                        onChange={this.handlePageSelectChange}
                        checked={
                            selectedDataOnPage.length > 0 &&
                            selectedDataOnPage.length === dataOnPage.length
                        }
                        indeterminate={
                            selectedDataOnPage.length > 0 &&
                            selectedDataOnPage.length < dataOnPage.length
                        }
                        disabled={isLoadingData}
                    />
                    <IconButton
                        className={classes.arrowButton}
                        onClick={this.handleMenuClick}
                        disableRipple
                        disabled={isLoadingData}
                    >
                        <ArrowDownIcon data-cy="arrow-drop-down" />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMenuClose}
                        elevation={2}
                        transitionDuration={{
                            enter: 100,
                            exit: 100,
                        }}
                    >
                        <MenuItem
                            data-cy="arrow-down-select-all"
                            disableGutters
                            dense
                            onClick={this.handleSelectAllClick}
                        >
                            Select All Results
                        </MenuItem>
                        <MenuItem
                            data-cy="arrow-down-select-all"
                            disableGutters
                            dense
                            onClick={this.handleSelectNoneClick}
                        >
                            Select None
                        </MenuItem>
                    </Menu>
                </TableCell>
                {this.renderColumnHeaders()}
                <TableCell />
            </TableRow>
        );
    };

    renderColumnHeaders = () => {
        const { columns } = this.props;

        return columns.map(this.renderColumnCell);
    };

    renderColumnCell = (column, key) => {
        const { name, sort } = column;
        const { onColumnHeaderClick } = this.props;
        const datacy = name.replace(/\s+/g, '-');
        return (
            <TableCell
                key={`accounts-table-header-${key}`}
                sortDirection={sort}
                data-cy={`header-${datacy}`}
            >
                <TableSortLabel
                    direction={sort}
                    active={!!sort}
                    onClick={() =>
                        !!onColumnHeaderClick &&
                        onColumnHeaderClick(column, key)
                    }
                    hideSortIcon
                >
                    {name}
                </TableSortLabel>
            </TableCell>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <TableHead className={classes.tablehead}>
                {this.renderHeaderRow()}
            </TableHead>
        );
    }
}

export default withState(usedStates)(
    withProps(usedProps)(withStyles(styles)(ResultsTableHead))
);
