import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from 'prop-types';

class SearchBasedDropdown extends Component {
    state = {
        options: [],
        arrayOptions: []
    };

    componentDidMount = () => {
        const { valueKey, labelKey, arrayOptions } = this.props;
        let options = arrayOptions.map((option, index) => ({
            value: option[valueKey] ? option[valueKey] : index,
            label: option[labelKey] ? option[labelKey] : `Label ${index}`
        }));

        this.setState({ arrayOptions: this.props.arrayOptions, options });
    };

    componentWillReceiveProps = (props) => {
        if (
            JSON.stringify(this.state.arrayOptions) !==
            JSON.stringify(props.arrayOptions)
        ) {
            const { valueKey, labelKey, arrayOptions } = props;
            let options = arrayOptions.map((option, index) => ({
                value: option[valueKey] ? option[valueKey] : index,
                label: option[labelKey] ? option[labelKey] : `Label ${index}`
            }));

            this.setState({ arrayOptions: props.arrayOptions, options });
        }
    };

    filterOptions = (inputValue) => {
        let { options } = this.state;
        const limit = this.getLimit(options.length);

        return (
            options &&
            options
                .filter((i) =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, limit)
        );
    };

    loadOptions = (inputValue) => {
        const { timeout = 0 } = this.props;
        return new Promise((resolve) =>
            setTimeout(() => resolve(this.filterOptions(inputValue)), timeout)
        );
    };

    getLimit = (arrayLength) => {
        return arrayLength > 1000 ? 1000 : arrayLength;
    };

    render() {
        const {
            arrayOptions,
            value,
            handleChange,
            placeholder,
            valueKey,
            labelKey,
            isClearable,
            isDisabled,
            autoFocus,
            isSearchable,
            customStyles,
            className,
            isMultiSelect,
            ...rest
        } = this.props;

        const limit = this.getLimit(arrayOptions.length);
        const { options } = this.state;

        return (
            <AsyncSelect
                styles={customStyles}
                cacheOptions
                value={value}
                autoFocus={autoFocus}
                loadOptions={this.loadOptions}
                defaultOptions={options.slice(0, limit)}
                onChange={handleChange}
                placeholder={placeholder}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                className={className}
                isMulti={isMultiSelect}
                {...rest}
            />
        );
    }
}

SearchBasedDropdown.defaultProps = {
    value: null,
    arrayOptions: [],
    isClearable: false,
    isDisabled: false,
    labelKey: 'label',
    valueKey: 'value',
    isSearchable: true
};

SearchBasedDropdown.propTypes = {
    arrayOptions: PropTypes.array.isRequired,
    valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool
};

export default SearchBasedDropdown;
