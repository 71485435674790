import React from 'react';
import StatsTableRow from './StatsTableRow';
import { withState, withProps } from './StatsTableProvider';
import { TableBody, withStyles, TableRow, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const styles = (theme) => ({
    rowLabel: {
        minWidth: '150px'
    },
    checkbox: {
        width: '70px'
    },
    rowValue: {
        fontWeight: 'bold',
        color: '#555'
    },
    rowPercentage: {
        color: '#aaa',
        fontSize: '11px'
    },
    compareLabel: {
        minWidth: '150px',
        fontWeight: 'bold'
    },
    compareCheckbox: {
        padding: '0 1em 11em'
    },
    changeLabel: {
        fontWeight: 'bold'
    },
    positiveRate: {
        color: '#4caf50',
        fontWeight: 'bold'
    },
    negativeRate: {
        color: '#f44336',
        fontWeight: 'bold'
    }
});

const usedStates = ['page', 'rowsPerPage'];
const usedProps = ['isLoading', 'columns', 'data'];

const StatsTableBody = ({
    isLoading,
    rowsPerPage,
    data = [],
    columns = [],
    page,
    classes
}) => {
    let totals = {};
    let compare_totals = {};
    let mappedData = data.map((row) => {
        columns
            .filter((column) => {
                return column.isShown;
            })
            //eslint-disable-next-line array-callback-return
            .map((column) => {
                if (!column.subcolumns) {
                    totals[column.key] = !totals[column.key]
                        ? row[column.key]
                        : totals[column.key] + row[column.key];
                    if (!!row.compare) {
                        compare_totals[column.key] = !compare_totals[column.key]
                            ? row.compare[column.key]
                            : compare_totals[column.key] +
                              row.compare[column.key];
                    }
                } else {
                    //eslint-disable-next-line array-callback-return
                    column.subcolumns.map((subcolumn) => {
                        totals[subcolumn.key] = !totals[subcolumn.key]
                            ? row[subcolumn.key]
                            : totals[subcolumn.key] + row[subcolumn.key];

                        if (!!row.compare && !!row.compare[subcolumn.key]) {
                            compare_totals[subcolumn.key] = !compare_totals[
                                subcolumn.key
                            ]
                                ? row.compare[subcolumn.key]
                                : compare_totals[subcolumn.key] +
                                  row.compare[subcolumn.key];
                        }
                    });
                }
            });
        return row;
    });

    if (isLoading)
        return (
            <TableBody>
                <TableRow>
                    <TableCell className={classes.checkbox}></TableCell>
                    {columns
                        .filter(({ isShown }) => {
                            return isShown;
                        })
                        .map(() => {
                            return (
                                <TableCell>
                                    <Skeleton
                                        variant="rec"
                                        height={20}
                                        width={80}
                                        style={{
                                            borderRadius: 10,
                                            margin: `0 5px`
                                        }}
                                    />
                                </TableCell>
                            );
                        })}
                </TableRow>
            </TableBody>
        );

    return (
        <TableBody>
            {mappedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    return (
                        <StatsTableRow
                            row={row}
                            key={`stats-table-row-${index}-${row.label}`}
                            totals={totals}
                            compare_totals={compare_totals}
                        />
                    );
                })}
        </TableBody>
    );
};

export default withState(usedStates)(
    withProps(usedProps)(withStyles(styles)(StatsTableBody))
);
