import React, { Fragment, useState, useEffect, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { isObject, has } from 'lodash';
import { Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useDidUpdateEffect } from '../../utilities/customHooks';

const useStyles = makeStyles((theme) => ({
    paper: {
        overflowY: 'visible'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: 300
    },
    datepicker: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            display: 'flex',
            marginBottom: theme.spacing(1)
        },
        '& > div > div:first-child': {
            marginRight: 20
        }
    },
    pickerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'inherit',
        '& label': {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 12,
            lineHeight: 1,
            padding: '0 0 .5em'
        }
    },
    reactdp: {
        padding: '.5em 1em',
        border: '1px solid #ddd',
        fontSize: 16,
        borderRadius: 3,
        fontFamily: 'inherit',
        color: '#333',
        maxWidth: 200
    }
}));

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const strDate = (date) => {
    return typeof date.getMonth === 'function'
        ? format(date, 'YYYY-MM-DD')
        : date;
};

const DatePicker = ({
    onChange = () => { },
    format: dateFormat = 'MM/dd/yyyy',
    value = '',
    alterValue = { value: '', label: '', disableDate: true },
    id
}) => {
    const hasAlterValue = !!alterValue.value;
    const startDate =
        isObject(value) && has(value, 'start')
            ? new Date(`${strDate(value.start)}T00:00:00.000`)
            : new Date();
    const endDate =
        isObject(value) && has(value, 'end')
            ? new Date(`${strDate(value.end)}T00:00:00.000`)
            : new Date();
    const isRangeValue =
        isObject(value) && has(value, 'start') && has(value, 'end')
            ? true
            : false;

    const formatDatePicked = (isRange, start, end, formatter) => {
        return isRange
            ? `${format(new Date(start), formatter)} to ${format(
                new Date(end),
                formatter
            )}`
            : format(new Date(start), formatter);
    };
    const datePickedValue =
        isObject(value) && (has(value, 'start') || has(value, 'end'))
            ? formatDatePicked(isRangeValue, startDate, endDate, 'MM/DD/YYYY')
            : '';

    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);
    const [isRange, setIsRange] = useState(isRangeValue);
    const [open, setOpen] = useState(false);
    const [datePicked, setDatePicked] = useState(datePickedValue);
    const [formatter, setFormatter] = useState('MM/DD/YYYY');
    const [enableAlter, setEnableAlter] = useState(false);
    const classes = useStyles();

    const handleIsRangeChange = (e) => setIsRange(e.target.checked);

    const handleDateChange = (field) => (date) => {
        if (field === 'start') {
            const d = new Date(date);
            setStart(d);
            if (isRange && d > endDate) {
                setEnd(d);
            }
        }
        if (field === 'end') setEnd(new Date(date));
    };

    const handleSubmit = useCallback(() => {
        const datePicked = formatDatePicked(isRange, start, end, formatter);
        setDatePicked(
            enableAlter && alterValue.disableDate
                ? alterValue.label
                : datePicked
        );
        setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableAlter, isRange, start, end]);

    useDidUpdateEffect(() => {
        const dateValue = isRange
            ? {
                start: format(start, 'YYYY-MM-DD'),
                end: format(end, 'YYYY-MM-DD')
            }
            : { start: format(start, 'YYYY-MM-DD') };

        const otherValue = enableAlter ? { alterValue: alterValue.value } : {};

        const combinedValue = {
            ...(alterValue.disableDate && enableAlter ? {} : dateValue),
            ...otherValue
        };
        onChange(combinedValue);
    }, [datePicked]);

    useEffect(() => {
        let [separator] = dateFormat.replace(/[mMdDyY]/gm, '');
        let formats = dateFormat
            .split(/[/-]/)
            .map((f) => f.toUpperCase())
            .join(separator || '-');
        setFormatter(formats);
    }, [dateFormat]);

    useEffect(() => {
        setEnableAlter(!!value.alterValue);
        if (!!value.alterValue && alterValue.disableDate) {
            setDatePicked(alterValue.label);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const textValue =
        hasAlterValue && enableAlter && alterValue.disableDate
            ? alterValue.label || 'Alternate Value'
            : datePicked;

    return (
        <Fragment>
            <TextField
                margin="none"
                fullWidth
                onClick={() => setOpen(true)}
                value={textValue}
                onChange={() => setOpen(true)}
                data-cy={id}
                id="date-column-filter"
            />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                classes={{ paper: classes.paper }}
                data-cy="date-dialog"
            >
                <DialogTitle disableTypography className={classes.header}>
                    <Typography variant="subheading">Choose a date</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                data-cy="date-range-toggle"
                                color="primary"
                                onChange={handleIsRangeChange}
                                checked={isRange}
                                disabled={
                                    hasAlterValue &&
                                    enableAlter &&
                                    alterValue.disableDate
                                }
                            />
                        }
                        label="Date Range"
                    />
                </DialogTitle>
                <DialogContent className={classes.datepicker}>
                    <div>
                        <div className={classes.pickerWrapper}>
                            {isRange && <label>Start</label>}
                            <ReactDatePicker
                                id="date-picker"
                                selected={new Date(start)}
                                showMonthDropdown
                                showYearDropdown
                                dateFormat={dateFormat}
                                className={classes.reactdp}
                                onChange={handleDateChange('start')}
                                disabled={
                                    hasAlterValue &&
                                    enableAlter &&
                                    alterValue.disableDate
                                }
                            />
                        </div>
                        {isRange && (
                            <div className={classes.pickerWrapper}>
                                <label>End</label>
                                <ReactDatePicker
                                    selected={new Date(end)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat={dateFormat}
                                    className={classes.reactdp}
                                    onChange={handleDateChange('end')}
                                    minDate={new Date(start)}
                                    disabled={
                                        hasAlterValue &&
                                        enableAlter &&
                                        alterValue.disableDate
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {hasAlterValue && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="primary"
                                    onChange={({ target: { checked } }) =>
                                        setEnableAlter(checked)
                                    }
                                    inputProps={{ style: { zIndex: 0 } }}
                                />
                            }
                            label="Offered Year Round"
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        data-cy="submit-date"
                        onClick={handleSubmit}
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DatePicker;
