import React, { useEffect, useState } from 'react';
import ResultsTable from '../../components/ResultsTable/ResultsTable';
import { DIRECTORIES } from '../../utilities/directory';
import { buildFormData, trimSlash } from '../../helpers/dataUtility';
import {
    useScholarshipState,
    useScholarshipMethods
} from './ScholarshipProvider';
import { useGlobalState, useGlobalMethods } from '../Client/GlobalProvider';
import {
    useDidUpdateEffect,
    useObjectState
} from '../../utilities/customHooks';
import getCriteriaByDirectory, {
    fetchCriteriaOptions
} from '../../utilities/searchCriteria';
import CustomGlobalFilterDrawer from '../../components/CustomGlobalFilter/CustomGlobalFilterDrawer';
import DialogBox from '../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import HomeIcon from '@material-ui/icons/Home';
import DirectoryIcon from '@material-ui/icons/StarsRounded';
import { makeStyles, Tooltip } from '@material-ui/core';
import { blue, yellow } from '@material-ui/core/colors';
import MultiSelect from '@khanacademy/react-multi-select';
import { useSnackbar } from 'notistack';
import { postRequest } from '../../helpers/apiRequestUtility';
import { GA_URL } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
    homepage: {
        color: blue[500]
    },
    directory: {
        color: yellow[700]
    },
    row: {
        '& td': {
            width: 150,
            minWidth: 120
        },
        '& td:nth-child(1)': {
            width: 50,
            minWidth: 50
        },
        '& td:nth-child(2)': {
            width: 300,
            minWidth: 300
        },
        '& td:nth-child(6)': {
            width: 100,
            minWidth: 100
        },
        '& td:nth-child(7)': {
            width: 100,
            minWidth: 100
        },
        '& td:last-child': {
            width: 60,
            minWidth: 60
        }
    },
    dialogContent: {
        '& .MuiDialog-paper': {
            overflowY: 'visible'
        },
        '& .MuiDialogContent-root': {
            overflowY: 'visible'
        }
    }
}));

const ResultsMain = ({ active }) => {
    const classes = useStyles();
    const {
        data,
        count,
        page,
        rowsPerPage,
        fetching,
        filters,
        sort,
        sortOrder,
        setScholarshipState,
        showGlobalFilters,
        showColumnFilters
    } = useScholarshipState();
    const { fetchScholars } = useScholarshipMethods();
    const { enqueueSnackbar } = useSnackbar();
    const {
        locations,
        fetchingLocations,
        handleStateChange: setGlobalState
    } = useGlobalState();
    const { fetchLocations } = useGlobalMethods();
    const [state, setState] = useObjectState({
        award_name: '',
        directory_id: [],
        location: [],
        types: [],
        type: null,
        date_created: '',
        deadline_date: '',
        fetchingTypes: false,
        is_featured_in_directory: null,
        is_featured_in_homepage: null,
        is_active: null,
        isSaving: false,
        selected: [],
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: ''
        },
        selectedRow: {},
        showUpdateDialog: false,
        updateTitle: ''
    });
    const [typesOptions, setTypeOptions] = useObjectState({});
    const [typePromise, setTypePromise] = useObjectState({});
    const [directory, setDirectory] = useState([]);
    const [featuredDirectories, setfFeaturedDirectories] = useState([]);

    const columns = [
        {
            name: 'Scholarship Title',
            key: 'award_name',
            type: 'text',
            format: 'bold',
            filter: {
                type: 'text',
                value: state.award_name,
                onChange: ({ target: { value } }) =>
                    setState({ award_name: value })
            }
        },
        {
            name: 'Directory',
            key: 'directoryNames',
            filter: {
                type: 'multiselect',
                optionKey: 'name',
                optionValue: 'id',
                options: [...DIRECTORIES].filter(
                    (directory) =>
                        !!directory.page_number_tag || directory.abbrv === 'GA'
                ),
                value: state.directory_id,
                handler: (value) => {
                    setState({ directory_id: value });
                }
            }
        },
        {
            name: 'Location',
            key: 'locations',
            filter: {
                type: 'select',
                optionKey: 'name',
                optionValue: 'id',
                isLoading: fetchingLocations,
                options: locations,
                value: state.location,
                isMultiSelect: true,
                onChange: (value) => {
                    const country_id = (value || [])
                        .filter((location) => location.value.startsWith('2_'))
                        .map((location) => {
                            //eslint-disable-next-line no-unused-vars
                            const [bv, criteria_value] =
                                location.value.split('_');
                            return criteria_value;
                        });

                    const city_id = (value || [])
                        .filter((location) => location.value.startsWith('16_'))
                        .map((location) => {
                            //eslint-disable-next-line no-unused-vars
                            const [bv, criteria_value] =
                                location.value.split('_');
                            return criteria_value;
                        });

                    setState({ location: value, country_id, city_id });
                }
            }
        },
        {
            name: 'Type',
            key: 'types',
            filter: {
                type: 'multiselect',
                options: state.types,
                value: state.type,
                isDisabled:
                    (state.directory_id || []).length === 0 ||
                    state.fetchingTypes,
                isLoading: state.fetchingTypes,
                handler: (selected) => {
                    const values = {};
                    //eslint-disable-next-line array-callback-return
                    (selected || []).map((val) => {
                        const [criteria, value] = val.split('=');
                        values[criteria] = [...(values[criteria] || []), value];
                    });
                    setState((prev) => {
                        const otherTypes = {};
                        [
                            'volunteer_type',
                            'intern_type',
                            'degree_program',
                            'language',
                            'adventure_travel_type',
                            'highschool_type',
                            'gap_year_type'
                            //eslint-disable-next-line array-callback-return
                        ].map((criterion) => {
                            if (
                                !Object.keys(selected || {}).includes(
                                    criterion
                                ) &&
                                Object.keys(prev || {}).includes(criterion)
                            ) {
                                otherTypes[criterion] = [];
                            }
                        });
                        return {
                            ...prev,
                            type: selected,
                            ...otherTypes,
                            ...values
                        };
                    });
                }
            }
        },
        {
            name: 'Date Created',
            key: 'date_created',
            type: 'date',
            sort: sort === 'date_created' ? sortOrder : undefined,
            filter: {
                type: 'date',
                value: state.date_created,
                onChange: (value) => {
                    setState({ date_created: value });
                }
            }
        },
        {
            name: 'Deadline',
            key: 'deadline_date',
            type: 'text',
            sort: sort === 'deadline_date' ? sortOrder : undefined,
            filter: {
                type: 'date',
                value: state.deadline_date,
                alterValue: {
                    value: 'offered_year_round',
                    label: 'Offered Year Round',
                    disableDate: true
                },
                onChange: (value) => {
                    setState({ deadline_date: value });
                }
            }
        }
    ];

    const rowMenus = [
        {
            name: 'view',
            text: 'View on Frontend'
        },
        {
            name: 'edit',
            text: 'Edit',
            handler: (row) => {
                setScholarshipState({
                    selectedScholarship: row,
                    edit: true
                });
                setGlobalState({
                    scholarshipDrawerOpen: true
                });
            }
        },
        {
            name: 'feature',
            text: 'Feature on Directory',
            handler: (row) => {
                getScholarshipDirectory(row);
                setState({
                    selectedRow: row,
                    updateTitle: 'Feature in Directory',
                    showUpdateDialog: true
                });
            }
        },
        {
            name: 'feature-homepage',
            text: 'Feature on Homepage',
            handler: (row) => {
                handleShowActionConfirmation('feature in homepage', () =>
                    featureScholarshipsHomepage('feature_homepage', row)
                );
            }
        },
        {
            name: 'unfeature-homepage',
            text: 'Unfeature on Homepage',
            handler: (row) => {
                handleShowActionConfirmation('unfeature in homepage', () =>
                    featureScholarshipsHomepage('unfeature_homepage', row)
                );
            }
        },
        {
            name: 'delete',
            text: 'Delete',
            handler: (row) => {
                handleShowActionConfirmation('delete', () =>
                    handleDeleteScholarships(false, row)
                );
            }
        }
    ];

    const getScholarshipDirectory = (row) => {
        const directory = row.directories
            .filter((directory) => directory.id !== 10)
            .map((l) => {
                return {
                    value: l.id,
                    label: l.name
                };
            });
        setDirectory(directory);

        const featuredDirectories = row.directories
            .filter((directory) => directory.id !== 10)
            .filter((directory) => directory.is_featured_in_directory === 1)
            .map((l) => {
                return l.id;
            });
        setfFeaturedDirectories(featuredDirectories);
    };

    const icons = [
        {
            name: 'homepage_feature',
            icon: (
                <Tooltip title="Featured in Homepage" placement="top">
                    <HomeIcon fontSize="small" className={classes.homepage} />
                </Tooltip>
            ),
            placement: 'right',
            column: 'award_name'
        },
        {
            name: 'directory_feature',
            icon: (
                <Tooltip title="Featured in Directory" placement="top">
                    <DirectoryIcon
                        fontSize="small"
                        className={classes.directory}
                    />
                </Tooltip>
            ),
            placement: 'right',
            column: 'award_name'
        }
    ];

    const rowClasses = [{ className: classes.row }];

    const batchActions = [
        {
            icon: 'delete',
            handler: () =>
                handleShowActionConfirmation('delete', () =>
                    handleDeleteScholarships(true)
                )
        }
    ];

    const rowMenuChecker = (row, menus) =>
        menus
            .filter((menu) => {
                const deadline_passed =
                    row.deadline_date !== 'Offered Year Round' &&
                    new Date(row.deadline_date).getTime() < Date.now();
                if (menu.name === 'view') return !deadline_passed;
                if (menu.name === 'feature') return !deadline_passed;
                if (menu.name === 'feature-homepage')
                    return !row.is_featured_in_homepage && !deadline_passed;
                if (menu.name === 'unfeature-homepage')
                    return row.is_featured_in_homepage && !deadline_passed;
                return true;
            })
            .map((menu) => {
                if (
                    menu.name === 'view' &&
                    row.directories &&
                    row.directories.length > 0
                ) {
                    const dirs = DIRECTORIES.filter((item) => {
                        return item.id === row.directories[0].id;
                    });
                    const alias = !!dirs ? dirs[0].alias : '';
                    menu.url = `${trimSlash(
                        GA_URL
                    )}/scholarships-abroad/${alias}`;
                    menu.target = '_blank';
                }
                return menu;
            });

    const iconsChecker = (row, icons) => {
        icons = icons
            .filter((icon) => {
                if (icon.name === 'homepage_feature')
                    return row.is_featured_in_homepage === 1;
                if (icon.name === 'directory_feature')
                    return row.is_featured_in_directory;
                return true;
            })
            .map((icon) => {
                if (
                    icon.name === 'directory_feature' &&
                    row.is_featured_in_directory
                ) {
                    icon.icon = (
                        <Tooltip
                            title={`Featured in Directory: ${row.featuredDirectories}`}
                            placement="top"
                        >
                            <DirectoryIcon
                                fontSize="small"
                                className={classes.directory}
                            />
                        </Tooltip>
                    );
                }
                return icon;
            });

        return icons;
    };

    const searchOptions = [
        {
            name: 'Contact Email',
            key: 'contact_email',
            hasAutoSuggest: false
        }
    ];

    const featureScholarshipDirectory = () => {
        const data = new FormData();
        let scholarship = state.selectedRow;
        let selectedId = [scholarship.id];

        const unfeaturedDirectories = directory
            .map((l) => {
                return l.value;
            })
            .filter((x) => !featuredDirectories.includes(x));

        data.append('id', selectedId);

        buildFormData(data, {
            feature_directory_id: featuredDirectories,
            unfeature_directory_id: unfeaturedDirectories
        });

        setState({
            selectedRow: {},
            updateTitle: '',
            showUpdateDialog: false
        });
        setDirectory([]);
        setfFeaturedDirectories([]);

        sendUpdateRequest(selectedId, data);
    };

    const featureScholarshipsHomepage = (action, scholarship) => {
        const data = new FormData();
        let selectedId = [scholarship.id];

        data.append('id', selectedId);

        if (action === 'feature_homepage')
            data.append('is_feature_homepage', 1);
        else if (action === 'unfeature_homepage')
            data.append('is_feature_homepage', 0);

        setState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        sendUpdateRequest(selectedId, data);
    };

    const sendUpdateRequest = (selectedId, data) => {
        if (selectedId.length) {
            setState({
                isSaving: true,
                fetching: true
            });

            postRequest(
                `/scholarships/${selectedId}/feature`,
                data,
                (success) => {
                    setState({ isSaving: false });
                    enqueueSnackbar(`Scholarship has been updated!`, {
                        variant: 'success'
                    });
                    fetchScholars();
                },
                (error) => {
                    setState({ isSaving: false, fetching: false });
                    enqueueSnackbar('Failed to update Scholarship.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Scholarships have been updated.', {
                variant: 'error'
            });
        }
    };

    const handleDeleteScholarships = async (isBatch, scholarship) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch
            ? selected.map((row) => row.id)
            : [scholarship.id];

        data.append('id', selectedIds.join());

        setState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            setState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/scholarships/delete`,
                data,
                (success) => {
                    setState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Scholarships have been deleted!`,
                        {
                            variant: 'success'
                        }
                    );
                    fetchScholars();
                },
                (error) => {
                    setState({ isSaving: false, fetching: false });
                    enqueueSnackbar('Failed to delete Scholarships.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Scholarships have been deleted.', {
                variant: 'error'
            });
        }
    };

    const handleShowActionConfirmation = (batchAction, func) => {
        setState({
            dialog: {
                show: true,
                title: batchAction,
                content: `Are you sure you want to ${batchAction} the selected scholarship(s)?`,
                stringOverride: {
                    primaryAction: batchAction,
                    secondaryAction: 'Cancel'
                },
                onOk: func
            }
        });
    };

    const handleCloseDialog = () => {
        setState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {}
            }
        });
    };

    const handleClearUpdateDialog = () => {
        setState({
            selectedRow: {},
            updateTitle: '',
            showUpdateDialog: false
        });
        setDirectory([]);
        setfFeaturedDirectories([]);
    };

    const handleSelectMultiple = (selected) => {
        setState({
            selected
        });
    };

    const handlePageChange = (page) => {
        setScholarshipState({ page });
    };

    const handleRowsPerPageChange = (rpp) => {
        setScholarshipState({ rowsPerPage: rpp });
    };

    const handleColumnFiltersOpen = () => {
        setScholarshipState({ showColumnFilters: true });
    };

    const handleColumnFilterClose = () => {
        setState({
            award_name: '',
            directory_id: [],
            location: null,
            types: [],
            type: null,
            date_created: '',
            deadline_date: '',
            city_id: null,
            country_id: null,
            volunteer_type: null,
            intern_type: null,
            degree_program: null,
            language: null,
            adventure_travel_type: null,
            highschool_type: null,
            gap_year_type: null
        });
        // to do: filter actual data
        setScholarshipState({ filters: {}, showColumnFilters: false });
    };

    const handleColumnFiltersApply = () => handleApplyFilter();

    const handleGlobalFiltersApply = (filters) => {
        setState({
            is_featured_in_directory: filters.is_featured_in_directory || null,
            is_featured_in_homepage: filters.is_featured_in_homepage || null,
            is_active: filters.is_active || null
        });
        handleToggleGlobalFilters(false);
    };

    const handleClearGlobalFilter = () => {
        setState({
            is_featured_in_directory: null,
            is_featured_in_homepage: null,
            is_active: null
        });
        handleToggleGlobalFilters(false);
    };

    const handleApplyFilter = () => {
        setScholarshipState({
            filters: {
                award_name: state.award_name,
                directory_id: state.directory_id,
                date_created: state.date_created,
                deadline_date: state.deadline_date,
                country_id: state.country_id,
                city_id: state.city_id,
                is_featured_in_directory: state.is_featured_in_directory,
                is_featured_in_homepage: state.is_featured_in_homepage,
                volunteer_type: state.volunteer_type,
                intern_type: state.intern_type,
                degree_program: state.degree_program,
                language: state.language,
                adventure_travel_type: state.adventure_travel_type,
                highschool_type: state.highschool_type,
                gap_year_type: state.gap_year_type,
                contact_email: state.contact_email,
                is_active: state.is_active
            },
            page: 0
        });
    };

    const handleToggleGlobalFilters = (open) =>
        setScholarshipState({ showGlobalFilters: open });

    const handleSearchChange = (data) => {
        if (data.filter && data.value) {
            setState({
                [data.filter]: data.value
            });
        }
    };

    const handleSearchClose = () => {
        setState({ contact_email: null });
    };

    const handleSorting = ({ key, sort: sortDirection }) => {
        if (['date_created', 'deadline_date'].includes(key)) {
            setScholarshipState({
                sort: key,
                sortOrder:
                    sort === key && sortDirection === 'desc' ? 'asc' : 'desc'
            });
        }
    };

    const cancelFetchingTypes = (type) => {
        if (typePromise[type]) typePromise[type].cancel();
        setTypePromise({ [type]: null });
    };

    const setFetchTypePromise = (type) => {
        if (!!typePromise[type]) return;

        const cancelablePromise = () => {
            let cancel = () => {};
            const promise = new Promise(async (resolve, reject) => {
                cancel = reject;
                const criteria_options = (
                    (await fetchCriteriaOptions(type)) || []
                ).map(({ value, label }) => ({
                    id: `${type}=${value}`,
                    name: label,
                    value: `${type}=${value}`,
                    label
                }));
                setTypeOptions({
                    [type]: criteria_options
                });
                setTypePromise({ [type]: null });
            });
            promise.cancel = cancel;
            return promise;
        };
        setTypePromise({ [type]: cancelablePromise() });
    };

    useDidUpdateEffect(() => {
        handleApplyFilter();
    }, [
        state.is_featured_in_directory,
        state.is_featured_in_homepage,
        state.contact_email,
        state.is_active
    ]);

    useEffect(() => {
        if (showColumnFilters && !locations.length && !fetchingLocations)
            fetchLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showColumnFilters, locations, fetchLocations]);

    useEffect(() => {
        setState({ deadline_date: filters.deadline_date });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.deadline_date]);

    useDidUpdateEffect(() => {
        const types = getCriteriaByDirectory({
            key: 'id',
            val: state.directory_id
        });
        const criteria = [
            ...new Set(
                Object.keys(types || {})
                    .map((directory_id) => {
                        const { new_criteria } = types[directory_id].type || {};
                        return new_criteria;
                    })
                    .filter((c) => !!c)
            )
        ];

        //eslint-disable-next-line array-callback-return
        criteria.map((criterion) => {
            if ((typesOptions[criterion] || []).length === 0)
                setFetchTypePromise(criterion);
        });

        //eslint-disable-next-line array-callback-return
        Object.keys(typePromise).map((key) => {
            if (!criteria.includes(key) && !!typePromise[key]) {
                cancelFetchingTypes(key);
            }
        });
    }, [state.directory_id]);

    useEffect(() => {
        const hasActive = Object.keys(typePromise).some(
            (key) => !!typePromise[key]
        );
        setState({ fetchingTypes: hasActive });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typePromise]);

    useDidUpdateEffect(() => {
        if (state.directory_id.length && !state.fetchingTypes) {
            let options = [];
            const types = getCriteriaByDirectory({
                key: 'id',
                val: state.directory_id
            });
            [
                ...new Set(
                    Object.keys(types || {})
                        .map((directory_id) => {
                            const { new_criteria } =
                                types[directory_id].type || {};
                            return new_criteria;
                        })
                        .filter((c) => !!c)
                )
                //eslint-disable-next-line array-callback-return
            ].map((criterion) => {
                options = [...options, ...(typesOptions[criterion] || [])];
            });
            setState({ types: options });
        } else if (!state.directory_id.length) {
            setState({ types: [] });
        }
    }, [state.directory_id, state.fetchingTypes]);

    useEffect(() => {
        const typesValues = (state.types || []).map(({ value }) => value);
        const selectedTypes = (state.type || []).filter((type) =>
            typesValues.includes(type)
        );
        setState({ type: selectedTypes });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.types]);

    useEffect(() => {
        const criteria = [
            'volunteer_type',
            'intern_type',
            'degree_program',
            'language',
            'adventure_travel_type',
            'highschool_type',
            'gap_year_type'
        ];
        const emptyValues = {};
        //eslint-disable-next-line array-callback-return
        Object.keys(state).filter((key) => {
            if (criteria.includes(key)) {
                const hasValidValues = (state[key] || []).some((value) =>
                    (state.type || []).includes(`${key}=${value}`)
                );
                if (!hasValidValues) emptyValues[key] = null;
            }
        });
        setState((prev) => ({ ...prev, ...emptyValues }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.type]);

    if (!active) return null;

    const activeGlobalFilters =
        !!state.is_featured_in_directory ||
        !!state.is_featured_in_homepage ||
        !!state.is_active;

    return (
        <>
            <ResultsTable
                label={data.length > 1 ? 'scholarships' : 'scholarship'}
                isLoadingData={fetching}
                data={data}
                columns={columns}
                count={count || data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                batchActions={batchActions}
                icons={icons}
                iconsChecker={iconsChecker}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenuChecker}
                rowClasses={rowClasses}
                showColumnFilters={showColumnFilters}
                searchFilterOptions={searchOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                selected={state.selected}
                onSelectedChange={handleSelectMultiple}
                onColumnFilterOpen={handleColumnFiltersOpen}
                onColumnFilterClose={handleColumnFilterClose}
                onColumnFiltersApply={handleColumnFiltersApply}
                onSearchFilterChange={handleSearchChange}
                onSearchFilterClose={handleSearchClose}
                onGlobalFiltersClick={() => handleToggleGlobalFilters(true)}
                onColumnHeaderClick={handleSorting}
                activeGlobalFilters={activeGlobalFilters}
                onRowClick={(row) => {
                    setScholarshipState({
                        selectedScholarship: row,
                        edit: true
                    });
                    setGlobalState({
                        scholarshipDrawerOpen: true
                    });
                }}
            />
            <CustomGlobalFilterDrawer
                open={showGlobalFilters}
                onClose={() => handleToggleGlobalFilters(false)}
                onClear={handleClearGlobalFilter}
                filters={[
                    {
                        key: 'is_featured_in_directory',
                        type: 'switch',
                        filterLabel: 'Featured On Directory',
                        options: [
                            {
                                value: '0',
                                label: 'Not Featured'
                            },
                            {
                                value: '1',
                                label: 'Featured'
                            }
                        ]
                    },
                    {
                        key: 'is_featured_in_homepage',
                        type: 'switch',
                        filterLabel: 'Featured On Homepage',
                        options: [
                            {
                                value: '0',
                                label: 'Not Featured'
                            },
                            {
                                value: '1',
                                label: 'Featured'
                            }
                        ]
                    },
                    {
                        key: 'is_active',
                        type: 'switch',
                        filterLabel: 'Active',
                        options: [
                            {
                                value: '0',
                                label: 'Inactive'
                            },
                            {
                                value: '1',
                                label: 'Active'
                            }
                        ]
                    }
                ]}
                onApply={handleGlobalFiltersApply}
            />
            <DialogBox
                actions="OkCancel"
                title={state.dialog.title.toUpperCase()}
                contentText={state.dialog.content}
                open={state.dialog.show}
                onOk={state.dialog.onOk}
                onCancel={handleCloseDialog}
                onClose={handleCloseDialog}
                stringOverride={state.dialog.stringOverride}
            />
            <DialogBox
                className={classes.dialogContent}
                actions="OkCancel"
                title={state.updateTitle.toUpperCase()}
                open={state.showUpdateDialog}
                onOk={() => {
                    featureScholarshipDirectory();
                }}
                onCancel={handleClearUpdateDialog}
                onClose={handleClearUpdateDialog}
                stringOverride={{
                    primaryAction: 'Save',
                    secondaryAction: 'Cancel'
                }}
            >
                {
                    <div>
                        <div>Select the directories:</div>
                        <div>
                            <MultiSelect
                                options={directory}
                                selected={featuredDirectories}
                                onSelectedChanged={(selected) => {
                                    setfFeaturedDirectories(selected);
                                }}
                                overrideStrings={{
                                    selectSomeItems: 'Select directories...',
                                    allItemsAreSelected:
                                        'All directories are selected'
                                }}
                            />
                        </div>
                    </div>
                }
            </DialogBox>
            <DialogBox
                open={state.isSaving}
                title={'Saving Changes...'}
                actions=""
            >
                <LinearProgress />
            </DialogBox>
        </>
    );
};

export default ResultsMain;
