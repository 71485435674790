import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { ScholarshipRankingContext } from '../../../context';
import { useObjectState } from '../../../utilities/customHooks';
import {
    constructHttpParams,
    isEmptyDate
} from '../../../helpers/dataUtility';
import { sendRequest, postRequest } from '../../../helpers/apiRequestUtility';
import { map } from 'lodash';

const { Provider } = ScholarshipRankingContext;

const ScholarshipRankingProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useObjectState({
        data: [],
        sortOrder: 'desc',
        sort: 'directory_rank',
        reorder: false,
        fetching: false
    });

    const fetchHomepageFeatures = () => {
        const { sortOrder, sort } = state;
        const params = constructHttpParams({
            directory_id: 10,
            is_featured_in_homepage: 1,
            is_active: 1,
            offset: 0,
            limit: -1,
            sort: `${sortOrder === 'asc' ? '+' : '-'}${sort},${
                sortOrder === 'asc' ? '-' : '+'
            }id`
        });

        setState({ fetching: true });

        sendRequest(
            `/scholarships?${params}`,
            ({ scholarships }) => {
                const data = [...scholarships].map((scholarship) => {
                    if (isEmptyDate(scholarship.deadline_date))
                        scholarship.deadline_date = 'Offered Year Round';

                    return scholarship;
                });
                sortData(data);
            },
            () => {
                setState({ data: [] });
                enqueueSnackbar(
                    'Failed to fetch scholarships. Please try again.',
                    { variant: 'error' }
                );
            }
        ).finally(() => setState({ fetching: false }));
    };

    const sortData = (rawData = state.data) => {
        const { sortOrder } = state;
        setState({
            data: [...rawData].sort((a, b) => {
                if (!a.directory_rank && !!b.directory_rank) return 1;
                if (!b.directory_rank && !!a.directory_rank) return -1;
                if (sortOrder === 'desc')
                    return a.directory_rank - b.directory_rank;
                if (sortOrder === 'asc')
                    return b.directory_rank - a.directory_rank;
                return 0;
            })
        });
    };

    const handleSaveRanking = () => {
        const { temp } = state;
        temp.forEach((scholarship, index) => {
            scholarship.directory_rank = index + 1;
        });
        const mappedIds = map(temp, 'id');
        let rankIds = mappedIds.join();
        const params = constructHttpParams({ id: rankIds, directory_id: 10 });

        setState({ fetching: true, data: [], reorder: false });

        postRequest(
            `/scholarships/rank?${params}`,
            [],
            (json) => {
                if (!!json.error) {
                    setState({ reorder: true, data: temp });
                    enqueueSnackbar('Error encountered. Please try again!', {
                        variant: 'error'
                    });
                } else {
                    setState({ data: temp });
                    enqueueSnackbar('Ranking successfully updated!', {
                        variant: 'success'
                    });
                }
            },
            (error) => {
                setState({ reorder: true, data: temp });
                enqueueSnackbar('Error encountered. Please try again!', {
                    variant: 'error'
                });
            }
        ).finally(() => setState({ fetching: false }));
    };

    useEffect(() => {
        fetchHomepageFeatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        sortData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sortOrder]);

    useEffect(() => {
        if (state.reorder) setState({ sortOrder: 'desc', temp: state.data });
        else setState({ temp: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.reorder, state.data]);

    return (
        <Provider
            value={{
                state,
                setScholarshipRankingState: setState,
                handleSaveRanking
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useScholarshipRankingState = () => {
    const { state, setScholarshipRankingState } = useContext(
        ScholarshipRankingContext
    );
    return { ...state, setScholarshipRankingState };
};

export const useScholarshipRankingMethods = () => {
    const { state, ...methods } = useContext(ScholarshipRankingContext);
    return methods;
};

export default ScholarshipRankingProvider;
