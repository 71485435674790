import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CssBaseline from '@material-ui/core/CssBaseline';
import SetPasswordForm from './SetPassword/SetPasswordForm';
import NotFound from '../Error/NotFound';
import Loader from '../../components/Loader/Loader';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../config/constants';
import { withRouter } from 'react-router-dom';
import { postRequest } from '../../helpers/apiRequestUtility';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    back: {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        top: theme.spacing.unit * 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none'
        },

        '& svg': {
            fill: 'white',
            fontSize: '1em'
        }
    }
});

const SetPassword = ({ classes, location }) => {
    const [email, setEmail] = useState(null);
    const [token, setToken] = useState(null);
    const [isValid, setValid] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const validateToken = () => {
        const urlParams = new URLSearchParams(location.search);
        const inviteToken = urlParams.get('token');
        const existingPrimary = urlParams.get('existing_primary');
        const existingBilling = urlParams.get('existing_billing');
        const formData = new FormData();

        if (!inviteToken) setValid(false, setLoading(false));

        formData.append('token', inviteToken);
        !!existingPrimary &&
            formData.append('existing_primary', existingPrimary);
        !!existingBilling &&
            formData.append('existing_billing', existingBilling);

        postRequest('/verify-invite-token', formData, (result) => {
            const { invite, error } = result;
            if (error) setValid(false, setLoading(false));
            else if (invite.email) setToken(inviteToken);
            setEmail(invite.email, setValid(true, setLoading(false)));
        });
    };

    useEffect(() => {
        validateToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    // return <Loader />;

    if (isLoading) return <Loader />;

    if (!isValid) return <NotFound />;

    return (
        <Fragment>
            <CssBaseline />
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                <div className={classes.root}>
                    <div className={classes.back}>
                        <ArrowBackIos />
                        <a href="https://www.goabroad.com">Back to GoAbroad</a>
                    </div>
                    <img
                        className={classes.background}
                        src="https://images.unsplash.com/photo-1504609773096-104ff2c73ba4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
                        alt=""
                    />
                    <SetPasswordForm email={email} token={token} />
                </div>
            </GoogleReCaptchaProvider>
        </Fragment>
    );
};
export default withStyles(styles)(withRouter(SetPassword));
