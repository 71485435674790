import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { RECAPTCHA_SITE_KEY } from '../../config/constants';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ForgotPasswordForm from './ForgotPassword/ForgotPasswordForm';
import IsLoggedIn from '../../helpers/IsLoggedIn';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.875rem',
        letterSpacing: '0.01071em',

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    back: {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        top: theme.spacing.unit * 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none'
        },

        '& svg': {
            fill: 'white',
            fontSize: '1em'
        }
    }
});

const ForgotPassword = ({ classes, history }) => {
    if (IsLoggedIn()) history.push('/dashboard');
    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
            <CssBaseline />
            <div className={classes.root}>
                <div className={classes.back}>
                    <ArrowBackIos />
                    <a href="https://www.goabroad.com">Back to GoAbroad</a>
                </div>
                <img
                    className={classes.background}
                    src="https://images.unsplash.com/photo-1516546453174-5e1098a4b4af?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2991&q=80"
                    alt=""
                />
                <ForgotPasswordForm />
            </div>
        </GoogleReCaptchaProvider>
    );
};

export default withStyles(styles)(ForgotPassword);
