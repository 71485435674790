import {
    Chip,
    Fade,
    Grow,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core';
import format from 'date-fns/format';
import React from 'react';
import { PARTNER_NAMES } from '../../../config/constants';
import { useTravelResourceRankingState } from './TravelResourceRankingProvider';

const useStyles = makeStyles((theme) => ({
    root: { padding: theme.spacing(3) },
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        display: 'inline',
        marginRight: theme.spacing(1)
    },
    chip: {
        '&:not(:first-of-type)': {
            marginLeft: theme.spacing(1)
        }
    }
}));

const validFilters = ['partner_id', 'date'];

const DataFilters = () => {
    const classes = useStyles();
    const { reorder, filters, setTravelResourceRankingState } =
        useTravelResourceRankingState();

    const hanldleOpenFilterDrawer = () => {
        setTravelResourceRankingState({ filterDrawerOpen: true });
    };

    if (reorder) return null;

    const hasFilters = Object.keys(filters)
        .filter((key) => validFilters.includes(key))
        .some((key) => !!filters[key]);

    return (
        <Fade in={!reorder}>
            <Paper elevation={1}>
                <div className={classes.root}>
                    <Typography className={classes.label}>
                        Data Filters
                    </Typography>
                    {Object.keys(filters)
                        .filter(
                            (key) =>
                                validFilters.includes(key) && !!filters[key]
                        )
                        //eslint-disable-next-line array-callback-return
                        .map((key) => {
                            if (key === 'partner_id') {
                                return (
                                    <Grow in>
                                        <Chip
                                            label={PARTNER_NAMES[filters[key]]}
                                            variant="outlined"
                                            color="primary"
                                            className={classes.chip}
                                            onClick={hanldleOpenFilterDrawer}
                                        />
                                    </Grow>
                                );
                            }

                            if (key === 'date') {
                                return (
                                    <Grow in>
                                        <Chip
                                            label={format(
                                                new Date(filters[key]),
                                                'MM/YYYY'
                                            )}
                                            variant="outlined"
                                            color="primary"
                                            className={classes.chip}
                                            onClick={hanldleOpenFilterDrawer}
                                        />
                                    </Grow>
                                );
                            }
                        })}
                    <Chip
                        label={hasFilters ? 'Edit Filters' : 'Add Filters'}
                        color="primary"
                        className={classes.chip}
                        onClick={hanldleOpenFilterDrawer}
                    />
                </div>
            </Paper>
        </Fade>
    );
};

export default DataFilters;
