import Datepicker from 'react-datepicker';
import React, { Component } from 'react';
import SearchBasedDropdown from '../../components/SearchBasedDropdown/SearchBasedDropdown';
import { getYearMonthDayDateFormat } from '../../helpers/dataUtility';
import {
    FormControlLabel,
    FormControl,
    Checkbox,
    Card,
    Grid,
    Divider,
    Button,
    Drawer,
    Typography,
    withStyles,
    LinearProgress
} from '@material-ui/core';
import { GA_API } from '../../config/constants';
import DialogBox from '../../components/DialogBox/DialogBox';
import { isSameYear } from 'date-fns';

const TABWIDTH = 420;

const styles = theme => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    exportDrawer: {
        padding: `0px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`
    },
    formControl: {
        width: '100%',

        '& > span': {
            margin: '0'
        }
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',

        '& > div:not(:last-of-type)': {
            marginBottom: theme.spacing(4)
        }
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1),
        width: '310px'
    },
    export: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing.unit,
        marginTop: theme.spacing(2),
        height: 35,
        width: '310px',
        alignSelf: 'center'
    },
    drawerLabel: {
        marginBottom: theme.spacing.unit
    },
    card: {
        overflow: 'visible'
    },
    formLabel: {
        marginTop: theme.spacing(1)
    }
});

class ExportInvoicesDrawer extends Component {
    state = {
        filterBuffer: {
            range: { label: 'Month', value: 'month' },
            team_id: { label: 'Display All', value: 1 }
        },
        isAllTime: false,
        includeClientBreakdown: false
    };

    handleClearAllFilters = () => {
        this.setState({ filterBuffer: {}, includeClientBreakdown: false });
    };

    handleAddFilter = filter => value => {
        const { filterBuffer } = this.state;
        filterBuffer[filter] = value;

        this.setState({ filterBuffer });
    };

    handleIsAllTime = e => {
        this.setState({ isAllTime: e.target.checked });
    };

    handleIncludeClientBreakdown = e => {
        this.setState({ includeClientBreakdown: e.target.checked });
    };

    exportInvoices = () => {
        const { filterBuffer, isAllTime, includeClientBreakdown } = this.state;
        const { onClose } = this.props;
        const filters = {};

        this.setState({ openLoader: true });

        for (var key in filterBuffer) {
            if (Object.prototype.hasOwnProperty.call(filterBuffer, key)) {
                var val = filterBuffer[key];
                if (!(key === 'team_id' && val.value === 1))
                    filters[key] = val.value || val;
            }
        }

        filters.all_time = isAllTime ? 1 : 0;
        filters.client_breakdown = includeClientBreakdown ? 1 : 0;
        if (!isAllTime) {
            filters.start_date = getYearMonthDayDateFormat(filters.start_date);
            filters.end_date = getYearMonthDayDateFormat(filters.end_date);
        }

        const url = new URL(GA_API + `/invoices/export-report`);
        url.search = new URLSearchParams(filters).toString();
        const filename = 'Invoice-Reports.csv';

        fetch(url, {
            credentials: 'include',
            headers: {
                Accept: 'text/csv'
            }
        }).then(res => {
            res.blob().then(blob => {
                const objUrl = (window.webkitURL || window.URL).createObjectURL(
                    blob
                );

                let tempAnchor = document.createElement('a');
                tempAnchor.download = filename;
                tempAnchor.href = objUrl;
                tempAnchor.dataset.downloadurl = [
                    'text/csv',
                    tempAnchor.download,
                    tempAnchor.href
                ].join(':');

                tempAnchor.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(objUrl);
                }, 250);

                this.setState({
                    openLoader: false
                });
                this.handleClearAllFilters();
                onClose();
            });
        });
    };

    render() {
        const { classes, open, onClose } = this.props;

        const {
            filterBuffer,
            isAllTime,
            includeClientBreakdown,
            openLoader
        } = this.state;

        const divideDate = [
            { name: 'Month', value: 'month' },
            { name: 'Quarter', value: 'quarter' },
            { name: 'Year', value: 'year' }
        ];

        const compareDateRange = [
            { name: 'Previous Year', value: 1 },
            { name: 'Previous 2 Years', value: 2 },
            { name: 'Previous 5 Years', value: 3 }
        ];

        const salesTeam = [
            { name: 'Display All', value: 1 },
            { name: 'Sales Duo', value: 31 },
            { name: 'The Tycoons', value: 30 },
            { name: 'Corporate Pirates', value: 32 },
            { name: 'Sales Express', value: 29 },
            { name: 'Content Team', value: 23 }
        ];

        return (
            <Drawer
                open={open}
                anchor="right"
                onClose={onClose}
                BackdropProps={{
                    invisible: true
                }}
            >
                <div className={classes.root}>
                    <Typography className={classes.drawerTitle}>
                        Data Filters
                    </Typography>
                    <Card className={classes.card}>
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.handleIsAllTime}
                                            checked={isAllTime}
                                        />
                                    }
                                    label="All Time"
                                    className={classes.formLabel}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        Start Date
                                    </Typography>
                                    <Datepicker
                                        className={classes.datepicker}
                                        selected={filterBuffer.start_date}
                                        onChange={this.handleAddFilter(
                                            'start_date'
                                        )}
                                        dateFormat="MM/yyyy"
                                        placeholderText="Select Date"
                                        showMonthYearPicker
                                        disabled={isAllTime}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        End Date
                                    </Typography>
                                    <Datepicker
                                        className={classes.datepicker}
                                        selected={filterBuffer.end_date}
                                        onChange={this.handleAddFilter(
                                            'end_date'
                                        )}
                                        dateFormat="MM/yyyy"
                                        placeholderText="Select Date"
                                        showMonthYearPicker
                                        disabled={isAllTime}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        Divide Date Range By
                                    </Typography>
                                    <SearchBasedDropdown
                                        valueKey="value"
                                        labelKey="name"
                                        placeholder=""
                                        arrayOptions={divideDate}
                                        handleChange={this.handleAddFilter(
                                            'range'
                                        )}
                                        value={filterBuffer.range}
                                        isClearable
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        Compare Date Range With
                                    </Typography>
                                    <SearchBasedDropdown
                                        valueKey="value"
                                        labelKey="name"
                                        placeholder=""
                                        arrayOptions={compareDateRange}
                                        handleChange={this.handleAddFilter(
                                            'compare_range'
                                        )}
                                        value={filterBuffer.compare_range}
                                        isClearable
                                        isDisabled={
                                            !isSameYear(
                                                filterBuffer.start_date,
                                                filterBuffer.end_date
                                            ) || isAllTime
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    margin="normal"
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <Typography className={classes.drawerLabel}>
                                        Sales Team
                                    </Typography>
                                    <SearchBasedDropdown
                                        valueKey="value"
                                        labelKey="name"
                                        placeholder=""
                                        arrayOptions={salesTeam}
                                        handleChange={this.handleAddFilter(
                                            'team_id'
                                        )}
                                        value={filterBuffer.team_id}
                                        isClearable
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            className={classes.exportDrawer}
                            spacing={0}
                        >
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={
                                                this
                                                    .handleIncludeClientBreakdown
                                            }
                                            checked={includeClientBreakdown}
                                        />
                                    }
                                    label="Include Client Breakdown"
                                    className={classes.formLabel}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                    <Button
                        className={classes.export}
                        color="primary"
                        onClick={this.exportInvoices}
                        variant="contained"
                        disabled={
                            !(
                                filterBuffer.start_date && filterBuffer.end_date
                            ) && !isAllTime
                        }
                    >
                        EXPORT REPORT
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleClearAllFilters}
                        variant="outlined"
                    >
                        CLEAR FILTERS
                    </Button>
                </div>
                <DialogBox
                    open={openLoader}
                    title="Export Processing"
                    actions=""
                >
                    <LinearProgress />
                </DialogBox>
            </Drawer>
        );
    }
}

export default withStyles(styles)(ExportInvoicesDrawer);
