import React, { useEffect } from 'react';
import SortIcon from '@material-ui/icons/Sort';
import {
    Grid,
    Typography,
    makeStyles,
    IconButton,
    TableSortLabel,
    Divider,
    Fade
} from '@material-ui/core';
import { useObjectState } from '../../../utilities/customHooks';
import ColumnFilter from './ColumnFilter';
import {
    useProgramRankingState,
    useProgramRankingMethods
} from './ProgramRankingProvider';

const useStyles = makeStyles((theme) => ({
    root: { marginBottom: theme.spacing(1) },
    columnWrapper: {
        padding: `0 !important`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: 44,
        '&:first-child': {
            paddingLeft: `${theme.spacing(2)}px !important`
        }
    },
    columnHeader: {
        color: '#555',
        fontWeight: 'bold',
        padding: 0,
        fontSize: 14
    }
}));

const TableHeader = () => {
    const classes = useStyles();
    const { reorder, data, sortDirection, setProgramRankingState } =
        useProgramRankingState();
    const { setFilters, removeFilters } = useProgramRankingMethods();
    const [state, setState] = useObjectState({
        programNameFilter: false,
        programName: null
    });

    useEffect(() => {
        if (!state.programNameFilter) {
            setState({ programName: null });
            removeFilters(['program_id']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.programNameFilter, state.programIDFilter]);

    useEffect(() => {
        if (state.programName) {
            setFilters({ program_id: state.programName.value });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.programName]);

    useEffect(() => {
        if (state.programID) {
            setFilters({ program_id: state.programID.value });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.programID]);

    useEffect(() => {
        if (reorder) {
            setState({
                programNameFilter: false,
                programName: null,
                programIDFilter: false,
                programID: null
            });
            removeFilters(['program_id']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reorder]);

    const handleReorder = () => setProgramRankingState({ reorder: true });

    const handleToggleFilter = (field) => setState({ [field]: !state[field] });

    const handleSort = () =>
        setProgramRankingState({
            sortDirection: sortDirection === 'asc' ? 'desc' : 'asc'
        });

    const handleFilterProgramName = (programName) => setState({ programName });

    const handleFilterProgramID = (programID) => setState({ programID });

    const programNames = [
        ...new Set(
            [...data].map(({ id, name }) => ({
                value: id,
                label: name
            }))
        )
    ];

    const programIDs = [
        ...new Set(
            [...data].map(({ id }) => ({
                value: id,
                label: id
            }))
        )
    ];

    return (
        <>
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={2} className={classes.columnWrapper}>
                    <Typography className={classes.columnHeader}>
                        <TableSortLabel
                            active={!reorder}
                            direction={sortDirection}
                            onClick={
                                !reorder && data.length ? handleSort : null
                            }
                            hideSortIcon
                        >
                            Ranking
                        </TableSortLabel>
                    </Typography>
                    {!reorder && (
                        <IconButton
                            size="small"
                            onClick={handleReorder}
                            disabled={!data.length}
                        >
                            <SortIcon fontSize="small" />
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={5} className={classes.columnWrapper}>
                    <ColumnFilter
                        classes={classes}
                        disabled={reorder}
                        label="Program ID"
                        filterValue={state.programID}
                        filterOptions={programIDs}
                        showFilter={state.programIDFilter}
                        onFilterOpen={() =>
                            handleToggleFilter('programIDFilter')
                        }
                        onFilterClose={() =>
                            handleToggleFilter('programIDFilter')
                        }
                        onFilterChange={handleFilterProgramID}
                    />
                </Grid>
                <Grid item xs={3} className={classes.columnWrapper}>
                    <ColumnFilter
                        classes={classes}
                        disabled={reorder}
                        label="Program Name"
                        filterValue={state.programName}
                        filterOptions={programNames}
                        showFilter={state.programNameFilter}
                        onFilterOpen={() =>
                            handleToggleFilter('programNameFilter')
                        }
                        onFilterClose={() =>
                            handleToggleFilter('programNameFilter')
                        }
                        onFilterChange={handleFilterProgramName}
                    />
                </Grid>
                <Grid item xs={2} className={classes.columnWrapper}></Grid>
            </Grid>
            <Fade in={!reorder && !!data.length}>
                <Divider />
            </Fade>
        </>
    );
};

export default TableHeader;
