import React from 'react';
import SearchBasedDropdown from '../../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSEOToolState } from './SEOToolProvider';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: `0 0 ${theme.spacing(1)}px`
    }
}));

export default function CustomPlacement({ label, onDelete, ...props }) {
    const classes = useStyles();
    const { isEdit } = useSEOToolState();
    return (
        <>
            <Typography variant='overline' display='block'>{label}</Typography>
            <Grid
                container
                alignItems="center"
                alignContent="center"
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={onDelete ? 10 : 12}>
                    <SearchBasedDropdown
                        isDisabled={isEdit}
                        data-cy={`custom-placement-${props.valueKey}`}
                        {...props}
                    />
                </Grid>
                {!!onDelete && (
                    <Grid item sm={2} alignItems="center">
                        <IconButton onClick={onDelete} size="medium">
                            <Delete fontSize="small" />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
