import React from 'react';
import {
    Fade,
    makeStyles,
    Paper,
    Button,
    Typography,
    lighten
} from '@material-ui/core';
import { map } from 'lodash';
import Check from '@material-ui/icons/Check';
import {
    useTravelResourceRankingState,
    useTravelResourceRankingMethods
} from './TravelResourceRankingProvider';
import { postRequest } from '../../../helpers/apiRequestUtility';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        display: 'inline',
        marginRight: theme.spacing(1)
    },
    reorderCard: {
        padding: theme.spacing(3),
        display: 'flex',
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        borderRadius: 3,
        alignItems: 'center',
        '& p': {
            color: theme.palette.secondary.main
        }
    },
    actions: {
        marginLeft: 'auto',
        display: 'flex',
        '& button:not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    }
}));

const ReorderAction = (props) => {
    const classes = useStyles();
    const {
        reorder,
        setTravelResourceRankingState,
        temp
    } = useTravelResourceRankingState();
    const {
        handleCloseDialog,
        handleErrorDialog
    } = useTravelResourceRankingMethods();

    const handleCancel = () => {
        setTravelResourceRankingState({ reorder: false });
    };

    const hadleSave = () => {
        temp.forEach((feature, index) => {
            feature.position = index + 1;
        });
        const mappedAdIds = map(temp, 'id');
        let rankIds = mappedAdIds.join();
        setTravelResourceRankingState({
            data: temp,
            reorder: false,
            rankLoading: true
        });
        postRequest(
            `/ads/rank?id=${rankIds}`,
            [],
            (json) => {
                setTravelResourceRankingState({
                    rankLoading: false
                });
                if (!!json.error) {
                    handleErrorDialog();
                } else {
                    const dialog = {
                        actions: 'Ok',
                        title: 'Successfully ranked features!',
                        showDialog: true,
                        onOk: handleCloseDialog,
                        stringOverride: {
                            primaryAction: 'OK'
                        }
                    };
                    setTravelResourceRankingState({
                        dialog: dialog
                    });
                }
            },
            (error) => {
                handleErrorDialog();
            }
        );
    };

    if (!reorder) return null;

    return (
        <Fade in={reorder}>
            <Paper elevation={1}>
                <div className={classes.reorderCard}>
                    <Typography className={classes.label}>
                        Re-order Ranking
                    </Typography>
                    <div className={classes.actions}>
                        <Button
                            color="default"
                            disableElevation
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<Check />}
                            disableElevation
                            onClick={hadleSave}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Paper>
        </Fade>
    );
};

export default ReorderAction;
