import React from 'react';
import DataFilters from './DataFilters';
import FilterDrawer from './FilterDrawer';
import ReorderAction from './ReorderAction';
import TravelResourceRankingProvider from './TravelResourceRankingProvider';
import TravelResourceRankingTable from './TravelResourceRankingTable';

const TravelResourceRanking = (props) => {
    return (
        <TravelResourceRankingProvider>
            <DataFilters />
            <ReorderAction />
            <FilterDrawer />
            <TravelResourceRankingTable />
        </TravelResourceRankingProvider>
    );
};

export default TravelResourceRanking;
