import {
    ClickAwayListener,
    IconButton,
    InputAdornment,
    TextField
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import ExtendedColumnFilters from './ColumnFilters/ExtendedColumnFilters';

export default function ResultsTableInputColumnFilter({
    id,
    type,
    isDisabled,
    value,
    alterValue,
    onEnter = () => { },
    onTextInputChange = () => { },
    onExtendedFilterChange = () => { },
}) {
    const [focus, setFocus] = useState(false);

    const handleChange = (e) => {
        onTextInputChange(e);
    };

    const handleExtendedFilterChange = (value) => {
        onExtendedFilterChange(value);
        setFocus(false);
    };

    const checkAlterValue = useCallback(() => {
        if (!alterValue) return false;

        if (alterValue.value === value) return true;

        if (Array.isArray(value) || typeof value === 'object') return true;

        return (alterValue.options || [])
            .map(({ value }) => value)
            .includes(value);
    }, [value, alterValue]);

    const getAlterValue = useCallback(() => {
        if (!alterValue) return '';

        if (alterValue.value === value) return alterValue.label;

        if (Array.isArray(value)) {
            return alterValue.options
                .filter(({ value: val }) => value.includes(val))
                .map(({ label }) => label)
                .join(', ');
        }

        if (typeof value === 'object') {
            return `${value.start}-${value.end}`
        }

        return alterValue.options
            .filter(({ value: val }) => value === val)
            .map(({ label }) => label)
            .join('');
    }, [value, alterValue]);

    const isAlterValue = checkAlterValue();
    const inputValue = isAlterValue ? getAlterValue() : value;

    return (
        <div>
            <TextField
                margin="none"
                fullWidth
                onKeyDown={onEnter}
                onClick={isAlterValue ? () => setFocus(true) : undefined}
                onChange={handleChange}
                type={isAlterValue ? 'text' : type}
                isDisabled={isDisabled}
                value={inputValue}
                data-cy={id}
                id={`text-column-filter`}
                InputProps={{
                    readOnly: isAlterValue,
                    endAdornment: !!alterValue ? (
                        <InputAdornment>
                            <IconButton
                                data-cy="program-id-column-filter"
                                onClick={() => setFocus(true)}
                                size="small"
                            >
                                <ExpandMore fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    ) : null
                }}
            />
            {!!alterValue && focus && (
                <ClickAwayListener onClickAway={() => setFocus(false)}>
                    <div>
                        <ExtendedColumnFilters
                            {...alterValue}
                            onChange={handleExtendedFilterChange}
                            currentValue={isAlterValue ? value : undefined}
                        />
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
}
