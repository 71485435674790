import React from 'react';
import { withAccountStatsContext } from './AccountStatsProvider';
import StatsTable from '../StatsTable/StatsTable';
import DataFilters from '../DataFilters';
import { pick, mapKeys, find, sortBy as _sortBy, findIndex } from 'lodash';
import { constructHttpParams } from '../../../helpers/dataUtility';

const AccountStats = ({
    columns,
    compare,
    comparisonDates,
    comparisonRangeID,
    dateRangeID,
    dates,
    displayBy,
    filters,
    fetching,
    page,
    rowsPerPage,
    statsTableData,
    sortBy,
    sortingOrder,
    handleSetState,
    fetchStatsData,
    exporting,
    exportData,
    enableExport,
    selected,
    location,
}) => {
    const dateFilters = {
        compare,
        comparisonDates,
        comparisonRangeID,
        dateRangeID,
        dates,
    };

    const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA =
                typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            const varB =
                typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return order === 'asc' ? comparison * -1 : comparison;
        };
    };

    const handleDisplayByChange = ({ value }) => {
        columns =
            value === 'month' || value === 'day' || value === 'week'
                ? columns.map((column) => {
                      return updatedColumn(column, true);
                  })
                : columns.map((column) => {
                      return updatedColumn(column, false);
                  });

        handleColumnsChange(columns);
        handleSetState({ displayBy: value }, fetchStatsData);
    };

    const hadleDateFiltersChange = (value) => {
        let newDisplayBy =
            value.comparisonRangeID === 3
                ? ['month', 'week', 'day'].includes(displayBy)
                    ? 'range'
                    : displayBy
                : displayBy;
        newDisplayBy =
            newDisplayBy === 'range' && value.compare === false
                ? 'month'
                : newDisplayBy;
        handleSetState({ ...value, displayBy: newDisplayBy }, fetchStatsData);
    };

    const handlePageChange = (page) => handleSetState({ page });

    const handleRowsPerPageChange = (rowsPerPage) =>
        handleSetState({ rowsPerPage });

    const handleSortChange = (column) => {
        handleSetState({
            sortBy: column,
            sortingOrder:
                sortBy !== column
                    ? 'desc'
                    : sortingOrder === 'desc'
                    ? 'asc'
                    : 'desc',
            statsTableData: statsTableData.sort(
                compareValues(column, sortingOrder)
            ),
        });
    };

    const handleColumnsChange = (columns) => {
        handleSetState({ columns });
    };

    const filterColumnsToDisplay = (filters) => {
        columns =
            (Object.keys(filters).length === 0 || filters.client) &&
            (displayBy === 'month' ||
                displayBy === 'day' ||
                displayBy === 'week')
                ? columns.map((column) => {
                      return updatedColumn(column, true);
                  })
                : columns;
        columns =
            filters.directory ||
            filters.adType ||
            filters.contentType ||
            filters.listing ||
            filters.location
                ? columns.map((column) => {
                      return updatedColumn(column, false);
                  })
                : columns;

        handleColumnsChange(columns);
    };

    const updatedColumn = (column, isShown) => {
        return column.key === 'myg_applicants'
            ? { ...column, isShown: isShown }
            : column.key === 'best_matches'
            ? { ...column, isShown: isShown }
            : column.key === 'partial_matches'
            ? { ...column, isShown: isShown }
            : column.key === 'best_redemptions'
            ? { ...column, isShown: isShown }
            : column.key === 'partial_redemptions'
            ? { ...column, isShown: isShown }
            : column.key === 'total_redemptions'
            ? { ...column, isShown: isShown }
            : column;
    };

    const handleEnableExport = (value, selected) => {
        handleSetState({ enableExport: value });
        handleSetState({ selected: selected });
    };

    const handleOnExport = (exportColumns) => {
        exportColumns.unshift('label');

        // rearrange selected based on results arrangement
        const sorted = _sortBy(selected, function (item) {
            return findIndex(statsTableData, (e) => {
                return e.label === item.label;
            });
        });

        let data = sorted.map((stats, key, data) => {
            let obj = pick(stats, exportColumns);
            if (compare) {
                obj.label = stats.compare_label + ' ' + obj.label;
            }

            return mapKeys(obj, (value, k) => {
                const column = find(columns, (o) => {
                    return o.key === k;
                });

                return column ? column.label : 'Display By';
            });
        });
        if (compare) {
            let compareData = sorted.map((stats, key, data) => {
                let compareObj = pick(stats.compare, exportColumns);
                compareObj.label = stats.compare_label + ' ' + compareObj.label;
                return mapKeys(compareObj, (value, k) => {
                    const column = find(columns, (o) => {
                        return o.key === k;
                    });

                    return column ? column.label : 'Display By';
                });
            });
            sorted.forEach((value, index) => {
                data.splice(index * 2 + 1, 0, compareData[index]);
            });
        }
        handleSetState({ exporting: true, exportData: data, selected: [] });
        setTimeout(() => {
            handleSetState({ exporting: false });
        }, 1000);
    };

    const handleFiltersChange = (filters) => {
        handleSetState({ filters, selected: [] }, fetchStatsData);
        addValidBookmarkableFields(filters);
        filterColumnsToDisplay(filters);
    };

    const addValidBookmarkableFields = (filters) => {
        let search_params = [];

        Object.keys(filters).forEach((filter) => {
            if (filter === 'client') {
                search_params['provider_id'] = (filters[filter] || {}).value;
            }
        });

        location.push({
            pathname: '/stats',

            search: `?${constructHttpParams(search_params)}`,
        });
    };

    return (
        <div>
            <DataFilters
                filters={filters}
                dateFilters={dateFilters}
                onFiltersChange={handleFiltersChange}
                onDateFiltersChange={hadleDateFiltersChange}
            />
            <StatsTable
                isLoading={fetching}
                data={statsTableData}
                displayBy={displayBy}
                compareID={comparisonRangeID}
                compare={compare}
                columns={columns}
                sortBy={sortBy}
                sortingOrder={sortingOrder}
                page={page}
                rowsPerPage={rowsPerPage}
                onDisplayFilterChange={handleDisplayByChange}
                onSortingChange={handleSortChange}
                onColumnsChange={handleColumnsChange}
                onSelectedChange={() => {}}
                onUnselectedChange={() => {}}
                onSelectAll={() => {}}
                onSelectNone={() => {}}
                onExport={handleOnExport}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                isExporting={exporting}
                exportData={exportData}
                handleEnableExport={handleEnableExport}
                enableExport={enableExport}
                selected={selected}
            />
        </div>
    );
};

export default withAccountStatsContext([
    'columns',
    'compare',
    'comparisonDates',
    'comparisonRangeID',
    'dateRangeID',
    'dates',
    'displayBy',
    'filters',
    'fetching',
    'page',
    'rowsPerPage',
    'statsTableData',
    'sortBy',
    'sortingOrder',
    'exporting',
    'exportData',
    'enableExport',
    'selected',
    'location',
])(AccountStats);
