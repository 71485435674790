import React, { Component, useContext } from 'react';
import { AdUrlVerifierContext } from '../../../context';
import moment from 'moment';
import { sendRequest, postRequest } from '../../../helpers/apiRequestUtility';
import { withSnackbar } from 'notistack';
import { transformDateParam } from '../../../utilities/dateFormatter.js';
import { isEmpty } from 'lodash';
import {
    BOOKMARKABLE_FIELDS,
    isBookmarkable
} from '../../../utilities/boomarkability';
import queryString from 'query-string';
import {
    explodeUrlParams,
    constructHttpParams,
    hasValue,
    capitalizeFirstLetter
} from '../../../helpers/dataUtility';
import { withRouter } from 'react-router-dom';
import { TEAM_ASSIGNMENT } from '../../../config/constants';

export const GLOBAL_FILTERS = ['status', 'team_id'];

const { Provider, Consumer } = AdUrlVerifierContext;

class AdUrlVerifierProvider extends Component {
    state = {
        data: [],
        selected: [],
        count: 0,
        page: 0,
        rowsPerPage: 10,
        isFetching: false,
        filters: {},
        prevFilters: {},
        bookmark: {},
        sort: [],
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: ''
        },
        isSaving: false,
        label: '',
        globalFiltersStatus: ''
    };

    componentDidMount = () => {
        this.initialize();
        this.browserRouterListen();
    };

    initialize = () => {
        const { history } = this.props;
        let { search = '' } = history.location;
        let search_params = explodeUrlParams(search);
        let bookmark = {};

        if (!!search) {
            //eslint-disable-next-line array-callback-return
            Object.keys(search_params).map((key) => {
                if (BOOKMARKABLE_FIELDS.ad_url_verifiers_view.includes(key)) {
                    if (key === 'page')
                        bookmark.page =
                            search_params[key] >= 1 ? search_params[key] : 1;
                    if (key === 'rowsPerPage')
                        bookmark.rowsPerPage = [5, 10, 15, 20, 50].includes(
                            search_params[key]
                        )
                            ? search_params[key]
                            : 10;
                    bookmark[key] = search_params[key];
                }
            });
        }

        if (Object.keys(bookmark).length === 0) {
            bookmark['status'] = 'broken';
            this.setState({ globalFiltersStatus: 'broken' });
        }

        this.setState(
            {
                ...bookmark,
                filters: bookmark,
                prevFilters: bookmark,
                bookmark,
                globalFiltersStatus: bookmark['status']
            },
            async () => {
                this.fetchAdUrls(0, this.state.rowsPerPage);
            }
        );
    };

    browserRouterListen = () => {
        const { history } = this.props;
        history.listen((location, action) => {
            if (action === 'POP') {
                const filters = queryString.parse(location.search);
                const { page, rowsPerPage } = filters;
                this.setState(
                    {
                        filters,
                        prevFilters: filters,
                        page: page || 1,
                        rowsPerPage: rowsPerPage || 10,
                        bookmark: { ...filters }
                    },
                    () => {
                        this.fetchAdUrls(0, this.state.rowsPerPage);
                    }
                );
            }
        });
    };

    addValidBookmarkableFields = (params) => {
        let search_params = [];

        Object.keys(params).forEach((param) => {
            if (isBookmarkable('ad_url_verifiers_view', param)) {
                search_params[param] = params[param];
            }
        });

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?${constructHttpParams(search_params)}`
        });
    };

    getTableLabel = (count) => {
        const { filters, globalFiltersStatus } = this.state;
        const labels = [
            hasValue(filters.status)
                ? capitalizeFirstLetter(globalFiltersStatus)
                : '',
            hasValue(filters.team_id) ? TEAM_ASSIGNMENT[filters.team_id] : ''
        ];

        const label = `${labels
            .filter((label) => {
                return label !== '';
            })
            .join(', ')} Ads URL${count > 1 || count === 0 ? 's' : ''}`;

        return label;
    };

    fetchAdUrls = async (offset, limit) => {
        const { page, rowsPerPage, filters, sort } = this.state;

        this.setState({ isFetching: true });

        const { client, ad_id, item_id, url, status, date_verified, team_id } =
            filters;

        const wildcard = ['url'];

        let sortString = isEmpty(sort)
            ? '-status'
            : sort.map((obj, key) => {
                  return (obj['direction'] === 'asc' ? '+' : '-') + obj['key'];
              });

        let keys = Object.keys(filters).filter((key) => {
            return filters[key] !== null && filters[key] !== '';
        });

        let wildcardableFields = keys
            .filter((key) => {
                return wildcard.includes(key);
            })
            .join(',');

        let filterParams = {
            provider_id: client || null,
            url: url || null,
            status: status ? status : null,
            ad_id: ad_id || null,
            item_id: item_id ? item_id : null,
            date_last_verified: date_verified
                ? transformDateParam(date_verified)
                : null,

            wildcards: wildcardableFields || null,
            team_id
        };

        const params = this.buildParams({
            offset: offset || rowsPerPage * page,
            limit: limit || rowsPerPage,
            sort: sortString,
            ...filterParams
        });

        await sendRequest(
            `/ad-urls?${params}`,
            ({ ad_urls = [], count = 0 } = {}) => {
                this.setState({
                    data: ad_urls,
                    count,
                    isFetching: false,
                    label: this.getTableLabel(count)
                });
            }
        );
    };

    handleUpdateAdUrls = async (action, isBatch, adUrl) => {
        const { enqueueSnackbar } = this.props;
        const data = new FormData();
        const { selected } = this.state;

        let selectedIds = isBatch
            ? selected
                  .filter((ad) => {
                      if (action === 'mark as verified') {
                          return ad.status !== 200;
                      } else if (action === 'mark as broken') {
                          return [200, 301, 302].includes(ad.status);
                      }
                      return true;
                  })
                  .map((ad) => ad.id)
            : [adUrl.id];

        data.append('id', selectedIds.join());
        data.append(
            'date_last_verified',
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        );

        if (action === 'mark as verified') {
            data.append('status', 200);
        } else if (action === 'mark as broken') {
            data.append('status', 500);
        }

        this.setState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            this.setState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/ad-urls/update`,
                data,
                (success) => {
                    this.setState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Ad URLs have been updated!`,
                        {
                            variant: 'success'
                        }
                    );
                    this.fetchAdUrls();
                },
                (error) => {
                    this.setState({ isSaving: false });
                    enqueueSnackbar('Failed to update Ad URLs.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Ad URLS have been updated.', {
                variant: 'error'
            });
        }
    };

    handleShowActionConfirmation = (batchAction, func) => {
        this.setState({
            dialog: {
                show: true,
                title: batchAction,
                content: `Are you sure you want to ${batchAction} the selected ad URL(s)?`,
                stringOverride: {
                    primaryAction: batchAction,
                    secondaryAction: 'Cancel'
                },
                onOk: func
            }
        });
    };

    handleCloseDialog = () => {
        this.setState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {}
            }
        });
    };

    handleSelectMultiple = (selected) => {
        this.setState({
            selected
        });
    };

    buildParams = (params = {}) =>
        Object.keys(params)
            .filter((key) => ![undefined, null, NaN, ''].includes(params[key]))
            .map((key) => `${key}=${params[key]}`)
            .join('&');

    setAdUrlVerifierState = (newState, callback) =>
        this.setState(newState, callback);

    render = () => (
        <Provider
            value={{
                state: this.state,
                setAdUrlVerifierState: this.setAdUrlVerifierState,
                fetchAdUrls: this.fetchAdUrls,
                handleShowActionConfirmation: this.handleShowActionConfirmation,
                addValidBookmarkableFields: this.addValidBookmarkableFields,
                handleUpdateAdUrls: this.handleUpdateAdUrls,
                handleCloseDialog: this.handleCloseDialog,
                handleSelectMultiple: this.handleSelectMultiple
            }}
            children={this.props.children}
        />
    );
}

export const withAdUrlVerifierContext = (Component) => (props) =>
    (
        <Consumer>
            {(context) => <Component {...props} context={context} />}
        </Consumer>
    );

export const withAdUrlVerifierContextState =
    (fields) => (Component) => (props) =>
        (
            <Consumer>
                {({ state, ...rest }) => {
                    let extracted = {};
                    //eslint-disable-next-line array-callback-return
                    Object.keys(state).map((key) => {
                        if ((fields || []).includes(key))
                            extracted[key] = state[key];
                    });
                    return (
                        <Component {...{ ...props, ...extracted, ...rest }} />
                    );
                }}
            </Consumer>
        );

export const useAdUrlVerifierState = () => {
    const { state, setAdUrlVerifierState } = useContext(AdUrlVerifierContext);
    return { ...state, setAdUrlVerifierState };
};

export const useAdUrlVerifierMethods = () => {
    const { state, ...methods } = useContext(AdUrlVerifierContext);
    return methods;
};

export default withRouter(withSnackbar(AdUrlVerifierProvider));
