import { cloudinaryImage } from '../helpers/cloudinary';
import { trimSlash } from '../helpers/dataUtility';

const {
    HOST,
    REACT_APP_PROXY_URL,
    REACT_APP_RECAPTCHA_SITE_KEY,
    REACT_APP_CLOUDINARY_BASE_URL = 'https://res.cloudinary.com',
    REACT_APP_CLOUDINARY_SECTION_BASE_URL = 'https://images.goabroad.com',
    REACT_APP_USE_SECTION = false,
    REACT_APP_CLOUDINARY_USERNAME = 'gaplabs',
    REACT_APP_CLOUDINARY_FOLDER = 'images',
    REACT_APP_GOABROAD_URL,
    REACT_APP_GOABROAD_INSURANCE_URL,
    REACT_APP_GOABROAD_JOBS_URL,
    REACT_APP_GOABROAD_EMBASSY_URL,
    REACT_APP_CLIENT_ADMIN_URL,
    REACT_APP_STRIPE_PUBLISHABLE_KEY,
} = process.env;

export const GA_API = REACT_APP_PROXY_URL;
export const CIPHER_KEY = '604BR04dM34nIn6F';
export const CIPHER_IV = 'lTR4v3LR350uRC35';
export const USER_COOKIE_EXPIRATION = 82800; // expires in the next 23 hours
export const RECAPTCHA_SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY || '';

export const CLOUDINARY_BASE_URL = [1, true, 'true'].includes(
    REACT_APP_USE_SECTION
)
    ? REACT_APP_CLOUDINARY_SECTION_BASE_URL
    : REACT_APP_CLOUDINARY_BASE_URL;

export const CLOUDINARY_URL = [1, true, 'true'].includes(REACT_APP_USE_SECTION)
    ? [trimSlash(CLOUDINARY_BASE_URL), 'image/upload'].join('/')
    : [
          trimSlash(CLOUDINARY_BASE_URL),
          REACT_APP_CLOUDINARY_USERNAME,
          'image/upload',
      ].join('/');

export const CLOUDINARY_FOLDER = REACT_APP_CLOUDINARY_FOLDER;

export const GA_URL = REACT_APP_GOABROAD_URL || 'www.goabroad.com/';

export const GA_INSURANCE_URL =
    REACT_APP_GOABROAD_INSURANCE_URL || 'https://insurance.goabroad.com/';

export const GA_JOBS_URL =
    REACT_APP_GOABROAD_JOBS_URL || 'https://jobs.goabroad.com/';

export const GA_EMBASSY_URL =
    REACT_APP_GOABROAD_EMBASSY_URL || 'https://embassy.goabroad.com/';

export const GA_RECODE_URL = HOST || 'https://localhost:3000';

export const GA_INVOICE_PDF_PATH = '/invoices/invoice-pdf/GoAbroadInvoice';

export const CLIENT_ADMIN_URL = REACT_APP_CLIENT_ADMIN_URL;

export const STRIPE_PUBLISHABLE_KEY = REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const AD_TYPE = {
    ORG_FEATURE: 1,
    LISTING_LOGO: 2,
    HEADLINE_PHOTO: 3,
    TEXT_AD: 4,
    NEWS_TICKER: 5,
    PREMIER_LISTING_FEATURE: 6,
    LISTING_PHOTO: 7,
    VIDEO_AD: 8,
    ARTICLE_DIRECTORY_ORG_FEATURE: 10,
    ARTICLE_FEATURE: 11,
    FEATURED_LISTING: 12,
    LISTING_COVER_PHOTO: 13,
    DIRECTORY_FEATURE: 14,
};

export const PARTNERS = {
    TRAVEL_INSURANCE: 1,
    ONLINE_TEFL: 2,
    FLIGHTS: 3,
    ACCOMMODATIONS: 5,
    DATA_PLANS: 7,
    TRAVEL_RESOURCE: 9,
    PASSPORTS_VISAS: 10,
    FLIGHTS_TRANSPORTATION: 11,
    FINANCES: 12,
    MOBILE_DATA: 13,
};

export const PARTNER_NAMES = {
    [PARTNERS.TRAVEL_INSURANCE]: 'Travel Insurance',
    [PARTNERS.ONLINE_TEFL]: 'Online TEFL',
    [PARTNERS.FLIGHTS]: 'Flights',
    [PARTNERS.ACCOMMODATIONS]: 'Accommodations',
    [PARTNERS.DATA_PLANS]: 'Data Plans',
    [PARTNERS.TRAVEL_RESOURCE]: 'Travel Resource',
    [PARTNERS.PASSPORTS_VISAS]: 'Passports & Visas',
    [PARTNERS.FLIGHTS_TRANSPORTATION]: 'Flights & Transportation',
    [PARTNERS.FINANCES]: 'Finances Abroad',
    [PARTNERS.MOBILE_DATA]: 'SIM Cards Abroad',
};

export const RESOURCE_PAGES = [
    {
        partner_id: PARTNERS['ACCOMMODATIONS'],
        name: PARTNER_NAMES[PARTNERS.ACCOMMODATIONS],
    },
    {
        partner_id: PARTNERS['PASSPORTS_VISAS'],
        name: PARTNER_NAMES[PARTNERS.PASSPORTS_VISAS],
    },
    {
        partner_id: PARTNERS['TRAVEL_INSURANCE'],
        name: PARTNER_NAMES[PARTNERS.TRAVEL_INSURANCE],
    },
    {
        partner_id: PARTNERS['FLIGHTS_TRANSPORTATION'],
        name: PARTNER_NAMES[PARTNERS.FLIGHTS_TRANSPORTATION],
    },
    {
        partner_id: PARTNERS['FINANCES'],
        name: PARTNER_NAMES[PARTNERS.FINANCES],
    },
    {
        partner_id: PARTNERS['MOBILE_DATA'],
        name: PARTNER_NAMES[PARTNERS.MOBILE_DATA],
    },
];

export const ad_fields = {
    [AD_TYPE.ORG_FEATURE]: ['title', 'image', 'web_url'],
    [AD_TYPE.LISTING_LOGO]: [],
    [AD_TYPE.HEADLINE_PHOTO]: ['title', 'web_url', 'image'],
    [AD_TYPE.TEXT_AD]: ['web_url', 'description', 'image'],
    [AD_TYPE.NEWS_TICKER]: ['title', 'description', 'web_url', 'image'],
    [AD_TYPE.PREMIER_LISTING_FEATURE]: ['web_url', 'image'],
    [AD_TYPE.LISTING_PHOTO]: ['image'],
    [AD_TYPE.VIDEO_AD]: ['web_url', 'title', 'video_link'],
    [AD_TYPE.ARTICLE_DIRECTORY_ORG_FEATURE]: ['title', 'web_url', 'image'],
    [AD_TYPE.ARTICLE_FEATURE]: ['title', 'image', 'web_url'],
    [AD_TYPE.FEATURED_LISTING]: ['featured_program', 'title', 'image'],
    [AD_TYPE.LISTING_COVER_PHOTO]: ['image'],
    [AD_TYPE.DIRECTORY_FEATURE]: ['image', 'title', 'web_url'],
};

export const salaryCompenstationDir = [3, 11, 5, 2, 4, 6];

export const scholarshipDir = [1, 8, 3, 9, 2, 6];

export const creditDir = [9, 8, 1, 3, 10, 6];

export const directoriesWithTypes = [2, 9, 1, 6, 3];

export const directoriesWithTimings = [1, 8, 3, 11, 2, 6, 7, 10, 9];

export const search_criteria_fields = {
    1: 'program_id',
    2: 'country_id',
    4: 'state_id',
    8: 'main_region_id',
    16: 'city_id',
    32: 'adventure_type_id',
    64: 'degree_id',
    128: 'study_type_id',
    256: 'highschool_type_id',
    512: 'duration_id',
    1024: 'language_id',
    2048: 'intern_type_id',
    4096: 'term_id',
    8192: 'volunteer_type_id',
    16384: 'world_region_id',
    32768: 'online_tefl',
    65536: 'accomodation_type_id',
    131072: 'academic_degree_id',
    262144: 'scholarship_type_id',
    524288: 'gap_year_type_id',
    104576: 'most_recent',
    2097152: 'intern_duration_id',
    4194304: 'gap_year_timing_id',
    8388608: 'teach_duration_id',
};

export const BIN_VALUE = {
    program_id: 1,
    directory_id: 1,
    country_id: 2,
    state_id: 4,
    main_region_id: 8,
    city_id: 16,
    adventure_type_id: 32,
    degree_id: 64,
    study_type_id: 128,
    highschool_type_id: 256,
    duration_id: 512,
    language_id: 1024,
    intern_type_id: 2048,
    term_id: 4096,
    volunteer_type_id: 8192,
    world_region_id: 16384,
    online_tefl: 32768,
    accomodation_type_id: 65536,
    academic_degree_id: 131072,
    scholarship_type_id: 262144,
    gap_year_type_id: 524288,
    most_recent: 1048576,
    intern_duration_id: 2097152,
    gap_year_timing_id: 4194304,
    teach_duration_id: 8388608,
};

export const ad_factors = {
    program_id: 1,
    directory_id: 1,
    country_id: 2,
    city_id: 4,
    main_region_id: 8,
    partner_id: 16,
    adventure_type_id: 32,
    degree_id: 64,
    intern_type_id: 128,
    language_id: 256,
    volunteer_type_id: 512,
    highschool_type_id: 1024,
    academic_degree_id: 2048,
    term_id: 4096,
    state_id: 8192,
    sponsorship_id: 16384,
    default: 32768,
    world_region_id: 65536,
    special_ad_page: 131072,
    study_type_id: 262144,
    duration_id: 524288,
    gap_year_type_id: 1048576,
    intern_duration_id: 2097152,
    gap_year_timing_id: 4194304,
    teach_duration_id: 8388608,
};

export const account_status_label = {
    0: 'Inactive',
    1: 'Active',
};

export const account_type_label = {
    0: 'Free',
    1: 'Paid',
};

export const inquiry_source_label = {
    0: 'Provider',
    1: 'Program',
    2: 'Participant Matches',
};

export const TEAM_ASSIGNMENT = {
    0: 'Unassigned',

    32: 'Corporate Pirates',

    31: 'Sales Duo',

    29: 'Sales Express',

    30: 'The Tycoons',

    23: 'Content',
};

export const preferred_oai_label = {
    0: 'Non Preferred OAI Match',
    1: 'Preferred OAI Match',
};

export const PROVIDER_LINK_STATUS = {
    verified: 'Verified',
    redirects: 'Redirects',
    broken: 'Broken',
    pending: 'Pending',
};

export const LOCATION_CRITERIA = [
    'world_region_id',
    'country_id',
    'main_region_id',
    'city_id',
];
export const TYPE_CRITERIA = [
    'adventure_type_id',
    'degree_id',
    'highschool_type_id',
    'language_id',
    'intern_type_id',
    'volunteer_type_id',
    // 'provider_id',
    'gap_year_type_id',
];
export const TIMING_CRITERIA = [
    'duration_id',
    'term_id',
    'study_type_id',
    'intern_duration_id',
    'gap_year_timing_id',
    'teach_duration_id',
];

export const INTERNAL_ADMIN = 'client-accounts';

export const CLIENT_ADMIN = 'client-admin';

export const CLIENT_PROFILE = 'client/profile';

export const INVOICE_STATUS_VALUE = {
    draft: 0,
    pending: 1,
    unpaid: 2,
    partial: 3,
    overdue: 4,
    paid: 5,
    void: 6,
};

export const OAI_STATUS = {
    0: 'Unanswered',
    1: 'Ready To Send',
    2: 'Answered',
    3: 'Unqualified',
    4: 'Bounced',
};

export const OAI_AUTOMATED_STATUS = {
    2: 'Answered',
    3: 'Unqualified',
    4: 'Bounced',
};

export const ERROR_CODES = {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
    UNPROCESSABLE: 422,
    SERVER_ERROR: 500,
};

export const LISTING_COVER_PHOTO_ID = 189;

export const LISTING_COVER_PHOTO_TEN_PLUS = 324;

export const LISTING_COVER_PHOTO_CUSTOM_PROGRAM = 499;

export const CLOUDINARY_PATHS = {
    CLIENT_LOGO: 'clients/logos/MAIN',
    STATIC: 'static',
    PROGRAM_CONTENT: 'program_content',
    PARTNERS: 'partners',
    INTERVIEWS: 'interviews',
    AUTHORS: `program_content/authors`,
};

export const GA_LOGO = cloudinaryImage({
    filename: 'goabroad-logo.png',
    path: CLOUDINARY_PATHS.STATIC,
    params: { w: 200 },
});

export const GA_LOZENGE = cloudinaryImage({
    filename: 'lozenge.png',
    path: CLOUDINARY_PATHS.STATIC,
    params: { w: 200, c: 'thumb' },
});

export const default_image = cloudinaryImage({
    filename: 'header-tiled-background.png',
    path: CLOUDINARY_PATHS.STATIC,
    params: { c: 'fill', fl: 'progressive' },
});

export const redirects = [301, 302];

export const broken = [400, 410, 404, 500, 502, 503];

export const verified = [200];

export const INTERVIEW_TYPE = {
    ALUMNI: 1,
    STAFF: 2,
};

export const SCHOLARSHIP_TYPE_CRITERIA = {
    2: 'volunteer_type_id',
    4: 'intern_type_id',
    5: 'intern_type_id',
    6: 'degree_id',
    7: 'language_id',
    8: 'adventure_type_id',
    9: 'highschool_type_id',
    12: 'degree_id',
    19: 'gap_year_type_id',
};

export const SCHOLARSHIP_TIMING_CRITERIA = {
    2: 'duration_id',
    3: 'teach_duration_id',
    4: 'intern_duration_id',
    6: 'term_id',
    9: 'duration_id',
    12: 'study_type_id',
    19: 'gap_year_timing_id',
};

export const CUSTOM_AMOUNT = [155, 383, 516, 517];

const PROGRAM_STATS_COLOR = {
    approved: 'success',
    pending: 'pending',
    expiring: 'primary',
    expired: 'error',
    draft: '',
};
const INVOICE_STATS_COLOR = {
    paid: 'success',
    partial: 'pending',
    unpaid: '',
    overdue: 'error',
};

const INTERVIEW_STATS_COLOR = {
    published: 'success',
};

const AD_STATS_COLOR = {
    active: 'success',
    scheduled: 'primary',
    expired: 'error',
};

const REVIEW_STATS_COLOR = {
    approved: 'success',
    inactive: 'error',
    not_checked: 'error',
};

const ARTICLE_STATS_COLOR = {
    published: 'success',
};

const REVIEW_FILTERS = {
    approved: 'is_approved=1&content_status=1',
    inactive: 'status=0',
    not_checked: 'content_status=0',
};

const INVOICE_FILTERS = {
    paid: 5,
    partial: 3,
    unpaid: 2,
    overdue: 4,
};

export const STATS_TYPE = {
    programs: {
        label: 'Programs',
        key: 'program_stats',
        endpoint: `/providers/:provider_id/programs-stats`,
        url: `/client/profile/:provider_id/programs?listing_status=:filter`,
        colors: PROGRAM_STATS_COLOR,
    },
    ads: {
        label: 'Ads',
        key: 'ads_stats',
        endpoint: `/providers/:provider_id/ads-stats`,
        url: `/client/profile/:provider_id/ads?status=:filter`,
        colors: AD_STATS_COLOR,
    },
    invoices: {
        label: 'Invoices',
        key: 'invoice_stats',
        endpoint: `/providers/:provider_id/invoices-stats`,
        url: `/client/profile/:provider_id/invoices?payment_status=:filter`,
        colors: INVOICE_STATS_COLOR,
        filters: INVOICE_FILTERS,
    },
    reviews: {
        label: 'Reviews',
        key: 'review_stats',
        endpoint: `/providers/:provider_id/reviews-stats`,
        url: `/client/profile/:provider_id/reviews?:filter`,
        colors: REVIEW_STATS_COLOR,
        filters: REVIEW_FILTERS,
    },
    interviews: {
        label: 'Interviews',
        key: 'interview_stats',
        endpoint: `/providers/:provider_id/interviews-stats`,
        url: `${trimSlash(GA_URL)}/providers/:provider_alias/interviews`,
        colors: INTERVIEW_STATS_COLOR,
    },
    articles: {
        label: 'Articles',
        key: 'article_stats',
        endpoint: `/providers/:provider_id/articles-stats`,
        url: `${trimSlash(GA_URL)}/providers/:provider_alias/articles`,
        colors: ARTICLE_STATS_COLOR,
    },
};

export const MYG_STATS_TYPE = {
    account: {
        label: 'Accounts',
    },
    active: {
        label: 'Active Users',
    },
    inactive: {
        label: 'Inactive Users',
    },
    applications: {
        label: 'User Applications',
    },
};

export const DEAL_TYPE = {
    deal: 1,
    scholarship: 2,
};

export const DEAL_STATUS = {
    approved: 0,
    archived: 1,
    expired: 2,
    expiring: 3,
    pending: 4,
};

export const BEST_MATCH_SCORE = 2;

export const GENDER = {
    1: 'Male',
    2: 'Female',
    3: 'Non-binary',
};

export const PROVIDER_FAQ_STATUS = {
    NONE: 0,
    APPROVED: 1,
    PENDING: 2,
};

export const MYGENDER = {
    1: 'Male',
    2: 'Female',
    3: 'Undisclosed',
};

export const metaPageTypes = [
    {
        value: 'world_region',
        label: 'Region',
        isLocation: true,
    },
    {
        value: 'country',
        label: 'Country',
        isLocation: true,
    },
    {
        value: 'main_region',
        label: 'Main Region',
        isLocation: true,
    },
    {
        value: 'city',
        label: 'City',
        isLocation: true,
    },
    {
        value: 'type',
        label: 'Subject/Field/Causes/Type/Focus',
        altLabel: 'Type',
    },
    {
        value: 'timing',
        label: 'Duration/Term',
        altLabel: 'Timing',
    },
];

export const metaPlaceholders = [
    {
        value: '{type}',
        label: '{type}',
    },
    {
        value: '{term}',
        label: '{term}',
    },
    {
        value: '{course}',
        label: '{course}',
    },
    {
        value: '{location}',
        label: '{location}',
    },
    {
        value: '{city}',
        label: '{city}',
    },
    {
        value: '{mainregion}',
        label: '{mainregion}',
    },
    {
        value: '{online}',
        label: '{online}',
    },
    {
        value: '{programCount}',
        label: '{programCount}',
    },
];

export const META_PAGE_MAPPER = {
    world_region_id: 'World region',
    country_id: 'Country',
    main_region_id: 'Main region',
    city_id: 'City',
    adventure_type_id: 'Type',
    degree_id: 'Subject',
    highschool_type_id: 'Focus',
    language_id: 'Type',
    intern_type_id: 'Field',
    volunteer_type_id: 'Cause',
    gap_year_type_id: 'Focus',
    duration_id: 'Duration',
    term_id: 'Term',
    intern_duration_id: 'Duration',
    study_type_id: 'Degree',
    gap_year_timing_id: 'Term',
    teach_duration_id: 'Duration',
};

export const COMMLOGS_TYPE = {
    0: 'Email',
    1: 'Phone Call',
    2: 'Memo',
    3: 'Program',
    4: 'Invoice',
    5: 'Phone Call Request',
};

export const REVIEW_CATEGORIES = [
    {
        2: 55,
        3: 43,
        4: 19,
        5: 25,
        6: 37,
        7: 31,
        8: 1,
        9: 13,
        10: 69,
        11: 52,
        12: 7,
        19: 61,
    },
    {
        2: 57,
        3: 44,
        4: 21,
        5: 26,
        6: 38,
        7: 33,
        8: 2,
        9: 14,
        10: 68,
        11: 51,
        12: 8,
        19: 62,
    },
    {
        2: 58,
        3: 45,
        4: 22,
        5: 27,
        6: 39,
        7: 34,
        8: 3,
        9: 15,
        10: 67,
        11: 49,
        12: 9,
        19: 63,
    },
    {
        2: 56,
        3: 46,
        4: 20,
        5: 29,
        6: 40,
        7: 35,
        8: 4,
        9: 17,
        10: 70,
        11: 54,
        12: 10,
        19: 64,
    },
    {
        2: 59,
        3: 47,
        4: 23,
        5: 28,
        6: 41,
        7: 36,
        8: 5,
        9: 16,
        10: 71,
        11: 53,
        12: 11,
        19: 65,
    },
    {
        2: 60,
        3: 48,
        4: 24,
        5: 30,
        6: 42,
        7: 32,
        8: 6,
        9: 18,
        10: 72,
        11: 50,
        12: 12,
        19: 66,
    },
];
