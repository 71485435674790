import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { MenuItem, Typography, lighten, makeStyles } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    mentionInput: {
        padding: theme.spacing(1),
        '&__input': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            border: 0,
            borderBottom: `1px solid ${grey[400]}`,
            outline: 0,
            fontSize: `16px !important`
        },
        '&__input:focus': {
            borderBottom: `2px solid ${blue[500]}`
        }
    },
    mention: {
        padding: theme.spacing(1)
    },
    selectedMention: {
        backgroundColor: `${lighten(
            theme.palette.primary.main,
            0.9
        )} !important`,
        color: grey[900]
    }
}));

export default function MentionTextField({
    data = [],
    value,
    onChange,
    multiline = false,
    label,
    length = 0,
    maxChar = 0,
    minChar = 0,
    ...props
}) {
    const classes = useStyles();
    return (
        <>
            {label && <Typography variant="overline">{label}</Typography>}
            <MentionsInput
                {...props}
                rows={3}
                value={value}
                onChange={onChange}
                className={classes.mentionInput}
                singleLine={!multiline}
            >
                <Mention
                    trigger="{"
                    data={data}
                    markup="@__display__"
                    renderSuggestion={(
                        entry,
                        search,
                        highlightedDisplay,
                        index,
                        focused
                    ) => {
                        return (
                            <MenuItem
                                selected={focused}
                                className={classes.mention}
                                dense
                                classes={{
                                    selected: classes.selectedMention
                                }}
                            >
                                {entry.display}
                            </MenuItem>
                        );
                    }}
                />
            </MentionsInput>
            <Typography variant="caption" display="block" align="right">
                {label === "Meta Description" && value.length > maxChar &&
                    <Typography paragraph={true} variant="colorError" display="initial" align="left">
                        Warning! Long meta description is not recommended
                    </Typography>}
                {label === "Meta Description" && value.length < minChar &&
                    <Typography paragraph={true} variant="colorError" display="initial" align="left">
                        Warning! Short meta description is not recommended
                    </Typography>}
                {value.length ? value.length : 0}{maxChar ? `/${maxChar}` : ''}
            </Typography>

        </>
    );
}
