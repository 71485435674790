import React from 'react';
import ResultsMain from './ResultsMain';
import ProgramUrlVerifierProvider from './ProgramUrlVerifierProvider';

const ProgramUrlVerifier = ({ active }) => {
    if (!active) return null;
    return (
        <ProgramUrlVerifierProvider>
            <ResultsMain />
        </ProgramUrlVerifierProvider>
    );
};

export default ProgramUrlVerifier;
