import { makeStyles } from '@material-ui/styles';
import { grey, orange } from '@material-ui/core/colors';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = ({ size }) =>
    makeStyles(() => ({
        team: {
            color: '#fff',
            fontSize: size === 'large' ? 12 : 8,
            width: size === 'large' ? 24 : 16,
            height: size === 'large' ? 24 : 16,
            verticalAlign: 'middle',
            display: 'inline-flex',
            borderRadius: 50,
            alignItems: 'center',
            justifyContent: 'center',
        },
        unassigned: {
            background: grey[500],
        },
        pirates: {
            background: '#a8639e',
        },
        'corporate-pirates': {
            background: '#a8639e',
        },
        duo: {
            background: '#e5748e',
        },
        'sales-duo': {
            background: '#e5748e',
        },
        express: {
            background: '#ffc107',
        },
        'sales-express': {
            background: '#ffc107',
        },
        tycoons: {
            background: '#974044',
        },
        'the-tycoons': {
            background: '#974044',
        },
        content: {
            background: orange[700],
        },
        'content-team': {
            background: orange[700],
        },
        oai: {
            background: '#4E97D1',
        },
    }));

const TeamIcon = ({ variant, size, className }) => {
    const classes = useStyles({ size })();
    if (!variant) return null;
    const { [variant]: { initial, label } = {} } = {
        duo: { initial: 'SD', label: 'Sales Duo' },
        'sales-duo': { initial: 'SD', label: 'Sales Duo' },
        pirates: { initial: 'CP', label: 'Corporate Pirates' },
        'corporate-pirates': { initial: 'CP', label: 'Corporate Pirates' },
        express: { initial: 'SE', label: 'Sales Express' },
        'sales-express': { initial: 'SE', label: 'Sales Express' },
        tycoons: { initial: 'TT', label: 'The Tycoons' },
        'the-tycoons': { initial: 'TT', label: 'The Tycoons' },
        content: { initial: 'CT', label: 'Content' },
        'content-team': { initial: 'CT', label: 'Content' },
        unassigned: { initial: 'U', label: 'Unassigned' },
        oai: { initial: 'OAI', label: 'Preferred OAI' },
    };
    return (
        <Tooltip title={label || 'Unassigned'} placement="top">
            <Typography
                className={classnames([
                    classes.team,
                    classes[variant],
                    className,
                ])}
            >
                {initial || 'U'}
            </Typography>
        </Tooltip>
    );
};

export default TeamIcon;
