import { format, lastDayOfMonth } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { TravelResourceRankingContext } from '../../../context';
import { useObjectState } from '../../../utilities/customHooks';
import {
    arrayDeepCopy,
    constructHttpParams
} from '../../../helpers/dataUtility';
import { sendRequest } from '../../../helpers/apiRequestUtility';

const { Provider } = TravelResourceRankingContext;

const TravelResourceRankingProvider = (props) => {
    const [state, setState] = useObjectState({
        data: [],
        filters: {},
        reorder: false,
        fetching: false,
        filterDrawerOpen: true,
        sortDirection: 'desc',
        rankLoading: false,
        dialog: {
            showDialog: false,
            title: '',
            content: '',
            onOk: () => {},
            onCancel: () => {},
            stringOverride: {}
        }
    });

    const setFilters = (filters) =>
        setState((prev) => ({
            ...prev,
            filters: { ...prev.filters, ...filters }
        }));

    const removeFilters = (keys = []) =>
        setState((prev) => {
            let newFilters = { ...prev.filters };
            //eslint-disable-next-line array-callback-return
            keys.map((key) => {
                const { [key]: filter, ...others } = newFilters;
                newFilters = others;
            });
            return {
                ...prev,
                filters: newFilters
            };
        });

    const fetchAds = () => {
        const { filters = {} } = state;
        const dateFilter = new Date(filters.date);
        const start_date = format(lastDayOfMonth(dateFilter), 'YYYY-MM-DD');
        const end_date = format(dateFilter, 'YYYY-MM-01');
        const params = constructHttpParams({
            partner_id: filters.partner_id,
            start_date: `-${start_date}`,
            end_date: `+${end_date}`,
            ad_type_id: 12,
            limit: -1,
            status: 'active'
        });

        setState({ fetching: true });
        sendRequest(`/ads?${params}`, ({ ads = [] } = {}) => {
            sortData(ads);
        }).finally(() => setState({ fetching: false }));
    };

    const sortData = (rawData = state.data) => {
        const { sortDirection } = state;
        setState({
            data: [...rawData].sort((a, b) => {
                if (!a.position && !!b.position) return 1;
                if (!b.position && !!a.position) return -1;
                if (sortDirection === 'desc') return a.position - b.position;
                if (sortDirection === 'asc') return b.position - a.position;
                return 0;
            })
        });
    };

    const onDrop = (dropIndex) => {
        let { temp, dragged_index } = state;
        const oldFeatures = arrayDeepCopy(temp);
        let draggedIndex = dragged_index;
        let draggedAd = oldFeatures[draggedIndex];

        if (draggedIndex < dropIndex) {
            for (let index = draggedIndex; index < dropIndex; index++) {
                temp[index] = oldFeatures[index + 1];
            }
            temp[dropIndex] = draggedAd;
        } else if (draggedIndex > dropIndex) {
            for (let index = draggedIndex; index > dropIndex; index--) {
                temp[index] = oldFeatures[index - 1];
            }
            temp[dropIndex] = draggedAd;
        }
        setState({ temp });
    };

    const handleErrorDialog = () => {
        const dialog = {
            actions: 'Ok',
            title: 'Oh no! Ranking of features was unsuccessful, please try again.',
            showDialog: true,
            onOk: handleCloseDialog,
            stringOverride: { primaryAction: 'OK' }
        };
        setState({ dialog: dialog });
    };

    const handleCloseDialog = () => {
        const dialog = {
            showDialog: false
        };
        setState({ dialog: dialog });
    };

    useEffect(() => {
        if (state.filters.partner_id && state.filters.date) fetchAds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.filters.partner_id, state.filters.date]);

    useEffect(() => {
        sortData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sortDirection]);

    useEffect(() => {
        if (state.reorder) {
            setState({ sortDirection: 'desc', temp: [...state.data] });
            removeFilters(['client_id']);
        } else setState({ temp: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.reorder, state.data]);

    return (
        <Provider
            value={{
                state,
                setTravelResourceRankingState: setState,
                setFilters,
                removeFilters,
                fetchAds,
                sortData,
                onDrop,
                handleErrorDialog,
                handleCloseDialog
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useTravelResourceRankingState = () => {
    const { state, setTravelResourceRankingState } = useContext(
        TravelResourceRankingContext
    );
    return { ...state, setTravelResourceRankingState };
};

export const useTravelResourceRankingMethods = () => {
    const { state, ...methods } = useContext(TravelResourceRankingContext);
    return methods;
};

export default TravelResourceRankingProvider;
