import React, { Component, Fragment } from 'react';
import {
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    withStyles
} from '@material-ui/core';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import { get } from 'lodash';
import { TableLoader } from '../../../components/Loader/Loader';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto'
    },
    table: {
        minWidth: 650,

        '& tr:nth-child(even)': {
            background: '#f2f2f2'
        }
    }
});

const adTabs = [
    {
        id: 1,
        name: 'Tier 1'
    },
    {
        id: 2,
        name: 'Tier 3'
    }
];

class AdsBreakdown extends Component {
    state = {
        tabSelected: 0,
        adsBreakdown: { tier1: [], tier2: [] },
        loading: false
    };

    componentDidMount() {
        const { row, params } = this.props;
        this.setState({ loading: true });
        sendRequest(
            `/providers/${row.provider.id}/ads-breakdown?${params}`,
            (response) => {
                const { ads_breakdown = {} } = response;
                this.setState({ adsBreakdown: ads_breakdown, loading: false });
            }
        );
    }

    renderAdsBreakdownTable = () => {
        const { adsBreakdown, tabSelected, loading } = this.state;
        const rows = adsBreakdown[`tier${tabSelected + 1}`] || [];
        const { classes } = this.props;

        if (loading) {
            return <TableLoader />;
        }
        return (
            <div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ad Id</TableCell>
                            <TableCell align="left">Ad Name</TableCell>
                            <TableCell align="left">Location</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Timing</TableCell>
                            <TableCell align="left">Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">
                                    {row.type &&
                                        get(row.type, 'display_name', '-')}
                                </TableCell>
                                <TableCell align="left">
                                    {row.placements &&
                                        get(row.placements, 'location', '-')}
                                </TableCell>
                                <TableCell align="left">
                                    {row.placements &&
                                        get(row.placements, 'type', '-')}
                                </TableCell>
                                <TableCell align="left">
                                    {row.placements &&
                                        get(row.placements, 'timing', '-')}
                                </TableCell>
                                <TableCell align="left">
                                    $
                                    {row.product_item.cost
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </TableCell>
                            </TableRow>
                        ))}
                        {rows.length === 0 && (
                            <TableRow key="no-rows-data">
                                <TableCell colSpan={6} align="center">
                                    No Data
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        );
    };

    handleChange = (event, tabSelected) => {
        this.setState({ tabSelected });
    };

    render() {
        const { classes } = this.props;
        const { tabSelected } = this.state;

        return (
            <Fragment>
                <Tabs
                    value={tabSelected}
                    onChange={this.handleChange}
                    className={classes.tabsRoot}
                    indicatorColor="secondary"
                    textColor="secondary"
                    fullWidth
                >
                    {adTabs.map((tab) => {
                        return (
                            <Tab
                                disableRipple
                                label={tab.name}
                                className={classes.tabRoot}
                                classes={{
                                    labelContainer:
                                        tab.notifications &&
                                        classes.labelContainer
                                }}
                            />
                        );
                    })}
                </Tabs>
                {this.renderAdsBreakdownTable()}
            </Fragment>
        );
    }
}

export default withStyles(styles)(AdsBreakdown);
