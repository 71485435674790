import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { rtrim } from 'validator';
import { CLIENT_ADMIN_URL } from '../../config/constants';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2
    },
    snackbar: {
        '& > div': {
            padding: theme.spacing.unit * 3,
            background: 'rgba(0,30,55,0.8)',
            maxWidth: 'none',
            marginBottom: theme.spacing.unit * 2
        },
        '& svg': {
            color: 'white'
        }
    },
    close: {
        display: 'flex'
    },
    checkbox: {
        marginRight: theme.spacing.unit * 2,
        display: 'flex',
        alignItems: 'center'
    },
    closeBtn: {
        position: 'absolute',
        top: 8,
        right: 8
    }
});

const Notice = (props) => {
    const { classes, message } = props;
    const [open, setOpen] = useState(false);
    const [hide, setHide] = useState(
        localStorage.getItem('hideLoginNotice') || false
    );
    const referrer = rtrim(document.referrer, '/').match(
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim //eslint-disable-line no-useless-escape
    );

    useEffect(() => {
        setHide(localStorage.getItem('hideLoginNotice'));
        setOpen((!hide && referrer === CLIENT_ADMIN_URL) || !!message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hide]);

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('hideLoginNotice', hide);
    };

    const handleHide = (e) => {
        setHide(e.target.checked);
    };

    if (!message || typeof message !== 'string') return null;

    return (
        <Fragment>
            <CssBaseline />
            <Paper className={classes.root}>
                <Snackbar
                    className={classes.snackbar}
                    open={open && !hide}
                    TransitionComponent={Fade}
                    message={
                        !!message
                            ? message
                            : 'We’re excited to announce that we’ve recently launched a brand new GoAbroad account system! To proceed with logging in to your GoAbroad account, also known as our client admin system, please use our new login form above. If you experience any technical difficulties please email admin@goabroad.com'
                    }
                    action={
                        <Fragment>
                            <div className={classes.close}>
                                <div className={classes.checkbox}>
                                    <Checkbox
                                        size="small"
                                        value="small"
                                        onChange={handleHide}
                                    />
                                    <span>Don't show this message again</span>
                                </div>
                                <IconButton
                                    className={classes.closeBtn}
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={handleClose}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </Fragment>
                    }
                />
            </Paper>
        </Fragment>
    );
};

export default withStyles(styles)(Notice);
