/**
 * Decimal adjustment of a number.
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
export function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;

    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

/**
 * transform number to get the float value in two decimal place
 *
 * @param {Integer|Float} value  Value to be parsed to float.
 *
 * @return {Object}
 *         {String} return.type         Indicate whether the number is in Dollar or Percent.
 *         {Float}  return.value        The float value of the given parameter.
 */
export function transformNumberToTwoDecimalFloat(
    number,
    type = 'round',
    exp = -2
) {
    return decimalAdjust(type, number, exp);
}

/**
 * Transform number to currency
 *
 * @param {Integer|Float} number   Value to be parsed.
 * @param {String}        currency Currency.
 *
 * @return {String} The adjusted value.
 */
export function toCurrency(number, currency = 'USD') {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(number);
}

/**
 * Get float value of a number or string
 *
 * @param {Integer|Float|String} value  Value to be parsed to float.
 *                                      '$' at the start will be dollar type.
 *                                      '%' at the end will be percent type.
 *
 * @return {Object}
 *         {String} return.type         Indicate whether the number is in Dollar or Percent.
 *         {Float}  return.value        The float value of the given parameter.
 */
export function getNumber(value = '') {
    const string = value.toString();

    if (isNaN(string[0])) {
        return {
            type: 'dollar',
            value: parseFloat(string.slice(1)),
        };
    } else if (isNaN(string[string.length - 1])) {
        return {
            type: 'percent',
            value: parseFloat(string.slice(0, string.length - 1)),
        };
    } else {
        return {
            type: 'dollar',
            value: parseFloat(string),
        };
    }
}
