import { Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import ResultsMain from './ResultsMain';
import ScholarshipsRanking from './ScholarshipsRanking/Main';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(2)
    }
}));

const scholarshipTabs = [
    { name: 'Scholarship Listings', path: '/scholarships' },
    { name: 'Scholarships Ranking', path: '/scholarships-ranking' }
];

const ScholarshipTabs = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [active, setActive] = useState(0);

    useEffect(() => {
        const activeIndex = scholarshipTabs.findIndex(
            ({ path }) => location.pathname === path
        );
        setActive(activeIndex);
    }, [location.pathname]);

    return (
        <>
            <Tabs
                className={classes.root}
                indicatorColor="secondary"
                textColor="secondary"
                value={active}
            >
                {scholarshipTabs.map(({ name, path }) => (
                    <Tab
                        key={`scholarship-tab-${path}`}
                        label={name}
                        disableRipple
                        onClick={() => history.push(path)}
                    />
                ))}
            </Tabs>
            <ResultsMain active={active === 0} />
            <ScholarshipsRanking active={active === 1} />
        </>
    );
};

export default ScholarshipTabs;
