import React from 'react';
import DealProvider from './DealProvider';
import { SnackbarProvider } from 'notistack';
import DealTabs from './DealTabs';
import ResultsMain from './ResultsMain';
import ApplicationProvider from './Applications/ApplicationProvider';
import UsersProvider from './Users/UsersProvider';

const Main = () => {
    return (
        <SnackbarProvider>
            <DealProvider>
                <ResultsMain />
                <ApplicationProvider>
                    <UsersProvider>
                        <DealTabs />
                    </UsersProvider>
                </ApplicationProvider>
            </DealProvider>
        </SnackbarProvider>
    );
};

export default Main;
