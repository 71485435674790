import React, { useState, useContext, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import grey from '@material-ui/core/colors/grey';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { isValidLink, toValidUrl } from '../../helpers/dataUtility';
import DialogBox from '../DialogBox/DialogBox';
import { LinearProgress } from '@material-ui/core';
import SearchBasedDropdown from '../SearchBasedDropdown/SearchBasedDropdown';
import { GlobalContext } from '../../context';
import { useAuthenticatedRequest } from '../../views/Client/GlobalProvider';
import { useSnackbar } from 'notistack';
import { isObject } from 'lodash';
import classNames from 'classnames';

const TABWIDTH = 420;

const CHAR_LIMIT = {
    firstName: 75,
    lastName: 75,
    jobTitle: 250,
};

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: TABWIDTH,
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginBottom: theme.spacing.unit * 2,
        borderRadius: '3px',
    },
    memo: {
        marginBottom: theme.spacing.unit * 2,
        borderRadius: '3px',
        padding: `0 ${theme.spacing.unit}px`,
        '& textarea': {
            minHeight: '80px',
        },
    },
    card: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit,
        overflow: 'visible',
    },
    checkboxCard: {
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        color: grey[600],
        textTransform: 'uppercase',
        fontSize: '11px',
        letterSpacing: '1px',
        display: 'flex',
        alignItems: 'center',
    },
    cardHeader: {
        padding: `${theme.spacing.unit * 2}px`,
    },
    subheader: {
        fontWeight: 'bold',
    },
    ctaButton: {
        marginTop: 'auto',
        marginBottom: theme.spacing.unit * 2,
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'center',

        '& > svg': {
            marginRight: theme.spacing.unit,
        },
    },
    validationIcon: {
        marginLeft: 'auto',
        fontSize: '16px',
    },
    fieldInput: {
        paddingRight: 4,
    },
    validIcon: {
        color: '#4caf50',
    },
});

const CreateAccountDrawer = ({ classes, open, onClose }) => {
    const [state, setState] = useState({
        notify: {
            sales: false,
            primary: false,
            none: false,
        },
        isValidEmail: true,
        isValidUrl: true,
        email: '',
        phone: '',
        url: '',
        clientName: '',
        webUrl: '',
        country: {},
        firstName: '',
        lastName: '',
        jobTitle: '',
        memo: '',
        saving: false,
        openDraw: true,
        isValidUniqueEmail: true,
        timeOut: 0,
        errorMessage: '',
        error: false,
    });

    const { enqueueSnackbar } = useSnackbar();
    const { sendRequest, postRequest } = useAuthenticatedRequest();
    const history = useHistory();
    const context = useContext(GlobalContext);
    const { countries } = context.state;

    const handleSetState = (newState, callback = () => {}) => {
        setState((prev) => {
            return { ...prev, ...newState };
        });
        callback();
    };

    const {
        notify,
        email,
        url,
        phone,
        isValidEmail,
        isValidUrl,
        isValidUniqueEmail,
        clientName,
        country,
        firstName,
        lastName,
        jobTitle,
        memo,
        saving,
        error,
    } = state;

    const handleToggleNotify = (option, checked) => {
        let { notify } = state;
        notify[option] = checked;
        if (option === 'none') {
            notify.sales = false;
            notify.primary = false;
        }

        if (option === 'sales' || option === 'primary') {
            notify.none = false;
        }
        handleSetState({ notify });
    };

    const handleCountryChange = ({ value, label }) => {
        let country = {};
        country = {
            id: value,
            name: label,
        };
        handleSetState({ country });
    };

    const handleEmailChange = (e) => {
        let email = e.target.value,
            timeOut;

        if (state.timeOut) {
            clearTimeout(state.timeOut);
        }

        timeOut = setTimeout(() => {
            let re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line no-useless-escape
            let isValidUniqueEmail = true;

            if (re.test(String(email).toLowerCase())) {
                sendRequest(
                    '/users/count-by-email?email=' + email,
                    ({ count }) => {
                        if (count > 0) {
                            isValidUniqueEmail = false;
                        }
                        handleSetState({
                            isValidUniqueEmail,
                            isValidEmail: true,
                        });
                    },
                    () => {
                        handleSetState({
                            isValidUniqueEmail,
                            isValidEmail: false,
                        });
                    }
                );
            } else {
                handleSetState({
                    isValidEmail: false,
                });
            }
        }, 1000);

        handleSetState({
            email,
            timeOut,
        });
    };

    const handleFieldChange = (field) => (e) => {
        if (field === 'url')
            handleSetState({
                [field]: e.target.value,
                isValidUrl: e.target.value ? isValidLink(e.target.value) : true,
            });
        else handleSetState({ [field]: e.target.value });
    };

    const handleCloseErrorDialog = () => {
        handleSetState({ error: false, errorMessage: '' });
    };

    const handleSave = async () => {
        const {
            clientName,
            url,
            country,
            phone,
            firstName,
            lastName,
            jobTitle,
            email,
            memo,
            notify,
        } = state;

        const form_data = new FormData();

        let newUrl = '';

        newUrl = toValidUrl(url);

        form_data.append('name', clientName);
        form_data.append('web_url', newUrl);
        form_data.append('country_id', country.id);
        form_data.append('phone', phone);
        form_data.append('contact_email', email);
        form_data.append('contact_phone', phone);
        form_data.append('first_name', firstName);
        form_data.append('last_name', lastName);
        form_data.append('job_title', jobTitle);
        form_data.append('memo', memo);
        form_data.append('notify_sales', notify.sales ? 1 : 0);
        form_data.append('notify_contact', notify.primary ? 1 : 0);

        handleSetState({ saving: true });

        const newProvider = await postRequest(
            `/providers`,
            form_data,
            (json) => {
                return json.provider.provider;
            },
            (error) => {
                const {
                    code,
                    error: message = 'Error encountered creating Account.',
                } = isObject(error) ? error : {};
                if (code !== 401)
                    handleSetState(
                        {
                            saving: false,
                        },
                        () =>
                            enqueueSnackbar(message, {
                                variant: 'error',
                            })
                    );
                handleSetState({
                    saving: false,
                });
            },
            handleSave
        );

        if (!!newProvider) {
            handleSetState(
                {
                    notify: {
                        sales: false,
                        primary: false,
                        none: false,
                    },
                    isValidEmail: true,
                    isValidUrl: true,
                    isValidPhone: true,
                    email: '',
                    phone: '',
                    url: '',
                    clientName: '',
                    webUrl: '',
                    country: '',
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    memo: '',
                    saving: false,
                },
                () => {
                    if (!!newProvider.id) {
                        history.push('/client/profile/' + newProvider.id);
                    } else {
                        enqueueSnackbar('Error encountered creating Account.', {
                            variant: 'error',
                        });
                    }
                }
            );
        }
    };

    const isFormComplete = () => {
        return (
            clientName &&
            country &&
            firstName &&
            lastName &&
            email &&
            isValidEmail &&
            isValidUniqueEmail &&
            (notify.none || notify.primary || notify.sales) &&
            firstName.length <= CHAR_LIMIT.firstName &&
            lastName.length <= CHAR_LIMIT.lastName &&
            jobTitle.length <= CHAR_LIMIT.jobTitle
        );
    };

    useEffect(() => {
        const { fetchCountries } = context;
        if (open) fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            BackdropProps={{
                invisible: true,
            }}
        >
            <div className={classes.root}>
                <DialogBox open={saving} title={'Saving Account...'} actions="">
                    <LinearProgress />
                </DialogBox>

                <DialogBox
                    open={error}
                    title={'Error'}
                    onClose={handleCloseErrorDialog}
                    actions=""
                >
                    <Typography className={classes.errorMessage}>
                        <CancelIcon fontSize="large" color="secondary" /> Error
                        encountered creating Account.
                    </Typography>
                </DialogBox>
                <Card className={classes.card}>
                    <div className={classes.cardContent}>
                        <Typography className={classes.label}>
                            Client Name
                        </Typography>
                        <Input
                            className={classes.input}
                            value={clientName}
                            onChange={handleFieldChange('clientName')}
                        />
                        <Typography className={classes.label}>
                            <span>Website URL</span>
                            {!!url ? (
                                isValidUrl ? (
                                    <Tooltip
                                        title="Valid Website"
                                        placement="top"
                                    >
                                        <CheckIcon
                                            className={classNames([
                                                classes.validationIcon,
                                                classes.validIcon,
                                            ])}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title="Invalid Website"
                                        placement="top"
                                    >
                                        <ErrorIcon
                                            className={classes.validationIcon}
                                            color="secondary"
                                        />
                                    </Tooltip>
                                )
                            ) : null}
                        </Typography>
                        <Input
                            className={classes.input}
                            placeholder="https://www.yourdomain.com"
                            value={url}
                            onChange={handleFieldChange('url')}
                        />
                        <Typography className={classes.label}>
                            Country
                        </Typography>
                        <SearchBasedDropdown
                            value={
                                country.id
                                    ? {
                                          label: country.name,
                                          value: country.id,
                                      }
                                    : null
                            }
                            arrayOptions={countries}
                            valueKey="id"
                            labelKey="name"
                            handleChange={handleCountryChange}
                        />
                    </div>
                </Card>

                <Card className={classes.card}>
                    <CardHeader
                        subheader={'Primary Contact'}
                        className={classes.cardHeader}
                        classes={{ subheader: classes.subheader }}
                    />
                    <Divider className={classes.divider} />
                    <div className={classes.cardContent}>
                        <Typography className={classes.label}>
                            First Name
                        </Typography>
                        <TextField
                            error={firstName.length > CHAR_LIMIT.firstName}
                            helperText={
                                firstName.length > CHAR_LIMIT.firstName
                                    ? 'First Name is too long'
                                    : ''
                            }
                            className={classes.input}
                            fullWidth
                            value={firstName}
                            onChange={handleFieldChange('firstName')}
                            InputProps={{
                                endAdornment: firstName ? (
                                    <Typography
                                        variant="caption"
                                        color={
                                            firstName.length >
                                            CHAR_LIMIT.firstName
                                                ? 'error'
                                                : 'textPrimary'
                                        }
                                    >
                                        {firstName.length}/
                                        {CHAR_LIMIT.firstName}
                                    </Typography>
                                ) : null,
                                classes: { input: classes.fieldInput },
                            }}
                        />
                        <Typography className={classes.label}>
                            Last Name
                        </Typography>
                        <TextField
                            error={lastName.length > CHAR_LIMIT.lastName}
                            helperText={
                                lastName.length > CHAR_LIMIT.lastName
                                    ? 'Last Name is too long'
                                    : ''
                            }
                            className={classes.input}
                            fullWidth
                            value={lastName}
                            onChange={handleFieldChange('lastName')}
                            InputProps={{
                                endAdornment: lastName ? (
                                    <Typography
                                        variant="caption"
                                        color={
                                            lastName.length >
                                            CHAR_LIMIT.lastName
                                                ? 'error'
                                                : 'textPrimary'
                                        }
                                    >
                                        {lastName.length}/{CHAR_LIMIT.lastName}
                                    </Typography>
                                ) : null,
                                classes: { input: classes.fieldInput },
                            }}
                        />
                        <Typography className={classes.label}>
                            Job Title
                        </Typography>
                        <TextField
                            error={jobTitle.length > CHAR_LIMIT.jobTitle}
                            helperText={
                                jobTitle.length > CHAR_LIMIT.jobTitle
                                    ? 'Job Title is too long'
                                    : ''
                            }
                            className={classes.input}
                            fullWidth
                            value={jobTitle}
                            onChange={handleFieldChange('jobTitle')}
                            InputProps={{
                                endAdornment: jobTitle ? (
                                    <Typography
                                        variant="caption"
                                        color={
                                            jobTitle.length >
                                            CHAR_LIMIT.jobTitle
                                                ? 'error'
                                                : 'textPrimary'
                                        }
                                    >
                                        {jobTitle.length}/{CHAR_LIMIT.jobTitle}
                                    </Typography>
                                ) : null,
                                classes: { input: classes.fieldInput },
                            }}
                        />
                        <Typography className={classes.label}>
                            <span>Email</span>
                            {!!email ? (
                                isValidEmail && isValidUniqueEmail ? (
                                    <Tooltip
                                        title="Valid Email"
                                        placement="top"
                                    >
                                        <CheckIcon
                                            className={classNames([
                                                classes.validationIcon,
                                                classes.validIcon,
                                            ])}
                                        />
                                    </Tooltip>
                                ) : !isValidUniqueEmail ? (
                                    <Tooltip
                                        title="Existing Email"
                                        placement="top"
                                    >
                                        <ErrorIcon
                                            className={classes.validationIcon}
                                            color="secondary"
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title="Invalid Email"
                                        placement="top"
                                    >
                                        <ErrorIcon
                                            className={classes.validationIcon}
                                            color="secondary"
                                        />
                                    </Tooltip>
                                )
                            ) : null}
                        </Typography>
                        <Input
                            className={classes.input}
                            fullWidth
                            placeholder="youremail@domain.com"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <Typography className={classes.label}>
                            {' '}
                            Phone{' '}
                        </Typography>
                        <Input
                            className={classes.input}
                            fullWidth
                            value={phone}
                            onChange={handleFieldChange('phone')}
                        />
                    </div>
                </Card>

                <Card className={classes.card}>
                    <CardHeader
                        subheader={'Memo'}
                        className={classes.cardHeader}
                        classes={{ subheader: classes.subheader }}
                    />
                    <Divider className={classes.divider} />
                    <div className={classes.cardContent}>
                        <TextField
                            className={classes.memo}
                            multiline
                            fullWidth
                            variant="outlined"
                            placeholder="Add memo ..."
                            value={memo}
                            onChange={handleFieldChange('memo')}
                        />
                    </div>
                </Card>

                <Card className={classes.checkboxCard}>
                    <Typography className={classes.label}>
                        Notify Options
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={notify.sales && !notify.none}
                                disabled={notify.none}
                                onChange={(e, checked) =>
                                    handleToggleNotify('sales', checked)
                                }
                            />
                        }
                        label="Sales Team"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={notify.primary && !notify.none}
                                disabled={notify.none}
                                onChange={(e, checked) =>
                                    handleToggleNotify('primary', checked)
                                }
                            />
                        }
                        label="Primary Contact"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={notify.none}
                                disabled={notify.primary || notify.sales}
                                onChange={(e, checked) =>
                                    handleToggleNotify('none', checked)
                                }
                            />
                        }
                        label="None"
                    />
                </Card>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.ctaButton}
                    disabled={!isFormComplete()}
                    onClick={handleSave}
                >
                    Create Account
                </Button>
            </div>
        </Drawer>
    );
};

export default withStyles(styles)(CreateAccountDrawer);
