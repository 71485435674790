import React, { useContext, useEffect } from 'react';
import { CustomMetaContext } from '../../../../context';
import { useObjectState } from '../../../../utilities/customHooks';
import { DIRECTORIES } from '../../../../utilities/directory';
import { sendRequest, postRequest } from '../../../../helpers/apiRequestUtility';
import { useSnackbar } from 'notistack';
import {
    ERROR_CODES,
    LOCATION_CRITERIA,
    TIMING_CRITERIA,
    TYPE_CRITERIA,
    META_PAGE_MAPPER,
    BIN_VALUE
} from '../../../../config/constants';
import { SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';
const { Provider } = CustomMetaContext;

export default function CustomMetaProvider(props) {
    const [state, setState] = useObjectState({
        data: [],
        metaTypes: [],
        urlFilter: null,
        filters: {},
        count: 1,
        selected: [],
        selectedMeta: null,
        fetching: false,
        page: 0,
        rowsPerPage: 10,
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => { },
            onCancel: () => { }
        },
        deletingMeta: false
    });
    const { rowsPerPage, page, filters, urlFilter } = state;
    const { enqueueSnackbar } = useSnackbar();

    //Fetch custom meta-seo
    const fetchMeta = async () => {

        setState({ fetching: true });
        let params = "";
        let additionalParams = "";

        //for search filter
        if (Object.keys(filters).length > 0 && (filters.directory !== null || Object.keys(filters.customPlacements).length !== 0 || filters.online === true)) {
            let isDirectorySet = !!filters?.directory?.value;
            let binValue = 1;
            if (isDirectorySet) {
                var directoryID = filters.directory.value;
                additionalParams = additionalParams.concat("&directory_id=", directoryID);
            }
            let customPlacementsData = Object.entries(filters).find((x) => {
                return x[0] === "customPlacements";
            });
            Object.entries(customPlacementsData[1]).forEach((data) => {
                let resultData = Object.values(SEARCH_CRITERIA).find((x) => x.new_criteria === data[0]);
                binValue += BIN_VALUE[resultData.id];
                if (!!resultData)
                    additionalParams = additionalParams.concat(`&${resultData.id}=`, !!data[1]?.value ? data[1].value : 0);
            });
            if (filters.online) {
                binValue += 32768;

            }
            if (binValue > 1) {
                additionalParams = additionalParams.concat("&bin_value=", binValue);
            }

        }

        //for URL search filter
        if (urlFilter) {

            let binValue = 0;
            additionalParams += "&";

            urlFilter.forEach(({ criteria, value }, i) => {
                if (criteria === "online") {
                    if (value === 1) {
                        binValue += 32768;
                    }
                    return
                }
                binValue += BIN_VALUE[criteria];
                if (i === urlFilter.length - 1) {
                    if (criteria === "directory_id") {
                        additionalParams += criteria + "=" + value
                    } else {
                        additionalParams += criteria + "=" + value;
                    }

                } else {
                    if (criteria === "directory_id") {
                        additionalParams += criteria + "=" + value + "&"
                    } else {
                        additionalParams += criteria + "=" + value + "&";
                    }

                }

            })
            additionalParams += "&bin_value=" + binValue;
        }

        params = `limit=${rowsPerPage}&offset=${page * rowsPerPage}&custom=1${additionalParams}`;

        await sendRequest(`/seo-meta/info?${params}`, (json) => {
            let seoData = [];
            let data = json['seo_meta'];

            data.forEach((metaData) => {
                const {
                    id,
                    h1,
                    h2,
                    title,
                    description,
                    directory_id,
                    program_results_meta_criteria
                } = metaData;

                const crit = [];

                let criterias = program_results_meta_criteria.criteria_value;
                let pageTypeLabel = '';

                criterias.forEach(criteria => {
                    if (TIMING_CRITERIA.includes(criteria.criteria)) {
                        crit.push(META_PAGE_MAPPER[criteria.criteria]);
                    }
                    if (TYPE_CRITERIA.includes(criteria.criteria)) {
                        crit.push(META_PAGE_MAPPER[criteria.criteria]);
                    }
                    if (LOCATION_CRITERIA.includes(criteria.criteria)) {
                        crit.push(META_PAGE_MAPPER[criteria.criteria]);
                    }

                });
                if (crit.length > 0) {
                    pageTypeLabel = crit.join(", ");
                } else {
                    pageTypeLabel = 'Directory';
                }

                const seo = {
                    id,
                    pageType: pageTypeLabel,
                    pageTypeLabel,
                    h1,
                    h2,
                    title,
                    description
                }

                const dir = DIRECTORIES.find(element => element.id === directory_id)
                seo.directory = dir.name;
                seoData.push(seo);
            });

            setState({
                data: seoData,
                count: json['meta'].count,
                fetching: false
            })

        }).finally(() => {
            setState({
                fetching: false
            })
        });
    };

    const deleteMeta = (isBatch, seoMeta) => {

        const { selected, } = state;
        const data = new FormData();
        let selectedSeoId = isBatch ? selected.map((seo) => seo.id) : [seoMeta.id];
        setState({ deletingMeta: true });
        data.append('id', selectedSeoId.join());

        postRequest(
            '/seo-meta/delete',
            data,
            (json) => {
                if (json["successIds"].length > 0) {
                    enqueueSnackbar(isBatch ? 'Seo Metas have been deleted!' : 'Seo Meta has been deleted!', {
                        variant: 'success'
                    });
                } else {
                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }
                fetchMeta();

                setState({ selectedMeta: null, selected: [], page: 0 });

            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to delete meta.', {
                        variant: 'error'
                    });
            },
        ).finally(() => {
            setState({ deletingMeta: false, selectedMeta: null });

        })
    };


    useEffect(() => {
        fetchMeta();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchMeta();
        // eslint-disable-next-line
    }, [rowsPerPage, page, urlFilter]);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchMeta();
        }
        // eslint-disable-next-line
    }, [filters]);

    return (
        <Provider
            value={{
                state,
                setCustomMetaState: setState,
                deleteMeta,
                fetchMeta
            }}
        >
            {props.children}
        </Provider>
    );
}

export const useCustomMetaState = () => {
    const { state, setCustomMetaState } = useContext(CustomMetaContext);
    return { ...state, setCustomMetaState };
};

export const useCustomMetaMethods = () => {
    const { state, ...methods } = useContext(CustomMetaContext);
    return methods;
};
