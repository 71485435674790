import React, { useEffect } from 'react';
import {
    Drawer,
    Paper,
    Button,
    Typography,
    TextField,
    TextareaAutosize,
    makeStyles,
    Tooltip,
    InputAdornment
} from '@material-ui/core';
import { red, grey } from '@material-ui/core/colors';
import Error from '@material-ui/icons/Error';

import SaveIcon from '@material-ui/icons/Save';
import {
    useObjectState,
    useSnackbarMessage
} from '../../../utilities/customHooks';
import {
    useAuthenticatedRequest,
    useGlobalState
} from '../../Client/GlobalProvider';
import { useTopicsMethods, useTopicsState } from './TopicProvider';
import { useSnackbar } from 'notistack';
import { buildFormData } from '../../../helpers/dataUtility';
import LoadingDialog from '../../../components/Loader/LoadingDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: 420,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        color: '#555',
        textAlign: 'center',
        fontSize: '1.5em',
        padding: '12px',
        fontWeight: 'bold'
    },
    paper: {
        padding: theme.spacing(3),
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    filter: {
        fontFamily: 'Roboto',
        width: '100%',

        '& > div': {
            marginBottom: theme.spacing(2)
        },

        '& button': {
            width: '100%',
            padding: '8px 0',
            background: 'rgb(31,179,212)',
            color: 'white',
            marginTop: theme.spacing(1),
            boxShadow: 'none',

            '&:hover': {
                background: 'rgba(31,179,212,0.9)'
            }
        }
    },
    label: {
        color: '#9e9e9e',
        fontSize: 11,
        textTransform: 'uppercase',
        lineHeight: 2.66,
        marginBottom: theme.spacing.unit * 0.5
    },
    articleTitle: {
        width: '100%'
    },
    datepicker: {
        fontSize: 11,
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'left',
        padding: theme.spacing(1),
        height: 38,
        width: 360
    },
    bio: {
        width: '100%',
        resize: 'none',
        fontSize: 16,
        borderRadius: 4,
        // lineHeight: '1.5em',
        borderColor: '#c4c4c4',
        padding: '10px 14px'
    },
    socialMedia: {
        marginBottom: theme.spacing(2),
        '& svg': {
            fontSize: 18
        }
    },
    headshot: {
        height: 150,
        width: 150,
        margin: 'auto'
    },
    avatar: {
        display: 'flex',
        margin: 'auto',
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    fab: {
        backgroundColor: '#FFF',
        color: grey[700]
    },
    cta: {
        marginTop: 'auto'
    }
}));

const TopicDrawer = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { showSuccess, showError } = useSnackbarMessage(enqueueSnackbar);
    const { edit, selectedTopic, setTopicsState } = useTopicsState();
    const { fetchTopics } = useTopicsMethods();
    const { topicDrawerOpen, handleStateChange: setGlobalState } =
        useGlobalState();
    const { postRequest } = useAuthenticatedRequest();
    const [state, setState] = useObjectState({
        name: '',
        alias: '',
        title: '',
        description: '',
        saving: false,
        loaded: true
    });

    useEffect(() => {
        if (edit) {
            const editData = {
                name: selectedTopic.name || '',
                alias: selectedTopic.alias || '',
                title: selectedTopic.meta_title || '',
                description: selectedTopic.meta_description || ''
            };
            setState({ ...editData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit, selectedTopic]);

    const handleSave = () => {
        const formData = new FormData();
        const data = {
            name: state.name,
            alias: state.alias,
            meta_title: state.title,
            meta_description: state.description
        };
        const endpoint = edit
            ? `/articles/topics/${selectedTopic.id}/update`
            : '/articles/topics';

        buildFormData(formData, data);

        setState({ saving: true });

        postRequest(
            endpoint,
            formData,
            (data) => {
                showSuccess(
                    `Successfully saved ${edit ? 'changes' : 'new Topic'}!`
                );
                handleClose();
                fetchTopics();
            },
            (e) => {
                showError(
                    `Failed to save ${
                        edit ? 'changes' : 'new Topic'
                    }. Please try again.`
                );
            }
        ).finally(() => setState({ saving: false }));
    };

    const handleClose = () => {
        setTopicsState({ edit: false, selectedTopic: null });
        setGlobalState({ topicDrawerOpen: false });
        setState({
            name: '',
            title: '',
            description: '',
            alias: ''
        });
    };

    const handleFieldChange =
        (field) =>
        ({ target: { value } }) => {
            if (field === 'name') {
                setState({
                    name: value,
                    alias: value
                        .toLowerCase()
                        .replace(/[\s]/g, '-')
                        .replace(/[^\w\s\-]/gi, ''), //eslint-disable-line  no-useless-escape
                    title: `${value} Travel Articles | GoAbroad.com`,
                    description: `Read GoAbroad ${value} articles. Find travel tips and advice to help you pick a program, choose a location, and get your adventure abroad started now!`
                });
            } else {
                setState({ [field]: value });
            }
        };

    const isInvalidAlias = /[^A-Za-z0-9\-]/gi.test(state.alias); //eslint-disable-line  no-useless-escape
    const isValidFields =
        !!state.name &&
        state.name.length <= 30 &&
        !!state.alias &&
        !!state.title &&
        state.title.length <= 50 &&
        !!state.description &&
        state.description.length <= 160 &&
        !isInvalidAlias;

    return (
        <>
            <LoadingDialog
                open={state.saving}
                message={edit ? 'Saving changes ...' : 'Saving new topic ...'}
            />
            <Drawer
                anchor="right"
                open={topicDrawerOpen || edit}
                onClose={handleClose}
            >
                <div className={classes.root}>
                    <Paper className={classes.paper} elevation={1}>
                        <Typography className={classes.drawerTitle}>
                            {edit ? 'Edit Topic' : 'Add Topic'}
                        </Typography>
                        <Typography variant="overline">Topic Name</Typography>
                        <TextField
                            data-cy="topic-name"
                            size="small"
                            autoFocus
                            onChange={handleFieldChange('name')}
                            value={state.name}
                            placeholder="Enter Topic Name"
                            inputProps={{ maxLength: 30 }}
                            //eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <Typography
                                            variant="caption"
                                            align="right"
                                        >
                                            {state.name.length}/30
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                            error={state.name.length > 30}
                        />
                        <br />
                        <Typography variant="overline">
                            Topic URL Slug
                        </Typography>
                        <TextField
                            data-cy="url-slug"
                            size="small"
                            onChange={handleFieldChange('alias')}
                            value={state.alias}
                            placeholder="Enter URL Slug"
                            InputProps={{
                                endAdornment: isInvalidAlias ? (
                                    <InputAdornment data-cy="invalid-slug">
                                        <Tooltip
                                            title="Invalid Slug. Please exclude special characters."
                                            placement="top"
                                        >
                                            <Error
                                                style={{
                                                    color: red[500],
                                                    fontSize: 15
                                                }}
                                            />
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null
                            }}
                            error={isInvalidAlias}
                            helperText={
                                isInvalidAlias
                                    ? 'Invalid Slug. Please exclude special characters.'
                                    : ''
                            }
                        />
                        <br />
                        <Typography variant="overline">
                            Topic Page Title
                        </Typography>
                        <TextField
                            data-cy="page-title"
                            size="small"
                            onChange={handleFieldChange('title')}
                            value={state.title}
                            placeholder="Enter Page Title"
                            inputProps={{ maxLength: 50 }}
                            //eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <Typography
                                            variant="caption"
                                            align="right"
                                        >
                                            {state.title.length}/50
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                            error={state.title.length > 50}
                        />
                        <br />
                        <Typography variant="overline">
                            Topic Meta Description
                        </Typography>
                        <TextareaAutosize
                            data-cy="meta-description"
                            className={classes.bio}
                            rowsMin={4}
                            rowsMax={8}
                            onChange={handleFieldChange('description')}
                            value={state.description}
                            maxLength={160}
                            placeholder="Enter Meta Description"
                        />
                        <Typography
                            variant="caption"
                            color={
                                state.description.length > 160
                                    ? 'secondary'
                                    : 'initial'
                            }
                            align="right"
                        >
                            {state.description.length}/160
                        </Typography>
                    </Paper>
                    <Button
                        data-cy="create-update-button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSave}
                        startIcon={
                            <SaveIcon
                                color="white"
                                className={classes.extendedIcon}
                            />
                        }
                        disabled={!isValidFields}
                        className={classes.cta}
                    >
                        {edit ? 'Update' : 'Create'}
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

export default TopicDrawer;
