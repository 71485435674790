import React, { Fragment } from 'react';
import { withState, withProps } from './StatsTableProvider';
import {
    Typography,
    TableRow,
    TableCell,
    Checkbox,
    withStyles
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
    arrayJsonToString
} from '../../../helpers/dataUtility';
import { grey } from '@material-ui/core/colors';

const styles = (theme) => ({
    rowLabel: {
        minWidth: '150px'
    },
    checkbox: {
        width: '70px'
    },
    rowValue: {
        fontWeight: 'bold',
        color: '#555'
    },
    compareLabel: {
        minWidth: '150px',
        fontWeight: 'bold'
    },
    compareCheckbox: {
        padding: '0 1em 11em'
    },
    changeLabel: {
        fontWeight: 'bold'
    },
    positiveRate: {
        color: '#4caf50',
        fontWeight: 'bold'
    },
    negativeRate: {
        color: '#f44336',
        fontWeight: 'bold'
    },
    link: {
        color: grey[800],
        fontWeight: 'bold',
        textDecoration: 'none'
    }
});

const usedStates = ['isSelectedAll', 'selected', 'unselected'];
const usedProps = [
    'columns',
    'displayBy',
    'handleSetState',
    'onSelectedChange',
    'onUnselectedChange',
    'handleEnableExport',
    'compare'
];

const StatsTableRow = ({
    classes,
    row,
    columns,
    isSelectedAll,
    selected,
    unselected,
    displayBy,
    onSelectedChange,
    onUnselectedChange,
    handleSetState,
    handleEnableExport,
    compare
}) => {
    const getChange = (row, key) => {
        const { compare } = row;
        if (!row[key] && !compare[key]) return 0;
        if (!!row[key] && !compare[key]) return 100;
        if (isFinite((row[key] - compare[key]) / compare[key]))
            return ((row[key] - compare[key]) / compare[key]) * 100;
        return 0;
    };

    const filterSelected = (item) => {
        return !compare
            ? item.label !== row.label
            : item.compare_label !== row.compare_label;
    };

    const isRowSelected = () => {
        let selectedLabels = arrayJsonToString(selected || []);
        return selectedLabels.includes(JSON.stringify(row));
    };

    const handleSelectChange = (e) => {
        e.stopPropagation();
        let selectedLabels = Array.isArray(selected)
            ? selected.map((item) => {
                  return !compare ? item.label : item.compare_label;
              })
            : [];

        if (selectedLabels.includes(!compare ? row.label : row.compare_label)) {
            selected = selected.filter(filterSelected);
        } else {
            selected = [...selected, row];
        }
        !!onSelectedChange && onSelectedChange(selected);
        handleEnableExport(selected.length > 0, selected);
        handleSetState({
            selected,
            unselected
        });
    };

    return (
        <Fragment>
            {!!row.compare && (
                <TableRow padding="none">
                    <TableCell
                        padding="checkbox"
                        className={classes.compareCheckbox}
                        rowSpan={4}
                    >
                        <Checkbox
                            checked={isRowSelected()}
                            onClick={handleSelectChange}
                        />
                    </TableCell>
                    <TableCell
                        align="left"
                        className={classes.compareLabel}
                        padding="none"
                    >
                        {row.compare_label}
                    </TableCell>
                    {columns.map((column) => {
                        if (!column.isShown) return null;
                        if (!column.subcolumns) return <TableCell></TableCell>;

                        return column.subcolumns.map((subcolumn) => {
                            return <TableCell></TableCell>;
                        });
                    })}
                </TableRow>
            )}
            <TableRow hover={!row.compare}>
                {!row.compare && (
                    <TableCell padding="checkbox" className={classes.checkbox}>
                        <Checkbox
                            checked={isRowSelected()}
                            onClick={handleSelectChange}
                        />
                    </TableCell>
                )}
                <TableCell align="left" className={classes.rowLabel}>
                    {row.url ? (
                        <Link to={row.url} className={classes.link}>
                            {row.label}
                        </Link>
                    ) : (
                        <Typography>{row.label}</Typography>
                    )}
                </TableCell>
                {columns.map((column) => {
                    if (!column.isShown) return null;
                    if (!column.subcolumns)
                        return (
                            <TableCell align="center" key={column.key}>
                                <span className={classes.rowValue}>
                                    {!!row[column.key]
                                        ? row[column.key]
                                              .toString()
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                              )
                                        : 0}
                                    &nbsp;
                                </span>
                            </TableCell>
                        );

                    return column.subcolumns.map((subcolumn) => {
                        return (
                            <TableCell align="right">
                                <span className={classes.rowValue}>
                                    {row[subcolumn.key]
                                        .toString()
                                        .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )}{' '}
                                    &nbsp;
                                </span>
                            </TableCell>
                        );
                    });
                })}
            </TableRow>
            {!!row.compare && (
                <Fragment>
                    <TableRow>
                        <TableCell align="left" className={classes.rowLabel}>
                            {row.compare.label}
                        </TableCell>
                        {columns.map((column) => {
                            if (!column.isShown) return null;
                            if (!column.subcolumns)
                                return (
                                    <TableCell align="center">
                                        <span className={classes.rowValue}>
                                            {row.compare[column.key]
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                )}
                                            &nbsp;
                                        </span>
                                    </TableCell>
                                );

                            return column.subcolumns.map((subcolumn) => {
                                return (
                                    <TableCell align="center">
                                        <span className={classes.rowValue}>
                                            {row.compare[subcolumn.key]
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                )}{' '}
                                            &nbsp;
                                        </span>
                                    </TableCell>
                                );
                            });
                        })}
                    </TableRow>
                    <TableRow selected>
                        <TableCell align="left" className={classes.changeLabel}>
                            {'% Change'}
                        </TableCell>
                        {columns.map((column) => {
                            if (!column.isShown) return null;

                            const change = getChange(row, column.key);

                            if (!column.subcolumns)
                                return (
                                    <TableCell align="center">
                                        <span
                                            className={
                                                change > 0
                                                    ? classes.positiveRate
                                                    : change < 0
                                                    ? classes.negativeRate
                                                    : classes.rowValue
                                            }
                                        >{`${change.toFixed(2)}%`}</span>
                                    </TableCell>
                                );

                            return column.subcolumns.map((subcolumn) => {
                                return (
                                    <TableCell align="center">
                                        <span
                                            className={classes.rowValue}
                                        >{`${change.toFixed(2)}%`}</span>
                                    </TableCell>
                                );
                            });
                        })}
                    </TableRow>
                </Fragment>
            )}
        </Fragment>
    );
};

export default withState(usedStates)(
    withProps(usedProps)(withStyles(styles)(StatsTableRow))
);
