import React from 'react';
import DialogBox from '../../../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import ResultsTable from '../../../../components/ResultsTable/ResultsTable';
import { useCustomMetaMethods, useCustomMetaState } from './CustomMetaProvider';
import MetaViewer from '../MetaViewer';
import { GA_URL, META_PAGE_MAPPER } from '../../../../config/constants';
import urlParser from '../../../../utilities/urlParser';
import { trimSlash } from '../../../../helpers/dataUtility';
import { DIRECTORIES } from '../../../../utilities/directory';
import { sendRequest } from '../../../../helpers/apiRequestUtility';
import { useSnackbar } from 'notistack';
import { SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';

export default function ResultsMain() {
    const {
        data,
        count,
        page,
        dialog,
        rowsPerPage,
        fetching,
        selectedMeta,
        setCustomMetaState,
        deletingMeta,
        fetchMeta,
        selected
    } = useCustomMetaState();

    const { deleteMeta } = useCustomMetaMethods();
    const { enqueueSnackbar } = useSnackbar();
    const columns = [
        {
            id: 'directory',
            key: 'directory',
            name: 'Directory',
            type: 'text'
        },
        {
            id: 'page_type',
            key: 'pageTypeLabel',
            name: 'Page Type',
            type: 'text'
        },
        {
            id: 'title',
            key: 'title',
            name: 'Page Title',
            type: 'text'
        },
        {
            id: 'description_truncated',
            key: 'descriptionTruncated',
            name: 'Meta Description',
            type: 'text'
        },
        {
            id: 'h1',
            key: 'h1',
            name: 'H1',
            type: 'text'
        },
        {
            id: 'h2',
            key: 'h2',
            name: 'H2',
            type: 'text'
        },

    ];
    const getValueData = async (valueType, id) => {
        return await sendRequest(
            `/search-criteria-options?criteria=${valueType}&id=${id}`,
            ({ search_criteria_options = [] }) => {
                let { alias } = search_criteria_options[0];
                return alias
            }
            ,
            (error) => {
                console.error(error);
            }
        );
    }
    const getCriteriaValue = async (id) => {
        return await sendRequest(`/seo-meta/info?id=${id}`, (json) => {
            return json['seo_meta'][0].program_results_meta_criteria.criteria_value;

        },
            (error) => {
                console.error(error);
            }
        );
    }
    const getPriority = (item) => {
        return Object.keys(META_PAGE_MAPPER).indexOf(item.criteria);
    }
    const generateLink = async (id) => {
        let criteriaData = [];
        let directory = "";
        let link = "";
        let online = false;

        let data = await getCriteriaValue(id);
        let onlineData = data.find(x => x.criteria === "online");
        if (onlineData) {
            online = true;
        }

        data = data.sort((a, b) => getPriority(a) - getPriority(b));

        for (const val of data) {
            if (val.criteria === "directory_id") {
                directory = DIRECTORIES.find((x) => x.id === val.value).alias;
            } else {
                let x = await getValueData(SEARCH_CRITERIA[val.criteria].new_criteria, val.value)
                criteriaData.push(x);
            }

        }
        link = criteriaData.join("/", criteriaData);
        link = `${trimSlash(GA_URL)}/${directory}/search/${online ? "online" : ""}${link}/${directory}-1`
        return link;
    };

    const rowMenus = [
        {
            name: 'viewFrontEndPage',
            text: 'View Frontend Page',
            handler: async ({ id }) => {
                const link = await generateLink(id);
                window.open(link, '_blank');
            }
        },
        {
            name: 'edit',
            text: 'Edit',
            target: '/site-tools/seo-tool/',
            handler: ({ id }) => {
                window.location.href = `/site-tools/seo-tool/${id}`;
            }
        },
        {
            name: 'delete',
            text: 'Delete',
            handler: (row) => {
                handleOpenDialog(
                    'Delete Custom Meta',
                    'Are you sure you want to delete this custom meta?',
                    () => {
                        deleteMeta(false, row);
                        handleCloseDialog();
                    }
                );
            }
        }
    ];
    const batchActions = [
        {
            icon: 'delete',
            handler: () =>
                handleBatchDialogConfirmation('Delete', 'Deleted', () => {
                    deleteMeta(true)
                    handleCloseDialog()
                }),
        }
    ];

    const searchFilters = [
        {
            key: 'page_url',
            name: 'Page URL',
            hasAutoSuggest: false
        }
    ];

    const getData = () => {
        data.forEach((dataDetail) => {
            dataDetail.descriptionTruncated = dataDetail.description.substr(0, 160);
            if (dataDetail.description.length > 160) {
                dataDetail.descriptionTruncated += "..."
            }
        })
        return data;
    };

    const handleBatchDialogConfirmation = (
        batchAction,
        message,
        callback,
        content = null
    ) => {
        let dialog = {
            show: true,
            title: batchAction,
            content:
                content ||
                `Are you sure you want to ${message} set of seo meta/s ?`,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            },
            stringOverride: {
                primaryAction: batchAction,
                secondaryAction: 'Cancel'
            }
        };

        setCustomMetaState({
            dialog: dialog
        });
    };
    const handleRowClick = (row) => {
        setCustomMetaState({ selectedMeta: row })
    }
    const handleOpenDialog = (title, content, callback) => {
        const dialog = {
            actions: 'OkCancel',
            title,
            content,
            show: true,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        };
        setCustomMetaState({ dialog: dialog });
    };

    const handleCloseDialog = () => {
        const dialog = {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => { },
            onClose: () => { }
        };
        setCustomMetaState({ dialog: dialog });
    };
    const handleSelectedMetas = (rows) => {
        setCustomMetaState({
            selected: rows,
        });
    };
    const handleSearchFilterChange = ({ filter, value }) => {
        if (filter === 'page_url') {
            handleFilterByUrl(value);
        }
    }
    const handleCloseSearchFilter = () => {
        setCustomMetaState({
            urlFilter: null,
            selected: []
        })
    }
    const getAliasCriteria = (alias = []) => {
        let slug = [];
        alias.pop();
        if (alias.length < 3) return []
        for (let index = 5; index < alias.length; index++) {
            slug.push(alias[index]);
        }

        return slug;
    }
    const handleFilterByUrl = async (url) => {
        let slug = trimSlash(url).split(`/`);
        let directory = slug[3];
        let criteria = getAliasCriteria(slug);
        let params = "";

        const { hostname: domain } = urlParser(url);
        const { hostname: GA_DOMAIN } = urlParser(GA_URL);

        if (GA_DOMAIN !== domain) {
            enqueueSnackbar('Invalid URL', {
                variant: 'error',
                autoHideDuration: 2000
            });
            return;
        }
        setCustomMetaState({ fetching: true });
        const { id } = (DIRECTORIES.find((data) => data.alias === directory));
        if (criteria.length === 0) {
            params = "criteria[]";
        }
        criteria.forEach((crit, i) => {
            params += `criteria[]=${crit}${(i === criteria.length - 1) ? "" : "&"}`;
        });

        await sendRequest(
            `/directories/${id}/alias/search-options?${params}`,
            ({ search_criteria }) => {
                setCustomMetaState({
                    urlFilter: criteria.includes("online")
                        ? [...search_criteria, { value: 1, criteria: "online" }] :
                        search_criteria,
                    page: 0,
                    selected: [],
                    filters: {}
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    return (
        <>
            <ResultsTable
                data={getData()}
                columns={columns}
                count={count}
                rowMenus={rowMenus}
                batchActions={batchActions}
                searchFilterOptions={searchFilters}
                isLoadingData={fetching}
                hasGlobalFilters={false}
                hasColumnFilters={false}
                label={count > 1 ? 'custom metas' : 'custom meta'}
                rowsPerPage={rowsPerPage}
                page={page}
                selected={selected}
                onRowClick={handleRowClick}
                onSelectedChange={(rows) => handleSelectedMetas(rows)}
                onRowsPerPageChange={(rows) => {
                    setCustomMetaState(
                        {
                            page: 0,
                            rowsPerPage: rows
                        }, fetchMeta
                    )
                }
                }
                onPageChange={(page) =>

                    setCustomMetaState(
                        {
                            page: page
                        },

                    )
                }
                onSearchFilterChange={handleSearchFilterChange}
                onSearchFilterClose={handleCloseSearchFilter}
            />
            <MetaViewer
                open={!!selectedMeta}
                onClose={() => setCustomMetaState({ selectedMeta: null })}
                meta={selectedMeta}
                isCustom
            />
            <DialogBox open={deletingMeta} title={'Processing...'} actions="">
                <LinearProgress />
            </DialogBox>

            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.onOk}
                onCancel={dialog.onCancel}
                onClose={dialog.onCancel}
                stringOverride={dialog.stringOverride}
                setSelectedRow={() => { }}
            />
        </>
    );
}
