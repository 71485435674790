import React from 'react';
import ResultsMain from './ResultsMain';
import DataFilters from './DataFilters';
import DataFiltersDrawer from './DataFiltersDrawer';

export default function DirectorySRDefaultMeta({ active }) {
    if (!active) return null;

    return (
        <>
            <DataFilters />
            <DataFiltersDrawer />
            <ResultsMain />
        </>
    );
}
