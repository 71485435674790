import React, { Fragment, useEffect, useState } from 'react';
import ResultsTable from '../../../components/ResultsTable/ResultsTable';
import DialogBox from '../../../components/DialogBox/DialogBox';
import TopicDrawer from './TopicDrawer';
import { useTopicsMethods, useTopicsState } from './TopicProvider';
import { useObjectState } from '../../../utilities/customHooks';
import { GA_URL } from '../../../config/constants';
import { trimSlash } from '../../../helpers/dataUtility';
import LoadingDialog from '../../../components/Loader/LoadingDialog';

const ResultsMain = () => {
    const {
        sortBy,
        sortingOrder,
        count,
        data,
        topics,
        page,
        rowsPerPage,
        filters,
        selected,
        dialog,
        fetching,
        deleting
    } = useTopicsState();
    const {
        handleEditTopic,
        handleConfirmDelete,
        handleSorting,
        onSelectedChange,
        handleCancelAction,
        handleCloseAction,
        handleConfirmBatchDelete,
        setSelectedTopic,
        setTopicsState,
        applyFilters
    } = useTopicsMethods();
    const [topicNames, setTopicNames] = useState([]);
    const [columnFilters, setColumnFilters] = useObjectState({});

    useEffect(() => {
        setTopicNames(
            [...topics].map(({ id, name }) => ({
                value: id,
                label: name
            }))
        );
    }, [topics]);

    const columns = [
        {
            name: 'Topic Name',
            format: 'bold',
            key: 'name',
            filter: {
                type: 'select',
                options: topicNames,
                value: columnFilters.name,
                onChange: (name) => setColumnFilters({ name: name })
            }
        },
        {
            name: 'Number of Associated Articles',
            format: 'number',
            id: 'articles-count',
            key: 'articles_count',
            type: 'number',
            sort: sortBy === 'articles_count' ? sortingOrder : null,
            urlKey: 'filterUrl',
            filter: {
                type: 'number',
                value: columnFilters.articles_count,
                onChange: ({ target: { value } }) =>
                    setColumnFilters({ articles_count: value })
            }
        }
    ];

    const rowMenus = [
        { name: 'view', text: 'View', target: '_blank' },
        { name: 'edit', text: 'Edit', handler: handleEditTopic },
        { name: 'delete', text: 'Delete', handler: handleConfirmDelete }
    ];

    const rowMenusChecker = (row, menus) =>
        menus.map((menu) => {
            if (menu.name === 'view')
                menu.url = `${trimSlash(GA_URL)}/articles/${row.alias}`;
            return menu;
        });

    const batchActions = [
        {
            icon: 'delete',
            handler: handleConfirmBatchDelete
        }
    ];

    const onCloseColumnFilters = () => {
        const otherKeys = {};
        const filterKeys = columns
            .filter(({ filter }) => !!filter)
            .map(({ key }) => key);
        Object.keys(filters || {})
            .filter((key) => !filterKeys.includes(key))
            //eslint-disable-next-line array-callback-return
            .map((key) => {
                otherKeys[key] = filters[key];
            });

        setColumnFilters({
            name: null,
            articlesCount: undefined,
            guidesCount: undefined
        });
        setTopicsState({ filters: { ...otherKeys }, page: 0 }, applyFilters);
    };

    const onApplyColumnFilters = () => {
        const otherKeys = {};
        const filterKeys = columns
            .filter(({ filter }) => !!filter)
            .map(({ key }) => key);
        Object.keys(filters || {})
            .filter((key) => !filterKeys.includes(key))
            //eslint-disable-next-line array-callback-return
            .map((key) => {
                otherKeys[key] = filters[key];
            });

        setTopicsState(
            { filters: { ...columnFilters, ...otherKeys }, page: 0 },
            applyFilters
        );
    };

    const handlePageChange = (page) => {
        setTopicsState({ page }, applyFilters);
    };

    const handleRowsPerPageChange = (rowsPerPage) => {
        setTopicsState({ rowsPerPage }, applyFilters);
    };

    return (
        <Fragment>
            <TopicDrawer />
            <ResultsTable
                columns={columns}
                label={count > 1 ? 'topics' : 'topic'}
                hasSearch={false}
                hasGlobalFilters={false}
                data={data}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenusChecker}
                onColumnFilterClose={onCloseColumnFilters}
                onColumnFiltersApply={onApplyColumnFilters}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                selected={selected}
                onSelectedChange={onSelectedChange}
                batchActions={batchActions}
                onColumnHeaderClick={handleSorting}
                isLoadingData={fetching}
            />

            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.action}
                onCancel={handleCancelAction}
                onClose={handleCloseAction}
                stringOverride={dialog.stringOverride}
                setSelectedRow={setSelectedTopic}
            />
            <LoadingDialog open={deleting} message="Deleting topic(s) ..." />
        </Fragment>
    );
};

export default ResultsMain;
