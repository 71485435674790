import CloseIcon from '@material-ui/icons/CloseRounded';
import DoneIcon from '@material-ui/icons/Done';
import React, { Component } from 'react';
import SearchBasedDropdown from '../../../../components/SearchBasedDropdown/SearchBasedDropdown';
import SearchIcon from '@material-ui/icons/Search';
import Sort from '@material-ui/icons/Sort';
import {
    Grid,
    IconButton,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';

const styles = (theme) => ({
    heading: {
        marginBottom: theme.spacing(1),
        color: '#555',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(3),
    },
    label: {
        padding: '0',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#555'
    },
    columnIcon: {
        fontSize: 20
    },
    column: {
        width: 250
    },
    headerWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    columnHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});

class FeaturesTableHeader extends Component {
    state = {
        showClientSearch: false,
        showProgramSearch: false
    };

    toggleSearch = (search, value) => {
        this.setState({ [search]: value });
    };

    closeAndClearFilter = (filter, column) => {
        this.props.handleClearFilter(filter);
        this.toggleSearch(column, false);
    };

    render() {
        const {
            classes,
            reorder,
            disableRank,
            saveNewOrder,
            handleReorder,
            handleAddColumnFilter,
            filters,
            webFeatures,
            disableSearchFilter
        } = this.props;
        const { showClientSearch, showProgramSearch } = this.state;

        return (
            <Grid className={classes.heading} container spacing={32}>
                <Grid item xs={2} className={classes.headerWrap}>
                    <div className={classes.columnHeader}>
                        <Typography className={classes.label}>
                            Ranking
                        </Typography>
                        {reorder ? (
                            <Tooltip title="Save new order" placement="top">
                                <IconButton
                                    className={classes.columnIcon}
                                    onClick={saveNewOrder}
                                >
                                    <DoneIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Reorder" placement="top">
                                <IconButton
                                    className={classes.columnIcon}
                                    onClick={handleReorder}
                                    aria-label="reorder"
                                    size="small"
                                    disabled={disableRank}
                                >
                                    <Sort fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.headerWrap}>
                    {showClientSearch ? (
                        <div className={classes.columnHeader}>
                            <div className={classes.column}>
                                <SearchBasedDropdown
                                    valueKey="client_id"
                                    labelKey="client_name"
                                    arrayOptions={webFeatures}
                                    value={filters.client_filter}
                                    handleChange={handleAddColumnFilter(
                                        'client_filter'
                                    )}
                                    isDisabled={disableSearchFilter}
                                />
                            </div>
                            <IconButton
                                className={classes.columnIcon}
                                onClick={() =>
                                    this.closeAndClearFilter(
                                        'client_filter',
                                        'showClientSearch'
                                    )
                                }
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    ) : (
                        <div className={classes.columnHeader}>
                            <Typography className={classes.label}>
                                Client Name
                            </Typography>
                            <IconButton
                                className={classes.columnIcon}
                                onClick={() =>
                                    this.toggleSearch('showClientSearch', true)
                                }
                            >
                                <SearchIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    )}
                </Grid>
                <Grid item xs={4} className={classes.headerWrap}>
                    {showProgramSearch ? (
                        <div className={classes.columnHeader}>
                            <div className={classes.column}>
                                <SearchBasedDropdown
                                    valueKey="program_id"
                                    labelKey="program_name"
                                    arrayOptions={webFeatures}
                                    value={filters.program_filter}
                                    handleChange={handleAddColumnFilter(
                                        'program_filter'
                                    )}
                                    isDisabled={disableSearchFilter}
                                />
                            </div>
                            <IconButton
                                className={classes.columnIcon}
                                onClick={() =>
                                    this.closeAndClearFilter(
                                        'program_filter',
                                        'showProgramSearch'
                                    )
                                }
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    ) : (
                        <div className={classes.columnHeader}>
                            <Typography className={classes.label}>
                                Program Name
                            </Typography>
                            <IconButton
                                className={classes.columnIcon}
                                onClick={() =>
                                    this.toggleSearch('showProgramSearch', true)
                                }
                            >
                                <SearchIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    )}
                </Grid>
                <Grid item xs={2} className={classes.headerWrap}>
                    <div className={classes.columnHeader}>
                        <Typography className={classes.label}>
                            Directory
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={1} className={classes.headerWrap} />
            </Grid>
        );
    }
}

export default withStyles(styles)(FeaturesTableHeader);
