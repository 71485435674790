import { useSnackbar } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { InterviewsContext } from '../../context';
import { sendRequest, postRequest } from '../../helpers/apiRequestUtility';
import {
    constructHttpParams,
    capitalizeFirstLetter
} from '../../helpers/dataUtility';
import { useGlobalState } from '../Client/GlobalProvider';
import { useObjectState } from '../../utilities/customHooks';
import { useHistory } from 'react-router';
import moment from 'moment';

const { Provider } = InterviewsContext;

export const COLUMN_FILTERS = [
    'interviewee_name',
    'provider_id',
    'date_added',
    'date_published'
];

export const GLOBAL_FILTERS = [
    'interview_type',
    'publication_status',
    'listing_status'
];

const InterviewProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const {
        handleStateChange: setGlobalState
    } = useGlobalState();
    const [state, setInterviewState] = useObjectState({
        data: [],
        count: 0,
        filters: {},
        columnFilters: {},
        rowsPerPage: 10,
        page: 0,
        fetching: false,
        sortKey: 'interview_date',
        sortDirection: 'desc',
        selected: [],
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: ''
        },
        isSaving: false
    });

    const fetchInterviews = () => {
        const { page, rowsPerPage, sortKey, sortDirection, filters } = state;
        let filterParams = filters;
        if (filters.listing_status === 'none') {
            filterParams = { ...filters, listing_status: 0, program_id: 0 };
        }

        const rank = sortDirection === 'desc' ? '-' : '+';
        const params = constructHttpParams({
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            fields:
                'id,title,status,type,provider_id,date_added,interview_date,interviewee,listing_status,program_id,alias',
            sort: `${rank}${sortKey},${rank}id`,
            wildcards: filters['interviewee_name'] ? 'interviewee_name' : null,
            ...filterParams
        });

        setInterviewState({ fetching: true });
        sendRequest(
            `/interviews?${params}`,
            ({ interviews, count }) => {
                if (filters.listing_status === 0) {
                    interviews = interviews.filter((interview) => {
                        return interview.program_id !== 0;
                    });
                    count = interviews.length;
                }
                setInterviewState({ data: interviews, count });
            },
            () => {
                setInterviewState({ data: [], count: 0 });
                enqueueSnackbar(
                    'Failed fetching interviews. Please try again.',
                    { variant: 'error' }
                );
            }
        ).finally(() => setInterviewState({ fetching: false }));
    };

    useEffect(() => {
        fetchInterviews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.page,
        state.rowsPerPage,
        state.sortDirection,
        state.sortKey,
        state.filters
    ]);

    const handleUpdateInterviews = async (action, isBatch, interview) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch
            ? selected
                  .filter((row) => {
                      if (action === 'publish') {
                          return row.status !== 1;
                      } else if (action === 'unpublish') {
                          return row.status !== 0;
                      }
                      return true;
                  })
                  .map((row) => row.id)
            : [interview.id];

        data.append('id', selectedIds.join());

        if (action === 'publish') {
            data.append('status', 1);
        } else if (action === 'unpublish') {
            data.append('status', 0);
        }

        setInterviewState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            setInterviewState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/interviews/update`,
                data,
                (success) => {
                    setInterviewState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Interviews have been updated!`,
                        {
                            variant: 'success'
                        }
                    );
                    fetchInterviews();
                },
                (error) => {
                    setInterviewState({ isSaving: false });
                    enqueueSnackbar('Failed to update Interviews.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Interviews have been updated.', {
                variant: 'error'
            });
        }
    };

    const handleAddInterview = async (interviewData) => {
        const data = new FormData();
        const title = `${interviewData.provider.label} ${
            interviewData.type.label
        } Interview with ${capitalizeFirstLetter(interviewData.name)}`;
        setGlobalState({ interviewDrawerOpen: false })
        
        const alias = interviewData.name
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');

        const type = interviewData.type.value === 'alumni' ? 1 : 2
        var metaTitle = interviewData.name + " | " + interviewData.provider.label;
        if (metaTitle.length < 49){
            metaTitle += ' ' + interviewData.type.label + " Interview"
        } else if (metaTitle.length > 65){
            metaTitle = metaTitle.substring(0, 62) + '...';
        }

        const metaDescription = type === 1 ?
                    "Check out this GoAbroad Interview with " + interviewData.name +  " who participated in " + interviewData.provider.label + " program." :
                    "Get to know the people behind " + interviewData.provider.label + "! Check out this GoAbroad Staff Interview with " + interviewData.name + "."

        data.append('provider_id', interviewData.provider.value);
        data.append(
            'program_id',
            interviewData.program ? interviewData.program.value : null
        );

        data.append('interviewee_name', interviewData.name);
        data.append('type', type);
        data.append('alias', alias);
        data.append('title', title);
        data.append('status', 0);
        data.append('show_bio', 1);
        data.append('meta_title', metaTitle);
        data.append('meta_description', metaDescription);
        data.append(
            'date_added',
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        );

        await postRequest(
            `/interviews`,
            data,
            (response) => {
                enqueueSnackbar('Interview created!', {
                    variant: 'success'
                });
                history.push(`/interviews/edit/${response.id}`);
            },
            (error) => {
                enqueueSnackbar('Failed to create an Interview.', {
                    variant: 'error'
                });
            }
        );
    };

    const handleDeleteInterviews = async (isBatch, interview) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch
            ? selected.map((row) => row.id)
            : [interview.id];

        data.append('id', selectedIds.join());

        setInterviewState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            setInterviewState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/interviews/delete`,
                data,
                (success) => {
                    setInterviewState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Interviews have been deleted!`,
                        {
                            variant: 'success'
                        }
                    );
                    fetchInterviews();
                },
                (error) => {
                    setInterviewState({ isSaving: false });
                    enqueueSnackbar('Failed to delete Interviews.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Interviews have been deleted.', {
                variant: 'error'
            });
        }
        
        if (isBatch){
            state.selected.forEach((selectedInterview) => {
                updateDuplicates(selectedInterview.interviewee_name, selectedInterview.provider_id, selectedInterview.type)
            })
        } else {
            updateDuplicates(interview.interviewee_name, interview.provider_id, interview.type)
        }
        
    };

    const updateDuplicates = (interviewee_name, provider_id, type) => {
            fetchDuplicateInterviews(interviewee_name, provider_id, type).then((duplicateInterviews)=>{
                if (duplicateInterviews.length === 1){
                    let newMetaTitle = duplicateInterviews[0].interviewee_name + ' | ' + duplicateInterviews[0].provider_name;
                    if (newMetaTitle.length < 49){
                        newMetaTitle += ' ' + (duplicateInterviews[0].type === 1 ? 'Alumni' : 'Staff') + " Interview"
                    } else if (newMetaTitle.length > 65){
                        newMetaTitle = newMetaTitle.substring(0, 62) + '...';
                    } 
                    const newMetaDescription = duplicateInterviews[0].type === 1 ?
                        "Check out this GoAbroad Interview with " + duplicateInterviews[0].interviewee_name +  " who participated in " + duplicateInterviews[0].provider_name + " program." :
                        "Get to know the people behind " + duplicateInterviews[0].provider_name + "! Check out this GoAbroad Staff Interview with " + duplicateInterviews[0].interviewee_name + "."
                
                    const data = new FormData();
                    data.append('meta_title', newMetaTitle);
                    data.append('meta_description', newMetaDescription);
                    postRequest(
                        `/interviews/${duplicateInterviews[0].id}/update`,
                        data,
                    );
                }
            })
    }

    const fetchDuplicateInterviews = (interviewee_name, provider_id, type) =>
        new Promise((res, rej) => {
            return sendRequest(
                `/interviews?interviewee_name=${interviewee_name}&provider_id=${provider_id}&type=${type}`,
                ({ interviews = [] }) => res(interviews || {}),
                () => rej({})
            );
        });

    const handleShowActionConfirmation = (batchAction, func) => {
        setInterviewState({
            dialog: {
                show: true,
                title: batchAction,
                content: `Are you sure you want to ${batchAction} the selected interview(s)?`,
                stringOverride: {
                    primaryAction: batchAction,
                    secondaryAction: 'Cancel'
                },
                onOk: func
            }
        });
    };

    const handleCloseDialog = () => {
        setInterviewState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {}
            }
        });
    };

    const handleSelectMultiple = (selected) => {
        setInterviewState({
            selected
        });
    };

    return (
        <Provider
            value={{
                state,
                setInterviewState,
                handleUpdateInterviews,
                handleShowActionConfirmation,
                handleCloseDialog,
                handleSelectMultiple,
                handleAddInterview,
                handleDeleteInterviews
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useInterviewState = () => {
    const { state, ...methods } = useContext(InterviewsContext);
    return { ...state, ...methods };
};

export default InterviewProvider;
