import React, { PureComponent } from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withState, withProps } from './ResultsTableProvider';

const usedState = ['page', 'rowsPerPage'];
const usedProps = [
    'count',
    'rowsPerPageOptions',
    'isLoadingData',
    'onPageChange',
    'onRowsPerPageChange',
];
class ResultsTableFooter extends PureComponent {
    handlePageChange = (e, page) => {
        const { handleStateChanged: setState, onPageChange } = this.props;
        !!onPageChange && onPageChange(page);
        setState({ page });
    };

    handleRowsPerPageChange = (e) => {
        const { handleStateChanged: setState, onRowsPerPageChange } =
            this.props;
        !!onRowsPerPageChange && onRowsPerPageChange(e.target.value);
        setState({ rowsPerPage: e.target.value });
    };

    render() {
        const {
            page,
            count,
            rowsPerPage,
            rowsPerPageOptions,
            isLoadingData = false,
        } = this.props;
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        count={count || 0}
                        rowsPerPage={rowsPerPage}
                        SelectProps={{ disabled: isLoadingData }}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                            disabled: isLoadingData || page === 0,
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                            disabled:
                                isLoadingData ||
                                page + 1 >= Math.ceil(count / rowsPerPage),
                        }}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={this.handleRowsPerPageChange}
                        rowsPerPageOptions={
                            rowsPerPageOptions || [5, 10, 15, 20, 50]
                        }
                    />
                </TableRow>
            </TableFooter>
        );
    }
}

export default withState(usedState)(withProps(usedProps)(ResultsTableFooter));
