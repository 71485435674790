import React, { useContext, useEffect } from 'react';
import { ApplicationContext } from '../../../context';
import { useObjectState } from '../../../utilities/customHooks';
import { useGlobalMethods } from '../../Client/GlobalProvider';
import {
    constructHttpParams,
    formatRangeParam,
    removeNull,
} from '../../../helpers/dataUtility';
import { useParams } from 'react-router-dom';

const { Provider } = ApplicationContext;

const ApplicationProvider = (props) => {
    const { authenticatedGetRequest: sendRequest } = useGlobalMethods();
    const { id } = useParams();
    const [state, setState] = useObjectState({
        data: [],
        filters: {},
        sort: 'updated_at',
        sortOrder: 'desc',
        rowsPerPage: 10,
        page: 0,
        count: 0,
        selectedApplication: null,
        edit: false,
        fetching: false,
        showGlobalFilters: false,
        showColumnFilters: false,
        saving: false,
    });

    const fetchApplications = async () => {
        setState({ fetching: true });
        if (!!id) {
            state.filters.provider_id = id;
        }
        const derivedFilters = {
            name: state.filters.name,
            email: state.filters.email,
            date_saved: formatRangeParam(state.filters.date_saved),
            updated_at: formatRangeParam(state.filters.updated_at),
            age_range: formatRangeParam(state.filters.age),
            citizenship_id: (state.filters.country_name || []).join(','),
            education_status_id: (
                state.filters.education_status_name || []
            ).join(','),
            provider_id: state.filters.provider_id,
            exclude_goabroad: state.filters.exclude_goabroad,
            sort: `${state.sortOrder === 'asc' ? '+' : '-'}${state.sort}`,
            wildcards: removeNull([
                !!state.filters.name ? 'name' : null,
                !!state.filters.email ? 'email' : null,
            ]).join(','),
        };
        const params = constructHttpParams({
            limit: state.rowsPerPage,
            offset: state.rowsPerPage * state.page,
            ...derivedFilters,
        });
        return sendRequest(
            `/applications?${params}`,
            (data) => data,
            () => {},
            fetchApplications
        )
            .then(({ applications = [], count } = {}) => {
                setState({
                    data: (applications || []).map((application) => {
                        application.email =
                            application.application_email || application.email;
                        return application;
                    }),
                    count: count || applications.length || 0,
                });
            })
            .finally(() => setState({ fetching: false }));
    };

    useEffect(() => {
        fetchApplications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.page,
        state.rowsPerPage,
        state.sort,
        state.sortOrder,
        state.filters,
    ]);

    return (
        <Provider
            value={{
                state,
                setApplicationState: setState,
                fetchApplications,
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useApplicationState = () => {
    const { state, setApplicationState } = useContext(ApplicationContext);
    return { ...state, setApplicationState };
};

export const useApplicationMethods = () => {
    const { state, ...methods } = useContext(ApplicationContext);
    return methods;
};

export default ApplicationProvider;
