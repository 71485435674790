import React, { Fragment, useState } from 'react';
import AdIcon from '@material-ui/icons/InsertPhoto';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ClientIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DescriptionIcon from '@material-ui/icons/Description';
import CreateAccountDrawer from './CreateAccountDrawer';
import IconButton from '@material-ui/core/IconButton';
import ImportReviewsDrawer from './ImportReviewsDrawer';
import ExportTopRatedProvidersDrawer from './ExportTopRatedProvidersDrawer';
import ExportInvoicesDrawer from './ExportInvoicesDrawer';
import ListingIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Notes';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { NavLink, useLocation, Switch, Route } from 'react-router-dom';
import ReviewIcon from '@material-ui/icons/LibraryBooks';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import blueGrey from '@material-ui/core/colors/blueGrey';
import classNames from 'classnames';
import grey from '@material-ui/core/colors/grey';
import ArticleIcon from '@material-ui/icons/LibraryBooks';
import { makeStyles } from '@material-ui/styles';
import dashboardRoutes from '../../routes/dashboard';
import { useGlobalState } from '../../views/Client/GlobalProvider';
import { SnackbarProvider, useSnackbar } from 'notistack';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { getCustHeaderName } from '../../routes/customHeaderNames';
import StarIcon from '@material-ui/icons/Star';
import RunTopPick from '@material-ui/icons/Bookmark';
import { useAuthenticatedRequest } from '../../views/Client/GlobalProvider';
import { useObjectState } from '../../utilities/customHooks';
import DialogBox from '../../components/DialogBox/DialogBox';

const drawerWidthExpanded = 200;
const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
    appBar: {
        width: `calc(100% - ${drawerWidthExpanded}px)`,
        marginLeft: drawerWidthExpanded,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '& svg': {
            color: grey[500],
        },
    },
    sidebarExpanded: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    toolbar: {
        minHeight: drawerWidth,
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    menuButton: {
        marginRight: theme.spacing.unit * 2,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },

    grow: {
        flexGrow: 1,
    },
    avatar: {
        marginRight: theme.spacing.unit,
    },
    userName: {
        color: blueGrey[500],
        marginRight: theme.spacing.unit * 3,
    },
    menuIcon: {
        color: grey[500],
    },
    createCTA: {
        marginRight: theme.spacing.unit * 2,
        '& svg': {
            color: theme.palette.common.white,
            marginRight: theme.spacing.unit,
        },
    },
    notification: {
        flexShrink: 0,
        marginRight: theme.spacing.unit,
    },
    views: {
        marginLeft: theme.spacing.unit * 4,
    },
    viewsDropdown: {
        minWidth: 180,
    },
    selectMenu: {
        padding: 10,
        '&:focus': {
            background: 'none',
        },
    },
    ctaLinks: {
        textDecoration: 'none',
    },
    dialog: {
        overflow: 'visible',
    },
    content: {
        overflow: 'visible',
    },
}));

const Header = ({ handleSidebarExpand, sidebarExpanded, name }) => {
    const classes = useStyles();
    const location = useLocation();
    const { handleStateChange } = useGlobalState();
    const { sendRequest } = useAuthenticatedRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [openCreateAccount, setOpenCreateAccount] = useState(false);
    const [openImportReviews, setOpenImportReviews] = useState(false);
    const [openExportInvoices, setOpenExportInvoices] = useState(false);
    const [openExportTopRatedProviders, setOpenExportTopRatedProviders] =
        useState(false);
    const [state, setState] = useObjectState({
        dialog: {
            showDialog: false,
            title: '',
            content: '',
            onOk: () => { },
            onCancel: () => { },
            stringOverride: {}
        },
    });

    const makeBrand = () => {
        const { pathname } = location;
        const [route = {}] = dashboardRoutes.filter(
            ({ path }) => pathname === path || pathname.startsWith(path)
        );
        const customHeaderName = getCustHeaderName(pathname);
        const { name } = route;
        return customHeaderName || name;
    };

    const handleTopPicksScript = async () => {
        setState({
            dialog: { showDialog: false }
        });
        await sendRequest(
            `programs/run-top-picks-script`,
            (json) => {
                enqueueSnackbar('GoAbroad Picks successfully refreshed!', {
                    variant: 'success'
                })
            },
            (error) => {
                enqueueSnackbar('There was an error updating GoAbroad Picks', {
                    variant: 'error'
                });
            },
            handleTopPicksScript
        );
    };

    const handleOpenDialog = (title, content, callback) => {
        const dialog = {
            actions: 'OkCancel',
            title,
            content,
            showDialog: true,
            onOk: () => {
                callback();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        };
        setState({ dialog: dialog });
    };

    const handleCloseDialog = () => {
        const dialog = {
            showDialog: false
        };

        setState({
            dialog: dialog,
        });
    };

    const { dialog } = state;

    const renderCTA = () => {
        return (
            <Switch>
                <Route path="/client-accounts" exact>
                    <Button
                        id="create-account-btn"
                        className={classes.createCTA}
                        data-cy="create-account-btn"
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => setOpenCreateAccount(true)}
                    >
                        <ClientIcon />
                        Create Account
                    </Button>
                </Route>
                <Route path="/client-accounts/programs">
                    <Button
                        // data-cy="button-add-deal"
                        id="top-picks-script"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => {
                            handleOpenDialog(`Rank GoAbroad Picks`,
                                `This will refresh GoAbroad Picks on the MyGoAbroad user accounts.`,
                                () => {
                                    handleTopPicksScript();
                                });
                        }}
                    >
                        <RunTopPick />
                        Rank GoAbroad Picks
                    </Button>
                    <NavLink to="/listing/create" className={classes.ctaLinks}>
                        <Button
                            data-cy="create-program"
                            id="create-listing-btn"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            <ListingIcon />
                            Create Program
                        </Button>
                    </NavLink>
                </Route>
                <Route path="/client-accounts/invoices">
                    <Fragment>
                        <Button
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => setOpenExportInvoices(true)}
                        >
                            <DownloadIcon />
                            Export Report
                        </Button>
                        <NavLink
                            to="/sales-and-collections-summary"
                            className={classes.ctaLinks}
                        >
                            <Button
                                id="create-invoice-btn"
                                className={classes.createCTA}
                                color="primary"
                                variant="contained"
                                size="large"
                            >
                                <MonetizationOnIcon />
                                Sales And Collections
                            </Button>
                        </NavLink>
                        <NavLink to="/collections" className={classes.ctaLinks}>
                            <Button
                                id="create-invoice-btn"
                                className={classes.createCTA}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={() => { }}
                            >
                                <AccountBalanceWalletIcon />
                                Collections
                            </Button>
                        </NavLink>
                        <NavLink
                            to="/invoice/create"
                            className={classes.ctaLinks}
                        >
                            <Button
                                id="create-invoice-btn"
                                className={classes.createCTA}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={() => { }}
                            >
                                <DescriptionIcon />
                                Create Invoice
                            </Button>
                        </NavLink>
                    </Fragment>
                </Route>
                <Route path="/client-accounts/ads">
                    <NavLink to="/ad/create" className={classes.ctaLinks}>
                        <Button
                            data-cy="create-ad"
                            id="create-ad-btn"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            <AdIcon />
                            Create Ad
                        </Button>
                    </NavLink>
                </Route>
                <Route path="/articles">
                    <Button
                        data-cy="btn-export-article"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ articleExportDialogOpen: true })
                        }
                    >
                        <ArticleIcon />
                        Export Articles
                    </Button>
                    <Button
                        data-cy="btn-add-article"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ articleDrawerOpen: true })
                        }
                    >
                        <ArticleIcon />
                        Add Article
                    </Button>
                </Route>
                <Route path="/authors">
                    <Button
                        data-cy="create-author-CTA"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ authorDrawerOpen: true })
                        }
                    >
                        <PersonAddIcon />
                        Add Author
                    </Button>
                </Route>
                <Route path="/topics">
                    <Button
                        data-cy="add-topic-button"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ topicDrawerOpen: true })
                        }
                    >
                        <NoteAddIcon />
                        Add Topic
                    </Button>
                </Route>
                <Route path="/client-accounts/reviews">
                    <Fragment>
                        {/* <Button
                            id="review-widget-genrator-btn"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            <StarIcon />
                            Review Widget Generator
                        </Button> */}
                        <NavLink
                            to="/review-widget-generator"
                            className={classes.ctaLinks}
                        >
                            <Button
                                id="review-widget-genrator-btn"
                                className={classes.createCTA}
                                color="primary"
                                variant="contained"
                                size="large"
                            >
                                <StarIcon />
                                Review Widget Generator
                            </Button>
                        </NavLink>
                        <Button
                            id="export-top-rated-providers-btn"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => setOpenExportTopRatedProviders(true)}
                        >
                            <ReviewIcon />
                            Export Top Rated Providers
                        </Button>
                        <Button
                            data-cy="import-review-btn"
                            id="import-reviews-btn"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() => setOpenImportReviews(true)}
                        >
                            <ReviewIcon />
                            Import Reviews
                        </Button>
                    </Fragment>
                </Route>
                <Route path="/guides">
                    <Button
                        data-cy="add-guide-button"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ guidesDrawerOpen: true })
                        }
                    >
                        <AddBoxIcon />
                        Add Guide
                    </Button>
                </Route>
                <Route path={['/scholarships', '/scholarships-ranking']}>
                    <Button
                        id="add-scholarships"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ scholarshipDrawerOpen: true })
                        }
                    >
                        <AddBoxIcon />
                        Add Scholarship
                    </Button>
                </Route>
                <Route path={['/mygoabroad']} exact>
                    <NavLink 
                        className={classes.ctaLinks}
                        to="/mygoabroad/deals/add"
                    >
                        <Button
                            data-cy="button-add-deal"
                            id="add-deals"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            <AddBoxIcon />
                            Add Deal
                        </Button>
                    </NavLink>
                </Route>
                <Route path="/interviews">
                    <Button
                        data-cy="btn-create-interview"
                        id="add-interview-btn"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ interviewDrawerOpen: true })
                        }
                    >
                        <ReviewIcon />
                        Add Interview
                    </Button>
                </Route>
                <Route path="/guides">
                    <Button
                        data-cy="add-guide-button"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ guidesDrawerOpen: true })
                        }
                    >
                        <AddBoxIcon />
                        Add Guide
                    </Button>
                </Route>
                <Route path="/collections">
                    <NavLink
                        to="/sales-and-collections-summary"
                        className={classes.ctaLinks}
                    >
                        <Button
                            id="create-invoice-btn"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            <MonetizationOnIcon />
                            Sales And Collections
                        </Button>
                    </NavLink>
                    <Button
                        id="export-collection-btn"
                        className={classes.createCTA}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleStateChange({ collectionExportDialogOpen: true })
                        }
                    >
                        <DownloadIcon />
                        Export Collection
                    </Button>
                </Route>
                <Route path={['/site-tools', '/site-tools/default-meta']} exact>
                    <NavLink
                        to="/site-tools/seo-tool"
                        className={classes.ctaLinks}
                    >
                        <Button
                            data-cy="add-default-meta"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() =>
                                handleStateChange({ defaultMetaDrawer: true })
                            }
                        >
                            <AddBoxIcon />
                            Add New Default Meta
                        </Button>
                    </NavLink>
                </Route>
                <Route path={['/site-tools/custom-meta']} exact>
                    <NavLink
                        to="/site-tools/seo-tool"
                        className={classes.ctaLinks}
                    >
                        <Button
                            data-cy="add-default-meta"
                            className={classes.createCTA}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={() =>
                                handleStateChange({ defaultMetaDrawer: true })
                            }
                        >
                            <AddBoxIcon />
                            Add New Custom Meta
                        </Button>
                    </NavLink>
                </Route>
            </Switch>
        );
    };

    return (
        <Fragment>
            <AppBar
                position="absolute"
                className={classNames(classes.appBar, {
                    [classes.sidebarExpanded]: !sidebarExpanded,
                })}
                elevation={1}
            >
                <Toolbar classes={{ root: classes.toolbar }}>
                    <IconButton
                        aria-label="Expand Sidebar"
                        onClick={handleSidebarExpand}
                        className={classes.menuButton}
                    >
                        {sidebarExpanded ? (
                            <CloseIcon className={classes.menuIcon} />
                        ) : (
                            <MenuIcon className={classes.menuIcon} />
                        )}
                    </IconButton>
                    <Typography variant="h6" color="default" noWrap>
                        {name || makeBrand()}
                    </Typography>
                    <div className={classes.grow} />
                    {renderCTA()}
                </Toolbar>
            </AppBar>
            <SnackbarProvider>
                <CreateAccountDrawer
                    open={openCreateAccount}
                    onClose={() => setOpenCreateAccount(false)}
                />
            </SnackbarProvider>
            <ExportInvoicesDrawer
                open={openExportInvoices}
                onClose={() => setOpenExportInvoices(false)}
            />
            <ImportReviewsDrawer
                open={openImportReviews}
                onClose={() => setOpenImportReviews(false)}
            />
            <ExportTopRatedProvidersDrawer
                open={openExportTopRatedProviders}
                onClose={() => setOpenExportTopRatedProviders(false)}
            />
            <DialogBox
                actions={dialog.actions}
                title={dialog.title}
                contentText={dialog.content}
                open={dialog.showDialog}
                onOk={dialog.onOk}
                onCancel={dialog.onCancel}
                onClose={handleCloseDialog}
                disableBackdropClick={true}
            >
            </DialogBox>
        </Fragment>
    );
};

export default Header;
