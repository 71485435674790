import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import loadFile from '../../utilities/load-file';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
    pickerMessage: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: grey[100],

        '& h6, & p': {
            color: '#888'
        }
    },
    error: {
        color: '#e91e63 !important'
    }
});

const DropZone = ({
    onImageChange = () => { },
    minWidth = 100,
    minHeight = 100,
    maxSize = 25,
    hasImage,
    error
}) => {
    const classes = useStyles();
    const {
        acceptedFiles = [],
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'image/*'
    });

    useEffect(() => {
        const [file] = acceptedFiles || [];
        if (!!file)
            loadFile(file).then((image) =>
                onImageChange(image, { type: file.type, size: file.size })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    if (hasImage) return null;

    return (
        <div
            data-cy="image-picker"
            {...getRootProps({ className: classes.pickerMessage })}
        >
            <input {...getInputProps()} />
            <Typography data-cy="upload-instruction" variant="subtitle1">
                Drag and drop image here or click here to select an image
            </Typography>
            <Typography data-cy="aspect-ratio" variant="body2">
                {`Aspect Ratio: ${minWidth}px by ${minHeight}px Max Size: ${maxSize}MB`}
            </Typography>
            <Typography
                className={classes.error}
                variant="body2"
                color="secondary"
                align="center"
            >
                {error}
            </Typography>
        </div>
    );
};

export default DropZone;
