import React from 'react';
import {
    Grid,
    Card,
    Typography,
    Link,
    makeStyles,
    lighten
} from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { useScholarshipRankingState } from './ScholarshipRankingProvider';
import classNames from 'classnames';
import { toValidUrl } from '../../../helpers/dataUtility';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: lighten(grey[100], 0.5)
        }
    },
    reorder: {
        boxShadow: `0 2px 15px 0 rgb(0 0 0 / 5%) !important`,
        marginBottom: theme.spacing(1)
    },
    grid: {
        padding: theme.spacing(2)
    },
    name: {
        fontWeight: 800,
        cursor: 'pointer'
    },
    rank: {
        fontWeight: 800,
        fontSize: 16,
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none',
        fontWeight: 800,
        overflowWrap: 'anywhere',
        color: grey[700]
    },
    dragIcon: {
        display: 'flex',
        marginLeft: 'auto',
        alignSelf: 'center',
        cursor: 'grab'
    },
    deadline: {
        display: 'flex',
        '& span': {
            display: 'inline'
        }
    },
    dragging: {
        visibility: 'hidden'
    },
    opaque: {
        backgroundColor: lighten(grey[100], 0.5)
    }
}));

const ScholarshipCard = ({
    id,
    index,
    name,
    url,
    deadline,
    rank,
    onDrop,
    onClick = () => {}
}) => {
    const classes = useStyles();
    const { reorder, setScholarshipRankingState } =
        useScholarshipRankingState();

    const onDragStart = (e) => {
        setScholarshipRankingState({ dragged_id: index });
    };

    const onDragOver = (e) => {
        e.preventDefault();
        return false;
    };

    const handleDrop = (e) => {
        e.preventDefault();
        onDrop(index);
    };

    const handleClick = () => {
        onClick(id);
    };

    return (
        <Card
            className={classNames({
                [classes.root]: true,
                [classes.reorder]: reorder,
                [classes.opaque]: index > 9
            })}
            component="div"
            draggable={reorder}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={handleDrop}
        >
            <Grid container>
                <Grid item xs={2} className={classes.grid}>
                    <Typography className={classes.rank}>{rank}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                    <Typography onClick={handleClick} className={classes.name}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                    <Link className={classes.link} href={toValidUrl(url)} underline="none" target="_blank">
                        {url}
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={2}
                    className={classNames([classes.grid, classes.deadline])}
                >
                    <Typography component="span">{deadline}</Typography>
                    {reorder && <DragHandle className={classes.dragIcon} />}
                </Grid>
            </Grid>
        </Card>
    );
};

export default ScholarshipCard;
