import React, { Component, useContext } from 'react';
import { UrlVerifierContext } from '../../../context';

import {
    explodeUrlParams,
    constructHttpParams,
    hasValue,
    capitalizeFirstLetter
} from '../../../helpers/dataUtility';
import queryString from 'query-string';
import {
    BOOKMARKABLE_FIELDS,
    isBookmarkable
} from '../../../utilities/boomarkability';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { sendRequest, postRequest } from '../../../helpers/apiRequestUtility';
import { withSnackbar } from 'notistack';
import { transformDateParam } from '../../../utilities/dateFormatter.js';
import { isEmpty } from 'lodash';
import { TEAM_ASSIGNMENT } from '../../../config/constants';

export const GLOBAL_FILTERS = ['status', 'team_id'];

const { Provider, Consumer } = UrlVerifierContext;

class ProviderUrlVerifierProvider extends Component {
    state = {
        data: [],
        selected: [],
        count: 0,
        page: 0,
        rowsPerPage: 10,
        isFetching: false,
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: ''
        },
        isSaving: false,
        disableMarkAsVerified: false,
        disableMarkAsBroken: false,
        filters: {},
        prevFilters: {},
        bookmark: {},
        sort: [],
        label: '',
        globalFiltersStatus: ''
    };

    componentDidMount = () => {
        this.initialize();
        this.browserRouterListen();
    };

    initialize = () => {
        const { history } = this.props;
        let { search = '' } = history.location;
        let search_params = explodeUrlParams(search);
        let bookmark = {};

        if (!!search) {
            //eslint-disable-next-line array-callback-return
            Object.keys(search_params).map((key) => {
                if (
                    BOOKMARKABLE_FIELDS.provider_url_verifiers_view.includes(
                        key
                    )
                ) {
                    if (key === 'page')
                        bookmark.page =
                            search_params[key] >= 1 ? search_params[key] : 1;
                    if (key === 'rowsPerPage')
                        bookmark.rowsPerPage = [5, 10, 15, 20, 50].includes(
                            search_params[key]
                        )
                            ? search_params[key]
                            : 10;
                    bookmark[key] = search_params[key];
                }
            });
        }

        if (Object.keys(bookmark).length === 0) {
            bookmark['status'] = 'broken';
            this.setState({ globalFiltersStatus: 'broken' });
        }

        this.setState(
            {
                ...bookmark,
                filters: bookmark,
                prevFilters: bookmark,
                bookmark,
                globalFiltersStatus: bookmark['status']
            },
            () => {
                this.fetchProviderUrls(0, this.state.rowsPerPage);
            }
        );
    };

    browserRouterListen = () => {
        const { history } = this.props;
        history.listen((location, action) => {
            if (action === 'POP') {
                const filters = queryString.parse(location.search);
                const { page, rowsPerPage } = filters;
                this.setState(
                    {
                        filters,
                        prevFilters: filters,
                        page: page || 1,
                        rowsPerPage: rowsPerPage || 10,
                        bookmark: { ...filters }
                    },
                    () => {
                        this.fetchProviderUrls(0, this.state.rowsPerPage);
                    }
                );
            }
        });
    };

    addValidBookmarkableFields = (params) => {
        let search_params = [];

        Object.keys(params).forEach((param) => {
            if (isBookmarkable('provider_url_verifiers_view', param)) {
                search_params[param] = params[param];
            }
        });

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?${constructHttpParams(search_params)}`
        });
    };

    getTableLabel = (count) => {
        const { filters, globalFiltersStatus } = this.state;
        const labels = [
            hasValue(filters.status)
                ? capitalizeFirstLetter(globalFiltersStatus)
                : '',
            hasValue(filters.team_id) ? TEAM_ASSIGNMENT[filters.team_id] : ''
        ];

        const label = `${labels
            .filter((label) => {
                return label !== '';
            })
            .join(', ')} Provider URL${count > 1 || count === 0 ? 's' : ''}`;

        return label;
    };

    fetchProviderUrls = (offset, limit) => {
        const { page, rowsPerPage, filters, sort } = this.state;

        this.setState({ isFetching: true });

        const {
            client,
            provider_link,
            link_type,
            status,
            date_verified,
            team_id
        } = filters;
        const wildcard = ['url'];

        let sortString = isEmpty(sort)
            ? '-status'
            : sort.map((obj, key) => {
                  return (obj['direction'] === 'asc' ? '+' : '-') + obj['key'];
              });

        let filterParams = {
            provider_id: client || null,
            url: provider_link || null,
            url_type: link_type ? link_type.value : null,
            status: status ? status : null,
            date_last_verified: date_verified
                ? transformDateParam(date_verified)
                : null,
            wildcards: provider_link ? wildcard : null,
            team_id
        };

        const params = this.buildParams({
            offset: offset || rowsPerPage * page,
            limit: limit || rowsPerPage,
            sort: sortString,
            ...filterParams
        });

        sendRequest(
            `/provider-urls?${params}`,
            ({ provider_urls = [], count = 0 } = {}) =>
                this.setState({
                    data: provider_urls,
                    count,
                    label: this.getTableLabel(count)
                })
        ).finally(() => this.setState({ isFetching: false }));
    };

    handleUpdateProviderUrls = async (action, isBatch, providerUrl) => {
        const { enqueueSnackbar } = this.props;
        const data = new FormData();
        const { selected } = this.state;

        let selectedIds = isBatch
            ? selected
                  .filter((provider) => {
                      if (action === 'mark as verified') {
                          return provider.status !== 200;
                      } else if (action === 'mark as broken') {
                          return [200, 301, 302].includes(provider.status);
                      }
                      return true;
                  })
                  .map((provider) => provider.id)
            : [providerUrl.id];

        data.append('id', selectedIds.join());
        data.append(
            'date_last_verified',
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        );

        if (action === 'mark as verified') {
            data.append('status', 200);
        } else if (action === 'mark as broken') {
            data.append('status', 500);
        }

        this.setState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            this.setState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/provider-urls/update`,
                data,
                (success) => {
                    this.setState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Provider URLs have been updated!`,
                        {
                            variant: 'success'
                        }
                    );
                    this.fetchProviderUrls();
                },
                (error) => {
                    this.setState({ isSaving: false });
                    enqueueSnackbar('Failed to update Provider URLs.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Provider URLS have been updated.', {
                variant: 'error'
            });
        }
    };

    handleShowActionConfirmation = (batchAction, func) => {
        this.setState({
            dialog: {
                show: true,
                title: batchAction,
                content: `Are you sure you want to ${batchAction} the selected provider URL(s)?`,
                stringOverride: {
                    primaryAction: batchAction,
                    secondaryAction: 'Cancel'
                },
                onOk: func
            }
        });
    };

    handleCloseDialog = () => {
        this.setState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {}
            }
        });
    };

    handleSelectMultiple = (selected) => {
        this.setState({
            selected
        });
    };

    buildParams = (params = {}) =>
        Object.keys(params)
            .filter((key) => ![undefined, null, NaN, ''].includes(params[key]))
            .map((key) => `${key}=${params[key]}`)
            .join('&');

    setProviderUrlVerifierState = (newState, callback) =>
        this.setState(newState, callback);

    render = () => (
        <Provider
            value={{
                state: this.state,
                setProviderUrlVerifierState: this.setProviderUrlVerifierState,
                fetchProviderUrls: this.fetchProviderUrls,
                addValidBookmarkableFields: this.addValidBookmarkableFields,
                handleShowActionConfirmation: this.handleShowActionConfirmation,
                handleUpdateProviderUrls: this.handleUpdateProviderUrls,
                handleCloseDialog: this.handleCloseDialog,
                handleSelectMultiple: this.handleSelectMultiple
            }}
            children={this.props.children}
        />
    );
}

export const withProviderUrlVerifierContext = (Component) => (props) =>
    (
        <Consumer>
            {(context) => <Component {...props} context={context} />}
        </Consumer>
    );

export const withProviderUrlVerifierContextState =
    (fields) => (Component) => (props) =>
        (
            <Consumer>
                {({ state, ...rest }) => {
                    let extracted = {};
                    //eslint-disable-next-line array-callback-return
                    Object.keys(state).map((key) => {
                        if ((fields || []).includes(key))
                            extracted[key] = state[key];
                    });
                    return (
                        <Component {...{ ...props, ...extracted, ...rest }} />
                    );
                }}
            </Consumer>
        );

export const useProviderUrlVerifierState = () => {
    const { state, setProviderUrlVerifierState } =
        useContext(UrlVerifierContext);
    return { ...state, setProviderUrlVerifierState };
};

export const useProviderUrlVerifierMethods = () => {
    const { state, ...methods } = useContext(UrlVerifierContext);
    return methods;
};

export default withRouter(withSnackbar(ProviderUrlVerifierProvider));
