import React from 'react';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    zoomContainer: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        height: '2rem',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: '5px',
        padding: '0'
    },
    sliderTrack: {
        backgroundColor: '#fff'
    },
    sliderThumb: {
        backgroundColor: '#fff'
    },
    zoomButtons: {
        color: '#fff',
        padding: '0'
    },
    rotateButton: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        color: '#fff',
        padding: '0',
        minWidth: '40px',
        margin: '0 5px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .7)'
        }
    }
});

const TransformControl = ({
    show,
    onZoomIn,
    onZoomOut,
    onRotateLeft,
    onRotateRight
}) => {
    const classes = useStyles();

    return (
        <Fade in={show} timeout={{ enter: 100, exit: 2000 }}>
            <div className={classes.zoomContainer}>
                <Button
                    size="small"
                    className={classes.zoomButtons}
                    disableRipple
                    onClick={onRotateLeft}
                >
                    <RotateLeft
                        fontSize="small"
                        className={classes.rotateIcon}
                    />
                </Button>
                <Button
                    className={classes.zoomButtons}
                    size="small"
                    onClick={onZoomIn}
                    disableRipple
                >
                    <ZoomIn fontSize="small" />
                </Button>
                <Button
                    className={classes.zoomButtons}
                    size="small"
                    onClick={onZoomOut}
                    disableRipple
                >
                    <ZoomOut fontSize="small" />
                </Button>
                <Button
                    size="small"
                    className={classes.zoomButtons}
                    disableRipple
                    onClick={onRotateRight}
                >
                    <RotateRight
                        fontSize="small"
                        className={classes.rotateIcon}
                    />
                </Button>
            </div>
        </Fade>
    );
};

export default TransformControl;
