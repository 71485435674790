import React, { useContext, useEffect } from 'react';
import { SEOToolContext } from '../../../../context';
import { useObjectState } from '../../../../utilities/customHooks';
import LoadingDialog from '../../../../components/Loader/LoadingDialog';
import { useHistory } from 'react-router';
import { fetchCriteriaOptions } from '../../../../utilities/searchCriteria';
import { postRequest } from '../../../../helpers/apiRequestUtility';
import { SCHOLARSHIP_TYPE_CRITERIA, SCHOLARSHIP_TIMING_CRITERIA } from '../../../../config/constants';
import { sendRequest } from '../../../../helpers/apiRequestUtility';
import { useSnackbar } from 'notistack';

const { Provider } = SEOToolContext;

export default function SEOToolProvider(props) {
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const [state, setState] = useObjectState({
        directory: null,
        pageTypes: [],
        online: false,
        available: false,
        saving: false,
        checking: false,
        isCustom: false,
        regions: [],
        h1: '',
        h2: '',
        title: '',
        description: '',
        countries: [],
        mainRegions: [],
        cities: [],
        customPlacements: {},
        placementOptions: {},
        rawOptions: {},
        postUrl: '',
        isEdit: false,
        fetching: false,
        fetchingType: [],
        doneCheck: false
    });
    const {
        directory,
        online,
        h1,
        h2,
        title,
        description,
        pageTypes,
        customPlacements,
        isEdit,
        fetching,
        fetchingType
    } = state;

    const mapperType = {
        2: 'volunteer_type',
        4: 'intern_type',
        5: 'intern_type',
        6: 'degree_program',
        7: 'language',
        8: 'adventure_travel_type',
        9: 'highschool_type',
        12: 'degree_program',
        19: 'gap_year_type'
    };

    const mapperTiming = {
        2: 'duration',
        3: 'teach_duration',
        4: 'intern_duration',
        6: 'term',
        9: 'duration',
        12: 'study_type',
        19: 'gap_year_timing'
    }
    const getValueLabel = async (valueType, id) => {
        setState((prev) => ({
            ...prev,
            fetchingType: [...prev.fetchingType, valueType]
        }))
        sendRequest(
            `/search-criteria-options?criteria=${valueType}&id=${id}`,
            ({ search_criteria_options = [] }) => {
                let { name } = search_criteria_options[0];
                setState((prev) => ({
                    ...prev,
                    customPlacements: { ...prev.customPlacements, [valueType]: { label: name, value: id } },
                    fetchingType: [...fetchingType].filter((x) => x !== valueType)
                }));

            }
            ,
            () => []
        );


    };

    const reformatMentionText = (text) => {
        const pattern = /@?\{([^}]*)\}/g;
        const result = text.replace(pattern, (_, location) => {
            return location ? `{${location}}` : '';
        });
        return result;

    }

    const handleSave = async ({ id }) => {


        let url = isEdit ? `/seo-meta/${id}/update` : "/seo-meta";
        let hasCustom = Object.keys(customPlacements).some(
            (key) => !!customPlacements[key]);

        const data = new FormData();
        const minChar = {
            description: { min: '70' },
        }

        if (title.length === 0) {
            enqueueSnackbar('Title is required', {
                variant: 'error'
            });
            return
        }
        if (description.length === 0) {
            enqueueSnackbar('Description is required', {
                variant: 'error'
            });
            return
        }
        if (description.length < minChar['description'].min) {
            enqueueSnackbar('Description is too short', {
                variant: 'error'
            });
            return
        }
        if (h1.length === 0) {
            enqueueSnackbar('H1 is required', {
                variant: 'error'
            });
            return
        }
        if (h2.length === 0) {
            enqueueSnackbar('H2 is required', {
                variant: 'error'
            });
            return
        }


        setState({ saving: true });

        data.append('h1', reformatMentionText(h1));
        data.append('h2', reformatMentionText(h2));
        data.append('title', reformatMentionText(title));
        data.append('description', reformatMentionText(description));
        data.append('online', online ? 1 : 0);


        if (directory) {
            data.append('directory_id', directory.value ?? null);
        }

        if (!online) {
            if (pageTypes.includes('city')) {
                const cityId = customPlacements?.city?.value > 0 ? customPlacements.city.value : 0;
                data.append('city_id', cityId);
                if (cityId > 0 && (customPlacements?.country?.value !== 0 || customPlacements?.country?.value !== undefined) && !isEdit) {
                    const cityDetails = state.rawOptions['city'].find(({ value }) => value === cityId);
                    data.append('country_id', cityDetails.country_id);
                } else {
                    data.append('country_id', 0);
                }
            } else if (pageTypes.includes('main_region')) {
                const mainRegionId = customPlacements?.main_region?.value > 0 ? customPlacements.main_region.value : 0;
                data.append('main_region_id', mainRegionId);

                if (mainRegionId > 0 && (customPlacements?.country?.value !== 0 || customPlacements?.country?.value !== undefined) && !isEdit) {
                    const mainRegionDetails = state.rawOptions['main_region'].find(({ value }) => value === mainRegionId);
                    data.append('country_id', mainRegionDetails.country_id);
                } else {
                    data.append('country_id', 0);
                }
            } else if (pageTypes.includes('country')) {
                const countryId = customPlacements?.country?.value;
                data.append('country_id', !!countryId ? countryId : 0);
            } else if (pageTypes.includes('world_region')) {
                const worldRegionId = customPlacements?.world_region?.value > 0 ? customPlacements.world_region.value : 0;
                data.append('world_region_id', worldRegionId);
            }
        }



        if (pageTypes.includes('type')) {
            data.append(SCHOLARSHIP_TYPE_CRITERIA[directory.value], customPlacements[mapperType[directory.value]]?.value > 0 ? customPlacements[mapperType[directory.value]].value : 0);
        }
        if (pageTypes.includes('timing')) {
            data.append(SCHOLARSHIP_TIMING_CRITERIA[directory.value], customPlacements[mapperTiming[directory.value]]?.value > 0 ? customPlacements[mapperTiming[directory.value]]?.value : 0);
        }

        await postRequest(url, data, (res) => {

        }, (error) => {
            console.log(error);
        });
        setState({ saving: false });
        history.push(hasCustom ? '/site-tools/custom-meta' : '/site-tools');
    };

    const setCustomPlacement = (type, value) =>
        setState((prev) => ({
            ...prev,
            customPlacements: { ...prev.customPlacements, [type]: value }
        }));

    const setPlacementOptions = (type, value) => {
        setState((prev) => ({
            ...prev,
            placementOptions: { ...prev.placementOptions, [type]: value }
        }));
    };

    const setRawPlacementOptions = (criteria, options = []) => {
        setState((prev) => ({
            ...prev,
            rawOptions: { ...prev.rawOptions, [criteria]: options }
        }));
    };

    const fetchPlacementOptions = async (criteria) => {
        setPlacementOptions(criteria, 'fetching');
        fetchCriteriaOptions(criteria + "&status=1")
            .then((data) => {
                setPlacementOptions(criteria, data || []);
                setRawPlacementOptions(criteria, data || []);
            })
            .catch(() => {
                setPlacementOptions(criteria, []);
            });
    };

    useEffect(() => {
        if (isEdit) return;
        const placementCriteria = Object.keys(state.customPlacements || {});
        const customValues = Object.values(state.customPlacements || {});
        let allNull = true;

        customValues.forEach((val) => {
            if (val !== null) {
                allNull = false;
                setState((prev) => ({
                    ...prev,
                    isCustom: true
                }));
                return;
            }
            return
        });

        if (customValues.length === 0 || allNull) {
            setState((prev) => ({
                ...prev,
                isCustom: false
            }));
        }
        placementCriteria.forEach((criteria) => {
            if (!state.placementOptions[criteria]) {
                fetchPlacementOptions(criteria);
            }
            if (criteria === "country") {
                if (pageTypes.includes("city") && !state.placementOptions["city"]) {
                    fetchPlacementOptions("city");
                }
                if (pageTypes.includes("main_region") && !state.placementOptions["main_region"]) {
                    fetchPlacementOptions("main_region");
                }
            }
        });
        if (
            (placementCriteria.includes('main_region') || placementCriteria.includes('city')) && !placementCriteria.includes('country')
        ) {
            fetchPlacementOptions('country');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements]);

    useEffect(() => {
        if (isEdit) return;
        if (state.customPlacements.world_region) {
            const customPlacements = Object.keys(state.customPlacements);
            if (customPlacements.includes('country')) {
                const region_countries = state.rawOptions['country'].filter(
                    ({ world_region_id }) =>
                        world_region_id ===
                        state.customPlacements.world_region.value
                );
                setCustomPlacement('country', null);
                setPlacementOptions('country', region_countries);
            }

            if (customPlacements.includes('main_region')) {
                const region_countries = state.rawOptions['country']
                    .filter(
                        ({ world_region_id }) =>
                            world_region_id ===
                            state.customPlacements.world_region.value
                    )
                    .filter(({ value }) =>
                        state.customPlacements['country']
                            ? state.customPlacements['country'].value === value
                            : true
                    );
                const countryIds = region_countries.map(({ value }) => value);
                const mainRegions = state.rawOptions['main_region'].filter(
                    ({ country_id }) => countryIds.includes(country_id)
                );
                setCustomPlacement('main_region', null);
                setPlacementOptions('main_region', mainRegions);
            }

            if (customPlacements.includes('city')) {
                const region_countries = state.rawOptions['country'].filter(
                    ({ world_region_id }) =>
                        world_region_id ===
                        state.customPlacements.world_region.value
                );
                const countryIds = region_countries.map(({ value }) => value);
                setCustomPlacement('city', null);
                setPlacementOptions(
                    'city',
                    state.rawOptions['city'].filter(({ country_id }) =>
                        countryIds.includes(country_id)
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements.world_region]);

    useEffect(() => {
        if (isEdit) return;
        if (state.customPlacements.country) {
            const customPlacements = Object.keys(state.customPlacements);
            const placementOptions = Object.keys(state.placementOptions);

            if (placementOptions.includes('main_region')) {
                customPlacements.includes('main_region') && setCustomPlacement('main_region', null);
                setPlacementOptions(
                    'main_region',
                    state.rawOptions['main_region'].filter(
                        ({ country_id }) =>
                            country_id === state.customPlacements.country.value
                    )
                );
            }

            if (placementOptions.includes('city')) {
                customPlacements.includes('city') && setCustomPlacement('city', null);
                setPlacementOptions(
                    'city',
                    state.rawOptions['city'].filter(
                        ({ country_id }) =>
                            state.customPlacements.country.value === country_id
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements.country]);

    useEffect(() => {
        if (isEdit) return;
        if (state.customPlacements.main_region) {
            const customPlacements = Object.keys(state.customPlacements);

            if (customPlacements.includes('city')) {
                setCustomPlacement('city', null);
                setPlacementOptions(
                    'city',
                    state.rawOptions['city'].filter(
                        ({ main_region_id }) =>
                            state.customPlacements.main_region.value ===
                            main_region_id
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements.main_region]);

    return (
        <Provider
            value={{
                state,
                setSEOToolState: setState,
                handleSave,
                getValueLabel,
                setCustomPlacement,
                setPlacementOptions,
                fetchPlacementOptions
            }}
        >
            {props.children}
            <LoadingDialog
                message={'Checking Placement ...'}
                open={state.saving && state.checking}
            />
            <LoadingDialog
                message={'Saving SEO Meta ...'}
                open={state.saving && !state.checking}
            />
            <LoadingDialog
                message={'Loading SEO Meta ...'}
                open={fetching || fetchingType.length > 0}
            />
        </Provider>
    );
}

export const useSEOToolState = () => {
    const { state, setSEOToolState } = useContext(SEOToolContext);
    return { ...state, setSEOToolState };
};

export const useSEOToolMethods = () => {
    const { state, ...methods } = useContext(SEOToolContext);
    return methods;
};
