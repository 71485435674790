import { AbilityBuilder } from '@casl/ability';
import { getUserCookie } from '../../helpers/cookieUtility';

// ADMINISTRATORS
const admin = AbilityBuilder.define((can, cannot) => {
    // Invoices
    cannot('pending', 'Invoice'); // Cannot set Invoice to Pending. Instead, Admin will be able to save and send Invoice (unpaid).

    // Client Profile
    can('read', 'ClientProfile');
});

// MANAGERS
const manager = AbilityBuilder.define((can, cannot) => {
    // Dashboard
    can('read', 'Dashboard');

    // Invoices
    can('create', 'Invoice');
    can('update', 'Invoice');
    can('draft', 'Invoice');
    can('save_send', 'Invoice');
    can('save_changes', 'Invoice');
    can('read', 'Invoice');
    can('edit_invoice_item_amount', 'Invoice');
    cannot('pending', 'Invoice');

    // Advertisements
    can('create', 'Advertisement');
    can('draft', 'Advertisement');
    can('pending', 'Advertisement');
    can('read', 'Advertisement');
    can('update', 'Advertisement');

    can('mark_as_done', 'Advertisement');

    // Programs
    can('read', 'Program');
    can('create', 'Program');
    can('update', 'Program');

    // Client Profile
    can('read', 'ClientProfile');

    // Article
    can('update', 'Article');

    // Guide
    can('update', 'Guide');

    // Interview
    can('update', 'Interview');

    //Sales and Collections Summary
    can('read', 'Sales and Collections Summary');

    //SEO Meta
    can('read', 'SEO Meta');
    can('create', 'SEO Meta');
    can('update', 'SEO Meta');

    //Review Widget Generator
    can('read', 'Review Widget Generator');

    //Deals
    can('create', 'AdminAddDeals')
    can('update', 'AdminAddDeals')
});

// EMPLOYEE
const employee = AbilityBuilder.define((can, cannot) => {
    // Dashboard
    can('read', 'Dashboard');

    // Invoices
    can('create', 'Invoice');
    can('update', 'Invoice');
    can('draft', 'Invoice');
    can('pending', 'Invoice');
    can('read', 'Invoice');

    // Advertisements
    can('create', 'Advertisement');
    can('draft', 'Advertisement');
    can('pending', 'Advertisement');
    can('read', 'Advertisement');
    can('update', 'Advertisement');

    can('mark_as_done', 'Advertisement');

    // Programs
    can('read', 'Program');
    can('create', 'Program');
    can('update', 'Program');

    // Client Profile
    can('read', 'ClientProfile');

    cannot('save_send', 'Invoice');
    cannot('save_changes', 'Invoice');

    // Article
    can('update', 'Article');

    // Guide
    can('update', 'Guide');

    //Sales and Collections Summary
    can('read', 'Sales and Collections Summary');

    // Interview
    can('update', 'Interview');

    //SEO Meta
    can('read', 'SEO Meta');
    can('create', 'SEO Meta');
    can('update', 'SEO Meta');
    
    // MyGoAbroad Deals
    can('create', 'AdminAddDeals')
    can('update', 'AdminAddDeals')
});

// CLIENTS - others
const client = AbilityBuilder.define((can, cannot) => {
    can('read', 'ClientDashboard');

    // Invoices
    can('read', 'ClientInvoice');
    can('read', 'Invoice');
    can('pay', 'Invoice');

    // Inquiry
    can('read', 'Inquiry');

    // Advertisements
    can('read', 'ClientAdvertisement', { own: true });
    can('manage', 'ClientVerification');

    // Programs
    can('read', 'ClientProgram', { own: true });
    can('create', 'ClientProgram');
    can('update', 'ClientProgram');

    // Programs
    can('read', 'Program');
    can('create', 'Program');
    can('update', 'Program');

    // Applications
    can('read', 'ClientApplications', { own: true });

    // MyGoAbroad
    can('read', 'ClientMyGoAbroad', { own: true });
    can('create', 'ClientAddDeals');
    can('update', 'ClientAddDeals');

    // Advertisements
    cannot('create', 'ClientAdvertisement');
    cannot('draft', 'ClientAdvertisement');
    cannot('pending', 'ClientAdvertisement');
    cannot('mark_as_done', 'ClientAdvertisement');

    can('read', 'ClientStaff');
    can('read', 'ClientLog');
    can('read', 'ClientInquiry');
    can('read', 'ClientReview');

    // User Settings
    can('read', 'Settings');
    can('manage', 'Verification');

    // Reviews
    can('read', 'ReviewWidget');
    can('read', 'Review');
});

// CLIENTS-BILLED
const client_billed = AbilityBuilder.define((can, cannot) => {
    can('read', 'ClientDashboard');

    // Invoices
    can('read', 'ClientInvoice');
    can('read', 'Invoice');
    can('pay', 'Invoice');

    // Advertisements
    can('read', 'ClientAdvertisement', { own: true });

    cannot('create', 'ClientAdvertisement');
    cannot('draft', 'ClientAdvertisement');
    cannot('pending', 'ClientAdvertisement');
    cannot('mark_as_done', 'ClientAdvertisement');

    // Programs
    can('read', 'ClientProgram', { own: true });
    cannot('create', 'ClientProgram');
    cannot('update', 'ClientProgram');

    // Applications
    can('read', 'ClientApplications', { own: true });

    // MyGoAbroad
    can('read', 'ClientMyGoAbroad', { own: true });
    can('create', 'ClientAddDeals');
    can('update', 'ClientAddDeals');

    can('read', 'ClientStaff');
    can('read', 'ClientLog');
    can('read', 'ClientInquiry');
    can('read', 'ClientReview');

    // User Settings
    can('read', 'Settings');

    // Reviews
    can('read', 'ReviewWidget');
});

// primary client
const client_primary = AbilityBuilder.define((can, cannot) => {
    can('read', 'ClientDashboard');

    // Invoices
    can('read', 'ClientInvoice');

    // Inquiry
    can('read', 'Inquiry');

    // Advertisements
    can('read', 'ClientAdvertisement');

    //Verification
    can('manage', 'ClientVerification');

    can('read', 'ClientStaff');

    // User Settings
    can('read', 'Settings');

    can('manage', 'Verification');

    cannot('create', 'ClientAdvertisement');
    cannot('draft', 'ClientAdvertisement');
    cannot('pending', 'ClientAdvertisement');
    cannot('mark_as_done', 'ClientAdvertisement');

    // Programs
    can('read', 'ClientProgram');
    can('read', 'ClientLog');
    can('read', 'ClientInquiry');
    can('read', 'ClientReview');

    // Applications
    can('read', 'ClientApplications', { own: true });

    // MyGoAbroad
    can('read', 'ClientMyGoAbroad', { own: true });
    can('create', 'ClientAddDeals');
    can('update', 'ClientAddDeals');

    // Programs
    can('read', 'Program');
    can('create', 'Program');
    can('update', 'Program');

    can('pay', 'Invoice');
    can('read', 'Invoice');

    // Reviews
    can('read', 'ReviewWidget');
    can('read', 'Review');
});

// NO ACCESS
const _default = AbilityBuilder.define((can, cannot) => {
    cannot('read', 'none');
});

export const getAbility = () => {
    const user_info = getUserCookie();

    if (user_info) {
        switch (user_info.access_level) {
            case 1: {
                if (user_info.is_primary) {
                    return ABILITIES.client_primary;
                } else if (user_info.is_billing) {
                    return ABILITIES.client_billed;
                }
                return ABILITIES.client;
            }
            case 2:
                return ABILITIES.employee;
            case 3:
                return ABILITIES.manager;
            case 4:
                return ABILITIES.admin;
            default:
                return ABILITIES._default;
        }
    }

    return ABILITIES._default;
};

export const isAuthenticated = () => {
    const cookie = getUserCookie();

    return !!cookie;
};

export const isClientAdmin = () => {
    const user_info = getUserCookie();

    if (!user_info) return ABILITIES._default;

    return user_info.access_level === 1;
};

export const ABILITIES = {
    admin,
    manager,
    employee,
    client,
    client_billed,
    client_primary,
    _default,
};

export default ABILITIES._default;
