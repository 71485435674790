import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { isEmail } from 'validator';
import IsLoggedIn from '../../../helpers/IsLoggedIn';
import { postRequest } from '../../../helpers/apiRequestUtility';
import { withGlobalContext } from '../../Client/GlobalProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { storeUserCookie } from '../../../helpers/cookieUtility';
import { CLIENT_ADMIN, GA_LOGO } from '../../../config/constants';
import ClientList from './ClientList';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isNil } from 'lodash';
import { isClientAdmin } from '../../../components/AccessLevel/ability';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',
        overflow: 'hidden',
        zIndex: 1000,

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)',
        },
    },
    paper: {
        width: '100%',
        maxWidth: 430,
        padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        position: 'relative',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        minWidth: 320,
        background: '#f8f8f8',
        '&:first-child': {
            marginBottom: theme.spacing.unit * 2,
        },

        // Remove chrome autofill background
        '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
        {
            transition: 'background-color 5000s ease-in-out 0s',
        },
        '&.Mui-focused': {
            outlineColor: '#03a9f4',
        },
    },
    button: {
        minWidth: 320,
        height: 50,
        background: '#03a9f4',
        color: 'white',

        '&:hover': {
            background: '#0092d3',
        },
    },
    logo: {
        marginBottom: theme.spacing.unit * 4,
    },
    h1: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1.5em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333',
    },
    forgot: {
        fontSize: '0.75em',
        marginTop: theme.spacing.unit * 2,
        color: '#555',

        '& a': {
            color: '#03a9f4',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    },
    email: {
        borderRight: 'none',
        borderRadius: '3px 0 0 3px',
    },
    error: {
        position: 'absolute',
        top: -50,
        background: 'rgba(255,42,42,0.60)',
        color: 'white',
        padding: '8px 16px',
        borderRadius: 3,
    },
    showPassword: {
        marginRight: '-7px',
    },
    loginButtonWrapper: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& div[role=progressbar]': {
            position: 'absolute',
        },
    },
    progress: {
        color: '#03a9f4',
    },
});

class LoginForm extends Component {
    state = {
        email: '',
        error: '',
        password: '',
        showPassword: false,
        verifying: false,
        chooseClient: false,
        user: {},
        providers: []
    };

    async componentDidMount() {
        const { history } = this.props;
        if (IsLoggedIn()) {
            const redirect_url = isClientAdmin()
                ? `/${CLIENT_ADMIN}/dashboard`
                : '/dashboard';
            history.push(redirect_url);
        }
    }

    handleLogin = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        this.setState({ verifying: true, error: '' }, async () => {
            const token = (await this.getRecaptchaResponse());
            let form = new FormData();
            form.append('email', email);
            form.append('password', password);
            form.append('g-recaptcha-response', token);
            postRequest('/login', form, this.handleSuccess, this.handleError);
        });
    };

    handleSuccess = (user) => {
        const { providers = [], ...userData } = user;
        this.forceUpdate();

        if (providers.length > 1) {
            this.setState({ user: userData, providers, chooseClient: true });
        } else {
            this.storeAndRedirect(userData);
        }
    };

    handleError = async ({ error }) => {
        const { message } = error || {};
        this.setState({
            verifying: false,
            password: '',
            error: message || 'Authentication Failed. Please try again.',
        });
        this.email.focus();
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleInputChange = (field) => (e) => {
        this.setState({ [field]: e.target.value });
    };

    handleSelectClient = (provider) => {
        let { user } = this.state;
        user.provider_id = provider.id;
        user.contact_id = provider.contact_id;
        user.is_primary = provider.is_primary;
        user.is_billing = provider.is_billing;
        this.storeAndRedirect(user);
    };

    storeAndRedirect = (user) => {
        const { history, location, globalContext } = this.props;
        const origin = window.location.origin;
        const prevUrl = document.referrer;
        const {
            from = prevUrl.startsWith(origin)
                ? prevUrl.replace(origin, '')
                : null,
        } = location.state || {};

        const isClient = user.access_level === 1;
        const clientDashboard = `/${CLIENT_ADMIN}/dashboard`;
        const adminDashboard = '/dashboard';
        const dashboard = isClient ? clientDashboard : adminDashboard;
        let redirect_url = !isNil(from) ? from : dashboard;
        if (!isClient && redirect_url === clientDashboard)
            redirect_url = adminDashboard;

        storeUserCookie(user);
        globalContext.handleStateChange({ user }, () => {
            history.push(redirect_url);
        });
    };

    async getRecaptchaResponse() {
        return await this.props.googleReCaptchaProps.executeRecaptcha('login');
    }

    render() {
        const { classes } = this.props;
        const {
            email,
            password,
            verifying,
            showPassword,
            chooseClient,
            error,
            providers,
        } = this.state;

        if (chooseClient)
            return (
                <ClientList
                    clients={providers}
                    onSelect={this.handleSelectClient}
                />
            );

        return (
            <Paper className={classes.paper}>
                {!!error && <span className={classes.error}>{error}</span>}
                <img className={classes.logo} src={GA_LOGO} alt="" />
                <h1 className={classes.h1}>
                    Login to your GoAbroad.com Admin Account
                </h1>

                <form className={classes.form} onSubmit={this.handleLogin}>
                    <FormControl className={classes.input} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                            Email Address
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            data-cy="login-email"
                            type="text"
                            placeholder={'example@domain.com'}
                            color="primary"
                            labelWidth={105}
                            value={email}
                            onChange={this.handleInputChange('email')}
                            required
                            disabled={verifying}
                            inputRef={(ref) => (this.email = ref)}
                            error={!!error}
                        />
                    </FormControl>

                    <FormControl className={classes.input} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                            Password
                        </InputLabel>
                        <OutlinedInput
                            data-cy="login-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        className={classes.showPassword}
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        edge="end"
                                        disabled={verifying}
                                    >
                                        {showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                            value={password}
                            onChange={this.handleInputChange('password')}
                            required
                            disabled={verifying}
                            error={!!error}
                        />
                    </FormControl>

                    <div className={classes.loginButtonWrapper}>
                        {verifying && (
                            <CircularProgress
                                className={classes.progress}
                                size={24}
                            />
                        )}
                        {!verifying && (
                            <Button
                                data-cy="login-button"
                                className={classes.button}
                                variant="contained"
                                disabled={
                                    !email ||
                                    !isEmail(email) ||
                                    !password ||
                                    verifying
                                }
                                type="submit"
                            >
                                {!verifying && 'Login'}
                            </Button>
                        )}
                    </div>
                    <input
                        type="hidden"
                        id="g-recaptcha-response"
                        name="g-recaptcha-response"
                    />
                    <p className={classes.forgot}>
                        <a href="/forgot-password" data-cy="forgot-password">Forgot your password?</a>{' '}
                        Don't have an account?{' '}
                        <a href="https://www.goabroad.com/account/new">
                            Sign Up
                        </a>
                    </p>
                </form>
            </Paper>
        );
    }
}

export default withGoogleReCaptcha(
    withGlobalContext(withStyles(styles)(withRouter(LoginForm)))
);
