import {
    Paper,
    lighten,
    makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSEOToolState } from './SEOToolProvider';
import { grey } from '@material-ui/core/colors';
import MentionTextField from './MentionTextField';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
        width: '100%'
    },
    mentionInput: {
        padding: theme.spacing(1),
        '&__input': {
            padding: theme.spacing(1)
        },
    },
    mention: {
        padding: theme.spacing(1)
    },
    selectedMention: {
        backgroundColor: `${lighten(
            theme.palette.primary.main,
            0.9
        )} !important`,
        color: grey[900]
    }
}));

export default function MetaForm() {
    const { setSEOToolState, h1, h2, title, description, pageTypes, online, isCustom, directory } = useSEOToolState();
    const classes = useStyles();

    const [placeholders, setPlaceHolders] = useState([]);
    const maxChar = {
        title: { max: '65' },
        description: { max: '160' },
        h1: { max: '70' },

    }
    const minChar = {
        title: { min: '0' },
        description: { min: '70' },
        h1: { min: '0' },

    }
    const handleFieldChange = (field) => (e) => {

        const dataText = e.target.value;
        if (field === "description") {
            setSEOToolState({ [field]: e.target.value });
        } else if (Object.keys(maxChar).includes(field) && dataText.length > maxChar[field].max) {
            return
        } else if (Object.keys(minChar).includes(field) && dataText.length < minChar[field].min) {
            return
        }
        setSEOToolState({ [field]: e.target.value });
    };
    useEffect(() => {

        let metasPlaceholder = [];
        if (pageTypes.includes("world_region") || pageTypes.includes("country")) {
            metasPlaceholder.push({
                id: "{location}",
                display: "{location}"
            })
        }
        if (pageTypes.includes("city")) {
            metasPlaceholder.push({
                id: "{city}",
                display: "{city}"
            })
        }
        if (pageTypes.includes("main_region")) {
            metasPlaceholder.push({
                id: "{mainregion}",
                display: "{mainregion}"
            })
        }
        if (directory) {
            const { value } = directory;
            if (pageTypes.includes("timing")) {
                if (value === 12) {
                    metasPlaceholder.push({
                        id: "{type}",
                        display: "{type}"
                    });
                } else {
                    metasPlaceholder.push({
                        id: "{term}",
                        display: "{term}"
                    })
                }
            }
            if (pageTypes.includes("type")) {
                if (value === 6 || value === 7 || value === 12) {
                    metasPlaceholder.push({
                        id: "{course}",
                        display: "{course}"
                    })
                } else {
                    metasPlaceholder.push({
                        id: "{type}",
                        display: "{type}"
                    })
                }

            }
        }
        if (online) {
            metasPlaceholder.push({
                id: "{online}",
                display: "{online}"
            })
        }
        if (isCustom) {
            metasPlaceholder.push({
                id: "{programCount}",
                display: "{programCount}"
            })
        }
        setPlaceHolders(metasPlaceholder);
        // eslint-disable-next-line
    }, [pageTypes, online, isCustom]);

    return (
        <Paper className={classes.paper}>
            <div>
                <MentionTextField
                    data={placeholders}
                    value={title}
                    onChange={handleFieldChange('title')}
                    placeholder="Enter Page Title"
                    label={"Page Title"}
                    length={title.length}
                    maxChar={maxChar['title'].max}
                    minChar={minChar['title'].min}
                />
                <MentionTextField
                    data={placeholders}
                    value={description}
                    onChange={handleFieldChange('description')}
                    placeholder="Enter Meta Description"
                    label={"Meta Description"}
                    length={description.length}
                    maxChar={maxChar['description'].max}
                    minChar={minChar['description'].min}
                    multiline
                />
                <MentionTextField
                    data={placeholders}
                    value={h1}
                    onChange={handleFieldChange('h1')}
                    placeholder="Enter H1"
                    label={"H1"}
                    length={h1.length}
                    maxChar={maxChar['h1'].max}
                    minChar={minChar['h1'].min}
                />
                <MentionTextField
                    data={placeholders}
                    value={h2}
                    onChange={handleFieldChange('h2')}
                    placeholder="Enter H2"
                    label={"H2"}
                    length={h2.length}
                />
            </div>
        </Paper>
    );
}
