import React, { useEffect, useState } from 'react';
import { Fade, Typography, makeStyles, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import { useTravelResourceRankingState } from './TravelResourceRankingProvider';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { grey } from '@material-ui/core/colors';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 250
    },
    search: { width: 250 },
    disabled: {
        color: `${grey[400]} !important`
    }
}));

const ColumnFilter = ({
    label,
    showFilter = false,
    filterValue = null,
    filterOptions = [],
    disabled = false,
    onFilterOpen = () => {},
    onFilterClose = () => {},
    onFilterChange = () => {},
    classes = {}
}) => {
    const filterClass = useStyles();
    const { reorder } = useTravelResourceRankingState();
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (showFilter) {
            onFilterChange(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setValue(filterValue);
    }, [filterValue]);

    if (!showFilter)
        return (
            <>
                <Typography
                    className={classNames({
                        [classes.columnHeader]: true
                    })}
                >
                    {label}
                </Typography>
                {!reorder && (
                    <IconButton onClick={onFilterOpen} disabled={disabled}>
                        <SearchIcon fontSize="small" />
                    </IconButton>
                )}
            </>
        );

    return (
        <>
            <Fade in={showFilter}>
                <div className={filterClass.search}>
                    <SearchBasedDropdown
                        arrayOptions={filterOptions}
                        handleChange={(val) => setValue(val)}
                        placeholder={`Select ${label}`}
                        value={value}
                        isDisabled={disabled}
                    />
                </div>
            </Fade>
            <IconButton onClick={onFilterClose} disabled={disabled}>
                <Close fontSize="small" />
            </IconButton>
        </>
    );
};

export default ColumnFilter;
