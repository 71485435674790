import React, { useEffect } from 'react';
import SortIcon from '@material-ui/icons/Sort';
import {
    Grid,
    Typography,
    makeStyles,
    IconButton,
    TableSortLabel,
    Divider,
    Fade
} from '@material-ui/core';
import { useObjectState } from '../../../utilities/customHooks';
import ColumnFilter from './ColumnFilter';
import {
    useTravelResourceRankingState,
    useTravelResourceRankingMethods
} from './TravelResourceRankingProvider';

const useStyles = makeStyles((theme) => ({
    root: { marginBottom: theme.spacing(1) },
    columnWrapper: {
        padding: `0 !important`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: 44,
        '&:first-child': {
            paddingLeft: `${theme.spacing(2)}px !important`
        }
    },
    columnHeader: {
        color: '#555',
        fontWeight: 'bold',
        padding: 0,
        fontSize: 14
    }
}));

const TableHeader = () => {
    const classes = useStyles();
    const { reorder, data, sortDirection, setTravelResourceRankingState } =
        useTravelResourceRankingState();
    const { setFilters, removeFilters } = useTravelResourceRankingMethods();
    const [state, setState] = useObjectState({
        clientFilter: false,
        client: null
    });

    useEffect(() => {
        if (!state.clientFilter) removeFilters(['client_id']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.clientFilter]);

    useEffect(() => {
        if (state.client) setFilters({ client_id: state.client.value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.client]);

    useEffect(() => {
        if (reorder) {
            setState({ clientFilter: false, client: null });
            removeFilters(['client_id']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reorder]);

    const handleReorder = () =>
        setTravelResourceRankingState({ reorder: true });

    const handleToggleFilter = (field) => setState({ [field]: !state[field] });

    const handleSort = () =>
        setTravelResourceRankingState({
            sortDirection: sortDirection === 'asc' ? 'desc' : 'asc'
        });

    const handleFilterClient = (client) => setState({ client });

    const clientNames = [
        ...new Set(
            [...data].map(({ client_id, client_name }) => ({
                value: client_id,
                label: client_name
            }))
        )
    ];

    return (
        <>
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={2} className={classes.columnWrapper}>
                    <Typography className={classes.columnHeader}>
                        <TableSortLabel
                            active={!reorder}
                            direction={sortDirection}
                            onClick={!reorder ? handleSort : null}
                            hideSortIcon
                        >
                            Ranking
                        </TableSortLabel>
                    </Typography>
                    {!reorder && (
                        <IconButton size="small" onClick={handleReorder}>
                            <SortIcon fontSize="small" />
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={5} className={classes.columnWrapper}>
                    <ColumnFilter
                        classes={classes}
                        disabled={reorder}
                        label="Client Name"
                        filterValue={state.client}
                        filterOptions={clientNames}
                        showFilter={state.clientFilter}
                        onFilterOpen={() => handleToggleFilter('clientFilter')}
                        onFilterClose={() => handleToggleFilter('clientFilter')}
                        onFilterChange={handleFilterClient}
                    />
                </Grid>
                <Grid item xs={3} className={classes.columnWrapper}>
                    <Typography className={classes.columnHeader}>
                        Resource Page
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.columnWrapper}></Grid>
            </Grid>
            <Fade in={!reorder && !!data.length}>
                <Divider />
            </Fade>
        </>
    );
};

export default TableHeader;
