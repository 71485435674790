import React from 'react';
import ResultsMain from './ResultsMain';
import ProviderUrlVerifierProvider from './ProviderUrlVerifierProvider';

const ProviderUrlVerifier = ({ active }) => {
    if (!active) return null;
    return (
        <ProviderUrlVerifierProvider>
            <ResultsMain />
        </ProviderUrlVerifierProvider>
    );
};

export default ProviderUrlVerifier;
