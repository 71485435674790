import {
    Divider,
    Fade,
    Grid,
    Grow,
    IconButton,
    makeStyles,
    Paper,
    TableSortLabel,
    Tooltip,
    Typography,
    TableBody,
    Table
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import classNames from 'classnames';
import React from 'react';
import ScholarshipCard from './ScholarshipCard';
import {
    useScholarshipRankingState,
    useScholarshipRankingMethods
} from './ScholarshipRankingProvider';
import TableToolbar from './TableToolbar';
import { useScholarshipState } from '../ScholarshipProvider';
import SortIcon from '@material-ui/icons/Sort';
import { TableRowLoader } from '../../../components/Loader/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: 0
    },
    head: {
        // marginBottom: theme.spacing(2)
    },
    grid: {
        padding: theme.spacing(2)
    },
    opaque: {
        color: grey[500]
    },
    ranking: {
        display: 'flex'
    },
    reorder: {},
    tableWrapper: {
        overflowX: 'auto'
    }
}));

const ScholarshipRankingTable = (props) => {
    const classes = useStyles();
    const {
        data,
        sortOrder,
        reorder,
        fetching,
        temp,
        dragged_id,
        setScholarshipRankingState
    } = useScholarshipRankingState();
    const { handleSaveRanking } = useScholarshipRankingMethods();
    const { setScholarshipState } = useScholarshipState();

    const handleSortClick = () => {
        setScholarshipRankingState({
            sortOrder: sortOrder === 'asc' ? 'desc' : 'asc'
        });
    };

    const handleDrop = (index) => {
        const newData = [...temp];

        if (dragged_id < index) {
            for (let idx = dragged_id; idx < index; idx++) {
                newData[idx] = temp[idx + 1];
            }
            newData[index] = temp[dragged_id];
        } else if (dragged_id > index) {
            for (let idx = dragged_id; idx > index; idx--) {
                newData[idx] = temp[idx - 1];
            }
            newData[index] = temp[dragged_id];
        }

        setScholarshipRankingState({ temp: newData });
    };

    const handleCancelRanking = () =>
        setScholarshipRankingState({ reorder: false });

    const handleClickScholarship = (scholarship_id) => {
        const [scholarship = null] = [...data].filter(
            ({ id }) => id === scholarship_id
        );
        setScholarshipState({ selectedScholarship: scholarship, edit: true });
    };

    const handleReorder = () => setScholarshipRankingState({ reorder: true });

    return (
        <Paper elevation={1}>
            <TableToolbar
                onCancelRanking={handleCancelRanking}
                onSaveRanking={handleSaveRanking}
            />
            <div className={classes.root}>
                <Grid container className={classes.head}>
                    <Grid
                        item
                        xs={2}
                        className={classNames([classes.grid, classes.ranking])}
                    >
                        <TableSortLabel
                            direction={sortOrder}
                            active={!reorder}
                            hideSortIcon
                            onClick={!reorder ? handleSortClick : null}
                        >
                            Ranking
                        </TableSortLabel>
                        <Grow in={!reorder}>
                            <Tooltip title="Re-order Ranking" placement="top">
                                <IconButton
                                    size="small"
                                    className={classes.reorder}
                                    onClick={handleReorder}
                                >
                                    <SortIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grow>
                    </Grid>
                    <Grid item xs={4} className={classes.grid}>
                        <Typography
                            className={classNames({
                                [classes.opaque]: reorder
                            })}
                        >
                            Scholarship Title
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.grid}>
                        <Typography
                            className={classNames({
                                [classes.opaque]: reorder
                            })}
                        >
                            Scholarship Link
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.grid}>
                        <Typography
                            className={classNames({
                                [classes.opaque]: reorder
                            })}
                        >
                            Deadline
                        </Typography>
                    </Grid>
                </Grid>
                <Fade in={!reorder}>
                    <Divider />
                </Fade>
                {fetching && (
                    <div className={classes.tableWrapper}>
                        <Table padding="dense">
                            <TableBody>
                                <TableRowLoader
                                    colSpan={4}
                                    withCheckbox={false}
                                />
                            </TableBody>
                        </Table>
                    </div>
                )}
                {[...(reorder ? temp : data)].map(
                    (
                        { id, award_name, url, deadline_date, directory_rank },
                        index
                    ) => (
                        <>
                            <ScholarshipCard
                                id={id}
                                index={index}
                                rank={directory_rank}
                                name={award_name}
                                deadline={deadline_date}
                                url={url}
                                key={`ranked-scholarship-${id}-${index}`}
                                onDrop={handleDrop}
                                onClick={handleClickScholarship}
                            />
                            <Fade in={!reorder && index < data.length - 1}>
                                <Divider />
                            </Fade>
                        </>
                    )
                )}
            </div>
        </Paper>
    );
};

export default ScholarshipRankingTable;
