import {
    Drawer,
    FormControlLabel,
    Paper,
    Typography,
    Checkbox,
    Divider,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import SearchBasedDropdown from '../../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { useDefaultMetaState } from './DefaultMetaProvider';
import { DIRECTORIES } from '../../../../utilities/directory';
import { useObjectState } from '../../../../utilities/customHooks';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Check';
import { metaPageTypes } from '../../../../config/constants';
import { CRITERIA_PER_DIRECTORY, SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36
            }
        }
    },
    paper: {
        padding: theme.spacing(2)
    },
    counter: {
        alignSelf: 'end'
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',
        '& button:first-child': {
            marginRight: theme.spacing(2)
        }
    },
    datePicker: {
        display: 'flex'
    },
    checkbox: {
        width: '100%'
    }
}));

export default function DataFiltersDrawer() {
    const classes = useStyles();
    const { filters, dataFilterOpen, setDefaultMetaState, metaTypes } =
        useDefaultMetaState();
    const [state, setState] = useObjectState({
        directory: null,
        pageTypes: [],
        online: false,
        disabledList: []
    });
    const filterList = {
        city: ['country', 'city'],
        main_region: ['country', 'main_region'],
        country: ['country'],
        world_region: ['world_region'],
        type: ['type'],
        timing: ['timing']
    };

    const disList = {
        city: ['world_region', 'main_region'],
        main_region: ['world_region', 'city'],
        country: ['world_region', 'main_region', 'city'],
        world_region: ['country', 'main_region', 'city']
    }

    const { disabledList } = state;
    const directories = [...DIRECTORIES].filter(
        (directory) => !!directory.page_number_tag || directory.abbrv === 'GA'
    );

    const handleFieldChange = (field) => (value) => {

        const locationTypes = metaTypes.filter((item) => item.isLocation);

        setState({ [field]: value, customPlacements: {}, pageTypes: [], disabledList: [] });

        if (field === "directory" && value === null) {
            let y = ["type", "timing"];
            setState((prev) => ({
                ...prev,
                pageTypes: [...prev.pageTypes].filter((x) => !y.includes(x))
            }))
        }

        if (value) {
            let directoryID = value.value;
            let criteriaTypeID = CRITERIA_PER_DIRECTORY[directoryID].type;
            let criteriaTimingID = CRITERIA_PER_DIRECTORY[directoryID].timing;

            let criteraTypeDetails = SEARCH_CRITERIA[criteriaTypeID];
            let criteriaTimingDetails = SEARCH_CRITERIA[criteriaTimingID];

            let metaTypeTiming = []

            if (!!criteraTypeDetails) {
                metaTypeTiming.push({
                    value: 'type',
                    label: !!criteraTypeDetails ? criteraTypeDetails.name : '',
                    altLabel: 'Type'
                })
            }

            if (!!criteriaTimingDetails) {
                metaTypeTiming.push({
                    value: 'timing',
                    label: !!criteriaTimingDetails ? criteriaTimingDetails.name : '',
                    altLabel: 'Type'
                })
            }

            setDefaultMetaState((prev) => ({
                ...prev,
                metaTypes: [...locationTypes, ...metaTypeTiming]
            }))
        } else {
            setDefaultMetaState({ metaTypes: locationTypes });
        }

    }
    const handlePageTypeChange = (isLocation, pageType) => (e, checked) => {


        setState((prev) => ({
            ...prev,
            disabledList: checked
                ? isLocation ? disList[pageType] : [...prev.disabledList]
                : isLocation ? [...prev.disabledList].filter((p) => !disList[pageType].includes(p)) : [...prev.disabledList],
            pageTypes: checked
                ? [...prev.pageTypes, ...filterList[pageType]]
                : [...prev.pageTypes].filter((p) => {
                    if (pageType === "country") {
                        let loc = ["city", "main_region", "country"];
                        return !loc.includes(p);
                    }
                    return !filterList[pageType].includes(p);
                })
        }));
    };

    const handleOnlineChange = (e, checked) => setState({ online: checked });

    const handleDisable = (isLocation, value = []) => {

        if (isLocation && state.online) {
            return true;
        }
        return disabledList.includes(value);


    }
    const handleApplyFilters = () =>
        setDefaultMetaState({
            filters: state,
            dataFilterOpen: false,
            page: 0,
            count: 0,
            urlFilter: null
        });

    const handleClearFilters = () => {
        setState({
            directory: null,
            pageTypes: [],
            online: false
        });
    };


    useEffect(() => {
        const drawerFilters = {};
        const y = ["types", "timing"]

        //eslint-disable-next-line array-callback-return
        Object.keys(filters).map((key) => {
            if (Object.keys(state).includes(key)) {
                drawerFilters[key] = filters[key];
            }
        });
        if (!filters.directory && drawerFilters.metaTypes) {
            drawerFilters.metaTypes = drawerFilters.metaTypes.filter((x) => x.isLocation);
        }
        if (drawerFilters.pageTypes) {
            if (drawerFilters.pageTypes.includes("world_region")) {
                drawerFilters.disabledList = disList["world_region"];
            } else if (drawerFilters.pageTypes.includes("main_region") && drawerFilters.pageTypes.includes("country")) {
                drawerFilters.disabledList = disList["main_region"];
            } else if (drawerFilters.pageTypes.includes("main_region")) {
                drawerFilters.disabledList = [];
                drawerFilters.pageTypes = drawerFilters.pageTypes.filter((x) => y.includes(x));
            } else if (drawerFilters.pageTypes.includes("city") && drawerFilters.pageTypes.includes("country")) {
                drawerFilters.disabledList = disList["city"];
            } else if (drawerFilters.pageTypes.includes("city")) {
                drawerFilters.disabledList = [];
                drawerFilters.pageTypes = drawerFilters.pageTypes.filter((x) => y.includes(x));
            } else if (drawerFilters.pageTypes.includes("country")) {
                drawerFilters.disabledList = disList["country"];
            } else {
                drawerFilters.disabledList = [];
            }
        }
        setState(drawerFilters);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        let locations = ['world_region', 'country', 'main_region', 'city'];

        if (metaTypes.length === 0) {
            locations.forEach((location) => {
                let loc = metaPageTypes.find((meta) => meta.value === location);

                setDefaultMetaState((prev) => ({
                    ...prev,
                    metaTypes: [...prev.metaTypes, loc]
                }))
            });
        }
        // eslint-disable-next-line
    }, []);


    return (
        <Drawer
            open={dataFilterOpen}
            onClose={() => setDefaultMetaState({ dataFilterOpen: false })}
            anchor="right"
        >
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Search Filters</Typography>
                    <br />
                    <div>
                        <Typography variant="overline">Directory</Typography>
                        <SearchBasedDropdown
                            arrayOptions={directories}
                            labelKey="name"
                            valueKey="id"
                            value={state.directory}
                            placeholder="Select Directory"
                            onChange={handleFieldChange('directory')}
                            isClearable
                        />
                        <br />
                        <Typography variant="overline">Page Type</Typography>
                        <br />
                        {metaTypes.map(({ value, label, isLocation }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.pageTypes.includes(
                                            value
                                        )}
                                        onChange={handlePageTypeChange(isLocation, value)}
                                        disabled={handleDisable(isLocation, value)}
                                        name={value}
                                        color="primary"
                                    />
                                }
                                label={label}
                                className={classes.checkbox}
                            />
                        ))}
                        <Divider />
                        <br />
                        <Typography variant="overline">Online</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.online}
                                    onChange={handleOnlineChange}
                                    name="online"
                                    color="primary"
                                />
                            }
                            label={'Include Online'}
                            className={classes.checkbox}
                        />
                    </div>
                </Paper>
                <div className={classes.cta}>
                    <Button
                        data-cy="default-meta-clear-data-filters"
                        fullWidth
                        color="secondary"
                        disableElevation
                        disableFocusRipple
                        startIcon={<CloseIcon fontSize="small" />}
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        data-cy="default-meta-apply-data-filters"
                        variant="contained"
                        fullWidth
                        color="primary"
                        disableFocusRipple
                        disableElevation
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={handleApplyFilters}
                    >
                        Apply & Search
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}
