import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Paper } from '@material-ui/core';
import { CLOUDINARY_URL } from '../../../config/constants';

const styles = (theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        '& p': { fontWeight: 600, color: '#555' },
    },
    avatar: { borderRadius: 0, backgroundColor: '#03a9f414' },
    letterAvatar: { backgroundColor: '#03a9f4' },
    item: {
        '&:hover': {
            backgroundColor: '#03a9f414',
            borderRadius: 3,
        },
    },
    paper: {
        width: '100%',
        maxWidth: 430,
        padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        position: 'relative',
    },
    logo: {
        marginBottom: theme.spacing.unit * 4,
    },
    h1: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1.5em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333',
    },
});

const Notice = (props) => {
    const { classes, clients, onSelect } = props;
    return (
        <Paper className={classes.paper}>
            <h1 className={classes.h1}>Choose Client</h1>
            <Fade direction="left" in={true} mountOnEnter>
                <List className={classes.root}>
                    {clients.map((client, key) => {
                        return (
                            <Fragment>
                                <ListItem
                                    button
                                    className={classes.item}
                                    onClick={() => onSelect(client)}
                                    key={`client-list-item-${key}`}
                                >
                                    <ListItemAvatar className={classes.avatar}>
                                        {client.logo ? (
                                            <Avatar
                                                alt={client.name}
                                                src={`${CLOUDINARY_URL}/c_pad,h_80,w_80/v1/images2/clients/logos/MAIN/${client.logo}`}
                                            />
                                        ) : (
                                            <Avatar
                                                className={classes.letterAvatar}
                                            >
                                                {client.name.substring(1, 0)}
                                            </Avatar>
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography>{client.name}</Typography>
                                    </ListItemText>
                                </ListItem>
                                {key < clients.length - 1 && <Divider />}
                            </Fragment>
                        );
                    })}
                </List>
            </Fade>
        </Paper>
    );
};

export default withStyles(styles)(Notice);
