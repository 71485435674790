import React, { Component, Fragment } from 'react';
import AdsBreakdown from './AdsBreakdown';
import DataFilters from './DataFilters';
import FiltersDrawer from './FiltersDrawer';
import SearchResultsTable from './SearchResultsTable/SearchResultsTable';
import { Dialog, DialogTitle, withStyles } from '@material-ui/core';
import { withSearchResultsTableContext } from './SearchResultsTable/SearchResultsTableProvider';

const styles = theme => ({
    modal: {
        '& > div > div': {
            width: '100%',
            maxWidth: 1000,
            height: '75vh',
            padding: theme.spacing(3)
        }
    }
});

class SearchResultsRankingContainer extends Component {
    toggleFiltersDrawer = (filtersDrawerOpen) => {
        const { handleStateChanged } = this.props.context;
        handleStateChanged({ filtersDrawerOpen });
    };

    handleApplyFilters = ({ online, ...filters }) => {
        const { handleStateChanged } = this.props.context;
        handleStateChanged({
            filtersDrawerOpen: false,
            filters: JSON.parse(JSON.stringify(filters)),
            online: online
        });
    };

    render() {
        const {
            classes,
            context
        } = this.props;

        const { toggleAdsBreakdownModal } = context;

        const {
            open,
            searchCriteriaParams,
            filtersDrawerOpen,
            filters,
            row,
            count,
            loading
        } = context.state;

        return (
            <Fragment>
                <Dialog
                    className={classes.modal}
                    onClose={toggleAdsBreakdownModal(false)}
                    aria-labelledby="Ads Breakdown"
                    open={open}
                >
                    <DialogTitle id="Ads Breakdown">Ads Breakdown</DialogTitle>
                    <AdsBreakdown params={searchCriteriaParams} row={row} />
                </Dialog>

                <DataFilters
                    filtersDrawerOpen={filtersDrawerOpen}
                    filters={filters}
                    handleToggleFiltersDrawer={this.toggleFiltersDrawer}
                />
                <FiltersDrawer
                    handleApplyFilters={this.handleApplyFilters}
                    filtersDrawerOpen={filtersDrawerOpen}
                    handleToggleFiltersDrawer={this.toggleFiltersDrawer}
                />
                <SearchResultsTable
                    count={count}
                    loading={loading}
                />
            </Fragment>
        );
    }
}

export default (withSearchResultsTableContext)(withStyles(styles)(SearchResultsRankingContainer));
