import React, { useContext, useEffect } from 'react';
import { DealContext } from '../../context';
import {
    useDidUpdateEffect,
    useObjectState,
} from '../../utilities/customHooks';
import {
    constructHttpParams,
    explodeUrlParams,
    buildFormData,
    formatRangeParam,
} from '../../helpers/dataUtility';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
    useAuthenticatedRequest,
    useGlobalState,
} from '../Client/GlobalProvider';
import { format } from 'date-fns';
import { removeNull } from '../../helpers/dataUtility';
import { isBookmarkable } from '../../utilities/boomarkability';
import { useHistory, useLocation } from 'react-router-dom';
import { DEAL_STATUS } from '../../config/constants';

const { Provider } = DealContext;

const DealsProvider = (props) => {
    const { handleStateChange } = useGlobalState();
    const { sendRequest, postRequest } = useAuthenticatedRequest();
    const { user } = useGlobalState();
    const { enqueueSnackbar } = useSnackbar();
    const { search } = useLocation();
    const history = useHistory();
    const [state, setState] = useObjectState({
        data: [],
        filters: {},
        sort: 'date_created',
        sortOrder: 'desc',
        rowsPerPage: 10,
        page: 0,
        count: 0,
        selectedDeal: null,
        fetching: false,
        showGlobalFilters: false,
        showColumnFilters: false,
        saving: false,
        dstatus: {},
        is_edited: {},
        deal_status: {},
        buffer_status: {},
    });

    const fetchDeals = async () => {
        setState({
            fetching: true,
        });
        const derivedFilters = {
            type: (state.filters.type || {}).value,
            status:
                state.deal_status === 2 || state.deal_status === 3
                    ? 1
                    : state.dstatus,
            is_edited: state.is_edited,
            is_expiring:
                state.deal_status === 3 ? 1 : state.deal_status === 0 ? 0 : [],
            title: (state.filters.title || '').replace('&', '%26'),
            program_id:
                state.filters.program_id !== 'is_all_programs'
                    ? state.filters.program_id
                    : null,
            is_all_programs:
                state.filters.program_id === 'is_all_programs' ? 1 : null,
            expiration:
                state.deal_status === 2
                    ? `-${format(new Date(), 'YYYY-MM-DD')}`
                    : state.deal_status === 0 && !state.filters.expiration
                    ? encodeURIComponent('+').concat(
                          `${format(new Date(), 'YYYY-MM-DD')}`
                      )
                    : formatRangeParam(state.filters.expiration),
            date_created: formatRangeParam(state.filters.date_created),
            participant_id: (state.filters.nationalities || []).join(','),
            age_range_id: (state.filters.age_range_names || []).join(','),
            education_status_id: (
                state.filters.education_status_names || []
            ).join(','),
            wildcards: !!state.filters.title ? 'title' : null,
            team_id: state.filters.team,
            sort: `${state.sortOrder === 'asc' ? '+' : '-'}${state.sort}`,
        };
        const params = constructHttpParams({
            limit: state.rowsPerPage,
            offset: state.rowsPerPage * state.page,
            provider_id: user.provider_id
                ? user.provider_id
                : (state.filters.provider_name || [])
                      .map(({ value }) => value)
                      .join(','),
            ...derivedFilters,
        });

        return sendRequest(
            `/deals?${params}`,
            (data) => data || [],
            () => {},
            fetchDeals
        )
            .then(({ deals = [], count } = {}) => {
                const mapped = deals.map((deal) => {
                    deal.program_id = deal.is_all_programs
                        ? 'All Programs'
                        : `${deal.program.slice(0, 2).join(', ')}${
                              deal.program.length > 2
                                  ? ` and ${deal.program.length - 2} others`
                                  : ''
                          }`;
                    deal.age_range_names = deal.age_range
                        .map(({ age_range }) => age_range)
                        .join(',');
                    deal.education_status_names = [
                        deal.education_status
                            .map(({ education_status }) => education_status)
                            .slice(0, 2)
                            .join(', '),
                        deal.education_status.length > 2
                            ? ` and ${deal.education_status.length - 2} others`
                            : '',
                    ].join('');
                    deal.nationalities = `${deal.participant
                        .map(({ name }) => name)
                        .slice(0, 2)
                        .join(', ')}${
                        deal.participant.length > 2
                            ? ` and ${deal.participant.length - 2} others`
                            : ''
                    }`;
                    deal.deal_status =
                        deal.status === 0 || deal.is_edited === 1
                            ? 4
                            : deal.status === 2
                            ? 1
                            : moment(deal.expiration).format('YYYY-MM-DD') <=
                                  moment(new Date()).format('YYYY-MM-DD') &&
                              deal.status === 1
                            ? 2
                            : deal.is_expiring === 1
                            ? 3
                            : deal.is_edited === 0 && deal.status === 1
                            ? 0
                            : {};
                    return deal;
                });
                setState({ data: removeNull(mapped), count: count || 0 });
            })
            .finally(() =>
                setState({
                    fetching: false,
                })
            );
    };

    const handleDealStatus = (dealStatus) => {
        if (dealStatus === 4) {
            //pending
            setState({ deal_status: 4, dstatus: {}, is_edited: 1 });
        } else if (dealStatus === 0) {
            //approved
            setState({ deal_status: 0, dstatus: {}, is_edited: 0 });
        } else if (dealStatus === 1) {
            //archived
            setState({ deal_status: 1, dstatus: 2, is_edited: {} });
        } else if (dealStatus === 2) {
            //expired
            setState({ deal_status: 2, is_edited: 0, dstatus: 1 });
        } else if (dealStatus === 3) {
            //expiring
            setState({ deal_status: 3, is_edited: {}, dstatus: {} });
        }
    };

    const handleSubmitDeal = async (dealData, action) => {
        setState({ saving: true });
        const data = new FormData();

        data.append('type', dealData.type.value);
        data.append('title', dealData.title);
        data.append('description', dealData.description);
        data.append(
            'is_all_programs',
            dealData.expirationDate < new Date() ? 0 : dealData.is_all_programs
        );
        data.append('provider_id', dealData.provider.value);
        data.append('status', dealData.status);
        data.append('is_edited', dealData.is_edited);
        data.append(
            'date_created',
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        );
        data.append(
            'updated_at',
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        );
        data.append(
            'expiration',
            moment(dealData.expirationDate).format('YYYY-MM-DD ') +
                moment(new Date()).format('HH:mm:ss')
        );
        dealData.user &&
            data.append('user_first_name', dealData.user?.first_name);
        dealData.user &&
            data.append('user_last_name', dealData.user?.last_name);
        dealData.user && data.append('user_email', dealData.user?.email);
        data.append('deal_email_address', dealData.dealRecipientEmail);

        const valuesPrograms = {};
        const programSelection =
            dealData.expirationDate < new Date() && dealData.is_all_programs
                ? dealData.programs
                : dealData.selectedPrograms;

        // eslint-disable-next-line array-callback-return
        (programSelection || []).map((selectedPrograms) => {
            valuesPrograms['selectedPrograms'] = [
                ...(valuesPrograms['selectedPrograms'] || []),
                selectedPrograms.id,
            ];
        });
        // eslint-disable-next-line array-callback-return
        Object.keys(valuesPrograms).forEach(function (key) {
            buildFormData(data, {
                selectedPrograms: valuesPrograms[key],
            });
        });

        const valuesCountry = {};
        // eslint-disable-next-line array-callback-return
        (dealData.country || []).map((country) => {
            valuesCountry['country'] = [
                ...(valuesCountry['country'] || []),
                country.id,
            ];
        });
        Object.keys(valuesCountry).forEach(function (key) {
            buildFormData(data, {
                country: valuesCountry[key],
            });
        });

        const valuesAge = {};
        // eslint-disable-next-line array-callback-return
        (dealData.ages || []).map((ages) => {
            valuesAge['ages'] = [...(valuesAge['ages'] || []), ages.id];
        });
        Object.keys(valuesAge).forEach(function (key) {
            buildFormData(data, {
                ages: valuesAge[key],
            });
        });

        const valuesEducation = {};
        // eslint-disable-next-line array-callback-return
        (dealData.education || []).map((education) => {
            valuesEducation['education'] = [
                ...(valuesEducation['education'] || []),
                education.id,
            ];
        });
        Object.keys(valuesEducation).forEach(function (key) {
            buildFormData(data, {
                education: valuesEducation[key],
            });
        });

        if (action === 'add') {
            await postRequest(
                `/deals`,
                data,
                (response) => {
                    setState({ saving: false });
                    fetchDeals().then(handleStateChange({ dealSource: true }));
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Deal adding failed!', {
                        variant: 'error',
                    });
                },
                () => handleSubmitDeal(dealData, action)
            );
        } else if (action === 'edit') {
            await postRequest(
                `/deals/${dealData.scholarshipID}/update`,
                data,
                (response) => {
                    setState({ saving: false });
                    fetchDeals().then(handleStateChange({ dealSource: true }));
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Saving changes failed!', {
                        variant: 'error',
                    });
                },
                () => handleSubmitDeal(dealData, action)
            );
        } else if (action === 'approve') {
            await postRequest(
                `/deals/${dealData.scholarshipID}/approve`,
                data,
                (response) => {
                    setState({ saving: false });
                    fetchDeals().then(handleStateChange({ dealSource: true }));
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Saving changes failed!', {
                        variant: 'error',
                    });
                },
                () => handleSubmitDeal(dealData, action)
            );
        } else if (action === 'unapprove') {
            await postRequest(
                `/deals/${dealData.scholarshipID}/unapprove`,
                data,
                (response) => {
                    setState({ saving: false });
                    fetchDeals().then(handleStateChange({ dealSource: true }));
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Saving changes failed!', {
                        variant: 'error',
                    });
                },
                () => handleSubmitDeal(dealData, action)
            );
        } else if (action === 'archive') {
            const data = new FormData();
            data.append('id', dealData.scholarshipID);
            data.append('provider_id', dealData.provider.value);

            await postRequest(
                `/deals/archive`,
                data,
                (response) => {
                    setState({ saving: false });
                    fetchDeals().then(handleStateChange({ dealSource: true }));
                },
                (error) => {
                    setState({ saving: false });
                    enqueueSnackbar('Saving changes failed!', {
                        variant: 'error',
                    });
                },
                () => handleSubmitDeal(dealData, action)
            );
        }
    };

    useDidUpdateEffect(() => {
        fetchDeals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.page,
        state.rowsPerPage,
        state.sort,
        state.sortOrder,
        state.filters,
    ]);

    useDidUpdateEffect(() => {
        const params = constructHttpParams({
            deal_status: state.filters.deal_status
                ? state.filters.deal_status.label.toLowerCase()
                : {},
        });

        history.push({ pathname: `/mygoabroad`, search: `?${params}` });
    }, [state.filters.deal_status, state.sort, state.sortOrder]);

    useEffect(() => {
        const search_params = explodeUrlParams(search);
        const filter_params = {};
        const sortParams = {};

        //eslint-disable-next-line array-callback-return
        Object.keys(search_params).map((key) => {
            if (isBookmarkable('deals_view', key)) {
                if (['deal_status'].includes(key)) {
                    const [start] = search_params[key].split(',');
                    filter_params['deal_status'] = {
                        value: DEAL_STATUS[start],
                        label: start.toUpperCase(),
                    };
                    handleDealStatus(DEAL_STATUS[start]);
                }
            }
        });

        if (Object.keys({ ...filter_params, ...sortParams }).length) {
            setState((prev) => ({
                ...prev,
                ...sortParams,
                filters: { ...prev.filters, ...filter_params },
                buffer_status: { ...filter_params },
                showColumnFilters:
                    Object.keys(filter_params).includes('deal_status'),
            }));
        } else {
            fetchDeals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Provider
            value={{
                state,
                setDealState: setState,
                handleSubmitDeal,
                fetchDeals,
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useDealState = () => {
    const { state, setDealState } = useContext(DealContext);
    return { ...state, setDealState };
};

export const useDealMethods = () => {
    const { state, ...methods } = useContext(DealContext);
    return methods;
};

export default DealsProvider;
