import * as dataUtility from '../../helpers/dataUtility';

import React, { PureComponent } from 'react';
import { difference, intersection } from 'lodash';

const { Provider, Consumer } = React.createContext();

class ResultsTableProvider extends PureComponent {
    state = {
        selected: [],
        unselected: [],
        page: this.props.page || 0,
        rowsPerPage: this.props.rowsPerPage || 10,
        isSelectedAll: false,
        showColumnFilters: this.props.showColumnFilters || false,
        showSearchFilter: false
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.page !== prevProps.page)
            this.setState({ page: this.props.page });

        if (this.props.rowsPerPage !== prevProps.rowsPerPage)
            this.setState({ rowsPerPage: this.props.rowsPerPage });

        if (this.props.showColumnFilters !== prevProps.showColumnFilters)
            this.setState({ showColumnFilters: this.props.showColumnFilters });

        if (this.props.isSelectedAll !== prevProps.isSelectedAll)
            this.setState({ isSelectedAll: this.props.isSelectedAll });

        if (
            JSON.stringify(this.props.selected) !==
            JSON.stringify(prevProps.selected)
        )
            this.setState({ selected: this.props.selected });

        if (
            JSON.stringify(this.props.unselected) !==
            JSON.stringify(prevProps.unselected)
        )
            this.setState({ unselected: this.props.unselected });
    };

    handleStateChanged = (state, callback) => {
        this.setState(state, callback);
    };

    getSelectedDataOnCurrentPage = () => {
        let { selected, unselected, isSelectedAll } = this.state;
        let dataOnPage = this.getDataOnCurrentPage();

        selected = selected && dataUtility.arrayJsonToString(selected);
        unselected = unselected && dataUtility.arrayJsonToString(unselected);
        dataOnPage = dataOnPage && dataUtility.arrayJsonToString(dataOnPage);
        selected = isSelectedAll
            ? difference(dataOnPage, unselected)
            : intersection(dataOnPage, selected);

        return dataUtility.arrayStringToJSON(selected);
    };

    getDataOnCurrentPage = () => {
        let { data } = this.props;
        if (!data || !Array.isArray(data)) return [];
        return data;
    };

    render() {
        return (
            <Provider
                value={{
                    state: this.state,
                    props: this.props,
                    rowAdd: this.props.rowAdd,
                    handleAddRowClick: this.props.handleAddRowClick,
                    handleStateChanged: this.handleStateChanged,
                    getSelectedDataOnCurrentPage:
                        this.getSelectedDataOnCurrentPage,
                    getDataOnCurrentPage: this.getDataOnCurrentPage
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export const withState =
    (fields = []) =>
    (Component) =>
    (props) => {
        return (
            <Consumer>
                {(context) => {
                    const { state, props: context_props, ...rest } = context;
                    let values = {};
                    //eslint-disable-next-line array-callback-return
                    fields.map((field) => {
                        values[field] = state[field];
                    });
                    return <Component {...values} {...props} {...rest} />;
                }}
            </Consumer>
        );
    };

export const withProps =
    (fields = []) =>
    (Component) =>
    (props) => {
        return (
            <Consumer>
                {(context) => {
                    const { props: context_props } = context;
                    let values = {};
                    //eslint-disable-next-line array-callback-return
                    fields.map((field) => {
                        values[field] = context_props[field];
                    });
                    return <Component {...values} {...props} />;
                }}
            </Consumer>
        );
    };

export default ResultsTableProvider;
