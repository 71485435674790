import React, { PureComponent } from 'react';

import ArchiveIcon from '@material-ui/icons/ArchiveRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ColumnFilterIcon from '@material-ui/icons/ViewColumnOutlined';
import CompareArrowsIcon from '@material-ui/icons/CompareArrowsRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import DownloadIcon from '@material-ui/icons/ArrowDownwardRounded';
import DuplicateIcon from '@material-ui/icons/FileCopyRounded';
import ExportIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/VerticalAlignTop';
import RemindIcon from '@material-ui/icons/NotificationsActiveRounded';
import RenewIcon from '@material-ui/icons/AutorenewRounded';
import ResultsTableSearchFilter from './ResultsTableSearchFilter';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/SendRounded';
import ThumbUp from '@material-ui/icons/ThumbUp';
import AddTopPick from '@material-ui/icons/Bookmark';
import RemoveTopPick from '@material-ui/icons/BookmarkBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import UnpublishIcon from '@material-ui/icons/VerticalAlignBottom';
import ThumbDown from '@material-ui/icons/ThumbDown';
import Ready from '@material-ui/icons/AssignmentTurnedIn';
import Broken from '@material-ui/icons/LinkOff';
import Verified from '@material-ui/icons/Check';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import { withState, withProps } from './ResultsTableProvider';
import { Badge } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
        borderRadius: 3
    },
    highlight: {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: '0 0 auto'
    },
    tableTitle: {
        fontSize: '1.2rem',
        fontWeight: 'normal'
    },
    toolbarActions: {
        display: 'flex',
        alignItems: 'center'
    },
    batchActions: {
        display: 'flex'
    },
    unpublish: {
        color: 'blue'
    },
    iconButton: {
        width: 48,
        height: 48
    },
    badge: {
        marginLeft: 0
    }
});

const icons = {
    delete: {
        label: 'Delete',
        icon: <DeleteIcon />
    },
    export: {
        label: 'Export',
        icon: <ExportIcon />
    },
    approve: {
        label: 'Approve',
        icon: <ThumbUp />
    },
    unapprove: {
        label: 'Unapprove',
        icon: <CloseIcon />
    },
    archive: {
        label: 'Archive',
        icon: <ArchiveIcon />
    },
    renew: {
        label: 'Renew',
        icon: <RenewIcon />
    },
    duplicate: {
        label: 'Duplicate',
        icon: <DuplicateIcon />
    },
    unpublish: {
        label: 'Unpublish',
        icon: <UnpublishIcon iconFill={'#74696c'} />
    },
    publish: {
        label: 'Publish',
        icon: <PublishIcon iconFill={'#74696c'} />
    },
    mark_as_checked: {
        label: 'Mark As Checked',
        icon: <CheckIcon />
    },
    transfer: {
        label: 'Transfer To New Program',
        icon: <CompareArrowsIcon />
    },
    send: {
        label: 'Send to Client',
        icon: <SendIcon />
    },
    remind: {
        label: 'Remind Client',
        icon: <RemindIcon />
    },
    download: {
        label: 'Download',
        icon: <DownloadIcon />
    },
    qualify: {
        label: 'Set as Qualified',
        icon: <ThumbUp />
    },
    unqualify: {
        label: 'Mark as Unqualified',
        icon: <ThumbDown />
    },
    ready: {
        label: 'Mark as Ready',
        icon: <Ready />
    },
    verified: {
        label: 'Mark as Verified',
        icon: <Verified />
    },
    broken: {
        label: 'Mark as Broken',
        icon: <Broken />
    },
    upgrade: {
        label: 'Upgrade',
        icon: <LinkIcon />
    },
    downgrade: {
        label: 'Downgrade',
        icon: <LinkOffIcon />
    },
    add_top_pick: {
        label: 'Mark as GoAbroad Pick',
        icon: <AddTopPick />
    },
    remove_top_pick: {
        label: 'Unmark as GoAbroad Pick',
        icon: <RemoveTopPick />
    },
};

const usedStates = [
    'isSelectedAll',
    'showSearchFilter',
    'showColumnFilters',
    'selected',
    'unselected'
];

const usedProps = [
    'count',
    'label',
    'customLabel',
    'hasSearch',
    'columns',
    'searchFilterOptions',
    'onSearchFilterChange',
    'onSearchFilterOpen',
    'onSearchFilterClose',
    'onColumnFilterOpen',
    'onGlobalFiltersClick',
    'batchActions',
    'headerCTA',
    'isLoadingData',
    'hasGlobalFilters',
    'hasColumnFilters',
    'activeGlobalFilters',
    'disabledColumnFilter',
    'disabledSearchFilter',
    'disabledGlobalFilter'
];

class ResultsTableEnhancedToolbar extends PureComponent {
    handleShowSearchFilter = () => {
        const { handleStateChanged, onSearchFilterOpen: callback } = this.props;
        handleStateChanged({ showSearchFilter: true }, callback);
    };

    handleCloseSearchFilter = () => {
        const { handleStateChanged, onSearchFilterClose: callback } =
            this.props;
        handleStateChanged({ showSearchFilter: false }, callback);
    };

    handleColumnFilterClick = () => {
        const { handleStateChanged: setState, onColumnFilterOpen: callback } =
            this.props;
        setState({ showColumnFilters: true }, callback);
    };

    renderToolbarTitle = () => {
        const {
            classes,
            count = 0,
            label = 'record',
            hasSearch = true,
            searchFilterOptions = [],
            onSearchFilterChange,
            isSelectedAll,
            showSearchFilter,
            selected,
            unselected,
            isLoadingData,
            customLabel
        } = this.props;

        const numSelected = isSelectedAll
            ? count - unselected.length
            : selected.length;

        if (hasSearch && showSearchFilter)
            return (
                <ResultsTableSearchFilter
                    className="data-cy-application-search-filter"
                    searchFilterOptions={searchFilterOptions}
                    onSearchFilterChange={onSearchFilterChange}
                    isLoadingData={isLoadingData}
                />
            );

        if (numSelected > 0)
            return (
                <Typography color="inherit" variant="subheading">
                    {numSelected
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                    selected
                </Typography>
            );

        if (isLoadingData)
            return (
                <Typography
                    variant="h6"
                    id="tableTitle"
                    className={classes.tableTitle}
                >
                    Loading ...
                </Typography>
            );

        return (
            <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
                data-cy={`${label}-header`}
            >
                {customLabel ||
                    `You are viewing ${count
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${label}`}
            </Typography>
        );
    };

    renderBatchActions = () => {
        const { batchActions = [] } = this.props;

        if (!batchActions || !Array.isArray(batchActions)) return null;
        return batchActions.map(this.renderBatchActionButton);
    };

    renderBatchActionButton = (action, key) => {
        const { classes, isSelectedAll } = this.props;
        const { icon, handler, disabled, enableInSelectAll } = action;

        if (!enableInSelectAll && isSelectedAll) return null;

        return (
            <Tooltip
                title={icons[icon].label}
                key={`accounts-batchicons-${key}-tooltip`}
                placement="top"
            >
                <IconButton
                    data-cy={`batch-buttons-${key}`}
                    className={classes.iconButton}
                    key={`accounts-batchicons-${key}`}
                    aria-label={icons[icon].label}
                    onClick={handler}
                    disabled={disabled}
                >
                    {icons[icon].icon}
                </IconButton>
            </Tooltip>
        );
    };

    renderHeaderCTA = () => {
        const { headerCTA } = this.props;

        if (!headerCTA || !Array.isArray(headerCTA)) return null;
        return headerCTA.map(this.renderCTA);
    };

    renderCTA = (cta, index) => {
        return <div key={`top-cta-${index}`}>{cta}</div>;
    };

    render() {
        const {
            classes,
            count = 0,
            hasSearch = true,
            hasColumnFilters = true,
            hasGlobalFilters = true,
            onGlobalFiltersClick,
            isLoadingData,
            isSelectedAll,
            showSearchFilter,
            showColumnFilters,
            selected,
            unselected,
            searchFilterOptions: options,
            columns = [],
            activeGlobalFilters,
            disabledColumnFilter,
            disabledSearchFilter,
            disabledGlobalFilter
        } = this.props;
        const numSelected = isSelectedAll
            ? count - unselected.length
            : selected.length;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}
            >
                <div className={classes.title}>{this.renderToolbarTitle()}</div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <div className={this.props.classes.batchActions} data-cy="batch-actions-row">
                            {this.renderBatchActions()}
                        </div>
                    ) : (
                        <div className={classes.toolbarActions}>
                            {hasSearch && (
                                <Tooltip
                                    title={
                                        showSearchFilter
                                            ? 'Clear Search'
                                            : 'Toggle Search Filter'
                                    }
                                    placement="top"
                                >
                                    {showSearchFilter ? (
                                        <IconButton
                                            data-cy="hide-search-filter"
                                            aria-label="Close"
                                            onClick={
                                                this.handleCloseSearchFilter
                                            }
                                            disabled={
                                                isLoadingData ||
                                                disabledSearchFilter
                                            }
                                            color="secondary"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            data-cy="show-search-filter"
                                            aria-label="Search"
                                            onClick={
                                                this.handleShowSearchFilter
                                            }
                                            disabled={
                                                isLoadingData ||
                                                (Array.isArray(options)
                                                    ? options
                                                    : []
                                                ).length === 0
                                            }
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            )}

                            {hasColumnFilters && (
                                <IconButton
                                    data-cy="column-filter"
                                    aria-label="Column Filter"
                                    onClick={this.handleColumnFilterClick}
                                    disabled={
                                        isLoadingData ||
                                        columns.length === 0 ||
                                        disabledColumnFilter
                                    }
                                    color={
                                        !!showColumnFilters ? 'primary' : 'none'
                                    }
                                >
                                    <Tooltip
                                        title="Column Filters"
                                        placement="top"
                                    >
                                        <ColumnFilterIcon />
                                    </Tooltip>
                                </IconButton>
                            )}

                            {hasGlobalFilters && (
                                <Badge
                                    color="primary"
                                    variant="dot"
                                    invisible={!activeGlobalFilters}
                                    className={classes.badge}
                                    overlap="circle"
                                >
                                    <IconButton
                                        data-cy="global-filter"
                                        aria-label="Filter"
                                        onClick={onGlobalFiltersClick}
                                        disabled={
                                            isLoadingData ||
                                            disabledGlobalFilter
                                        }
                                        color={
                                            activeGlobalFilters
                                                ? 'primary'
                                                : 'none'
                                        }
                                    >
                                        <Tooltip
                                            title={
                                                activeGlobalFilters
                                                    ? 'Has Active Global Filters'
                                                    : 'Global Filters'
                                            }
                                            placement="top"
                                        >
                                            <FilterListIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Badge>
                            )}
                            {this.renderHeaderCTA()}
                        </div>
                    )}
                </div>
            </Toolbar>
        );
    }
}

export default withState(usedStates)(
    withProps(usedProps)(withStyles(styles)(ResultsTableEnhancedToolbar))
);
