import React from 'react';
import {
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
    makeStyles
} from '@material-ui/core';
import { useScholarshipRankingState } from './ScholarshipRankingProvider';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        borderRadius: 3
    },
    reorder: {
        display: 'flex',
        marginLeft: 'auto'
    },
    reorderIcon: {
        '-moz-transform': 'scale(-1, 1)',
        '-o-transform': 'scale(-1, 1)',
        '-webkit-transform': 'scale(-1, 1)',
        transform: 'scale(-1, 1)'
    },
    highlight: {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85)
    },
    save: {
        color: green[500]
    }
}));

const TableToolbar = ({ onCancelRanking, onSaveRanking }) => {
    const classes = useStyles();
    const { reorder } = useScholarshipRankingState();

    return (
        <Toolbar
            className={classNames({
                [classes.root]: true,
                [classes.highlight]: reorder
            })}
        >
            <Typography variant="h6">
                {reorder ? 'Re-order Ranking' : 'Scholarship Homepage Ranking'}
            </Typography>
            {reorder && (
                <div className={classes.reorder}>
                    <Tooltip title="Cancel">
                        <IconButton
                            className={classes.cancel}
                            onClick={onCancelRanking}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save">
                        <IconButton
                            className={classes.save}
                            onClick={onSaveRanking}
                            color="primary"
                        >
                            <Check color="primary" className={classes.save} />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Toolbar>
    );
};

export default TableToolbar;
