import { SnackbarProvider } from 'notistack';
import React from 'react';
import AddInterviewDrawer from './AddInterviewDrawer';
import InterviewProvider from './InterviewsProvider';
import ResultsMain from './ResultsMain';

const Main = (props) => {
    return (
        <SnackbarProvider>
            <InterviewProvider>
                <AddInterviewDrawer />
                <ResultsMain />
            </InterviewProvider>
        </SnackbarProvider>
    );
};

export default Main;
