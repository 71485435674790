import AccountStatsProvider from './AccountStatsProvider';
import ClientStats from './AccountStats';
import { SnackbarProvider } from 'notistack';

import React from 'react';

const Main = (props) => {
    return (
        <SnackbarProvider>
            <AccountStatsProvider>
                <ClientStats />
            </AccountStatsProvider>
        </SnackbarProvider>
    );
};

export default Main;
