import { Chip, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import AddBoxIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useDefaultMetaState } from './DefaultMetaProvider';
import { META_PAGE_MAPPER, metaPageTypes } from '../../../../config/constants';
import { CRITERIA_PER_DIRECTORY } from '../../../../utilities/searchCriteria';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2)
    },
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    filterChip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

export default function DataFilter() {
    const classes = useStyles();
    const { filters, setDefaultMetaState } = useDefaultMetaState();
    const { directory } = filters;
    const handleAddFilter = () => setDefaultMetaState({ dataFilterOpen: true });

    const handleDeleteFilter = (key) => () => {
        let y = ["type", "timing"];

        setDefaultMetaState((prev) => ({
            ...prev,
            metaTypes: key === "directory"
                ? [...prev.metaTypes].filter((x) => !y.includes(x.value))
                : [...prev.metaTypes],
            filters: {
                ...prev.filters,
                [key]: null,
                pageTypes: key === "directory"
                    ? [...prev.filters.pageTypes].filter((x) => !y.includes(x))
                    : [...prev.filters.pageTypes]
            }
        }))


    };

    const handleDeletePageType = (key) => () => {
        let y = ["type", "timing"];

        setDefaultMetaState((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                pageTypes: key === "country"
                    ? [...prev.filters.pageTypes].filter(
                        (type) => y.includes(type))
                    : [...prev.filters.pageTypes].filter(
                        (type) => type !== key
                    )
            }
        }));
    };

    const hasFilters = Object.keys(filters || {}).some((key) => {
        if (key === 'online') return filters[key];
        if (key === 'pageTypes') return filters[key].length;
        return !!filters[key];
    });

    return (
        <Paper className={classes.paper}>
            <div className={classes.root}>
                <Typography variant="overline">Data Filters</Typography>
                <br />
                {Object.keys(filters)
                    .filter((key) =>
                        ['directory', 'pageTypes', 'online'].includes(key)
                    )
                    .map((key) => {
                        if (!filters[key]) return null;

                        if (key === 'directory') {
                            const { label } = filters[key] || {};
                            return (
                                <Chip
                                    data-cy="default-meta-directory-filter-chip"
                                    label={label}
                                    color="primary"
                                    onDelete={handleDeleteFilter(key)}
                                    onClick={handleAddFilter}
                                    className={classes.filterChip}
                                />
                            );
                        }

                        if (key === 'online' && filters[key]) {
                            return (
                                <Chip
                                    data-cy="default-meta-online-filter-chip"
                                    label={'Include Online'}
                                    color="primary"
                                    onDelete={handleDeleteFilter(key)}
                                    onClick={handleAddFilter}
                                    className={classes.filterChip}
                                />
                            );
                        }

                        if (key === 'pageTypes') {
                            return metaPageTypes
                                .filter(({ value }) =>
                                    filters[key].includes(value)
                                )
                                .map(({ value, label, isLocation }) => {
                                    if (isLocation && filters.online) return null

                                    if (!isLocation) {
                                        let metaCriteria = CRITERIA_PER_DIRECTORY[directory.value][value];
                                        label = !!metaCriteria ? META_PAGE_MAPPER[metaCriteria] : label
                                    }

                                    return (
                                        <Chip
                                            data-cy="default-meta-page-type-filter-chip"
                                            label={label}
                                            color="primary"
                                            onDelete={handleDeletePageType(
                                                value
                                            )}
                                            onClick={handleAddFilter}
                                            className={classes.filterChip}
                                        />
                                    );
                                });
                        }

                        return null;
                    })}
                <Chip
                    icon={
                        hasFilters ? (
                            <EditIcon fontSize="small" />
                        ) : (
                            <AddBoxIcon fontSize="small" />
                        )
                    }
                    data-cy="default-meta-add-data-filter"
                    label={hasFilters ? 'Edit Filters' : 'Add Filters'}
                    onClick={handleAddFilter}
                    color="primary"
                    variant="outlined"
                    className={classes.filterChip}
                />
            </div>
        </Paper>
    );
}
