import {
    Drawer,
    makeStyles,
    Paper,
    Typography,
    Button,
    TextField
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect } from 'react';
import { useGlobalMethods, useGlobalState } from '../Client/GlobalProvider';
import Select from 'react-select';
import SearchBasedDropdown from '../../components/SearchBasedDropdown/SearchBasedDropdown';
import { useObjectState } from '../../utilities/customHooks';
import { sendRequest } from '../../helpers/apiRequestUtility';
import { useInterviewState } from './InterviewsProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        color: '#555',
        textAlign: 'left',
        fontSize: '1.5em',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    create: {
        width: '100%',
        marginTop: 'auto'
    }
}));

const AddInterviewDrawer = (props) => {
    const classes = useStyles();
    const {
        providers,
        fetchingProviders,
        interviewDrawerOpen,
        handleStateChange: setGlobalState
    } = useGlobalState();
    const { fetchProviders } = useGlobalMethods();
    const [state, setState] = useObjectState({
        name: '',
        type: null,
        provider: null,
        program: null,
        programs: [],
        fetcingPrograms: false
    });
    const { handleAddInterview } = useInterviewState();

    const handleFieldChange = (field) => (value) =>
        setState({ [field]: value });

    useEffect(() => {
        if (!providers.length) fetchProviders();
    }, [providers, fetchProviders]);

    useEffect(() => {
        const fetchPrograms = () =>
            new Promise((res, rej) => {
                return sendRequest(
                    `/programs?fields=id,name&provider_id=${state.provider.value}&status=1`,
                    ({ programs }) => res(programs),
                    () => rej([])
                );
            });

        if (!!state.provider && !!state.provider.value) {
            setState({ fethingPrograms: true });
            fetchPrograms().then((data) =>
                setState({ programs: data || [], fethingPrograms: false })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.provider]);

    const { provider, program, programs, type, name, fethingPrograms } = state;

    const formComplete = [
        !!name,
        !!type,
        !!provider
            ? !!type
                ? type.value === 'alumni'
                    ? !!program
                    : true
                : false
            : false
    ].every((v) => v);

    return (
        <Drawer
            anchor="right"
            open={interviewDrawerOpen}
            onClose={() => setGlobalState({ interviewDrawerOpen: false })}
            BackdropProps={{
                invisible: false
            }}
        >
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography className={classes.drawerTitle}>
                        Add Interview
                    </Typography>
                    <Typography variant="overline">Interviewee Name</Typography>
                    <TextField
                        data-cy="interviewee-name"
                        fullWidth
                        variant="outlined"
                        size="small"
                        autoFocus
                        onChange={({ target: { value } }) =>
                            handleFieldChange('name')(value)
                        }
                        value={name}
                    />
                    <br />
                    <Typography variant="overline">Interviewee Type</Typography>
                    <Select
                        id="type-div"
                        value={type}
                        options={[
                            { value: 'alumni', label: 'Alumni' },
                            { value: 'staff', label: 'Staff' }
                        ]}
                        onChange={handleFieldChange('type')}
                    />
                    <br />
                    <Typography variant="overline">Provider Name</Typography>
                    <SearchBasedDropdown
                        id="provider-div"
                        value={provider}
                        arrayOptions={providers}
                        valueKey="id"
                        labelKey="name"
                        isDisabled={fetchingProviders}
                        placeholder={
                            fetchingProviders
                                ? 'Loading ...'
                                : 'Select Provider'
                        }
                        onChange={handleFieldChange('provider')}
                    />
                    <br />
                    <Typography variant="overline">Program Listing</Typography>
                    <SearchBasedDropdown
                        id="program-div"
                        value={program}
                        arrayOptions={programs}
                        valueKey="id"
                        labelKey="name"
                        isDisabled={
                            fethingPrograms ||
                            !state.provider ||
                            !state.provider.value
                        }
                        placeholder={
                            !state.provider
                                ? 'Select Provider to load programs'
                                : fethingPrograms
                                    ? 'Loading programs ...'
                                    : 'Select Program'
                        }
                        isLoading={fethingPrograms}
                        onChange={handleFieldChange('program')}
                        isClearable
                    />
                    <br />
                </Paper>
                <div className={classes.create}>
                    <Button
                        data-cy="btn-create-interview-2"
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<SaveIcon />}
                        disableElevation
                        disableFocusRipple
                        onClick={() => handleAddInterview(state)}
                        disabled={!formComplete}
                    >
                        Create Interview
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default AddInterviewDrawer;
