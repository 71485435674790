import React, { PureComponent } from 'react';
import { AccountStatsContext, GlobalContext } from '../../../context';
import { sendRequest } from '../../../helpers/apiRequestUtility';
import { toUTCDate } from '../../../utilities/dateFormatter';
import { withRouter } from 'react-router-dom';
import { withGlobalContext } from '../../Client/GlobalProvider';
import { find } from 'lodash';
import { withSnackbar } from 'notistack';

import {
    format,
    differenceInDays,
    differenceInCalendarYears,
    addDays,
    endOfMonth,
    subDays,
} from 'date-fns';
import { explodeUrlParams } from '../../../helpers/dataUtility';

const { Provider, Consumer } = AccountStatsContext;

const columns = [
    {
        label: 'Total Direct Contacts',
        key: 'total_direct_contacts',
        isShown: true,
    },
    {
        label: 'Ad Impressions',
        key: 'ad_impressions',
        isShown: true,
    },
    {
        label: 'Ad Clicks',
        key: 'ad_clicks',
        isShown: true,
    },
    {
        label: 'Listing Impressions',
        key: 'listing_impressions',
        isShown: true,
    },
    {
        label: 'Listing Pageviews',
        key: 'listing_pageviews',
        isShown: true,
    },
    {
        label: 'PDF Downloads',
        key: 'pdf_downloads',
        isShown: true,
    },
    {
        label: 'Client Link Clicks',
        key: 'link_clicks',
        isShown: true,
    },
    {
        label: 'Application Clicks',
        key: 'application_clicks',
        isShown: true,
    },
    {
        label: 'Client Inquiry Clicks',
        key: 'inquiry_clicks',
        isShown: true,
    },
    {
        label: 'GoAbroad Inquiries',
        key: 'goabroad_inquiries',
        isShown: true,
    },
    {
        label: 'Participant Matches',
        key: 'participant_matches',
        isShown: true,
    },
    {
        label: 'Newsletter Clicks',
        key: 'newsletter_clicks',
        isShown: true,
    },
    {
        label: 'Article Clicks',
        key: 'article_clicks',
        isShown: true,
    },
    {
        label: 'Interview Clicks',
        key: 'interview_clicks',
        isShown: true,
    },
    {
        label: 'MyG Individual Applicants',
        key: 'myg_applicants',
        isShown: true,
    },
    {
        label: 'MyG Perfect Matches',
        key: 'best_matches',
        isShown: true,
    },
    {
        label: 'MyG Good Matches',
        key: 'partial_matches',
        isShown: true,
    },
    {
        label: 'MyG Perfect Matches Redemptions',
        key: 'best_redemptions',
        isShown: true,
    },
    {
        label: 'MyG Good Matches Redemptions',
        key: 'partial_redemptions',
        isShown: true,
    },
    {
        label: 'MyG Total Redemptions',
        key: 'total_redemptions',
        isShown: true,
    },
];

const compareValues = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }

        return order === 'asc' ? comparison * -1 : comparison;
    };
};

class AccountStatsProvider extends PureComponent {
    static contextType = GlobalContext;
    state = {
        columns: columns,
        compare: false,
        comparisonRangeID: 1,
        comparisonDates: {},
        dateRangeID: 6,
        dates: { start: subDays(new Date(), 30), end: new Date() },
        displayBy: 'month',
        filters: {},
        rowsPerPage: 12,
        sortingOrder: 'asc',
        sortBy: '',
        statsTableData: [],
        fetching: false,
        exportData: [],
        enableExport: false,
        selected: [],
        location: this.props.history,
    };

    componentDidMount = async () => {
        const {
            fetchProviders,
            fetchDirectories,
            fetchCountries,
            fetchProductItems,
        } = this.context;
        const search_params = explodeUrlParams(this.props.location.search);
        const client = search_params.provider_id
            ? { value: search_params.provider_id }
            : null;

        this.setState(
            {
                fetching: true,
                filters: search_params.provider_id ? { client } : {},
            },
            this.fetchStatsData
        );

        await fetchProviders();
        fetchDirectories();
        fetchCountries();
        fetchProductItems();

        if (search_params.provider_id) {
            const provider = find(this.context.state.providers, {
                id: search_params.provider_id,
            });
            this.setState({
                filters: {
                    client: provider
                        ? { value: provider.id, label: provider.name }
                        : null,
                },
            });
        }
    };

    handleSetState = (newState, callback) => this.setState(newState, callback);

    handleFiltersChange = () => {};

    transformDateParam = ({ start, end }) => {
        if (differenceInDays(new Date(start), new Date(end)) === 0)
            return format(new Date(start), 'YYYY-MM-DD');
        else
            return `${format(new Date(start), 'YYYY-MM-DD')},${format(
                new Date(end),
                'YYYY-MM-DD'
            )}`;
    };

    buildParams = (params = {}) => {
        return Object.keys(params)
            .filter((key) => {
                return ![undefined, null, NaN, ''].includes(params[key]);
            })
            .map((key) => `${key}=${params[key]}`)
            .join('&');
    };

    buildFilterParams = () => {
        const { dates, compare, comparisonDates, filters } = this.state;
        const {
            client = {},
            directory = {},
            location = {},
            adType = {},
            listing = '',
            contentType = {},
        } = filters;
        const search_params = explodeUrlParams(this.props.location.search);
        const filterParams = {
            provider_id: (client || {}).value || search_params.provider_id,
            directory_id: (directory || {}).value,
            country_id: (location || {}).value,
            product_item_id: (adType || {}).value,
            program_id: listing,
            referer_type: (contentType || {}).value,
        };

        let params = {
            date: this.transformDateParam(dates),
            compare_date: compare
                ? this.transformDateParam(comparisonDates)
                : null,
            ...filterParams,
        };

        return this.buildParams(params);
    };

    computeStats = (stats) => {
        const { displayBy: display = 'month' } = this.state;
        const {
            bct = 0,
            bi = 0,
            ci = 0,
            cns = 0,
            csct = 0,
            emtc = 0,
            flv = 0,
            lpv = 0,
            ls = 0,
            nl = 0,
            oai = 0,
            dl = 0,
            acsct = 0,
            icsct = 0,
            mygia = 0,
            mygbm = 0,
            mygpm = 0,
            mygbmr = 0,
            mygpmr = 0,
        } = stats;

        return {
            total_direct_contacts: ['interview', 'article'].includes(display)
                ? 0
                : emtc + csct + ci + oai + bct + cns + ls + acsct + icsct,
            ad_impressions: bi,
            ad_clicks: bct,
            listing_impressions: lpv,
            listing_pageviews: flv,
            link_clicks: ['article', 'interview'].includes(display) ? 0 : csct,
            application_clicks: ls,
            inquiry_clicks: ci,
            goabroad_inquiries: emtc,
            participant_matches: oai,
            newsletter_clicks: ['month', 'day', 'week', 'directory'].includes(
                display
            )
                ? cns + nl
                : cns,
            article_clicks: ['interview'].includes(display) ? 0 : acsct,
            interview_clicks: ['article'].includes(display) ? 0 : icsct,
            pdf_downloads: dl,
            myg_applicants: mygia,
            best_matches: mygbm,
            partial_matches: mygpm,
            best_redemptions: mygbmr,
            partial_redemptions: mygpmr,
            total_redemptions: mygbmr + mygpmr,
        };
    };

    statsDateSorter = (a, b) => {
        const { compare } = this.state;
        if ((compare ? a.date1 : a.date) < (compare ? b.date1 : b.date))
            return -1;
        if ((compare ? a.date1 : a.date) > (compare ? b.date1 : b.date))
            return 1;
        return 0;
    };

    datesCompareLabel = (date1, date2) => {
        const { comparisonRangeID, displayBy } = this.state;
        const start = toUTCDate(date1),
            end = toUTCDate(date2);
        const compare_labels = {
            month: format(start, 'MMMM'),
            day: format(start, 'MMM DD'),
            week:
                format(new Date(date1), 'MMM DD YYYY') +
                ' - ' +
                format(addDays(new Date(date1), 6), 'MMM DD YYYY'),
        };
        const label1 = {
            1: {
                month: format(start, 'YYYY'),
                day: format(start, 'YYYY'),
                week: format(start, 'YYYY'),
            },
            2: {
                month: `${format(start, 'MMM DD YYYY')} - ${format(
                    new Date(endOfMonth(start)),
                    'MMM DD YYYY'
                )}`,
                day: format(start, 'MMM DD YYYY'),
                week: format(start, 'YYYY'),
            },
        };
        const label2 = {
            1: {
                month: format(end, 'YYYY'),
                day: format(end, 'YYYY'),
                week: format(end, 'YYYY'),
            },
            2: {
                month: `${format(end, 'MMM DD YYYY')} - ${format(
                    new Date(endOfMonth(end)),
                    'MMM DD YYYY'
                )}`,
                day: format(end, 'MMM DD YYYY'),
                week: format(start, 'YYYY'),
            },
        };

        return {
            compare_label:
                comparisonRangeID === 1 || displayBy === 'week'
                    ? compare_labels[displayBy] || '--'
                    : '--',
            label1: label1[comparisonRangeID][displayBy],
            label2: label2[comparisonRangeID][displayBy],
        };
    };

    dateRangeLabel = (date) => {
        const { comparisonRangeID } = this.state;
        const [start, end] = date.split(',');

        if (comparisonRangeID === 1) return format(start, 'YYYY');

        return `${format(start, 'MMM DD YYYY')} - ${format(
            new Date(end),
            'MMM DD YYYY'
        )}`;
    };

    dateRangeObjectLabel = (date) => {
        const { comparisonRangeID } = this.state;
        const { start, end } = date;

        if (comparisonRangeID === 1) return format(start, 'YYYY');

        return `${format(start, 'MMM DD YYYY')} - ${format(
            end,
            'MMM DD YYYY'
        )}`;
    };

    datesStats = ({ date, stats, date1, date2, stats1, stats2 }) => {
        const { dates, compare, displayBy } = this.state;
        const yearDiff = differenceInCalendarYears(
            new Date(dates.end),
            new Date(dates.start)
        );
        const labels = {
            month: format(toUTCDate(date), yearDiff > 0 ? 'MMMM YYYY' : 'MMMM'),
            day: format(toUTCDate(date), 'MMMM DD, YYYY'),
            week:
                format(toUTCDate(date), 'MMM DD YYYY') +
                ' - ' +
                format(addDays(toUTCDate(date), 6), 'MMM DD YYYY'),
        };

        const computedStats = this.computeStats(stats || stats1 || {});
        let comparedStats = {};

        if (compare) {
            const { compare_label, label1, label2 } = this.datesCompareLabel(
                date1,
                date2
            );
            comparedStats = {
                compare_label,
                label: label1,
                compare: {
                    label: label2,
                    ...this.computeStats(stats2),
                },
            };
        }

        return {
            label: labels[displayBy],
            ...computedStats,
            ...comparedStats,
        };
    };

    nonDatesStats = ({
        label,
        stats,
        date1,
        date2,
        stats1,
        stats2,
        ...others
    }) => {
        const { compare } = this.state;
        const computedStats = this.computeStats(stats || stats1 || {});
        let comparedStats = {};

        if (compare)
            comparedStats = {
                compare_label: label,
                label: this.dateRangeLabel(date1),
                compare: {
                    label: this.dateRangeLabel(date2),
                    ...this.computeStats(stats2),
                },
            };

        return {
            label,
            ...computedStats,
            ...comparedStats,
            ...others,
        };
    };

    handleError = () => {
        const { enqueueSnackbar } = this.props;

        this.setState({
            statsTableData: [],
            rowsPerPage: 12,
            page: 0,
            selected: [],
            enableExport: false,
            sortBy: '',
            sortingOrder: 'asc',
        });

        enqueueSnackbar('Ops! Error was encountered. Please try again.', {
            variant: 'error',
        });
    };

    fetchStatsByMonths = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/months?${params}`,
            ({ months }) => {
                let mappedData = months
                    .sort(this.statsDateSorter)
                    .map(this.datesStats);
                this.setState({
                    statsTableData: mappedData,
                    rowsPerPage: 12,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: '',
                    sortingOrder: 'asc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByWeeks = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/weeks?${params}`,
            ({ weeks }) => {
                let mappedData = weeks
                    .sort(this.statsDateSorter)
                    .map(this.datesStats);
                this.setState({
                    statsTableData: mappedData,
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: '',
                    sortingOrder: 'asc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByDay = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/days?${params}`,
            ({ days }) => {
                let mappedData = days
                    .sort(this.statsDateSorter)
                    .map(this.datesStats);
                this.setState({
                    statsTableData: mappedData,
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: '',
                    sortingOrder: 'asc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByLocation = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/locations?limit=-1&${params}`,
            ({ locations }) => {
                let mappedData = locations.map(
                    ({ name: label, stats, date1, date2, stats1, stats2 }) =>
                        this.nonDatesStats({
                            label,
                            stats,
                            date1,
                            date2,
                            stats1,
                            stats2,
                        })
                );
                this.setState({
                    statsTableData: [...mappedData].sort(
                        compareValues('ad_impressions', 'asc')
                    ),
                    rowsPerPage: 12,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: 'ad_impressions',
                    sortingOrder: 'desc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByAdTypes = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/ad-types?limit=-1&${params}`,
            ({ ad_types }) => {
                let mappedData = ad_types.map(
                    ({
                        id,
                        name: label,
                        stats,
                        date1,
                        date2,
                        stats1,
                        stats2,
                    }) =>
                        this.nonDatesStats({
                            label,
                            stats,
                            date1,
                            date2,
                            stats1,
                            stats2,
                            url: `/client-accounts/ads?item_id=${id}`,
                        })
                );
                this.setState({
                    statsTableData: [...mappedData].sort(
                        compareValues('ad_impressions', 'asc')
                    ),
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: 'ad_impressions',
                    sortingOrder: 'desc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByDirectories = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/directories?limit=-1&${params}`,
            ({ directories }) => {
                let mappedData = directories.map(
                    ({ name: label, stats, date1, date2, stats1, stats2 }) =>
                        this.nonDatesStats({
                            label,
                            stats,
                            date1,
                            date2,
                            stats1,
                            stats2,
                        })
                );
                this.setState({
                    statsTableData: [...mappedData].sort(
                        compareValues('ad_impressions', 'asc')
                    ),
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: 'ad_impressions',
                    sortingOrder: 'desc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByPrograms = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/programs?limit=-1&${params}`,
            ({ programs }) => {
                let mappedData = programs.map(
                    ({
                        id,
                        name: label,
                        stats,
                        date1,
                        date2,
                        stats1,
                        stats2,
                    }) =>
                        this.nonDatesStats({
                            label,
                            stats,
                            date1,
                            date2,
                            stats1,
                            stats2,
                            url: `/client-accounts/programs?program_id=${id}`,
                        })
                );
                this.setState({
                    statsTableData: [...mappedData].sort(
                        compareValues('listing_impressions', 'asc')
                    ),
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: 'listing_impressions',
                    sortingOrder: 'desc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByArticles = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/articles?referer_type=1&limit=-1&${params}`,
            ({ articles }) => {
                let mappedData = articles.map(
                    ({
                        id,
                        title: label,
                        stats,
                        date1,
                        date2,
                        stats1,
                        stats2,
                    }) =>
                        this.nonDatesStats({
                            label,
                            stats,
                            date1,
                            date2,
                            stats1,
                            stats2,
                            url: `/articles?article_id=${id}`,
                        })
                );
                this.setState({
                    statsTableData: [...mappedData].sort(
                        compareValues('article_clicks', 'asc')
                    ),
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: 'article_clicks',
                    sortingOrder: 'desc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByInterviews = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/interviews?referer_type=2&limit=-1&${params}`,
            ({ interviews }) => {
                let mappedData = interviews.map(
                    ({ title: label, stats, date1, date2, stats1, stats2 }) =>
                        this.nonDatesStats({
                            label,
                            stats,
                            date1,
                            date2,
                            stats1,
                            stats2,
                        })
                );
                this.setState({
                    statsTableData: [...mappedData].sort(
                        compareValues('interview_clicks', 'asc')
                    ),
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                    sortBy: 'interview_clicks',
                    sortingOrder: 'desc',
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsByRange = () => {
        const params = this.buildFilterParams();
        this.setState({ fetching: true });
        sendRequest(
            `/stats/range?${params}`,
            ({ range }) => {
                const { dates, comparisonDates, dateRangeID } = this.state;
                let mappedData = [range].map((stats) => {
                    const dateRangeOptions = {
                        1: 'Today',
                        2: 'Yesterday',
                        3: 'This Month',
                        4: 'Last Month',
                        5: 'Last 7 Days',
                        6: 'Last 30 Days',
                        7: 'Year-to-Date',
                        8: 'Custom',
                    };
                    const label = dateRangeOptions[dateRangeID];
                    const computedStats = this.computeStats(
                        stats['stats1'] || {}
                    );
                    let comparedStats = {
                        compare_label: dateRangeOptions[dateRangeID],
                        label: this.dateRangeObjectLabel(dates),
                        compare: {
                            label: this.dateRangeObjectLabel(comparisonDates),
                            ...this.computeStats(stats['stats2']),
                        },
                    };

                    return {
                        label,
                        ...computedStats,
                        ...comparedStats,
                    };
                });
                this.setState({
                    statsTableData: mappedData,
                    rowsPerPage: 10,
                    page: 0,
                    selected: [],
                    enableExport: false,
                });
            },
            this.handleError
        ).finally(() => this.setState({ fetching: false }));
    };

    fetchStatsData = () => {
        const { displayBy } = this.state;

        if (displayBy === 'month') this.fetchStatsByMonths();
        if (displayBy === 'day') this.fetchStatsByDay();
        if (displayBy === 'location') this.fetchStatsByLocation();
        if (displayBy === 'directory') this.fetchStatsByDirectories();
        if (displayBy === 'listing') this.fetchStatsByPrograms();
        if (displayBy === 'ad_type') this.fetchStatsByAdTypes();
        if (displayBy === 'article') this.fetchStatsByArticles();
        if (displayBy === 'week') this.fetchStatsByWeeks();
        if (displayBy === 'interview') this.fetchStatsByInterviews();
        if (displayBy === 'range') this.fetchStatsByRange();
    };

    render() {
        return (
            <Provider
                value={{
                    state: this.state,
                    handleSetState: this.handleSetState,
                    fetchStatsData: this.fetchStatsData,
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export const withAccountStatsContext = (fields) => (Component) => (props) => {
    return (
        <Consumer>
            {({ state, ...rest }) => {
                let extracted = {};
                //eslint-disable-next-line array-callback-return
                Object.keys(state).map((key) => {
                    if ((fields || []).includes(key))
                        extracted[key] = state[key];
                });
                return <Component {...{ ...props, ...extracted, ...rest }} />;
            }}
        </Consumer>
    );
};

export default withSnackbar(
    withGlobalContext(withRouter(AccountStatsProvider))
);
