import React, { Component } from 'react';
import DialogBox from '../DialogBox/DialogBox';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: '',
            errorInfo: '',
            isOpen: false
        };
        this.handleOnClose = this.handleOnClose.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { isOpen: true, hasError: true, error };
    }

    handleOnClose() {
        this.setState({ isOpen: false });
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('errorInfo: ', errorInfo);
    }

    handleRefresh() {
        this.setState({ isOpen: false }, () => {
            window.location.reload();
        });
    }

    render() {
        const { hasError, isOpen } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <DialogBox
                    actions="Ok"
                    title="Error"
                    contentText="Something went wrong."
                    open={isOpen}
                    onOk={this.handleRefresh}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            this.handleOnClose();
                        }
                    }}
                />
            );
        }

        return children;
    }
}

export default ErrorBoundary;
