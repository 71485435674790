import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { useGlobalState } from '../../views/Client/GlobalProvider';
import { delete_cookie } from '../../helpers/cookieUtility';
import DialogBox from '../DialogBox/DialogBox';
import { postRequest } from '../../helpers/apiRequestUtility';
import LoginDialog from '../../views/Authentication/LoginDialog';
import { CLOUDINARY_URL } from '../../config/constants';

const drawerWidthExpanded = 200;

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.common.white,
        border: 'none',
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        position: 'relative',
        width: drawerWidthExpanded,
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme.transitions.create('width'),
        overflow: 'hidden',
        // paddingBottom: theme.spacing.unit * 2,
    },
    sidebarLinks: {
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '& > a': {
            textDecoration: 'none',
        },
    },
    sidebarLink: {
        borderLeft: `3px solid ${theme.palette.common.white}`,
        paddingTop: `${theme.spacing.unit * 2}px`,
        paddingBottom: `${theme.spacing.unit * 2}px`,
        '& svg': {
            color: grey[400],
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: lightBlue[50],
            borderLeft: `3px solid ${lightBlue[300]}`,
        },
        '&:hover svg': {
            color: lightBlue[600],
        },
        '& span': {
            color: grey[800],
            whiteSpace: 'nowrap',
        },
    },
    selected: {
        backgroundColor: `${lightBlue[50]} !important`,
        borderLeft: `3px solid ${lightBlue[300]}`,
        '& svg': {
            color: lightBlue[600],
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
    logout: {
        fontSize: '1rem',
        fontWeight: 'normal',
        textTransform: 'none',
        '& svg': {
            color: grey[400],
            marginRight: theme.spacing.unit * 3,
        },
    },
    user: {
        display: 'flex',
        padding: '20px',
        width: '100%',
        '& p': {
            fontSize: '1rem',
        },
    },
    avatar: {
        marginRight: theme.spacing.unit * 2,
        width: 45,
        height: 45,
    },
    welcome: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginTop: 2,
    },
    sidebarExpanded: {
        '& > div': {
            width: '80px !important',
            overflow: 'hidden',
        },
    },
    menuGutters: {
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    logoutButton: {
        borderLeft: `3px solid ${theme.palette.common.white}`,
        paddingTop: `${theme.spacing.unit * 2.5}px`,
        paddingBottom: `${theme.spacing.unit * 2.5}px`,
        '& svg': {
            color: grey[400],
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: lightBlue[50],
            borderLeft: `3px solid ${lightBlue[300]}`,
        },
        '&:hover svg': {
            color: lightBlue[600],
        },
        '& span': {
            color: grey[800],
        },
    },
    logoutButtonListItem: {
        minHeight: 69,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
}));

const Sidebar = ({ sidebarExpanded, routes }) => {
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { user = {}, handleStateChange } = useGlobalState();
    const history = useHistory();
    const location = useLocation();

    const handleLogout = () => {
        handleStateChange({
            providers: [],
            selectedProvider: null,
            scBuffer: {},
            types: [],
            timings: [],
            user: {},
            productItems: [],
            locations: [],
            accountManagers: [],
            directories: [],
            isLoading: false,
        });
        setShowLoader(true);
        postRequest('/logout').finally(() => {
            delete_cookie('user_info', '/');
            setShowLoader(false);
            history.push('/login', { from: '/dashboard' });
        });
    };

    const activeRoute = (routeName) =>
        !location
            ? null
            : location.pathname.indexOf(routeName) > -1
            ? true
            : false;

    return (
        <Fragment>
            <LoginDialog />
            <DialogBox
                open={showConfirm}
                actions={showLoader ? '' : 'OkCancel'}
                stringOverride={{ primaryAction: 'Logout' }}
                onOk={showLoader ? undefined : handleLogout}
                onCancel={() => setShowConfirm(false)}
                onClose={() => setShowConfirm(false)}
                disableBackdropClick={showLoader}
            >
                <Typography align="center" variant="h6">
                    {showLoader ? 'Logging out ...' : 'Do you wish to logout?'}
                </Typography>
            </DialogBox>
            <Drawer
                classes={{ paper: classes.drawerPaper }}
                className={classNames({
                    [classes.sidebarExpanded]: !sidebarExpanded,
                })}
                anchor="left"
                variant="permanent"
                open
            >
                <div className={classes.user}>
                    <Avatar
                        className={classes.avatar}
                        src={`${CLOUDINARY_URL}/c_pad,h_180,w_180/v2/images2/goabroad-logo.webp`}
                    />
                    <div>
                        <Typography className={classes.welcome}>
                            Welcome,
                        </Typography>
                        <Typography>{user.first_name}</Typography>
                    </div>
                </div>
                <List className={classes.sidebarLinks}>
                    {routes.map((prop, key) => {
                        if (prop.redirect) return null;

                        return (
                            <NavLink
                                data-cy={`side-${prop.name}`}
                                to={{ pathname: prop.path, tabSelected: 0 }}
                                key={key}
                            >
                                <Tooltip
                                    placement="right"
                                    title={sidebarExpanded ? '' : prop.name}
                                    key={`${prop.shortName}-tooltip`}
                                >
                                    <ListItem
                                        button
                                        disableRipple
                                        className={classes.sidebarLink}
                                        classes={{
                                            selected: classes.selected,
                                            gutters: classes.menuGutters,
                                        }}
                                        selected={activeRoute(prop.path)}
                                    >
                                        <ListItemIcon>
                                            {typeof prop.icon === 'string' ? (
                                                <Icon fontSize='small'>{prop.icon}</Icon>
                                            ) : (
                                                <prop.icon fontSize='small' />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={prop.shortName}
                                        />
                                    </ListItem>
                                </Tooltip>
                            </NavLink>
                        );
                    })}
                    {sidebarExpanded ? (
                        <ListItem
                            button
                            className={classes.logoutButton}
                            disableRipple
                            onClick={() => setShowConfirm(true)}
                            classes={{
                                gutters: classes.menuGutters,
                            }}
                        >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    ) : (
                        <Tooltip placement="right" title={'Logout'}>
                            <ListItem className={classes.logoutButtonListItem}>
                                <IconButton
                                    variant="fab"
                                    onClick={() => setShowConfirm(true)}
                                    data-cy="Logout"
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </ListItem>
                        </Tooltip>
                    )}
                </List>
            </Drawer>
        </Fragment>
    );
};

export default Sidebar;
