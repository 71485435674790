import React from 'react';
import ScholarshipRankingProvider from './ScholarshipRankingProvider';
import ScholarshipRankingTable from './ScholarshipRankingTable';
import { SnackbarProvider } from 'notistack';

const Main = ({ active }) => {
    if (!active) return null;

    return (
        <SnackbarProvider>
            <ScholarshipRankingProvider>
                <ScholarshipRankingTable />
            </ScholarshipRankingProvider>
        </SnackbarProvider>
    );
};

export default Main;
