import {
    Drawer,
    FormControlLabel,
    Paper,
    Typography,
    Checkbox,
    Divider,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import SearchBasedDropdown from '../../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { useCustomMetaState } from './CustomMetaProvider';
import { DIRECTORIES } from '../../../../utilities/directory';
import { useObjectState } from '../../../../utilities/customHooks';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Check';
import { metaPageTypes } from '../../../../config/constants';
import { CRITERIA_PER_DIRECTORY, SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';
import { fetchCriteriaOptions } from '../../../../utilities/searchCriteria';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36
            }
        }
    },
    paper: {
        padding: theme.spacing(2)
    },
    counter: {
        alignSelf: 'end'
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',
        '& button:first-child': {
            marginRight: theme.spacing(2)
        }
    },
    datePicker: {
        display: 'flex'
    },
    checkbox: {
        width: '100%'
    }
}));

export default function DataFiltersDrawer() {
    const classes = useStyles();
    const { filters, dataFilterOpen, setCustomMetaState, metaTypes } =
        useCustomMetaState();
    const [state, setState] = useObjectState({
        directory: null,
        pageTypes: [],
        online: false,
        disabledList: [],
        world_region: [],
        country: [],
        main_region: [],
        city: [],
        customPlacements: {},
        placementOptions: {},
        rawOptions: {},
    });
    const [onlineDisable, setOnlineDisable] = useState(true);
    const filterList = {
        city: ['country', 'city'],
        main_region: ['country', 'main_region'],
        country: ['country'],
        world_region: ['world_region'],
        type: ['type'],
        timing: ['timing']
    };

    const disList = {
        city: ['world_region', 'main_region'],
        main_region: ['world_region', 'city'],
        country: ['world_region', 'main_region', 'city'],
        world_region: ['country', 'main_region', 'city']
    }
    const directories = [...DIRECTORIES].filter(
        (directory) => !!directory.page_number_tag || directory.abbrv === 'GA'
    );
    const { disabledList, placementOptions, customPlacements } = state;

    const handleFieldChange = (field) => (value) => {
        const locationTypes = metaTypes.filter((item) => item.isLocation);

        setState({ [field]: value, customPlacements: {}, pageTypes: [], disabledList: [] });

        if (field === "directory" && value === null) {
            let y = ["type", "timing"];
            setState((prev) => ({
                ...prev,
                pageTypes: [...prev.pageTypes].filter((x) => !y.includes(x))
            }))
        } else if (field === "directory" && value) {
            let dir = DIRECTORIES.find(x => x.id === value.value);
            setOnlineDisable(!!!dir.has_online);
        }

        if (value) {
            let directoryID = value.value;
            let criteriaTypeID = CRITERIA_PER_DIRECTORY[directoryID].type;
            let criteriaTimingID = CRITERIA_PER_DIRECTORY[directoryID].timing;

            let criteraTypeDetails = SEARCH_CRITERIA[criteriaTypeID];
            let criteriaTimingDetails = SEARCH_CRITERIA[criteriaTimingID];

            let metaTypeTiming = []

            if (!!criteraTypeDetails) {
                metaTypeTiming.push({
                    isLocation: false,
                    value: 'type',
                    label: !!criteraTypeDetails ? criteraTypeDetails.name : '',
                    altLabel: 'Type',
                    id: criteraTypeDetails.new_criteria
                })
            }

            if (!!criteriaTimingDetails) {
                metaTypeTiming.push({
                    isLocation: false,
                    value: 'timing',
                    label: !!criteriaTimingDetails ? criteriaTimingDetails.name : '',
                    altLabel: 'Type',
                    id: criteriaTimingDetails.new_criteria
                })
            }
            setCustomMetaState((prev) => ({
                ...prev,
                metaTypes: [...locationTypes, ...metaTypeTiming]
            }))
        } else {
            setCustomMetaState({ metaTypes: locationTypes });
        }

    }

    const handlePageTypeChange = (isLocation, pageType) => (e, checked) => {

        let cusData = {}
        if (!isLocation) {
            var typeTiming = metaTypes.find((x) => x.value === pageType);
            cusData[typeTiming.id] = null
        } else {
            filterList[pageType].forEach((type) => {
                cusData[type] = null;
            });
        }

        setState((prev) => ({
            ...prev,
            disabledList: checked
                ? isLocation ? disList[pageType] : [...prev.disabledList]
                : isLocation ? [...prev.disabledList].filter((p) => !disList[pageType].includes(p)) : [...prev.disabledList],
            pageTypes: checked
                ? [...prev.pageTypes, ...filterList[pageType]]
                : [...prev.pageTypes].filter((p) => {
                    if (pageType === "country") {
                        let loc = ["city", "main_region", "country"];
                        return !loc.includes(p);
                    }
                    return !filterList[pageType].includes(p);
                }),
            customPlacements: checked
                ? { ...prev.customPlacements, ...cusData }
                : Object.fromEntries(
                    Object.entries(prev.customPlacements).filter(([key, value]) => {
                        if (isLocation) {
                            let loc = ["city", "main_region", "country"];
                            if (pageType === "country") {
                                return !loc.includes(key)
                            }
                            return !filterList[pageType].includes(key)
                        } else {
                            return key !== typeTiming.id
                        }
                    }))


        }));

    };

    const handleOnlineChange = (e, checked) => {
        let locations = ["world_region", "country", "main_region", "city"];
        setState((prev) => ({
            ...prev,
            online: checked,
            pageTypes: prev.pageTypes.filter((data) => !locations.includes(data)),
            disabledList: [],
            customPlacements: Object.fromEntries(
                Object.entries(prev.customPlacements).filter(([key, value]) => !locations.includes(key)))
        }));

    }
    const handleDisable = (isLocation, value = []) => {

        if (isLocation && state.online) {
            return true;
        }
        return disabledList.includes(value);


    }

    const handleApplyFilters = () =>
        setCustomMetaState({
            filters: state,
            dataFilterOpen: false,
            page: 0,
            count: 0,
            urlFilter: null
        });

    const handleClearFilters = () => {
        setState({
            directory: null,
            pageTypes: [],
            online: false,
            customPlacements: {},
            disabledList: []
        });
    };
    const setCustomPlacement = (type, value) =>
        setState((prev) => ({
            ...prev,
            customPlacements: { ...prev.customPlacements, [type]: value }
        }));

    const setPlacementOptions = (type, value) => {
        setState((prev) => ({
            ...prev,
            placementOptions: { ...prev.placementOptions, [type]: value }
        }));
    };

    const setRawPlacementOptions = (criteria, options = []) => {
        setState((prev) => ({
            ...prev,
            rawOptions: { ...prev.rawOptions, [criteria]: options }
        }));
    };

    const fetchPlacementOptions = async (criteria) => {
        setPlacementOptions(criteria, 'fetching');

        fetchCriteriaOptions(criteria + "&status=1")
            .then((data) => {
                setPlacementOptions(criteria, data || []);
                setRawPlacementOptions(criteria, data || []);

            })
            .catch(() => {
                setPlacementOptions(criteria, []);
            });
    };

    useEffect(() => {

        const placementCriteria = Object.keys(state.customPlacements || {});

        placementCriteria.forEach((criteria) => {
            if (!state.placementOptions[criteria]) {
                fetchPlacementOptions(criteria);
            }
        });
        if (
            (placementCriteria.includes('main_region') ||
                placementCriteria.includes('city')) &&
            !placementCriteria.includes('country')
        ) {
            fetchPlacementOptions('country');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements]);

    useEffect(() => {

        if (state.customPlacements.world_region) {
            const customPlacements = Object.keys(state.customPlacements);
            if (customPlacements.includes('country')) {
                const region_countries = state.rawOptions['country'].filter(
                    ({ world_region_id }) =>
                        world_region_id ===
                        state.customPlacements.world_region.value
                );
                setCustomPlacement('country', null);
                setPlacementOptions('country', region_countries);
            }

            if (customPlacements.includes('main_region')) {
                const region_countries = state.rawOptions['country']
                    .filter(
                        ({ world_region_id }) =>
                            world_region_id ===
                            state.customPlacements.world_region.value
                    )
                    .filter(({ value }) =>
                        state.customPlacements['country']
                            ? state.customPlacements['country'].value === value
                            : true
                    );
                const countryIds = region_countries.map(({ value }) => value);
                const mainRegions = state.rawOptions['main_region'].filter(
                    ({ country_id }) => countryIds.includes(country_id)
                );
                setCustomPlacement('main_region', null);
                setPlacementOptions('main_region', mainRegions);
            }

            if (customPlacements.includes('city')) {
                const region_countries = state.rawOptions['country'].filter(
                    ({ world_region_id }) =>
                        world_region_id ===
                        state.customPlacements.world_region.value
                );
                const countryIds = region_countries.map(({ value }) => value);
                setCustomPlacement('city', null);
                setPlacementOptions(
                    'city',
                    state.rawOptions['city'].filter(({ country_id }) =>
                        countryIds.includes(country_id)
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements.world_region]);

    useEffect(() => {

        if (state.customPlacements.country) {
            const customPlacements = Object.keys(state.customPlacements);

            if (customPlacements.includes('main_region')) {
                setCustomPlacement('main_region', null);
                setPlacementOptions(
                    'main_region',
                    state.rawOptions['main_region'].filter(
                        ({ country_id }) =>
                            country_id === state.customPlacements.country.value
                    )
                );
            }

            if (customPlacements.includes('city')) {

                setCustomPlacement('city', null);
                setPlacementOptions(
                    'city',
                    state.rawOptions['city'].filter(
                        ({ country_id }) => (
                            state.customPlacements.country.value === country_id
                        )
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements.country]);

    useEffect(() => {

        if (state.customPlacements.main_region) {
            const customPlacements = Object.keys(state.customPlacements);

            if (customPlacements.includes('city')) {
                setCustomPlacement('city', null);
                setPlacementOptions(
                    'city',
                    state.rawOptions['city'].filter(
                        ({ main_region_id }) =>
                            state.customPlacements.main_region.value ===
                            main_region_id
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements.main_region]);

    const handleChangeSelected = (field) => (value) => {
        setState((prev) => ({
            ...prev,
            customPlacements: {
                ...prev.customPlacements,
                [field]: value
            }
        }))

    };
    useEffect(() => {

        const placementCriteria = Object.keys(state.customPlacements || {});
        placementCriteria.forEach((criteria) => {
            if (!state.placementOptions[criteria]) {
                fetchPlacementOptions(criteria);
            }
        });
        if (
            (placementCriteria.includes('main_region') ||
                placementCriteria.includes('city')) &&
            !placementCriteria.includes('country')
        ) {
            fetchPlacementOptions('country');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customPlacements]);

    useEffect(() => {
        const drawerFilters = {};
        const y = ["types", "timing"]

        //eslint-disable-next-line array-callback-return
        Object.keys(filters).map((key) => {
            if (Object.keys(state).includes(key)) {
                drawerFilters[key] = filters[key];
            }
        });

        if (!filters.directory && drawerFilters.metaTypes) {
            drawerFilters.metaTypes = drawerFilters.metaTypes.filter((x) => x.isLocation);
        }
        if (drawerFilters.pageTypes) {
            if (drawerFilters.pageTypes.includes("world_region")) {
                drawerFilters.disabledList = disList["world_region"];
            } else if (drawerFilters.pageTypes.includes("main_region") && drawerFilters.pageTypes.includes("country")) {
                drawerFilters.disabledList = disList["main_region"];
            } else if (drawerFilters.pageTypes.includes("main_region")) {
                drawerFilters.disabledList = [];
                drawerFilters.pageTypes = drawerFilters.pageTypes.filter((x) => y.includes(x));
            } else if (drawerFilters.pageTypes.includes("city") && drawerFilters.pageTypes.includes("country")) {
                drawerFilters.disabledList = disList["city"];
            } else if (drawerFilters.pageTypes.includes("city")) {
                drawerFilters.disabledList = [];
                drawerFilters.pageTypes = drawerFilters.pageTypes.filter((x) => y.includes(x));
            } else if (drawerFilters.pageTypes.includes("country")) {
                drawerFilters.disabledList = disList["country"];
            } else {
                drawerFilters.disabledList = [];
            }
        }
        setState(drawerFilters);
        // eslint-disable-next-line
    }, [filters]);


    useEffect(() => {
        let locations = ['world_region', 'country', 'main_region', 'city'];
        if (metaTypes.length === 0) {
            locations.forEach((location) => {
                let loc = metaPageTypes.find((meta) => meta.value === location);

                setCustomMetaState((prev) => ({
                    ...prev,
                    metaTypes: [...prev.metaTypes, loc]
                }))
            });

        }
        // eslint-disable-next-line
    }, []);

    return (
        <Drawer
            open={dataFilterOpen}
            onClose={() => setCustomMetaState({ dataFilterOpen: false })}
            anchor="right"
        >
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Search Filters</Typography>
                    <br />
                    <div>
                        <Typography variant="overline">Directory</Typography>
                        <SearchBasedDropdown
                            arrayOptions={directories}
                            labelKey="name"
                            valueKey="id"
                            value={state.directory}
                            placeholder="Select Directory"
                            onChange={handleFieldChange('directory')}
                            isClearable
                        />
                        <br />
                        <Typography variant="overline">Page Type</Typography>
                        <br />
                        {metaTypes.map(({ value, label, isLocation, id }) => {
                            const locID = isLocation ? value : id;
                            return (
                                <><FormControlLabel
                                    control={<Checkbox
                                        checked={state.pageTypes.includes(
                                            value
                                        )}
                                        onChange={handlePageTypeChange(isLocation, value)}
                                        disabled={handleDisable(isLocation, value)}
                                        name={value}
                                        color="primary" />}

                                    label={label}
                                    className={classes.checkbox} />
                                    {state.pageTypes.includes(value) &&

                                        < SearchBasedDropdown
                                            arrayOptions={placementOptions[locID] === 'fetching' ? [] : placementOptions[locID]}
                                            labelKey="label"
                                            valueKey="value"
                                            value={customPlacements[locID]}
                                            isDisabled={handleDisable(isLocation, value)}
                                            onChange={handleChangeSelected(locID)}
                                            placeholder={placementOptions[locID] === 'fetching' ? 'Loading ...' : "Select " + label}
                                            isClearable />
                                    }
                                </>
                            )
                        })}
                        <Divider />
                        <br />
                        <Typography variant="overline">Online</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.online}
                                    onChange={handleOnlineChange}
                                    name="online"
                                    color="primary"
                                    disabled={onlineDisable}
                                />
                            }
                            label={'Include Online'}
                            className={classes.checkbox}
                        />
                    </div>
                </Paper>
                <div className={classes.cta}>
                    <Button
                        data-cy="default-meta-clear-data-filters"
                        fullWidth
                        color="secondary"
                        // variant="contained"
                        disableElevation
                        disableFocusRipple
                        startIcon={<CloseIcon fontSize="small" />}
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        data-cy="default-meta-apply-data-filters"
                        variant="contained"
                        fullWidth
                        color="primary"
                        disableFocusRipple
                        disableElevation
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={handleApplyFilters}
                    >
                        Apply & Search
                    </Button>
                </div>
            </div >
        </Drawer >
    );
}
