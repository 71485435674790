import React, { useEffect } from 'react';
import {
    TextField,
    Drawer,
    makeStyles,
    Paper,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SaveIcon from '@material-ui/icons/Save';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useGlobalState, useAuthenticatedRequest, useGlobalMethods } from '../Client/GlobalProvider';
import { green, red } from '@material-ui/core/colors';
import SearchBasedDropdown from '../../components/SearchBasedDropdown/SearchBasedDropdown';
import { DIRECTORIES } from '../../utilities/directory';
import {
    useObjectState,
    useDidUpdateEffect
} from '../../utilities/customHooks';
import { isEmail, isURL } from 'validator';
import {
    getCriteriaByDirectory,
    fetchCriteriaOptions
} from '../../utilities/searchCriteria';
import DatePicker from 'react-datepicker';
import {
    useScholarshipState,
    useScholarshipMethods
} from './ScholarshipProvider';
import MultiSelect from '@khanacademy/react-multi-select';
import DialogBox from '../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36
            }
        },
        '& .dropdown-content': {
            zIndex: '2 !important'
        },
        '& .search-based__menu': {
            zIndex: 2
        }
    },
    paper: {
        padding: theme.spacing(2)
    },
    counter: {
        alignSelf: 'end'
    },
    cta: {
        marginTop: 'auto'
    },
    datePicker: {
        display: 'flex'
    }
}));

const AddEditScholarshipDrawer = (props) => {
    const classes = useStyles();
    const {
        locations,
        fetchingLocations,
        countries,
        fetchingCountries,
        scholarshipDrawerOpen,
        handleStateChange: setGlobalState
    } = useGlobalState();
    const { sendRequest } = useAuthenticatedRequest();
    const { fetchCountries } = useGlobalMethods();
    const { edit, selectedScholarship, setScholarshipState, saving } =
        useScholarshipState();
    const [state, setState] = useObjectState({
        directory: {
            label: 'GoAbroad',
            value: 10
        },
        title: '',
        description: '',
        link: '',
        location: null,
        isWorldwide: false,
        type: null,
        types: [],
        isAllTypes: false,
        contactEmail: '',
        deadline: null,
        yearRound: false,
        featuredOnDirectory: false,
        featuredOnHomepage: null,
        fetchingTypes: false,
        selectedDirectories: [10],
        isSaving: false,
        locationOptions: []
    });
    const [typesOptions, setTypeOptions] = useObjectState({});
    const [typePromise, setTypePromise] = useObjectState({});

    const { handleAddScholarship } = useScholarshipMethods();

    const directories = [...DIRECTORIES]
        .filter(
            (directory) =>
                !!directory.page_number_tag || directory.abbrv === 'GA'
        )
        .map((l) => {
            return {
                value: l.id,
                label: l.name
            };
        });

    const handleFieldChange = (field) => (value) =>
        setState({ [field]: value });

    const cancelFetchingTypes = (type) => {
        if (typePromise[type]) typePromise[type].cancel();
        setTypePromise({ [type]: null });
    };

    const setFetchTypePromise = (type) => {
        if (!!typePromise[type]) return;

        const cancelablePromise = () => {
            let cancel = () => {};
            const promise = new Promise(async (resolve, reject) => {
                cancel = reject;
                const criteria_options = (
                    (await fetchCriteriaOptions(type)) || []
                ).map(({ value, label }) => ({
                    id: `${type}=${value}`,
                    name: label,
                    value: `${type}=${value}`,
                    label
                }));
                setTypeOptions({
                    [type]: criteria_options
                });
                setTypePromise({ [type]: null });
            });
            promise.cancel = cancel;
            return promise;
        };
        setTypePromise({ [type]: cancelablePromise() });
    };

    const handleClearFields = () => {
        setState({
            title: '',
            description: '',
            link: '',
            selectedDirectories: [10],
            location: null,
            isWorldwide: false,
            type: null,
            types: [],
            isAllTypes: false,
            contactEmail: '',
            deadline: null,
            yearRound: false,
            featuredOnDirectory: false,
            featuredOnHomepage: null
        });
    };

    useDidUpdateEffect(() => {
        const types = getCriteriaByDirectory({
            key: 'id',
            val: state.selectedDirectories
        });
        const criteria = [
            ...new Set(
                Object.keys(types || {})
                    .map((selectedDirectories) => {
                        const { new_criteria } =
                            types[selectedDirectories].type || {};
                        return new_criteria;
                    })
                    .filter((c) => !!c)
            )
        ];

        //eslint-disable-next-line array-callback-return
        criteria.map((criterion) => {
            if ((typesOptions[criterion] || []).length === 0)
                setFetchTypePromise(criterion);
        });

        //eslint-disable-next-line array-callback-return
        Object.keys(typePromise).map((key) => {
            if (!criteria.includes(key) && !!typePromise[key]) {
                cancelFetchingTypes(key);
            }
        });
    }, [state.selectedDirectories]);

    useEffect(() => {
        const hasActive = Object.keys(typePromise).some(
            (key) => !!typePromise[key]
        );
        setState({ fetchingTypes: hasActive });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typePromise]);

    useDidUpdateEffect(() => {
        if (state.selectedDirectories.length && !state.fetchingTypes) {
            let options = [];
            const types = getCriteriaByDirectory({
                key: 'id',
                val: state.selectedDirectories
            });
            [
                ...new Set(
                    Object.keys(types || {})
                        //eslint-disable-next-line array-callback-return
                        .map((selectedDirectories) => {
                            const { new_criteria } =
                                types[selectedDirectories].type || {};
                            return new_criteria;
                        })
                        .filter((c) => !!c)
                )
                //eslint-disable-next-line array-callback-return
            ].map((criterion) => {
                options = [...options, ...(typesOptions[criterion] || [])];
            });
            setState({ types: options });

            if (edit && !(state.type || []).length)
                setState({ type: getTypes() });
        }
    }, [state.selectedDirectories, state.fetchingTypes]);

    useDidUpdateEffect(() => {
        const typesValue = state.types.map((type) => type.value);
        const filteredType = [...(state.type || [])].filter((type) => {
            return typesValue.includes(type.value);
        });
        setState({ type: filteredType });
        if (
            !!state.type &&
            ![0].includes(state.type.length, state.types.length)
        ) {
            setState({
                isAllTypes: state.type.length === state.types.length
            });
        }
    }, [state.types]);

    useDidUpdateEffect(() => {
        if (state.isWorldwide && !fetchingCountries) {
            let worldwide = countries.map((country) => {
                return { value: `2_${country.id}`, label: country.name };
            });
            setState({ location: worldwide });
        }
    }, [state.isWorldwide, fetchingCountries]);

    useDidUpdateEffect(() => {
        if (state.isAllTypes) {
            let allTypes = types.map((type) => {
                return {
                    value: type.id,
                    label: type.name
                };
            });
            setState({ type: allTypes });
        }
    }, [state.isAllTypes]);

    useEffect(() => {
        if (scholarshipDrawerOpen && !locations.length && !fetchingLocations)  {
            setState({ fetchingLocations: true });
            fetchCountries();
            fetchLocations()
                .then((locations) => setState({ locations }))
                .finally(() => setState({ fetchingLocations: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scholarshipDrawerOpen]);

    const fetchLocations = async () =>
        await sendRequest(
            '/locations?location_type=country,main_region,city',
            ({ locations }) => locations || []
        ); 

    useEffect(() => {
        setState({
            locationOptions: [...(state.locations || [])].sort((a, b) => {
                const [criteriaA] = a.id.split('_');
                const [criteriaB] = b.id.split('_');
                if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
                if (parseInt(criteriaA) < parseInt(criteriaB)) return -1;
                if (a.name > b.name) return 1;
                if (b.name < a.name) return -1;
                return 0;
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.locations]);


    const getLocations = () => {
        let countries,
            cities,
            main_regions = {};
        if (!!selectedScholarship.countries) {
            countries = selectedScholarship.countries.map((country) => {
                return {
                    value: `2_${country.id}`,
                    label: country.name
                };
            });
        }
        if (!!selectedScholarship.cities) {
            cities = selectedScholarship.cities.map((city) => {
                return {
                    value: `16_${city.id}`,
                    label: city.name
                };
            });
        }
        if (!!selectedScholarship.main_regions) {
            main_regions = selectedScholarship.main_regions.map((region) => {
                return {
                    value: `8_${region.id}`,
                    label: region.name
                };
            });
        }
        return countries.concat(cities, main_regions);
    };

    const getTypes = () => {
        let adventure_types,
            degrees,
            gapyear_types,
            highschool_types,
            intern_types,
            languages,
            volunteer_types = {};

        if (!!selectedScholarship.adventure_types) {
            adventure_types = selectedScholarship.adventure_types.map(
                (adventure_type) => {
                    return {
                        value: `adventure_travel_type=${adventure_type.id}`,
                        label: adventure_type.name
                    };
                }
            );
        }
        if (!!selectedScholarship.degrees) {
            degrees = selectedScholarship.degrees.map((degree) => {
                return {
                    value: `degree_program=${degree.id}`,
                    label: degree.name
                };
            });
        }
        if (!!selectedScholarship.gapyear_types) {
            gapyear_types = selectedScholarship.gapyear_types.map(
                (gapyear_type) => {
                    return {
                        value: `gap_year_type=${gapyear_type.id}`,
                        label: gapyear_type.name
                    };
                }
            );
        }
        if (!!selectedScholarship.highschool_types) {
            highschool_types = selectedScholarship.highschool_types.map(
                (highschool_type) => {
                    return {
                        value: `highschool_type=${highschool_type.id}`,
                        label: highschool_type.name
                    };
                }
            );
        }
        if (!!selectedScholarship.intern_types) {
            intern_types = selectedScholarship.intern_types.map(
                (intern_type) => {
                    return {
                        value: `intern_type=${intern_type.id}`,
                        label: intern_type.name
                    };
                }
            );
        }
        if (!!selectedScholarship.languages) {
            languages = selectedScholarship.languages.map((language) => {
                return {
                    value: `language=${language.id}`,
                    label: language.name
                };
            });
        }
        if (!!selectedScholarship.languages) {
            languages = selectedScholarship.languages.map((language) => {
                return {
                    value: `language=${language.id}`,
                    label: language.name
                };
            });
        }
        if (!!selectedScholarship.volunteer_types) {
            volunteer_types = selectedScholarship.volunteer_types.map(
                (volunteer_type) => {
                    return {
                        value: `volunteer_type=${volunteer_type.id}`,
                        label: volunteer_type.name
                    };
                }
            );
        }
        return adventure_types.concat(
            degrees,
            gapyear_types,
            highschool_types,
            intern_types,
            languages,
            volunteer_types
        );
    };

    useEffect(() => {
        if (edit && !!selectedScholarship) {
            let location = getLocations();
            setState({
                title: selectedScholarship.award_name,
                description: selectedScholarship.description.substring(0, 550),
                link: selectedScholarship.url,
                selectedDirectories: selectedScholarship.directories.map(
                    (directory) => directory.id
                ),
                location: location,
                isWorldwide: location.length === 239,
                contactEmail: selectedScholarship.contact_email,
                deadline:
                    selectedScholarship.deadline_date !== 'Offered Year Round'
                        ? selectedScholarship.deadline_date
                        : null,
                yearRound:
                    selectedScholarship.deadline_date === 'Offered Year Round',
                featuredOnDirectory:
                    selectedScholarship.is_featured_in_directory,
                featuredOnHomepage: selectedScholarship.directories.some(
                    (e) => e.id === 10
                )
                    ? selectedScholarship.is_featured_in_homepage
                    : null
            });
        } else if (!selectedScholarship) {
            setState({
                title: '',
                description: '',
                link: '',
                selectedDirectories: [10],
                location: null,
                isWorldwide: false,
                type: null,
                types: [],
                contactEmail: '',
                deadline: null,
                yearRound: false,
                featuredOnDirectory: false,
                featuredOnHomepage: null
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit, selectedScholarship]);

    const {
        title,
        location,
        isWorldwide,
        type,
        types,
        isAllTypes,
        description,
        link,
        deadline,
        yearRound,
        contactEmail,
        selectedDirectories,
        featuredOnDirectory,
        featuredOnHomepage,
        fetchingTypes,
        locationOptions,
    } = state;

    const isGoAbroadOnly =
        selectedDirectories.length === 1 && selectedDirectories[0] === 10;

    return (
        <Drawer
            open={scholarshipDrawerOpen || edit}
            onClose={() =>
                setGlobalState({ scholarshipDrawerOpen: false }, () => {
                    setScholarshipState({
                        edit: false,
                        selectedScholarship: null
                    });
                    handleClearFields();
                })
            }
            anchor="right"
        >
            <div className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                    <Typography variant="h6">
                        {edit ? 'Edit' : 'Add'} Scholarship
                    </Typography>
                    <br />
                    <Typography variant="overline">
                        Scholarship Title
                    </Typography>
                    <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        size="small"
                        onChange={({ target: { value }, ...e }) => {
                            handleFieldChange('title')(value.substring(0, 80));
                        }}
                        value={title}
                        InputProps={{
                            endAdornment: (
                                <Typography
                                    variant="caption"
                                    className={classes.counter}
                                >
                                    {title.length}/80
                                </Typography>
                            )
                        }}
                    />
                    <br />
                    <br />
                    <Typography variant="overline">Description</Typography>
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={({ target: { value }, ...e }) => {
                            handleFieldChange('description')(
                                value.substring(0, 550)
                            );
                        }}
                        InputProps={{
                            endAdornment: (
                                <Typography
                                    variant="caption"
                                    className={classes.counter}
                                >
                                    {description.length}/550
                                </Typography>
                            )
                        }}
                    />
                    <br />
                    <br />
                    <Typography variant="overline">Scholarship Link</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        onChange={({ target: { value } }) =>
                            handleFieldChange('link')(value)
                        }
                        value={link}
                        InputProps={{
                            endAdornment: !!link ? (
                                !isURL(link) ? (
                                    <Tooltip
                                        title="Invalid Link"
                                        placement="top"
                                    >
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{
                                                color: red[500],
                                                fontSize: 16
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Valid Link" placement="top">
                                        <CheckCircleIcon
                                            fontSize="small"
                                            style={{
                                                color: green[500],
                                                fontSize: 16
                                            }}
                                        />
                                    </Tooltip>
                                )
                            ) : null
                        }}
                    />
                    <br />
                    <br />
                    <Typography variant="overline">Directory</Typography>
                    <MultiSelect
                        options={directories}
                        selected={selectedDirectories}
                        placeholder="Select Directory"
                        onSelectedChanged={handleFieldChange(
                            'selectedDirectories'
                        )}
                    />
                    <br />
                    <Typography variant="overline">Location</Typography>
                    <SearchBasedDropdown
                        arrayOptions={locationOptions}
                        labelKey="name"
                        valueKey="id"
                        value={location}
                        isMultiSelect={true}
                        isDisabled={
                            fetchingLocations || isWorldwide || isGoAbroadOnly
                        }
                        placeholder={
                            fetchingLocations
                                ? 'Loading ...'
                                : 'Select Location'
                        }
                        onChange={handleFieldChange('location')}
                        isClearable
                        classNamePrefix="search-based"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={({ target: { checked } }) =>
                                    handleFieldChange('isWorldwide')(checked)
                                }
                                checked={isWorldwide}
                                color="primary"
                                disabled={fetchingCountries || isGoAbroadOnly}
                            />
                        }
                        label="Worldwide"
                    />
                    <br />
                    <Typography variant="overline">Type</Typography>
                    <SearchBasedDropdown
                        arrayOptions={types}
                        value={type}
                        isMultiSelect={true}
                        isDisabled={
                            (selectedDirectories || []).length === 0 ||
                            fetchingTypes ||
                            isAllTypes ||
                            isGoAbroadOnly
                        }
                        placeholder={
                            fetchingTypes ? 'Loading ...' : 'Select Type'
                        }
                        onChange={handleFieldChange('type')}
                        isClearable
                        classNamePrefix="search-based"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={({ target: { checked } }) =>
                                    handleFieldChange('isAllTypes')(checked)
                                }
                                checked={isAllTypes}
                                color="primary"
                                disabled={
                                    (selectedDirectories || []).length === 0 ||
                                    fetchingTypes ||
                                    isGoAbroadOnly
                                }
                            />
                        }
                        label="Include All"
                    />
                    <br />
                    <Typography variant="overline">Contact Email</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={({ target: { value } }) =>
                            handleFieldChange('contactEmail')(value)
                        }
                        value={contactEmail}
                        InputProps={{
                            endAdornment: !!contactEmail ? (
                                !isEmail(contactEmail) ? (
                                    <Tooltip
                                        title="Invalid Email"
                                        placement="top"
                                    >
                                        <ErrorIcon
                                            fontSize="small"
                                            style={{
                                                color: red[500],
                                                fontSize: 16
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title="Valid Email"
                                        placement="top"
                                    >
                                        <CheckCircleIcon
                                            fontSize="small"
                                            style={{
                                                color: green[500],
                                                fontSize: 16
                                            }}
                                        />
                                    </Tooltip>
                                )
                            ) : null
                        }}
                    />
                    <br />
                    <br />
                    <Typography variant="overline">Deadline</Typography>
                    <DatePicker
                        selected={deadline ? new Date(deadline) : null}
                        showMonthDropdown
                        showYearDropdown
                        className={classes.datePicker}
                        minDate={new Date()}
                        onChange={handleFieldChange('deadline')}
                        value={deadline}
                        disabled={yearRound}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={({ target: { checked } }) =>
                                    handleFieldChange('yearRound')(checked)
                                }
                                checked={yearRound}
                                color="primary"
                                disabled={!!deadline}
                            />
                        }
                        label="Offered Year Round"
                    />
                    <br />
                    <br />
                    <Tooltip
                        title="Ranks this Scholarship at the top of the selected Directory Scholarship Homepage"
                        placement="top"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={({ target: { checked } }) =>
                                        handleFieldChange(
                                            'featuredOnDirectory'
                                        )(checked)
                                    }
                                    checked={featuredOnDirectory}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="primary"
                                    disabled={
                                        selectedDirectories.length > 1 ||
                                        isGoAbroadOnly
                                    }
                                />
                            }
                            label="Feature on Directory"
                        />
                    </Tooltip>
                    <Tooltip
                        title="Ranks this Scholarship at the top of the Scholarship Homepage"
                        placement="top"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={({ target: { checked } }) =>
                                        handleFieldChange('featuredOnHomepage')(
                                            checked
                                        )
                                    }
                                    checked={
                                        selectedDirectories.includes(10) &&
                                        !!featuredOnHomepage
                                    }
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color="primary"
                                    disabled={!selectedDirectories.includes(10)}
                                />
                            }
                            label="Feature on Scholarship Homepage"
                        />
                    </Tooltip>
                    <br />
                </Paper>
                <br />
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    disableFocusRipple
                    disableElevation
                    className={classes.cta}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                        handleClearFields();
                        handleAddScholarship(state, edit ? 'edit' : 'add');
                    }}
                >
                    {edit ? 'Save' : 'Add'}
                </Button>
            </div>
            <DialogBox open={saving} title={'Saving Changes...'} actions="">
                <LinearProgress />
            </DialogBox>
        </Drawer>
    );
};

export default AddEditScholarshipDrawer;
