import { Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect, lazy } from 'react';
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';

const ResultsMain = lazy(() => import('./ResultsMain'));
const Applications = lazy(() => import('./Applications/Applications'));
const Users = lazy(() => import('./Users/Users'));

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(2)
    }
}));

const dealTabs = [
    { name: 'Deals', path: '/mygoabroad' },
    { name: 'Applications', path: '/mygoabroad/applications' },
    { name: 'Users', path: '/mygoabroad/users' }
];

const DealTabs = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [active, setActive] = useState(0);

    useEffect(() => {
        const activeIndex = dealTabs.findIndex(
            ({ path }) => location.pathname === path
        );
        setActive(activeIndex);
    }, [location.pathname]);

    return (
        <>
            <Tabs
                className={classes.root}
                indicatorColor="secondary"
                textColor="secondary"
                value={active}
            >
                {dealTabs.map(({ name, path }) => (
                    <Tab
                        key={`scholarship-tab-${path}`}
                        data-cy={`${name}-tab`}
                        label={name}
                        disableRipple
                        onClick={() => history.push(path)}
                    />
                ))}
            </Tabs>
            <ResultsMain active={active === 0} />
            <Applications active={active === 1} />
            <Users active={active === 2} />
        </>
    );
};

export default DealTabs;
