import React from 'react';
import ScholarshipProvider from './ScholarshipProvider';
import AddEditScholarshipDrawer from './AddEditScholarshipDrawer';
import ScholarshipTabs from './ScholarshipTabs';
import { SnackbarProvider } from 'notistack';

const Main = (props) => {
    return (
        <SnackbarProvider>
            <ScholarshipProvider>
                <ScholarshipTabs />
                <AddEditScholarshipDrawer />
            </ScholarshipProvider>
        </SnackbarProvider>
    );
};

export default Main;
