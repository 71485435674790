import React from 'react';
import { Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import ProviderUrlVerifier from './ProviderUrlVerifier/ProviderUrlVerifier';
import ProgramUrlVerifier from './ProgramUrlVerifier/ProgramUrlVerifier';
import AdUrlVerifier from './AdUrlVerifier/AdUrlVerifier';
import { useLocation } from 'react-router-dom';
import { findIndex } from 'lodash';
import LinkTab from '../../components/Tabs/LinkTab';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing(2)
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '0.9rem'
    }
}));

const verifierTabs = [
    {
        name: 'Provider URL',
        url: 'url-verifiers/provider-url'
    },
    {
        name: 'Program URL',
        url: 'url-verifiers/program-url'
    },
    {
        name: 'Ads URL',
        url: 'url-verifiers/ads-url'
    }
];

const VerifierTabs = (props) => {
    const classes = useStyles();
    const { pathname } = useLocation();

    const tabSelected = findIndex(verifierTabs, [
        'url',
        pathname.replace(/^\/+|\/+$/g, '')
    ]);

    return (
        <SnackbarProvider>
            <Tabs
                className={classes.root}
                value={tabSelected < 0 ? 0 : tabSelected}
                indicatorColor="secondary"
                textColor="secondary"
            >
                {verifierTabs.map(({ name, url }, index) => (
                    <LinkTab
                        key={`verifier-tab-${index}`}
                        label={name}
                        className={classes.tabRoot}
                        disableRipple
                        to={`/${url}`}
                    />
                ))}
            </Tabs>
            <ProviderUrlVerifier
                active={tabSelected === 0 || tabSelected === -1}
            />
            <ProgramUrlVerifier active={tabSelected === 1} />
            <AdUrlVerifier active={tabSelected === 2} />
        </SnackbarProvider>
    );
};

export default VerifierTabs;
