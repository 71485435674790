import 'react-datepicker/dist/react-datepicker.css';

import React, { Fragment, useState } from 'react';
import DateRangeFilter from './DateRangeFilter';
import FiltersDrawer from './FiltersDrawer';
import {
    Button,
    Chip,
    ClickAwayListener,
    Paper,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import { useGlobalState } from '../Client/GlobalProvider';
import moment from 'moment';

const styles = (theme) => ({
    paper: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(2),
        margin: `${theme.spacing(2)}px 0`,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        position: 'sticky',
        top: 40,
        zIndex: 1,
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& svg': {
            fontSize: 18,
        },
    },
    chipLoader: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    label: {
        padding: 0,
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        display: 'inline',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1),
        margin: '0 8px',
        '& span': {
            width: '146px',
        },
    },
    dates: {
        '& span > div': {
            margin: `0 ${theme.spacing(1)}px`,
        },

        '& span > div:last-child': {
            marginRight: 0,
        },

        '& input': {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    dateWrap: {
        marginLeft: 'auto',
        padding: 0,
        marginBottom: theme.spacing(0.5),
    },
    paperDate: {
        position: 'absolute',
        top: 40,
        right: 0,
        zIndex: 3,
        width: 'auto',
        padding: theme.spacing(3),
    },
    dataFilterWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const DataFilters = ({
    classes,
    filters,
    dateFilters,
    onFiltersChange = () => {},
    onDateFiltersChange = () => {},
}) => {
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const { fetchingProviders } = useGlobalState();

    const handleFilterRemove = (key) => {
        const filtersCopy = JSON.parse(JSON.stringify(filters));
        delete filtersCopy[key];
        onFiltersChange(filtersCopy);
    };

    const filterValues = [];

    Object.keys(filters || {}).forEach((filter) => {
        filters[filter] &&
            filterValues.push({
                filter: filter,
                value:
                    filter === 'listing'
                        ? filters[filter]
                        : filters[filter].label,
            });
    });

    const {
        dates = { start: new Date(), end: new Date() },
        dateRangeID,
        compare,
        comparisonRangeID,
        comparisonDates,
    } = dateFilters || {};

    return (
        <Fragment>
            <Paper className={classes.paper} elevation={1}>
                <div className={classes.dataFilterWrapper}>
                    {filterValues.length ? (
                        <Fragment>
                            <Typography className={classes.label}>
                                Data Filters:{' '}
                            </Typography>
                            <div>
                                {filterValues.map(({ filter, value }) => {
                                    if (
                                        filter === 'client' &&
                                        fetchingProviders
                                    )
                                        return (
                                            <Tooltip
                                                title={'Loading Client ...'}
                                                placement="top"
                                            >
                                                <Chip
                                                    className={
                                                        classes.chipLoader
                                                    }
                                                    onClick={() =>
                                                        setShowFilterDrawer(
                                                            true
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        );
                                    return (
                                        <Tooltip
                                            title={filter
                                                .split('_')
                                                .map(
                                                    (word) =>
                                                        word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        word.slice(1)
                                                )
                                                .join(' ')}
                                            placement="top"
                                        >
                                            <Chip
                                                className={classes.chip}
                                                variant="outlined"
                                                color="primary"
                                                label={value}
                                                onDelete={() =>
                                                    handleFilterRemove(filter)
                                                }
                                                onClick={() =>
                                                    setShowFilterDrawer(true)
                                                }
                                            />
                                        </Tooltip>
                                    );
                                })}
                                <Chip
                                    className={classes.chip}
                                    color="primary"
                                    label="Add"
                                    onClick={() => setShowFilterDrawer(true)}
                                />
                            </div>
                        </Fragment>
                    ) : (
                        <Button
                            onClick={() => setShowFilterDrawer(true)}
                            color="primary"
                            variant="contained"
                        >
                            Add Filters
                        </Button>
                    )}
                    <ClickAwayListener
                        onClickAway={() => setShowDateFilter(false)}
                    >
                        <div className={classes.dateWrap}>
                            <Button
                                onClick={() => setShowDateFilter(true)}
                                className={classes.dates}
                            >
                                <Button
                                    className={classes.datepicker}
                                    variant="outlined"
                                >
                                    {moment(dates.start).format('ll')}
                                </Button>
                                {' - '}
                                <Button
                                    className={classes.datepicker}
                                    variant="outlined"
                                >
                                    {moment(dates.end).format('ll')}
                                </Button>
                            </Button>

                            <DateRangeFilter
                                dates={dates}
                                dateRangeID={dateRangeID}
                                compare={compare}
                                comparisonRangeID={comparisonRangeID}
                                comparisonDates={comparisonDates}
                                open={showDateFilter}
                                onChange={onDateFiltersChange}
                                onClose={() => setShowDateFilter(false)}
                            />
                        </div>
                    </ClickAwayListener>
                </div>
            </Paper>
            <FiltersDrawer
                filters={filters}
                open={showFilterDrawer}
                onClose={() => setShowFilterDrawer(false)}
                onApplyFilters={onFiltersChange}
            />
        </Fragment>
    );
};

export default withStyles(styles)(DataFilters);
