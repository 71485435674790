import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useGlobalState } from '../Client/GlobalProvider';
import { delete_cookie, storeUserCookie } from '../../helpers/cookieUtility';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { postRequest } from '../../helpers/apiRequestUtility';
import { RECAPTCHA_SITE_KEY } from '../../config/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        padding: `0 ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: 390
    },
    actions: {
        padding: `0 ${theme.spacing(4)}px ${theme.spacing(3)}px`
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',
        overflow: 'hidden',
        zIndex: 1000,

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    paper: {
        width: '100%',
        maxWidth: 430,
        padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        position: 'relative'
    },
    form: {},
    input: {
        minWidth: 320,
        background: '#f8f8f8',
        '&:not(:first-child)': {
            marginBottom: theme.spacing.unit * 2
        },

        // Remove chrome autofill background
        '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
            {
                transition: 'background-color 5000s ease-in-out 0s'
            },
        '&.Mui-focused': {
            outlineColor: '#03a9f4'
        }
    },
    button: {
        height: 50,
        background: '#03a9f4',
        color: 'white',

        '&:hover': {
            background: '#0092d3'
        }
    },
    logo: {
        marginBottom: theme.spacing.unit * 4
    },
    h1: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1.5em',
        maxWidth: 320,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333'
    },
    forgot: {
        fontSize: '0.75em',
        marginTop: theme.spacing.unit * 2,
        color: '#555',

        '& a': {
            color: '#03a9f4',
            fontWeight: 'bold',
            textDecoration: 'none'
        }
    },
    email: {
        borderRight: 'none',
        borderRadius: '3px 0 0 3px'
    },
    error: {
        position: 'absolute',
        top: -50,
        background: 'rgba(255,42,42,0.60)',
        color: 'white',
        padding: '8px 16px',
        borderRadius: 3
    },
    showPassword: {
        marginRight: '-7px'
    },
    loginButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 50,
        '& div[role=progressbar]': {
            position: 'absolute'
        }
    },
    progress: {
        color: '#03a9f4'
    },
    message: {
        maxWidth: 320,
        marginBottom: theme.spacing(4)
    },
    logout: {
        minHeight: 50,
        color: grey[500]
    }
}));

const LoginDialog = () => {
    const classes = useStyles();
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const {
        user,
        showLoginDialog,
        loginDialogCallback = () => {},
        handleStateChange: setGlobalState
    } = useGlobalState();
    const passwordRef = useRef(null);
    const [state, setState] = useState({
        email: '',
        password: '',
        verifying: false,
        showPassword: false,
        error: ''
    });

    const handleSetState = (newState, callback = () => {}) => {
        setState((prev) => {
            return { ...prev, ...newState };
        });
        callback();
    };

    const handleInputChange =
        (field) =>
        ({ target: { value = '' } = {} }) =>
            handleSetState({ [field]: value });

    const handleShowPassword = () =>
        handleSetState({ showPassword: !state.showPassword });

    const handleSuccess = (user) => {
        storeUserCookie(user);
        handleSetState({
            verifying: false,
            error: '',
            password: '',
            showPassword: false
        });
        setGlobalState({ showLoginDialog: false }, loginDialogCallback);
    };

    const handleError = async (error) => {
        const { message = '' } = error || {};
        handleSetState({
            verifying: false,
            password: '',
            error: message || 'Authentication Failed. Please try again.'
        });
        passwordRef.current.focus();
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const { email, password } = state;
        handleSetState({ verifying: true, error: '' }, async () => {
            const form = new FormData();
            form.append('email', email);
            form.append('password', password);
            form.append(
                'g-recaptcha-response',
                await executeRecaptcha('login')
            );
            postRequest('/login', form, handleSuccess, handleError);
        });
    };

    const handleLogout = () => {
        setGlobalState({
            providers: [],
            selectedProvider: null,
            scBuffer: {},
            types: [],
            timings: [],
            user: {},
            productItems: [],
            locations: [],
            accountManagers: [],
            directories: [],
            isLoading: false,
            showLoginDialog: false
        });
        delete_cookie('user_info', '/');
        history.push('/login');
    };

    useEffect(() => {
        if (passwordRef.current) passwordRef.current.focus();
    }, [passwordRef, showLoginDialog]);

    useEffect(() => {
        if (!!user) handleSetState({ email: user.email });
    }, [user]);

    const { email, password, verifying, showPassword, error } = state;

    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
            <Dialog open={showLoginDialog} className={classes.dialog}>
                <DialogTitle>Login Required</DialogTitle>
                <form className={classes.form} onSubmit={handleLogin}>
                    <DialogContent className={classes.content}>
                        <Typography className={classes.message}>
                            Looks like you've been logged out. Please login
                            again to continue.
                        </Typography>
                        <FormControl
                            className={classes.input}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Email Address
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type="text"
                                placeholder={'example@domain.com'}
                                color="primary"
                                labelWidth={105}
                                value={email}
                                required
                                disabled
                                error={!!error}
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={classes.showPassword}
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPassword}
                                            edge="end"
                                            disabled={verifying}
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                                value={password}
                                onChange={handleInputChange('password')}
                                required
                                disabled={verifying}
                                error={!!error}
                                inputRef={passwordRef}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.logout}
                                    disabled={verifying}
                                    type="reset"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.loginButtonWrapper}>
                                    {verifying && (
                                        <CircularProgress
                                            className={classes.progress}
                                            size={24}
                                        />
                                    )}
                                    {!verifying && (
                                        <Button
                                            className={classes.button}
                                            fullWidth
                                            variant="contained"
                                            disabled={!password || verifying}
                                            type="submit"
                                        >
                                            {!verifying && 'Login'}
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </GoogleReCaptchaProvider>
    );
};

export default LoginDialog;
