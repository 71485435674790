import {
    green,
    grey,
    lightBlue,
    orange,
    pink,
    purple,
    red
} from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core';

export const adminTheme = createTheme({
    palette: {
        primary: {
            main: lightBlue[500],
            contrastText: '#fff'
        },
        secondary: {
            main: pink[500],
            contrastText: '#fff'
        },
        background: {
            default: '#f2f4f7'
        },
        success: {
            main: green[500],
            contrastText: '#fff'
        }
    },
    overrides: {
        MuiTypography: {
            root: {
                color: grey[800]
            },
            h6: {
                color: grey[800]
            },
            body1: {
                fontSize: '0.875rem'
            },
            body2: {
                color: grey[800]
            },
            overline: {
                color: grey[500],
                fontSize: 11,
                lineHeight: 2.66,
                textTransform: 'uppercase',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            },
            unassigned: {
                background: grey[500]
            },
            pirates: {
                background: '#a8639e'
            },
            duo: {
                background: '#e5748e'
            },
            express: {
                background: '#fff600'
            },
            tycoons: {
                background: '#974044'
            },
            content: {
                background: orange[700]
            },
            colorSuccess: {
                color: green[500]
            },
            colorPending: {
                color: orange[500]
            },
            title: {
                color: grey[700]
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: 'white'
            },
            root: {
                boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)'
            }
        },
        MuiTableRow: {
            root: {
                '-webkit-user-select': 'none',
                '-moz-user-select': 'none',
                '-ms-user-select': 'none',
                cursor: 'pointer',
                '&$hover:hover': {
                    backgroundColor: '#f9f9f9'
                },
                '&$selected': {
                    backgroundColor: '#f9f9f9'
                }
            }
        },
        MuiTableCell: {
            body: {
                fontSize: '0.8215rem'
            },
            head: {
                color: 'rgba(0,0,0,0.54)',
                fontSize: '0.75rem',
                fontWeight: '500',
                lineHeight: 1
            },
            root: {
                lineHeight: 1.3,
                padding: 16
            },
            sizeSmall: {
                padding: '8px 0'
            },
            footer: {
                borderBottom: 0
            }
        },
        MuiTableHead: {
            root: {
                height: 42
            },
            sizeSmall: {
                padding: '8px 0'
            }
        },
        MuiTablePagination: {
            caption: {
                color: 'rgba(0,0,0,0.54)',
                fontSize: '0.75rem',
                fontWeight: '500',
                lineHeight: 1
            },
            input: {
                color: 'rgba(0,0,0,0.54)',
                fontSize: '0.75rem',
                fontWeight: '500',
                lineHeight: 1
            }
        },
        MuiButton: {
            root: {
                letterSpacing: 0
            },
            contained: {
                boxShadow: 'none'
            },
            fab: {
                boxShadow: 'none'
            }
        },
        MuiMenu: {
            paper: {
                boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '0.9rem',
                padding: 8
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiChip: {
            root: {
                fontSize: '0.7rem',
                minHeight: 25,
                textTransform: 'uppercase'
            }
        },
        MuiDrawer: {
            paper: {
                boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.07)'
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.2)'
            }
        },
        MuiCard: {
            root: {
                boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)'
            }
        },
        MuiCardHeader: {
            content: {
                '& > span:last-child': {
                    lineHeight: '1.66'
                }
            },
            avatar: {
                '& > div': {
                    width: 50,
                    height: 50
                }
            }
        },
        MuiCardContent: {
            root: {
                paddingTop: 24,
                paddingBottom: 24
            }
        },
        MuiSvgIcon: {
            colorSuccess: {
                color: green[400]
            },
            colorRed: {
                color: red[200]
            },
            colorOrange: {
                color: orange[500]
            },
            colorPurple: {
                color: purple[200]
            },
            colorLightblue: {
                color: lightBlue[300]
            },
            colorGrey: {
                color: grey[400]
            }
        },
        MuiBadge: {
            badge: {
                zIndex: 0
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)'
            }
        },
        MuiFab: {
            root: {
                boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)'
            }
        }
    }
});
