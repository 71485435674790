import React, { useContext, useEffect } from 'react';
import { UsersContext } from '../../../context';
import { useObjectState } from '../../../utilities/customHooks';
import { useGlobalMethods, useGlobalState } from '../../Client/GlobalProvider';
import {
    constructHttpParams,
    formatRangeParam,
    removeNull,
} from '../../../helpers/dataUtility';

const { Provider } = UsersContext;

const UsersProvider = (props) => {
    const { authenticatedGetRequest: sendRequest } = useGlobalMethods();
    const { user } = useGlobalState();
    const [state, setState] = useObjectState({
        data: [],
        users_stats: [],
        users_appli_stats: [],
        user_redemption_stats: [],
        filters: {},
        sort: 'last_login',
        sortOrder: 'desc',
        rowsPerPage: 10,
        page: 0,
        count: 0,
        selectedUsers: null,
        edit: false,
        fetching: false,
        fetchingStats: false,
        showGlobalFilters: false,
        showColumnFilters: false,
        saving: false,
    });

    const fetchUsersStats = async () => {
        setState({ fetchingStats: true });

        return sendRequest(
            `/my-users/stats`,
            (data) => data,
            () => {},
            fetchUsersStats
        )
            .then((data) => {
                const [mapped_user] = data['my_user_stats'];
                const [mapped_appli] = data['my_user_application_stats'];
                const [mapped_redemption] = data['my_user_redemption_stats'];
                setState({
                    users_stats: mapped_user,
                    users_appli_stats: mapped_appli,
                    users_redemption_stats: mapped_redemption,
                });
            })
            .catch(() => {
                setState({ users_stats: [] });
            })
            .finally(() => setState({ fetchingStats: false }));
    };

    const fetchUsers = async () => {
        setState({ fetching: true });
        const derivedFilters = {
            first_name: state.filters.first_name,
            last_name: state.filters.last_name,
            email: state.filters.email,
            gender: (state.filters.gender || {}).value,
            birth_date: formatRangeParam(state.filters.birth_date),
            last_login: formatRangeParam(state.filters.last_login),
            application_date: formatRangeParam(state.filters.application_date),
            date_created: formatRangeParam(state.filters.date_created),
            citizenship_id: (state.filters.country_name || []).join(','),
            provider_id: state.filters.provider_id,
            sort: `${state.sortOrder === 'asc' ? '+' : '-'}${state.sort}`,
            wildcards: removeNull([
                !!state.filters.first_name ? 'first_name' : null,
                !!state.filters.last_name ? 'last_name' : null,
                !!state.filters.email ? 'email' : null,
            ]).join(','),
        };
        const params = constructHttpParams({
            limit: state.rowsPerPage,
            offset: state.rowsPerPage * state.page,
            ...derivedFilters,
        });

        return sendRequest(
            `/my-users?${params}`,
            (data) => data,
            () => {},
            fetchUsers
        )
            .then(({ users = [], count } = {}) => {
                setState({
                    data: (users || []).map((users) => {
                        users.gender =
                            users.gender === 1
                                ? 'Male'
                                : users.gender === 2
                                ? 'Female'
                                : users.gender === 3
                                ? 'Undisclosed'
                                : '';
                        users.last_login =
                            users.last_login === '0000-00-00 00:00:00'
                                ? ''
                                : users.last_login;
                        return users;
                    }),
                    count: count || users.length || 0,
                });
            })
            .catch(() => setState({ fetching: false }))
            .finally(() => setState({ fetching: false }));
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.page,
        state.rowsPerPage,
        state.sort,
        state.sortOrder,
        state.filters,
    ]);

    useEffect(() => {
        fetchUsersStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <Provider
            value={{
                state,
                setUsersState: setState,
                fetchUsers,
                fetchUsersStats,
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useUsersState = () => {
    const { state, setUsersState } = useContext(UsersContext);
    return { ...state, setUsersState };
};

export const useUsersMethods = () => {
    const { state, ...methods } = useContext(UsersContext);
    return methods;
};

export default UsersProvider;
