import React, { useState, useEffect } from 'react';
import { postRequest } from '../../../../helpers/apiRequestUtility';
import { dateFormatter } from '../../../../utilities/dateFormatter';
import { isValidLink } from '../../../../helpers/dataUtility';
import DialogBox from '../../../../components/DialogBox/DialogBox';
import CancelIcon from '@material-ui/icons/Cancel';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { isEmpty } from 'lodash';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Input,
    Typography,
    withStyles
} from '@material-ui/core';

const styles = (theme) => ({
    bodyContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        flexGrow: '1'
    },
    card: {
        minWidth: 275,
        marginBottom: theme.spacing.unit * 3,
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(2)
    },
    datepicker: {
        color: grey[600],
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        width: 130,
        height: 30
    },
    detail: {
        margin: `${theme.spacing.unit}px 0`
    },
    placement: {
        fontWeight: 'bold',
        color: grey[600]
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: grey[600],
        marginBottom: theme.spacing.unit * 2
    },
    header: {
        margin: `0 ${theme.spacing(1)}px`
    },
    content: {
        padding: `0 ${theme.spacing(2)}px`
    },
    grid: {
        margin: 0,
        padding: 0
    },
    heading: {
        fontWeight: 'bold',
        color: grey[600]
    },
    url: {
        width: '320px',
        color: grey[600]
    },
    editButton: {
        marginTop: '20px'
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'center',

        '& > svg': {
            marginRight: theme.spacing.unit
        }
    },
    adUrl: {
        textDecoration: 'none',
        color: grey[600]
    }
});

const DetailsTab = (props) => {
    const { classes, filterData, filters, closeDrawer } = props;

    const [ad_id, setID] = useState(0);
    const [ad_name, setName] = useState('');
    const [directory, setDirectory] = useState('');
    const [location, setLocation] = useState('');
    const [type, setType] = useState('');
    const [timing, setTiming] = useState('');
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [ad_url, setUrl] = useState('');

    useEffect(() => {
        setID(props.id);
        setName(props.ad_name);
        setDirectory(props.directory);
        setLocation(props.placements.location);
        setType(props.placements.type);
        setTiming(props.placements.timing);
        setStartDate(props.start_date);
        setEndDate(props.end_date);
        setUrl(props.url);

        setSuccess(false);
    }, [props]);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isValidInputs, setIsValidInputs] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const handleStartDateChange = (date) => {
        setStartDate(dateFormatter(date, 'date'));
    };

    const handleEndDateChange = (date) => {
        setEndDate(dateFormatter(date, 'date'));
    };

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    const handleUpdateAd = () => {
        const errors = validateData();

        if (!isEmpty(errors)) {
            setDialogMsg('Error');
            setErrors(errors);
            setOpenDialog(true);
            setIsValidInputs(false);
            setLoading(false);
        } else updateAd();
    };

    const updateAd = () => {
        const form_data = new FormData();

        form_data.append('start_date', start_date);
        form_data.append('end_date', end_date);
        form_data.append('url', ad_url);

        setLoading(true);
        postRequest(
            `/ads/${ad_id}/update`,
            form_data,
            (json) => {
                setOpenDialog(true);
                setDialogMsg('Ad successfully updated!');
                setSuccess(true);
                setErrors({});
                setLoading(false);
            },
            (error) => {
                setOpenDialog(true);
                setDialogMsg('Error');
                setErrors({ error: 'Something went wrong.' });
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (!openDialog && success) {
            closeDrawer();
            filterData(filters, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDialog, success]);

    const validateData = () => {
        const errors = {};
        const isUrlValid = isValidLink(ad_url);
        const isDatesValid =
            new Date(end_date).getTime() >= new Date(start_date).getTime();

        if (!isUrlValid) {
            errors.ad_url = 'Invalid URL';
        }
        if (!isDatesValid) {
            errors.dates = 'Invalid dates';
        }

        return errors;
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogMsg('');
        setIsValidInputs(true);
    };

    return (
        <div className={classes.bodyContent}>
            <DialogBox
                open={openDialog || !isValidInputs}
                title={dialogMsg}
                onClose={handleCloseDialog}
                actions="Ok"
            >
                {!isEmpty(errors) &&
                    Object.values(errors).map((msg) => (
                        <Typography className={classes.errorMessage}>
                            <CancelIcon fontSize="large" color="secondary" />{' '}
                            {msg}
                        </Typography>
                    ))}
            </DialogBox>
            <div className={classes.widgets}>
                <Card className={classes.card}>
                    <CardHeader
                        title={ad_name}
                        subheader="APPEARS ON:"
                        classes={{
                            title: classes.title
                        }}
                        subheaderTypographyProps={{
                            variant: 'overline'
                        }}
                        className={classes.header}
                    />
                    <CardContent className={classes.content}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        Directory
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classes.placement}
                                    >
                                        {directory}
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        Location
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classes.placement}
                                    >
                                        {location}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        Type
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classes.placement}
                                    >
                                        {type}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        Timing
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classes.placement}
                                    >
                                        {timing}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        Ad Placement URL
                                    </Typography>
                                    <div className={classes.detail}>
                                        <Input
                                            disabled={loading}
                                            className={classes.adUrl}
                                            variant="body2"
                                            value={ad_url}
                                            onChange={handleUrlChange}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent className={classes.content}>
                        <div className={classes.detail}>
                            <Typography
                                className={classes.heading}
                                variant="button"
                            >
                                Ad Run Time
                            </Typography>
                        </div>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        Start Date
                                    </Typography>
                                    <DatePicker
                                        disabled={loading}
                                        className={classes.datepicker}
                                        selected={
                                            start_date
                                                ? new Date(start_date)
                                                : new Date()
                                        }
                                        onChange={handleStartDateChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.detail}>
                                    <Typography variant="overline">
                                        End Date
                                    </Typography>
                                    <DatePicker
                                        disabled={loading}
                                        className={classes.datepicker}
                                        selected={
                                            end_date
                                                ? new Date(end_date)
                                                : new Date()
                                        }
                                        onChange={handleEndDateChange}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Button
                    disabled={loading}
                    className={classes.editButton}
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={handleUpdateAd}
                >
                    {loading ? 'Saving...' : 'Edit'}
                </Button>
            </div>
        </div>
    );
};

DetailsTab.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailsTab);
