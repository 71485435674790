import { Paper, makeStyles, Grid, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FilterInput from './FilterInput';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        position: 'absolute',
        marginTop: 10,
        zIndex: 3,
        width: 'max-content'
    },
    input: {
        width: 60
    },
    button: { marginTop: theme.spacing(1) }
}));

export default function ExtendedColumnFilters({
    onChange = () => { },
    currentValue,
    ...props
}) {
    const classes = useStyles();
    const [state, setState] = useState(null);

    const handleChange = (value) => setState(value);

    const handleHandleOk = () => onChange(state);

    useEffect(() => {
        setState(currentValue);
    }, [currentValue]);

    return (
        <div>
            <Paper data-cy="paper-range" className={classes.paper}>
                <FilterInput
                    {...props}
                    currentValue={state}
                    onChange={handleChange}
                />
                <Grid container>
                    <Grid item xs={!!currentValue ? 6 : 0}>
                        {!!currentValue && (
                            <Button
                                fullWidth
                                size="small"
                                className={classes.button}
                                color="secondary"
                                onClick={() => setState(null)}
                            >
                                Clear
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={!!currentValue ? 6 : 12}>
                        <Button
                            data-cy="ok-btn"
                            fullWidth
                            size="small"
                            className={classes.button}
                            color="primary"
                            onClick={handleHandleOk}
                        >
                            Ok
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
