import React, { useState, useEffect, memo } from 'react';
import {
    Button,
    Card,
    Checkbox,
    Drawer,
    FormControl,
    FormLabel as FormlLabel,
    FormControlLabel,
    withStyles,
    Typography
} from '@material-ui/core';

const TABWIDTH = 420;

const styles = (theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: TABWIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    options: {
        padding: theme.spacing(3),
    },
    card: {
        marginBottom: theme.spacing(3),
        overflow: 'scroll',
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    }
});

const ColumnManipulator = ({
    classes,
    open,
    onClose,
    columns: columnsProp,
    onChange = () => { }
}) => {
    const [columns, setColumns] = useState([]);

    const handleChange = (index) => (e) => {
        setColumns(
            columns.map((column, idx) => {
                if (index === idx) column.isShown = e.target.checked;
                return column;
            })
        );
    };

    const handleSaveChanges = () => {
        onChange(columns);
        onClose();
    };

    useEffect(() => {
        setColumns(columnsProp || []);
    }, [columnsProp]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            BackdropProps={{
                invisible: true
            }}
        >
            <div className={classes.root}>
                <Typography className={classes.drawerTitle}>
                    Column Manipulator
                </Typography>
                <Card className={classes.card}>
                    <div className={classes.options}>
                        <FormControl>
                            <FormlLabel className={classes.formLabel}>
                                Select which columns to show
                            </FormlLabel>
                            {columns.map((column, index) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={column.isShown}
                                                onChange={handleChange(index)}
                                            />
                                        }
                                        label={column.label}
                                    />
                                );
                            })}
                        </FormControl>
                    </div>
                </Card>

                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSaveChanges}
                >
                    Save Changes
                </Button>
            </div>
        </Drawer>
    );
};

export default withStyles(styles)(memo(ColumnManipulator));
