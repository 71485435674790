import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export default function RadioFilter({
    radioLabel = '',
    options = [],
    currentValue = '',
    onChange = () => { }
}) {
    const [state, setState] = useState(null);
    const handleChange = (e) => {
        setState(e.target.value);
        onChange(e.target.value);
    };

    useEffect(() => {
        setState(currentValue);
    }, [currentValue]);

    return (
        <FormControl data-cy="radio-btn-column-filter">
            {!!radioLabel && <FormLabel>{radioLabel}</FormLabel>}
            <RadioGroup value={state} onChange={handleChange}>
                {options.map((option) => (
                    <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
