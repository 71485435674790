import React, { useEffect, useState } from 'react';
import {
    Drawer,
    makeStyles,
    Paper,
    Typography,
    Button,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
    useGlobalState,
    useAuthenticatedRequest
} from '../Client/GlobalProvider';
import SearchBasedDropdown from '../../components/SearchBasedDropdown/SearchBasedDropdown';
import { DIRECTORIES } from '../../utilities/directory';
import {
    useObjectState,
    useSnackbarMessage
} from '../../utilities/customHooks';
import {
    getCriteriaByDirectory,
    fetchCriteriaOptions
} from '../../utilities/searchCriteria';
import { useHistory } from 'react-router';
import DialogBox from '../../components/DialogBox/DialogBox';
import {
    booleanParam,
    buildFormData,
    constructHttpParams
} from '../../helpers/dataUtility';
import { BIN_VALUE, search_criteria_fields } from '../../config/constants';
import LoadingDialog from '../../components/Loader/LoadingDialog';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        width: 420,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .react-datepicker-wrapper': {
            display: 'flex',

            '& input': {
                padding: theme.spacing(1),
                border: '1px solid #ccc',
                borderRadius: 3,
                height: 36
            }
        }
    },
    paper: {
        padding: theme.spacing(2)
    },
    counter: {
        alignSelf: 'end'
    },
    cta: {
        marginTop: 'auto'
    },
    datePicker: {
        display: 'flex'
    },
    required: {
        marginLeft: 'auto'
    }
}));

const AddGuideDrawer = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { guidesDrawerOpen, handleStateChange: setGlobalState } =
        useGlobalState();
    const { sendRequest, postRequest } = useAuthenticatedRequest();
    const { enqueueSnackbar } = useSnackbar();
    const { showErrorMessage } = useSnackbarMessage(enqueueSnackbar);
    const [state, setState] = useObjectState({
        directory: null,
        location: null,
        type: null,
        timing: null,
        online: false,
        exactLocation: false,
        directoryOnly: false,
        checkingPlacements: false,
        existingPlacements: false,
        saving: false,
        timingCriteria: null,
        typeCriteria: null,
        locations: [],
        fetchingLocations: false,
        locationOptions: []
    });
    const [showError, setShowError] = useState(false);

    const handleFieldChange = (field) => (value) => {
        if (field === 'online' && value)
            setState({ [field]: value, location: null });
        else setState({ [field]: value });
    };

    const handleResetDrawer = () => {
        setState({
            directory: null,
            location: null,
            type: null,
            timing: null,
            online: false,
            exactLocation: false,
            directoryOnly: false,
            checkingPlacements: false,
            existingPlacements: false,
            timingCriteria: null,
            typeCriteria: null
        });
    };

    const fetchLocations = async () =>
        await sendRequest(
            '/locations?location_type=world_region,country,main_region,city',
            ({ locations }) => locations || []
        );

    const getLocationCriteria = () => {
        const { location, locations } = state;
        const locationCriteria = {};

        if (!location) return locationCriteria;

        const [{ search_criteria = [] } = {}] = locations.filter(({ id }) => {
            return id === location.value;
        });
        search_criteria.forEach(({ criteria_id, criteria_value }) => {
            locationCriteria[search_criteria_fields[criteria_id] || ''] =
                criteria_value;
        });

        return locationCriteria;
    };

    const checkPlacements = async () => {
        const locationCriteria = state.online ? {} : getLocationCriteria();
        const bin_value = [
            'directory_id',
            state.timing ? state.timingCriteria : '',
            state.type ? state.typeCriteria : '',
            state.online ? 'online_tefl' : '',
            ...Object.keys(locationCriteria)
        ]
            .filter((placement) => !!placement)
            .reduce((prev, current) => {
                return prev + (BIN_VALUE[current] || 0);
            }, 0);

        const params = constructHttpParams({
            directory_id: (state.directory || {}).value || null,
            [state.timingCriteria]: (timing || {}).value || null,
            [state.typeCriteria]: (type || {}).value || null,
            bin_value: bin_value,
            ...locationCriteria
        });
        const exists = await sendRequest(
            `/guides?${params}`,
            ({ count }) => count || 0
        ).then((count) => count > 0);

        return exists;
    };

    const handleSaveGuide = () => {
        const data = new FormData();
        const locationCriteria = !online ? getLocationCriteria() : {};

        buildFormData(data, {
            ...locationCriteria,
            directory_id: (state.directory || {}).value || null,
            [state.timingCriteria]: (state.timing || {}).value || null,
            [state.typeCriteria]: (state.type || {}).value || null,
            online: booleanParam(state.online)
        });

        setState({ saving: true });

        postRequest(
            '/guides',
            data,
            (data) => {
                if (data.id) {
                    handleResetDrawer();
                    setGlobalState({ guidesDrawerOpen: false }, () => {
                        history.push(`/guides/edit/${data.id}`);
                    });
                } else {
                    showErrorMessage(
                        'Failed to create guide. Please try again.'
                    );
                }
            },
            () => {
                showErrorMessage('Failed to create guide. Please try again.');
            },
            handleSaveGuide
        ).finally(() => setState({ saving: false }));
    };

    const handleCreateGuide = async () => {
        setState({ saving: true, checkingPlacements: true });

        const exists = await checkPlacements();

        setState({ existingPlacements: exists, checkingPlacements: false });

        if (!exists) handleSaveGuide();
        else {
            setState({ saving: false });
            setShowError(true);
        }
    };

    useEffect(() => {
        const fetchTypes = (directory_id) =>
            new Promise(async (res, rej) => {
                const { [directory_id]: available } = getCriteriaByDirectory({
                    key: 'id',
                    val: [directory_id]
                });
                const criterias = Object.keys(available);

                if (criterias.includes('type') && !!available.type) {
                    const { new_criteria, id } = available.type;
                    setState({ typeCriteria: id });
                    res(await fetchCriteriaOptions(new_criteria));
                }

                res([]);
            });

        const fetchTimings = (directory_id) =>
            new Promise(async (res, rej) => {
                const { [directory_id]: available } = getCriteriaByDirectory({
                    key: 'id',
                    val: [directory_id]
                });
                const criterias = Object.keys(available);

                if (criterias.includes('timing') && !!available.timing) {
                    const { new_criteria, id } = available.timing;
                    setState({ timingCriteria: id });
                    res(await fetchCriteriaOptions(new_criteria));
                }

                res([]);
            });

        if (!!state.directory) {
            setState({
                type: null,
                fetchingTypes: true,
                fetchingTimings: true,
                timingCriteria: null,
                typeCriteria: null
            });
            fetchTypes(state.directory.value).then((data) => {
                const existingType = [...data]
                    .map(({ value }) => value)
                    .includes((state.type || {}).value);
                setState({
                    type: existingType ? state.type : null,
                    types: data || [],
                    fetchingTypes: false
                });
            });
            fetchTimings(state.directory.value).then((data) => {
                const existingTiming = [...data]
                    .map(({ value }) => value)
                    .includes((state.timing || {}).value);
                setState({
                    timing: existingTiming ? state.timing : null,
                    timings: data || [],
                    fetchingTimings: false
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.directory]);

    useEffect(() => {
        if (state.online && state.directory) {
            const [{ has_online } = {}] = [...DIRECTORIES].filter(
                ({ id }) => state.directory.value === id
            );
            if (!has_online) setState({ directory: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.online]);

    useEffect(() => {
        if (
            guidesDrawerOpen &&
            !state.locations.length &&
            !state.fetchingLocations
        ) {
            setState({ fetchingLocations: true });
            fetchLocations()
                .then((locations) => setState({ locations }))
                .finally(() => setState({ fetchingLocations: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidesDrawerOpen]);

    useEffect(() => {
        setState({
            locationOptions: [...(state.locations || [])].sort((a, b) => {
                const [criteriaA] = a.id.split('_');
                const [criteriaB] = b.id.split('_');
                if (parseInt(criteriaA) > parseInt(criteriaB)) return 1;
                if (parseInt(criteriaA) < parseInt(criteriaB)) return -1;
                if (a.name > b.name) return 1;
                if (b.name < a.name) return -1;
                return 0;
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.locations]);

    const {
        location,
        locationOptions,
        type,
        types,
        directory,
        fetchingLocations,
        fetchingTypes,
        fetchingTimings,
        timing,
        timings,
        online,
        directoryOnly
    } = state;

    const directories = [...DIRECTORIES]
        .filter(
            (directory) =>
                !!directory.page_number_tag || directory.abbrv === 'GA'
        )
        .filter(({ has_online }) => (online ? has_online : true));

    const [{ has_online } = {}] = [...DIRECTORIES].filter(({ id }) =>
        !!state.directory ? state.directory.value === id : false
    );

    return (
        <>
            <LoadingDialog
                open={state.checkingPlacements || state.saving}
                message={
                    state.checkingPlacements
                        ? 'Checking placements...'
                        : 'Creating Guide...'
                }
            />
            <Drawer
                open={guidesDrawerOpen}
                onClose={() => setGlobalState({ guidesDrawerOpen: false })}
                anchor="right"
            >
                <div className={classes.root} data-cy='add-guide-drawer'>
                    <Paper elevation={1} className={classes.paper}>
                        <Typography variant="h6">Add Guide</Typography>
                        <br />
                        <Typography
                            variant="overline"
                            style={{ display: 'flex' }}
                        >
                            Directory
                            <Typography
                                variant="overline"
                                color="secondary"
                                align="right"
                                className={classes.required}
                            >
                                <small>Required</small>
                            </Typography>
                        </Typography>
                        <SearchBasedDropdown
                            id="directory-dropdown"
                            arrayOptions={directories}
                            labelKey="name"
                            valueKey="id"
                            value={directory}
                            placeholder="Select Directory"
                            onChange={handleFieldChange('directory')}
                        />
                        <br />
                        <Typography variant="overline">Location</Typography>
                        <SearchBasedDropdown
                            id="location-dropdown"
                            arrayOptions={locationOptions || []}
                            labelKey="name"
                            valueKey="id"
                            value={location}
                            isDisabled={
                                fetchingLocations || directoryOnly || online
                            }
                            placeholder={
                                fetchingLocations
                                    ? 'Loading ...'
                                    : 'Select Location'
                            }
                            onChange={handleFieldChange('location')}
                            isClearable
                        />
                        {!!has_online && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        data-cy="online-checkbox"
                                        checked={online}
                                        onChange={({ target }) =>
                                            handleFieldChange('online')(
                                                target.checked
                                            )
                                        }
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Online"
                            />
                        )}
                        <br />
                        <Typography variant="overline">Timing</Typography>
                        <SearchBasedDropdown
                            id="timing-dropdown"
                            arrayOptions={timings}
                            value={timing}
                            isDisabled={
                                fetchingTimings ||
                                directoryOnly ||
                                !state.timingCriteria
                            }
                            placeholder={
                                fetchingTimings
                                    ? 'Loading ...'
                                    : 'Select Timing'
                            }
                            onChange={handleFieldChange('timing')}
                            isClearable
                        />
                        <br />
                        <Typography variant="overline">Type</Typography>
                        <SearchBasedDropdown
                            id="type-dropdown"
                            arrayOptions={types}
                            value={type}
                            isDisabled={
                                fetchingTypes ||
                                directoryOnly ||
                                !state.typeCriteria
                            }
                            placeholder={
                                fetchingTypes ? 'Loading ...' : 'Select Type'
                            }
                            onChange={handleFieldChange('type')}
                            isClearable
                        />
                    </Paper>
                    <br />
                    <Button
                        data-cy="create-guide-button"
                        variant="contained"
                        fullWidth
                        color="primary"
                        disableFocusRipple
                        disableElevation
                        disabled={!state.directory}
                        className={classes.cta}
                        startIcon={<SaveIcon />}
                        onClick={handleCreateGuide}
                    >
                        Create Guide
                    </Button>
                </div>
                <DialogBox
                    actions="Ok"
                    title={'Cannot create guide'}
                    contentText={`Guide has been created for the selected placement. Please select different placements.`}
                    open={showError}
                    onOk={() => setShowError(false)}
                />
            </Drawer>
        </>
    );
};

export default AddGuideDrawer;
