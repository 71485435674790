import React, { Component, Fragment, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableLoader } from '../../components/Loader/Loader';

const Inquiries = lazy(() => import('./Inquiries/Inquiries'));

const styles = (theme) => ({
    oaiTable: {
        marginTop: '20px',
    },
});

class OnlineAdvisor extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Suspense fallback={<TableLoader />}>
                    <div className={classes.oaiTable}>
                        <Inquiries {...this.props} />
                    </div>
                </Suspense>
            </Fragment>
        );
    }
}

OnlineAdvisor.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OnlineAdvisor);
