/* eslint-disable */
import { CIPHER_KEY, CIPHER_IV } from '../config/constants';
import { aes } from 'node-forge/lib/aes';

const cipher = require('node-forge/lib/cipher');
const util = require('node-forge/lib/util');

/**
 * Encrypt string using AES-CBC cipher algorithm
 *
 * @param  {String} data    The string to be encrypted
 *
 * @return {String}         The encrypted string
 */
export const encrypt = data => {
    let aesCipher = cipher.createCipher('AES-CBC', CIPHER_KEY);
    aesCipher.start({ iv: CIPHER_IV });
    aesCipher.update(util.createBuffer(JSON.stringify(data)));
    aesCipher.finish();

    return aesCipher.output.toHex();
};

/**
 * Decrypt string using AES-CBC cipher algorithm
 *
 * @param  {String} data    The string to be decrypted
 *
 * @return {String}         The decrypted string
 */
export const decrypt = data => {
    let decipher = cipher.createDecipher('AES-CBC', CIPHER_KEY);
    decipher.start({ iv: CIPHER_IV });
    decipher.update(util.createBuffer(util.hexToBytes(data)));
    const result = decipher.finish();
    if (result) {
        return JSON.parse(decipher.output.data);
    }

    return '';
};
