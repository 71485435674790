import React, { memo, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import InputWithCounter from '../InputWithCounter/InputWithCounter';
import grey from '@material-ui/core/colors/grey';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PortraitIcon from '@material-ui/icons/Portrait';
import LandscapeIcon from '@material-ui/icons/Landscape';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import {
    Checkbox,
    FormControlLabel,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import classnames from 'classnames';
import {
    useDidUpdateEffect,
    useObjectState,
} from '../../utilities/customHooks';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircle from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    imageData: {
        maxWidth: '400px',
        minWidth: '250px',
        paddingLeft: '20px',
        marginLeft: '1em',
        display: 'flex',
        flexDirection: 'column',
    },
    imageAlt: {
        '& input, & textarea': {
            color: grey[600],
        },
    },
    alignments: {
        marginLeft: theme.spacing(1),
    },
    active: {
        backgroundColor: lightBlue[500],
        color: '#fff',
        borderColor: lightBlue[500],

        '&:hover': {
            backgroundColor: lightBlue[500],
            color: '#fff',
            borderColor: lightBlue[500],
        },
    },
    actions: {
        display: 'flex',
        marginTop: 'auto',
        justifyContent: 'flex-end',

        '& button:last-child': {
            marginLeft: theme.spacing(2),
        },
    },
    portraitWrap: {
        '&:first-of-type': {
            marginTop: theme.spacing(2),
        },
    },
}));

const ImageData = ({
    alt: imageAlt,
    caption: imageCaption,
    orientation: imageOrientation,
    alignment: imageAlignment,
    mobileWrap,
    isLogo: isLogoImage,
    isValid,
    options: {
        withAlt,
        withCaption,
        withOrientation,
        withAlignment,
        imageAltMin,
        imageAltMax,
        imageCaptionMin,
        imageCaptionMax,
        imageCaptionRequired,
    } = {},
    onChange = () => {},
    onDone = () => {},
    onCancel = () => {},
}) => {
    const classes = useStyles();
    const [orientation, setOrientation] = useState('');
    const [alignment, setAlignment] = useState('');
    const [alt, setAlt] = useState('');
    const [caption, setCaption] = useState('');
    const [isLogo, setIsLogo] = useState(false);
    const [state, setState] = useObjectState({
        orientation: 'landscape',
        alignment: 'center',
        alt: '',
        caption: '',
        mobileWrap: 'full',
        isLogo: false,
    });

    const handleOrientationChange = (value) => () => setOrientation(value);

    const handleAlignmentChange = (value) => () => setAlignment(value);

    const handleWrapChange = (value) => (e) => setState({ mobileWrap: value });

    useDidUpdateEffect(() => {
        onChange({
            alt,
            caption,
            orientation,
            alignment,
            mobileWrap: state.mobileWrap,
            isLogo,
        });
    }, [alt, caption, orientation, alignment, isLogo, state.mobileWrap]);

    useEffect(() => {
        setAlt(imageAlt);
        setCaption(imageCaption);
        setIsLogo(isLogoImage);
        setState({ mobileWrap });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageCaption, imageAlt, isLogoImage, mobileWrap]);

    useEffect(() => {
        if (['portrait', 'landscape'].includes(imageOrientation))
            setOrientation(imageOrientation);
        if (['left', 'right'].includes(imageAlignment))
            setAlignment(imageAlignment);
    }, [imageOrientation, imageAlignment]);

    if (!withAlt && !withCaption && !withOrientation && !withAlignment)
        return null;

    return (
        <div className={classes.imageData}>
            {withAlt && (
                <InputWithCounter
                    label="Image Alt"
                    min={imageAltMin}
                    max={imageAltMax}
                    multiline
                    className={{ input: classes.imageAlt }}
                    onChange={(value) => setAlt(value)}
                    value={alt}
                    data-cy="image_alt"
                    isRequired
                    autoFocus
                />
            )}
            {withCaption && (
                <InputWithCounter
                    label="Caption"
                    min={imageCaptionMin}
                    max={imageCaptionMax}
                    multiline
                    onChange={(value) => setCaption(value)}
                    value={caption}
                    data-cy="image_caption"
                    isRequired={imageCaptionRequired}
                />
            )}
            <div>
                {withOrientation && (
                    <ButtonGroup>
                        <Tooltip title="Portrait">
                            <Button
                                onClick={handleOrientationChange('portrait')}
                                className={classnames({
                                    [classes.active]:
                                        orientation === 'portrait',
                                })}
                            >
                                <PortraitIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Landscape">
                            <Button
                                onClick={handleOrientationChange('landscape')}
                                className={classnames({
                                    [classes.active]:
                                        orientation === 'landscape',
                                })}
                            >
                                <LandscapeIcon />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                )}
                {withOrientation && withAlignment && (
                    <>
                        <ButtonGroup className={classes.alignments}>
                            <Tooltip title="Align Left">
                                <Button
                                    disabled={orientation === 'landscape'}
                                    onClick={handleAlignmentChange('left')}
                                    className={classnames({
                                        [classes.active]:
                                            orientation === 'portrait' &&
                                            alignment === 'left',
                                    })}
                                >
                                    <FormatAlignLeftIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Align Right">
                                <Button
                                    disabled={orientation === 'landscape'}
                                    onClick={handleAlignmentChange('right')}
                                    className={classnames({
                                        [classes.active]:
                                            orientation === 'portrait' &&
                                            alignment === 'right',
                                    })}
                                >
                                    <FormatAlignRightIcon />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                        {orientation === 'portrait' && (
                            <>
                                <div>
                                    <Tooltip
                                        title="Full width on mobile view"
                                        placement="left"
                                    >
                                        <FormControlLabel
                                            className={classes.portraitWrap}
                                            disabled={
                                                orientation === 'landscape'
                                            }
                                            control={
                                                <Checkbox
                                                    disabled={
                                                        orientation ===
                                                        'landscape'
                                                    }
                                                    icon={
                                                        <RadioButtonUnchecked color="primary" />
                                                    }
                                                    checkedIcon={
                                                        <CheckCircle color="primary" />
                                                    }
                                                    checked={
                                                        state.mobileWrap ===
                                                        'full'
                                                    }
                                                    onChange={handleWrapChange(
                                                        'full'
                                                    )}
                                                />
                                            }
                                            label="Full width on mobile"
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title="Wrap on mobile view"
                                        placement="left"
                                    >
                                        <FormControlLabel
                                            className={classes.portraitWrap}
                                            disabled={
                                                orientation === 'landscape'
                                            }
                                            control={
                                                <Checkbox
                                                    disabled={
                                                        orientation ===
                                                        'landscape'
                                                    }
                                                    icon={
                                                        <RadioButtonUnchecked color="primary" />
                                                    }
                                                    checkedIcon={
                                                        <CheckCircle color="primary" />
                                                    }
                                                    checked={
                                                        state.mobileWrap ===
                                                        'wrap'
                                                    }
                                                    onChange={handleWrapChange(
                                                        'wrap'
                                                    )}
                                                />
                                            }
                                            label="Wrap on mobile"
                                        />
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            <div className={classes.actions}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onDone}
                    data-cy="btn_ok_crop"
                    disabled={!isValid}
                    disableElevation
                >
                    Done
                </Button>
            </div>
        </div>
    );
};

export default memo(ImageData);
