import React, { Component, Fragment } from 'react';

import TeamIcon from '../../../../components/Icons/TeamIcon';
import ViewListIcon from '@material-ui/icons/ViewList';
import { withSearchResultsTableContext } from './SearchResultsTableProvider';
import {
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    withStyles
} from '@material-ui/core';
import { GA_URL } from '../../../../config/constants';
import { trimSlash } from '../../../../helpers/dataUtility';

const styles = (theme) => ({
    rowValue: {
        color: '#555'
    },
    tbody: {},
    tr: {
        minHeight: 70,

        '& span': {
            display: 'flex',
            alignItems: 'center'
        },

        '&:nth-child(2)': {
            minWidth: 300
        },
        '&:nth-child(3)': {
            minWidth: 300
        },
        '&:nth-child(4), &:nth-child(5), &:nth-child(6)': {
            background: '#ffecf4'
        },
        '&:nth-child(7), &:nth-child(8), &:nth-child(9)': {
            background: '#ecf6ff'
        },
        '&:nth-child(10), &:nth-child(11), &:nth-child(12)': {
            background: '#f4ffec'
        }
    },
    tableRow: {
        '&:nth-child(odd)': {
            background: '#f5f5f5',

            '& td:nth-child(4), & td:nth-child(5), & td:nth-child(6)': {
                background: '#e7cbd7'
            },
            '& td:nth-child(7), & td:nth-child(8), & td:nth-child(9)': {
                background: '#d3e6f8'
            },
            '& td:nth-child(10), & td:nth-child(11), & td:nth-child(12)': {
                background: '#e3fad3'
            }
        }
    },
    link: {
        textDecoration: 'none',
        fontWeight: 'bold',
        color: '#424242'
    },
    iconWrapLeft: {
        display: 'flex',
        float: 'left',
        marginRight: theme.spacing(1),

        '& > svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5)
        }
    },
    iconWrapRight: {
        display: 'flex',
        float: 'right',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),

        '& > svg': {
            fontSize: 16,
            marginRight: theme.spacing(0.5)
        }
    }
});

class SearchResultsTableRow extends Component {
    formatValue = (formatType, value) => {
        if (formatType === 'comma-format') {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else if (formatType === 'currency') {
            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return value;
        }
    };

    getIcons = () => {
        return [
            {
                name: 'pirates_icon',
                icon: <TeamIcon variant="pirates" />,
                placement: 'right',
                column: 'name'
            },
            {
                name: 'unassigned_icon',
                icon: <TeamIcon variant="unassigned" />,
                placement: 'right',
                column: 'name'
            },
            {
                name: 'duo_icon',
                icon: <TeamIcon variant="duo" />,
                placement: 'right',
                column: 'name'
            },
            {
                name: 'express_icon',
                icon: <TeamIcon variant="express" />,
                placement: 'right',
                column: 'name'
            },
            {
                name: 'tycoons_icon',
                icon: <TeamIcon variant="tycoons" />,
                placement: 'right',
                column: 'name'
            },
            {
                name: 'content_icon',
                icon: <TeamIcon variant="content" />,
                placement: 'right',
                column: 'name'
            }
        ];
    };

    iconsChecker = (row, icons) => {
        icons = icons.filter((icon) => {
            if (icon.name === 'pirates_icon')
                return row.provider.team_id === 32;
            if (icon.name === 'unassigned_icon')
                return row.provider.team_id === 0;
            if (icon.name === 'duo_icon') return row.provider.team_id === 31;
            if (icon.name === 'express_icon')
                return row.provider.team_id === 29;
            if (icon.name === 'tycoons_icon')
                return row.provider.team_id === 30;
            if (icon.name === 'content_icon')
                return row.provider.team_id === 23;
            return true;
        });

        return icons;
    };

    renderColumnIcons = (icons, placement) => {
        const { classes } = this.props;

        if (!icons || icons.length === 0) return null;

        return (
            <div
                className={
                    classes[
                        `iconWrap${placement === 'left' ? 'Left' : 'Right'}`
                    ]
                }
            >
                {icons
                    .filter((icon) => {
                        return icon.placement === placement;
                    })
                    .map(({ icon }, iconKey) => {
                        return (
                            <div key={`icon-${placement}-${iconKey}`}>
                                {icon}
                            </div>
                        );
                    })}
            </div>
        );
    };

    render() {
        const { classes, context, row } = this.props;
        const { toggleAdsBreakdownModal, columns = [] } = context;

        return (
            <Fragment>
                <TableRow
                    className={classes.tableRow}
                    hover={true}
                    onClick={this.handleRowSelect}
                >
                    {columns.map((column) => {
                        if (!column.subcolumns)
                            return (
                                <TableCell
                                    className={classes.tr}
                                    align="left"
                                    key={`row-column-${column.key}`}
                                >
                                    <span>
                                        {column.key === 'client_name' &&
                                            this.renderColumnIcons(
                                                this.iconsChecker(
                                                    row,
                                                    this.getIcons()
                                                ),
                                                'right'
                                            )}
                                        {column.key === 'client_name' ||
                                        column.key === 'program_name' ? (
                                            <a
                                                rel="noreferrer"
                                                className={classes.link}
                                                href={
                                                    column.key === 'client_name'
                                                        ? `/client/profile/${
                                                              row.provider.id
                                                          }`
                                                        : `${trimSlash(
                                                              GA_URL
                                                          )}/providers/${
                                                              row.provider.alias
                                                          }/programs/${
                                                              row.top_program
                                                                  .url_alias
                                                          }-${
                                                              row.top_program.id
                                                          }`
                                                }
                                                target={
                                                    column.key ===
                                                    'program_name'
                                                        ? '_blank'
                                                        : '_self'
                                                }
                                            >
                                                {column.format
                                                    ? this.formatValue(
                                                          column.format,
                                                          row[column.key]
                                                      )
                                                    : row[column.key]}
                                            </a>
                                        ) : (
                                            row[column.key]
                                        )}
                                    </span>
                                </TableCell>
                            );

                        return column.subcolumns.map((subcolumn) => {
                            return (
                                <TableCell
                                    className={classes.tr}
                                    align="left"
                                    key={`row-column-${column.key}-${
                                        subcolumn.key
                                    }`}
                                >
                                    <span>
                                        {subcolumn.format
                                            ? this.formatValue(
                                                  subcolumn.format,
                                                  row[column.key][subcolumn.key]
                                              )
                                            : row[column.key][subcolumn.key]}
                                    </span>
                                </TableCell>
                            );
                        });
                    })}
                    <TableCell>
                        <Tooltip title="View Ads Breakdown" placement="top">
                            <IconButton
                                onClick={toggleAdsBreakdownModal(true, row)}
                            >
                                <ViewListIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }
}

export default withSearchResultsTableContext(
    withStyles(styles)(SearchResultsTableRow)
);
