import React, { useState, useEffect } from 'react';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { withState, withProps } from './StatsTableProvider';
import {
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    Menu,
    MenuItem,
    IconButton,
    TableSortLabel,
    withStyles
} from '@material-ui/core';
import {
    arrayJsonToString,
    arrayStringToJSON
} from '../../../helpers/dataUtility';

const styles = (theme) => ({
    rowLabel: {
        minWidth: '150px'
    },
    arrowButton: {
        padding: 0
    }
});

const usedStates = ['isSelectedAll', 'selected', 'unselected'];
const usedProps = [
    'classes',
    'isLoading',
    'columns',
    'onSortingChange',
    'sortBy',
    'sortingOrder',
    'data',
    'onUnselectedChange',
    'onSelectedChange',
    'onSelectAll',
    'onSelectNone',
    'getSelectedFromCurrentPage',
    'handleEnableExport'
];

const StatsTableHead = ({
    classes,
    data,
    columns = [],
    isLoading,
    selected,
    sortingOrder,
    sortBy,
    handleSetState,
    getSelectedFromCurrentPage,
    getDataFromCurrentPage,
    onSortingChange,
    onSelectAll,
    onSelectNone,
    onSelectedChange,
    handleEnableExport
}) => {
    const hasSubColumns =
        columns.filter((c) => {
            return !!c.subcolumns;
        }).length !== 0;

    const [anchorEl, setAnchor] = useState(null);

    const handleSelectChange = (e) => {
        let dataOnPage = arrayJsonToString(getDataFromCurrentPage());

        let selectedRaw = arrayJsonToString(selected || []);

        if (e.target.checked) {
            dataOnPage = [...dataOnPage].filter(filterDataOnPage(selectedRaw));
            selectedRaw = [...selectedRaw, ...dataOnPage];
        } else {
            selectedRaw = [...selectedRaw].filter(filterSelected(dataOnPage));
        }
        selectedRaw = arrayStringToJSON(selectedRaw);
        !!onSelectedChange && onSelectedChange(selectedRaw);

        !!handleEnableExport &&
            handleEnableExport(selectedRaw.length ? true : false, selectedRaw);
    };

    const handlePageSelectAll = () => {
        handleSetState({ selected: [...data] }, () => {
            onSelectAll();
            onSelectedChange && onSelectedChange(data);
            !!handleEnableExport && handleEnableExport(true, [...data]);
            setAnchor(null);
        });
    };

    const handleSelectNone = () => {
        handleSetState({ selected: [] }, () => {
            onSelectNone();
            onSelectedChange && onSelectedChange([]);
            !!handleEnableExport && handleEnableExport(false, []);
            setAnchor(null);
        });
    };

    const filterDataOnPage = (selected) => (item) => {
        return ![...(selected || [])].includes(item);
    };

    const filterSelected = (dataOnPage) => (item) => {
        return ![...(dataOnPage || [])].includes(item);
    };

    let dataOnPage = getDataFromCurrentPage();
    let selectedDataOnPage = getSelectedFromCurrentPage();

    useEffect(() => {
        handleSetState({ selected: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    rowSpan={hasSubColumns ? 2 : 1}
                    colSpan={isLoading ? 1 : 2}
                    padding="checkbox"
                    className={classes.rowLabel}
                >
                    <Checkbox
                        checked={
                            selectedDataOnPage.length > 0 &&
                            selectedDataOnPage.length === dataOnPage.length
                        }
                        onChange={handleSelectChange}
                        indeterminate={
                            selectedDataOnPage.length > 0 &&
                            selectedDataOnPage.length < dataOnPage.length
                        }
                        disabled={isLoading}
                    />
                    <IconButton
                        className={classes.arrowButton}
                        onClick={(e) => setAnchor(e.target)}
                        disableRipple
                    >
                        <ArrowDownIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchor(null)}
                        elevation={2}
                        transitionDuration={{
                            enter: 100,
                            exit: 100
                        }}
                    >
                        <MenuItem
                            disableGutters
                            dense
                            onClick={handlePageSelectAll}
                        >
                            Select All Results
                        </MenuItem>
                        <MenuItem
                            disableGutters
                            dense
                            onClick={handleSelectNone}
                        >
                            Select None
                        </MenuItem>
                    </Menu>
                </TableCell>
                {columns
                    .filter((column) => {
                        return column.isShown;
                    })
                    .map((column, index) => {
                        return (
                            <TableCell
                                colSpan={
                                    !!column.subcolumns
                                        ? column.subcolumns.length
                                        : 1
                                }
                                align="center"
                                rowSpan={
                                    index > 0 &&
                                    !!columns[index - 1].subcolumns &&
                                    !column.subcolumns
                                        ? 2
                                        : 1
                                }
                            >
                                <TableSortLabel
                                    active={sortBy === column.key}
                                    direction={sortingOrder}
                                    onClick={() => onSortingChange(column.key)}
                                >
                                    {column.label}
                                </TableSortLabel>
                            </TableCell>
                        );
                    })}
            </TableRow>
            {hasSubColumns && (
                <TableRow>
                    {columns
                        .filter((column) => {
                            return column.isShown;
                        })
                        .map((column) => {
                            if (!column.subcolumns) return null;
                            return column.subcolumns.map((subcolumn) => {
                                return (
                                    <TableCell padding="none" align="center">
                                        {subcolumn.label}
                                    </TableCell>
                                );
                            });
                        })}
                </TableRow>
            )}
        </TableHead>
    );
};

export default withState(usedStates)(
    withProps(usedProps)(withStyles(styles)(StatsTableHead))
);
