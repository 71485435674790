import { format } from 'date-fns';
import { clients, directories, locations } from '../config/staticdata';
import { isArray, isObject } from 'lodash';

export const stringifyObject = (object) => {
    return JSON.stringify(object);
};

export const arrayJsonToString = (array) => {
    return array.map((item) => {
        return JSON.stringify(item);
    });
};

export const arrayStringToJSON = (array) => {
    return array.map((item) => {
        return JSON.parse(item);
    });
};

export const arrayDeepCopy = (array) => {
    return JSON.parse(JSON.stringify(array));
};

export const constructHttpParams = (params) =>
    Object.keys(params)
        .filter(
            (key) =>
                (![null, undefined, ''].includes(params[key]) &&
                    !isObject(params[key])) ||
                (isArray(params[key]) && params[key].length > 0) ||
                (!isArray(params[key]) &&
                    isObject(params[key]) &&
                    Object.keys(params[key]).length > 0)
        )
        .map(
            (key) =>
                `${key}=${isArray(params[key])
                    ? params[key]
                        .filter((i) => ![null, undefined, ''].includes(i))
                        .join(',')
                    : !isArray(params[key]) && isObject(params[key])
                        ? Object.keys(params[key])
                            .map((index) => params[key][index])
                            .filter((i) => ![null, undefined, ''].includes(i))
                            .join(',')
                        : params[key]
                }`
        )
        .join('&');

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

export const formatDate = (raw_date) => {
    let date = new Date(raw_date);
    let MONTHS = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    return `${MONTHS[date.getMonth()]
        } ${date.getDate()}, ${date.getFullYear()}`;
};

// MM/DD/YYYY format
export const getMonthDayYearDateFormat = (date) => {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
};

// YYYY-MM-DD format
export const getYearMonthDayDateFormat = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export const isValidLink = (url = '') => {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+:]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return !!pattern.test(url);
};

export const isValidVideoLink = (url = '') => {
    if (!isValidLink(url)) return false;
    const pattern =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return pattern.test(url);
};

export const getYouTubeVideoID = (url) => {
    let regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/; //eslint-disable-line no-useless-escape
    let match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};

export const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

export const explodeUrlParams = (search) => {
    let params = {};
    search
        .replace('?', '')
        .split('&')
        //eslint-disable-next-line array-callback-return
        .map((param) => {
            let property = param.split('=');

            params[property[0]] = isNaN(property[1])
                ? property[1]
                : parseFloat(property[1]);
        });

    return params;
};

export const toValidUrl = (url) => {
    if (
        ![null, undefined, ''].includes(url) &&
        !new RegExp('^(https?)://').test(url)
    ) {
        url = 'http://' + url;
    }

    return url;
};

export const buildFormData = (formData, data, parentKey) => {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data === null ? '' : data;
        if (typeof data === 'string' && data.startsWith('data:image/')) {
            formData.append(parentKey, dataURItoBlob(value));
        } else if (typeof data === 'boolean') {
            formData.append(parentKey, !!value ? 1 : 0);
        } else {
            formData.append(parentKey, value);
        }
    }
};

export const hasValue = (value) => {
    return value !== null && value !== undefined && value !== '';
};

export const convertToKeyValueDict = (arrayObj) => {
    const val = {};
    arrayObj.forEach((ob) => {
        val[ob.key] = ob.direction;
    });
    return val;
};

export const updateOrMerge = (ob1, ob2) => {
    const merged_obj = { ...ob1, ...ob2 };
    const val = Object.entries(merged_obj);
    return val.map((obj) => ({ direction: obj[1], key: obj[0] }));
};

export const appendHttp = (url) => {
    let newUrl = '';

    let re = new RegExp('^(http|https)://', 'i');
    let match = re.test(url);

    newUrl = match ? url : url ? 'http://' + url : '';
    return newUrl;
};

export const validateUrl = (url) => {
    let re =
        /^((https?|ftp|smtp):\/\/)?(www[^\/,<>%$])?((?!www.)[a-z0-9]+)(\.([a-z]){2,}){1,2}(\/)?(\/[\w\-]+\/?)*$/; //eslint-disable-line no-useless-escape
    return re.test(String(url).toLowerCase());
};

export const formatDateRange = (dateRange) => {
    const startdate = new Date(dateRange.startdate);
    const enddate = new Date(dateRange.enddate);

    return `${format(startdate, 'MMM DD YYYY')} - ${format(
        enddate,
        'MMM DD YYYY'
    )}`;
};

export const stringSorter =
    (key, order = 'asc') =>
        (a, b) => {
            if (order === 'asc' && a[key] > b[key]) return 1;
            if (order === 'asc' && a[key] < b[key]) return -1;
            if (order === 'desc' && a[key] > b[key]) return -1;
            if (order === 'desc' && a[key] < b[key]) return 1;
            return 0;
        };

export const sortingFunction = (data, key, order) =>
    data.sort((a, b) => {
        return order === 'desc' ? b[key] - a[key] : a[key] - b[key];
    });

export const getClientPropertyByID = (client_id) => {
    if (client_id) {
        const client = clients.filter((client) => {
            return client.id === parseInt(client_id);
        });

        return client[0];
    }

    return null;
};

export const getDirectoryPropertyByID = (directory_id) => {
    if (directory_id) {
        const directory = directories.filter((directory) => {
            return directory.directoryid === parseInt(directory_id);
        });

        return directory[0];
    }

    return null;
};

export const getLocationPropertyByID = (location_id) => {
    if (location_id) {
        const location = locations.filter((location) => {
            return location.id === parseInt(location_id);
        });

        return location[0];
    }

    return null;
};

export const capitalizeFirstLetter = (string = '') => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const trimSlash = (string = '') =>
    (string || '').replace(/^\/+|\/+$/g, '');

export const booleanParam = (value) =>
    [null, undefined].includes(value) ? null : value ? 1 : 0;

export const filterObject = (object = {}, filter = () => { }) => {
    const filtered = {};
    Object.keys(object)
        .filter((key) => filter({ key, value: object[key] }))
        .map((key) => (filtered[key] = object[key]));
    return filtered;
};

export const getImageDimension = (image) => {
    return new Promise(function (resolved, rejected) {
        var i = new Image();
        i.onload = function () {
            resolved({ w: i.width, h: i.height });
        };
        i.src = image;
    });
};

export const commafy = (x) => {
    if (typeof x === 'number')
        return x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return x.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const isEmptyDate = (date) =>
    ['0000-00-00 00:00:00', '0000-00-00', 'null', '', undefined, null].includes(
        date
    );

export const removeNull = (object) => {
    const clean = {};

    if (Array.isArray(object)) {
        return object.filter((val) => ![null, undefined, ""].includes(val));
    } else {
        Object.keys(object || {}).forEach((key) => {
            if (![null, undefined, ""].includes(object[key])) {
                clean[key] = object[key];
            }
        });
    }
    return clean;
};

export const formatRangeParam = (date) => {
    if (typeof date !== 'object' || !date) return date;
    return [date.start, date.end].filter(v => !!v).join(',');
}

function imageToUri(url, type, callback, fallback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    var base_image = new Image();
    base_image.crossOrigin = "anonymous"
    base_image.src = url;
    base_image.onload = function () {
        canvas.width = base_image.width;
        canvas.height = base_image.height;

        ctx.drawImage(base_image, 0, 0);

        callback(canvas.toDataURL(type || 'image/png'));

        canvas.remove();
    };
    base_image.onerror = fallback;
}

export const imageToDataURI = (url, type = 'image/jpeg') =>
    new Promise((res, rej) => {
        imageToUri(url, type, res, rej);
    });
