import React, { useContext, useEffect } from 'react';
import { DefaultMetaContext } from '../../../../context';
import { useObjectState } from '../../../../utilities/customHooks';
import { DIRECTORIES } from '../../../../utilities/directory';
import { postRequest, sendRequest } from '../../../../helpers/apiRequestUtility';
import { useSnackbar } from 'notistack';
import { ERROR_CODES, LOCATION_CRITERIA, TIMING_CRITERIA, TYPE_CRITERIA, META_PAGE_MAPPER, BIN_VALUE } from '../../../../config/constants';
import { SEARCH_CRITERIA, CRITERIA_PER_DIRECTORY } from '../../../../utilities/searchCriteria';


const { Provider } = DefaultMetaContext;

export default function DefaultMetaProvider(props) {
    const [state, setState] = useObjectState({
        data: [],
        metaTypes: [],
        urlFilter: null,
        filters: {},
        count: 1,
        selected: [],
        selectedMeta: null,
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: () => { },
            onCancel: () => { }
        },
        fetching: true,
        page: 0,
        rowsPerPage: 20
    });
    const { rowsPerPage, page, filters, urlFilter } = state;

    useEffect(() => {
        fetchMeta();
        // eslint-disable-next-line
    }, [rowsPerPage, page, urlFilter]);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchMeta();
        }
        // eslint-disable-next-line
    }, [filters]);

    const { enqueueSnackbar } = useSnackbar();


    //Fetch default meta-seo
    const fetchMeta = async () => {

        setState({ fetching: true });

        let params = "";
        let additionalParams = "";


        //for search filter
        if (filters.directory !== null || filters.pageTypes.length > 0 || filters.online === true) {
            let binValue = 1;
            let isDirectorySet = !!filters?.directory?.value;
            let searchCriteriaObj = Object.values(SEARCH_CRITERIA);

            if (isDirectorySet) {
                var directoryID = filters.directory.value;
                var criteriaDirectory = CRITERIA_PER_DIRECTORY[filters.directory.value];
                additionalParams = additionalParams.concat("&directory_id=", directoryID);
            }

            Object.entries(filters).forEach((data) => {
                if (data[0] === "pageTypes") {
                    data[1].forEach((val) => {
                        if (isDirectorySet) {

                            if (val === "type") {
                                let typeID = criteriaDirectory.type;
                                let filterData = searchCriteriaObj.find((x) => x.id === typeID);
                                binValue += parseInt(filterData.bin_value);

                            }
                            if (val === "timing") {
                                let timingID = criteriaDirectory.timing;
                                let filterData = searchCriteriaObj.find((x) => x.id === timingID);
                                binValue += parseInt(filterData.bin_value);

                            }
                        }
                        if (val === "timing" || val === "type") return;
                        if (filters.online) return;

                        let filterData = searchCriteriaObj.find((x) => x.new_criteria === val)
                        binValue += parseInt(filterData.bin_value);
                    })
                }

                if (data[0] === "online" && data[1] === true) {
                    binValue += 32768;
                }


            });
            if (binValue > 1) {
                additionalParams = additionalParams.concat("&bin_value=", binValue);
            }

        }

        //for URL search filter
        if (urlFilter) {
            let binVal = 0;
            additionalParams += "&";

            urlFilter.forEach(({ criteria, value }, i) => {
                if (criteria === "online") {
                    if (value === 1) {
                        binVal += 32768;
                    }
                    return
                }
                binVal += BIN_VALUE[criteria];

                if (i === urlFilter.length - 1) {
                    if (criteria === "directory_id") {
                        additionalParams += criteria + "=" + value
                    } else {
                        additionalParams += criteria + "=0";
                    }

                } else {
                    if (criteria === "directory_id") {
                        additionalParams += criteria + "=" + value + "&"
                    } else {
                        additionalParams += criteria + "=0&";
                    }

                }

            });
            additionalParams += "&bin_value=" + binVal

        }

        params = `limit=${rowsPerPage}&offset=${page * rowsPerPage}&custom=0${additionalParams}`;
        await sendRequest(`/seo-meta/info?${params}`, (json) => {
            let seoData = [];
            let data = json['seo_meta'];

            data.forEach((metaData) => {
                const {
                    id,
                    h1,
                    h2,
                    title,
                    description,
                    directory_id,
                    program_results_meta_criteria
                } = metaData;

                const crit = [];

                let criterias = program_results_meta_criteria.criteria_value;
                let pageTypeLabel = '';

                criterias.forEach(criteria => {
                    if (TIMING_CRITERIA.includes(criteria.criteria)) {
                        crit.push(META_PAGE_MAPPER[criteria.criteria]);
                    }
                    if (TYPE_CRITERIA.includes(criteria.criteria)) {
                        crit.push(META_PAGE_MAPPER[criteria.criteria]);
                    }
                    if (LOCATION_CRITERIA.includes(criteria.criteria)) {
                        crit.push(META_PAGE_MAPPER[criteria.criteria]);
                    }

                });
                if (crit.length > 0) {
                    pageTypeLabel = crit.join(", ");
                } else {
                    pageTypeLabel = 'Directory';
                }

                const seo = {
                    id,
                    pageType: pageTypeLabel,
                    pageTypeLabel,
                    h1,
                    h2,
                    title,
                    description
                }

                const dir = DIRECTORIES.find(element => element.id === directory_id)
                seo.directory = dir.name;
                seoData.push(seo);
            });

            setState({
                data: seoData,
                count: json['meta'].count,
                fetching: false,
            })

        }).finally(() => {
            setState({
                fetching: false
            })
        });
    };

    //TODO DELETE META
    const deleteMeta = (isBatch, seoMeta) => {
        const { selected, } = state;
        const data = new FormData();
        let selectedSeoId = isBatch
            ? selected.forEach((seo) => seo.id)
            : [seoMeta.id];

        data.append('id', selectedSeoId.join());
        postRequest(
            '/seo-meta/delete',
            data,
            (json) => {
                fetchMeta();
                enqueueSnackbar(isBatch ? 'Seo Meta have been deleted!' : 'Seo Meta has been deleted!', {
                    variant: 'success'
                });
                setState({ selectedMeta: null });

            },
            ({ code } = {}) => {
                if (code !== ERROR_CODES.UNAUTHORIZED)
                    enqueueSnackbar('Failed to delete meta.', {
                        variant: 'error'
                    });
            },
        )
    };

    useEffect(() => {
        fetchMeta();
        // eslint-disable-next-line
    }, []);

    return (
        <Provider
            value={{
                state,
                setDefaultMetaState: setState,
                fetchMeta,
                deleteMeta,
            }}
        >
            {props.children}
        </Provider>
    );
}

export const useDefaultMetaState = () => {
    const { state, setDefaultMetaState } = useContext(DefaultMetaContext);
    return { ...state, setDefaultMetaState };
};

export const useDefaultMetaMethods = () => {
    const { state, ...methods } = useContext(DefaultMetaContext);
    return methods;
};
