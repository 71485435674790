import {
    FormControlLabel,
    Paper,
    Typography,
    Checkbox,
    Divider,
    makeStyles,
    Button,
    CircularProgress
} from '@material-ui/core';
import React from 'react';
import SearchBasedDropdown from '../../../../components/SearchBasedDropdown/SearchBasedDropdown';
import { DIRECTORIES } from '../../../../utilities/directory';
import {
    metaPageTypes,
    BIN_VALUE,
    SCHOLARSHIP_TYPE_CRITERIA,
    SCHOLARSHIP_TIMING_CRITERIA
} from '../../../../config/constants';

import { Alert } from '@material-ui/lab';
import { useSEOToolState } from './SEOToolProvider';
import getCriteriaByDirectory from '../../../../utilities/searchCriteria';
import { sendRequest } from '../../../../helpers/apiRequestUtility';
import { SEARCH_CRITERIA } from '../../../../utilities/searchCriteria';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    form: {},
    checkbox: {
        width: '100%'
    },
    addCustomBtn: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        borderStyle: 'dashed'
    },
    alert: {
        marginTop: theme.spacing(2),
        '& .MuiAlert-message': {
            width: '100%',
            textAlign: 'center'
        }
    }
}));

const directories = [...DIRECTORIES].filter(
    (directory) => !!directory.page_number_tag || directory.abbrv === 'GA'
);

export default function PlacementForm() {
    const classes = useStyles();

    const {
        directory,
        pageTypes,
        online,
        available,
        checking,
        customPlacements,
        setSEOToolState,
        isEdit,
        doneCheck,
        rawOptions
    } = useSEOToolState();

    const disableFields = {
        world_region: ["country", "main_region", "city"],
        country: ["world_region", "main_region", "city"],
        main_region: ["world_region", "city"],
        city: ["world_region", "main_region"]
    }
    const locationCriteria = ["world_region", "country", "main_region", "city"];

    const handleFieldChange = (field) => (value) => {
        if (field === 'directory') {
            setSEOToolState((prev) => ({
                ...prev,
                pageTypes: !value
                    ? []
                    : [...prev.pageTypes].filter(x => locationCriteria.includes(x)),
                customPlacements: !value
                    ? {}
                    : Object.fromEntries(
                        Object.entries(prev.customPlacements).filter(([keyData, value]) =>
                            locationCriteria.includes(keyData)
                        )),
                online: !value ? false : prev.online,
                doneCheck: !value ? false : prev.doneCheck
            }));
        }
        setSEOToolState({ [field]: value, available: false, doneCheck: false });
    };

    const handlePageTypeChange = (pageType) => (e, checked) => {
        if (isEdit) return
        if (pageType === "country" && !checked && pageTypes.includes("city")) {
            updateCheckedItem("country", false);
            updateCheckedItem("city", false);

        }
        if (pageType === "country" && !checked && pageTypes.includes("main_region")) {
            updateCheckedItem("country", false);
            updateCheckedItem("main_region", false);

        }
        if (pageType === "city") {
            let checkedItem = ["city", "country"];
            checkedItem.forEach((selectedItem) => {
                updateCheckedItem(selectedItem, checked);

            })

        } else if (pageType === "main_region") {
            let checkedItem = ["main_region", "country"];
            checkedItem.forEach((selectedItem) => {
                updateCheckedItem(selectedItem, checked);

            })
        } else if (pageType === "country") {
            updateCheckedItem("country", checked);
        } else if (pageType === "world_region") {
            updateCheckedItem("world_region", checked);
        }
        if (pageType === "type") {
            updateCheckedItem("type", checked);
        }
        if (pageType === "timing") {
            updateCheckedItem("timing", checked);
        }


    };
    const updateCheckedItem = (selectedItem, checked) => {
        setSEOToolState((prev) => {
            const newPageTypes = checked
                ? [...prev.pageTypes, selectedItem]
                : [...prev.pageTypes].filter((p) => p !== selectedItem);
            const { [selectedItem]: removed, ...customPlacements } =
                prev.customPlacements;

            return {
                ...prev,
                available: false,
                pageTypes: newPageTypes,
                isCustom: newPageTypes.length ? prev.isCustom : false,
                customPlacements: checked
                    ? prev.customPlacements
                    : customPlacements,
                doneCheck: false
            };
        });
    }
    const handleOnlineChange = (e, checked) => {
        if (!isEdit) {
            setSEOToolState((prev) => ({
                ...prev,
                pageTypes: checked
                    ? [...prev.pageTypes].filter(x => !locationCriteria.includes(x))
                    : [...prev.pageTypes],
                customPlacements: checked
                    ? Object.fromEntries(
                        Object.entries(prev.customPlacements).filter(([keyData, value]) =>
                            !locationCriteria.includes(keyData)
                        ))
                    : prev.customPlacements,
                online: checked,
                available: false,
                doneCheck: false

            }));
        }
    }

    const handleCheckPlacement = async () => {
        setSEOToolState({ checking: true });

        let filter = {
            directory_id: directory.value ?? null,
        }


        if (pageTypes.includes('city')) {

            filter.city_id = customPlacements?.city?.value > 0 ? customPlacements.city.value : 0;

            if (customPlacements?.city?.value > 0 && (customPlacements?.country?.value !== 0 || customPlacements?.country?.value !== undefined)) {
                let cityDetails = rawOptions['city'].filter(
                    ({ value }) => {
                        return value === customPlacements.city.value
                    }
                )
                filter.country_id = cityDetails[0].country_id;

            } else {
                filter.country_id = 0;

            }
        } else if (pageTypes.includes('main_region')) {
            filter.main_region_id = customPlacements?.main_region?.value > 0 ? customPlacements.main_region.value : 0;

            if (customPlacements?.main_region?.value > 0 && (customPlacements?.country?.value !== 0 || customPlacements?.country?.value !== undefined)) {
                let mainRegionDetails = rawOptions['main_region'].filter(
                    ({ value }) => {
                        return value === customPlacements.main_region.value
                    }
                )
                filter.country_id = mainRegionDetails[0].country_id;
            } else {
                filter.country_id = 0;
            }
        } else if (pageTypes.includes('country')) {
            filter.country_id = customPlacements?.country?.value > 0 ? customPlacements.country.value : 0;
        } else if (pageTypes.includes('world_region')) {
            filter.world_region_id = customPlacements?.world_region?.value > 0 ? customPlacements.world_region.value : 0;
        }

        if (pageTypes.includes('type')) {
            let type_id = SCHOLARSHIP_TYPE_CRITERIA[directory.value];
            let searchCriteria = SEARCH_CRITERIA[type_id].new_criteria;

            filter[type_id] = customPlacements[searchCriteria]?.value > 0 ? customPlacements[searchCriteria].value : 0
        }
        if (pageTypes.includes('timing')) {
            let type_id = SCHOLARSHIP_TIMING_CRITERIA[directory.value];
            let searchCriteria = SEARCH_CRITERIA[type_id].new_criteria;

            filter[type_id] = customPlacements[searchCriteria]?.value > 0 ? customPlacements[searchCriteria].value : 0;
        }

        if (online) {
            filter.online_tefl = 1;
        }
        filter.bin_value = computeBinValue(filter);

        const params = (convertFilter(filter));

        await sendRequest(`/seo-meta/info?${params}&placement=1`, (json) => {

            if (json['seo_meta'].length > 0) {
                setSEOToolState({ available: false });
            } else {
                setSEOToolState({ available: true });
            }
            setSEOToolState({ checking: false, doneCheck: true });
        });

    }

    const convertFilter = (filter) => {
        let filters = "";
        Object.entries(filter).forEach((fil, el) => {

            if (el === Object.keys(filter).length - 1) {
                if (fil[0] === "online_tefl") {
                    filters += "online=" + fil[1];
                } else {
                    filters += fil[0] + '=' + fil[1];
                }

            } else {
                if (fil[0] === "online_tefl") {
                    filters += "online=" + fil[1] + "&";
                } else {
                    filters += fil[0] + '=' + fil[1] + "&";
                }
            }

        })
        return filters;
    }
    const computeBinValue = (filter) => {
        let val = 0;

        Object.keys(filter).forEach((data) => {

            val += BIN_VALUE[data];

        });
        return val;
    };
    const directoryCriteria = directory
        ? getCriteriaByDirectory({ key: 'id', val: [directory.value] }, [
            'id',
            'name',
            'new_criteria',
            'bin_value'
        ])[directory.value]
        : {};
    const handleDisable = (isLocation, value) => {
        if (isEdit) return true
        if (pageTypes.includes("city")) {
            return disableFields["city"].includes(value);
        } else if (pageTypes.includes("main_region")) {
            return disableFields["main_region"].includes(value);
        } else if (pageTypes.includes("world_region")) {
            return disableFields["world_region"].includes(value);
        } else if (pageTypes.includes("country") && !pageTypes.includes("city")) {
            return disableFields["country"].includes(value);
        }
        if (!pageTypes.includes(value)) {
            return !directory || (isLocation ? online : false) || isEdit
        }
        return !directory || (isLocation ? online : false)
    }
    const handleOnlineDisabled = (e) => {
        if (isEdit) return true;
        if (directory) {
            const direct = DIRECTORIES.find(x => x.id === directory.value);
            return !!!direct.has_online;
        }
        return true;

    }
    return (
        <Paper className={classes.paper}>
            <div className={classes.form}>
                <Typography variant="overline">Directory</Typography>
                <SearchBasedDropdown
                    isDisabled={isEdit}
                    arrayOptions={directories}
                    labelKey="name"
                    valueKey="id"
                    value={directory}
                    placeholder="Select Directory"
                    handleChange={!isEdit && handleFieldChange('directory')}
                    isClearable
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                online
                            }
                            onChange={handleOnlineChange}
                            name="online"
                            color="primary"
                            disabled={handleOnlineDisabled()}
                        />
                    }
                    label={'Online'}
                    className={classes.checkbox}
                />
                {!!directory && (
                    <>
                        <br />
                        <Typography variant="overline">Page Type</Typography>
                        <br />
                        {metaPageTypes.map(({ value, label, isLocation }) => {
                            const isCriteria =
                                !isLocation && !!directoryCriteria[value];
                            const criteriaLabel = isCriteria
                                ? directoryCriteria[value].name
                                : '';

                            if (!!directory && !isLocation && !isCriteria)
                                return null;

                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={pageTypes.includes(value)}
                                            onChange={
                                                handlePageTypeChange(
                                                    value
                                                )
                                            }
                                            disabled={
                                                handleDisable(isLocation, value)

                                            }
                                            name={value}
                                            color="primary"
                                        />
                                    }
                                    label={criteriaLabel || label}
                                    className={classes.checkbox}
                                />
                            );
                        })}
                        <Divider />
                        <br />
                    </>
                )}

                {!!directory && !available && !doneCheck && !isEdit && (
                    <Button
                        fullWidth
                        color="primary"
                        startIcon={
                            checking ? <CircularProgress size={18} /> : null
                        }
                        disabled={checking}
                        onClick={handleCheckPlacement}
                    >
                        {checking
                            ? 'Checking Placement ...'
                            : 'Check Placement'}
                    </Button>
                )}
                {!!directory && doneCheck && (
                    <Alert
                        severity={available ? 'success' : 'error'}
                        title="Placement Available"
                        icon={false}
                        className={classes.alert}
                        color={available ? 'success' : 'error'}
                    >
                        {available ? 'Placement available' : 'Placement unavailable'}
                    </Alert>
                )}
            </div>
        </Paper>
    );
};
