import React from 'react';
import { useTravelResourceRankingState } from './TravelResourceRankingProvider';
import TableRow from './TableRow';
import { Typography, Divider, Fade } from '@material-ui/core';

const TableBody = () => {
    const { data, filters, temp, reorder } = useTravelResourceRankingState();
    const rowData = [...(reorder ? temp : data)].filter((ad) =>
        reorder
            ? true
            : filters.client_id
            ? ad.client_id === filters.client_id
            : true
    );

    if (!rowData.length)
        return (
            <Typography variant="h6" align="center" color="#DDD">
                No Results
            </Typography>
        );

    return (
        <div>
            {rowData.map((ad, index) => (
                <>
                    <TableRow ad={ad} index={index} />
                    <Fade in={!reorder && index !== rowData.length - 1}>
                        <Divider />
                    </Fade>
                </>
            ))}
        </div>
    );
};

export default TableBody;
