import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import queryString from 'query-string';
import { GA_LOGO } from '../../../../config/constants';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    paper: {
        width: '100%',
        maxWidth: 600,
        minHeight: 600,
        padding: theme.spacing.unit * 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        position: 'relative'
    },
    button: {
        minWidth: 320,
        height: 50,
        background: '#03a9f4',
        color: 'white',
        marginTop: theme.spacing.unit * 2,

        '&:hover': {
            background: '#0092d3'
        }
    },
    logo: {
        marginBottom: theme.spacing.unit * 6
    },
    message: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: 0,
        fontSize: '1.5em',
        maxWidth: 450,
        textAlign: 'center',
        lineHeight: '1.5em',
        color: '#333'
    },
    back: {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        top: theme.spacing.unit * 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none'
        },

        '& svg': {
            fill: 'white',
            fontSize: '1em'
        }
    }
});

// export default
class PaymentSuccess extends Component {
    state = {
        loading: true,
        invoiceId: null
    };

    componentDidMount() {
        const { match, location } = this.props;
        if (match && location) {
            const queryParams = queryString.parse(location.search);
            this.setState({
                loading: false,
                invoiceId: queryParams.invoice_id
            });
        }
    }

    viewInvoice = () => {
        this.props.history.push({
            pathname: `/invoice/view/${this.state.invoiceId}`
        });
    };

    viewDashboard = () => {
        this.props.history.push({
            pathname: '/client-admin/dashboard'
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <CssBaseline />
                <div className={classes.root}>
                    <img
                        className={classes.background}
                        src="https://images.unsplash.com/photo-1516738901171-8eb4fc13bd20?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
                        alt=""
                    />
                    <Paper className={classes.paper}>
                        <img className={classes.logo} src={GA_LOGO} alt="" />
                        <p className={classes.message}>
                            Payment has been successfully received!
                        </p>
                        <Button
                            className={classes.button}
                            variant="contained"
                            onClick={this.viewInvoice}
                        >
                            View Invoice
                        </Button>
                        <Button
                            className={classes.button}
                            variant="contained"
                            onClick={this.viewDashboard}
                        >
                            Account Dashboard
                        </Button>
                    </Paper>
                </div>
            </Fragment>
        );
    }
}

export default withStyles(styles)(PaymentSuccess);
