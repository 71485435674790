import { USER_COOKIE_EXPIRATION } from '../config/constants';
import { encrypt, decrypt } from '../utilities/crypt';
import urlencode from 'urlencode';
import { has } from 'lodash';

export const storeCookie = (key, value, expirationInSeconds) => {
    var d = new Date();
    var exp = expirationInSeconds || null;
    if (exp) {
        d.setTime(d.getTime() + (exp - 3600) * 1000);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = key + '=' + value + ';' + expires + ';path=/';
    } else {
        document.cookie = key + '=' + value + ';path=/';
    }
};

export const getCookie = (key) => {
    var name = key + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

function is_cookie_exist(name) {
    return document.cookie.split(';').some((c) => {
        return c.trim().startsWith(name + '=');
    });
}

export function delete_cookie(name, path, domain) {
    if (is_cookie_exist(name)) {
        document.cookie =
            name +
            '=' +
            (path ? ';path=' + path : '') +
            (domain ? ';domain=' + domain : '') +
            ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
}

export const storeUserCookie = (user) => {
    // store encrypted user_info to cookie
    const u = urlencode.stringify(user);
    storeCookie('user_info', urlencode(encrypt(u)), USER_COOKIE_EXPIRATION);
};

export const getUserCookie = () => {
    let user = null;
    const cookie = getCookie('user_info');
    if (cookie) {
        try {
            const d = decrypt(cookie);
            user = urlencode.parse(urlencode.decode(d));

            if (!has(user, 'id')) return null;

            //eslint-disable-next-line array-callback-return
            Object.entries(user).map(([key, value]) => {
                if (value && !isNaN(value)) {
                    user[key] = parseInt(value);
                }
            });
        } catch (e) {
            delete_cookie('user_info', '/');
        }
    }

    return user;
};
