import React, { useEffect, useState } from 'react';
import GlobalFilterDrawer from '../../../components/Drawer/GlobalFilterDrawer';
import ResultsTable from '../../../components/ResultsTable/ResultsTable';
import { useGlobalMethods, useGlobalState } from '../../Client/GlobalProvider';
import {
    useProgramUrlVerifierState,
    useProgramUrlVerifierMethods
} from './ProgramUrlVerifierProvider';
import {
    convertToKeyValueDict,
    updateOrMerge
} from '../../../helpers/dataUtility';
import DialogBox from '../../../components/DialogBox/DialogBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import TeamIcon from '../../../components/Icons/TeamIcon';
import { makeStyles } from '@material-ui/styles';
import { hasValue, toValidUrl } from '../../../helpers/dataUtility';
import { redirects, broken, verified } from '../../../config/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        '& td:nth-child(2)': {
            width: 300,
            minWidth: 200
        },
        '& td:nth-child(3)': {
            width: 200,
            minWidth: 100
        },
        '& td:nth-child(5) a': {
            width: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            overflow: 'hidden',
            paddingRight: theme.spacing(1)
        }
    }
}));

const response_status = [
    {
        value: 400,
        label: 400
    },
    {
        value: 410,
        label: 410
    },
    {
        value: 404,
        label: 404
    },
    {
        value: 500,
        label: 500
    },
    {
        value: 502,
        label: 502
    },
    {
        value: 503,
        label: 503
    },
    {
        value: 200,
        label: 200
    },
    {
        value: 301,
        label: 301
    },
    {
        value: 302,
        label: 302
    }
];

const link_type = [
    {
        value: 1,
        label: 'Visit WebSite'
    },
    {
        value: 2,
        label: 'Inquire Here'
    }
];

const COLUMN_FILTER_NAMES = [
    'client',
    'program_id',
    'program_name',
    'url',
    'link_type',
    'status',
    'date_verified'
];
const SORTABLE_FIELDS = ['status'];

const GLOBAL_STATUS = ['broken', 'redirects', 'verified'];

const ResultsMain = (props) => {
    const classes = useStyles();
    const {
        data,
        count,
        isFetching,
        page,
        rowsPerPage,
        filters,
        dialog,
        isSaving,
        selected,
        label,
        globalFiltersStatus,
        sort
    } = useProgramUrlVerifierState();
    const {
        setProgramUrlVerifierState,
        fetchProgramUrls,
        addValidBookmarkableFields,
        handleSelectMultiple,
        handleShowActionConfirmation,
        handleUpdateProgramUrls,
        handleCloseDialog
    } = useProgramUrlVerifierMethods();

    const { providers = [] } = useGlobalState();
    const { fetchProviders } = useGlobalMethods();
    const [state, setState] = useState({
        openGlobalFilter: false
    });
    const [selectedStatus, setSelectedStatus] = useState([]);

    const setResulstMainState = (obj) =>
        setState((prev) => ({ ...prev, ...obj }));

    useEffect(() => {
        if (providers.length === 0) fetchProviders();
    }, [providers, fetchProviders]);

    const provider = filters.client
        ? providers.filter((client) => {
            return client.id === filters.client;
        })[0]
        : null;

    const provider_value = provider
        ? { value: provider.id, label: provider.name }
        : null;

    const type = filters.link_type
        ? link_type.filter((type) => {
            return type.value === filters.link_type.value;
        })[0]
        : null;

    const type_value = type ? { value: type.value, label: type.label } : null;

    const status_sort = sort.filter((option) => {
        return option.key === 'status';
    });

    const status_sort_value =
        status_sort.length > 0 ? status_sort[0]['direction'] : 'desc';

    const columns = [
        {
            name: 'Client Name',
            type: 'text',
            format: 'bold',
            key: 'provider_name',
            urlKey: 'client_link',
            urlTarget: '',
            filter: {
                options: providers || {},
                optionValue: 'id',
                optionKey: 'name',
                value: provider_value
                    ? { value: provider_value.id, label: provider_value.label }
                    : null,
                type: 'select',
                onChange: (provider_value) =>
                    handleColumnFiltersChange('client', provider_value.value)
            }
        },
        {
            name: 'Program ID',
            type: 'text',
            key: 'program_id',
            filter: {
                value: filters.program_id,
                onChange: ({ target: { value: id = '' } }) => {
                    if (id.match(/^[0-9,]*$/))
                        handleColumnFiltersChange('program_id', id);
                }
            }
        },
        {
            name: 'Program Title',
            type: 'text',
            key: 'program_name',
            urlKey: 'programs_link',
            urlTarget: 'blank',
            filter: {
                onChange: (e) => {
                    handleColumnFiltersChange('program_name', e.target.value);
                }
            }
        },
        {
            name: 'Program Link',
            format: 'bold',
            key: 'url',
            urlKey: 'crawlable',
            urlTarget: 'blank',
            type: 'text',
            filter: {
                onChange: (e) => {
                    handleColumnFiltersChange('url', e.target.value);
                }
            }
        },
        {
            name: 'Link Type',
            type: 'select',
            key: 'link_type',
            filter: {
                options: link_type,
                optionValue: 'value',
                optionKey: 'label',
                value: type_value,
                type: 'select',
                onChange: (link) => handleColumnFiltersChange('link_type', link)
            }
        },
        {
            name: 'Response Status',
            type: 'status_chip:response_status',
            key: 'status',
            format: 'normal',
            filter: {
                options:
                    response_status
                        .filter(function (status) {
                            if (globalFiltersStatus === 'redirects') {
                                return redirects.includes(status.value);
                            }

                            if (globalFiltersStatus === 'broken') {
                                return broken.includes(status.value);
                            }

                            if (globalFiltersStatus === 'verified') {
                                return verified.includes(status.value);
                            }
                            return true;
                        })
                        .map((status) => {
                            return {
                                id: `${status.value}`,
                                name: `${status.label}`
                            };
                        }) || {},
                value: selectedStatus,
                type: 'multiselect',
                handler: (status) => {
                    handleSelectedStatus(status);
                }
            },
            sort: status_sort_value
        },
        {
            name: 'Date Last Crawled',
            type: 'date',
            key: 'date_last_verified',
            filter: {
                onChange: (date_verified) =>
                    handleColumnFiltersChange('date_verified', date_verified)
            }
        }
    ];

    const rowMenus = [
        {
            name: 'update',
            text: 'Update Link',
            target: 'blank'
        },
        {
            name: 'verified',
            text: 'Mark As Verified',
            handler: (row) => {
                handleShowActionConfirmation('mark as verified', () =>
                    handleUpdateProgramUrls('mark as verified', false, row)
                );
            }
        },
        {
            name: 'broken',
            text: 'Mark As Broken',
            handler: (row) => {
                handleShowActionConfirmation('mark as broken', () =>
                    handleUpdateProgramUrls('mark as broken', false, row)
                );
            }
        }
    ];

    const batchActions = [
        {
            icon: 'verified',
            handler: () =>
                handleShowActionConfirmation('mark as verified', () =>
                    handleUpdateProgramUrls('mark as verified', true)
                )
        },
        {
            icon: 'broken',
            handler: () =>
                handleShowActionConfirmation('mark as broken', () =>
                    handleUpdateProgramUrls('mark as broken', true)
                )
        }
    ];

    const icons = [
        {
            name: 'pirates_icon',
            icon: <TeamIcon variant="pirates" />,
            placement: 'right',
            column: 'provider_name'
        },
        {
            name: 'unassigned_icon',
            icon: <TeamIcon variant="unassigned" />,
            placement: 'right',
            column: 'provider_name'
        },
        {
            name: 'duo_icon',
            icon: <TeamIcon variant="duo" />,
            placement: 'right',
            column: 'provider_name'
        },
        {
            name: 'express_icon',
            icon: <TeamIcon variant="express" />,
            placement: 'right',
            column: 'provider_name'
        },
        {
            name: 'tycoons_icon',
            icon: <TeamIcon variant="tycoons" />,
            placement: 'right',
            column: 'provider_name'
        },
        {
            name: 'content_icon',
            icon: <TeamIcon variant="content" />,
            placement: 'right',
            column: 'provider_name'
        }
    ];

    const iconsChecker = (row, icons) => {
        icons = icons.filter((icon) => {
            if (icon.name === 'pirates_icon') return row.team_id === 32;
            if (icon.name === 'unassigned_icon')
                return row.team_id === 0 || row.team_id === null;
            if (icon.name === 'duo_icon') return row.team_id === 31;
            if (icon.name === 'express_icon') return row.team_id === 29;
            if (icon.name === 'tycoons_icon') return row.team_id === 30;
            if (icon.name === 'content_icon') return row.team_id === 23;
            return true;
        });
        return icons;
    };

    const handlePageChange = (page) => {
        setProgramUrlVerifierState({ page: page }, fetchProgramUrls);
    };

    const handleRowsPerPageChange = (rowsPerPage) =>
        setProgramUrlVerifierState({ rowsPerPage, page: 0 }, fetchProgramUrls);

    const mappedData = (data || []).map((programUrl) => {
        if (programUrl.url_type === 1) programUrl.link_type = 'Visit Website';
        else if (programUrl.url_type === 2)
            programUrl.link_type = 'Inquire Here';

        programUrl.client_link = `/client/profile/${programUrl.provider_id}`;
        programUrl.programs_link = `/client-accounts/programs`;

        if (programUrl.date_last_verified === '0000-00-00 00:00:00')
            programUrl.date_last_verified = '';

        programUrl.crawlable = programUrl.url ? toValidUrl(programUrl.url) : '';

        return programUrl;
    });

    const rowMenuChecker = (row, rowMenus) =>
        rowMenus
            .filter((menu) => {
                if (menu.name === 'verified') return row.status !== 200;
                if (menu.name === 'broken')
                    return [200, 301, 302].includes(row.status);
                return true;
            })
            .map((menu) => {
                if (menu.name === 'update') {
                    menu.url = `/listing/${row.program_id}/edit`;
                }
                return menu;
            });

    const rowClasses = [{ className: classes.root }];

    const handleGlobalFilterClick = () =>
        setResulstMainState({ openGlobalFilter: true });

    const { openGlobalFilter } = state;

    const handleApplyGlobalFilters = ({ team, programLinkStatus }) => {
        const globalFilters = {
            team_id: team,
            status: programLinkStatus
        };
        addValidBookmarkableFields({ ...filters, ...globalFilters });
        setSelectedStatus([]);
        setProgramUrlVerifierState(
            {
                rowsPerPage,
                page: 0,
                globalFiltersStatus: programLinkStatus,
                filters: { ...filters, ...globalFilters }
            },
            fetchProgramUrls
        );
    };

    const handleClearGlobalFilters = () => {
        let { status, team_id, ...otherFilters } = filters;

        addValidBookmarkableFields(otherFilters);
        setSelectedStatus([]);
        setProgramUrlVerifierState(
            {
                rowsPerPage,
                page: 0,
                filters: otherFilters,
                globalFiltersStatus: ''
            },
            fetchProgramUrls
        );
        setResulstMainState({ openGlobalFilter: false });
    };

    const mapFilters = (filters) => {
        const { status = null, team_id = null } = filters;
        const new_filters = {};

        if (hasValue(team_id)) {
            new_filters['team'] = team_id;
            new_filters['teamAssignment'] = true;
        }

        //eslint-disable-next-line array-callback-return
        GLOBAL_STATUS.map((item) => {
            if ((status || []).includes(item)) {
                new_filters['programLink'] = true;
                new_filters['programLinkStatus'] = item;
            }
        });

        return new_filters;
    };

    const handleColumnFiltersChange = (filter, value) => {
        const newFilters = { ...filters };
        newFilters[filter] = value;
        setProgramUrlVerifierState({ filters: newFilters, selected: [] });
    };

    const handleSelectedStatus = (status) => {
        const newFilters = { ...filters };
        newFilters['status'] = globalFiltersStatus
            ? globalFiltersStatus + ',' + status.join(',')
            : status.join(',');
        setSelectedStatus(status);
        setProgramUrlVerifierState({ filters: newFilters, selected: [] });
    };

    const handleApplyColumnFilters = () => {
        setProgramUrlVerifierState(
            {
                page: 0,
                isFetching: true,
                selected: []
            },
            () => fetchProgramUrls()
        );
    };

    const handleClearColumnFilters = () => {
        const newFilters = { ...filters };
        //eslint-disable-next-line array-callback-return
        COLUMN_FILTER_NAMES.map((key) => {
            newFilters[key] = null;
            if (key === 'status') {
                setSelectedStatus([]);
                let filtersArray = !!filters.status
                    ? filters.status.split(',')
                    : [];
                //eslint-disable-next-line array-callback-return
                GLOBAL_STATUS.map((status) => {
                    if (filtersArray.includes(status)) {
                        newFilters['status'] = status;
                    }
                });
            }
        });
        setProgramUrlVerifierState(
            {
                filters: newFilters,
                page: 0,
                isFetching: true,
                selected: []
            },
            () => fetchProgramUrls()
        );
    };

    const onColumnHeaderClick = (column, key) => {
        const { sort: columnSort, key: columnKey } = column;

        if (SORTABLE_FIELDS.includes(columnKey)) {
            setProgramUrlVerifierState(
                {
                    sort: updateOrMerge(
                        [],
                        convertToKeyValueDict([
                            {
                                direction:
                                    columnSort === 'asc' ? 'desc' : 'asc',
                                key: columnKey
                            }
                        ])
                    ),
                    loading: true,
                    page: 0,
                    rowsPerPage
                },
                fetchProgramUrls
            );
        }
    };

    return (
        <>
            <ResultsTable
                hasSearch={false}
                label={label}
                data={mappedData}
                columns={columns}
                rowMenus={rowMenus}
                rowMenusChecker={rowMenuChecker}
                activeGlobalFilters={
                    !!globalFiltersStatus || filters['team_id']
                }
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                selected={selected}
                onRowsPerPageChange={handleRowsPerPageChange}
                onPageChange={handlePageChange}
                batchActions={batchActions}
                rowClasses={rowClasses}
                onGlobalFiltersClick={handleGlobalFilterClick}
                isLoadingData={isFetching}
                onColumnFiltersApply={handleApplyColumnFilters}
                onColumnFilterClose={handleClearColumnFilters}
                onColumnHeaderClick={onColumnHeaderClick}
                onSelectedChange={handleSelectMultiple}
                icons={icons}
                iconsChecker={iconsChecker}
            />
            <GlobalFilterDrawer
                accountStatus={false}
                programLink
                open={openGlobalFilter}
                onClose={() => setResulstMainState({ openGlobalFilter: false })}
                onApplyFilter={handleApplyGlobalFilters}
                filters={mapFilters(filters)}
                onClearFilters={handleClearGlobalFilters}
            />
            <DialogBox
                actions="OkCancel"
                title={dialog.title.toUpperCase()}
                contentText={dialog.content}
                open={dialog.show}
                onOk={dialog.onOk}
                onCancel={handleCloseDialog}
                onClose={handleCloseDialog}
                stringOverride={dialog.stringOverride}
                classes={{
                    dialog: classes.dialog,

                    content: classes.content
                }}
            />
            <DialogBox open={isSaving} title={'Saving Changes...'} actions="">
                <LinearProgress />
            </DialogBox>
        </>
    );
};

export default ResultsMain;
