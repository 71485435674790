import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router';
import {
    useDidUpdateEffect,
    useObjectState
} from '../../utilities/customHooks';
import { GuidesContext } from '../../context';
import {
    constructHttpParams,
    explodeUrlParams,
    isEmptyDate
} from '../../helpers/dataUtility';
import {
    SEARCH_CRITERIA,
    transformLocationParams,
    transformDirectoryParams,
    getCriteriaByDirectory
} from '../../utilities/searchCriteria';
import getDirectoryBy from '../../utilities/directory.js';
import {
    exportCsv,
    sendRequest,
    postRequest
} from '../../helpers/apiRequestUtility';
import { transformDateParam } from '../../utilities/dateFormatter.js';
import { isBookmarkable } from '../../utilities/boomarkability';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useGlobalMethods } from '../Client/GlobalProvider';
import { LOCATION_CRITERIA } from '../../config/constants';

const { Provider } = GuidesContext;

const GuidesProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const location = useLocation();
    const { handleStateChange } = useGlobalMethods();
    const sortable = ['date_modified', 'date_published'];
    const [state, setGuidesState] = useObjectState({
        data: [],
        authors: [],
        fetchingAuthors: false,
        filters: [],
        page: 0,
        rowsPerPage: 10,
        count: 0,
        fetching: false,
        globalFiltersOpen: false,
        dialog: {
            show: false,
            title: '',
            content: '',
            stringOverride: {},
            onOk: ''
        },
        sortBy: 'date_published',
        sortOrder: 'desc',
        selected: [],
        isSaving: false,
        locations: [],
        fetchingLocations: false,
        pageUrl: ''
    });

    useDidUpdateEffect(() => {
        const { filters, page_url, locations } = state;
        const { provider, status, author_id, date_published, date_modified } =
            filters;
        const tempFilters = { ...filters };

        const locationParams = transformLocationParams({
            filters: tempFilters,
            locations
        });
        const directoryParams = transformDirectoryParams(tempFilters);

        const bookmarkableParams = {};

        const transformedFilters = {
            page_url,
            provider_id: !isEmpty(provider) ? provider.value : null,
            status,
            author_id: author_id !== null ? author_id : null,
            date_published: date_published
                ? transformDateParam(date_published)
                : null,
            date_modified: date_modified
                ? transformDateParam(date_modified)
                : null,
            ...locationParams,
            ...directoryParams
        };
        // eslint-disable-next-line
        Object.keys(transformedFilters).map((key) => {
            if (isBookmarkable('guides_view', key)) {
                bookmarkableParams[key] = transformedFilters[key];
            }
        });
        const params = constructHttpParams(bookmarkableParams);
        history.push({ pathname: `/guides`, search: `?${params}` });
    }, [state.filters, state.page_url]);

    useDidUpdateEffect(() => {
        fetchGuides();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.filters, state.sortBy, state.sortOrder]);

    useEffect(() => {
        const searchParams = explodeUrlParams(location.search);
        const bookmarkableParams = {};

        let sortParams = {};
        if (searchParams.sort && sortable.includes(searchParams.sort)) {
            sortParams = {
                sortBy: searchParams.sort,
                sortOrder: 'desc'
            }
        }

        //eslint-disable-next-line array-callback-return
        Object.keys(searchParams).map((key) => {
            if (isBookmarkable('guides_view', key)) {
                if (key === 'date_published' || key === 'date_modified') {
                    bookmarkableParams[key] = {
                        start: searchParams[key]
                    };
                } else bookmarkableParams[key] = searchParams[key];
            }
        });

        if (bookmarkableParams) {
            generateFilters(bookmarkableParams).then((data) => {
                setGuidesState({
                    ...sortParams,
                    filters: {
                        ...state.filters,
                        ...bookmarkableParams,
                        ...data
                    },
                    fetching: false
                });
            });
        }

        if (isEmpty(state.authors) && !state.fetchingAuthors) fetchAuthors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateFilters = async (searchParams) => {
        let { locations: locationOptions } = state;
        const { directory_id, online, bin_value } = searchParams;
        const {
            [directory_id]: {
                timing: { id: timing_id, new_criteria: timing_criteria } = {},
                type: { id: type_id, new_criteria: type_criteria } = {}
            } = {}
        } = getCriteriaByDirectory({
            key: 'id',
            val: [directory_id]
        });

        const filterKeys = Object.keys(searchParams);
        const params = {};

        setGuidesState({ fetching: true });
        if (directory_id) {
            const [{ id: value, name: label }] = getDirectoryBy(
                { key: 'id', val: [directory_id] },
                ['id', 'name']
            );
            params['directory'] = { value, label };

            const types =
                type_criteria && filterKeys.includes(type_id)
                    ? await fetchCriteriaOptions(type_criteria)
                    : [];
            const timings =
                timing_criteria && filterKeys.includes(timing_id)
                    ? await fetchCriteriaOptions(timing_criteria)
                    : [];

            params['timing'] =
                timings
                    .map(({ value, name: label }) => {
                        return { value, label };
                    })
                    .filter(({ value }) => {
                        return searchParams[timing_id] === value;
                    })[0] || null;

            params['type'] =
                types
                    .map(({ value, name: label }) => {
                        return { value, label };
                    })
                    .filter(({ value }) => {
                        return searchParams[type_id] === value;
                    })[0] || null;
        }

        const hasLocationFilter =
            LOCATION_CRITERIA.filter((loc) => {
                return filterKeys.includes(loc);
            }).length > 0;

        if (hasLocationFilter) {
            if (locationOptions.length === 0) {
                locationOptions = await fetchLocations();
            }
            params['location'] = locationOptions
                .filter((data) => {
                    let filter = false;
                    LOCATION_CRITERIA.forEach((i) => {
                        if (filterKeys.includes(i)) {
                            const bin = SEARCH_CRITERIA[i].bin_value;
                            const value = searchParams[i];
                            const id = bin + '_' + value;
                            if (data.id === id) {
                                filter = true;
                                return;
                            }
                        }
                    });
                    return filter;
                })
                .map(({ id: value, name: label }) => {
                    return { label, value };
                })[0];
        }
        if (online) params['online'] = { label: 'Online', value: 1 };

        if (bin_value) {
            params['bin_value'] = bin_value;
            params['isDirectoryOnly'] = bin_value === 1 ? 1 : 0;
        }

        return params;
    };

    const sortGuides = ({ guides, sortBy, sortOrder }) => {
        guides.sort((a, b) => {
            if (!sortBy) return 0;

            const isEmptyA = isEmptyDate(a[sortBy]);
            const isEmptyB = isEmptyDate(b[sortBy]);
            const valueA = isEmptyA ? 0 : new Date(a[sortBy]).getTime();
            const valueB = isEmptyB ? 0 : new Date(b[sortBy]).getTime();

            if (sortOrder === 'asc') return valueA - valueB;
            else return valueB - valueA;
        });

        return guides;
    };

    const handleSort = ({ key: sortBy, sort }) => {
        if (!sortable.includes(sortBy)) return;

        const sortOrder = sort === 'desc' ? 'asc' : 'desc';

        setGuidesState({
            data: [],
            sortBy,
            sortOrder,
            page: 0
        });
    };

    const fetchCriteriaOptions = async (criteria) =>
        sendRequest(
            `/search-criteria-options?criteria=${criteria}`,
            ({ search_criteria_options = [] }) =>
                search_criteria_options.map(({ id: value, alias, name }) => {
                    return { value, alias, name };
                })
        );

    const fetchLocations = async () => {
        let { locations, fetchingLocations } = state;
        if (
            !locations ||
            locations.length === 0 ||
            fetchingLocations === false
        ) {
            setGuidesState({ fetchingLocations: true });
            locations = await sendRequest(
                '/locations?location_type=world_region,country,main_region,city',
                ({ locations }) => {
                    setGuidesState({ locations, fetchingLocations: false });
                    return locations;
                }
            );
        }
        return locations;
    };

    const fetchAuthors = () => {
        setGuidesState({ fetchingAuthors: true });
        const params = constructHttpParams({
            fields: 'id,name',
            limit: -1
        });

        sendRequest(`/authors?${params}`, ({ authors }) => {
            authors.sort((a, b) => {
                let fa = a.name.toString().replace(/\s+/g, ''),
                    fb = b.name.toString().replace(/\s+/g, '');
                if (fa > fb) return 1;
                if (fa < fb) return -1;
                return 0;
            })
            setGuidesState({ authors, fetchingAuthors: false });
        });
    };

    const fetchGuides = (offset = null, limit = null) => {
        const {
            page,
            rowsPerPage,
            sortBy,
            sortOrder,
            filters,
            locations,
            data
        } = state;

        const {
            provider,
            status,
            author_id,
            date_published,
            date_modified,
            urlParams
        } = filters;

        let filterParams = {
            provider_id: !isEmpty(provider) ? provider.value : null,
            status,
            author_id: author_id !== null ? author_id : null,
            date_published: date_published
                ? transformDateParam(date_published)
                : null,
            date_modified: date_modified
                ? transformDateParam(date_modified)
                : null
        };

        const locationParams = transformLocationParams({ filters, locations });
        const directoryParams = transformDirectoryParams({ ...filters });

        filterParams = {
            ...filterParams,
            ...locationParams,
            ...directoryParams,
            ...urlParams
        };

        const params = constructHttpParams({
            offset: offset !== null ? offset : page * rowsPerPage,
            limit: limit !== null ? limit : rowsPerPage,
            fields: 'id,search_criteria,author_id,author,date_created,date_modified,date_published,status',
            sort: `${sortOrder === 'desc' ? '-' : '+'}${sortBy},+id`,
            ...filterParams
        });

        setGuidesState({ fetching: true });
        sendRequest(
            `/guides?${params}`,
            ({ guides, count }) => {
                const { sortBy, sortOrder } = state;
                const newIds = [...guides].map(({ id }) => id);
                let tempData = [...data]
                    .filter(({ id }) => !newIds.includes(id))
                    .concat(guides);
                tempData = sortGuides({ guides: tempData, sortBy, sortOrder });
                setGuidesState({ data: tempData, count });
            },
            () => {
                setGuidesState({ data: [], count: 0 });
                enqueueSnackbar('Failed fetching guides. Please try again.', {
                    variant: 'error'
                });
            }
        ).finally(() => setGuidesState({ fetching: false }));
    };

    const handleUpdateGuides = async (action, isBatch, guide) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch
            ? selected
                  .filter((row) => {
                      if (action === 'publish') {
                          return row.status !== 1;
                      } else if (action === 'unpublish') {
                          return row.status !== 0;
                      }
                      return true;
                  })
                  .map((row) => row.id)
            : [guide.id];

        data.append('id', selectedIds.join());

        if (action === 'publish') {
            data.append('status', 1);
            data.append(
                'date_published',
                moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            );
        } else if (action === 'unpublish') {
            data.append('status', 0);
        }

        setGuidesState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            setGuidesState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/guides/update`,
                data,
                (success) => {
                    setGuidesState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Guides have been updated!`,
                        {
                            variant: 'success'
                        }
                    );
                    fetchGuides();
                },
                (error) => {
                    setGuidesState({ isSaving: false });
                    enqueueSnackbar('Failed to update Guides.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Guides have been updated.', {
                variant: 'warning'
            });
        }
    };

    const handleDeleteGuides = async (isBatch, guide) => {
        const data = new FormData();
        const { selected } = state;

        let selectedIds = isBatch ? selected.map((row) => row.id) : [guide.id];

        data.append('id', selectedIds.join());

        setGuidesState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            }
        });

        if (selectedIds.length) {
            setGuidesState({
                isSaving: true,
                fetching: true
            });

            await postRequest(
                `/guides/delete`,
                data,
                (success) => {
                    setGuidesState({ isSaving: false });
                    const successCount = success.successIds.length;
                    enqueueSnackbar(
                        `(${successCount}) Guides have been deleted!`,
                        {
                            variant: 'success'
                        }
                    );
                    fetchGuides();
                },
                (error) => {
                    setGuidesState({ isSaving: false });
                    enqueueSnackbar('Failed to delete Guides.', {
                        variant: 'error'
                    });
                }
            );
        } else {
            enqueueSnackbar('No Guides have been deleted.', {
                variant: 'error'
            });
        }
    };

    const handleExportGuides = () => {
        const { selected } = state;
        const ids = selected.map((obj) => {
            return obj.id;
        });
        let url = `/guides/export`;

        setGuidesState({
            selected: [],
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {},
                onOk: {}
            },
            isSaving: true
        });

        if (selected.length > 0) {
            url += '?id=' + ids.join(',');
        } else {
            const { sortBy, sortOrder, filters, locations, count } = state;

            const {
                provider,
                status,
                author_id,
                date_published,
                date_modified,
                urlParams
            } = filters;

            const filterParams = {
                provider_id: !isEmpty(provider) ? provider.value : null,
                status,
                author_id,
                date_published,
                date_modified
            };

            const locationParams = transformLocationParams({
                filters,
                locations
            });
            const directoryParams = transformDirectoryParams({ ...filters });

            const params = {
                ...filterParams,
                ...locationParams,
                ...directoryParams,
                ...urlParams,
                offset: 0,
                limit: count,
                fields: 'id,search_criteria,author_id,author,date_created,date_modified,date_published',
                sort: `${sortOrder === 'desc' ? '-' : '+'}${sortBy},+id`
            };

            url += '?' + constructHttpParams(params);
        }

        exportCsv(
            url,
            'Guides.csv',
            () => {
                enqueueSnackbar('Guides successfully exported!', {
                    variant: 'success'
                });
                setGuidesState({ isSaving: false });
            },
            () => {
                handleStateChange({
                    showLoginDialog: true,
                    loginDialogCallback: handleExportGuides
                });
                setGuidesState({ isSaving: false });
            },
            () => {
                enqueueSnackbar('Error encountered', {
                    variant: 'error'
                });
                setGuidesState({ isSaving: false });
            }
        );
    };

    const handleShowActionConfirmation = (
        batchAction,
        func,
        content = null
    ) => {
        setGuidesState({
            dialog: {
                show: true,
                title: batchAction,
                content:
                    content ||
                    `Are you sure you want to ${batchAction} the selected guide(s)?`,
                stringOverride: {
                    primaryAction: batchAction,
                    secondaryAction: 'Cancel'
                },
                onOk: func
            }
        });
    };

    const handleCloseDialog = () => {
        setGuidesState({
            dialog: {
                show: false,
                title: '',
                content: '',
                stringOverride: {}
            }
        });
    };

    const handleSelectMultiple = (selected) => {
        setGuidesState({
            selected
        });
    };

    return (
        <Provider
            value={{
                state,
                setGuidesState,
                handleSort,
                fetchLocations,
                fetchCriteriaOptions,
                fetchAuthors,
                fetchGuides,
                handleUpdateGuides,
                handleDeleteGuides,
                handleExportGuides,
                handleShowActionConfirmation,
                handleCloseDialog,
                handleSelectMultiple
            }}
        >
            {props.children}
        </Provider>
    );
};

export const useGuidesState = () => {
    const { state, setGuidesState } = useContext(GuidesContext);
    return { ...state, setGuidesState };
};

export const useGuidesMethods = () => {
    const { state, ...methods } = useContext(GuidesContext);
    return methods;
};

export default GuidesProvider;
