import React, { Component } from 'react';

import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const styles = (theme) => ({
    wrapper: {
        marginBottom: '2rem',
        position: 'relative',
    },
    label: {
        color: grey[600],
        fontSize: 15,
        display: 'flex',
    },
    counter: {
        color: grey[600],
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        '& p': {
            display: 'inline',
            fontSize: 12,
        },
        position: 'absolute',
        right: 0,
    },
    input: {
        fontWeight: '100',
        '& textarea, & input': {
            lineHeight: '1.6em',
        },
    },
    required: {
        fontSize: 10,
        color: red[400],
        fontWeight: 'bold',
        marginLeft: 'auto',
    },
});

class InputWithCounter extends Component {
    state = {
        value: this.props.value || '',
        focused: false
    };

    componentWillReceiveProps = (props) => {
        if (props.value !== this.state.value) {
            this.setState({ value: props.value });
        }
    };

    handleChange = (e) => {
        let { max, onChange, isAutoTrimmed = false } = this.props;
        let value =
            max && isAutoTrimmed
                ? e.target.value.substring(0, max)
                : e.target.value;
        this.setState({ value });
        onChange && onChange(value);
    };

    handleFocus = (e) => {
        this.setState({ focused: true });
        this.props.onFocus && this.props.onFocus(e);
    };

    handleAcceptOnEnter = (e) => {
        this.props.onAccept && this.props.onAccept();
    }

    handleAccept = (e) => {
        let { value } = this.state;
        this.setState({
            focused: this.props.leaveonaccept ? false : true,
            value: this.props.clearonaccept ? '' : value
        });
        this.props.onAccept && this.props.onAccept(this.state.value);
    };

    handleBlur = (e) => {
        this.props.onAccept
            ? e.preventDefault()
            : this.setState({ focused: false });
        this.props.onBlur && this.props.onBlur();
    };

    render() {
        const {
            min,
            max,
            label,
            classes,
            onChange,
            acceptsOnEnter,
            onAccept,
            multiline = false,
            disableUnderline = false,
            dataCy,
            leaveonaccept,
            clearonaccept,
            isRequired,
            customClass,
            ...unused
        } = this.props;
        const { focused } = this.state;
        const value = this.props.value || '';

        return (
            <div className={`${classes.wrapper} ${customClass}`} data-cy={dataCy}>
                {label && (
                    <Typography className={classes.label}>
                        {label}
                        {isRequired && (
                            <span className={classes.required}>Required</span>
                        )}
                    </Typography>
                )}
                <TextField
                    fullWidth
                    {...unused}
                    value={value}
                    onChange={this.handleChange}
                    className={classes.input}
                    InputProps={{ disableUnderline, multiline }}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && acceptsOnEnter) {
                            e.preventDefault();
                            this.handleAcceptOnEnter();
                        }
                    }}
                />
                {focused && (
                    <Typography className={classes.counter} align="right">
                        <Typography
                            data-cy="alt-text-length"
                            color={
                                min &&
                                    ((value.length > 0))
                                    ? (value.length < min) ||
                                        (value.length  > max)
                                        ? 'secondary'
                                        : 'primary'
                                    : 'default'
                            }
                        >
                            {(value && value.length) || 0}
                        </Typography>
                        {max && (
                            <Typography data-cy="alt-text-max-length" color="default">{`/${max}`}</Typography>
                        )}
                    </Typography>
                )}
            </div>
        );
    }
}

InputWithCounter.propTypes = {
    classes: PropTypes.object.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onAccept: PropTypes.func,
    acceptsOnEnter: PropTypes.bool,
    isAutoTrimmed: PropTypes.bool,
    multiline: PropTypes.bool,
    disableUnderline: PropTypes.bool,
    dataCy: PropTypes.string
};

export default withStyles(styles)(InputWithCounter);
