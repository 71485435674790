import React from 'react';
import { Tooltip, Paper, Button, Typography, Chip } from '@material-ui/core';
import { useArticlesState, useArticlesMethods } from './ArticlesProvider';
import { makeStyles } from '@material-ui/styles';
import { capitalizeFirstLetter } from '../../../helpers/dataUtility';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: 'sticky',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        top: 40,
        zIndex: 1
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& svg': {
            fontSize: 18
        }
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& > div': {
            display: 'flex'
        }
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1)
    },
    dates: {
        '& span > div': {
            margin: `0 ${theme.spacing(1)}px`
        },

        '& span > div:last-child': {
            marginRight: 0
        },

        '& input': {
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    dateWrap: {
        marginLeft: 'auto',
        padding: 0,
        marginBottom: theme.spacing(0.5)
    },
    clear: {
        width: '100%',
        maxWidth: 332,
        padding: 0,
        height: 38
    },
    label: {
        padding: 0,
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        display: 'inline',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const filterFields = [
    'directory',
    'location',
    'type',
    'timing',
    'topic',
    'online'
];

const DataFilter = () => {
    const classes = useStyles();
    const { filters = {}, setArticlesState } = useArticlesState();
    const { fetchArticles } = useArticlesMethods();

    const handleRemoveFilter = (key) => () => {
        let {
            [key]: deleted,
            isDirectoryOnly,
            isExactLocation,
            bin_value,
            ...remaining
        } = filters;
        let state = {};

        if (key === 'directory') {
            remaining.type = null;
            remaining.timing = null;
            remaining.online = null;
            state = {
                types: { data: [], fetching: false },
                timings: { data: [], fetching: false }
            };
        }

        setArticlesState(
            {
                ...state,
                filters: remaining,
                fetchingArticles: true,
                selected: [],
                page: 0
            },
            fetchArticles
        );
    };

    const handleOpenDrawer = () => setArticlesState({ filterDrawerOpen: true });

    const dataFilters = Object.keys(filters)
        .filter((key) => filterFields.includes(key) && !!filters[key])
        .map((key) => {
            return { ...filters[key], key };
        });

    const hasDataFilters = dataFilters.length > 0;

    return (
        <Paper className={classes.paper} elevation={1}>
            <div className={classes.header}>
                <div>
                    {!hasDataFilters ? (
                        <Tooltip title="Add Data Filters" placement="top">
                            <Button
                                data-cy="btn-add-filters"
                                variant="contained"
                                color="primary"
                                onClick={handleOpenDrawer}
                            >
                                Add Filters
                            </Button>
                        </Tooltip>
                    ) : (
                        <Typography className={classes.label}>
                            Data Filters:{' '}
                        </Typography>
                    )}
                    {dataFilters.map(({ key, label }) => {
                        return (
                            <Tooltip
                                title={capitalizeFirstLetter(key)}
                                placement="top"
                            >
                                <Chip
                                    className={classes.chip}
                                    size="small"
                                    label={label}
                                    variant="outlined"
                                    color="primary"
                                    onDelete={handleRemoveFilter(key)}
                                    onClick={handleOpenDrawer}
                                />
                            </Tooltip>
                        );
                    })}
                    {hasDataFilters && (
                        <Tooltip title="Add Data Filters" placement="top">
                            <Chip
                                className={classes.chip}
                                size="small"
                                label={'Add'}
                                variant="contained"
                                color="primary"
                                onClick={handleOpenDrawer}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        </Paper>
    );
};

export default DataFilter;
