import React from 'react';
import { makeStyles, Chip, Tooltip } from '@material-ui/core';
import { blue, grey, red } from '@material-ui/core/colors';
import Delete from '@material-ui/icons/Cancel';
import Crop from '@material-ui/icons/Crop';

const useStyles = ({ isGif }) =>
    makeStyles((theme) => ({
        wrapper: {
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            backgroundColor: grey[100],
            borderRadius: theme.spacing(1),

            '& img': {
                maxWidth: '100%',
                maxHeight: '100%'
            }
        },
        clearButton: {
            position: 'absolute',
            top: theme.spacing(2),
            right: isGif ? theme.spacing(2) : 82 + theme.spacing(3),
            backgroundColor: red[300]
        },
        cropButton: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: blue[300]
        }
    }));

const ImageViewer = ({
    show,
    type,
    image,
    onCrop = () => {},
    onClear = () => {}
}) => {
    const classes = useStyles({ isGif: type === 'gif' })();

    if (!show) return null;

    return (
        <div className={classes.wrapper}>
            <Tooltip title="Remove Image" placement="bottom">
                <Chip
                    className={classes.clearButton}
                    label="clear"
                    onClick={onClear}
                    color="secondary"
                    data-cy="btn_clear_crop"
                    icon={<Delete fontSize={'small'} />}
                />
            </Tooltip>
            {type !== 'gif' && (
                <Tooltip title="Crop Image" placement="bottom">
                    <Chip
                        className={classes.cropButton}
                        label="Crop"
                        color="primary"
                        data-cy="btn_crop"
                        icon={<Crop fontSize={'small'} />}
                        size="medium"
                        onClick={onCrop}
                    />
                </Tooltip>
            )}
            <img src={image} alt="" />
        </div>
    );
};

export default ImageViewer;
