import { find } from 'lodash';

export const headerNames = [
    {
        path: 'client-accounts',
        name: 'Client Accounts'
    },
    {
        path: 'client-accounts/invoices',
        name: 'Client Invoices'
    },
    {
        path: 'client-accounts/programs',
        name: 'Client Programs'
    },
    {
        path: 'client-accounts/ads',
        name: 'Client Ads'
    },
    {
        path: 'client-accounts/reviews',
        name: 'Client Reviews'
    },
    {
        path: 'client-accounts/communication-logs',
        name: 'Client Communication Logs'
    },
    {
        path: 'ranking-system',
        name: 'Search Results Ranking'
    },
    {
        path: 'ranking-system/features',
        name: 'Features Ranking'
    },
    {
        path: 'ranking-system/program-ranking',
        name: 'Program Ranking in Search Results'
    },
    {
        path: 'ranking-system/travel-resource',
        name: 'Travel Resource Ranking'
    },
    {
        path: 'authors',
        name: 'Article Authors'
    },
    {
        path: 'topics',
        name: 'Article Topics'
    },
    {
        path: 'scholarships',
        name: 'Scholarship Listings'
    },
    {
        path: 'scholarships-ranking',
        name: 'Scholarships Ranking'
    },
    {
        path: 'mygoabroad',
        name: 'MyGoAbroad Deals'
    },
    {
        path: 'mygoabroad/applications',
        name: 'MyGoAbroad Applications'
    },
    {
        path: 'mygoabroad/users',
        name: 'MyGoAbroad Users'
    },
    {
        path: 'mygoabroad/deals/add',
        name: 'Add New Deal'
    },
    {
        path: 'url-verifiers',
        name: 'Provider URL Verifiers'
    },
    {
        path: 'url-verifiers/program-url',
        name: 'Program URL Verifiers'
    },
    {
        path: 'url-verifiers/ads-url',
        name: 'Ads URL Verifiers'
    },
    {
        path: 'site-tools',
        name: 'Directory and Search Results Metas'
    },
    {
        path: 'site-tools/custom-meta',
        name: 'Search Results Custom Metas'
    },
    {
        path: 'ad/create',
        name: 'Create New Ad'
    },
    {
        path: 'ad/[0-9]/edit',
        name: 'Edit Ad'
    }
];

export const getCustHeaderName = (path) => {
    const { name = '' } =
        find(headerNames, ['path', path.replace(/^\/+|\/+$/g, '')]) || {};

    return name;
};

export default headerNames;
