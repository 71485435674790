import React, { Fragment, lazy, Suspense, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { TableLoader } from '../../components/Loader/Loader.js';
import { useLocation } from 'react-router-dom';
import { findIndex } from 'lodash';
import LinkTab from '../../components/Tabs/LinkTab.js';

const ClientAccounts = lazy(() => import('./Accounts/ClientAccounts'));
const ClientAds = lazy(() => import('./Ads/ClientAds'));
const ClientInvoices = lazy(() => import('./Invoices/ClientInvoices'));
const ClientPrograms = lazy(() => import('./Programs/ClientPrograms'));
const ClientReviews = lazy(() => import('./Reviews/ClientReviews'));
const ClientCommLogs = lazy(() => import('./Logs/ClientCommLogs'));

const clientViews = [
    {
        url: 'client-accounts',
        name: 'Accounts'
    },
    {
        url: 'client-accounts/invoices',
        name: 'Invoices'
    },
    {
        url: 'client-accounts/programs',
        name: 'Programs'
    },
    {
        url: 'client-accounts/ads',
        name: 'Ads'
    },
    {
        url: 'client-accounts/reviews',
        name: 'Reviews'
    },
    {
        url: 'client-accounts/communication-logs',
        name: 'Communications'
    }
];

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        marginBottom: theme.spacing.unit * 4
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '0.9rem'
    },
    accountSummary: {
        padding: theme.spacing.unit * 3,
        width: 500
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Client = () => {
    const classes = useStyles();
    const { pathname } = useLocation();

    const tabSelected = findIndex(clientViews, [
        'url',
        pathname.replace(/^\/+|\/+$/g, '')
    ]);

    return (
        <Fragment>
            <Tabs
                value={tabSelected}
                className={classes.tabsRoot}
                indicatorColor="secondary"
                textColor="secondary"
            >
                {clientViews.map(({ id, name, url }) => {
                    return (
                        <LinkTab
                            key={id}
                            data-cy={`tabs-${name}`}
                            disableRipple
                            classes={{
                                root: classes.tabRoot,
                                selected: classes.tabSelected
                            }}
                            label={name}
                            to={`/${url}`}
                        />
                    );
                })}
            </Tabs>
            <Suspense fallback={<TableLoader />}>
                <ClientAccounts active={tabSelected === 0} />
                <ClientInvoices active={tabSelected === 1} />
                <ClientPrograms active={tabSelected === 2} />
                <ClientAds active={tabSelected === 3} />
                <ClientReviews active={tabSelected === 4} />
                <ClientCommLogs active={tabSelected === 5} />
            </Suspense>
        </Fragment>
    );
};

export default memo(Client);
