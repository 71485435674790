import React from 'react';
import EnhancedChip from '../EnhancedChip/EnhancedChip';
import PropTypes from 'prop-types';

const StatusChip = (props) => {
    const { type, status, variant, handleClick } = props;

    if (type === 'verification') {
        if (status === 5) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="VERIFIED"
                    type="positive"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else if (status === 'Pending') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="PENDING"
                    type="neutral"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="UNVERIFIED"
                    type="negative"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        }
    } else if (type === 'payment') {
        if (status === 'paid') {
            return (
                <EnhancedChip
                    label="PAID"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'partial') {
            return (
                <EnhancedChip
                    label="PARTIAL"
                    type="positive"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else if (status === 'overdue') {
            return (
                <EnhancedChip
                    label="OVERDUE"
                    type="negative"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else if (status === 'unpaid') {
            return (
                <EnhancedChip
                    label="UNPAID"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'draft') {
            return (
                <EnhancedChip
                    label="DRAFT"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'pending') {
            return (
                <EnhancedChip
                    label="PENDING"
                    type="neutral"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else if (status === 'void') {
            return (
                <EnhancedChip
                    label="VOID"
                    type="none"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="UNPAID"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'description') {
        if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="APPROVED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 2) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="UNAPPROVED"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 3) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="REVERT"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 4) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="MISSING"
                    type="none"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 0) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="PENDING"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'listing') {
        if (status === 'pending') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="PENDING"
                    type="neutral"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else if (status === 'approved') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="APPROVED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'unapproved') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="UNAPPROVED"
                    type=""
                    borderStyle=""
                    variant={variant}
                />
            );
        } else if (status === 'archived') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="ARCHIVED"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'draft') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="DRAFT"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'expiring') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="EXPIRING"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'expired') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="EXPIRED"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'ad') {
        if (status === 'active') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="ACTIVE"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'draft') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="DRAFT"
                    type="neutral"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        } else if (status === 'scheduled') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="SCHEDULED"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'expired') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="EXPIRED"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 'archived') {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="ARCHIVED"
                    type="none"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'contact') {
        if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="CONFIRMED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 0) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="INVITE PENDING"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="INACTIVE"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'review') {
        if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="PUBLISHED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="UNPUBLISHED"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'review_content') {
        if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="CHECKED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="UNCHECKED"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'response_status') {
        if (status === 200) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label={status}
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label={status}
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else if (type === 'deal_type') {
        if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="Deal"
                    type="positive"
                    borderStyle="solid"
                    variant="outlined"
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="Scholarship"
                    type="neutral"
                    borderStyle="solid"
                    variant="outlined"
                />
            );
        }
    } else if (type === 'deal_status') {
        if (status === 0) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="APPROVED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="ARCHIVED"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 2) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="EXPIRED"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 3) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="EXPIRING"
                    type="negative"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 4) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="PENDING"
                    type="neutral"
                    borderStyle="dashed"
                    variant={variant}
                />
            );
        }
    } else if (type === 'faq') {
        if (status === 1) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="APPROVED"
                    type="positive"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else if (status === 2) {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="PENDING"
                    type="neutral"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        } else {
            return (
                <EnhancedChip
                    handleClick={handleClick}
                    label="NONE"
                    type="none"
                    borderStyle="solid"
                    variant={variant}
                />
            );
        }
    } else {
        return (
            <EnhancedChip
                handleClick={handleClick}
                label="INVALID"
                type="negative"
                borderStyle="solid"
                variant={variant}
            />
        );
    }
};

StatusChip.propTypes = {
    type: PropTypes.oneOf([
        'verification',
        'payment',
        'description',
        'listing',
        'ad',
        'contact',
        'review',
        'review_content',
        '',
    ]).isRequired,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    variant: PropTypes.string,
    handleClick: PropTypes.func,
};

export default StatusChip;
