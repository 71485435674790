import React from 'react';
import {
    Drawer,
    Typography,
    makeStyles,
    Paper,
    Button
} from '@material-ui/core';
import SearchBasedDropdown from '../../../components/SearchBasedDropdown/SearchBasedDropdown';
import Datepicker from 'react-datepicker';
import { RESOURCE_PAGES } from '../../../config/constants';
import { useObjectState } from '../../../utilities/customHooks';
import Close from '@material-ui/icons/Close';
import Save from '@material-ui/icons/Check';
import { useTravelResourceRankingState } from './TravelResourceRankingProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `12px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: 420,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawerTitle: {
        borderBottom: 'solid 1px',
        color: '#e91e63',
        textAlign: 'center',
        fontSize: '.9rem',
        padding: '12px',
        marginBottom: '32px'
    },
    paper: {
        padding: theme.spacing(3)
    },
    datepicker: {
        fontSize: '14px',
        borderRadius: '4px',
        border: `1px solid rgba(0, 0, 0, 0.26)`,
        textAlign: 'center',
        padding: theme.spacing(1),
        width: 324
    },
    cta: {
        display: 'flex',
        marginTop: 'auto',

        '& button:first-child': {
            marginRight: theme.spacing(1)
        }
    }
}));

const resourcePages = [...RESOURCE_PAGES].map(({ partner_id, name }) => ({
    value: partner_id,
    label: name
}));

const FilterDrawer = (props) => {
    const classes = useStyles();
    const { filterDrawerOpen, setTravelResourceRankingState } =
        useTravelResourceRankingState();
    const [state, setState] = useObjectState({
        resource: null,
        date: null
    });

    const handleFieldChange = (field) => (value) =>
        setState({ [field]: value });

    const handleClear = () => {
        setState({
            resource: null,
            date: null
        });
    };

    const handleSave = () => {
        // to do: actual filtering of data
        const newFilters = {
            partner_id: state.resource.value,
            date: state.date
        };
        setTravelResourceRankingState({
            filterDrawerOpen: false,
            filters: newFilters
        });
    };

    const handleClose = () => {
        setTravelResourceRankingState({ filterDrawerOpen: false });
    };

    const isValid = !!state.resource && !!state.date;

    return (
        <Drawer open={filterDrawerOpen} anchor="right" onClose={handleClose}>
            <div className={classes.root}>
                <Typography className={classes.drawerTitle}>
                    Data Filters
                </Typography>
                <Paper className={classes.paper}>
                    <Typography variant="overline">Resource Page</Typography>
                    <SearchBasedDropdown
                        value={state.resource}
                        arrayOptions={resourcePages}
                        handleChange={handleFieldChange('resource')}
                    />
                    <br />
                    <Typography variant="overline">Month Year</Typography>
                    <Datepicker
                        className={classes.datepicker}
                        selected={state.date}
                        onChange={handleFieldChange('date')}
                        dateFormat="MM/yyyy"
                        placeholderText="Select date"
                        showMonthYearPicker
                    />
                </Paper>
                <div className={classes.cta}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disableElevation
                        disabled={!state.resource && !state.date}
                        onClick={handleClear}
                        startIcon={<Close />}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disableElevation
                        onClick={handleSave}
                        startIcon={<Save />}
                        disabled={!isValid}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default FilterDrawer;
