import React, { Component } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import {
    Card,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    withStyles
} from '@material-ui/core';
import getDirectoryBy from '../../../../utilities/directory';
import {
    GA_URL,
    GA_INSURANCE_URL,
    GA_JOBS_URL,
    GA_EMBASSY_URL
} from '../../../../config/constants';
import { format } from 'date-fns';
import { trimSlash } from '../../../../helpers/dataUtility';
import TeamIcon from '../../../../components/Icons/TeamIcon';

const styles = (theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        '&:nth-child(odd)': {
            background: '#f2f2f2'
        },
        td: {
            padding: '8px 16px'
        }
    },
    card: {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
        background: 'none',
        padding: theme.spacing(2),
        fontSize: 14,
        height: 60,
        display: 'flex',
        alignItems: 'center',

        '&:hover': {
            cursor: 'pointer',
            background: '#f9f9f9'
        }
    },
    container: {
        '& > div': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    rowValue: {
        fontSize: 13,
        float: 'left',
        fontWeight: 400,
        color: '#555'
    },
    reorder: {
        cursor: 'move',
        border: 'dashed 2px #ddd',
        boxShadow: 'none',

        '&:active': {
            cursor: 'move'
        }
    },
    link: {
        textDecoration: 'none',
        fontWeight: 'bold',
        color: '#424242'
    },
    menu: {
        textDecoration: 'none',
        color: '#424242'
    },
    teamIcon: {
        marginRight: theme.spacing(1)
    }
});

class FeaturesTableBody extends Component {
    state = {
        anchorEl: null,
        rowFeatureId: null
    };

    handleOpenRowMenu = (event, rowFeatureId) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget, rowFeatureId });
    };

    handleCloseRowMenu = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null, rowFeatureId: null });
    };

    getAdUrl = (ad_type, is_special_ad_page, ad_type_id) => {
        let url;
        const { directory_id = 10, partner_id } = ad_type;
        const [{ alias, abbrv } = {}] =
            getDirectoryBy({
                key: 'id',
                val: [directory_id || 10]
            }) || [];

        if (partner_id > 0) {
            url = this.getPartnerUrl(partner_id);
        } else if (directory_id === 18) {
            url = GA_EMBASSY_URL;
        } else if (directory_id === 5 && ad_type_id === 11) {
            url = `${trimSlash(GA_URL)}/articles/${alias}`;
        } else if (directory_id !== 0 && directory_id !== 10) {
            if (abbrv === 'JA') {
                url = GA_JOBS_URL;
            } else {
                url = `${trimSlash(GA_URL)}/${
                    [11, 10].includes(ad_type_id) ? 'articles/' : ''
                }${alias}`;
            }
        } else {
            url = GA_URL;
        }

        if (
            !!directory_id &&
            directory_id !== 10 &&
            ad_type_id !== 11 &&
            is_special_ad_page
        ) {
            url = trimSlash(url) + '/ssearch/online/';
        }

        return url;
    };

    getPartnerUrl = (partner_id) => {
        let url = trimSlash(GA_URL);

        switch (partner_id) {
            case 1:
                url = trimSlash(GA_INSURANCE_URL);
                break;
            case 5:
                url = `${url}/travel-resources/accommodations-abroad`;
                break;
            case 9:
                url = `${url}/travel-resources`;
                break;
            default:
                break;
        }
        return url;
    };

    renderRowMenu = (feature) => {
        const { classes, filters } = this.props;
        const { date_filter } = filters || {};
        const { anchorEl, rowFeatureId } = this.state;
        const filter_month = new Date(date_filter);
        let rowMenus = [
            {
                name: 'Edit Ad',
                url: feature.edit_url
            }
        ];
        if (
            !!feature.is_active &&
            !!feature.ad_status &&
            format(filter_month, 'MMYYYY') === format(new Date(), 'MMYYYY')
        ) {
            rowMenus = [
                {
                    name: 'View Ad',
                    url: feature.ad_url,
                    target: 'blank'
                },
                ...rowMenus
            ];
        }
        return (
            <Menu
                key={`menu-wrapper-${feature.id}`}
                anchorEl={anchorEl}
                open={feature.id === rowFeatureId}
                onClose={this.handleCloseRowMenu}
                elevation={2}
                transitionDuration={{ enter: 100, exit: 100 }}
            >
                {rowMenus.map((menu, index) => {
                    return (
                        <MenuItem
                            key={`menu-item-${feature.id}-${index}`}
                            dense
                            disableGutters
                            onClick={(e) => e.stopPropagation()}
                        >
                            <NavLink
                                to={{
                                    pathname: menu.url
                                }}
                                target={menu.target}
                                className={classes.menu}
                            >
                                {menu.name}
                            </NavLink>
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    };

    formattedFeatures = (ads) => {
        return ads.map((ad) => {
            const {
                ad_type = {},
                is_special_page_ad = 0,
                placements = {}
            } = ad;
            const { ad_type_id } = ad_type || {};
            ad = { ...ad };

            ad['location'] = placements['location']
                ? placements['location']['name']
                : '';
            ad['timing'] = placements['timing']
                ? placements['timing']['name']
                : '';
            ad['type'] = placements['type']
                ? ad['placements']['type']['name']
                : '';

            ad.ad_url = this.getAdUrl(
                ad_type || {},
                is_special_page_ad === 1,
                ad_type_id
            );

            ad.edit_url = `/ad/${ad.id}/edit`;

            return ad;
        });
    };

    render() {
        const {
            classes,
            handleFeatureDragOver,
            handleFeatureDragStart,
            handleFeatureDrop,
            webFeatures,
            reorder,
            handleClick
        } = this.props;
        const features = this.formattedFeatures(webFeatures);

        return features.map((feature, index) => {
            return (
                <div
                    className={classes.root}
                    key={`feature-card-wrapper-${feature.id}`}
                    draggable={reorder}
                    onDragStart={(e) => handleFeatureDragStart(e, index)}
                    onDragOver={(e) => handleFeatureDragOver(e, index)}
                    onDrop={(e) => handleFeatureDrop(e, index)}
                    onClick={(e) => handleClick(e, feature)}
                >
                    <Card
                        key={`card-${feature.id}`}
                        className={classNames({
                            [classes.card]: true,
                            [classes.reorder]: reorder
                        })}
                    >
                        <Grid
                            key={`grid-${feature.id}`}
                            className={classes.container}
                            container
                            spacing={32}
                        >
                            <Grid item xs={2}>
                                <Typography className={classes.rowValue}>
                                    {feature.position}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TeamIcon
                                    variant={(feature.team.name || 'unassigned')
                                        .toLowerCase()
                                        .replace(' ', '-')}
                                    size="small"
                                    className={classes.teamIcon}
                                />
                                <Typography className={classes.rowValue}>
                                    <NavLink
                                        className={classes.link}
                                        to={`/client/profile/${feature.client_id}`}
                                    >
                                        {feature.client_name}
                                    </NavLink>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.rowValue}>
                                    {feature.program_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.rowValue}>
                                    {feature.directory}
                                </Typography>
                            </Grid>
                            <Grid key={`grid-menu-${feature.id}`} item xs={1}>
                                <IconButton
                                    key={`grid-menu-icon-${feature.id}`}
                                    onClick={(e) =>
                                        this.handleOpenRowMenu(e, feature.id)
                                    }
                                >
                                    <MoreHorizIcon
                                        key={`grid-menu-hori-${feature.id}`}
                                    />
                                </IconButton>
                                {this.renderRowMenu(feature)}
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            );
        });
    }
}

export default withStyles(styles)(withRouter(FeaturesTableBody));
