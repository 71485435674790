import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notice from './Notice';
import LoginForm from './Login/LoginForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../config/constants';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: '"Roboto", sans-serif',
        background: '#f2f2f2',
        position: 'relative',
        fontSize: '0.8215rem',

        '&:after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)'
        }
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    back: {
        position: 'absolute',
        left: theme.spacing.unit * 3,
        top: theme.spacing.unit * 3,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none'
        },

        '& svg': {
            fill: 'white',
            fontSize: '1em'
        }
    }
});

const Login = (props) => {
    const { classes, location } = props;
    const { state: { message = '' } = {} } = location || {};
    return (
        <Fragment>
            <CssBaseline />
            {message && <Notice message={message} />}
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                <div className={classes.root}>
                    <div className={classes.back}>
                        <ArrowBackIos />
                        <a href="https://www.goabroad.com">Back to GoAbroad</a>
                    </div>
                    <img
                        className={classes.background}
                        src="https://images.unsplash.com/photo-1516738901171-8eb4fc13bd20?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
                        alt=""
                    />
                    <LoginForm />
                </div>
            </GoogleReCaptchaProvider>
        </Fragment>
    );
};
export default withStyles(styles)(Login);
